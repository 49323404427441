import { Grid, Box, Backdrop, Stack } from '@mui/material'
import React, { useState } from 'react'
import Text from '../../../../../components/customText/Text'
import { BlackToolTip, addErrorMsg, addLoader, openDocumentInNewtab, removeLoader } from '../../../../../utils/utils'
import { useEffect } from 'react'
import DocumentStyles from './DocumentStyles';
import CommonApi from '../../../../../apis/CommonApi'
import LocalStorage from '../../../../../utils/LocalStorage';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import OnBoardApi from '../../../../../apis/admin/employees/OnBoardApi';
import Dropzone from 'react-dropzone';
import { blue } from '../../../../../theme';
import UploadSvg1 from '../../../../../assets/svg/UploadSvg1.svg';
import Loader from '../../../../../assets/gif/japfuLoader.gif';
import ReusableDelete from '../../../../../components/reuablePopup/ReusableDelete'

function EducationForm(props) {
    const { education, data, setEducation, action, setEditAccIcon, setAddExpand, addExpand, edit, expanded, setExpanded, setEdit } = props;
    const classes = DocumentStyles();// eslint-disable-next-line
    const [openBacKLoader, setOpenBackDrop] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    // eslint-disable-next-line
    const cancel = () => {
        if (action == 'view') {
            // setAddExpand(true);
            setEdit(!edit)
            setExpanded(!expanded);
        } else if (action == 'addNew') {
            setAddExpand(!addExpand);
            setEditAccIcon(2);
        }
    }

    useEffect(() => {
        if (data != undefined || data != null) {
            setEducation({
                ...education,
                id: data.id,
                documents: data.documents
            })
        }
        // eslint-disable-next-line
    }, [data, edit, action])
    // eslint-disable-next-line
    const deleteDoc = (id, index) => {
        let data = education;
        if (id !== "") {
            data.documents_deleted_ids.push(id)
        }
        data.documents.splice(index, 1)
        setEducation({ ...education });
        setDeleteOpen(false);
        setDeleteIndex(null);
    }

    const uploadDocs = (e) => {

        if (e.length < 11) {
            setOpenBackDrop(true);
            for (let i = 0; i < e.length; i++) {
                if (e[i].path.split('.').some(r => ['pdf', 'doc', 'docx'].includes(r))) {
                    if (Number(((e[i].size / (1024 * 1024)).toFixed(2))) < 25 ) {
                        const formData = new FormData();
                        // e.map((i) => formData.append("files", i))
                        formData.append("files", e[i]);
                        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                        addLoader(true);
                        CommonApi.documentUpload("education-document", formData, LocalStorage.getAccessToken()).then((res) => {
                            removeLoader();
                            if (res.data.statusCode == 1003) {
                                if (education.documents.length < 10) {
                                    education.documents.push({
                                        id: '',
                                        new_document_id: res.data.data.id,
                                        document_url: res.data.data.document_url,
                                        name: res.data.data.document_name,
                                        slug: ""
                                    });
                                    if (i === e.length - 1) {
                                        setOpenBackDrop(false);
                                    }
                                }
                                else {
                                    setOpenBackDrop(false);
                                    addErrorMsg('You can upload a maximum of 10 files at a time.')
                                }
                                setEducation({ ...education })
                            } else {
                                addErrorMsg(res.data.message);
                            }
                        })
                    }
                    else {
                        setOpenBackDrop(false);
                        addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
                    }
                }
                else {
                    setOpenBackDrop(false);
                    addErrorMsg('Please upload files in PDF or DOC format only.')
                }
            }
        } else {
            setOpenBackDrop(false);
            addErrorMsg('You can upload a maximum of 10 files at a time.')
        }
        // setFileLoading(true);
        // for (const key in files) {
        //     if (files[key].type == "application/msword" || files[key].type == "application/doc" || files[key].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files[key].type == "application/ms-doc" || files[key].type == "application/pdf") {
        //         var fileSize = files[key].size / (1024 * 1024);
        //         if (fileSize.toFixed(2) <= 25) {
        //             var formData = new FormData();
        //             formData.append("files", files[key]);
        //             formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        //             CommonApi.documentUpload("education-document", formData, LocalStorage.getAccessToken()).then((response) => {
        //                 if (response.data.statusCode == 1003) {
        //                     if (education.documents.length <= 9) {
        //                         var newDocs = education.documents;
        //                         newDocs.push({
        //                             name: response.data.data.document_name,
        //                             new_document_id: response.data.data.id,
        //                             document_url: response.data.data.document_url
        //                         });
        //                         setEducation({ ...education, documents: newDocs });
        //                     }

        //                     setFileLoading(false);
        //                 } else {
        //                     setFileLoading(false);
        //                     addErrorMsg(response.data.message);
        //                 }
        //             });
        //         } else {
        //             addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
        //         }
        //     } else {
        //         addErrorMsg(`Upload documents Format PDF/Docs`);
        //     }
        // }
    };

    const openInNewTab = (args, documentUrl) => {
        if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else if (args.aws_s3_status == undefined || args.aws_s3_status == undefined) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            openDocumentInNewtab(args.document_key, args.document_path)
        }
    }

    return (
        <Grid container item lg={12} spacing={2} columnSpacing={2}>
            <Grid item lg={12} md={12}>
                <Box className={classes.dropzoneMainBox}>
                    <Box className={classes.dropBox1}>
                        <Dropzone onDrop={acceptedFiles => { uploadDocs(acceptedFiles) }}>
                            {({ getRootProps, getInputProps, isDragActive }) => (
                                <section className={classes.dropZone}>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {
                                            isDragActive ?
                                                <Grid container spacing={1} justifyContent='center'>
                                                    <Text normal my={11} className={classes.dropzoneText}>Add educational documents such as transcripts, diplomas, certificates, etc.</Text>
                                                </Grid>
                                                :
                                                <Grid container spacing={1} textAlign='center'>
                                                    <Grid item lg={12} md={12} marginTop={'-8px'}>
                                                        {/* <img src={download} alt='download' /> */}
                                                    </Grid>
                                                    <Grid item lg={12} md={12} textAlign='center'>
                                                        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' textAlign='center' gap={1}>
                                                            <Text largeBlack>Drag & Drop Documents</Text>
                                                            <Text smallGrey>or</Text>
                                                            {
                                                                education.documents.length < 10 ?
                                                                    <Text smallBlue1 sx={{ textDecoration: `1px underline ${blue} !important`, cursor: 'pointer' }}>Click Here</Text> :
                                                                    <Text largeBlack sx={{ color: "#525252 !important", textDecoration: `1px underline ${blue} !important`, cursor: 'pointer' }}>Click Here</Text>
                                                            }
                                                            <Text largeBlack>To Upload</Text>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} mt={1}>
                                                        <Text mediumOrange>Add your educational documents like Grade card,<br /> certificates, transcripts, etc</Text>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} mt={1}>
                                                        <img src={UploadSvg1} alt="UploadSvg" style={{ paddingTop: "15px" }} />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} mt={1}>
                                                        <Text smallGreyFont>Document Size 25MB, Format - PDF, Docs</Text>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        {/* <DropZone callApi={uploadDocs} multiDocs={true} education={true} disableDrop={education.documents.length == 10 ? true : false} /> */}
                    </Box>
                </Box>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBacKLoader}>
                    <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                </Backdrop>
            </Grid>
            <Grid item lg={12} md={12} mt={2} sx={{
                "@media (min-width: 900px) and (max-width: 1400px)": {
                    marginTop: "55px"
                },
            }}>
                {
                    education.documents.length > 0 ?
                        <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px", color: education.documents.length > 0 && "#0C75EB !important" }}>
                            <Text smallLabel sx={{ font: "12px Quicksand !important" }}>Uploaded Documents</Text>
                            <Grid item container lg={12} md={12} columnGap={1.5}>
                                {
                                    education.documents.map((item, index) => (
                                        <Box mt={1.5} sx={{ display: "flex", alignItems: "center", padding: "4px 8px", background: "#EEEEEE", borderRadius: "50px" }}>
                                            <Text sx={{
                                                color: "#0C75EB !important", font: "12px Quicksand !important", fontWeight: `${600} !important`,
                                                "@media (min-width: 900px) and (max-width: 1400px)": {
                                                    font: "10px Quicksand !important",
                                                    fontWeight: `${600} !important`
                                                },
                                            }}>
                                                <Stack direction={'row'} pt={1} >
                                                    <Text mediumBlue sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(item, item.document_url) }}>
                                                        <BlackToolTip arrow placement='top' title={item.name}>
                                                            {item.name.slice(0, 16)} {item.name.length > 16 ? '...' : ''}
                                                        </BlackToolTip>
                                                    </Text>
                                                    <HighlightOffIcon sx={{
                                                        cursor: "pointer", color: "#737373",marginTop: '-5px',
                                                        "@media (min-width: 900px) and (max-width: 1400px)": {
                                                            paddingLeft: '5px',
                                                            marginTop: '-5px',
                                                            height: '17px',
                                                            width: '17px'
                                                        },
                                                    }} onClick={() => { setDeleteIndex({ id: item.id, index: index }); setDeleteOpen(true); }} />
                                                </Stack>
                                            </Text>

                                        </Box>
                                    ))
                                }
                            </Grid>
                        </Box> :
                        <Box sx={{
                            border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px"
                        }}>
                            <Text smallLabel sx={{ font: "14px Quicksand !important" }}>Uploaded Documents</Text>
                            <Grid item container lg={12} columnGap={1.5}>
                            </Grid>
                        </Box>
                }
            </Grid>

            {deleteOpen && <ReusableDelete open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { deleteDoc(deleteIndex.id, deleteIndex.index) }} />}
        </Grid>
    )
}

export default EducationForm