import React, { Fragment, useEffect, useState } from 'react';
import { Box, Menu, MenuItem, Divider, Badge, Avatar, Grid, Stack, Skeleton } from '@mui/material';
import { ReactComponent as BellIcon } from '../../../assets/svg/notification.svg';
// import { ReactComponent as CloseIcon } from '../../../assets/svg/bellclosen.svg';
import placementExpImg from '../../../assets/svg/placementNF.svg';
import Text from '../../../components/customText/Text';
import BellIconStyles from './NotificationsStyles';
import styled from '@emotion/styled';
import DashboardAPI from '../../../apis/admin/DashboardAPI';
// import Button from '../../../components/customButton/Button';
import empIcon from '../../../assets/notifications/notification-emp.svg';
import tsPending from '../../../assets/notifications/notification-ts-pending.svg';
import tsapproved from '../../../assets/notifications/notification-ts-approved.svg';
import tsdrafted from '../../../assets/notifications/notification-ts-drafted.svg';
import orgIcon from '../../../assets/notifications/notification-org.svg';
import clientIcon from '../../../assets/notifications/notification-client.svg';
import tsrejected from '../../../assets/notifications/notification-rejected.svg';
import tsdeleted from '../../../assets/svg/timesheet-deleted.svg';
import tsSummary from '../../../assets/notifications/timesheet-summary.svg';
import LocalStorage from '../../../utils/LocalStorage';
import everify from '../../../assets/notifications/notification-everified.svg';
import zoho from '../../../assets/notifications/notification-zoho.svg'; // eslint-disable-next-line
import { NoDataFound, addErrorMsg, eventSource } from '../../../utils/utils';
import CommonApi from '../../../apis/CommonApi';
import { domain } from '../../../config/Domain';
import zohoError from '../../../assets/svg/zoho_error.svg';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const FilterBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 0,
        top: 3,
        background: '#FF4B55 !important',
        border: '1px solid #FFFF',
        color: '#FFFF !important',
    },
}));

const moduleIcons = {
    "timesheet_approved": tsapproved,
    "timesheet_pending": tsPending,
    "timesheet_draft": tsdrafted,
    "timesheet_summary": tsSummary,
    "timesheet_rejected": tsrejected,
    "timesheet-deleted": tsdeleted,
    "timesheet-missing": '',
    "employee": empIcon,
    "organization": orgIcon,
    "company": clientIcon,
    "employee-profile": empIcon,
    "employee > internalemployees": empIcon,
    "employee > allemployees": empIcon,
    "myprofile-billing": empIcon,
    "placement": placementExpImg,
    'placements': placementExpImg,
    "organization > internal_employee": orgIcon,
    "employee > onboaders": empIcon,
    "employee > documents > passport": empIcon,
    "employee > documents > i94": empIcon,
    "employee > documents > driving license": empIcon,
    "employee > documents > state id": empIcon,
    "employee > documents > opt visa": empIcon,
    "employee > documents > cpt visa": empIcon,
    "employee > documents > green card visa": empIcon,
    "employee > documents > stem-opt visa": empIcon,
    "employee > documents > h-1b visa": empIcon,
    "employee > documents > w4 form": empIcon,
    "employee > documents > ssn": empIcon,
    "employee > documents > i-94": empIcon,
    "employee > onboaders > offer rejected": empIcon,
    "employee > verify": empIcon,
    "employee > onboarders > add": empIcon,
    "employee > user-profile": empIcon,
    "myprofile > manage subscription": empIcon,
    "employee > user-profile > e-verify": everify,
    "zoho_config": zoho,
    "zoho_error": zohoError,
    "client": clientIcon,
    "vendor": clientIcon,
    "end-client": clientIcon,
    "employee-password-changed": empIcon,
    "multi_timesheet_draft": tsdrafted,
    "no-redirect": empIcon
};

export default function Notification() {

    const classes = BellIconStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [NotificationData, setNotificationData] = useState({});
    const [Notificationlength, setNotificationlength] = useState([]);
    const [readDot, setreadDot] = useState(false);
    const [loading, setloading] = useState(false);
    const [value, setValue] = useState('');
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };

    const handleClose = () => {
        setAnchorEl(null);
        setValue("");
    };

    const handleViewAll = () => {
        handleClose();
        window.open('/notifications', '_blank');
    };

    useEffect(() => {
        listing();
    }, [])

    useEffect(() => {
        /* Create an EventSource to connect to the SSE (Server-Sent Events) endpoint */
        // Listen for the 'notification' event
        eventSource.addEventListener('notification', function (event) {
            const data = JSON.parse(event.data);
            if (data.data.length > 0) {
                setNotificationData({ ...data.data[0] });
                setreadDot(data.unread_notification);
                setNotificationlength(Object.keys(data.data[0]));
            }
        }, false);
        eventSource.addEventListener('dateFormatUpdated', function (event) {
            const data = JSON.parse(event.data);
            if (data.status) {
                LocalStorage.setDateFormat(data.date_format)
            }
        }, false);
    }, [])
    const listing = (selectedValue) => {
        DashboardAPI.notificationBellList('', '', '', '', selectedValue ?? '').then((res) => {
            setloading(true);
            if (res.data.statusCode == 1003) {
                setloading(false);
                setNotificationData(res.data.data[0]);
                setreadDot(res.data.unread_notification)
                setNotificationlength(Object.keys(res.data.data[0]))
            }
        })
    }


    const updateNotification = (args) => {
        const data = {
            request_id: LocalStorage.uid()
        }
        DashboardAPI.notificationUpdate(args, data).then((res) => {
            if (res.data.statusCode == 1003) {
                listing();
            }
        })
    }

    const upgradePlan = () => {
        let dataSubmit = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        if (LocalStorage.getUserData().plan_name == 'Free Plan' || LocalStorage.getUserData().plan_name == 'free') {
            LocalStorage.setRenew(true);
            window.open('/billing', '_blank');
        }
        else {
            CommonApi.renewInitiation(dataSubmit).then(
                (res) => {
                    if (res.data.statusCode == 1003) {
                        window.open(res.data.data.url, '_blank');
                    }
                    else {
                        addErrorMsg(res.data.message)
                    }
                }
            )
        }
    }

    const clickHandler = (args) => {
        if (args.is_read == false) {
            updateNotification(args.id)
        }
        if (args.redirection_info.module == 'timesheet-deleted') {
            LocalStorage.setRedirectedModule('timesheet-deleted');
            window.open('/timesheet', '_blank');
        } else if (args.redirection_info.module == 'timesheet-missing') {
            LocalStorage.setRedirectedModule('timesheet-missing');
            window.open('/timesheet', '_blank');
        } else if (args.redirection_info.module == 'placement' || args.redirection_info.module == 'placements') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setNotificationEmpId(args.redirection_info.employee_id);
            LocalStorage.setRedirectedModule('placement');
            window.open('/placements/view-placement', '_blank');
        } else if (args.redirection_info.module == 'client') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('client');
            window.open('/clients/clients-user-profile', '_blank');
        }
        else if (args.redirection_info.module == 'vendor') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('vendor');
            window.open('/vendor/user-profile', '_blank');
        }
        else if (args.redirection_info.module == 'end-client') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('end-client');
            window.open('/clients/end-clients-user-profile', '_blank');
        } else if (args.redirection_info.module == 'employee > onboaders') {
            LocalStorage.setRedirectedModule('employee');
            LocalStorage.setFromPage('notification')
            window.open('/employees', '_blank');
        } else if (args.redirection_info.module == 'employee' || args.redirection_info.module == 'employee-profile') {
            LocalStorage.setRedirectedModule('employee');
            LocalStorage.setNotificationEmpId(args.redirection_info.employee_id);
            window.open('/employees/user-profile', '_blank');
        } else if (args.redirection_info.module == 'organization > internal_employee') {
            LocalStorage.setRedirectedModule('organization');
            LocalStorage.setFromPage('notification');
            window.open('/employees', '_blank');
        }
        //  else if (args.redirection_info.module == 'employee-profile') {
        //     window.open('/myprofile', '_blank')
        // } 
        else if (args.redirection_info.module == 'employee > documents > i94') {
            LocalStorage.setRedirectedModule('employee_userprofile');
            LocalStorage.setSubModule('i94');
            LocalStorage.setNotificationEmpId(args.redirection_info.employee_id);
            window.open('/employees/user-profile', '_blank');
        } else if (args.redirection_info.module == 'employee > documents > passport') {
            LocalStorage.setRedirectedModule('employee_userprofile');
            LocalStorage.setSubModule('i94');
            LocalStorage.setNotificationEmpId(args.redirection_info.employee_id);
            window.open('/employees/user-profile', '_blank');
        } else if (args.redirection_info.module == 'employee > documents > driving license' || args.redirection_info.module == 'employee > documents > ssn' || args.redirection_info.module == 'employee > documents > state id' || args.redirection_info.module == 'employee > documents > opt visa' || args.redirection_info.module == 'employee > documents > cpt visa' || args.redirection_info.module == 'employee > documents > green card visa' || args.redirection_info.module == 'employee > documents > stem-opt visa' || args.redirection_info.module == 'employee > documents > h-1b visa' || args.redirection_info.module == 'employee > documents > i-94') {
            LocalStorage.setRedirectedModule('employee_userprofile');
            LocalStorage.setSubModule('visa');
            LocalStorage.setNotificationEmpId(args.redirection_info.employee_id);
            window.open('/employees/user-profile', '_blank')
        } else if (args.redirection_info.module == 'employee > documents > w4 form') {
            LocalStorage.setRedirectedModule('employee_userprofile');
            LocalStorage.setSubModule('bank');
            LocalStorage.setNotificationEmpId(args.redirection_info.employee_id);
            window.open('/employees/user-profile', '_blank');
        } else if (args.redirection_info.module == 'timesheet_approved') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('timesheet_approved');
            window.open('/timesheet/edit-timesheet', '_blank');
        } else if (args.redirection_info.module == 'timesheet_pending') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('timesheet_pending');
            window.open('/timesheet/edit-timesheet', '_blank');
        } else if (args.redirection_info.module == 'timesheet_draft') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('timesheet_draft');
            window.open('/timesheet/edit-timesheet', '_blank');
        } else if (args.redirection_info.module == 'timesheet_rejected') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('timesheet_rejected');
            window.open('/timesheet/edit-timesheet', '_blank');
        } else if (args.redirection_info.module == 'timesheet_summary') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('timesheet_summary');
            window.open('/timesheet/summary', '_blank');
        } else if (args.redirection_info.module == 'myprofile-billing') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('myprofile-billing');
            upgradePlan();
        } else if (args.redirection_info.module == 'employee > internalemployees') {
            LocalStorage.setRedirectedModule('employee > internalemployees');
            window.open('/employees', '_blank');
        } else if (args.redirection_info.module == 'employee > allemployees') {
            LocalStorage.setRedirectedModule('employee > allemployees');
            window.open('/employees', '_blank');
        } else if (args.redirection_info.module == 'employee > onboaders > offer rejected') {
            LocalStorage.setRedirectedModule('employee > onboaders > offer rejected');
            window.open('/employees', '_blank');
        } else if (args.redirection_info.module == 'employee > verify') {
            LocalStorage.setRedirectedModule('employee > verify');
            LocalStorage.setNotificationId(args.redirection_info.id);
            window.open('/employee/verify', '_blank');
        } else if (args.redirection_info.module == 'employee > onboarders > add') {
            LocalStorage.setRedirectedModule('employee > onboarders > add');
            LocalStorage.setNotificationId(args.redirection_info.id);
            window.open('/employees/onboard', '_blank');
        }
        else if (args.redirection_info.module == 'employee > user-profile') {
            LocalStorage.setRedirectedModule('employee > user-profile');
            LocalStorage.setNotificationEmpId(args.redirection_info.employee_id);
            window.open('/employees/user-profile', '_blank');
        }
        else if (args.redirection_info.module == 'employee > user-profile > e-verify') {
            LocalStorage.setRedirectedModule('employee_userprofile');
            LocalStorage.setNotificationEmpId(args.redirection_info.id);
            window.open('/employees/user-profile', '_blank');
        }
        else if (args.redirection_info.module == 'myprofile > manage subscription') {
            LocalStorage.setRedirectedModule('myprofile > manage subscription');
            window.open('/myprofile', '_blank');
        } else if (args.redirection_info.module == 'zoho_config') {
            LocalStorage.setRedirectedModule('zoho_config');
            window.open('/app-integrations', '_blank');
        } else if (args.redirection_info.module == 'employee-password-changed') {
            LocalStorage.setRedirectedModule('employee-password-changed');
            setAnchorEl(null);
        } else if (args.redirection_info.module == 'multi_timesheet_draft') {
            LocalStorage.setRedirectedModule('multi_timesheet_draft');
            // LocalStorage.setTsPlacementID(args.redirection_info.placement_id);
            window.open('/timesheet', '_blank');
            setAnchorEl(null);
            window.open('/timesheet', '_blank');
        } else if (args.redirection_info.module == 'no-redirect') {
            LocalStorage.removeRedirectedModule();
            setAnchorEl(null);
        }
    }

    const NotificationIcon = ({ data }) => {
        if (data.redirection_info !== '' && data.redirection_info?.module == 'zoho_config') {
            return <Avatar src={data.redirection_info.status !== 'Fail' ? moduleIcons[data.redirection_info.module] : moduleIcons['zoho_error']} alt='icons' className={classes.avatarSize} />
        }
        return <Avatar src={data.redirection_info !== '' ? moduleIcons[data.redirection_info.module] : moduleIcons[data.notification_slug]} alt='icons' className={classes.avatarSize} />
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        listing(newValue === 'Unread' ? false : newValue === 'Read' ? true : '');
    };



    return (
        <Fragment>
            <Box className={classes.root1} onClick={handleClick} >
                <FilterBadge overlap="circular" variant={readDot == false ? '' : 'dot'} >
                    <BellIcon height={24} width={24} />
                </FilterBadge>
            </Box>

            <Menu
                anchorEl={anchorEl}
                open={open}
                // onClick={() => setAnchorEl(null)}
                onClose={handleClose}
                className={classes.menu}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                sx={{ padding: '12px 0px' }}
            >
                <Divider sx={{ borderWidth: '0px !important' }} />
                <MenuItem className={classes.menuItem}  >
                    <Text mediumBoldBlack2 sx={{ fontSize: '18px !important' }}>Notifications</Text>
                    {/* <Box className={classes.closeIcon}><CloseIcon onClick={handleClose} /></Box> */}
                    {
                        Notificationlength.length == 0 || LocalStorage.getUserData().org_config_set == false ? '' :
                            <MenuItem sx={{
                                "&:hover": {
                                    backgroundColor: "transparent !important",
                                },
                            }}>
                                <Text lightBluesmall sx={{
                                    color: `#0C75EB !important`, font: "13px Quicksand !important",
                                }} onClick={handleViewAll}>View all Notifications</Text>
                            </MenuItem>
                    }
                </MenuItem>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} >
                            <Tab label="All" value="" sx={{
                                color: value === "" ? '#0C75EB !important' : '#000000 !important',
                                font: "13px Quicksand !important",
                                textTransform: 'capitalize',
                                fontWeight: `${600} !important`,

                            }} />
                            <Tab label="Unread" value="Unread" sx={{
                                color: value === "Unread" ? '#0C75EB !important' : '#000000 !important',
                                font: "12px Quicksand !important",
                                textTransform: 'capitalize',
                                fontWeight: `${600} !important`,

                            }} />
                            <Tab label="Read" value="Read" sx={{
                                color: value === "Read" ? '#0C75EB !important' : '#000000 !important',
                                font: "12px Quicksand !important",
                                textTransform: 'capitalize',
                                fontWeight: `${600} !important`,

                            }} />
                        </TabList>
                    </Box>
                    <TabPanel value={value}>
                        {/* <Divider className={classes.line} /> */}
                        {
                            loading ? [1, 2, 3, 4, 5, 6, 7].map((index) => (
                                <Stack className={classes.notificationCard} key={index}>
                                    <Stack className={classes.leftContent}>
                                        <Skeleton variant='circular' height={'53px'} width={'53px'} />
                                        <Stack gap={1}>
                                            <Skeleton variant='text' width={150} height={16} />
                                            <Skeleton variant='text' width={100} height={14} />
                                        </Stack>
                                    </Stack>
                                    <Stack gap={1} alignItems={'center'}>
                                        <Skeleton variant='text' width={50} height={16} />
                                        <Skeleton variant='text' width={70} height={14} />
                                    </Stack>
                                </Stack>
                            )) :
                                (Notificationlength.length == 0) || (LocalStorage.getUserData().org_config_set == false) ?
                                    <Box className={classes.NoDataFoundIcon}>
                                        {NoDataFound()}
                                    </Box>
                                    :
                                    !loading ?
                                        <Box className={classes.scrollContent}>
                                            {Notificationlength.length > 0 ? Notificationlength.map((obj1, key1) => (<>
                                                {/* <Grid container justifyContent='center' alignItems='center'>
                                                    <Box className={classes.day}>
                                                        <Text smallBlack400>{obj1}</Text>
                                                    </Box>
                                                </Grid> */}
                                                {(NotificationData != undefined) && (NotificationData[obj1] != undefined) && NotificationData[obj1].map((obj, key) => (
                                                    key < 3 &&
                                                    <>
                                                        <Grid container lg={12} xs={12} sx={{ cursor: 'pointer', padding: '5px 0px' }}>
                                                            <Grid item container lg={12} xs={12} columnSpacing={2} alignItems='center' sx={{
                                                                background: obj.is_read ? '#F8F8F8' : '', padding: '12px 8px', margin: obj.is_read ? '5px 0px' : '0px',
                                                                '&:hover': {
                                                                    backgroundColor: "#F8F8F8",
                                                                    transition: "all .3s ease",
                                                                    boxShadow: '0px 0px 15px 1px rgba(12, 117, 235, 0.30) !important'
                                                                }
                                                            }} onClick={() => clickHandler(obj)}>
                                                                <Grid item lg={2}>
                                                                    <NotificationIcon data={obj} />
                                                                </Grid>
                                                                <Grid item lg={7.5}>
                                                                    {
                                                                        obj.is_read ? <Text notificationread>{obj.title ? obj.title : '-'}</Text> : <Text smallBoldBlack>{obj.title ? obj.title : '-'}</Text>
                                                                    }
                                                                    {
                                                                        obj.is_read ? <Text notificationreadSmall>{obj.content}</Text> : <Text greyLabel>{obj.content}</Text>
                                                                    }
                                                                </Grid>
                                                                <Grid item lg={2.5}>
                                                                    <Text smallGrey10>{obj.time}</Text>
                                                                    <Text greyLabel>{obj.date}</Text>
                                                                </Grid>
                                                            </Grid>
                                                            {
                                                                obj.is_read ? '' :
                                                                    <Divider className={classes.notificationdivider} />
                                                            }
                                                        </Grid>
                                                    </>
                                                ))
                                                }
                                            </>)) : ''}
                                        </Box> : ''
                        }
                    </TabPanel>
                </TabContext>
            </Menu>
        </Fragment>
    );
}

