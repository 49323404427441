import React, { useState, useRef, useEffect } from "react";
import Text from "../components/customText/Text";
import SendIcon from "@mui/icons-material/Send";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { ReactComponent as Avatar } from "../assets/svg/avatar.svg";
import { Box, Grid, IconButton } from "@mui/material";
import { CopyAllOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import Logo from "../assets/gif/japfuLoader.gif";
import { ReactComponent as LogoSvg } from "../assets/logo.svg";
import Chatbot_API from "../apis/Chatbot_API";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import BarChart from "../components/charts/BarChart";
import LineChart from "../components/charts/LineChart";
import PieChart from "../components/charts/PieChart";
import RadialChart from "../components/charts/RadialChart";
import LocalStorage from "../utils/LocalStorage";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DashboardAPI from "../apis/admin/DashboardAPI";
import { domain } from "../config/Domain";
import TableMivi from "../assets/svg/TableMivi.svg"
import TableMivi1 from "../assets/svg/TableMivi1.svg"
import TableMivi2 from "../assets/svg/TableMivi2.svg"

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#404040",
    padding: "6px 14px",
    minWidth: 100,
    border: "1px solid #404040"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#404040",
    "&::before": {
      backgroundColor: "#404040",
      border: "1px solid #404040"
    }
  },
}));

const useStyles = makeStyles(() => ({
  chatInput: {
    width: "100%",
    paddingRight: "5px",
    height: "60px",
    border: "none",
    borderRadius: "8px",
    font: "15px Quicksand",
    fontWeight: `${400}`,
    backgroundColor: "#F2F7FF",
    paddingLeft: "15px",
    alignItems: "center",
  },
  msg: {
    borderRadius: "4px",
    minWidth: "100px",
    margin: "4px 0px",
    padding: "5px 10px",
    textAlign: "start",
  },
  sendMsgBox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    background: "#F4F4F4",
  },
  receiveMsgBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  suggestions: {
    width: "75%",
    border: "1px solid #3B4957",
    borderRadius: "8px",
    padding: "5px 8px",
  },
  suggestionText: {
    color: "#3B4957 !important",
    cursor: "pointer",
  },
  miviBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    width: "1000px",
    borderRadius: "8px !important",
    "-webkit-scrollbar": {
      width: "0px !important",
    },
  },
  miviContainer: {
    display: "flex",
    flexDirection: "column !important",
    justifyContent: "center",
    alignItems: "center",
  },
  BootUpInputContainer: {
    paddingLeft: "10px",
    paddingRight: "10px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "8px",
    backgroundColor: "#F2F7FF",
  },
  BootUpInputContainerSearch: {
    paddingLeft: "10px",
    paddingRight: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "8px",
    backgroundColor: "#F2F7FF",
  },
  scrollableContainer: {
    display: "flex",
    flexDirection: "column !important",
    gap: "50px",
    alignItems: "center",
    height: "55vh",
    overflow: "scroll",
  },
  userBox: {
    display: "flex",
    padding: "30px",
    // flexDirection: "column !important",
    gap: 10,
  },
  miviMessageBox: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#F3F7FF",
    flexDirection: "column !important",
    borderRadius: "8px",
  },
}));

function ChatBot(props) {
  const [messages, setMessages] = useState([]);

  return (
    <>
      {messages.length > 0 ? (
        <ChatBox setplanDetails={props.setplanDetails} messages={messages} setMessages={setMessages} />
      ) : (
        <BootUpScreen setMessages={setMessages} setplanDetails={props.setplanDetails} />
      )}
    </>
  );
}

function BootUpScreen({ setMessages, setplanDetails }) {
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const changeHandler = (e) => {
    setMessage(e.target.value);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [spin, setSpin] = useState(false);

  const messageHandler = () => {
    setIsLoading(true);
    setTimeout(() => {
      setSpin(true);
    }, 200);
    Chatbot_API.FetchReply(message).then((response) => {
      if (response.status === 200) {
        setMessages([
          {
            user: "user",
            type: "text",
            question: message,
          },
          {
            ...response.data.data,
            user: "mivi",
          },
        ]);
        setIsLoading(false);
        setSpin(false);
        let data = {
          request_id: LocalStorage.uid(),
          subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
          if (res.data.statusCode === 1003) {
            setplanDetails(res.data);
            LocalStorage.setPlanCheckLocal(res.data)
            LocalStorage.setDateFormat(res.data.date_format);
          }
        })
      } else {
        setMessages([
          {
            user: "user",
            type: "text",
            question: message,
          },
          {
            user: "mivi",
            title: "Sorry!!",
            type: "text",
            message:
              "I'm not able to find the information you have asked, try rephrasing or ask in detail for more accurate responses.",
          },
        ]);
        setIsLoading(false);
        setSpin(false);
      }
    });
  };

  function getGreetingBasedOnTime() {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return "Good Morning";
    } else if (currentHour < 17) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  }


  const StyledImage = styled("img")({
    "@keyframes rotate": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  });

  const suggestionHandler = (suggestion) => {
    setIsLoading(true);
    setTimeout(() => {
      setSpin(true);
    }, 200);
    Chatbot_API.FetchReply(suggestion).then((response) => {
      if (response.status === 200) {
        setMessages([
          {
            user: "user",
            type: "text",
            question: suggestion,
          },
          {
            ...response.data.data,
            user: "mivi",
          },
        ]);
        setIsLoading(false);
        setSpin(false);
        let data = {
          request_id: LocalStorage.uid(),
          subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
          if (res.data.statusCode === 1003) {
            setplanDetails(res.data);
            LocalStorage.setPlanCheckLocal(res.data)
            LocalStorage.setDateFormat(res.data.date_format);
          }
        })
      } else {
        setMessages([
          {
            user: "user",
            type: "text",
            question: suggestion,
          },
          {
            user: "mivi",
            title: "Sorry!!",
            type: "text",
            message:
              "I'm not able to find the information you have asked, try rephrasing or ask in detail for more accurate responses.",
          },
        ]);
        setIsLoading(false);
        setSpin(false);
      }
    });
    
  };

  const imageContainers = [
    {
      headind_text: "Text to Tables",
      sub_text: "Create tables based on data with one click.",
      image: TableMivi,
      color: "linear-gradient(195.77deg, #ABDCFF -21.41%, #FFFFFF 88.99%)",
    },
    {
      headind_text: "Data Visualisation",
      sub_text: "Prompts that generate charts with your data.",
      image: TableMivi1,
      color: "linear-gradient(195.77deg, #FFF5E4 -21.41%, #FFFFFF 88.99%)",
    },
    {
      headind_text: "Instant Information",
      sub_text: "Access your data instantly with prompt",
      image: TableMivi2,
      color: "linear-gradient(195.77deg, #E7E0FE -21.41%, #FFFFFF 88.99%)",
    },
  ];

  const suggestions = [
    {
      question: "How many timesheets are pending for approval this month?",
    },
    {
      question: "How many employees joined in last three months?",
    },
    {
      question: "Give me comparison of placements in last three months?",
    },
  ];

  return (
    <Box
      className={classes.miviBox}
      sx={{
        height: isLoading ? "100%" : "",
        paddingTop: isLoading ? "25px" : "60px",
      }}
    >
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        sx={{
          marginTop: 0,
          marginLeft: 0,
          "&::-webkit-scrollbar": { display: "none", width: "0px" },
          height: isLoading ? "100%" : "",
        }}
      >
        <Grid item xs={12} className={classes.miviContainer}>
          <StyledImage
            src={Logo}
            style={{
              height: isLoading ? "120px" : "70px",
              width: isLoading ? "120px" : "70px",
              transition: isLoading
                ? "height 0.2s ease-in, width 0.2s ease-in"
                : "",
              // animation: spin ? `rotate 2s linear infinite` : "",
            }}
            isLoading={isLoading}
            alt="chatIcon"
          />
          {!isLoading ? (
            <Text veryLargeLabel sx={{ paddingTop: "20px" }} >
              Mivi
            </Text>
          ) : (
            <Text
              sx={{
                paddingTop: "50px",
                fontSize: "36px",
                width: "60%",
                textAlign: "center",
                "@keyframes blinker": {
                  "0%": {
                    opacity: 1,
                  },
                  "50%": {
                    opacity: 0.3,
                  },
                  "100%": {
                    opacity: 1,
                  },
                },
                animation: spin ? `blinker 1s linear infinite` : "",
              }}
            >
              Harnessing the data winds... Blowing in your direction!
            </Text>
          )}
        </Grid>
        {!isLoading ? (
          <div >
            <Grid item xs={12} className={classes.miviContainer}>
              <Text
                sx={{
                  font: "26px Quicksand",
                  fontSize: "26px",
                  fontWeight: "500",
                  paddingTop: "20px",
                }}
              >
                {getGreetingBasedOnTime()}, {LocalStorage.getUserData().first_name}
              </Text>
            </Grid>
            <Grid
              
              item
              xs={12}
              className={classes.miviContainer}
              sx={{ paddingBottom: "20px" }}
            >
              
              <Box id='mivisearchbar' sx={{width:'60%'}}>
                <Box className={classes.BootUpInputContainerSearch}>
                <input 
                  className={classes.chatInput}
                  placeholder="Ask Anything To Mivi"
                  name="message"
                  value={message}
                  onChange={changeHandler}
                  autoFocus="on"
                  autoComplete="off"
                  onKeyDown={(e) => {
                    if (e.code === "Enter" && message != "") {
                      messageHandler();
                    }
                  }}
                />
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "44px",
                    height: "44px",
                    backgroundColor: "#0C75EB",
                    borderRadius: "8px",
                  }}
                  onClick={() => {
                    if (message != "") {
                      messageHandler();
                    }
                  }}
                >
                  <SendIcon style={{ color: "#ffffff", fontSize: 20 }} />
                </Box>
                </Box>
                
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.scrollableContainer}
              sx={{ "&::-webkit-scrollbar": { display: "none" } }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  width: "80%",
                  justifyContent: "space-between !important",
                }}
              >
                {imageContainers.map((data) => (
                  <Box
                    sx={{
                      height: "160px",
                      width: "230px !important",
                      display: "flex",
                      flexDirection: "column !important",
                      justifyContent: "end",
                      background: data.color,
                      borderRadius: "6px",
                    }}
                  >
                    <Box
                      sx={{
                        paddingLeft: "15px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <img src={data.image} alt='img' />
                      <Text mediumBlack>{data.headind_text}</Text>
                      <Text verySmallBlack sx={{ color: "#525252 !important" }}>
                        {data.sub_text}
                      </Text>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  flexDirection: "column !important",
                }}
              >
                <Text largeBlack sx={{ color: "#404040 !important" }}>
                  Prompts You Can Try
                </Text>
                <Box sx={{ width: "100%", paddingBottom: "30px" }}>
                  {suggestions.map((suggestion) => (
                    <Box
                      sx={{
                        display: "flex",
                        borderRadius: "8px",
                        gap: "0px",
                        alignItems: "center",
                        border: "1px solid #F2F7FF",
                        padding: "20px 30px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#F2F7FF",
                          boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.5)",
                        },
                      }}
                      onClick={() => suggestionHandler(suggestion.question)}
                    >
                      <Text mediumBlack>{suggestion.question}</Text>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>{" "}
          </div>
        ) : null}
      </Grid>
    </Box>
  );
}

function ChatBox({ messages, setMessages, setplanDetails }) {
  const classes = useStyles();
  const [newMessage, setNewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const DotAnimationDiv = styled("div")({
    width: "5em",
    height: "2em",
    position: "relative",
    padding: "10px",
    marginLeft: "5px",
    background: "#e6e6e6",
    borderRadius: "20px",
    alignSelf: "end !important",

    ".typing__dot": {
      float: "left",
      width: "8px",
      height: "8px",
      margin: "0 4px",
      background: "#8d8c91",
      borderRadius: "50%",
      opacity: "0",
      animation: "loadingFade 1s infinite",
    },

    ".typing__dot:nth-child(1)": {
      animationDelay: "0s",
    },

    ".typing__dot:nth-child(2)": {
      animationDelay: "0.2s",
    },

    ".typing__dot:nth-child(3)": {
      animationDelay: "0.4s",
    },

    "@keyframes loadingFade": {
      "0%": {
        opacity: "0",
      },
      "50%": {
        opacity: "0.8",
      },
      "100%": {
        opacity: "0",
      },
    },
  });

  const Typing = () => (
    <DotAnimationDiv>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
    </DotAnimationDiv>
  );

  //   const messages = [
  //     {
  //       user: "user",
  //       type: "text",
  //       question: "some random question",
  //     },
  //     {
  //       user: "mivi",
  //       title: "Employees Joined This Month",
  //       type: "table",
  //       data: [
  //         {
  //           display_name: "sf fd",
  //           date_of_joining: "2024-05-30",
  //         },
  //         {
  //           display_name: "omini koimi",
  //           date_of_joining: "2024-05-22",
  //         },
  //         {
  //           display_name: "Test bb",
  //           date_of_joining: "2024-05-16",
  //         },
  //         {
  //           display_name: "wwww eeeeeeeeeee",
  //           date_of_joining: "2024-05-16",
  //         },
  //         {
  //           display_name: "haio oi",
  //           date_of_joining: "2024-05-15",
  //         },
  //         {
  //           display_name: "name lst fir",
  //           date_of_joining: "2024-05-08",
  //         },
  //         {
  //           display_name: "tesiusdh sadflkjbnsdalkjbf",
  //           date_of_joining: "2024-05-06",
  //         },
  //         {
  //           display_name: "name maei",
  //           date_of_joining: "2024-05-23",
  //         },
  //         {
  //           display_name: "qwertyu qwertyu",
  //           date_of_joining: "2024-05-14",
  //         },
  //         {
  //           display_name: "Test j",
  //           date_of_joining: "2024-05-10",
  //         },
  //         {
  //           display_name: "Test a",
  //           date_of_joining: "2024-05-04",
  //         },
  //         {
  //           display_name: "Kennan Danielle Hale Tyler",
  //           date_of_joining: "2024-05-05",
  //         },
  //         {
  //           display_name: "ASDFJHNGBVF SXDCFGBVF",
  //           date_of_joining: "2024-05-09",
  //         },
  //         {
  //           display_name: "gh ty",
  //           date_of_joining: "2024-05-16",
  //         },
  //         {
  //           display_name: "Pearls Goa",
  //           date_of_joining: "2024-05-17",
  //         },
  //         {
  //           display_name: "karunakarn h",
  //           date_of_joining: "2024-05-14",
  //         },
  //         {
  //           display_name: "sdaf adfgv",
  //           date_of_joining: "2024-05-07",
  //         },
  //         {
  //           display_name: "Nazeer Shaik",
  //           date_of_joining: "2024-05-14",
  //         },
  //         {
  //           display_name: "vamsi serwwwwwwwwwwwwwwwwwwwwwwwwwwwwww Reddy",
  //           date_of_joining: "2024-05-06",
  //         },
  //         {
  //           display_name: "Alexis Kai French Stevens",
  //           date_of_joining: "2024-05-13",
  //         },
  //         {
  //           display_name: "anvi sanvi",
  //           date_of_joining: "2024-05-28",
  //         },
  //         {
  //           display_name:
  //             "firstuuuuuuuuuuuuuuuuuuuuuuuuuuuu mediummmmmmmmmmmmmmmmmmmmmmmmmmmm Lastuuuuuuuuuuuuuuuuuuuuuuuuuuuuu",
  //           date_of_joining: "2024-05-06",
  //         },
  //         {
  //           display_name: "Dharshan Krishna Mathukudi",
  //           date_of_joining: "2024-05-14",
  //         },
  //         {
  //           display_name: "as a",
  //           date_of_joining: "2024-05-16",
  //         },
  //         {
  //           display_name: "tesiusdh sadflkjbnsdalkjbf",
  //           date_of_joining: "2024-05-06",
  //         },
  //         {
  //           display_name: "Sanjay Sahu M",
  //           date_of_joining: "2024-05-07",
  //         },
  //         {
  //           display_name: "xdfh dcxz",
  //           date_of_joining: "2024-05-13",
  //         },
  //         {
  //           display_name: "john ray",
  //           date_of_joining: "2024-05-17",
  //         },
  //         {
  //           display_name: "sdgd dfgd",
  //           date_of_joining: "2024-05-17",
  //         },
  //         {
  //           display_name: "test g",
  //           date_of_joining: "2024-05-04",
  //         },
  //         {
  //           display_name: "Ramana Reddy Chintapally",
  //           date_of_joining: "2024-05-15",
  //         },
  //         {
  //           display_name: "Vamsi pediveeti dhar",
  //           date_of_joining: "2024-05-22",
  //         },
  //         {
  //           display_name: "ASDFGHJ JHGFDS",
  //           date_of_joining: "2024-05-09",
  //         },
  //         {
  //           display_name: "Nazeer Shaik",
  //           date_of_joining: "2024-05-24",
  //         },
  //         {
  //           display_name: "parthibhan Leo",
  //           date_of_joining: "2024-05-15",
  //         },
  //         {
  //           display_name: "reenu p",
  //           date_of_joining: "2024-05-14",
  //         },
  //         {
  //           display_name: "Burton Ocean Gregory Wilcox",
  //           date_of_joining: "2024-05-05",
  //         },
  //         {
  //           display_name: "off board employee",
  //           date_of_joining: "2024-05-03",
  //         },
  //         {
  //           display_name: "Nirosha M",
  //           date_of_joining: "2024-05-01",
  //         },
  //         {
  //           display_name: "Test ten",
  //           date_of_joining: "2024-05-01",
  //         },
  //         {
  //           display_name: "Chatri Nathan",
  //           date_of_joining: "2024-05-29",
  //         },
  //         {
  //           display_name: "Testa test",
  //           date_of_joining: "2024-05-03",
  //         },
  //         {
  //           display_name: "qq ww",
  //           date_of_joining: "2024-05-15",
  //         },
  //         {
  //           display_name: "dlf D",
  //           date_of_joining: "2024-05-06",
  //         },
  //         {
  //           display_name: "mshi mahi",
  //           date_of_joining: "2024-05-29",
  //         },
  //         {
  //           display_name: "Hari sdfin",
  //           date_of_joining: "2024-05-06",
  //         },
  //         {
  //           display_name: "firs namo",
  //           date_of_joining: "2024-05-22",
  //         },
  //         {
  //           display_name: "Sudhakar Sunkara",
  //           date_of_joining: "2024-05-14",
  //         },
  //         {
  //           display_name: "Test Testing nine",
  //           date_of_joining: "2024-05-01",
  //         },
  //         {
  //           display_name: "Test Hema",
  //           date_of_joining: "2024-05-22",
  //         },
  //         {
  //           display_name: "Samira Shaik",
  //           date_of_joining: "2024-05-15",
  //         },
  //         {
  //           display_name: "Amar Rathod Sai",
  //           date_of_joining: "2024-05-16",
  //         },
  //         {
  //           display_name: "Vinod Kumar B",
  //           date_of_joining: "2024-05-09",
  //         },
  //         {
  //           display_name: "asd as",
  //           date_of_joining: "2024-05-07",
  //         },
  //         {
  //           display_name: "alla huddin",
  //           date_of_joining: "2024-05-20",
  //         },
  //         {
  //           display_name: "Test ac",
  //           date_of_joining: "2024-05-14",
  //         },
  //         {
  //           display_name: "test dfg",
  //           date_of_joining: "2024-05-01",
  //         },
  //         {
  //           display_name: "uma mahesh k",
  //           date_of_joining: "2024-05-13",
  //         },
  //         {
  //           display_name: "Dhilli k",
  //           date_of_joining: "2024-05-13",
  //         },
  //         {
  //           display_name: "contractor q Last",
  //           date_of_joining: "2024-05-15",
  //         },
  //         {
  //           display_name: "Bhuvan Chnadran Samarla",
  //           date_of_joining: "2024-05-16",
  //         },
  //         {
  //           display_name: "Prasad Rao Tutukkudi",
  //           date_of_joining: "2024-05-14",
  //         },
  //         {
  //           display_name: "shivani kumari",
  //           date_of_joining: "2024-05-17",
  //         },
  //         {
  //           display_name: "Kumari P",
  //           date_of_joining: "2024-05-17",
  //         },
  //         {
  //           display_name: "Itachi Sharingan",
  //           date_of_joining: "2024-05-17",
  //         },
  //         {
  //           display_name: "Minato Hui",
  //           date_of_joining: "2024-05-17",
  //         },
  //         {
  //           display_name: "Kakashi Sauihi",
  //           date_of_joining: "2024-05-28",
  //         },
  //         {
  //           display_name: "vinod R",
  //           date_of_joining: "2024-05-17",
  //         },
  //         {
  //           display_name: "adfd sf",
  //           date_of_joining: "2024-05-21",
  //         },
  //         {
  //           display_name: "Luffy OP",
  //           date_of_joining: "2024-05-17",
  //         },
  //         {
  //           display_name: "aaaaaa bbbbbb",
  //           date_of_joining: "2024-05-18",
  //         },
  //         {
  //           display_name: "Test  Testq",
  //           date_of_joining: "2024-05-17",
  //         },
  //         {
  //           display_name: "giri g",
  //           date_of_joining: "2024-05-20",
  //         },
  //         {
  //           display_name: "Off Emp",
  //           date_of_joining: "2024-05-16",
  //         },
  //         {
  //           display_name: "Timesheet Approver  PA",
  //           date_of_joining: "2024-05-21",
  //         },
  //         {
  //           display_name: "Via Ty",
  //           date_of_joining: "2024-05-20",
  //         },
  //         {
  //           display_name: "as      ds sd",
  //           date_of_joining: "2024-05-02",
  //         },
  //         {
  //           display_name: "Toyota Win",
  //           date_of_joining: "2024-05-08",
  //         },
  //         {
  //           display_name: "gfd bvfd",
  //           date_of_joining: "2024-05-22",
  //         },
  //       ],
  //     },
  //     {
  //       user: "mivi",
  //       title: "Employee Joined Count",
  //       type: "bar",
  //       series: [
  //         {
  //           name: "Employee Count",
  //           data: [24, 0, 0, 74, 78, 0, 0, 0, 0, 0, 0, 0],
  //         },
  //       ],
  //       xaxis: {
  //         categories: [
  //           "Jan",
  //           "Feb",
  //           "Mar",
  //           "Apr",
  //           "May",
  //           "Jun",
  //           "Jul",
  //           "Aug",
  //           "Sep",
  //           "Oct",
  //           "Nov",
  //           "Dec",
  //         ],
  //       },
  //     },
  //     {
  //       user: "mivi",
  //       title: "Placements Started This Year",
  //       type: "column",
  //       series: [
  //         {
  //           name: "Placement Count",
  //           data: [0, 0, 1, 71, 3, 0, 1, 0, 0, 0, 0, 0],
  //         },
  //       ],
  //       xaxis: {
  //         categories: [
  //           "Jan",
  //           "Feb",
  //           "Mar",
  //           "Apr",
  //           "May",
  //           "Jun",
  //           "Jul",
  //           "Aug",
  //           "Sep",
  //           "Oct",
  //           "Nov",
  //           "Dec",
  //         ],
  //       },
  //       yaxis: {
  //         title: {
  //           text: "Number of Placements",
  //         },
  //       },
  //       //   tooltip: {
  //       //     y: {
  //       //       formatter: "function (val) { return val + ' placements' }",
  //       //     },
  //       //   },
  //     },
  //     {
  //       user: "mivi",
  //       title: "Month with Highest Placements",
  //       type: "text",
  //       message: "May had the highest total placements of 71",
  //     },
  //     {
  //       user: "mivi",
  //       title: "Employee Joining Statistics",
  //       type: "line",
  //       series: [
  //         {
  //           name: "Join Count",
  //           data: [24, 0, 0, 74, 78, 0, 0, 0, 0, 0, 0, 0],
  //         },
  //       ],
  //       xaxis: {
  //         categories: [
  //           "Jan",
  //           "Feb",
  //           "Mar",
  //           "Apr",
  //           "May",
  //           "Jun",
  //           "Jul",
  //           "Aug",
  //           "Sep",
  //           "Oct",
  //           "Nov",
  //           "Dec",
  //         ],
  //       },
  //     },
  //     {
  //       user: "mivi",
  //       title: "Employee Visa Distribution",
  //       type: "pie",
  //       series: [2, 29, 4, 4, 23, 10],
  //       labels: ["None", "H-1B", "Green Card", "STEM-OPT", "CPT", "OPT"],
  //     },
  //     {
  //       user: "mivi",
  //       title: "Employee Visa Distribution",
  //       type: "radial",
  //       series: [2, 29, 4, 4, 23, 10],
  //       labels: ["None", "H-1B", "Green Card", "STEM-OPT", "CPT", "OPT"],
  //     },
  //   ];

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleBack = () => {
    setMessages([]);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleNewQuestion = () => {
    if (newMessage.trim() !== "" && newMessage !== undefined) {
      setIsLoading(true);
      setMessages((prevstate) => [
        ...prevstate,
        {
          user: "user",
          type: "text",
          question: newMessage,
        },
      ]);
      setNewMessage("");
      scrollToBottom();
      Chatbot_API.FetchReply(newMessage).then((response) => {
        if (response.status === 200) {
          setMessages((prevstate) => [
            ...prevstate,
            {
              ...response.data.data,
              user: "mivi",
            },
          ]);
          setIsLoading(false);
          scrollToBottom();
          let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
          }
          DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
              setplanDetails(res.data);
              LocalStorage.setPlanCheckLocal(res.data)
              LocalStorage.setDateFormat(res.data.date_format);
            }
          })
        } else {
          setMessages((prevstate) => [
            ...prevstate,
            {
              user: "mivi",
              title: "Sorry!!",
              type: "text",
              message:
                "I'm not able to find the information you have asked, try rephrasing or ask in detail for more accurate responses.",
            },
          ]);
          setIsLoading(false);
        }
      });
      
      scrollToBottom();
    }
  };
  return (
    <>
      <Box
        className={classes.miviBox}
        sx={{
          height: "100%",
          justifyContent: "start !important",
          alignItems: "start",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: 3,
            height: "30px",
            paddingBottom: "10px",
            // borderBottom: "1px solid #000000"
          }}
        >
          <ArrowBack
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={handleBack}
          />
          <Text
            sx={{
              font: "14px Quicksand !important",
              fontSize: "14px",
              fontWeight: "600 !important",
            }}
            noWrap
          >
            {messages[0].question.length > 120 ?
              <HtmlTooltip title={messages[0].question} placement="right" arrow>
                {messages[0].question.slice(0, 120) + (messages[0].question.length > 120 ? "..." : "")}
              </HtmlTooltip>
              :
              messages[0].question}
          </Text>
        </Grid>
        <Grid
          container
          item
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "0px",
            overflow: "scroll",
            "&::-webkit-scrollbar": { display: "none", width: "0px" },
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column !important",
              gap: 3,
            }}
          >
            {messages.map((message) => {
              switch (message.user) {
                case "user":
                  return (
                    <RenderUserMessage
                      message={message}
                      setNewMessage={setNewMessage}
                    />
                  );
                case "mivi":
                  return <RenderMiviMessage message={message} />;
                default:
                  return <Text>No Information</Text>;
              }
            })}
            {isLoading ? (
              <Typing style={{ alignSelf: "end !important" }} />
            ) : null}
            <div ref={messagesEndRef} />
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Box
            className={classes.BootUpInputContainer}
            sx={{
              width: "100%",
              backgroundColor: "#ffffff",
              border: "1px solid #0C75EB",
            }}
          >
            <input
              className={classes.chatInput}
              placeholder="Ask Anything To Mivi"
              name="message"
              value={newMessage}
              onChange={handleChange}
              style={{ width: "100%", backgroundColor: "#ffffff" }}
              autoFocus="on"
              autoComplete="off"
              disabled={isLoading}
              onKeyDown={(e) => {
                if (e.code === "Enter" && newMessage !== "" && !isLoading) {
                  handleNewQuestion();
                }
              }}
            />
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "44px",
                height: "44px",
                backgroundColor: "#0C75EB",
                borderRadius: "8px",
              }}
              onClick={() => {
                if (!isLoading) {
                  handleNewQuestion();
                }
              }}
            >
              <SendIcon style={{ color: "#ffffff", fontSize: 20 }} />
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  );
}

const RenderUserMessage = ({ message, setNewMessage }) => {
  const classes = useStyles();
  const handleCopy = () => {
    setNewMessage(message.question)
    navigator.clipboard.writeText(message.question)
  }
  return (
    <Box className={classes.userBox}>
      <Avatar height="60px" width="60px" />
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Text
          smallGrey
          boldblack22
          sx={{
            fontSize: "12px !important",
            fontWeight: "700 !important",
          }}
        >
          You
        </Text>
        <Text
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            overflow: "hidden",
            whiteSpacing: "pre-wrap !important",
            // display: "inline-block",
            // position: "relative",
            // "@keyframes typing": {
            //     "0%": {
            //       width: "0%",
            //     },
            //     "100%": {
            //         width: "100%",
            //     },
            //   },
            //   animation: `typing 2s steps(40, end)`,
          }}
        >
          {message.question}
          <IconButton aria-label="copy" size="small" onClick={handleCopy}>
            <Tooltip id="button-report" title="Copy" placement="right">
              <CopyAllOutlined sx={{ height: "18px", width: "18px" }} />
            </Tooltip>
          </IconButton>
        </Text>
      </Grid>
    </Box>
  );
};

const CreateTable = ({ data }) => {
  function sanitizeString(str) {
    if (typeof str !== "string") return "";
    str = str.replace(/_/g, " ");
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const createColumns = (data) => {
    if (data.length === 0) return [];
    return Object.keys(data[0]).map((key) => ({
      field: key,
      headerName: sanitizeString(key),
      width: 150,
      resizable: true,
      editable: false,
      cellClassName: "super-app-theme--cell",
    }));
  };

  const createRows = (data) => {
    if (data.length === 0) return [];
    return data.map((row, index) => ({ ...row, id: index + 1 }));
  };

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .super-app-theme--Open": {
      backgroundColor: "#ffffff",
      "&:hover": {
        backgroundColor: "#E4E7EC",
      },
      //   '&.Mui-selected': {
      //     backgroundColor: getSelectedBackgroundColor(
      //       theme.palette.info.main,
      //       theme.palette.mode,
      //     ),
      //     '&:hover': {
      //       backgroundColor: getSelectedHoverBackgroundColor(
      //         theme.palette.info.main,
      //         theme.palette.mode,
      //       ),
      //     },
      //   },
    },
  }));

  return (
    <>
      {data.length !== 0 ? (
        <StyledDataGrid
          rows={createRows(data)}
          columns={createColumns(data)}
          getRowClassName={() => "super-app-theme--Open"}
          sx={{
            "& .super-app-theme--cell": {
              borderRight: "1px solid #E4E7EC",
              fontWeight: "600",
            },
          }}
        />
      ) : (
        <Text
          sx={{
            font: "18px Quicksand",
            fontWeight: 600,
            paddingBottom: "20px",
          }}
        >
          No infomation to display
        </Text>
      )}
    </>
  );
};

const CreateBarChart = ({ data }) => {
  const customOptions = {
    legend: {
      show: true,
    },

    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: data.xaxis.categories,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: "17%",
        endingShape: "rounded",
        // borderRadius:'20px'
      },
    },
    colors: ["#165DFF", "#14C9C9"],
    // stroke: {
    //   show: true,
    //   width: 2,
    //   colors: ["transparent"],
    // },
    // grid: {
    //   show: true,
    // },
  };
  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "8px" }}>
      <BarChart
        categories={data.xaxis.categories}
        series={data.series}
        options={customOptions}
        height="300px"
      />
    </Box>
  );
};

const CreateColumnChart = ({ data }) => {
  const customOptions = {
    legend: {
      show: true,
    },

    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: data.xaxis.categories,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "17%",
        endingShape: "rounded",
        // borderRadius:'20px'
      },
    },
    colors: ["#165DFF", "#14C9C9"],
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    grid: {
      show: true,
    },
  };
  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "8px" }}>
      <BarChart
        categories={data.xaxis.categories}
        series={data.series}
        options={customOptions}
        height="300px"
      />
    </Box>
  );
};

const CreateLineChart = ({ data }) => {
  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "8px" }}>
      <LineChart
        categories={data.xaxis.categories}
        series={data.series}
        //   options={customOptions}
        title={data.title}
        height="300px"
      />
    </Box>
  );
};

const CreatePieChart = ({ data }) => {
  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "8px" }}>
      <PieChart
        labels={data.labels}
        series={data.series}
        //   options={customOptions}
        title={data.title}
        height="400px"
      />
    </Box>
  );
};

const CreateRadialChart = ({ data }) => {
  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "8px" }}>
      <RadialChart
        labels={data.labels}
        series={data.series}
        //   options={customOptions}
        title={data.title}
        height="400px"
      />
    </Box>
  );
};

const RenderMiviMessage = ({ message }) => {
  const classes = useStyles();
  const renderView = (message) => {
    switch (message.type) {
      case "table":
        return (
          <Box
            sx={{
              height: "600px",
              display: "flex",
              flexDirection: "column",
              width: "90%",
              "& .super-app-theme--Open": {
                backgroundColor: "#ffffff",
                border: "1px solid #E4E7EC",
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "#ffffff",
              },
            }}
          >
            <Text
              sx={{
                font: "15px Quicksand",
                fontWeight: 600,
                paddingBottom: "20px",
              }}
            >
              {message.title} :
            </Text>
            <CreateTable data={message.data} />
          </Box>
        );
      case "bar":
        return (
          <Box
            sx={{
              //   height: "600px",
              display: "flex",
              flexDirection: "column",
              width: "90%",
            }}
          >
            <Text
              sx={{
                font: "15px Quicksand",
                fontWeight: 600,
                paddingBottom: "20px",
              }}
            >
              {message.title} :
            </Text>
            <CreateBarChart data={message} />
          </Box>
        );
      case "column":
        return (
          <Box
            sx={{
              //   height: "600px",
              display: "flex",
              flexDirection: "column",
              width: "90%",
            }}
          >
            <Text
              sx={{
                font: "15px Quicksand",
                fontWeight: 600,
                paddingBottom: "20px",
              }}
            >
              {message.title} :
            </Text>
            <CreateColumnChart data={message} />
          </Box>
        );
      case "text":
        return (
          <Box
            sx={{
              //   height: "600px",
              display: "flex",
              flexDirection: "column",
              width: "90%",
            }}
          >
            <Text
              sx={{
                font: "18px Quicksand",
                fontWeight: 600,
                paddingBottom: "20px",
              }}
            >
              {message.message}
            </Text>
          </Box>
        );
      case "line":
        return (
          <Box
            sx={{
              //   height: "600px",
              display: "flex",
              flexDirection: "column",
              width: "90%",
            }}
          >
            {/* <Text
              sx={{
                font: "15px Quicksand",
                fontWeight: 600,
                paddingBottom: "20px",
              }}
            >
              {message.title} :
            </Text> */}
            <CreateLineChart data={message} />
          </Box>
        );
      case "pie":
        return (
          <Box
            sx={{
              //   height: "600px",
              display: "flex",
              flexDirection: "column",
              width: "90%",
            }}
          >
            <Text
              sx={{
                font: "15px Quicksand",
                fontWeight: 600,
                paddingBottom: "20px",
              }}
            >
              {message.title} :
            </Text>
            <CreatePieChart data={message} />
          </Box>
        );
      case "radial":
        return (
          <Box
            sx={{
              //   height: "600px",
              display: "flex",
              flexDirection: "column",
              width: "90%",
            }}
          >
            <Text
              sx={{
                font: "15px Quicksand",
                fontWeight: 600,
                paddingBottom: "20px",
              }}
            >
              {message.title} :
            </Text>
            <CreateRadialChart data={message} />
          </Box>
        );
      default:
        return <div>404 Not Found</div>;
    }
  };
  return (
    <>
      <Box className={classes.miviMessageBox}>
        <Box
          className={classes.userBox}
          sx={{
            width: "100%",
            paddingLeft: "10px !important",
            paddingTop: "0px !important",
          }}
        >
          <LogoSvg height="55px" width="55px" />
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Text
              //   mediumBlack
              //   boldblack22
              sx={{
                fontSize: "12px !important",
                fontWeight: "700 !important",
              }}
            >
              Mivi
            </Text>
          </Grid>
        </Box>
        {renderView(message)}
      </Box>
    </>
  );
};
export default ChatBot;
