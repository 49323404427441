import React, { useState, useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Box, Stack, Grid, Checkbox, SwipeableDrawer, Divider, Menu, Backdrop, Badge, Chip, FormControlLabel, Slider } from '@mui/material';
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Skeleton from '@mui/material/Skeleton';
import Component87 from '../../../../assets/svg/Component87.svg';
import Search from '../../../../assets/svg/search1.svg';
import { addErrorMsg, addLoader, addLoaderPlanExpired, BlackToolTip, capitalizeAndAddSpace, NoDataFound, NoPermission, removeExpiredLoader, removeLoader } from '../../../../utils/utils';
import { useLocation, useNavigate } from "react-router-dom";
import Text from '../../../../components/customText/Text';
import Button from '../../../../components/customButton/Button';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/cross.svg';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LocalStorage from '../../../../utils/LocalStorage';
import MenuItem from '@mui/material/MenuItem';
import infoCircle from '../../../../assets/images/info-circle.png';
import ClearIcon from '@mui/icons-material/Clear';
import Loader from '../../../../assets/gif/japfuLoader.gif';
import FilterListIcon from '@mui/icons-material/FilterList';
import PlacementDashboardStyles from '../../placements/newDashboard/PlacementDashboardStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import nofilterplacement from '../../../../assets/svg/nofilterplacement.svg';
import CircleIcon from '@mui/icons-material/Circle';
import CommonApi from '../../../../apis/CommonApi';
import { domain } from '../../../../config/Domain';
import DashboardAPI from '../../../../apis/admin/DashboardAPI';
import Pending from '../../../../assets/employee/Orange-PendingIcon.svg';
import CustomCircularProgress from '../../../../components/progressbar/CircularProgress';
import Input from '../../../../components/input/Input';
import ExpenseManagement from '../../../../apis/admin/payroll/ExpenseManagement';
import { onNumberWithTwoDecimalOnlyChangeWithNegative } from '../../../../components/Validation';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));


export default function BalanceSheetDashboard() {

    const location = useLocation();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = PlacementDashboardStyles();
    const navigate = useNavigate();
    const [balanceData, setBalanceData] = useState([]);
    const [pagination, setPagination] = useState({ total: "6" });
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [filterData, setFilterData] = useState({
        limit: 10,
        page: 1,
        search: "",
        sort_column: "created_at",
        sort_order: "desc",
        employment_type: [],

        initial_balance_from: '',
        initial_balance_to: '',
        total_earning_from: '',
        total_earning_to: '',
        total_salary_from: '',
        total_salary_to: '',
        total_reimbursement_from: '',
        total_reimbursement_to: '',
        total_deduction_from: '',
        total_deduction_to: '',
        current_balance_from: '',
        current_balance_to: '',
        employee_id: LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id
    })

    const accordianHandler = (e, item) => {
        e.stopPropagation();
        navigate('/balanceSheet/index', { state: { data: item } })
    }
    const [filterAmount, setFilterAmount] = useState({
        total_salary: {
            max: 0,
            min: 0
        },
        total_earning: {
            max: 0,
            min: 0
        },
        current_balance: {
            max: 0,
            min: 0
        },
        initial_balance: {
            max: 0,
            min: 0
        },
        total_deduction: {
            max: 0,
            min: 0
        },
        total_reimbursement: {
            max: 0,
            min: 0
        }
    })
    const [filterState, setFilterState] = useState({
        employment_type: [],
        initial_balance_from: '',
        initial_balance_to: '',
        total_earning_from: '',
        total_earning_to: '',
        total_salary_from: '',
        total_salary_to: '',
        total_reimbursement_from: '',
        total_reimbursement_to: '',
        total_deduction_from: '',
        total_deduction_to: '',
        current_balance_from: '',
        current_balance_to: '',
        limit: 10,
        page: 1
    })
    const [datesError, setDatesError] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        date_of_expense_from: "",
        date_of_expense_to: "",
        raised_amount_from: "",
        raised_amount_to: "",
        approved_amount: "",
        reason_for_rejection: ""
    })
    const [openBacKLoader, setOpenBacKLoader] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [employmentType, setEmploymentType] = useState([]);

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) {
            employmentList()
            getAllBalanceSheet(filterData)
        }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        getPlanCheck();
        // eslint-disable-next-line
    }, [])

    const employmentList = () => {
        CommonApi.employmentTypesList(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setEmploymentType(res.data.data);
            }
        })
    }

    const getPlanCheck = () => {
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
            }
        })
    }

    const getAllBalanceSheet = (filter) => {
        if (balanceData.length < 6) {
            setLoading(true);
        }
        ExpenseManagement.getBalanceSheetListing(filter).then((response) => {
            removeLoader();
            setOpenBacKLoader(false);
            if (response.data.statusCode == 1003) {
                setDrawer(null);
                setLoading(false);
                setBalanceData(response.data.data);
                setPagination({ total: response.data.pagination.total });
                setFilterAmount(response.data.filters)
            } else {
                setLoading(false);
                if (response.data.message == `You don't have access to perform this action. Please contact admin`) {

                }
                else {
                    addErrorMsg(response.data.message);
                }
            }
        })

    }

    const handleSearch = (e) => {
        // setOpenBacKLoader(true);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = e.target.value;
        setFilterData({ ...filterData });
        setLoading(true);
        // addLoader(true);
        getAllBalanceSheet(filterData);

    }

    const handleSearchClear = () => {
        // setOpenBacKLoader(true);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = '';
        setFilterData({ ...filterData });
        getAllBalanceSheet(filterData);

    }

    const handleDeleteChip = (id, name) => {
        if (name === "initial_balance" || name === "total_earning" || name === "total_salary" || name === "total_reimbursement" || name === "total_deduction" || name === "current_balance") {
            const updatedFilterState = { ...filterState }; // Create a copy of the filterState object

            // Update the start_date or end_date fields
            updatedFilterState[name + "_from"] = "";
            updatedFilterState[name + "_to"] = "";

            // Update the state with the modified filterState object
            setFilterState(updatedFilterState);
        } else {
            const newFilterState = { ...filterState };
            const updatedArray = newFilterState[name].filter(item => item !== id);
            newFilterState[name] = updatedArray;
            setFilterState(newFilterState);
        }
    };

    const handleChangeCheckBox = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        const updatedArray = [...filterState[name]]; // Create a new array
        var finalValue = value
        if (e.target.name != "status") {
            finalValue = Number(value)
        }
        const index = updatedArray.findIndex(item => item === finalValue);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(finalValue); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const clearAllFilter = () => {
        datesError.start_date_from = '';
        datesError.start_date_to = '';
        datesError.end_date_from = '';
        datesError.end_date_to = '';
        setDatesError({ ...datesError });
        setFilterState(prevState => ({
            ...prevState,
            employment_type: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            initial_balance_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            initial_balance_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            total_earning_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            total_earning_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            total_salary_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            total_salary_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            total_reimbursement_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            total_reimbursement_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            total_deduction_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            total_deduction_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            current_balance_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            current_balance_to: '' // Update the state with the new array
        }));
    }

    const cancelFilter = () => {
        setDrawer(false);
    }

    const handleListItemClick = (param) => {
        setSelectedFilter(param);
    }

    const handleApplyFilters = () => {
        if (filterState.initial_balance_from != "" && filterState.initial_balance_to == "") {
            datesError.initial_balance_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.initial_balance_from == "" && filterState.initial_balance_from != 0) && filterState.initial_balance_to != "") {
            datesError.initial_balance_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.initial_balance_from !== "" && filterState.initial_balance_to !== "") {
            if (filterState.initial_balance_from < filterState.initial_balance_to) {
                filterData.initial_balance_from = filterState.initial_balance_from;
                filterData.initial_balance_to = filterState.initial_balance_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.initial_balance_from = '';
            filterData.initial_balance_to = '';
        }
        if (filterState.total_earning_from != "" && filterState.total_earning_to == "") {
            datesError.total_earning_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.total_earning_from == "" && filterState.total_earning_from != 0) && filterState.total_earning_to != "") {
            datesError.total_earning_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.total_earning_from !== "" && filterState.date_of_exptotal_earning_toense_to !== "") {
            if (filterState.total_earning_from < filterState.total_earning_to) {
                filterData.total_earning_from = filterState.total_earning_from;
                filterData.total_earning_to = filterState.total_earning_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.total_earning_from = '';
            filterData.total_earning_to = '';
        }
        if (filterState.total_salary_from != "" && filterState.total_salary_to == "") {
            datesError.total_salary_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.total_salary_from == "" && filterState.total_salary_from != 0) && filterState.total_salary_to != "") {
            datesError.total_salary_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.total_salary_from !== "" && filterState.total_salary_to !== "") {
            if (filterState.total_salary_from < filterState.total_salary_to) {
                filterData.total_salary_from = filterState.total_salary_from;
                filterData.total_salary_to = filterState.total_salary_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.total_salary_from = '';
            filterData.total_salary_to = '';
        }
        if (filterState.total_reimbursement_from != "" && filterState.total_reimbursement_to == "") {
            datesError.total_reimbursement_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.total_reimbursement_from == "" && filterState.total_reimbursement_from != 0) && filterState.total_reimbursement_to != "") {
            datesError.total_reimbursement_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.total_reimbursement_from !== "" && filterState.total_reimbursement_to !== "") {
            if (filterState.total_reimbursement_from < filterState.total_reimbursement_to) {
                filterData.total_reimbursement_from = filterState.total_reimbursement_from;
                filterData.total_reimbursement_to = filterState.total_reimbursement_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.total_reimbursement_from = '';
            filterData.total_reimbursement_to = '';
        }
        if (filterState.total_deduction_from != "" && filterState.total_deduction_to == "") {
            datesError.total_deduction_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.total_deduction_from == "" && filterState.total_deduction_from != 0) && filterState.total_deduction_to != "") {
            datesError.total_deduction_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.total_deduction_from !== "" && filterState.total_deduction_to !== "") {
            if (filterState.total_deduction_from < filterState.total_deduction_to) {
                filterData.total_deduction_from = filterState.total_deduction_from;
                filterData.total_deduction_to = filterState.total_deduction_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.total_deduction_from = '';
            filterData.total_deduction_to = '';
        }
        if (filterState.current_balance_from != "" && filterState.current_balance_to == "") {
            datesError.current_balance_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.current_balance_from == "" && filterState.current_balance_from != 0) && filterState.current_balance_to != "") {
            datesError.current_balance_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.current_balance_from !== "" && filterState.current_balance_to !== "") {
            if (filterState.current_balance_from < filterState.current_balance_to) {
                filterData.current_balance_from = filterState.current_balance_from;
                filterData.current_balance_to = filterState.current_balance_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.current_balance_from = '';
            filterData.current_balance_to = '';
        }
        filterData.employment_type = filterState.employment_type;
        filterData.current_balance = [filterState.current_balance_from, filterState.current_balance_to]
        filterData.initial_balance = [filterState.initial_balance_from, filterState.initial_balance_to]
        filterData.total_earning = [filterState.total_earning_from, filterState.total_earning_to]
        filterData.total_salary = [filterState.total_salary_from, filterState.total_salary_to]
        filterData.total_reimbursement = [filterState.total_reimbursement_from, filterState.total_reimbursement_to]
        filterData.total_deduction = [filterState.total_deduction_from, filterState.total_deduction_to]
        if (filterData.current_balance.length === 2 && filterData.current_balance[0] === "" && filterData.current_balance[1] === "") {
            filterData.current_balance = [0, 0]; // Change to an empty array
        }
        if (filterData.initial_balance.length === 2 && filterData.initial_balance[0] === "" && filterData.initial_balance[1] === "") {
            filterData.initial_balance = [0, 0]; // Change to an empty array
        }
        if (filterData.total_earning.length === 2 && filterData.total_earning[0] === "" && filterData.total_earning[1] === "") {
            filterData.total_earning = [0, 0]; // Change to an empty array
        }
        if (filterData.total_salary.length === 2 && filterData.total_salary[0] === "" && filterData.total_salary[1] === "") {
            filterData.total_salary = [0, 0]; // Change to an empty array
        }
        if (filterData.total_reimbursement.length === 2 && filterData.total_reimbursement[0] === "" && filterData.total_reimbursement[1] === "") {
            filterData.total_reimbursement = [0, 0]; // Change to an empty array
        }
        if (filterData.total_deduction.length === 2 && filterData.total_deduction[0] === "" && filterData.total_deduction[1] === "") {
            filterData.total_deduction = [0, 0]; // Change to an empty array
        }
        setFilterData({ ...filterData });
        addLoader(true)
        getAllBalanceSheet({ ...filterData, limit: 10, page: 1 });
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const openFilter = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const handleSort = (sort_column, sort_order) => {
        setAnchorEl(null);
        setOpenBacKLoader(true);
        filterData.sort_column = sort_column;
        filterData.sort_order = sort_order;
        setFilterData({ ...filterData });
        setLoading(true);
        getAllBalanceSheet({ ...filterData });
        // setOpenBacKLoader(false);

    }

    const loadMoreData = () => {
        // addLoader(true);
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
        getAllBalanceSheet(filterData);

    }

    const handleOpenDrawer = () => {
        setDrawer("filter");
        if (filterData.initial_balance_from !== "" && filterData.initial_balance_to !== "") {
            filterState.initial_balance_from = filterData.initial_balance_from;
            filterState.initial_balance_to = filterData.initial_balance_to;
            setSelectedFilter(1);
        } else {
            filterState.initial_balance_from = '';
            filterState.initial_balance_to = '';
        }
        if (filterData.total_earning_from !== "" && filterData.total_earning_to !== "") {
            filterState.total_earning_from = filterData.total_earning_from;
            filterState.total_earning_to = filterData.total_earning_to;
            setSelectedFilter(2);
        } else {
            filterState.total_earning_from = '';
            filterState.total_earning_to = '';
        }
        if (filterData.total_salary_from !== "" && filterData.total_salary_to !== "") {
            filterState.total_salary_from = filterData.total_salary_from;
            filterState.total_salary_to = filterData.total_salary_to;
            setSelectedFilter(3);
        } else {
            filterState.total_salary_from = '';
            filterState.total_salary_to = '';
        }
        if (filterData.total_reimbursement_from !== "" && filterData.total_reimbursement_to !== "") {
            filterState.total_reimbursement_from = filterData.total_reimbursement_from;
            filterState.total_reimbursement_to = filterData.total_reimbursement_to;
            setSelectedFilter(4);
        } else {
            filterState.total_reimbursement_from = '';
            filterState.total_reimbursement_to = '';
        }
        if (filterData.total_deduction_from !== "" && filterData.total_deduction_to !== "") {
            filterState.total_deduction_from = filterData.total_deduction_from;
            filterState.total_deduction_to = filterData.total_deduction_to;
            setSelectedFilter(5);
        } else {
            filterState.total_deduction_from = '';
            filterState.total_deduction_to = '';
        }
        if (filterData.current_balance_from !== "" && filterData.current_balance_to !== "") {
            filterState.current_balance_from = filterData.current_balance_from;
            filterState.current_balance_to = filterData.current_balance_to;
            setSelectedFilter(6);
        } else {
            filterState.current_balance_from = '';
            filterState.current_balance_to = '';
        }
        if (filterData.employment_type.length > 0) {
            setSelectedFilter(7);
        }
        filterState.employment_type = filterData.employment_type;
        setFilterState({ ...filterState });
        datesError.start_date_to = "";
        datesError.end_date_to = "";
        setDatesError({ ...datesError })
    }

    const scrollBox = useRef(null);

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(pagination.total) >= filterData.limit) {
                    setOpenBacKLoader(true);
                    loadMoreData();
                }
            } else {
            }
        }
    };

    const handleSliderChangeRaised = (event, newValue) => {
        filterState.current_balance_from = newValue[0]
        filterState.current_balance_to = newValue[1]
        setFilterState({ ...filterState })
    };
    const handleSliderChangeTotalDeduction = (event, newValue) => {
        filterState.total_deduction_from = newValue[0]
        filterState.total_deduction_to = newValue[1]
        setFilterState({ ...filterState })
    };
    const handleSliderChangeTotalReimbursement = (event, newValue) => {
        filterState.total_reimbursement_from = newValue[0]
        filterState.total_reimbursement_to = newValue[1]
        setFilterState({ ...filterState })
    };
    const handleSliderChangeSalary = (event, newValue) => {
        filterState.total_salary_from = newValue[0]
        filterState.total_salary_to = newValue[1]
        setFilterState({ ...filterState })
    };
    const handleSliderChangeTotalEarning = (event, newValue) => {
        filterState.total_earning_from = newValue[0]
        filterState.total_earning_to = newValue[1]
        setFilterState({ ...filterState })
    };
    const handleSliderChangeInitial = (event, newValue) => {
        filterState.initial_balance_from = newValue[0]
        filterState.initial_balance_to = newValue[1]
        setFilterState({ ...filterState })
    };


    const handleInputChange = (index, value, name, type) => {
        let numericValue;

        const baseName = name ? name.replace(/_from|_to$/, '') : '';
        const limitValue = type === "min"
            ? filterAmount[baseName].min
            : filterAmount[baseName].max;

        const maxValue = filterAmount[baseName].max;

        if (value === '-' || value === '' || value.endsWith('.')) {
            numericValue = value;
        } else if (!isNaN(Number(value))) {
            numericValue = Number(value);

            if (type === "min" && numericValue > maxValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), maxValue); // Ensure it stays above maxValue
            }
            else if (type === "min" && numericValue < limitValue) {
                numericValue = Math.max(Math.floor(numericValue / 10), limitValue); // Ensure it stays above minValue
            }
            if (type === "max" && numericValue > maxValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), maxValue); // Ensure it stays above maxValue
            } 
            else if (type === "max" && numericValue > limitValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), limitValue); // Ensure it stays below maxValue
            }
        } else {
            // Fallback to empty string for invalid input
            numericValue = '';
        }

        filterState[name] = numericValue;
        setFilterState({ ...filterState });

    };


    const handleFilterDrawerClose = () => {
        filterState.employment_type = []
        filterState.initial_balance_from = '';
        filterState.initial_balance_to = '';
        filterState.total_earning_from = '';
        filterState.total_earning_to = '';
        filterState.total_salary_from = '';
        filterState.total_salary_to = '';
        filterState.total_reimbursement_from = '';
        filterState.total_reimbursement_to = '';
        filterState.total_deduction_from = '';
        filterState.total_deduction_to = '';
        filterState.current_balance_from = '';
        filterState.current_balance_to = '';
        setFilterState({ ...filterState });
        setDrawer(null);
    }


    return (
        <>
            <Grid container pl={'130px'} pr={'65px'}>
                <Grid item xs={12}>
                    <Text mediumViewAmt>Balance Sheet</Text>
                </Grid>
            </Grid>
            <Box className={classes.flexBox} style={{ paddingLeft: '65px' }}>

                <Box sx={{ width: "92%" }} >
                    <Box style={{ padding: "20px 10px 10px 10px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <Box sx={{
                                    height: "44px", border: "1.5px solid #E2E5E6", width: "368px", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center",
                                    "@media (min-width: 900px) and (max-width: 1400px)": {
                                        width: "258px"
                                    },
                                }}>
                                    <input
                                        type="text"
                                        value={filterData.search}
                                        className={classes.EmployeesSearchInput}
                                        onChange={handleSearch}
                                        placeholder="Search by Name / ID"
                                    />
                                    <Box sx={{ paddingRight: "15px !important" }}>
                                        {
                                            filterData.search !== "" ?
                                                <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                :
                                                <img src={Search} alt="Search" />
                                        }
                                    </Box>
                                </Box>
                            </div>
                            {
                                ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                                    <div>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingRight: "5px" }}>
                                            <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5} display={"flex"} justifyContent={"center"}>
                                                <Text offBoardBodyFont>
                                                    Count
                                                    <HtmlTooltip
                                                        placement="bottom"
                                                        arrow
                                                        title={
                                                            <React.Fragment>
                                                                <Box>
                                                                    <Typography className={classes.profileTooltipText}>
                                                                        Total Employee count is <br />based on the filters applied
                                                                    </Typography>
                                                                </Box>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                                    </HtmlTooltip>
                                                    {` - ${pagination ? pagination.total ? pagination.total : 0 : 0} Employees`}</Text>
                                            </Grid>
                                            <button title="Filter"
                                                type="button"
                                                className={classes.borderButton}
                                                onClick={() => { handleOpenDrawer() }}>
                                                <Badge badgeContent={filterData.employment_type.length + ((filterData.initial_balance_from !== "" && filterData.initial_balance_to !== "") && 1) + ((filterData.total_earning_from !== "" && filterData.total_earning_to !== "") && 1) + ((filterData.total_salary_from !== "" && filterData.total_salary_to !== "") && 1) + ((filterData.total_reimbursement_from !== "" && filterData.total_reimbursement_to !== "") && 1) + ((filterData.total_deduction_from !== "" && filterData.total_deduction_to !== "") && 1) + ((filterData.current_balance_from !== "" && filterData.current_balance_to !== "") && 1)} color="error">
                                                    <FilterListIcon sx={{ color: (filterData.employment_type.length > 0 || (filterData.initial_balance_from !== "" && filterData.initial_balance_to !== "") || (filterData.total_earning_from !== "" && filterData.total_earning_to !== "") || (filterData.total_salary_from !== "" && filterData.total_salary_to !== "") || (filterData.total_reimbursement_from !== "" && filterData.total_reimbursement_to !== "") || (filterData.total_deduction_from !== "" && filterData.total_deduction_to !== "") || (filterData.current_balance_from !== "" && filterData.current_balance_to !== "")) ? "#2BEA2B" : "" }} />
                                                </Badge>
                                            </button>
                                        </div>
                                    </div>
                                    : ""
                            }
                        </div>

                    </Box>
                    {
                        ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                            <Box style={{ padding: "10px" }}>

                                <Box sx={{
                                    display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px",
                                    "@media (min-width: 200px) and (max-width: 1400px)": {
                                        font: "12px Quicksand",
                                        fontWeight: "600",
                                        paddingTop: '1px'
                                    },
                                }} >
                                    <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                        <Grid item lg={3} md={3} sm={3} xs={3} textAlign={'left'} letterSpacing={'0.5px'}>
                                            Employee Name
                                            <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} onClick={handleClick}>
                                                <FilterListIcon sx={{ height: "19px", color: (filterData.sort_column == "name" || filterData.sort_column == "created_at") ? "#2BEA2B" : "" }} />
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                id="account-menu"
                                                open={openFilter}
                                                onClose={handleCloseFilter}
                                                onClick={handleCloseFilter}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: "visible",
                                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                        mt: 0.5,
                                                        ml: -2,
                                                        padding: "5px",
                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: "#EAECF0 !important",
                                                        }
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                            >
                                                <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'created_at' && filterData.sort_order == 'desc' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'desc')}> Newest First</MenuItem>
                                                <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'created_at' && filterData.sort_order == 'asc' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'asc')}> Oldest First</MenuItem>
                                                <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'name' && filterData.sort_order == 'asc' ? '#EAECF0' : "" }} onClick={() => handleSort('name', 'asc')}> Name (A-Z)</MenuItem>
                                                <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'name' && filterData.sort_order == 'desc' ? '#EAECF0' : "" }} onClick={() => handleSort('name', 'desc')}> Name (Z-A)</MenuItem>
                                            </Menu>
                                        </Grid>
                                        <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerEndDate} >Initial Opening Balance</Grid>
                                        <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerEndDate} >Total Earnings</Grid>
                                        <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerEndDate} >Total Salary</Grid>
                                        <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerEndDate} >Total Reimbursement</Grid>
                                        <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerEndDate} >Total Deductions</Grid>
                                        <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerEndDate} >Current Balance</Grid>
                                    </Grid>
                                </Box>

                                {
                                    loading ?
                                        [1, 2, 3, 4].map((item) => (
                                            <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                    <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <Box>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        </Box>
                                                        <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        )) :
                                        <>
                                            {
                                                balanceData.length > 0 ?
                                                    <Box sx={{
                                                        height: "62vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
                                                        paddingLeft: '5px', paddingRight: '5px',
                                                        "@media (min-height: 850px)": {
                                                            height: '73.5vh'
                                                        }
                                                    }} ref={scrollBox} onScroll={handleScroll}>
                                                        {
                                                            balanceData.map((place, key) => (
                                                                <Accordion
                                                                    onClick={(e) => accordianHandler(e, place)}
                                                                    key={key}
                                                                    className={classes.newCustomAccordion}
                                                                >
                                                                    <AccordionSummary
                                                                        aria-controls="panel1bh-content"
                                                                        id="panel1bh-header"
                                                                        className={classes.AccordionSummary}
                                                                    >
                                                                        <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                                            <Grid item lg={3} md={3} sm={3} xs={3} textAlign='start' display={'flex'} flexDirection={'row'}>
                                                                                <Box display='flex' alignItems='center' gap={2}>
                                                                                    <CustomCircularProgress
                                                                                        percentage={place.profile_percentage}
                                                                                        color={place.profile_percentage >= 76 ? 'green' : place.profile_percentage <= 75 && place.profile_percentage >= 51 ? 'yellow' : place.profile_percentage <= 50 ? "#FFBF00" : ''}
                                                                                        src={place.profile_picture_url}
                                                                                        name={place.profile_picture_url == undefined || place.profile_picture_url == null || place.profile_picture_url == '' ? place.employee_name && capitalizeAndAddSpace(place.employee_name[0]) : ''}
                                                                                        imgWidth='50px !important'
                                                                                        imgHeight='50px !important'
                                                                                        avatarWidth='50px !important'
                                                                                        avatarHeight='50px !important'
                                                                                        value={place.profile_percentage}
                                                                                        size={58}
                                                                                        tooltip
                                                                                        tooltipContent={
                                                                                            <Box p={'3px 0px'} width='100%'>
                                                                                                <Text mediumBoldWhite400>{`Profile Completion - ${place.profile_percentage}%`}</Text>
                                                                                            </Box>
                                                                                        }
                                                                                    />
                                                                                    <Box>
                                                                                        {
                                                                                            place.status == 'In Active' ?
                                                                                                <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                                    {place.employee_name === "" ? "--" : capitalizeAndAddSpace(place.employee_name).length > 16 ?
                                                                                                        <HtmlTooltip title={capitalizeAndAddSpace(place.employee_name)} placement="right" arrow>
                                                                                                            {capitalizeAndAddSpace(place.employee_name).slice(0, 16) + (capitalizeAndAddSpace(place.employee_name).length > 16 ? "..." : "")}
                                                                                                        </HtmlTooltip>
                                                                                                        :
                                                                                                        capitalizeAndAddSpace(place.employee_name)
                                                                                                    }
                                                                                                    {place.employee_e_verified == 1 || place.employee_e_verified == 4 ?
                                                                                                        <HtmlTooltip title={<Text smallWhite>E-verified</Text>} placement="right" arrow>
                                                                                                            <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                        </HtmlTooltip>
                                                                                                        : place.employee_e_verified == 2 ?
                                                                                                            <HtmlTooltip title={<Text smallWhite>E-Verification is pending</Text>} placement="right" arrow>
                                                                                                                <img src={Pending} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                            </HtmlTooltip> : ''
                                                                                                    }
                                                                                                </Text>
                                                                                                :
                                                                                                <Text mediumBoldBlack noWrap>
                                                                                                    {place.employee_name === "" ? "--" :
                                                                                                        capitalizeAndAddSpace(place.employee_name).length > 16 ?
                                                                                                            <HtmlTooltip title={capitalizeAndAddSpace(place.employee_name)} placement="right" arrow>
                                                                                                                {capitalizeAndAddSpace(place.employee_name).slice(0, 16) + (capitalizeAndAddSpace(place.employee_name).length > 16 ? "..." : "")}
                                                                                                            </HtmlTooltip>
                                                                                                            :
                                                                                                            capitalizeAndAddSpace(place.employee_name)
                                                                                                    }
                                                                                                    {place.employee_e_verified == 1 || place.employee_e_verified == 4 ?
                                                                                                        <HtmlTooltip title={<Text smallWhite>E-Verified</Text>} placement="right" arrow>
                                                                                                            <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                        </HtmlTooltip>
                                                                                                        : place.employee_e_verified == 2 ?
                                                                                                            <HtmlTooltip title={<Text smallWhite>E-Verification is pending</Text>} placement="right" arrow>
                                                                                                                <img src={Pending} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                            </HtmlTooltip> : ''
                                                                                                    }
                                                                                                </Text>
                                                                                        }
                                                                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center' p={'4px 0px'}>
                                                                                            <Text greyLabel> {place.employee_reference_id ? place.employee_reference_id : "--"}</Text>
                                                                                            {
                                                                                                place.employment_type ?
                                                                                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                                                                        <Box sx={{ height: '4px', width: '4px', background: '#C7CCD3', borderRadius: '50%' }} />
                                                                                                        <Text greyLabel> {place.employment_type}</Text>
                                                                                                    </Box> : ''
                                                                                            }
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerNames}><Text mediumBlack14>{place.initial_balance}</Text></Grid>
                                                                            <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerNames} pl={.2}><Text mediumBlack14>{place.total_earning}</Text></Grid>
                                                                            <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerNames} pl={1}><Text mediumBlack14>{place.total_salary}</Text></Grid>
                                                                            <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerNames} pl={2.5}><Text mediumBlack14>{place.total_reimbursement}</Text></Grid>
                                                                            <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerNames} pl={3.2}><Text mediumBlack14>{place.total_deduction}</Text></Grid>
                                                                            <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerNames} pl={3.4}><Text mediumBlack14>{place.current_balance}</Text></Grid>
                                                                        </Grid>
                                                                    </AccordionSummary>
                                                                </Accordion>
                                                            ))
                                                        }
                                                    </Box>
                                                    :
                                                    balanceData.length == 0 ?
                                                        <Box>
                                                            {NoDataFound(`No data found`)}
                                                        </Box>
                                                        : ''
                                            }

                                        </>
                                }
                            </Box> :
                            <>
                                {NoPermission()}
                            </>
                    }
                </Box>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openBacKLoader}
                // onClick={handleClose}
                >
                    <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                </Backdrop>

                <SwipeableDrawer
                    anchor={'right'}
                    open={drawer}
                    onClose={() => handleFilterDrawerClose()}
                    transitionDuration={300}
                    sx={{
                        ".MuiDrawer-paper ": {
                            borderRadius: '8px 0px 0px 8px !important',
                        },
                        "& .MuiBackdrop-root.MuiModal-backdrop": {
                            backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                        }
                    }
                    }
                >
                    {
                        drawer === "filter" ?
                            <Box width={'660px'} height={'100vh'} >
                                <Box height={'10vh'} borderBottom={'1px solid #EAECF0'} display={'flex'} alignItems={'center'} pl={4} >
                                    <Text headerBlack>Filters</Text>
                                </Box>

                                <Box display={'flex'} height={'7vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                                    <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={1} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>
                                        {
                                            filterState.initial_balance_from !== "" && filterState.initial_balance_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.initial_balance_from} - {filterState.initial_balance_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "initial_balance")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            filterState.total_earning_from !== "" && filterState.total_earning_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.total_earning_from} - {filterState.total_earning_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "total_earning")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            filterState.total_salary_from !== "" && filterState.total_salary_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.total_salary_from} - {filterState.total_salary_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "total_salary")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            filterState.total_reimbursement_from !== "" && filterState.total_reimbursement_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#2DC890 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.total_reimbursement_from} - {filterState.total_reimbursement_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "total_reimbursement")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            filterState.total_deduction_from !== "" && filterState.total_deduction_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.total_deduction_from} - {filterState.total_deduction_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "total_deduction")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            filterState.current_balance_from !== "" && filterState.current_balance_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.current_balance_from} - {filterState.current_balance_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "current_balance")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            employmentType.map((item, key) => (
                                                filterState.employment_type.includes(item.id) &&
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={key}
                                                    icon={<CircleIcon sx={{ color: "#2DC890 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack nowrap>{
                                                        item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                            <BlackToolTip title={item.value} placement="right" arrow>
                                                                {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                            </BlackToolTip>
                                                            :
                                                            item.value
                                                    }</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip(item.id, "employment_type")}
                                                    deleteIcon={<CloseIcon />}
                                                />
                                            ))
                                        }
                                    </Box>
                                    {
                                        ((filterState.initial_balance_from !== "" && filterState.initial_balance_to !== "") || (filterState.total_earning_from !== "" && filterState.total_earning_to !== "") || (filterState.total_salary_from !== "" && filterState.total_salary_to !== "") || (filterState.total_reimbursement_from !== "" && filterState.total_reimbursement_to !== "") || (filterState.total_deduction_from !== "" && filterState.total_deduction_to !== "") || filterState.employment_type.length > 0 || (filterState.current_balance_from !== "" && filterState.current_balance_to !== "")) ?
                                            <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                            :
                                            ''
                                    }
                                </Box>

                                <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'73vh'} >
                                    <Box display={'flex'} flexDirection={'column'} height={'100%'} width={'43%'} borderRight={'1px solid #EAECF0'} pt={0} >
                                        <List component="nav" aria-label="secondary mailbox folder" sx={{ overflowY: 'scroll' }}>
                                            <ListItem className={selectedFilter == 1 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(1)} secondaryAction={(filterState.initial_balance_from !== "" && filterState.initial_balance_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{(filterState.initial_balance_from !== "" && filterState.initial_balance_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 1 ? <Text ml={3} smallBlue1>Initial Opening Balance</Text> : <Text ml={3} mediumBlack>Initial Opening Balance</Text>} />
                                            </ListItem>
                                            <Divider className={classes.divider} />
                                            <ListItem className={selectedFilter == 2 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(2)} secondaryAction={(filterState.total_earning_from !== "" && filterState.total_earning_to !== "") > 0 ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{(filterState.total_earning_from !== "" && filterState.total_earning_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 2 ? <Text ml={3} smallBlue1>Total Earnings</Text> : <Text ml={3} mediumBlack>Total Earnings</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 3 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(3)} secondaryAction={(filterState.total_salary_from !== "" && filterState.total_salary_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{(filterState.total_salary_from !== "" && filterState.total_salary_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 3 ? <Text ml={3} smallBlue1> Total Salary</Text> : <Text ml={3} mediumBlack> Total Salary</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 4 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(4)} secondaryAction={(filterState.total_reimbursement_from !== "" && filterState.total_reimbursement_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#2DC890" }}><Text mediumWhite400>{(filterState.total_reimbursement_from !== "" && filterState.total_reimbursement_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 4 ? <Text ml={3} smallBlue1>Total Reimbursement</Text> : <Text ml={3} mediumBlack>Total Reimbursement</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 5 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(5)} secondaryAction={(filterState.total_deduction_from !== "" && filterState.total_deduction_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{(filterState.total_deduction_from !== "" && filterState.total_deduction_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 5 ? <Text ml={3} smallBlue1>Total Deductions</Text> : <Text ml={3} mediumBlack>Total Deductions</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 6 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(6)} secondaryAction={(filterState.current_balance_from !== "" && filterState.current_balance_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{(filterState.current_balance_from !== "" && filterState.current_balance_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 6 ? <Text ml={3} smallBlue1>Current Balance</Text> : <Text ml={3} mediumBlack>Current Balance</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 7 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(7)} secondaryAction={filterState.employment_type.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{filterState.employment_type.length}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 7 ? <Text ml={3} smallBlue1> Employement Type</Text> : <Text ml={3} mediumBlack> Employement Type</Text>} />
                                            </ListItem>
                                            <Divider />
                                        </List>
                                    </Box>
                                    <Box display={'flex'} sx={{ overflow: "auto" }} flexDirection={'column'} height={'70vh'} width={'55%'} pl={'25px !important'} pt={2}>

                                        {
                                            selectedFilter == 1 ?
                                                <>
                                                    {
                                                        <Grid container alignItems={"center"} pb={2}>
                                                            <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                <Box sx={{ width: 300, margin: "auto" }}>
                                                                    <Text mediumBlack14 pb={1}>
                                                                        Select Pay Range
                                                                    </Text>
                                                                    <Slider
                                                                        value={[filterState.initial_balance_from, filterState.initial_balance_to]}
                                                                        onChange={handleSliderChangeInitial}
                                                                        valueLabelDisplay="auto"
                                                                        min={filterAmount.initial_balance.min}
                                                                        max={filterAmount.initial_balance.max}
                                                                        sx={{ mb: 2 }}
                                                                    />
                                                                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                        <Input
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            inputProps={{
                                                                                name: 'number',
                                                                                value: filterState.initial_balance_from,
                                                                                inputProps: { maxLength: 50 },
                                                                            }}
                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                            handleChange={(e) => handleInputChange(0, e.target.value, 'initial_balance_from', 'min')}
                                                                            clientInput
                                                                            labelText={'$ Min'}
                                                                            size="small"
                                                                            sx={{ width: "35%" }}
                                                                        />
                                                                        <Input
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            inputProps={{
                                                                                name: 'number',
                                                                                value: filterState.initial_balance_to,
                                                                            }}
                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                            handleChange={(e) => handleInputChange(1, e.target.value, 'initial_balance_to', 'max')}
                                                                            clientInput
                                                                            labelText={'$ Max'}
                                                                            size="small"
                                                                            sx={{ width: "35%" }}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </>
                                                :
                                                selectedFilter == 2 ?
                                                    <>
                                                        {
                                                            <Grid container alignItems={"center"} pb={2}>
                                                                <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                    <Box sx={{ width: 300, margin: "auto" }}>
                                                                        <Text mediumBlack14 pb={1}>
                                                                            Select Pay Range
                                                                        </Text>
                                                                        <Slider
                                                                            value={[filterState.total_earning_from, filterState.total_earning_to]}
                                                                            onChange={handleSliderChangeTotalEarning}
                                                                            valueLabelDisplay="auto"
                                                                            min={filterAmount.total_earning.min}
                                                                            max={filterAmount.total_earning.max}
                                                                            sx={{ mb: 2 }}
                                                                        />
                                                                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                            <Input
                                                                                formControlProps={{
                                                                                    fullWidth: true
                                                                                }}
                                                                                inputProps={{
                                                                                    name: 'total_earning_from',
                                                                                    value: filterState.total_earning_from,
                                                                                    inputProps: { maxLength: 50 },
                                                                                }}
                                                                                onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                handleChange={(e) => handleInputChange(0, e.target.value, 'total_earning_from', 'min')}
                                                                                clientInput
                                                                                labelText={'$ Min'}
                                                                                size="small"
                                                                                sx={{ width: "35%" }}
                                                                            />
                                                                            <Input
                                                                                formControlProps={{
                                                                                    fullWidth: true
                                                                                }}
                                                                                inputProps={{
                                                                                    name: 'total_earning_to',
                                                                                    value: filterState.total_earning_to,
                                                                                }}
                                                                                onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                handleChange={(e) => handleInputChange(1, e.target.value, 'total_earning_to', 'max')}
                                                                                clientInput
                                                                                labelText={'$ Max'}
                                                                                size="small"
                                                                                sx={{ width: "35%" }}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                    :
                                                    selectedFilter == 3 ?
                                                        <>
                                                            {
                                                                <Grid container alignItems={"center"} pb={2}>
                                                                    <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                        <Box sx={{ width: 300, margin: "auto" }}>
                                                                            <Text mediumBlack14 pb={1}>
                                                                                Select Amount Range
                                                                            </Text>
                                                                            <Slider
                                                                                value={[filterState.total_salary_from, filterState.total_salary_to]}
                                                                                onChange={handleSliderChangeSalary}
                                                                                valueLabelDisplay="auto"
                                                                                min={filterAmount.total_salary.min}
                                                                                max={filterAmount.total_salary.max}
                                                                                sx={{ mb: 2 }}
                                                                            />
                                                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                <Input
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    inputProps={{
                                                                                        name: 'total_salary_from',
                                                                                        value: filterState.total_salary_from,
                                                                                        inputProps: { maxLength: 50 },
                                                                                    }}
                                                                                    onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                    handleChange={(e) => handleInputChange(0, e.target.value, 'total_salary_from', 'min')}
                                                                                    clientInput
                                                                                    labelText={'$ Min'}
                                                                                    size="small"
                                                                                    sx={{ width: "35%" }}
                                                                                />
                                                                                <Input
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    inputProps={{
                                                                                        name: 'total_salary_to',
                                                                                        value: filterState.total_salary_to,
                                                                                    }}
                                                                                    onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                    handleChange={(e) => handleInputChange(1, e.target.value, 'total_salary_to', 'max')}
                                                                                    clientInput
                                                                                    labelText={'$ Max'}
                                                                                    size="small"
                                                                                    sx={{ width: "35%" }}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        </>
                                                        :
                                                        selectedFilter == 4 ?
                                                            <>
                                                                {
                                                                    <Grid container alignItems={"center"} pb={2}>
                                                                        <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                            <Box sx={{ width: 300, margin: "auto" }}>
                                                                                <Text mediumBlack14 pb={1}>
                                                                                    Select Net Payable Range
                                                                                </Text>
                                                                                <Slider
                                                                                    value={[filterState.total_reimbursement_from, filterState.total_reimbursement_to]}
                                                                                    onChange={handleSliderChangeTotalReimbursement}
                                                                                    valueLabelDisplay="auto"
                                                                                    min={filterAmount.total_reimbursement.min}
                                                                                    max={filterAmount.total_reimbursement.max}
                                                                                    sx={{ mb: 2 }}
                                                                                />
                                                                                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                    <Input
                                                                                        formControlProps={{
                                                                                            fullWidth: true
                                                                                        }}
                                                                                        inputProps={{
                                                                                            name: 'total_reimbursement_from',
                                                                                            value: filterState.total_reimbursement_from,
                                                                                            inputProps: { maxLength: 50 },
                                                                                        }}
                                                                                        onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                        handleChange={(e) => handleInputChange(0, e.target.value, 'total_reimbursement_from', 'min')}
                                                                                        clientInput
                                                                                        labelText={'$ Min'}
                                                                                        size="small"
                                                                                        sx={{ width: "35%" }}
                                                                                    />
                                                                                    <Input
                                                                                        formControlProps={{
                                                                                            fullWidth: true
                                                                                        }}
                                                                                        inputProps={{
                                                                                            name: 'total_reimbursement_to',
                                                                                            value: filterState.total_reimbursement_to,
                                                                                        }}
                                                                                        onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                        handleChange={(e) => handleInputChange(1, e.target.value, 'total_reimbursement_to', 'max')}
                                                                                        clientInput
                                                                                        labelText={'$ Max'}
                                                                                        size="small"
                                                                                        sx={{ width: "35%" }}
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            </>
                                                            :
                                                            selectedFilter == 5 ?
                                                                <>
                                                                    {
                                                                        <Grid container alignItems={"center"} pb={2}>
                                                                            <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                                <Box sx={{ width: 300, margin: "auto" }}>
                                                                                    <Text mediumBlack14 pb={1}>
                                                                                        Select Amount Range
                                                                                    </Text>
                                                                                    <Slider
                                                                                        value={[filterState.total_deduction_from, filterState.total_deduction_to]}
                                                                                        onChange={handleSliderChangeTotalDeduction}
                                                                                        valueLabelDisplay="auto"
                                                                                        min={filterAmount.total_deduction.min}
                                                                                        max={filterAmount.total_deduction.max}
                                                                                        sx={{ mb: 2 }}
                                                                                    />
                                                                                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                        <Input
                                                                                            formControlProps={{
                                                                                                fullWidth: true
                                                                                            }}
                                                                                            inputProps={{
                                                                                                name: 'total_deduction_from',
                                                                                                value: filterState.total_deduction_from,
                                                                                                inputProps: { maxLength: 50 },
                                                                                            }}
                                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                            handleChange={(e) => handleInputChange(0, e.target.value, 'total_deduction_from', 'min')}
                                                                                            clientInput
                                                                                            labelText={'$ Min'}
                                                                                            size="small"
                                                                                            sx={{ width: "35%" }}
                                                                                        />
                                                                                        <Input
                                                                                            formControlProps={{
                                                                                                fullWidth: true
                                                                                            }}
                                                                                            inputProps={{
                                                                                                name: 'total_deduction_to',
                                                                                                value: filterState.total_deduction_to,
                                                                                            }}
                                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                            handleChange={(e) => handleInputChange(1, e.target.value, 'total_deduction_to', 'max')}
                                                                                            clientInput
                                                                                            labelText={'$ Max'}
                                                                                            size="small"
                                                                                            sx={{ width: "35%" }}
                                                                                        />
                                                                                    </Box>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                                :
                                                                selectedFilter == 6 ?
                                                                    <>
                                                                        {
                                                                            <Grid container alignItems={"center"} pb={2}>
                                                                                <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                                    <Box sx={{ width: 300, margin: "auto" }}>
                                                                                        <Text mediumBlack14 pb={1}>
                                                                                            Select Amount Range
                                                                                        </Text>
                                                                                        <Slider
                                                                                            value={[filterState.current_balance_from, filterState.current_balance_to]}
                                                                                            onChange={handleSliderChangeRaised}
                                                                                            valueLabelDisplay="auto"
                                                                                            min={filterAmount.current_balance.min}
                                                                                            max={filterAmount.current_balance.max}
                                                                                            sx={{ mb: 2 }}
                                                                                        />
                                                                                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                            <Input
                                                                                                formControlProps={{
                                                                                                    fullWidth: true
                                                                                                }}
                                                                                                inputProps={{
                                                                                                    name: 'current_balance_from',
                                                                                                    value: filterState.current_balance_from,
                                                                                                    inputProps: { maxLength: 50 },
                                                                                                }}
                                                                                                onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                                handleChange={(e) => handleInputChange(0, e.target.value, 'current_balance_from', 'min')}
                                                                                                clientInput
                                                                                                labelText={'$ Min'}
                                                                                                size="small"
                                                                                                sx={{ width: "35%" }}
                                                                                            />
                                                                                            <Input
                                                                                                formControlProps={{
                                                                                                    fullWidth: true
                                                                                                }}
                                                                                                inputProps={{
                                                                                                    name: 'current_balance_to',
                                                                                                    value: filterState.current_balance_to,
                                                                                                }}
                                                                                                onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                                handleChange={(e) => handleInputChange(1, e.target.value, 'current_balance_to', 'max')}
                                                                                                clientInput
                                                                                                labelText={'$ Max'}
                                                                                                size="small"
                                                                                                sx={{ width: "35%" }}
                                                                                            />
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                        }
                                                                    </>
                                                                    :
                                                                    selectedFilter == 7 ?
                                                                        <>
                                                                            {
                                                                                employmentType.length > 0 && employmentType.map((item, index) => (
                                                                                    <Grid container alignItems={"center"} pb={2}>
                                                                                        <FormControlLabel
                                                                                            key={index}
                                                                                            control={
                                                                                                <Checkbox
                                                                                                    size="small"
                                                                                                    name={"employment_type"}
                                                                                                    value={item.id}
                                                                                                    onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                                    // onClick = {handleCheckClick()}
                                                                                                    icon={<CheckBorderIcon />}
                                                                                                    checkedIcon={<CheckedIcon />}
                                                                                                    checked={filterState.employment_type.includes(item.id)} />}
                                                                                            label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                                                <BlackToolTip title={item.value} placement="right" arrow>
                                                                                                    {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                                                </BlackToolTip>
                                                                                                :
                                                                                                item.value}</Text>}
                                                                                        />
                                                                                    </Grid>
                                                                                ))
                                                                            }
                                                                        </>
                                                                        :
                                                                        <Box sx={{ height: "60vh", width: "100%", display: "flex", alignItems: "center" }}>
                                                                            <Grid container>
                                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                                    <img src={nofilterplacement} alt="nofilterplacement" style={{ paddingTop: "15px" }} />
                                                                                </Grid>
                                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"} >
                                                                                    <Text veryLargeLabel>Gear Up!</Text>
                                                                                </Grid>
                                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                                    <Text offBoardBodyFont>Select filters to scale our data peaks</Text>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                            // nofilterplacement
                                        }
                                    </Box>
                                </Box>

                                <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={1} height={'73px'} pr={'26px'}>
                                    <Button cancelSmall onClick={() => cancelFilter()}>Cancel</Button>
                                    <Button saveSmall onClick={() => handleApplyFilters()} >Apply Filters</Button>
                                </Box>
                            </Box >
                            : null
                    }

                </SwipeableDrawer>
            </Box>
        </>
    );
}