import { Box, Grid } from "@mui/material";
import Input from "../../components/input/Input";
import Text from "../../components/customText/Text";
import { onNumberOnlyChange } from "../../components/Validation";


export default function Zipcode(props) {
    const { state, error, handleChange, columnLength, isEditable } = props
    return(
        <>
         <Grid item lg={columnLength} md={columnLength} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'zip_code',
                            value: state.zip_code,
                            inputProps: { maxLength: 6 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        onKeyPress={onNumberOnlyChange}
                        disabled={isEditable ? isEditable : false}
                        error={error.zip_code}
                        labelText={state?.zip_code?.length == 5 ? 'Zipcode' : state?.zip_code?.length == 6 ? 'Pincode' : 'Zipcode / Pincode'}
                    />
                    {error.zip_code && <Text red>{error.zip_code ? error.zip_code : ''}</Text>}
                </Box>
            </Grid>
            <Grid item lg={columnLength} md={columnLength} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'city',
                            value: state.city || '',
                            inputProps: { maxLength: 50 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.city}
                        disabled={isEditable ? isEditable : false}
                        labelText='City'
                    />
                    {error.city && <Text red>{error.city ? error.city : ''}</Text>}
                </Box>
            </Grid>
            <Grid item lg={columnLength} md={columnLength} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'state_name',
                            value: state.state_name || '',
                        }}
                        disabled={true}
                        clientInput
                        labelText='State'
                    />
                </Box>
            </Grid>
            <Grid item lg={columnLength} md={columnLength} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'country_name',
                            value: state.country_name || '',
                        }}
                        disabled={true}
                        clientInput
                        labelText='Country'
                    />
                </Box>
            </Grid>
        </>
    )
}
