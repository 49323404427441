import React from 'react'
import VendorProfileStyles from './VendorProfileStyles';
import { Box, Grid, Skeleton, Stack,Popover,Divider  } from '@mui/material';
import Text from '../../../../components/customText/Text';
import Input from '../../../../components/input/Input';
import Button from '../../../../components/customButton/Button';
import FileInput from '../../../../components/muiFileInput/FileInput';
import { useState ,useRef,useEffect} from 'react';
import LocalStorage from '../../../../utils/LocalStorage';
import CommonApi from '../../../../apis/CommonApi';
import { address_validation, client_name, isValid, isValidMulti, validate_city, validate_emptyField, validate_empty_address, validate_zipcode,nonZeroNumericals } from '../../../../components/Validation';
import { addSuccessMsg, addErrorMsg, addWarningMsg, BlackToolTip, capitalizeAndAddSpace, addLoader, removeLoader, capitalize, checkZipcode } from '../../../../utils/utils';
import EditIcon from '../../../../assets/svg/newEdit.svg'
import FileSvg from '../../../../assets/svg/File.svg';
import ClientsApi from '../../../../apis/admin/clients/ClientsApi';
import LoadingScreen from '../../employees/userprofile/document/LoadingScreen';
import LoaderButton from '../../../../components/customButton/LoadingButton';
import Sure from '../../../../assets/svg/placementPop.svg'
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import Search from '../../../../components/select/Select';
import ClientStyles from '../ClientStyles';

import { useZipcodeAPICallMulti } from '../../ZipcodeCommon';
import { ErrorMessages } from '../../../../utils/ErrorMessages';
import { Buffer } from 'buffer';

function CompanyDetails(props) {
    const { action, setAction, state, setState, getCompanyDetails, getActivity, loading } = props;
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = VendorProfileStyles();
    const clientClasses = ClientStyles();

    const [erro, setErro] = useState([]);
    const [error, setError] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const [buttonLoaderDaysSave,setButtonLoaderDaysSave] = useState(false);

    const [deletePopup, setdeletePopup] = useState(false);
    const boxRef = useRef(null);
    const [days,setDays] = useState('');
    const [paymentTermsOptions, setPaymentTermsOptions] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(()=>{
        paymentTermsDropdown();
        // eslint-disable-next-line
    },[]);
    const paymentTermsDropdown = () => {
        CommonApi.getPaymentTermsDropdown().then((res)=>{
            if (res.data.statusCode == 1003) {
                setPaymentTermsOptions(res.data.data);
                if(days !== ''){
                    const newValue = res.data.data.find(option =>option.value===days);
                    setState({
                        ...state,
                        net_pay_terms_id: newValue?.id,
                        
                    })
                    setDays('');
                    setError({...error,net_pay_terms_id:validate_emptyField(newValue?.id)});
                    handleClose();
                }
            } 
        })
    }

    const handleDaysPaymentTerms = () => {
        const data = {
            request_id: LocalStorage.uid(),
            days: days,
            is_active: true
        }
        setButtonLoaderDaysSave(true);
        CommonApi.postPaymentTermsDays(data).then((res) => {
            if (res.data.statusCode == 1003) {
                paymentTermsDropdown();
                setButtonLoaderDaysSave(false);
            }
            else {
                addErrorMsg(res.data.message);
                setButtonLoaderDaysSave(false);
            }
        })
    }


    const handleClick = (event) => {
        setAnchorEl(boxRef.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openPaymentTerms = Boolean(anchorEl);
    const paymentTermsId = openPaymentTerms ? 'payment-terms-popover' : undefined;


    const handleChange = (e) => {
        if (e.target.name == 'name') {
            setState({ ...state, [e.target.name]: capitalize(e.target.value) });
            handleCompanyValidate(e);
        } else if (e.target.name == 'days') {
            if(nonZeroNumericals(e.target.value)){
            setDays(e.target.value.replace(/\D/g, ""))}
        } else if (e.target.name == 'net_pay_terms_id') {
            setState({ ...state, [e.target.name]: e.target.value });
            handleCompanyValidate(e);
        }
        else {
            setState({
                ...state,
                [e.target.name]: e.target.value
            }, handleCompanyValidate(e));
        }
    }
    // eslint-disable-next-line
    const uploadDocs = (e, index) => {
        addLoader(true);
        const formData = new FormData();
        formData.append('files', e.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg'].includes(r))) {
            if (Number(((e.target.files[0].size / (1024 * 1024)).toFixed(2))) < 25) {
                CommonApi.documentUpload("company-logo", formData, LocalStorage.getAccessToken()).then((res) => {
                    removeLoader();
                    if (res.data.statusCode === 1003) {
                        state.logo_path_id = res.data.data.id
                        state.logo_name = e.target.files[0].name
                        state.logo_url = res.data.data.document_url
                        setState({ ...state })
                    } else {
                        addErrorMsg(res.data.message);
                    }
                });
            }
            else {
                removeLoader()
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            removeLoader()
            addErrorMsg("Please upload files in PNG or JPG format only.");
        }
    }

    const deleteDocs = () => {
        state.logo_path_id = ''
        state.logo_name = ''
        state.logo_url = ''
        setState({ ...state });
        setdeletePopup(false)
    }

    const handleCompanyValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "name":
                error.name = client_name(input.value, "vendor ");
                break;
            case "net_pay_terms_id":
                error.net_pay_terms_id = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        let s1 = { ...error };
        setError(s1);
    };

    const handleValidateCommunicationAdd = (e, index) => {
        let input = e.target;
        let err = erro.length > 0 ? (erro ? erro[index] : erro) : erro;
        for (var k = 0; k <= index; k++) {
            erro.push({});
        }
        let s2 = erro.length > 0 ? [...erro] : [{ ...erro }];
        switch (input.name || input.tagName) {
            case "address_line_one":
                err.address_line_one = address_validation(input.value);
                break;
            case "address_line_two":
                err.address_line_two = validate_empty_address(input.value);
                break;
            case "city":
                err.city = validate_city(input.value);
                break;
            case "state_id":
                err.state_id = validate_emptyField(input.value);
                break;
            case "country_id":
                err.country_id = validate_emptyField(input.value);
                break;
            case "zip_code":
                err.zip_code = validate_zipcode(input.value, state.address[0].country_id);
                break;
            default:
                break;
        }
        setErro(s2);
    };

    const { zipcodeAPICallMulti } = useZipcodeAPICallMulti(state, setState, erro, setErro);

    const handleChangeAddress = (e) => {
        state.address[0][e.target.name] = e.target.value;
        setState({ ...state });
        handleValidateCommunicationAdd(e, 0)
        if (e.target.name == 'zip_code') {
            zipcodeAPICallMulti(e.target.value, 0, 'address', 'validation')
        }
    };

    const companyDetailsValidations = () => {
        let { name,net_pay_terms_id } = state;
        let errors = {};
        errors.name = client_name(name, 'vendor ');
        errors.net_pay_terms_id  = validate_emptyField(net_pay_terms_id );

        return errors;
    };
    const companyCommAddressValidations = async () => {
        let errors = {};
        let err = [];

        // Use for...of to handle asynchronous operations correctly
        for (const value of state.address) {
            errors = {};
            errors.address_line_one = address_validation(value.address_line_one);
            errors.address_line_two = validate_empty_address(value.address_line_two);
            errors.country_id = validate_emptyField(value.country_id);
            errors.state_id = validate_emptyField(value.state_id);
            errors.city = validate_city(value.city);

            // Await the asynchronous checkZipcode call
            errors.zip_code = await checkZipcode(value.zip_code);

            // Push the errors object for the current address to the array
            err.push(errors);
        }

        return err;
    };

    const updatevendor = () => {
        let data = { ...state, request_id: LocalStorage.uid(), encoded_name: Buffer.from(state.name).toString('base64') };
        setButtonLoader(true)
        ClientsApi.updateCompany(data, 'vendor').then((res) => {
            setTimeout(() => {
                setButtonLoader(false)
                if (res.data.statusCode === 1003) {
                    addSuccessMsg(res.data.message);
                    props.setformEdit(false)
                    handleCancel();
                    getActivity();
                } else {
                    addErrorMsg(res.data.message);
                }
            }, 400)
        })
    }

    const handleSubmit = async () => {
        let errors = companyDetailsValidations();
        let commErrors = await companyCommAddressValidations();
        if (isValid(errors) && isValidMulti(commErrors)) {
            state['request_id'] = LocalStorage.uid();
            updatevendor(state);
        } else {
            let s1 = { error };
            s1 = errors;
            let s2 = { error };
            s2 = commErrors;
            setError(s1);
            setErro(s2);
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
    }

    const handleCancel = () => {
        props.setformEdit(false)
        setAction('view');
        getCompanyDetails();
        getActivity();
        setError({});
        setErro([{
            name: "",
            reference_id: "",
            logo_path_id: '',
            logo_name: '',
            logo_url: '',
            address: [
                {
                    address_line_one: "",
                    address_line_two: "",
                    city: "",
                    state_id: "",
                    country_id: "",
                    country: null,
                    zip_code: ""
                }
            ]
        }])
    }

    const editAction = () => {
        props.setformEdit(true)
        setAction('update');
        setError({});
        setErro([{
            name: "",
            reference_id: "",
            logo_path_id: '',
            logo_name: '',
            logo_url: '',
            address: [
                {
                    address_line_one: "",
                    address_line_two: "",
                    city: "",
                    state_id: "",
                    country_id: "",
                    country: null,
                    zip_code: ""
                }
            ]
        }])
    }

    const openInNewTab = (documentUrl) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed == true)))) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
    }

    return (
        <Grid container p={'30px'} className={classes.boxShadow}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                    <Text largeBlue> Vendor Details </Text>
                    {
                        (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_edit" && item.is_allowed !== true))) ? "" :
                            state.status === 'In Active' ? '' : action == 'view' &&
                                loading ? <Skeleton animation='linear' className={classes.editSkeleton} /> :
                                action == 'view' ? <img src={EditIcon} alt='Edit' className={classes.cursor} onClick={editAction} /> : ''
                    }
                </Box>
            </Grid>
            {
                action == 'view' ?
                    <>
                        {
                            loading ?
                                <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                                    <LoadingScreen />
                                </Grid>
                                :
                                <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                                    <Grid item container lg={12}>
                                        <Grid item lg={3} md={4} sm={4} xs={12}>
                                            <Box p={1} pt={2}>
                                                <Text mediumGreyHeader> Vendor Name</Text>
                                                <Text pt={1} mediumBlack14>
                                                    {
                                                        state.name ? state.name.length > 16 ?
                                                            <BlackToolTip title={capitalizeAndAddSpace(state.name)} placement="top" arrow>
                                                                {capitalizeAndAddSpace(state.name.slice(0, 16)) + capitalizeAndAddSpace(state.name.length > 16 ? "..." : "")}
                                                            </BlackToolTip>
                                                            : capitalizeAndAddSpace(state.name) : '--'
                                                    }
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4} xs={12}>
                                            <Box p={1} pt={2}>
                                                <Text mediumGreyHeader> Vendor ID</Text>
                                                <Text pt={1} mediumBlack14 noWrap>{state.reference_id ? state.reference_id : '--'}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4} xs={12}>
                                            <Box p={1} pt={2}>
                                                <Text mediumGreyHeader> Vendor Logo (Optional)</Text>
                                                {
                                                    state.logo_name === '' ? '--' :
                                                        <Box display='flex' flexDirection='row' gap={1} pt={1} className={classes.cursor}>
                                                            <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} onClick={() => { openInNewTab(state.logo_url) }} />
                                                            <Text blue14 noWrap onClick={() => { openInNewTab(state.logo_url) }}>
                                                                <BlackToolTip arrow placement='top' title={state.logo_name}>
                                                                    {state.logo_name.slice(0, 16)} {state.logo_name.length > 16 ? '...' : ''}
                                                                </BlackToolTip>
                                                            </Text>
                                                        </Box>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box p={1} pt={2}>
                                            <Text mediumGreyHeader> Address Line - 1</Text>
                                            <Text pt={1} mediumBlack14 noWrap>
                                                {
                                                    state.address[0].address_line_one ? state.address[0].address_line_one.length > 130 ?
                                                        <BlackToolTip title={capitalizeAndAddSpace(state.address[0].address_line_one)} placement="top" arrow>
                                                            {capitalizeAndAddSpace(state.address[0].address_line_one).slice(0, 130) + capitalizeAndAddSpace(state.address[0].address_line_one.length > 130 ? "..." : "")}
                                                        </BlackToolTip>
                                                        : capitalizeAndAddSpace(state.address[0].address_line_one) : '--'
                                                }
                                            </Text>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box p={1} pt={2}>
                                            <Text mediumGreyHeader> Address Line - 2 (Optional)</Text>
                                            <Text pt={1} mediumBlack14 noWrap>
                                                {
                                                    state.address[0].address_line_two ? state.address[0].address_line_two.length > 130 ?
                                                        <BlackToolTip title={capitalizeAndAddSpace(state.address[0].address_line_two)} placement="top" arrow>
                                                            {capitalizeAndAddSpace(state.address[0].address_line_two).slice(0, 130) + capitalizeAndAddSpace(state.address[0].address_line_two.length > 130 ? "..." : "")}
                                                        </BlackToolTip>
                                                        : capitalizeAndAddSpace(state.address[0].address_line_two) : '--'
                                                }
                                            </Text>
                                        </Box>
                                    </Grid>
                                    <Grid item container lg={12} md={12} sm={12} xs={12}>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2}>
                                                <Text mediumGreyHeader> Country</Text>
                                                <Text pt={1} mediumBlack14 noWrap>{state.address[0].country_name ? state.address[0].country_name : '--'}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2}>
                                                <Text mediumGreyHeader> State</Text>
                                                <Text pt={1} mediumBlack14 noWrap>{state.address[0].state_name ? state.address[0].state_name : '--'}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2}>
                                                <Text mediumGreyHeader>City</Text>
                                                <Text pt={1} mediumBlack14 noWrap>
                                                    {
                                                        state.address[0].city ? state.address[0].city.length > 25 ?
                                                            <BlackToolTip title={state.address[0].city} placement="top" arrow>
                                                                {state.address[0].city.slice(0, 25) + (state.address[0].city.length > 25 ? "..." : "")}
                                                            </BlackToolTip>
                                                            : state.address[0].city : '--'
                                                    }
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2}>
                                                <Text mediumGreyHeader>Zip Code</Text>
                                                <Text pt={1} mediumBlack14 noWrap>{state.address[0].zip_code ? state.address[0].zip_code : '--'}</Text>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={12} md={6} sm={6} xs={12}>
                                        <Box p={1} pt={2}>
                                            <Text mediumGreyHeader>Payment Terms</Text>
                                            <Text pt={1} mediumBlack14 noWrap>
                                            {state.net_pay_term_days?`${state.net_pay_term_days} days`:'--'} 
                                            </Text>
                                        </Box>
                                    </Grid>
                                </Grid>
                        }
                    </>
                    :
                    <>
                        {
                            loading ?
                                <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                                    <LoadingScreen />
                                </Grid>
                                :
                                <>
                                    <Grid item container lg={12} md={12} sm={12} xs={12} spacing={3} my={1} className={classes.contentScroll}>
                                        <Grid item container lg={12} spacing={3}>
                                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'name',
                                                        value: state.name,
                                                        inputProps: { maxLength: 50 },
                                                    }}
                                                    handleChange={handleChange}
                                                    clientInput
                                                    error={error.name}
                                                    labelText='Vendor Name'
                                                />
                                                <Text errorText> {error.name ? error.name : ""}</Text>
                                            </Grid>
                                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'reference_id',
                                                        value: state.reference_id,
                                                    }}
                                                    disabled={true}
                                                    handleChange={handleChange}
                                                    clientInput
                                                    labelText='Vendor ID'
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={12} md={6} sm={6} xs={12}>
                                            <FileInput
                                                name='logo_path_id'
                                                FileName={state.logo_name}
                                                handleChange={(e) => uploadDocs(e)}
                                                label='Vendor Logo (Optional)'
                                                handleDelete={() => { setdeletePopup(true) }}
                                                actionState={state.logo_name ? 1 : ''}
                                                documentUrl={state.logo_url}
                                            />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'address_line_one',
                                                    value: state.address[0].address_line_one,
                                                    inputProps: { maxLength: 225 },
                                                }}
                                                handleChange={(e) => handleChangeAddress(e, "address")}
                                                clientInput
                                                labelText='Address Line 1'
                                                error={erro[0] && erro[0].address_line_one}
                                            />
                                            <Text errorText> {erro[0] ? erro[0].address_line_one : ""}</Text>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'address_line_two',
                                                    value: state.address[0].address_line_two,
                                                    inputProps: { maxLength: 225 },
                                                }}
                                                error={erro[0] && erro[0].address_line_two}
                                                handleChange={(e) => handleChangeAddress(e, "address")}
                                                clientInput
                                                labelText='Address Line 2 (Optional)'
                                            />
                                            <Text errorText> {erro[0] ? erro[0].address_line_two : ""}</Text>
                                        </Grid>

                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'zip_code',
                                                    value: state.address[0].zip_code,
                                                    inputProps: { maxLength: 5 }
                                                }}
                                                handleChange={(e) => handleChangeAddress(e, "address")}
                                                clientInput
                                                error={erro[0] ? erro[0].zip_code : ""}
                                                labelText={"Zip Code"}
                                            />
                                            <Text errorText> {erro[0] ? erro[0].zip_code == ErrorMessages.fieldRequired ? erro[0].zip_code : erro[0].zip_code ? ErrorMessages.invalidZipcode : "" : ""}</Text>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'city',
                                                    value: state.address[0].city,
                                                    inputProps: { maxLength: 50 },
                                                }}
                                                handleChange={(e) => handleChangeAddress(e, "address")}
                                                clientInput
                                                labelText='City'
                                                error={erro[0] && erro[0].city}
                                            />
                                            <Text errorText> {erro[0] ? erro[0].city : ""}</Text>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'state_name',
                                                    value: state.address[0].state_name || '',
                                                }}
                                                disabled={true}
                                                clientInput
                                                labelText='State'
                                            />
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'country_name',
                                                    value: state.address[0].country_name || '',
                                                }}
                                                disabled={true}
                                                clientInput
                                                labelText='Country'
                                            />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Divider className={clientClasses.dividerContact} />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Text headerBlack>Invoice Configuration</Text>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12} mb={2}>
                                            <Box ref={boxRef} >
                                                <Search
                                                    name='net_pay_terms_id'
                                                    value={state.net_pay_terms_id}
                                                    options={paymentTermsOptions}
                                                    label='Payment Terms'
                                                    onChange={handleChange}
                                                    buttonName='New Pay Term'
                                                    error={error.net_pay_terms_id}
                                                    buttonHandler={
                                                        handleClick
                                                    }
                                                    showButton
                                                />
                                            </Box>
                                            <Popover
                                                id={paymentTermsId}
                                                open={openPaymentTerms}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}

                                            >
                                                <Box sx={{ width: '275px !important', height: '176px !important', padding: '12px !important', boxShadow: '0px 0px 15px 0px #0000001F !important', borderRadius: '8px !important' }}>
                                                    <Grid container flexDirection='column' spacing={2} >
                                                        <Grid item > <Text headerBlack>Add New Pay Term</Text></Grid>
                                                        <Grid item ><Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'days',
                                                                value: days || '',
                                                                inputProps: { maxLength: 3 }
                                                            }}
                                                            handleChange={handleChange}

                                                            disabled={false}
                                                            clientInput
                                                            labelText='Days'
                                                        /></Grid>
                                                        <Grid item container justifyContent='end' gap={2}>
                                                            <Grid item >
                                                                <Button smallBlueOutline sx={{ height: '40px !important' }} onClick={handleClose}>Cancel</Button>

                                                            </Grid>
                                                            <Grid item >
                                                                <LoaderButton loading={buttonLoaderDaysSave} saveVerySmall sx={{ height: '40px !important' }} onClick={handleDaysPaymentTerms}>Save</LoaderButton>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Popover>
                                            {
                                                error.net_pay_terms_id ?
                                                    <Text red>{error.net_pay_terms_id ? error.net_pay_terms_id : ''}</Text> : ''
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} p='10px 0px 5px 0px !important'>
                                        <Box display='flex' flexDirection='row' justifyContent='space-between'>
                                            <Button blackCancelBtn onClick={handleCancel}>Cancel</Button>
                                            <LoaderButton loading={buttonLoader} saveNcontinueSmall onClick={handleSubmit}>Save</LoaderButton>
                                        </Box>
                                    </Grid>
                                </>
                        }
                    </>
            }
            <ReusablePopup openPopup={deletePopup} setOpenPopup={setdeletePopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you really wish to delete.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setdeletePopup(false) }}>
                                No
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { deleteDocs() }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Grid>
    )
}

export default CompanyDetails