import React, { useEffect, useState } from 'react';
import UserProfileStyles from '../UserProfileStyles';
import { Box} from '@mui/material';
import EducationDocument from './EducationDocument';
import WorkAuth from './WorkAuth';
import PersonalDocuments from './PersonalDocuments';

export default function AllSections(props) {
    // eslint-disable-next-line
    const classes = UserProfileStyles(); // eslint-disable-next-line
    const [tab, setTab] = useState(0);
    // eslint-disable-next-line
    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
        setCurrent(event)
    };
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [formLoader, setformLoader] = useState(false) // eslint-disable-next-line
    const [list, setList] = useState([]);
    const [current, setCurrent] = useState("education");
    useEffect(() => {
        setCurrent(props.documentPosition) // eslint-disable-next-line
    }, [props])

    return (
        <Box m={.5} mr={0.1}>
            {
                current === 'education' ?
                    <EducationDocument formEdit={props.formEdit} setformEdit={props.setformEdit} employee={props.employee} documentPosition={props.documentPosition} /> :
                    current === 'work' ?
                        <WorkAuth formEdit={props.formEdit} setformEdit={props.setformEdit} employee={props.employee} documentPosition={props.documentPosition} />
                        :
                        <PersonalDocuments formEdit={props.formEdit} setformEdit={props.setformEdit} employee={props.employee} documentPosition={props.documentPosition}/>
            }
        </Box>
    );
}