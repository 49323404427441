import React, { useEffect, useState } from 'react'
import Text from '../../../../../components/customText/Text'
import { Grid, Box, Skeleton } from '@mui/material' // eslint-disable-next-line
import Table from '@mui/material/Table';
import CircularProgress from '../../../../../components/progressbar/CircularProgress'
import UserProfileStyles from '../UserProfileStyles'; // eslint-disable-next-line
import Nodata from '../../../../../assets/svg/clients/noPLacementyet.svg';
import ClientsApi from '../../../../../apis/admin/clients/ClientsApi'
import { BlackToolTip, addErrorMsg, capitalizeAndAddSpace, getCurrencySymbol } from '../../../../../utils/utils'
import Button from '../../../../../components/customButton/Button'
import { useNavigate } from 'react-router-dom';
import LocalStorage from '../../../../../utils/LocalStorage'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LoadingScreen from '../../../employees/userprofile/document/LoadingScreen';

function PlacementDetails(props) {
    const classes = UserProfileStyles(); // eslint-disable-next-line
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        if (props.current == "Placements") {
            setLoading(true)
            ClientsApi.placementClientListing(props.id, "client").then((response) => {
                if (response.data.statusCode == 1003) {
                    setLoading(false);
                    setData(response.data.data);
                } else {
                    setLoading(false);
                    addErrorMsg(response.data.message);
                }
            });
        }

    }, [props])


    const navigateToEmployee = (args) => {
        LocalStorage.removeRedirectedModule();
        LocalStorage.removeSubModule();
        LocalStorage.removeNotificationEmpId();
        navigate(`/employees/user-profile`, { state: { id: args.employee_id, full_name: args.name, from: 'employmentDashboard' } })
    }
    // eslint-disable-next-line
    const columns = [
        {
            field: 'name',
            align: "left",
            headerAlign: "left",
            headerName: 'Employees',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params) =>
                loading ? <Skeleton animation="wave" width="40px" height='65px' style={{ borderRadius: '50%' }} /> :
                    <Box display='flex' flexDirection='row' pt={'6px'} gap={2}>
                        <CircularProgress
                            percentage={params.row.profile_progress}
                            color={params.row.profile_progress >= 76 ? 'green' : params.row.profile_progress <= 75 && params.row.profile_progress >= 51 ? 'yellow' : params.row.profile_progress < 50 ? "#FFBF00" : ''}
                            src={params.row.profile_picture_url}
                            name={params.row.profile_picture_url == undefined || params.row.profile_picture_url == null || params.row.profile_picture_url == '' ? params.row.display_name[0] : ''}
                            imgWidth='40px !important'
                            imgHeight='40px !important'
                            avatarWidth='40px !important'
                            avatarHeight='40px !important'
                            value={params.row.profile_progress}
                            size={48}
                            tooltip
                            tooltipContent={
                                <Box p={'3px 0px'} width='100%'>
                                    <Text mediumBoldWhite400>{`Profile Completion - ${params.row.profile_progress}%`}</Text>
                                </Box>
                            }
                            dashboardContent={
                                params.row.profile_progress == 100 ? '' : <Button addButtonTooltip sx={{ marginTop: '10px' }} onClick={() => { navigateToEmployee(params.row) }}>Complete Profile</Button>
                            }
                        />
                        <Box>
                            {
                                loading ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                    <Text nowrap mediumBlack>
                                        {
                                            params.row.display_name && params.row.display_name.length > 16 ?
                                                <BlackToolTip title={params.row.display_name} placement="top" arrow>
                                                    {capitalizeAndAddSpace(params.row.display_name.slice(0, 16)) + (params.row.display_name.length > 16 ? "..." : "")}
                                                </BlackToolTip>
                                                : capitalizeAndAddSpace(params.row.display_name)
                                        }
                                    </Text>
                            }

                            <Text mediumLabel sx={{ paddingTop: '5px !important' }} nowrap>{params.row.reference_id}</Text>
                        </Box>
                    </Box>
        },
        {
            field: 'hours',
            align: "left",
            headerAlign: "left",
            headerName: 'Hours',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params) =>
                loading ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                    <Text smallBlack>{params.row.hours}</Text>
        },
        {
            field: 'bill_rate',
            align: "left",
            headerAlign: "left",
            headerName: 'Bill Rate',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params) =>
                loading ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                    <Text smallBlack>{LocalStorage.getCurrencySymbol()} {params.row.bill_rate}</Text>
        },
        {
            field: 'status',
            align: "left",
            headerAlign: "left",
            headerName: 'Status',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params) =>
                loading ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                    params.row.status == 'Active' ?
                        <Text offBoardBodyGreen>In Project</Text>
                        :
                        <Text offBoardBodyGreen>Project Ended</Text>
        },
    ]

    // const generateRandom = () => {
    //     var length = 8,
    //         charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    //         retVal = "";
    //     for (var i = 0, n = charset.length; i < length; ++i) {
    //         retVal += charset.charAt(Math.floor(Math.random() * n));
    //     }
    //     return retVal;
    // }

    // eslint-disable-next-line
    return (
        <Grid container p={'30px'}>
            <Grid item lg={12} md={12} sm={12} xs={12} pb={2}>
                <Text largeBlue> Placement Details </Text>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                {
                    loading ?
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <LoadingScreen />
                        </Grid>
                        :
                        <TableContainer component={'div'} sx={{ height: '65vh !important', width: '100% !important', overflowY: 'scroll' }}>
                            <Table stickyHeader sx={{ border: '1px solid #F6F6F6', boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '12px 12px 0px 0px' }}>
                                <TableHead sx={{ height: '57px', borderRadius: '12px 12px 0px 0px' }}>
                                    <TableRow sx={{ borderRadius: '12px 12px 0px 0px', borderBottom: '1px solid #EAECF0' }}>
                                        <TableCell align="left" sx={{ background: '#F6F6F6', borderRadius: '12px 0px 0px 0px' }}><Text mediumBoldBlack>Employees</Text></TableCell>
                                        <TableCell align="left" sx={{ background: '#F6F6F6' }}><Text mediumBoldBlack>Hours</Text></TableCell>
                                        <TableCell align="left" sx={{ background: '#F6F6F6' }}><Text mediumBoldBlack>Bill Rate</Text></TableCell>
                                        <TableCell align="left" sx={{ background: '#F6F6F6', borderRadius: '0px 12px 0px 0px' }}><Text mediumBoldBlack>Status</Text></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.length > 0 ? data.map((obj, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ alignItems: 'center', cursor: 'pointer' }}
                                                onClick={() => navigate('/placements/view-placement', { state: { placement_id: obj.placement_id, id: obj.employee_id } })}
                                            >
                                                <TableCell align="left" sx={{ padding: '6px 10px' }}>
                                                    <Box display='flex' flexDirection='row' pt={'12px'} width='100%' gap={2}>
                                                        {
                                                            loading ? <Skeleton animation="wave" className={classes.placementProfileskeleton} /> :
                                                                <CircularProgress
                                                                    percentage={obj.profile_progress}
                                                                    color={obj.profile_progress >= 76 ? 'green' : obj.profile_progress <= 75 && obj.profile_progress >= 51 ? 'yellow' : obj.profile_progress < 50 ? "#FFBF00" : ''}
                                                                    src={obj.profile_picture_url}
                                                                    name={obj.profile_picture_url == undefined || obj.profile_picture_url == null || obj.profile_picture_url == '' ? obj.display_name[0] : ''}
                                                                    imgWidth='40px !important'
                                                                    imgHeight='40px !important'
                                                                    avatarWidth='40px !important'
                                                                    avatarHeight='40px !important'
                                                                    value={obj.profile_progress}
                                                                    size={48}
                                                                    tooltip
                                                                    tooltipContent={
                                                                        <Box p={'8px 0px'} width='100%'>
                                                                            <Text mediumBoldWhite400>{`Profile Completion - ${obj.profile_progress}%`}</Text>
                                                                        </Box>
                                                                    }
                                                                // dashboardContent={
                                                                //     obj.profile_progress == 100 ? '' : <Button addButtonTooltip sx={{ marginTop: '10px' }} onClick={() => { navigateToEmployee(obj) }}>Complete Profile</Button>
                                                                // }
                                                                />
                                                        }
                                                        <Box>
                                                            {
                                                                loading ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                                                    <Text nowrap mediumBlack>
                                                                        {loading ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                                                            obj.display_name && obj.display_name.length > 16 ?
                                                                                <BlackToolTip title={obj.display_name} placement="top" arrow>
                                                                                    {capitalizeAndAddSpace(obj.display_name.slice(0, 16)) + (obj.display_name.length > 16 ? "..." : "")}
                                                                                </BlackToolTip>
                                                                                : capitalizeAndAddSpace(obj.display_name)
                                                                        }
                                                                    </Text>
                                                            }

                                                            <Text mediumLabel sx={{ paddingTop: '5px !important' }} nowrap>{loading ? <Skeleton animation="wave" className={classes.textSkeleton} /> : obj.reference_id}</Text>
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="left" sx={{ padding: '6px 15px' }}>
                                                    <Text black12>{loading ? <Skeleton animation="wave" className={classes.textSkeleton} /> : obj.hours}</Text>
                                                </TableCell>
                                                <TableCell align="left" sx={{ padding: '6px 15px' }}><Text black12>{loading ? <Skeleton animation="wave" className={classes.textSkeleton} /> : `${getCurrencySymbol()} ${obj.bill_rate}`}</Text></TableCell>
                                                <TableCell align="left" sx={{ padding: '6px 15px' }}>{
                                                    obj.status == 'Active' ?
                                                        <Text offBoardBodyGreen>{loading ? <Skeleton animation="wave" className={classes.textSkeleton} /> : 'In Project'}</Text>
                                                        :
                                                        <Text offBoardBodyGreen>{loading ? <Skeleton animation="wave" className={classes.textSkeleton} /> : 'Project Ended'}</Text>}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                            : data.length == 0 ?
                                                <Grid item lg={12} textAlign='center' justifyContent='center' alignItems='center' ml={3} pl={10} mt={1} mb={2}>
                                                    <img src={Nodata} alt='Nodata' />
                                                    <Text veryLargeLabel>No Placement yet!</Text>
                                                </Grid> : ''
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                }

                {/* <Table
                    height={'336px'}
                    rows={data}
                    columns={columns}
                    hideFooter={true}
                    hidePagination={true}
                /> */}
            </Grid>
        </Grid>
    )
}

export default PlacementDetails