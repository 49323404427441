import { Avatar, Box, Divider, Grid } from '@mui/material'
import React, { useState } from 'react'
import Text from '../../../../components/customText/Text'
import Input from '../../../../components/input/Input'
import { onCharactersAndNumbersWithSpaceChange } from '../../../../components/Validation'
import GeneratePayroll from './GeneratePayroll'
import Button from '../../../../components/customButton/Button'
import { addErrorMsg, BlackToolTip, addSuccessMsg } from '../../../../utils/utils'
import GeneratePayrollApi from '../../../../apis/configurations/generatePayroll/GeneratePayrollApi'


function CommentsDrawer({ comments, paymentId, handleCloseDrawer }) {
  // eslint-disable-next-line
  const classes = GeneratePayroll();
  
  const [values, setValues] = useState({
    comment: '',
  });


  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }


  const handleComment = () => {
    const data = { comment: values.comment, payroll_payment_details_id: paymentId }
    GeneratePayrollApi.AddComment(data).then((res) => {
      if (res.data.statusCode === 1003) {
        setValues({ ...values, comment: '' });
        handleCloseDrawer();
        addSuccessMsg(res.data.message);
      } else {
        addErrorMsg(res.data.message);
      }
    })
  }

  return (
    <div>
      <Box width={'700px'} height={'100vh'} p={3} >
        <Grid item lg={12} md={12} xs={12} s={12}>
          <Text black18px>All Comments</Text>
        </Grid>

        {comments.length > 0 ?
          <Box py={2}>
            {
              comments.map((rowData, index) => (
                <>
                  <Grid key={index} container py={2} item lg={12} md={12} sm={12} xs={12} alignItems={"center"} justifyContent={"space-between"}>
                    <Grid container item lg={6} md={6} sm={6} xs={6}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: 0.7 }}>
                        <Avatar
                          alt={rowData?.display_name}
                          src={rowData.profile_picture_url}
                          small
                        />
                        <Box>
                          <Text smallBlack14 >
                            {
                              rowData?.display_name ? rowData?.display_name.length > 16 ?
                                <BlackToolTip title={rowData?.display_name} placement="top" arrow>
                                  {(rowData?.display_name.slice(0, 16)) + (rowData?.display_name.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                : rowData?.display_name : '--'
                            }
                          </Text>
                          <Text smallGrey10 > {rowData.role} </Text>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid container item lg={6} md={6} sm={6} xs={6} justifyContent={"end"} gap={1}>
                      <Text smallGrey10>{rowData?.date}</Text>
                      <Text smallGrey10>{rowData?.time}</Text>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} py={2}>
                      <Text smallBlack>{rowData.comment}</Text>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              ))
            }
          </Box> : ""}

        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
          <Input
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              name: 'comment',
              value: values.comment,
              inputProps: { maxLength: 150 }
            }}
            onKeyPress={onCharactersAndNumbersWithSpaceChange}
            labelText={'Comments'}
            handleChange={handleChange}
            rows={4}
            multiline={true}
            descriptionFormControl1
            descriptionInput
          />
        </Grid>

        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} justifyContent={"end"} gap={3}>
          <Button cancelBtn sx={{ minWidth: "60px !important", height: '40px !important' }} onClick={handleCloseDrawer}>Cancel</Button>
          <Button saveBtn sx={{ minWidth: "60px !important" }} onClick={handleComment}>Add</Button>
        </Grid>
      </Box>
    </div>
  )
}

export default CommentsDrawer
