import APIURL from "../../../config/development";
import LocalStorage from "../../../utils/LocalStorage";
import BaseApi from "../../BaseApi";
class OffboardApi {
    storeOffBoard(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `employee/off-board`, data, LocalStorage.getAccessToken());
    }
    getOffBoardCheckList(id, date) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/off-board?request_id=${LocalStorage.uid()}&employee_id=${id}&last_working_day=${date}`, LocalStorage.getAccessToken());
    }
    newstoreOffBoard(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `employee/finish-off-board`, data, LocalStorage.getAccessToken());
    }
    cancelOffBoarding(data){
        return BaseApi.postWithToken(APIURL.employee_URL + `employee/cancel-offboarding`, data, LocalStorage.getAccessToken());
    }
}
 // eslint-disable-next-line
export default new OffboardApi();