import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import offBoardSure from '../../assets/svg/offBoardSure.svg'
import { Grid } from 'rsuite';
import { BlackToolTip, addErrorMsg, capitalizeAndAddSpace, dateFormat } from '../../utils/utils';
import LocalStorage from '../../utils/LocalStorage';
import Text from '../customText/Text';
import CustomButton from '../customButton/Button'
import LoaderButton from '../customButton/LoadingButton'
import ReusablePopup from '../reuablePopup/ReusablePopup';
import ProjectEndDate from '../../assets/svg/ProjectEndDate.svg'
import SuccessImg from '../../assets/svg/SuccessImg.svg'
import Date from '../datePicker/Date';
import moment from 'moment';
import OffboardApi from '../../apis/admin/offboard/OffboardApi';
import OffboardingChecklistStyles from '../../views/admin/employees/userprofile/OffboardingChecklistStyles';


const CancelOffboarding =({cancelPopup,setCancelPopup,employee,offboardData,getEmployeeApi})=>{
    const offboardChecklistClasses = OffboardingChecklistStyles()
    const [placements,setPlacements] = useState([])
    const [showPlacements,setShowPlacements] = useState(false)
    const [showCancelSucess,setShowCancelSuccess] = useState(false)
    const [isSubmitting,setIsSubmitting] = useState(false)
    
    const handleSetPlacements = (placement_data)=>{
        const data = {...placement_data};
            const values = data.placement.data.map(each=>{
                return{
                    placement_id:each.placement_id,
                    company_name:each.company_name,
                    placement_start_date:each.placement_start_date,
                    placement_end_date:''
                }
            })
            setPlacements(values)
    }

      useEffect(()=>{
        if(offboardData?.placement?.data?.length>0){
            handleSetPlacements(offboardData)
        }
      },[offboardData])

      const dateChangePlacement = (e, name, index) => {
        let date = e == null ? '' : e.$d
        const newPlacements = [...placements];
        newPlacements[index] = {
            ...newPlacements[index],
            [name]: date === '' ? '' : moment(date).format(dateFormat())
        };
        setPlacements(newPlacements);
    }
    const handleCancel = () => {
        handleSetPlacements(offboardData)
        setShowPlacements(false)
    }

    const submitCancelOffboarding=()=>{
        setIsSubmitting(true)
        LocalStorage.removeRedirectedModule();
        LocalStorage.removeSubModule();
        LocalStorage.removeNotificationEmpId();
        let data = {
            employee_id:offboardData.employee_id,
            request_id:LocalStorage.uid(),
            placements
        }
        OffboardApi.cancelOffBoarding(data).then(response=>{
            if (response.data.statusCode == 1003) {
                setShowPlacements(false)
                setCancelPopup(false)
            }
            else {
                addErrorMsg(response.data.message)
            }
            setIsSubmitting(false);
            setShowCancelSuccess(true)    
        })

    }

    const handleSubmitCancel = ()=>{
        // setCancelPopup(false);
        LocalStorage.removeRedirectedModule();
        LocalStorage.removeSubModule();
        LocalStorage.removeNotificationEmpId();
        if(placements.length>0){
            setCancelPopup(false)
            setShowPlacements(true)
            return
        }else{
            submitCancelOffboarding()
        }
    }

    const handleDoneCancel = ()=>{
        setShowCancelSuccess(false)
        getEmployeeApi()
    }

    const showContent = ()=>{
        const dateSelected = placements.find(item=>item.placement_end_date!=='')
        if(placements.length>0 && dateSelected){
            return true
        }
        
        return false
    }
    
    return(
        <>
            <ReusablePopup white iconHide  fixedWidth openPopup={cancelPopup} setOpenPopup={setCancelPopup} >
                <Box sx={{display:'flex',flexDirection:'column',gap:2,alignItems:'center'}}>
                    <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={offBoardSure} alt='noactive' />
                    </Box>
                    <Box sx={{textAlign:'center'}}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                        <Text offBoardBodyFont sx={{ fontSize: '14px !important', fontWeight: `${400} !important`,textAlign:'center' }}>Do you wish to cancel offboarding <span style={{ color: '#0C75EB' }}>
                            <BlackToolTip title={capitalizeAndAddSpace(employee.basic_details.full_name)} placement="top" arrow>
                            {capitalizeAndAddSpace(employee.basic_details.full_name.slice(0, 16)) + capitalizeAndAddSpace(employee.basic_details.full_name.length > 16 ? "..." : " ")}
                            </BlackToolTip>
                        </span>. All the progress will be lost. </Text>
                    </Box>
                    <Box>
                    <Stack direction={"row"} spacing={3}>
                        <CustomButton smallBlueOutline onClick={() => {setCancelPopup(false); setIsSubmitting(false) }}>
                        No
                        </CustomButton>
                        <LoaderButton loading={isSubmitting} redBackground onClick={handleSubmitCancel}>
                            Yes, Cancel
                        </LoaderButton>

                    </Stack>
                    </Box>
                </Box>
               
            </ReusablePopup>
            <ReusablePopup white iconHide  fixedWidth450 openPopup={showPlacements} setOpenPopup={setShowPlacements} >
                <Box sx={{display:'flex',flexDirection:'column',gap:2,alignItems:'center'}}>
                    <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={ProjectEndDate} alt="OffBoard" />
                    </Box>
                    <Box sx={{textAlign:'center'}}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Project End Date</Text>
                        <Text offBoardHeadFont sx={{ fontSize: '14px !important', fontWeight: `${400} !important` }}>Please choose the end date for the current Project{placements.length>1 && 's'}</Text>
                    </Box>
                    <Box item container sx={{ display:'flex',flexDirection:'column',gap:2,width:'85%'}}>
                        {
                            placements?.map((item,idx)=>
                                <Grid item container lg={12} display={"flex"} mt={2} sx={{ backgroundColor: '#FCFCFC', padding: '10px' }} key={item.placement_id}>
                                    <Text offBoardHeadFont sx={{ fontSize: '14px !important', fontWeight: `${700} !important` }}>{item.company_name} <span style={{fontSize:'14px',fontWeight:400}}>(Optional)</span></Text>  
                                    <Grid item xs={6} container >
                                        <Date
                                            labelText={'End Date'}
                                            name='placement_end_date'
                                            value={item.placement_end_date}
                                            minDate={item.placement_start_date}
                                            onChange={(value => dateChangePlacement(value, 'placement_end_date', idx))}
                                            height='56px' 
                                        />
                                    </Grid>
                                </Grid>
                            )  
                            }
                    </Box>
                    <Box>
                    <Stack direction={"row"} spacing={3}>
                        <CustomButton popupCancelRed sx={{width:'122px !important',fontSize:'14px !important'}} onClick={handleCancel}>
                            Cancel
                        </CustomButton>
                        <LoaderButton loading={isSubmitting} popupSaveBlueHeight sx={{width:'122px !important',fontSize:'14px !important'}} onClick={submitCancelOffboarding}>
                            Continue
                        </LoaderButton>
                    </Stack> 
                    </Box>
                </Box>
            </ReusablePopup>
            <ReusablePopup openPopup={showCancelSucess} setOpenPopup={setShowCancelSuccess} white iconHide >
                <Box my={2} width={'450px'} sx={{textAlign:"center"}}>
                    <Box my={2} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={SuccessImg} alt="completed" />
                    </Box>
                    <Grid container justifyContent={'center'} my={1}>
                        <Text my={1} offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>
                            Off boarding cancelled successfully
                        </Text>

                    </Grid>
                    <Grid container justifyContent={'center'} my={1}>
                       {showContent() &&  
                            <Text offBoardBodyFont sx={{fontSize:'14px !important',textAlign:"center"}}>
                               The end dates for the placements are successfully updated and offboarding has been cancelled.
                            </Text>
                       }
                    </Grid>

                    <Box my={2} className={offboardChecklistClasses.popupHead1} mt={4}>
                        <CustomButton popupSaveBlue sx={{ width: "335px !important" }} 
                        onClick={handleDoneCancel}
                        >
                            Done
                        </CustomButton>
                    </Box>
                </Box>
            </ReusablePopup>
        </>
    )
}

export default CancelOffboarding;