import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Text from '../../../components/customText/Text';
import PlanPurchaseStyles from './PlanPurchaseStyles';
import logoName from '../../../assets/gif/japfuLoader.gif';
import planPurchaseImg from '../../../assets/svg/cash-sucess.svg';
import LoadingButton from '../../../components/customButton/LoadingButton';
import { removeExpiredLoader, eventSource } from '../../../utils/utils';
import logo from '../../../assets/svg/dashboard/New_Logo_Black.png';
import planFailed from '../../../assets/svg/Payment-failed.png';
function PlanSucess() {
    // eslint-disable-next-line
    const navigate = useNavigate(); // eslint-disable-next-line
    const classes = PlanPurchaseStyles();
    const [data, setData] = useState(''); // eslint-disable-next-line
    const [state, setState] = useState([]);
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        removeExpiredLoader()
    }, [])

    useEffect(() => {
        eventSource.addEventListener('paymentStatus', (event) => {
            setTimeout(() => {
                const data = JSON.parse(event.data);

                if (data?.status) {
                    setLoader(false);
                    setData(data.orderID);
                    setState(data);
                } else {
                    setLoader(false);
                }
            }, 6000);
        }, false);
    }, []);
    return (
        <Grid container lg={12} md={12} sm={12} xs={12} height={'100vh'} justifyContent='center' alignItems='center'>
            {
                loader ?
                    <Grid item xl={12} lg={12} container justifyContent='center' textAlign='center' pt={9} alignItems='center'>
                        <img src={logoName} alt="logoName" width={'100px'} height={'100px'}></img>
                    </Grid>
                    :
                    <>
                        {
                            state?.paymentStatus == 'paid' || state?.paymentStatus == 'succeeded' || state?.paymentStatus == 'success' ?

                                <Grid container lg={12} md={12} sm={12} xs={12} height={'100vh'} justifyContent='center'>
                                    <Grid item xs={12} justifyContent='center' textAlign='center' pt={4}>
                                        <img src={logo} alt='logo' style={{ height: '51px', width: '173px' }} />
                                    </Grid>
                                    <Grid item xl={12} lg={12} container justifyContent='center' textAlign='center' alignItems={'center'} >
                                        <img src={planPurchaseImg} alt='upgrade' />
                                    </Grid>
                                    <Grid item xl={12} lg={12} container justifyContent='center' textAlign='center' mt={-2}>
                                        <Text popupHead1 >Hurrayyyy!!!</Text>
                                    </Grid>
                                    <Grid item xl={12} lg={12} container justifyContent='center' textAlign='center' mt={-10}>
                                        <Text grey16 >Your payment has been successfully completed order id #{(data == undefined || data == null || data == '') ? Date.now() : data}</Text>
                                    </Grid>
                                    <Grid item xl={12} lg={12} container justifyContent='center' textAlign='center' mt={-12}>
                                        <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                            <Box className={classes.buttonStyles}>
                                                <LoadingButton
                                                    className={classes.loginButton}
                                                    variant="contained"
                                                    type="submit"
                                                    onClick={() => { navigate('/myprofile') }}
                                                >
                                                    Continue
                                                </LoadingButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                state == '' ?
                                    <Grid item xl={12} lg={12} container justifyContent='center' textAlign='center' pt={9} alignItems='center'>
                                        <img src={logoName} alt="logoName" width={'100px'} height={'100px'}></img>
                                    </Grid>
                                    :
                                    <Grid container lg={12} md={12} sm={12} xs={12} height={'100vh'} justifyContent='center'>
                                        <Grid item xs={12} justifyContent='center' textAlign='center' pt={4}>
                                            <img src={logo} alt='logo' style={{ height: '51px', width: '173px' }} />
                                        </Grid>
                                        <Grid item xl={12} lg={12} container justifyContent='center' textAlign='center' alignItems={'center'} >
                                            <img src={planFailed} alt='upgrade' />
                                        </Grid>
                                        <Grid item xl={12} lg={12} container justifyContent='center' textAlign='center' mt={-2}>
                                            <Text popupHead1 >Uh..Ohh!!!</Text>
                                        </Grid>
                                        <Grid item xl={12} lg={12} container justifyContent='center' textAlign='center' mt={-10}>
                                            <Text grey16 >Your payment didn't go through. Please re-try or change<br /> payment details.</Text>
                                        </Grid>
                                        <Grid item xl={12} lg={12} container justifyContent='center' textAlign='center' mt={-12}>
                                            <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                                <Box className={classes.buttonStyles}>
                                                    <LoadingButton
                                                        className={classes.loginButton}
                                                        variant="contained"
                                                        type="submit"
                                                        onClick={() => { navigate('/myprofile') }}
                                                    >
                                                        Re-try Payment
                                                    </LoadingButton>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                        }
                    </>
            }

            {/* <Grid item container lg={12} md={10} sm={12} xs={12} pl={2}>
                <Grid item lg={12} md={12} sm={12} xs={12} justifyContent='center'>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <img src={ArrowLeft} alt='ArrowLeft' style={{ cursor: 'pointer' }} onClick={() => navigate('/myprofile')}></img>
                        <Text blackHeader600 >My Profile</Text>
                    </Box>
                </Grid>
            </Grid> */}

        </Grid>
    )
}

export default PlanSucess
