import Chart from "react-apexcharts";

const PieChart = (props) => {
  const defaultOptions = {
    labels: props.labels,
    customScale: 1.5,
    colors: ['#0C75EB', '#77D2B7', '#FFB126', '#FFD0F2', '#96A7FF', '#84D277', '#A3A600'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const options = props.options ? props.options : defaultOptions;
  const series = props.series;
  const height = props.height ? props.height : "160px";
  const width = props.width ? props.width : "100%";

  return (
    <Chart
      {...props}
      options={options}
      series={series}
      type="pie"
      height={height}
      width={width}
    />
  );
};

export default PieChart;
