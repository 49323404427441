import BaseApi from '../../BaseApi'
import APIURL from "../../../config/development";
import LocalStorage from '../../../utils/LocalStorage';

class AnnouncementsApi {
    getAnnouncementsListing(params) {
        let { search, limit, page, platform } = params
        return BaseApi.getWithParams(APIURL.org_URL + `announcement/listing?request_id=${LocalStorage.uid()}&platform=${platform}&search=${search}&page=${page}&limit=${limit}`, LocalStorage.getAccessToken());
    }
    getAnnouncementsIndex(id) {
        return BaseApi.getWithParams(APIURL.org_URL + `announcement/index?request_id=${LocalStorage.uid()}&id=${id}`, LocalStorage.getAccessToken());
    }
    StoreAnnouncements(data) {
        return BaseApi.postWithToken(APIURL.org_URL + 'announcement/store', data, LocalStorage.getAccessToken());
    }
    deleteAnnouncements(data, id) {
        return BaseApi.deleteWithToken(APIURL.org_URL + `announcement/destroy/${id}`, data, LocalStorage.getAccessToken())
    }
}
// eslint-disable-next-line
export default new AnnouncementsApi();
