import APIURL from "../../../config/development";
import LocalStorage from "../../../utils/LocalStorage";
import BaseApi from "../../BaseApi";

class DashboardApi {
    getPayrollAnalysis(from_date, to_date) {
        return BaseApi.getWithParams(APIURL.API_URL + `payroll/dashboard/payroll-analysis?request_id=${LocalStorage.uid()}&from_date=${from_date}&to_date=${to_date}`, LocalStorage.getAccessToken());
    }

    getPayrollHistory(from_date, to_date) {
        return BaseApi.getWithParams(APIURL.API_URL + `payroll/dashboard/payroll-history?request_id=${LocalStorage.uid()}&from_date=${from_date}&to_date=${to_date}`, LocalStorage.getAccessToken());
    }

    getupcomingPayroll() {
        return BaseApi.getWithParams(APIURL.API_URL + `payroll/dashboard/upcoming-payroll?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    getPayrollComparison(previous_from_date, previous_to_date, present_from_date, present_to_date) {
        return BaseApi.getWithParams(APIURL.API_URL + `payroll/dashboard/payroll-comparison?request_id=${LocalStorage.uid()}&previous_from_date=${previous_from_date}&previous_to_date=${previous_to_date}&present_from_date=${present_from_date}&present_to_date=${present_to_date}`, LocalStorage.getAccessToken())
    }

    getpayrollCycles() {
        return BaseApi.getWithParams(APIURL.API_URL + `payroll-config/payroll-configuration/calendar?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }
 
}

export default new DashboardApi()