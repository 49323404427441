import React, { useState } from 'react'
// import ViewStyles from './ViewStyles';
import { Box, Grid, Skeleton } from '@mui/material';
import Text from '../../../../components/customText/Text';
import EditIcon from '../../../../assets/svg/newEdit.svg';
import moment from 'moment';
import { dateFormat } from '../../../../utils/utils';
import Checkbox from '@mui/material/Checkbox';
import TimeSheetConfigurationForm from './TimeSheetConfigurationForm';
import PlacementApi from '../../../../apis/admin/placements/PlacementApi';
import { useEffect } from 'react';
import CommonApi from '../../../../apis/CommonApi';
import LocalStorage from '../../../../utils/LocalStorage';

function TimesheetConfiguration(props) {

  const placementID = props.id ? props.id : "";
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [employees, setEmployees] = useState([]);
  var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
  const [state, setState] = useState({
    default_hours: "",
    timesheet_cycle: "",
    is_timesheet_attachment_mandatory: false,
    timesheet_start_date: "",
    timesheet_start_day: "",
    timesheet_next_cycle_start: "",
    approval_levels: []
  });

  useEffect(() => {
    employeesList();
    getPlacementTimesheetData(placementID)// eslint-disable-next-line
  }, []);

  const employeesList = () => {
    CommonApi.getTimesheetApprovers().then((response) => {
      if (response.data.statusCode == 1003) {
        setEmployees(response.data.data);
      }
    });
  }

  const getPlacementTimesheetData = (id) => {
    setLoading(true);
    PlacementApi.getPlacementIndex("timesheet-details", id).then((res) => {
      setLoading(false);
      if (res.data.statusCode === 1003) {
        res.data.data.timesheet_details['deleted_level_ids'] = [];
        res.data.data.timesheet_details['deleted_approver_ids'] = [];
        // res.data.data.timesheet_details['timesheet_next_cycle_start'] = res.data.data.timesheet_details
        setState({ ...res.data.data.timesheet_details });
        setLoading(false);
      } else {
        setLoading(false);
      }
    })
  }

  const handleEdit = () => {
    props.setformEdit(true);
    if (state.approval_levels.length == 0) {
      state.approval_levels = [{
        id: "",
        level: 1,
        approval_users: []
      }];
      setState({ ...state });
    }
    setEdit(true);
  }

  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {
          edit ?
            <TimeSheetConfigurationForm placementID={placementID} getPlacementTimesheetData={getPlacementTimesheetData} employees={employees} setEdit={setEdit} edit={edit} setState={setState} state={state} setformEdit={props.setformEdit} />
            :
            <Grid container p={3} alignItems={"center"}>
              <Grid item lg={6} md={6} sm={6}>
                <Text largeBlue>Timesheet Configuration</Text>
              </Grid>
              <Grid item lg={6} md={6} sm={6} textAlign={"end"}>
                {
                  props.status !== "In Active" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_edit" && item.is_allowed == true))) ?
                    <>
                      {!loading && <img onClick={() => handleEdit()} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} />}
                    </>
                    : ""
                }
              </Grid>

              <Grid item lg={4} md={4} sm={4} pt={4}>
                {
                  loading ?
                    <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                    :
                    <>
                      <Text mediumGreyHeader1>Timesheet Cycle</Text>
                      <Text mediumBlack14 pt={0.8} noWrap>{state.timesheet_cycle ? state.timesheet_cycle : "--"}</Text>
                    </>
                }
              </Grid>
              <Grid item lg={4} md={4} sm={4} pt={4}>
                {
                  loading ?
                    <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                    :
                    <>
                      <Text mediumGreyHeader1>Default Hours</Text>
                      <Text mediumBlack14 pt={0.8} noWrap>{state.default_hours ? state.default_hours : "--"}</Text>
                    </>
                }
              </Grid>
              <Grid item lg={4} md={4} sm={4} pt={4}>
                {
                  loading ?
                    <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                    :
                    <>
                      <Text mediumGreyHeader1>Timesheet Actual Start Date</Text>
                      <Text mediumBlack14 pt={0.8} noWrap>{state.timesheet_start_date ? moment(state.timesheet_start_date, dateFormat()).format(dateFormat()) : "--"}</Text>
                    </>
                }
              </Grid>
              {
                loading ?
                  <Grid item lg={4} md={4} sm={4} pt={4}>
                    <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                  </Grid>
                  :
                  <>
                    {
                      state.timesheet_next_cycle_start !== "" ?
                        <Grid item lg={4} md={4} sm={4} pt={4}>
                          <Text mediumGreyHeader1>Timesheet Effective Start Date</Text>
                          <Text mediumBlack14 pt={0.8} noWrap>{state.timesheet_next_cycle_start ? moment(state.timesheet_next_cycle_start, dateFormat()).format(dateFormat()) : "--"}</Text>
                        </Grid> : ""
                    }
                  </>
              }
              {
                state.timesheet_cycle !== "" && (state.timesheet_cycle == "Weekly" || state.timesheet_cycle == "Bi Weekly") ?
                  <Grid item lg={4} md={4} sm={4} pt={4}>
                    {
                      loading ?
                        <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                        :
                        <>
                          <Text mediumGreyHeader1>Week Start Day</Text>
                          <Text mediumBlack14 pt={0.8} noWrap>{state.timesheet_start_day ? state.timesheet_start_day : "--"}</Text>
                        </>
                    }
                  </Grid>
                  :
                  ""
              }

              <Grid item lg={12} md={12} sm={12} pt={4}>
                <Text largeBlue>Timesheet Approval Configuration</Text>
              </Grid>
              {
                loading ?
                  <Box pt={"1s0px "} >
                    <Skeleton variant='rounded' animation="wave" width={300} height={25} />
                  </Box>
                  :
                  <>
                    {
                      state.approval_levels && state.approval_levels.length > 0 ?
                        <>
                          {
                            state.approval_levels.map((item, index) => (
                              <>
                                <Grid item lg={10} md={10} sm={10} pt={3}>
                                  <Text mediumGreyHeader1>Level {index + 1} Approver (Optional)</Text>
                                  <Grid item container lg={12} md={12} sm={12} >
                                    {item.approval_users.map((i, ind) => (<Text mediumBlack14 pt={0.8}>{i.employee_name}{ind + 1 < item.approval_users.length ? ', ' : ''} &nbsp;</Text>))}
                                  </Grid>
                                </Grid>
                              </>
                            ))
                          }
                        </> :
                        ""

                    }

                  </>
              }

              <Grid item container lg={12} md={12} sm={12} pt={4} alignItems={"center"}>
                {
                  loading ?
                    <Box pt={"30px "} >
                      <Skeleton variant='rounded' animation="wave" width={300} height={25} />
                    </Box>
                    :
                    <>
                      <Checkbox sx={{ paddingLeft: "0px !important" }} size='small' checked={state.is_timesheet_attachment_mandatory} disabled />
                      <Text mediumGreyHeader1 sx={{ font: "15px Quicksand !important", color: "#1A141F !important" }}>Make timesheet attachment mandatory</Text>
                    </>
                }
              </Grid>
            </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default TimesheetConfiguration