import React, { useEffect, useState } from 'react'
import { Box, Grid, Skeleton } from '@mui/material'
import Text from '../../../../../components/customText/Text';
import CustomSelect from '../../../../../components/customSelect/CustomSelect';
import MainStyles from '../../MainStyles'
import Button from '../../../../../components/customButton/Button';
import DefaultInvoiceCycleApi from '../../../../../apis/configurations/invoices/DefaultInvoiceCycleApi';
import CommonApi from '../../../../../apis/CommonApi';
import LocalStorage from '../../../../../utils/LocalStorage';
import { addErrorMsg, addSuccessMsg, addWarningMsg } from '../../../../../utils/utils';
import TimeSheetApi from '../../../../../apis/admin/placements/TimeSheetApi';
import { isValid, validate_emptyField } from '../../../../../components/Validation';

function DefaultInvoiceCycle({ current }) {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = MainStyles()
    const [drop, setDrop] = useState([])
    const [dropdown, setDropdown] = useState([]);
    const [dayslist, setDaysList] = useState([]);
    const [action, setAction] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});

    const [state, setState] = useState({
        cycle_id: '',
        day_start_id: '',
        id: '',
        is_global: true,
        net_pay_terms_id: ''
    })

    useEffect(() => {
        setTimeout(() => {
            getDepartmentDropdown()
            cycleDropdown()
            indexApi()
            daysDropdown()
        }, 300)
    }, [])

    const validateAll = () => {
        let { cycle_id, day_start_id, net_pay_terms_id } = state
        let errors = {};
        errors.cycle_id = validate_emptyField(cycle_id);
        errors.day_start_id = validate_emptyField(day_start_id);
        errors.net_pay_terms_id = validate_emptyField(net_pay_terms_id);
        setError(errors);
        return errors;
    }

    const handleSaveAndEdit = () => {
        let errors = validateAll();
        if (isValid(errors)) {
            if (state.id === '') {
                state['request_id'] = LocalStorage.uid();
                DefaultInvoiceCycleApi.storeInvoiceApi(state).then((response) => {
                    if (response.data.statusCode == 1003) {
                        indexApi()
                        addSuccessMsg(response.data.message);
                    } else {
                        addErrorMsg(response.data.message);
                    }
                });
            } else {
                updateApi(state.id)
            }
        } else {
            let s1 = { error }
            s1 = errors
            setError(s1);
            addWarningMsg("Please verify and resubmit the form as some fields have not been filled in or contain invalid data");
        }
    }
    const getDepartmentDropdown = () => {
        DefaultInvoiceCycleApi.dropDownApi().then((response) => {
            if (response.data.statusCode == 1003) {
                setDrop(response.data.data)
            }
        });
    };
    const updateApi = (dataId) => {
        let data = { ...state, request_id: LocalStorage.uid() };
        DefaultInvoiceCycleApi.updateApi(data, dataId).then((response) => {
            if (response.data.statusCode == 1003) {
                indexApi()
                addSuccessMsg(response.data.message);

            } else {
                addErrorMsg(response.data.message);
            }
        });
    };
    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value }, handleValidates(e.target, error))
    };

    const handleValidates = (e) => {
        let input = e
        switch (input.name || input.targetName) {
            case 'cycle_id':
                error.cycle_id = validate_emptyField(input.value)
                break
            case 'day_start_id':
                error.day_start_id = validate_emptyField(input.value)
                break
            case 'net_pay_terms_id':
                error.net_pay_terms_id = validate_emptyField(input.value)
                break
            default:
                break
        }
        setError({ ...error });
    }

    const cycleDropdown = () => {
        CommonApi.getCycleDropdown(LocalStorage.uid(), LocalStorage.getAccessToken()).then((response) => {
            if (response.data.statusCode == 1003) {
                setDropdown(response.data.data);
            }
        });
    };
    const indexApi = () => {
        setTimeout(true)
        DefaultInvoiceCycleApi.indexApi(
        ).then((response) => {
            setTimeout(() => {
                setLoading(false)
                if (response.data.statusCode == 1003) {
                    if (response.data.data.length > 0) {
                        setState({ ...response.data.data[0] });
                        setAction('view');
                    } else {
                        setState({
                            cycle_id: '',
                            day_start_id: '',
                            id: '',
                            is_global: true,
                            net_pay_terms_id: ''
                        })
                        setAction('')
                    }
                } else {
                    addErrorMsg(response.data.message);
                }
            }, 400)
        });
    }
    const daysDropdown = () => {
        TimeSheetApi.getDaysDropdown(LocalStorage.uid(), LocalStorage.getAccessToken()).then((response) => {
            if (response.data.statusCode == 1003) {
                setDaysList(response.data.data);
            }
        });
    };
    return (
        <Box sx={{
            height: '75vh',
            overflow: 'auto',
            padding: '16px',
        }}>
            <Box className={classes.activeItemBox}>
                <Box className={classes.activeBoxHeading}><Text RegularBlack1 >{current}</Text></Box>
                {
                    loading ? <>
                        {[1].map((item, index) => (
                            <Grid container key={index} spacing={2}>
                                <Grid item lg={6}>
                                    <Skeleton animation="wave" height="100px" />
                                    <Skeleton animation="wave" height="100px" />

                                </Grid>
                                <Grid item lg={6}>
                                    <Skeleton animation="wave" height="100px" />
                                    <Skeleton animation="wave" height="100px" />
                                </Grid>
                            </Grid>
                        ))}
                    </>
                        :
                        <Grid container spacing={'20px'}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box pt={'8px'}>
                                    <CustomSelect
                                        disabled={action == 'view' ? true : false}
                                        name={'net_pay_terms_id'}
                                        value={state.net_pay_terms_id}
                                        commonSelect
                                        options={drop}
                                        label={'Payment Terms'}
                                        onChange={handleChange}
                                    />
                                    {error && <Text red>{error.net_pay_terms_id ? error.net_pay_terms_id : ''}</Text>}
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box pt={'8px'}>
                                    <CustomSelect
                                        disabled={action == 'view' ? true : false}
                                        name={'cycle_id'}
                                        value={state.cycle_id}
                                        commonSelect
                                        options={dropdown}
                                        label={'Invoice Cycle'}
                                        onChange={handleChange}
                                    />
                                    {error && <Text red>{error.cycle_id ? error.cycle_id : ''}</Text>}
                                </Box>
                            </Grid>
                            {state.cycle_id == 1 || state.cycle_id == 2 ?
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box pt={'8px'}>
                                        <CustomSelect
                                            name={'day_start_id'}
                                            value={state.day_start_id}
                                            disabled={action == 'view' ? true : false}
                                            label='Day Starts From'
                                            onChange={handleChange}
                                            options={dayslist}
                                            commonSelect
                                        />
                                        {error && <Text red>{error.day_start_id ? error.day_start_id : ''}</Text>}
                                    </Box>
                                </Grid> : ""}
                        </Grid>}
            </Box>
            <Box display={'flex'} justifyContent={'end'} mt={'22px'} gap={2}>
                <Button BorderBlueButton sx={{ minWidth: "100px !important" }} onClick={() => indexApi()}>Cancel</Button>
                {
                    (LocalStorage.getUserData().super_admin || (rolePermission!==""&&rolePermission.some(item => item.slug == "configurations_edit" && item.is_allowed == true) && rolePermission!==""&&rolePermission.some(item => item.slug == "configuration_invoice" && item.is_allowed == true))) ?
                        <Button popupSaveBlue onClick={() => action == 'view' ? setAction('update') : handleSaveAndEdit()}>{action == 'view' ? "Edit" : "Save"}</Button> :
                        <Button popupSaveBlueDisable>{action == 'view' ? "Edit" : "Save"}</Button>
                }
            </Box>
        </Box>
    )
}

export default DefaultInvoiceCycle;
