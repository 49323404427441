import { Box, Breadcrumbs, Checkbox, Dialog, DialogContent, FormControlLabel, Grid, Skeleton, Slide, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Text from '../../../components/customText/Text'
import AppIntegrationsStyles from './AppIntegrationsStyles';
import { useNavigate } from 'react-router-dom';
import Component87 from '../../../assets/svg/Component87.svg';
import LocalStorage from '../../../utils/LocalStorage';
import InstalledAppsApi from '../../../apis/InstalledAppsApi';
import { NoPermission, addErrorMsg } from '../../../utils/utils';
import { ReactComponent as CheckedIcon } from '../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../assets/svg/CheckedBorderIcon.svg';
import Button from '../../../components/customButton/Button';
import ReusableDelete from '../../../components/reuablePopup/ReusableDelete';
import submit from '../../../assets/svg/timesheet_submit.svg';
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
        width: "500px"
    }
}));// eslint-disable-next-line
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DeregisterApps() {

    const classes = AppIntegrationsStyles();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [listLoad, setListLoad] = useState(false);
    const [connectedList, setConnectedList] = useState([]);
    const [deregister, setDeregister] = useState([]);
    const [open, setOpen] = useState(false);
    const [btnLoad, setBtnLoad] = useState(false);
    const [success, setSuccess] = useState(false);
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    useEffect(() => {
        if (LocalStorage.getAppIntegrationId()) {
            getAppData(LocalStorage.getAppIntegrationId());
            getConnectedOrganizations();
        }  // eslint-disable-next-line
    }, [])

    const getAppData = (param) => {
        setLoading(true);
        InstalledAppsApi.getSingleApp(param).then((res) => {
            setLoading(false);
            if (res.data.statusCode === 1003) {
                setData(res.data.data[0]);
            } else {
                addErrorMsg(res.data.message)
            }
        })
    }

    const getConnectedOrganizations = () => {
        setListLoad(true);
        InstalledAppsApi.getZohoOrganizationList({ authorization_code: "", status: "registered" }).then((res) => {
            setListLoad(false);
            if (res.data.statusCode === 1003) {
                setConnectedList(res.data.data);
            }
        })
    }

    const handelCheck = (e) => {
        var newFilterData = deregister;
        if (newFilterData.includes(e.target.value)) {
            newFilterData.splice(newFilterData.findIndex(item => item === e.target.value), 1)
        } else {
            newFilterData.push(e.target.value)
        }
        setDeregister([...newFilterData]);
    }

    const handleDeRegister = () => {
        if (deregister.length > 0) {
            setOpen(true);
        } else {
            addErrorMsg("Please select any organization to deregister")
        }
    }

    const deRegister = () => {
        setBtnLoad(true);
        var data = {
            request_id: LocalStorage.uid(),
            organization_ids: deregister,
            de_register: true,
        }
        InstalledAppsApi.deregisterOrganization(data).then((res) => {
            setBtnLoad(false);
            if (res.data.statusCode === 1003) {
                setOpen(false);
                setSuccess(true);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    return (
        <Box className={classes.mainBox1}>
            <Box mx={2}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography onClick={() => { navigate("/myProfile") }} className={classes.breadcrumbsLink}>Profile</Typography>
                    <Typography onClick={() => { navigate("/app-integrations") }} className={classes.breadcrumbsLink}>App Integrations</Typography>
                    <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>Installed Apps</Text>
                </Breadcrumbs>
            </Box>
            {
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "app_integrations_view" && item.is_allowed == true))) ?
                    <Grid container>
                        <Grid item lg={6} md={6} sm={6} xs={12} pt={3}>
                            {
                                loading ?
                                    <Skeleton variant="rounded" height={70} width={300} />
                                    :
                                    <Box className={classes.logoBox}>
                                        <img src={data.logo_url} alt="logo" height={"75px"} width={"198px"} />
                                        <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                        <Box >
                                            <Text mediumOverView pt="5px">Connected</Text>
                                        </Box>
                                    </Box>
                            }
                        </Grid>
                        <Grid container alignItems={"center"} justifyContent={"end"} item lg={6} md={6} sm={6} xs={12} pt={3}>
                            {
                                loading ?
                                    <Skeleton variant="rounded" height={70} width={300} />
                                    :
                                    <a rel="noopener noreferrer" style={{ color: "#0C75EB" }} href={data && data.help_documentation_url && data.help_documentation_url} target="_blank" className={classes.helpText}>Help Documentation</a>
                            }
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                            {
                                loading ?
                                    <Skeleton variant="rounded" height={40} width={600} />
                                    :
                                    <Text largeBlack22>Select the Organization you wish to deregister</Text>
                            }
                        </Grid>
                        <Box sx={{ height: "45vh", overflow: "auto", paddingTop: "20px", width: "100%", paddingLeft: "5px" }}>
                            {
                                listLoad ?
                                    [1, 2, 3, 4].map((item) => (
                                        <Grid container>
                                            <Grid item lg={6} md={6} sm={6} xs={6} pt={1}>
                                                <Skeleton variant="rounded" width={250} height={25} />
                                            </Grid>
                                        </Grid>
                                    )) :
                                    <>
                                        {
                                            connectedList.length > 0 && connectedList.map((item) => (
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <FormControlLabel
                                                        key={1}
                                                        control={
                                                            <Checkbox
                                                                size="small"
                                                                name={"checked"}
                                                                value={item.id}
                                                                onChange={(e) => { handelCheck(e) }}
                                                                icon={<CheckBorderIcon />}
                                                                checkedIcon={<CheckedIcon />}
                                                                checked={deregister.includes(item.id) ? true : false} />}
                                                        label={<Text largeGreyTxt>{item.value}</Text>}
                                                    />
                                                </Grid>
                                            ))
                                        }
                                    </>
                            }
                        </Box>
                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"end"}>
                            <Button onClick={() => { handleDeRegister() }} saveBtn className={classes.configBtn}>De-register</Button>
                        </Grid>
                    </Grid>
                    :
                    <>
                        {NoPermission()}
                    </>
            }
            {open && <ReusableDelete loading={btnLoad} deregister={true} content={"Do you want to de-register the application from Japfu?"} open={open} setOpen={setOpen} onClick={() => { deRegister() }} />}

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={success}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent sx={{ margin: "30px", }}>
                    <Grid container>
                        <Grid item md={12} sm={12} textAlign={"center"}>
                            <img src={submit} alt='submit' />
                        </Grid>
                        <Grid item md={12} sm={12} pt={2} textAlign={"center"}>
                            <Text offBoardHeadFont sx={{ fontSize: "18px !important", fontWeight: `${700} !important` }}>Organization de-registered Successfully</Text>
                        </Grid>
                        <Grid item md={12} sm={12} pt={1} textAlign={"center"}>
                            <Text offBoardBodyFont>You have successfully de-registered the application with Japfu</Text>
                        </Grid>
                        <Grid item md={12} sm={12} mt={3} textAlign={"center"}>
                            <Button sx={{ width: "125px !important", font: "15px Quicksand !important", fontWeight: `${600} !important` }} smallBlue redBackground onClick={() => { navigate('/myProfile') }} >
                                Done
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        </Box>
    )
}

export default DeregisterApps