import { makeStyles } from "@mui/styles"
import { btnBgBlue } from "../../../../../theme"

const RehireStyles = makeStyles((theme) => ({
    dropZone: {
        background: '#F0F7FF',
        border: `1px dashed #0C75EB !important`,
        padding: '20px',
        borderRadius: '12px'
    },
    cursor:{
        cursor:'pointer'
    },
    chipLabel:{
        font: "12px Quicksand !important",
        letterSpacing: '0px',
        color: `${btnBgBlue.shade4} !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        margin:'8px 8px 0px 0px !important'
    }
}))

export default RehireStyles