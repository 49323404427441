import React, { useEffect, useState } from 'react';
import UserProfileStyles from './UserProfileStyles';
import { Box, Stack, Grid, Divider, ListItemButton, Skeleton, Button } from '@mui/material';
import CustomButton from '../../../components/customButton/Button';
import GeneralDetails from './generalDetails/GeneralDetails';
import Documents from './documents/index';
import Skills from './skills';
import PayConfiguration from './bankDetails/BankDetails';
import EmployeeAPI from '../../../apis/admin/employees/EmployeesApi';
import { BlackToolTip, addErrorMsg } from '../../../utils/utils';
import Text from '../../../components/customText/Text';
import { addSuccessMsg } from '../../../utils/utils';
import LocalStorage from '../../../utils/LocalStorage';
import verified from '../../../assets/svg/Component87.svg';
import Verify from '../../../assets/svg/verify_icon.svg';
import Pending from '../../../assets/employee/Orange-PendingIcon.svg';
import Rejected from '../../../assets/svg/Rejected.svg';
import { useNavigate } from 'react-router-dom';
import CommonApi from '../../../apis/CommonApi';
import eVerifyPending from '../../../assets/employee/pendingEverify.svg';
import warning from '../../../assets/employee/info.svg';
import ViewEmergency from './generalDetails/ViewEmergency';
import AllSections from './documents/AllSections';
import CircularProgress from '../../../components/progressbar/CircularProgress';
import offBoardSave from "../../../assets/svg/offBoardSave.svg"
import ReusablePopup from '../../../components/reuablePopup/ReusablePopup';
import PendingIcon from '../../../assets/svg/Vector (1).svg';

const dataArr = ["General Details", "Documents", "Bank Details", "Skills"];


export default function EmployeeUserProfile(props) {
    const classes = UserProfileStyles();
    const [current, setCurrent] = useState("General Details");
    const [stepItem, setStepItem] = useState('')
    const [openPopup, setOpenpopup] = useState(false)
    const [employee, setEmployee] = useState({
        basic_details: {
            first_name: "",
            middle_name: "",
            last_name: "",
            dob: "",
            gender: "",
            blood_group: "",
            marital_status_id: "",
            full_name: "",
            gender_description: "",
        },
        contact_details: {
            contact_number: "",
            alternate_contact_number: "",
            email_id: "",
            alternate_email: "",
        },
        current_address: {
            address_line_one: "",
            zip_code: "",
            city: "",
            state_id: "",
            country_id: "",
        },
        emergency_contacts: [{
            id: "",
            relationship_id: "",
            name: "",
            email_id: "",
            contact_number: "",
            address_1: "",
            city: "",
            zip_code: "",
            state_id: "",
            country_id: ""
        }],
        employment_details: {
            reference_id: "",
            date_of_joining: "",
            employment_type_id: "",
            employment_category_id: "",
            ssn: "",
            is_usc: "",
            visa_type_id: "",
            reporting_manager_id: "",
            department_id: "",
            team_id: "",
            role_id: "",
            vendor_id: '',
            vendor_price: '',
            showFullSSN: false
        },
        profile_picture_url: "",
        enable_login: "",
        profile_progress: "",
        e_verified: "",
        marital_status_id: ""
    });
    const [EmployeeProfile, setEmployeeProfile] = useState();// eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [documentPosition, setdocumentPosition] = useState('education');
    const [formEdit, setformEdit] = useState(false);

    useEffect(() => {
        // Call the API if either `current` or `props.formEdit` changes
        if (current && props.formEdit === false) {
            getEmployeeApi();
        }
        // eslint-disable-next-line
    }, [current, props.formEdit]);  // Dependency array includes both `current` and `formEdit`


    useEffect(() => {
        props.setformEdit(formEdit)
        // eslint-disable-next-line
    }, [formEdit])

    const getEmployeeApi = () => {
        setLoading(true);
        if (LocalStorage.getUserData().login_id !== '') {
            EmployeeAPI.getEmployeeConsultant(LocalStorage.getUserData().login_id).then((response) => {
                setTimeout(() => {
                    if (response.data.statusCode == 1003) {
                        setLoading(false);
                        setEmployee({ ...response.data.data });
                        setEmployeeProfile(response.data.data.profile_picture_url);
                    } else {
                        addErrorMsg(response.data.message);
                    }
                }, 400)
            });
        }
    }


    const uploadProfile = (e) => {
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg'].includes(r))) {
            const formData = new FormData();
            formData.append("files", e.target.files[0]);
            formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
            CommonApi.documentUpload("employee-profile", formData, LocalStorage.getAccessToken())
                .then((response) => {
                    if (response.data.statusCode == 1003) {
                        setEmployeeProfile(response.data.data.id);
                        ProfileUpdateApi(response.data.data.id)
                    } else {
                        setLoading(false)
                        addErrorMsg(response.data.message);
                    }
                });
        } else {
            addErrorMsg("Upload Valid File(png,jpg,jpeg).");
        }
    }

    const ProfileUpdateApi = (args) => {
        const data = {
            request_id: LocalStorage.uid(),
            id: LocalStorage.getUserData().login_id,
            documents: [
                {
                    new_document_id: args
                }
            ]
        }
        EmployeeAPI.profileUpload(LocalStorage.getUserData().login_id, data).then((res) => {
            if (res.data.statusCode == 1003) {
                setLoading(false);
                // addSuccessMsg('Profile Uploaded Successfully');
                EmployeeProfile === null || (EmployeeProfile && EmployeeProfile.includes('avatar')) ? addSuccessMsg('Profile Uploaded Successfully') : addSuccessMsg('Profile Updated Successfully');
                getEmployeeApi();
            } else {
                setLoading(false);
                addErrorMsg(res.data.message);
            }
        })
    }


    const navigate = useNavigate()

    const checkDocumentComplete = (item) => {
        if (employee.employment_details.employment_type_id == 1) {

        }
        else {
            if (item == 'Documents') {
                return employee.documents_pending
            }
            else if (item == 'Bank Details') {
                return employee.bank_pending
            }
            else if (item == 'Skills') {
                return employee.skills_pending
            }
        }
    }

    const navigateToComponent = () => {
        if (employee.documents_pending) {
            if (employee.complete_profile == 'education_documents') {
                setCurrent('Documents'); setdocumentPosition('education')
            }
            else if (employee.complete_profile == 'passport_document') {
                setCurrent('Documents'); setdocumentPosition('work')
            }
            else if (employee.complete_profile == 'i94_document') {
                setCurrent('Documents'); setdocumentPosition('work')
            }
            else if (employee.complete_profile == 'visa_document') {
                setCurrent('Documents'); setdocumentPosition('work')
            }
            else if (employee.complete_profile == 'personal_documents') {
                setCurrent('Documents'); setdocumentPosition('visa')
            }
        }
        else if (employee.bank_pending) {
            setCurrent('Bank Details'); setdocumentPosition('education')
        }
        else if (employee.skills_pending) {
            setCurrent('Skills'); setdocumentPosition('education')
        }

    }

    const ProfileDeleteApi = () => {
        let data = {
            request_id: LocalStorage.uid(),
            id: LocalStorage.getUserData().login_id
        }
        EmployeeAPI.deleteProfileApi(data).then((res) => {
            if (res.data.statusCode == 1003) {
                setLoading(false);
                addSuccessMsg('Profile Deleted Successfully');
                getEmployeeApi();
            } else {
                setLoading(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    return (
        <Box className={classes.mainContainer} px={5} py={1}>
            <Box my={2} mx={2}>
                <Grid container lg={12} columnSpacing={4}>
                    <Grid item container lg={3.2} md={3} sm={3} xs={12}>
                        <Box style={{ width: "100%", height: "78.5vh", borderRadius: "8px" }}>
                            <Box px={3} py={1} pt={4}>
                                <Box my={1} mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                    {
                                        loading ?
                                            <Skeleton variant="circular" width={50} height={50} /> :
                                            <CircularProgress
                                                percentage={employee.profile_progress}
                                                color={employee.profile_progress >= 76 ? 'green' : employee.profile_progress <= 75 && employee.profile_progress >= 51 ? 'yellow' : employee.profile_progress < 50 ? "#FFBF00" : ''}
                                                src={EmployeeProfile}
                                                name={EmployeeProfile == undefined || EmployeeProfile == null || EmployeeProfile == '' ? employee.basic_details && employee.basic_details.full_name[0] : ''}
                                                imgWidth='60px'
                                                imgHeight='60px'
                                                avatarWidth='60px'
                                                avatarHeight='60px'
                                                value={employee.profile_progress}
                                                size={70}
                                                edit={true}
                                                handleEdit={(e) => uploadProfile(e)}
                                                deleteButton={ProfileDeleteApi}
                                                tooltip
                                                tooltipContent={
                                                    <Box p={'3px 0px'} width='100%'>
                                                        <Text mediumBoldWhite400>{`Profile Completion - ${employee.profile_progress}%`}</Text>
                                                        {
                                                            employee.profile_progress == 100 ? '' : <CustomButton addButtonTooltip sx={{ marginTop: '10px' }} onClick={() => { navigateToComponent() }}>Complete Profile</CustomButton>
                                                        }
                                                    </Box>
                                                }
                                            />
                                    }

                                </Box>
                                {/* <Grid item lg={12} xs={12} p={1} textAlign='center' justifyContent='center'>
                                    <Button className={classes.uploadBtn} onClick={employee.is_off_boarding_initiated ? '' : clientAvatarUpload}>
                                        {employee.is_off_boarding_initiated ? <Text smallLabel sx={{ cursor: 'default !important' }}>Change Profile Picture</Text> : `Change Profile Picture`}
                                        {loader ? <CircularProgress color="secondary" size={"12px"} sx={{ margin: "0px 2px" }} /> : null}
                                    </Button>
                                    <input
                                        name='avatar'
                                        type='file'
                                        onChange={(e) => uploadProfile(e)}
                                        ref={AvatarInputRef}
                                        style={{ display: 'none' }}
                                    />
                                </Grid> */}
                                <Grid item container lg={12} pt={1} spacing={0} alignItems='center'>
                                    <Grid item lg={12}>
                                        {/* {
                                            loading ?
                                                <Skeleton width='150px' height='40px' animation='wave' />
                                                : */}
                                        <Box display='flex' flexDirection='row' gap={1} justifyContent='center'>
                                            <Text className={classes.profileName} >
                                                <BlackToolTip arrow placement='top' title={employee.basic_details.full_name}>
                                                    {employee.basic_details.full_name.slice(0, 16)}{employee.basic_details.full_name.length > 16 ? "..." : ""}
                                                </BlackToolTip>
                                            </Text>
                                            {employee.e_verified == 1 || employee.e_verified == 4 ?
                                                <BlackToolTip arrow placement='right' title={<Text smallWhite padding={'4px'}>E-verified</Text>}>
                                                    <img src={verified} alt='verified' />
                                                </BlackToolTip> :
                                                employee.e_verified == 2 ?
                                                    <BlackToolTip arrow placement='right' title={<Text smallWhite padding={'4px'}>This user is not E-Verified</Text>}>
                                                        <img src={PendingIcon} alt='Pending' />
                                                    </BlackToolTip>
                                                    : ''
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12} pt={0}>
                                    {/* {loading ? <Skeleton width='150px' height='40px' animation='wave' /> :  */}
                                    <Text className={classes.profileId}>{employee.employment_details.reference_id}</Text>
                                    {/* } */}
                                </Grid>
                                <Grid item lg={12}>
                                    <Stack my={1} direction="row" justifyContent={"center"} spacing={2}>
                                        {
                                            loading ?
                                                <Skeleton width='150px' height='60px' animation='wave' /> :
                                                employee.is_off_boarding_initiated === true ?
                                                    <CustomButton rehire onClick={() => { navigate('/rehire', { state: { full_name: employee.basic_details.full_name, id: employee.id, date: employee.last_working_day } }) }}>Re-hire</CustomButton> :
                                                    <Button className={classes.eVerifyBtn} sx={{ border: employee.e_verified == 1 ? '1px solid #4ABE43 !important' : employee.e_verified == 4 ? '1px solid #0C75EB !important' : '', backgroundColor: (employee.e_verified == 0 ? "#ffffff !important" : employee.e_verified == 1 || employee.e_verified == 4 ? "#FFFFFF !important" : employee.e_verified == 2 ? "#F59E0B !important" : employee.e_verified == 3 ? "#E51A1A !important" : ''), color: '#ffffff' }} >
                                                        <Stack direction='row' gap={1} sx={{ color: (employee.e_verified == 0 ? "#0C75EB  !important" : employee.e_verified == 1 ? "#ffffff !important" : employee.e_verified == 2 ? "#ffffff !important" : employee.e_verified == 3 ? "#ffffff !important" : '') }}>
                                                            {(employee.e_verified == 0 ? 'E-Verify' : employee.e_verified == 1 ? 'E-Verified' : employee.e_verified == 2 ? 'Pending' : employee.e_verified == 3 ? 'Rejected' : employee.e_verified == 4 ? 'Re-Verify' : '')}
                                                            {(employee.e_verified !== 0 && employee.e_verified !== 4) ? <img style={{ marginTop: '6px' }} width={'15px'} height={'15px'} src={employee.e_verified == 1 ? Verify : employee.e_verified == 2 ? Pending : employee.e_verified == 3 ? Rejected : null} alt='e_verify' /> : ''}
                                                        </Stack>
                                                    </Button>
                                        }
                                    </Stack>
                                </Grid>

                            </Box>
                            <Box px={3}>
                                <Divider />
                            </Box>
                            <Box py={3}>
                                <Box px={3} sx={{
                                    height: "30vh",
                                    overflow: "auto",
                                    '&::-webkit-scrollbar': {
                                        width: '4px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        '-webkit-box-shadow': 'inset 0 0 6px #ffffff',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: '#C7CCD3',
                                        outline: '1px solid #C7CCD3',
                                        borderRadius: "4px",
                                    },
                                    "@media (min-height: 750px)": {
                                        height: '36vh'
                                    }
                                }}>
                                    {
                                        dataArr.map((item, key) => (
                                            <ListItemButton
                                                key={key}
                                                className={`${classes.listItems} ${current === item ? classes.listItemsActive : null}`}
                                                onClick={() => {
                                                    if (current == item) { } else if (formEdit) {
                                                        setOpenpopup(true); setStepItem(item)
                                                    } else { setCurrent(item); setdocumentPosition('education') }
                                                }}
                                            >
                                                <Grid container>
                                                    <Grid item xs={10}>{item}</Grid>
                                                    {
                                                        checkDocumentComplete(item) ?
                                                            <Grid item container justifyContent={'flex-end'} xs={2}>
                                                                <BlackToolTip arrow placement='right' title={'Pending'}>
                                                                    <img src={eVerifyPending} alt='pending' />
                                                                </BlackToolTip>
                                                            </Grid> : ''
                                                    }
                                                </Grid>
                                            </ListItemButton>
                                        ))}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={.1}></Grid>
                    <Grid item lg={8.4} md={8.4} sm={8} xs={12}>
                        {
                            true ? '' : <Grid item pb={2}>
                                <Box display='flex' flexDirection='row' gap={1.5} sx={{ height: '60px', width: '100%', borderRadius: '8px', background: '#FDECCE', textAlign: 'center', alignItems: 'center', paddingLeft: '20px' }}>
                                    <Box pt={'4px'}>
                                        <img src={warning} alt='warning' />
                                    </Box>
                                    <Box>
                                        <Text mediumOrangeRehire> This employee has been re-hired recently, please re-confirm all the details </Text>
                                    </Box>
                                </Box>
                            </Grid>
                        }
                        <Box sx={{ width: '101%', height: true ? "78.5vh" : "70vh", borderRadius: "8px", overflow: 'auto' }}>
                            <Box>
                                {
                                    current === "General Details" && employee.basic_details.first_name !== '' &&
                                    <Box m={0.5} mr={0.1}>
                                        <Box mb={2} sx={{ borderRadius: "8px", boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D" }}>
                                            <GeneralDetails formEdit={formEdit} setformEdit={setformEdit} avatar_url={employee.profile_picture_url}
                                                employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} loading={loading} setLoading={setLoading} offBoardButton_enable={employee.is_off_boarding_initiated} offboardPercentage={employee.off_boarding_percentage}
                                            />
                                        </Box>
                                        <Box sx={{ borderRadius: "8px", boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D" }}>
                                            <ViewEmergency formEdit={formEdit} setformEdit={setformEdit} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} />
                                        </Box>
                                    </Box>
                                }
                                {
                                    current === "Documents" ?
                                        <Box m={0.5} mr={0.1} mb={0}>
                                            <Box mb={2} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                                <Documents formEdit={formEdit} setformEdit={setformEdit} documentPosition={documentPosition} setdocumentPosition={setdocumentPosition} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} />
                                            </Box>
                                            <Box sx={{ height: '70vh', width: '100%', overflowY: 'scroll' }}>
                                                <AllSections formEdit={formEdit} setformEdit={setformEdit} documentPosition={documentPosition} setdocumentPosition={setdocumentPosition} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} />
                                            </Box>
                                        </Box>
                                        : null
                                }
                                {
                                    current === "Bank Details" ? <PayConfiguration formEdit={formEdit} employee={employee} getEmployeeApi={getEmployeeApi} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                                }
                                {
                                    current === "Skills" ? <Skills formEdit={formEdit} employee={employee} getEmployeeApi={getEmployeeApi} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <ReusablePopup openPopup={openPopup} setopenPopup={setOpenpopup} white iconHide fullWidth>
                <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                    <img src={offBoardSave} alt='noactive' />
                </Grid>
                <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                    <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
                </Grid>
                <Grid container justifyContent={'center'} alignContent={'center'}>
                    <Text offBoardBodyFont>Your progress will not be saved</Text>
                </Grid>
                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                    <Stack direction={"row"} spacing={3}>
                        <CustomButton smallBlackOutline onClick={() => { setOpenpopup(false) }} >
                            Cancel
                        </CustomButton>
                        <CustomButton smallBlue redBackground onClick={() => { setformEdit(false); setCurrent(stepItem); setOpenpopup(false) }} >
                            Yes
                        </CustomButton>
                    </Stack>
                </Grid>
            </ReusablePopup>
        </Box >
    );
}