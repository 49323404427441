import React, { useEffect, useState } from 'react'
import { Box, Grid, Stack, Divider } from '@mui/material';
import logo from '../../assets/svg/dashboard/New_Logo_Black.png';
import great from '../../assets/svg/great.svg'
import Text from '../../components/customText/Text';
import BillingStyles from './BillingStyles';
import CheckIcon from '@mui/icons-material/Check';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import RadioGroup from '../../components/customButton/RadioGroup';
import LocalStorage from '../../utils/LocalStorage';
import { domain } from '../../config/Domain';
import CommonApi from '../../apis/CommonApi'; // eslint-disable-next-line
import { addErrorMsg, addLoader, removeExpiredLoader, removeLoader } from '../../utils/utils';
import yearlyTag from '../../assets/svg/green-tag.svg';
import AnalyticsApiTimesheet from '../../apis/admin/timesheets/AnalyticsApiTimesheet';
import DashboardAPI from '../../apis/admin/DashboardAPI';
import LoadingScreen from '../../views/employee/profile/documents/LoadingScreen';
import ArrowLeft from '../../assets/svg/fi_arrow-left.svg';
import mail from '../../assets/svg/fi_mail.svg';
import LoadingButton from '../../components/customButton/LoadingButton';
import vector from '../../assets/Vector.svg';
import downgrade from '../../assets/svg/downgrade.svg';

function Billing() {
    const navigate = useNavigate()
    const location = useLocation()
    const classes = BillingStyles();
    const [loader, setLoader] = useState(false);
    const [plan, setPlan] = useState('');
    const [planData, setPlanData] = useState('');
    const [checkplan, setcheckPlan] = useState('');
    const [checkplanAdvanced, setcheckPlanAdvanced] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentPlan, setCurrentPlan] = useState('');
    const [cancelSubscription, setcancelSubscription] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [radioSlug, setRadioSlug] = useState(''); // eslint-disable-next-line
    const [proratedValue, setproratedValue] = useState('');
    const [loadingRadio, setLoadingRadio] = useState(false);
    const freePlan = [
        {
            label: 'Admin Access',
            value: plan == 'starter' ? '8 Users' : plan == 'advanced' ? '12 Users' : '5 Users'
        },
        {
            label: 'Active Employees',
            value: plan == 'starter' ? '75' : plan == 'advanced' ? '155' : '35'
        },
        {
            label: 'AI Timesheets',
            value: plan == 'starter' ? '175' : plan == 'advanced' ? '500' : '50'
        },
        {
            label: 'MIVI AI Prompts',
            value: plan == 'starter' ? '250' : plan == 'advanced' ? '500' : '75'
        },
        {
            label: 'Onboarding & Offboarding',
            value: 'Yes'
        },
        {
            label: 'Timesheet Management',
            value: 'Yes'
        },
        {
            label: 'Placement Management',
            value: 'Yes'
        },
        {
            label: 'Client & Vendor Management',
            value: 'Yes'
        }
    ]

    const statusItems = [
        { id: 3, title: '', value: 3 },
    ]
    const statusItems1 = [
        { id: 2, title: '', value: 2 },
    ]
    const statusItems2 = [
        { id: 5, title: '', value: 5 },
    ]
    const statusItems3 = [
        { id: 4, title: '', value: 4 },
    ]

    const handleChangeTab = (args) => {
        if (args == 'starter') {
            if (currentPlan == null || currentPlan == 'trail') {
                setcheckPlan(3);
                setRadioSlug('starter-yearly');
                setPlan(args);
            }
            else if (location?.state?.status == 'activeUpgrade') {
                setcheckPlan(3);
                setRadioSlug('starter-yearly');
                setPlan(args);
            }
            else {
                if (currentPlan == 'starter-monthly') {
                    setRadioSlug('starter-monthly');
                }
                else if (currentPlan == 'starter-yearly') {
                    setRadioSlug('starter-yearly');
                }
                else {
                    setRadioSlug('starter-yearly');
                }
                setPlan(args);
            }
        }
        else if (args == 'advanced') {
            if (currentPlan == null || currentPlan == 'trail') {
                setcheckPlanAdvanced(5);
                setRadioSlug('advanced-yearly');
                setPlan(args);
            }
            if (currentPlan == "starter-yearly" || currentPlan == 'starter-monthly') {
                setcheckPlanAdvanced(5);
                setRadioSlug('advanced-yearly');
                setPlan(args);
            }
            else {
                if (currentPlan == 'advanced-monthly') {
                    setRadioSlug('advanced-monthly');
                }
                else {
                    setRadioSlug('advanced-yearly')
                }
                setPlan(args);
            }
        }
    }

    const handlechange = (e, name, args1) => {
        if (args1 == 'advanced') {
            setcheckPlanAdvanced(e.target.value);
        } else {
            setcheckPlan(e.target.value);
        }
        if (e.target.value == 2) {
            setRadioSlug('starter-monthly')
        } else if (e.target.value == 3) {
            setRadioSlug('starter-yearly')
        } else if (e.target.value == 4) {
            setRadioSlug('advanced-monthly')
        } else if (e.target.value == 5) {
            setRadioSlug('advanced-yearly')
        }
    }
    const getPlanCheck = () => {
        // addLoader(true);
        setLoading(true);
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        if (LocalStorage.getAccessToken()) {
            DashboardAPI.planCheck(data).then((res) => {
                if (res.data.statusCode === 1003) {
                    removeLoader();
                    setLoading(false);
                    setPlanData(res.data)
                    setCurrentPlan(res.data.current_plan_slug);
                    setcancelSubscription(res.data.is_subscription_cancelled)
                    // setRadioSlug(res.data.current_plan_slug)
                    setStartDate(res.data.current_plan_start_date);
                    if (res.data.current_plan_slug == 'trail') {
                        setPlan('starter');
                        setcheckPlan(3);
                        setcheckPlanAdvanced(5);
                        setRadioSlug('starter-yearly')
                    } else if (res.data.current_plan_slug == null) {
                        setPlan('basic');
                        setcheckPlan(3);
                        setcheckPlanAdvanced(5);
                    }
                    else if (location?.state?.autoRenew) {
                        setPlan('advanced');
                        setcheckPlan(3);
                        setcheckPlanAdvanced(5);
                        setRadioSlug('advanced-yearly')
                    }
                    else if (res.data.current_plan_slug == 'advanced-monthly' && location?.state?.autoRenewAdvance) {
                        setPlan('advanced');
                        setcheckPlan(3);
                        setcheckPlanAdvanced(4);
                        setRadioSlug('advanced-monthly')
                    }
                    else if (res.data.current_plan_slug == 'starter-monthly' && location?.state?.status == 'activeUpgrade' && location?.state?.renewal) {
                        setPlan('starter');
                        setcheckPlan(2);
                        setcheckPlanAdvanced(5);
                        setRadioSlug('starter-monthly')
                    }
                    else if ((res.data.current_plan_slug == 'starter-yearly' && location?.state?.status == 'activeUpgrade' && location?.state?.renewal)) {
                        setPlan('starter');
                        setcheckPlan(3);
                        setcheckPlanAdvanced(5);
                        setRadioSlug('starter-yearly')
                    }
                    else if (res.data.current_plan_slug == 'starter-monthly' && location?.state?.status == 'activeUpgrade') {
                        setPlan('starter');
                        setcheckPlan(3);
                        setcheckPlanAdvanced(5);
                        setRadioSlug('starter-yearly')
                    }
                    else if ((res.data.current_plan_slug == 'starter-yearly' && location?.state?.status == 'activeUpgrade')) {
                        setPlan('advanced');
                        setcheckPlan(3);
                        setcheckPlanAdvanced(5);
                        setRadioSlug('advanced-yearly')
                    }
                    else if ((res.data.current_plan_slug == 'starter-monthly' && location?.state?.status == 'upgrade') || (res.data.current_plan_slug == 'starter-yearly' && location?.state?.status == 'upgrade')) {
                        setPlan('advanced');
                        setcheckPlan(3);
                        setcheckPlanAdvanced(5);
                        setRadioSlug('advanced-yearly')
                    }
                    else if (res.data.current_plan_slug == 'starter-monthly') {
                        setPlan('starter');
                        setcheckPlan(2);
                        setcheckPlanAdvanced(5);
                        setRadioSlug('starter-monthly')
                    } else if (res.data.current_plan_slug == 'starter-yearly') {
                        setPlan('starter');
                        setcheckPlan(3);
                        setcheckPlanAdvanced(5);
                        setRadioSlug('starter-yearly')
                    }
                    else if (res.data.current_plan_slug == 'advanced-yearly') {
                        setPlan('advanced');
                        setcheckPlan(3);
                        setcheckPlanAdvanced(5);
                        setRadioSlug('advanced-yearly')
                    }
                    else if (location?.state?.status == "advanceUpgrade") {
                        setPlan('advanced');
                        setcheckPlan(3);
                        setcheckPlanAdvanced(5);
                        setRadioSlug('advanced-yearly')
                    }
                    else if (res.data.current_plan_slug == 'advanced-monthly') {
                        setPlan('advanced');
                        setcheckPlan(3);
                        setcheckPlanAdvanced(4);
                        setRadioSlug('advanced-monthly')
                    } else if (res.data.current_plan_slug == 'advanced-yearly') {
                        setPlan('advanced');
                        setcheckPlan(3);
                        setcheckPlanAdvanced(5);
                        setRadioSlug('advanced-yearly')
                    }
                } else {
                    removeLoader();
                    setLoading(false);
                    addErrorMsg(res.data.message);
                }
            })
        }

    }

    useEffect(() => {
        removeExpiredLoader()
        if (location?.state?.status == "activeUpgrade" || location?.state?.status == "advanceUpgrade" || (planData?.subscription_expiring && planData?.is_plan_auto_renewed)) {
            let data = {
                request_id: LocalStorage.uid(),
                plan_slug: radioSlug
            }
            setLoadingRadio(true)
            CommonApi.calculateProrate(data).then((res) => {
                if (res.data.statusCode == 1003) {
                    setTimeout(() => {
                        setproratedValue({ ...res.data });
                        removeLoader();
                        setLoadingRadio(false)
                    }, 800)
                }
                else {
                    setproratedValue({ ...proratedValue, amount: 0, period_days: 0 });
                    setLoadingRadio(false)
                    removeLoader()
                }
            })
        }
        // eslint-disable-next-line
    }, [radioSlug])

    useEffect(() => {
        removeExpiredLoader()
        if (location.state && location.state.renew) {
        }
        else if (LocalStorage.getAccessToken()) {
        }
        else {
            navigate('/dashboard')
        }
        getPlanCheck();
        // eslint-disable-next-line      
    }, [])

    const freePlanSubmit = () => {
        setLoader(true);
        let data = {
            request_id: LocalStorage.uid(),
            plan_slug: 'trail',
            subdomain_name: domain
        }
        CommonApi.paymentInitiation(data).then(
            (res) => {
                setLoader(false)
                if (res.data.statusCode == 1003) {
                    if (res.data.data.free_paln) {
                        let data = LocalStorage.getUserData()
                        data.plan_not_set = false
                        LocalStorage.setUserData(data);
                        LocalStorage.removeRedirectedModule();
                        LocalStorage.removeFromPage();
                        navigate('/configuration')
                    } else {
                        window.open(res.data.data.payment_url, '_blank');
                    }
                }
            }
        )
    }
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if (location.state && location.state.renew) {
        }
        else if (LocalStorage.getUserData().plan_not_set == false) {
            navigate('/dashboard')
        }
        // else {
        //     setPlan('basic')
        // }
        const token = searchParams.get("token")
        if (token) {
            let data = {
                request_id: LocalStorage.uid(),
                access_token: token,
                subdomain_name: domain
            }
            CommonApi.getLoginDetails(data).then(
                (response) => {
                    if (response.data.statusCode == 1003) {
                        LocalStorage.setUserData(response.data.data);
                        window.location.reload()
                    }
                }
            )
        }
        // eslint-disable-next-line
    }, [])

    const upgradePlan = () => {
        removeExpiredLoader()
        let data = {
            request_id: LocalStorage.uid(),
            plan_slug: radioSlug
        }
        // return true
        if (radioSlug == '') {
            addErrorMsg('Please select any plan')
            return true
        }
        setLoader(true);
        AnalyticsApiTimesheet.upgradePlan(data).then((res) => {
            if (res.data.statusCode == 1003) {
                setLoader(false);
                removeLoader();
                if (res.data?.data?.redirection == true) {
                    window.open(res.data.data.payment_url);
                }
                else if (res.data?.data?.redirection == false && (res.data?.data.free_plan == false || res.data?.data.free_paln == false)) {
                    navigate('/plan-status')
                }
                else if (res.data?.data?.payment_processing == true && res.data?.data?.redirection == false && res.data?.data.free_plan) {

                    if (LocalStorage.getUserData().org_config_set == false || LocalStorage.getUserData().org_config_set == null) {
                        navigate('/configuration')
                    }
                    else {
                        getPlanCheck();
                        navigate('/myprofile');
                    }
                } else {
                    navigate('/myprofile');
                }
            } else {
                removeLoader();
                setLoader(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const PlanSubmit = () => {
        removeExpiredLoader()
        upgradePlan();
        // let data = {
        //     request_id: LocalStorage.uid(),
        //     plan_id: checkplan,
        //     subdomain_name: domain
        // }
        // let key = 'pk_test_51P1o8905CUJoRYAjl2LMbglsEN0oSR2Ew01Y36eR4LYlarrOeqgmC7dXLsDIotcAfvnYcTV3Kg4NVy8Ph7V2kR7y00rwRmQOTi'
        // CommonApi.paymentInitiation(data).then(
        //     (res) => {
        //         if (res.data.statusCode == 1003) {
        //             let data = LocalStorage.getUserData()
        //             data.plan_not_set = false
        //             LocalStorage.setUserData(data)
        //             setSessionID(res.data.data.session_id)
        //             if (res.data.data.session_id) {
        //                 const stripe = window.Stripe(key);
        //                 const { error } = stripe.redirectToCheckout({
        //                     sessionId: res.data.data.session_id,
        //                 });
        //                 if (error) {
        //                     console.error('Error redirecting to checkout:', error);
        //                     // Handle error
        //                 }
        //             }
        //         }
        //     }
        // )
    }


    return (
        <Grid container pt={0} p={4}>
            <Grid item xs={1}></Grid>
            {
                loading ? <LoadingScreen /> :
                    <>
                        <Grid container xs={11}>
                            <Grid item container xs={12} spacing={2} alignItems='start'>
                                <Grid item xs={6}>
                                    <img src={logo} alt='logo' style={{ height: '51px', width: '173px' }} />
                                </Grid>
                                <Grid item container xs={5} justifyContent='end'>
                                    <Box display='flex' flexDirection='row' gap={2} className={classes.contactSupport} onClick={() => { window.open('https://www.japfu.ai/contact', '_blank') }}>
                                        <img src={mail} alt='mail' style={{ height: '24px', width: '24px' }} />
                                        <Text largeBoldBlue >Contact Support</Text>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} pt={2} spacing={2}>
                                {
                                    LocalStorage.getUserData().plan_not_set ? '' :
                                        <Grid item container xs={12} spacing={1} my={2}>
                                            <Grid item xs={9.5}>
                                                <Stack direction={'row'}>
                                                    <img src={ArrowLeft} alt='ArrowLeft' style={{ cursor: 'pointer' }} onClick={() => { location.state?.locationNavigate ? navigate('/myprofile', { state: { redirection: true } }) : LocalStorage.getlocationPath() != null ? navigate(LocalStorage.getlocationPath()) : navigate(-1) }} />
                                                    <Text BlackExtraDark sx={{ paddingLeft: '15px' }}>Back</Text>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                }
                                <Grid item container xs={7} pt={2}>
                                    <Grid item container xs={12}>
                                        <Grid item container xs={12}>
                                            <Grid item container xs={11} alignItems='center'>
                                                <Grid item lg={4} md={4} sm={4} xs={4} onClick={() => setPlan('basic')}>
                                                    <Box sx={{
                                                        color: plan == 'basic' ? '#fff' : '#737373',
                                                        background: plan == 'basic' ? '#0C75EB' : '#EAECF0',
                                                    }} className={classes.Active}>
                                                        No Cost
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={4} xs={4} onClick={() => handleChangeTab('starter')}>
                                                    <Box className={classes.Active} sx={{
                                                        color: plan == 'starter' ? '#fff' : plan == 'advanced' ? '#737373' : '#181A18',
                                                        background: plan == 'starter' ? '#0C75EB' : '#EAECF0',
                                                        borderRadius: '0px'
                                                    }}>
                                                        Starter
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={4} xs={4} onClick={() => handleChangeTab('advanced')}>
                                                    <Box sx={{
                                                        color: plan == 'advanced' ? '#fff' : '#181A18',
                                                        background: plan == 'advanced' ? '#0C75EB' : '#EAECF0',
                                                        borderRadius: '0px 8px 8px 0px'
                                                    }} className={classes.Active}>
                                                        Advanced
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} pt={4}>
                                                <Box pl={2}>
                                                    <Text largeBlue className={classes.lightBlue}> Benefits </Text>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box p={2} pt={0}>
                                                    {
                                                        freePlan.map((i) => (
                                                            <Grid container xs={12} pt={2} alignItems='center'>
                                                                <Grid item xs={7} pt={2}>
                                                                    <Stack direction={'row'} gap={3}>
                                                                        <CheckIcon sx={{ color: 'green !important', marginTop: '1px' }} />
                                                                        <Text grey18px>  {i.label} </Text>
                                                                    </Stack>
                                                                </Grid>
                                                                <Grid item xs={1} pt={2}>
                                                                    <Text greyLabel> - </Text>
                                                                </Grid>
                                                                <Grid item xs={2} pt={2} container justifyContent={'flex-end'}>
                                                                    <Text black18px> {i.value} </Text>
                                                                </Grid>
                                                            </Grid>
                                                        ))
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    loadingRadio ?
                                        <Grid item container justifyContent='center' textAlign='center' xs={4} sx={{ boxShadow: "0px 2px 24px 0px #919EAB1F", borderRadius: "8px" }}>
                                            <LoadingScreen />
                                        </Grid>
                                        :
                                        plan == 'starter' ?
                                            currentPlan == "advanced-yearly" || currentPlan == "advanced-monthly" ?
                                                <Grid item container justifyContent='center' textAlign='center' xs={4} sx={{ boxShadow: "0px 2px 24px 0px #919EAB1F", borderRadius: "8px" }}>
                                                    <Grid item xs={12}>
                                                        <Box height={'0vh'} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <img src={downgrade} alt='downgrade' />
                                                        <Text popupHead1 sx={{ paddingTop: '10px' }}>Downgrade Unavailable</Text>
                                                        <Text mediumBlue600 sx={{ textDecoration: 'underline', fontSize: '14px !important', paddingTop: '5px', cursor: 'pointer' }} onClick={() => { window.open('https://www.japfu.ai/contact', '_blank') }}>Contact Sales</Text>
                                                    </Grid>

                                                </Grid>
                                                :
                                                currentPlan == "starter-monthly" && location?.state?.status !== 'renew' && location?.state?.status !== 'upgrade' && location?.state?.renewal != true && location?.state?.autoRenewStarter == false ?
                                                    <Grid item p={1} pl={4} container xs={4} sx={{ boxShadow: "0px 2px 24px 0px #919EAB1F", borderRadius: "8px" }}>
                                                        <Grid item container sm={12} xs={12} pt={2} justifyContent='start'>
                                                            <Box display='flex' flexDirection='column' gap={1}>
                                                                <Text popupHead1>Current plan</Text>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item sm={12} xs={12} pt={2} justifyContent='start'>
                                                            <Box display='flex' flexDirection='column' sx={{ background: 'linear-gradient(135deg,#0396FF, #ABDCFF)', padding: '10px 16px', borderRadius: '8px' }}>
                                                                <Text mediumWhite400 sx={{ fontWeight: '600px !important' }}>Monthly</Text>
                                                                <Text largeWhite18>{LocalStorage.getCurrencySymbol()} 35<span style={{ color: '#fff', font: '14px Quicksand', fontWeight: '400' }}>.25 Per month</span></Text>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item sm={12} xs={12} pt={2} justifyContent='start'>
                                                            <Text offBoardBodyFont>Renews for $35.25 every month including taxes.</Text>
                                                        </Grid>
                                                        <Grid item container pt={1} pb={2}>
                                                            <Divider sx={{ width: '99%', borderColor: '#D9D9D9' }} />
                                                        </Grid>
                                                        <Grid item sm={12} xs={12} justifyContent='start'>
                                                            <Text popupHead1 sx={{ fontWeight: '600 !important' }}>Looking to Upgrade?</Text>
                                                        </Grid>
                                                        <Grid item container sm={12} xs={12} pt={2} justifyContent='start'>
                                                            <Box display='flex' flexDirection='column' sx={{ border: '1px solid #0C75EB', borderRadius: '10px', height: '64px', width: '100%', padding: '10px 0px 0px 10px' }}>
                                                                <Text black14 sx={{ fontWeight: '400px !important' }}>Billed Yearly</Text>
                                                                <Text largeBoldBlue>{LocalStorage.getCurrencySymbol()} 40<span style={{ color: '#0C75EB', font: '14px Quicksand', fontWeight: '400' }}>.00</span><span style={{ color: '#949494', font: '12px Quicksand', fontWeight: '400' }}> &nbsp;Per month</span></Text>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item container sm={12} xs={12} pt={2} justifyContent='start'>
                                                            <Box sx={{ background: '#F0F5FF', borderRadius: '8px', width: '100%' }}>
                                                                <Grid item container xs={12} sx={{ padding: '14px' }}>
                                                                    <Grid item xs={6}>
                                                                        <Text mediumBoldBlack>Bill Today</Text>
                                                                    </Grid>
                                                                    <Grid item xs={6} textAlign='end'>
                                                                        <Text mediumBoldBlack>{LocalStorage.getCurrencySymbol()} {proratedValue?.amount}</Text>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item py={2}>
                                                                    <Divider sx={{ width: '100%', borderColor: '#D9D9D9' }} />
                                                                </Grid>
                                                                <Grid item container xs={12} spacing={1} sx={{ padding: '4px 14px' }}>
                                                                    <Grid item xs={6}>
                                                                        <Text offBoardBodyFont>Remaining Validity</Text>
                                                                    </Grid>
                                                                    <Grid item xs={6} textAlign='end'>
                                                                        <Text offBoardBodyFont>{proratedValue?.period_days} Days</Text>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Text offBoardBodyFont>Prorated Amount</Text>
                                                                    </Grid>
                                                                    <Grid item xs={6} textAlign='end'>
                                                                        <Text offBoardBodyFont>{LocalStorage.getCurrencySymbol()} {checkplan == 2 ? (40 - proratedValue?.amount).toFixed(2) : (423 - proratedValue?.amount).toFixed(2)}</Text>
                                                                    </Grid>
                                                                    <Grid item container xs={12} py={2}>
                                                                        <Grid item xs={.5}>
                                                                            <img src={vector} alt='vestor' />
                                                                        </Grid>
                                                                        <Grid item xs={11.5}>
                                                                            <Text mediumGreyHeader1>As your current subscription is active, you'll be charged for your next renewal based on the pro-rated price. After the first renewal, the original plan price (<span style={{ color: '#0C75EB', font: '14px Quicksand', fontWeight: '600' }}>{LocalStorage.getCurrencySymbol()}{checkplan == 2 ? 40 : 423}</span>) will be applicable on all further renewals.</Text>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                            <Grid item container sm={12} xs={12} justifyContent={'center'} pt={1} my={1}>
                                                                <LoadingButton
                                                                    blueButton
                                                                    loading={loader}
                                                                    variant="contained"
                                                                    onClick={() => { PlanSubmit() }}
                                                                    fullWidth
                                                                >
                                                                    Complete Payment
                                                                </LoadingButton>
                                                            </Grid>
                                                            <Grid item container xs={12} justifyContent={'center'} my={1}>
                                                                <Text offBoardBodyFont>All transactions charged in USD</Text>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    currentPlan == "starter-yearly" && cancelSubscription == false ?
                                                        <Grid item p={1} pl={4} container xs={4} sx={{ boxShadow: "0px 2px 24px 0px #919EAB1F", borderRadius: "8px" }}>
                                                            <Grid item container sm={12} xs={12} pt={4} justifyContent='start'>
                                                                <Box display='flex' flexDirection='column' gap={1}>
                                                                    <Text popupHead1 sx={{ fontWeight: '600 !important' }}>Affordable & Powerful</Text>
                                                                    <Text mediumOverView>Feature-rich pricing that scales with your needs.</Text>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item container sm={12} xs={12} justifyContent={'center'} p={2} pt={4} spacing={2}>
                                                                <Grid item xs={6} sm={6}>
                                                                    <Box sx={{ height: '143px', border: checkplanAdvanced == 5 ? '1px solid #0C75EB' : '1px solid #C7CCD3', padding: '24px, 10px, 24px, 10px', borderRadius: '10px', position: 'relative', display: 'inline-flex' }}>
                                                                        <Box sx={{ textAlign: 'center', alignItems: 'center', position: 'absolute', top: '5%', left: '-3.5%' }}>
                                                                            <img src={yearlyTag} alt='' />
                                                                        </Box>
                                                                        <Grid container xs={12} pt={3}>
                                                                            <Grid item container xs={12} justifyContent={'center'}>
                                                                                <Box pl={3}>
                                                                                    <RadioGroup
                                                                                        row
                                                                                        name="yearly"
                                                                                        value={checkplan}
                                                                                        items={statusItems}
                                                                                        // disabled={currentPlan == 'advanced-monthly' ? true : false}
                                                                                        onChange={(e) => handlechange(e, "yearly", 'advanced')}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                <Text blackFont14 sx={{ color: '#171717 !important', fontWeight: '400 !important' }}>Billed Yearly</Text>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                <Text blue14 sx={{ fontSize: '18px !important' }}>$35<span style={{ color: '#0C75EB', font: '14px Quicksand', fontWeight: '400' }}>.25</span></Text>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5} pb={2}>
                                                                                <Text greysmallLabel sx={{ color: '#949494 !important' }}>Per month</Text>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6}>
                                                                    <Box sx={{ height: '143px', border: checkplanAdvanced == 4 ? '1px solid #0C75EB' : '1px solid #C7CCD3', padding: '24px, 10px, 24px, 10px', borderRadius: '10px', background: '#F6F6F6' }}>
                                                                        <Grid container xs={12} pt={3}>
                                                                            <Grid item container xs={12} justifyContent={'center'}>
                                                                                <Box pl={4}>
                                                                                    <RadioGroup
                                                                                        row
                                                                                        name="yearly"
                                                                                        value={checkplan}
                                                                                        items={statusItems1}
                                                                                        disabled={true}
                                                                                        onChange={(e) => handlechange(e, "monthly", 'advanced')}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                <Text blackFont14 sx={{ color: '#171717 !important', fontWeight: '400 !important' }}>Billed Monthly</Text>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                <Text mediumBlue600>Contact Sales</Text>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5} pb={2}>
                                                                                <Text onClick={() => { window.open('https://www.japfu.ai/contact', '_blank') }} mediumBlue sx={{ textDecorator: '1px solid 0C75EB !important', cursor: 'pointer', textDecoration: 'underline' }}>sales@japfu.ai</Text>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container pt={1} pb={2}>
                                                                <Divider sx={{ width: '99%', borderColor: '#D9D9D9' }} />
                                                            </Grid>
                                                            {
                                                                currentPlan == 'trail' || ((location?.state?.autoRenewStarter && (!planData?.subscription_expiring && !planData?.is_plan_auto_renewed)) || (location?.state?.autoRenewStarter && (planData?.subscription_expiring && !planData?.is_plan_auto_renewed))) ?
                                                                    <Grid item container sm={12} xs={12} pt={2} justifyContent='start'>
                                                                        <Box sx={{ height: '100px', width: '50vw', borderRadius: '8px', padding: '14px 12px 14px 12px', background: '#F0F5FF' }}>
                                                                            <Grid item container>
                                                                                <Grid container xs={6} justifyContent={'flex-start'}>
                                                                                    <Box p={1} pt={0} >
                                                                                        <Text mediumBoldBlack sx={{ fontWeight: '600 !important' }}>Bill Today</Text>
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item container xs={6} justifyContent={'flex-end'}>
                                                                                    <Box p={1} pt={0}>
                                                                                        <Text popupHead1 sx={{ fontWeight: '600 !important' }}>{checkplan == 2 ? '$40' : '$423'}<span style={{ font: '15px Quicksand', color: '#262626' }}>.00</span></Text>
                                                                                    </Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Divider sx={{ width: '100%', borderColor: '#D9D9D9' }} />
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'}>
                                                                                <Box p={1} pt={2}>
                                                                                    <Text offBoardBodyFont>Auto-renews for {checkplan == 2 ? '$40.00 every month' : '$423.00 every year'}  including taxes.</Text>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Box>
                                                                        <Grid item container sm={12} xs={12} justifyContent={'center'} pt={1} my={1}>
                                                                            <LoadingButton
                                                                                blueButton
                                                                                loading={loader}
                                                                                variant="contained"
                                                                                onClick={() => { PlanSubmit() }}
                                                                                fullWidth
                                                                            >
                                                                                Complete Payment
                                                                            </LoadingButton>
                                                                        </Grid>
                                                                        <Grid item container xs={12} justifyContent={'center'} my={1}>
                                                                            <Text offBoardBodyFont>All transactions charged in USD</Text>
                                                                        </Grid>
                                                                    </Grid>
                                                                    :
                                                                    <Grid item container sm={12} xs={12} pt={2} justifyContent='start'>
                                                                        <Box sx={{ background: '#F0F5FF', borderRadius: '8px', width: '100%' }}>
                                                                            <Grid item container xs={12} sx={{ padding: '14px' }}>
                                                                                <Grid item xs={6}>
                                                                                    <Text mediumBoldBlack>Bill Today</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6} textAlign='end'>
                                                                                    <Text mediumBoldBlack>{LocalStorage.getCurrencySymbol()} {proratedValue?.amount}</Text>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item >
                                                                                <Divider sx={{ width: '100%', borderColor: '#D9D9D9' }} />
                                                                            </Grid>
                                                                            <Grid item container xs={12} spacing={1} sx={{ padding: '8px 14px' }}>
                                                                                <Grid item xs={6}>
                                                                                    <Text offBoardBodyFont>Remaining Validity</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6} textAlign='end'>
                                                                                    <Text offBoardBodyFont>{proratedValue?.period_days} Days</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Text offBoardBodyFont>Prorated Amount</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6} textAlign='end'>
                                                                                    <Text offBoardBodyFont>{LocalStorage.getCurrencySymbol()} {checkplan == 2 ? (40 - proratedValue?.amount).toFixed(2) : (423 - proratedValue?.amount).toFixed(2)}</Text>
                                                                                </Grid>
                                                                                <Grid item container xs={12} py={2}>
                                                                                    <Grid item xs={.5}>
                                                                                        <img src={vector} alt='vestor' />
                                                                                    </Grid>
                                                                                    <Grid item xs={11.5}>
                                                                                        <Text mediumGreyHeader1>As your current subscription is active, you'll be charged for your next renewal based on the pro-rated price. After the first renewal, the original plan price (<span style={{ color: '#0C75EB', font: '14px Quicksand', fontWeight: '600' }}>{LocalStorage.getCurrencySymbol()}{checkplan == 2 ? 40 : 423}</span>) will be applicable on all further renewals.</Text>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                        <Grid item container sm={12} xs={12} justifyContent={'center'} pt={1} my={1}>
                                                                            <LoadingButton
                                                                                blueButton
                                                                                loading={loader}
                                                                                variant="contained"
                                                                                onClick={() => { PlanSubmit() }}
                                                                                fullWidth
                                                                            >
                                                                                Complete Payment
                                                                            </LoadingButton>
                                                                        </Grid>
                                                                        <Grid item container xs={12} justifyContent={'center'} my={1}>
                                                                            <Text offBoardBodyFont>All transactions charged in USD</Text>
                                                                        </Grid>
                                                                    </Grid>

                                                            }

                                                        </Grid>
                                                        :
                                                        <Grid item p={1} pl={4} container xs={4} sx={{ boxShadow: "0px 2px 24px 0px #919EAB1F", borderRadius: "8px" }}>
                                                            <Grid item container sm={12} xs={12} pt={4} justifyContent='start'>
                                                                <Box display='flex' flexDirection='column' gap={1}>
                                                                    <Text popupHead1 sx={{ fontWeight: '600 !important' }}>Affordable & Powerful</Text>
                                                                    <Text mediumOverView>Feature-rich pricing that scales with your needs.</Text>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item container sm={12} xs={12} justifyContent={'center'} p={2} spacing={2}>
                                                                <Grid item xs={6} sm={6}>
                                                                    <Box sx={{ minHeight: '143px', border: checkplan == 3 ? '1px solid #0C75EB' : '1px solid #C7CCD3', padding: '24px, 10px, 24px, 10px', borderRadius: '10px', position: 'relative', display: 'inline-flex' }}>
                                                                        {/* {checkplan == 3 ? */}
                                                                        <Box sx={{ textAlign: 'center', alignItems: 'center', position: 'absolute', top: '5%', left: '-3.5%' }}>
                                                                            <img src={yearlyTag} alt='' />
                                                                        </Box>
                                                                        {/* } */}
                                                                        <Grid container xs={12} pt={3}>
                                                                            <Grid item container xs={12} justifyContent={'center'}>
                                                                                <Box pl={3}>
                                                                                    <RadioGroup
                                                                                        row
                                                                                        name="yearly"
                                                                                        value={checkplan}
                                                                                        items={statusItems}
                                                                                        // disabled={currentPlan == 'starter-monthly' ? true : false}
                                                                                        onChange={(e) => handlechange(e, "yearly")}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                <Text blackFont14 sx={{ color: '#171717 !important', fontWeight: '400 !important' }}>Billed Yearly</Text>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                <Text blue14 sx={{ fontSize: '18px !important' }}>$35<span style={{ color: '#0C75EB', font: '14px Quicksand', fontWeight: '400' }}>.25</span></Text>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5} pb={2}>
                                                                                <Text greysmallLabel sx={{ color: '#949494 !important' }}>Per month</Text>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                                {
                                                                    currentPlan != 'starter-yearly'
                                                                        ?
                                                                        <Grid item xs={6} sm={6}>
                                                                            <Box sx={{ minHeight: '143px', border: checkplan == 2 ? '1px solid #0C75EB' : '1px solid #C7CCD3', padding: '24px, 10px, 24px, 10px', borderRadius: '10px' }}>
                                                                                <Grid container xs={12} pt={3}>
                                                                                    <Grid item container xs={12} justifyContent={'center'}>
                                                                                        <Box pl={4}>
                                                                                            <RadioGroup
                                                                                                row
                                                                                                name="yearly"
                                                                                                value={checkplan}
                                                                                                items={statusItems1}
                                                                                                disabled={(currentPlan == 'starter-yearly' || currentPlan == 'advanced-yearly') ? true : false}
                                                                                                onChange={(e) => handlechange(e, "monthly")}
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                        <Text blackFont14 sx={{ color: '#171717 !important', fontWeight: '400 !important' }}>Billed Monthly</Text>
                                                                                    </Grid>
                                                                                    <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                        <Text blue14 sx={{ fontSize: '18px !important' }}>$40<span style={{ font: '14px Quicksand', fontWeight: '400' }}>.00</span></Text>
                                                                                    </Grid>
                                                                                    <Grid item container xs={12} justifyContent={'center'} pt={0.5} pb={2}>
                                                                                        <Text greysmallLabel sx={{ color: '#949494 !important' }}>Per month</Text>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                        :

                                                                        <Grid item xs={6} sm={6}>
                                                                            <Box sx={{ height: '143px', border: checkplanAdvanced == 4 ? '1px solid #0C75EB' : '1px solid #C7CCD3', padding: '24px, 10px, 24px, 10px', borderRadius: '10px', background: '#F6F6F6' }}>
                                                                                <Grid container xs={12} pt={3}>
                                                                                    <Grid item container xs={12} justifyContent={'center'}>
                                                                                        <Box pl={4}>
                                                                                            <RadioGroup
                                                                                                row
                                                                                                name="yearly"
                                                                                                value={checkplan}
                                                                                                items={statusItems1}
                                                                                                disabled={true}
                                                                                                onChange={(e) => handlechange(e, "monthly", 'advanced')}
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                        <Text blackFont14 sx={{ color: '#171717 !important', fontWeight: '400 !important' }}>Billed Monthly</Text>
                                                                                    </Grid>
                                                                                    <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                        <Text mediumBlue600>Contact Sales</Text>
                                                                                    </Grid>
                                                                                    <Grid item container xs={12} justifyContent={'center'} pt={0.5} pb={2}>
                                                                                        <Text onClick={() => { window.open('https://www.japfu.ai/contact', '_blank') }} mediumBlue sx={{ textDecorator: '1px solid 0C75EB !important', cursor: 'pointer', textDecoration: 'underline' }}>sales@japfu.ai</Text>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                }

                                                                <Grid item container pt={1} pb={4}>
                                                                    <Divider sx={{ width: '99%', borderColor: '#D9D9D9' }} />
                                                                </Grid>
                                                                {
                                                                    location?.state?.autoRenewStarter || currentPlan == null || currentPlan == 'trail' ?

                                                                        <Box sx={{ height: '100px', width: '50vw', borderRadius: '8px', padding: '14px 12px 14px 12px', background: '#F0F5FF' }}>
                                                                            <Grid item container>
                                                                                <Grid container xs={6} justifyContent={'flex-start'}>
                                                                                    <Box p={1} pt={0} >
                                                                                        <Text mediumBoldBlack sx={{ fontWeight: '600 !important' }}>Bill Today</Text>
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item container xs={6} justifyContent={'flex-end'}>
                                                                                    <Box p={1} pt={0}>
                                                                                        <Text popupHead1 sx={{ fontWeight: '600 !important' }}>{checkplan == 2 ? '$40' : '$423'}<span style={{ font: '15px Quicksand', color: '#262626' }}>.00</span></Text>
                                                                                    </Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Divider sx={{ width: '100%', borderColor: '#D9D9D9' }} />
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'}>
                                                                                <Box p={1} pt={2}>
                                                                                    <Text offBoardBodyFont>Auto-renews for {checkplan == 2 ? '$40.00 every month' : '$423.00 every year'}  including taxes.</Text>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Box>


                                                                        :

                                                                        <Box sx={{ background: '#F0F5FF', borderRadius: '8px', width: '100%' }}>
                                                                            <Grid item container xs={12} sx={{ padding: '14px' }}>
                                                                                <Grid item xs={6}>
                                                                                    <Text mediumBoldBlack>Bill Today</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6} textAlign='end'>
                                                                                    <Text mediumBoldBlack>{LocalStorage.getCurrencySymbol()} {proratedValue?.amount}</Text>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item >
                                                                                <Divider sx={{ width: '100%', borderColor: '#D9D9D9' }} />
                                                                            </Grid>
                                                                            <Grid item container xs={12} spacing={1} sx={{ padding: '8px 14px' }}>
                                                                                <Grid item xs={6}>
                                                                                    <Text offBoardBodyFont>Remaining Validity</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6} textAlign='end'>
                                                                                    <Text offBoardBodyFont>{proratedValue?.period_days} Days</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Text offBoardBodyFont>Prorated Amount</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6} textAlign='end'>
                                                                                    <Text offBoardBodyFont>{LocalStorage.getCurrencySymbol()} {checkplan == 2 ? (40 - proratedValue?.amount).toFixed(2) : (423 - proratedValue?.amount).toFixed(2)}</Text>
                                                                                </Grid>
                                                                                <Grid item container xs={12} py={2}>
                                                                                    <Grid item xs={.5}>
                                                                                        <img src={vector} alt='vestor' />
                                                                                    </Grid>
                                                                                    <Grid item xs={11.5}>
                                                                                        <Text mediumGreyHeader1>As your current subscription is active, you'll be charged for your next renewal based on the pro-rated price. After the first renewal, the original plan price (<span style={{ color: '#0C75EB', font: '14px Quicksand', fontWeight: '600' }}>{LocalStorage.getCurrencySymbol()}{checkplan == 2 ? 40 : 423}</span>) will be applicable on all further renewals.</Text>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>

                                                                }
                                                                <Grid item container sm={12} xs={12} justifyContent={'center'} pt={1} >
                                                                    <LoadingButton
                                                                        // className={classes.loginButton}
                                                                        // sx={{ width: '27vw !important' }}
                                                                        blueButton
                                                                        fullWidth
                                                                        loading={loader}
                                                                        variant="contained"
                                                                        onClick={() => { PlanSubmit() }}
                                                                    >
                                                                        Complete Payment
                                                                    </LoadingButton>
                                                                </Grid>
                                                                <Grid item container xs={12} justifyContent={'center'}>
                                                                    <Text offBoardBodyFont>All transactions charged in USD</Text>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                            : plan == 'advanced' ?
                                                currentPlan == 'advanced-yearly' || ((currentPlan == 'starter-monthly' || currentPlan == 'starter-yearly') && location?.state?.status == "activeUpgrade") ?
                                                    <Grid item alignItems={'center'} p={1} container xs={4} sx={{ boxShadow: "0px 2px 24px 0px #919EAB1F", borderRadius: "8px" }}>
                                                        <Grid item container sm={12} xs={12} pt={3} justifyContent='start'>
                                                            <Box display='flex' flexDirection='column' gap={1}>
                                                                <Text popupHead1 sx={{ fontWeight: '600 !important' }}>Affordable & Powerful</Text>
                                                                <Text mediumOverView>Feature-rich pricing that scales with your needs.</Text>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item container sm={12} xs={12} justifyContent={'center'} p={2} spacing={2}>
                                                            <Grid item xs={6} sm={6} pb={2}>
                                                                <Box sx={{ height: '143px', border: checkplanAdvanced == 5 ? '1px solid #0C75EB' : '1px solid #C7CCD3', padding: '24px, 10px, 24px, 10px', borderRadius: '10px', position: 'relative', display: 'inline-flex' }}>
                                                                    <Box sx={{ textAlign: 'center', alignItems: 'center', position: 'absolute', top: '5%', left: '-3.5%' }}>
                                                                        <img src={yearlyTag} alt='' />
                                                                    </Box>
                                                                    <Grid container xs={12} pt={3}>
                                                                        <Grid item container xs={12} justifyContent={'center'}>
                                                                            <Box pl={3}>
                                                                                <RadioGroup
                                                                                    row
                                                                                    name="yearly"
                                                                                    value={checkplanAdvanced}
                                                                                    items={statusItems2}
                                                                                    // disabled={currentPlan == 'advanced-monthly' ? true : false}
                                                                                    onChange={(e) => handlechange(e, "yearly", 'advanced')}
                                                                                />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                            <Text blackFont14 sx={{ color: '#171717 !important', fontWeight: '400 !important' }}>Billed Yearly</Text>
                                                                        </Grid>
                                                                        <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                            <Text blue14 sx={{ fontSize: '18px !important' }}>$83<span style={{ color: '#0C75EB', font: '14px Quicksand', fontWeight: '400' }}>.25</span></Text>
                                                                        </Grid>
                                                                        <Grid item container xs={12} justifyContent={'center'} pt={0.5} pb={2}>
                                                                            <Text greysmallLabel sx={{ color: '#949494 !important' }}>Per month</Text>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                            {((currentPlan == 'starter-monthly' || currentPlan != 'starter-yearly') && location?.state?.status == "activeUpgrade")
                                                                ?
                                                                <Grid item xs={6} sm={6}>
                                                                    <Box sx={{ height: '143px', border: checkplanAdvanced == 4 ? '1px solid #0C75EB' : '1px solid #C7CCD3', padding: '24px, 10px, 24px, 10px', borderRadius: '10px' }}>
                                                                        <Grid container xs={12} pt={3}>
                                                                            <Grid item container xs={12} justifyContent={'center'}>
                                                                                <Box pl={4}>
                                                                                    <RadioGroup
                                                                                        row
                                                                                        name="yearly"
                                                                                        value={checkplanAdvanced}
                                                                                        items={statusItems3}
                                                                                        disabled={currentPlan == 'advanced-yearly' ? true : false}
                                                                                        onChange={(e) => handlechange(e, "monthly", 'advanced')}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                <Text blackFont14 sx={{ color: '#171717 !important', fontWeight: '400 !important' }}>Billed Monthly</Text>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                <Text blue14 sx={{ fontSize: '18px !important' }}>$95<span style={{ font: '14px Quicksand', fontWeight: '400' }}>.00</span></Text>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5} pb={2}>
                                                                                <Text greysmallLabel sx={{ color: '#949494 !important' }}>Per month</Text>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                                :
                                                                <Grid item xs={6} sm={6}>
                                                                    <Box sx={{ height: '143px', border: checkplanAdvanced == 4 ? '1px solid #0C75EB' : '1px solid #C7CCD3', padding: '24px, 10px, 24px, 10px', borderRadius: '10px', background: '#F6F6F6' }}>
                                                                        <Grid container xs={12} pt={3}>
                                                                            <Grid item container xs={12} justifyContent={'center'}>
                                                                                <Box pl={4}>
                                                                                    <RadioGroup
                                                                                        row
                                                                                        name="yearly"
                                                                                        value={checkplanAdvanced}
                                                                                        items={statusItems3}
                                                                                        disabled={(currentPlan == 'starter-yearly' || currentPlan == 'advanced-yearly') ? true : false}
                                                                                        onChange={(e) => handlechange(e, "monthly", 'advanced')}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                <Text blackFont14 sx={{ color: '#171717 !important', fontWeight: '400 !important' }}>Billed Monthly</Text>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                <Text mediumBlue600>Contact Sales</Text>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5} pb={2}>
                                                                                <Text onClick={() => { window.open('https://www.japfu.ai/contact', '_blank') }} mediumBlue sx={{ textDecorator: '1px solid 0C75EB !important', cursor: 'pointer', textDecoration: 'underline' }}>sales@japfu.ai</Text>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            }

                                                            <Grid item container pb={2}>
                                                                <Divider sx={{ width: '99%', borderColor: '#D9D9D9' }} />
                                                            </Grid>
                                                            {
                                                                ((location?.state?.status == "renew" && (!planData?.subscription_expiring && !planData?.is_plan_auto_renewed)) || (location?.state?.renewal && location?.state?.autoRenewStarterMonthly)) || (location?.state?.autoRenewStarter && (!planData?.subscription_expiring && !planData?.is_plan_auto_renewed)) ?
                                                                    <Grid container>
                                                                        <Box sx={{ height: '100px', width: '60vw', borderRadius: '8px', padding: '14px 12px 14px 12px', background: '#F0F5FF' }}>
                                                                            <Grid item container>
                                                                                <Grid container xs={6} justifyContent={'flex-start'}>
                                                                                    <Box p={1} pt={0} >
                                                                                        <Text mediumBoldBlack sx={{ fontWeight: '600 !important' }}>Bill Today</Text>
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item container xs={6} justifyContent={'flex-end'}>
                                                                                    <Box p={1} pt={0}>
                                                                                        <Text popupHead1 sx={{ fontWeight: '600 !important' }}>{checkplanAdvanced == 4 ? '$95' : '$999'}<span style={{ font: '15px Quicksand', color: '#262626' }}>.00</span></Text>
                                                                                    </Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Divider sx={{ width: '100%', borderColor: '#D9D9D9' }} />
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'}>
                                                                                <Box p={1} pt={2}>
                                                                                    <Text offBoardBodyFont>Auto-renews for {checkplanAdvanced == 4 ? '$95.00 every month' : '$999.00 every year'} including taxes.</Text>
                                                                                </Box>
                                                                            </Grid>

                                                                        </Box>
                                                                        <Grid item container sm={12} xs={12} justifyContent={'center'} pt={1} mt={1}>
                                                                            <LoadingButton
                                                                                blueButton
                                                                                loading={loader}
                                                                                variant="contained"
                                                                                onClick={() => { PlanSubmit() }}
                                                                                fullWidth
                                                                            >
                                                                                Complete Payment
                                                                            </LoadingButton>
                                                                        </Grid>
                                                                        <Grid item container xs={12} justifyContent={'center'} pt={1}>
                                                                            <Text offBoardBodyFont>All transactions charged in USD</Text>
                                                                        </Grid>
                                                                    </Grid>
                                                                    :
                                                                    <Grid item container sm={12} xs={12} pt={0} justifyContent='start'>
                                                                        <Box sx={{ background: '#F0F5FF', borderRadius: '8px', width: '100%' }}>
                                                                            <Grid item container xs={12} sx={{ padding: '12px' }}>
                                                                                <Grid item xs={6}>
                                                                                    <Text mediumBoldBlack>Bill Today</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6} textAlign='end'>
                                                                                    <Text mediumBoldBlack>{LocalStorage.getCurrencySymbol()} {proratedValue?.amount}</Text>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item >
                                                                                <Divider sx={{ width: '100%', borderColor: '#D9D9D9' }} />
                                                                            </Grid>
                                                                            <Grid item container xs={12} spacing={1} sx={{ padding: '8px 14px' }}>
                                                                                <Grid item xs={6}>
                                                                                    <Text offBoardBodyFont>Remaining Validity</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6} textAlign='end'>
                                                                                    <Text offBoardBodyFont>{proratedValue?.period_days} Days</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Text offBoardBodyFont>Prorated Amount</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6} textAlign='end'>
                                                                                    <Text offBoardBodyFont>{LocalStorage.getCurrencySymbol()} {checkplanAdvanced == 4 ? (95 - proratedValue?.amount).toFixed(2) : (999 - proratedValue?.amount).toFixed(2)}</Text>
                                                                                </Grid>
                                                                                <Grid item container xs={12} py={2}>
                                                                                    <Grid item xs={.5}>
                                                                                        <img src={vector} alt='vestor' />
                                                                                    </Grid>
                                                                                    <Grid item xs={11.5}>
                                                                                        <Text mediumGreyHeader1>As your current subscription is active, you'll be charged for your next renewal based on the pro-rated price. After the first renewal, the original plan price (<span style={{ color: '#0C75EB', font: '14px Quicksand', fontWeight: '600' }}>{LocalStorage.getCurrencySymbol()}{checkplanAdvanced == 4 ? 99 : 999}</span>) will be applicable on all further renewals.</Text>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                        <Grid item container sm={12} xs={12} justifyContent={'center'} pt={1} my={1}>
                                                                            <LoadingButton
                                                                                blueButton
                                                                                loading={loader}
                                                                                variant="contained"
                                                                                onClick={() => { PlanSubmit() }}
                                                                                fullWidth
                                                                            >
                                                                                Complete Payment
                                                                            </LoadingButton>
                                                                        </Grid>
                                                                        <Grid item container xs={12} justifyContent={'center'} pt={1}>
                                                                            <Text offBoardBodyFont>All transactions charged in USD</Text>
                                                                        </Grid>
                                                                    </Grid>
                                                            }

                                                        </Grid>
                                                        {
                                                            ((currentPlan == 'starter-monthly' || currentPlan == 'starter-yearly') && location?.state?.status == "activeUpgrade") || (currentPlan == 'advanced-monthly' || currentPlan == 'advanced-yearly') ? '' :
                                                                <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                                                    <Box className={classes.buttonStyles}>
                                                                        <LoadingButton
                                                                            blueButton
                                                                            sx={{ width: '28.5vw !important' }}
                                                                            loading={loader}
                                                                            variant="contained"
                                                                            onClick={() => { PlanSubmit() }}
                                                                            fullWidth
                                                                        >
                                                                            Complete Payment
                                                                        </LoadingButton>
                                                                    </Box>
                                                                    <Grid item container xs={12} justifyContent={'center'} pt={1}>
                                                                        <Text offBoardBodyFont>All transactions charged in USD</Text>
                                                                    </Grid>
                                                                </Grid>
                                                        }

                                                    </Grid>
                                                    :
                                                    currentPlan == 'advanced-monthly' && location?.state?.status != 'renew' && location?.state?.autoRenewAdvance != true ?
                                                        <Grid item p={1} pl={4} container xs={4} sx={{ boxShadow: "0px 2px 24px 0px #919EAB1F", borderRadius: "8px" }}>
                                                            <Grid item container sm={12} xs={12} pt={2} justifyContent='start'>
                                                                <Box display='flex' flexDirection='column' gap={1}>
                                                                    <Text popupHead1>Current plan</Text>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item sm={12} xs={12} pt={2} justifyContent='start'>
                                                                <Box display='flex' flexDirection='column' sx={{ background: 'linear-gradient(135deg, #FFC80B, #FFD995)', padding: '10px 16px', borderRadius: '8px' }}>
                                                                    <Text mediumWhite400 sx={{ fontWeight: '600px !important' }}>Monthly</Text>
                                                                    <Text largeWhite18>{LocalStorage.getCurrencySymbol()} 95<span style={{ color: '#fff', font: '14px Quicksand', fontWeight: '400' }}> Per month</span></Text>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item sm={12} xs={12} pt={2} justifyContent='start'>
                                                                <Text offBoardBodyFont>Renews for $95 every month including taxes.</Text>
                                                            </Grid>
                                                            <Grid item container pt={1} pb={2}>
                                                                <Divider sx={{ width: '99%', borderColor: '#D9D9D9' }} />
                                                            </Grid>
                                                            <Grid item sm={12} xs={12} justifyContent='start'>
                                                                <Text popupHead1 sx={{ fontWeight: '600 !important' }}>Looking to Upgrade?</Text>
                                                            </Grid>
                                                            <Grid item container sm={12} xs={12} pt={2} justifyContent='start'>
                                                                <Box display='flex' flexDirection='column' sx={{ border: '1px solid #0C75EB', borderRadius: '10px', height: '64px', width: '100%', padding: '10px 0px 0px 10px' }}>
                                                                    <Text black14 sx={{ fontWeight: '400px !important' }}>Billed Yearly</Text>
                                                                    <Text largeBoldBlue>{LocalStorage.getCurrencySymbol()} 83<span style={{ color: '#0C75EB', font: '14px Quicksand', fontWeight: '400' }}>.00</span><span style={{ color: '#949494', font: '12px Quicksand', fontWeight: '400' }}> &nbsp;Per month</span></Text>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item container sm={12} xs={12} pt={2} justifyContent='start'>
                                                                <Box sx={{ background: '#F0F5FF', borderRadius: '8px', width: '100%' }}>
                                                                    <Grid item container xs={12} sx={{ padding: '14px' }}>
                                                                        <Grid item xs={6}>
                                                                            <Text mediumBoldBlack>Bill Today</Text>
                                                                        </Grid>
                                                                        <Grid item xs={6} textAlign='end'>
                                                                            <Text mediumBoldBlack>{LocalStorage.getCurrencySymbol()} {proratedValue?.amount}</Text>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item py={2}>
                                                                        <Divider sx={{ width: '100%', borderColor: '#D9D9D9' }} />
                                                                    </Grid>
                                                                    <Grid item container xs={12} spacing={1} sx={{ padding: '4px 14px' }}>
                                                                        <Grid item xs={6}>
                                                                            <Text offBoardBodyFont>Remaining Validity</Text>
                                                                        </Grid>
                                                                        <Grid item xs={6} textAlign='end'>
                                                                            <Text offBoardBodyFont>{proratedValue?.period_days} Days</Text>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Text offBoardBodyFont>Prorated Amount</Text>
                                                                        </Grid>
                                                                        <Grid item xs={6} textAlign='end'>
                                                                            <Text offBoardBodyFont>{LocalStorage.getCurrencySymbol()} {checkplanAdvanced == 4 ? (95 - proratedValue?.amount).toFixed(2) : (999 - proratedValue?.amount).toFixed(2)}</Text>
                                                                        </Grid>
                                                                        <Grid item container xs={12} py={2}>
                                                                            <Grid item xs={.5}>
                                                                                <img src={vector} alt='vestor' />
                                                                            </Grid>
                                                                            <Grid item xs={11.5}>
                                                                                <Text mediumGreyHeader1>As your current subscription is active, you'll be charged for your next renewal based on the pro-rated price. After the first renewal, the original plan price (<span style={{ color: '#0C75EB', font: '14px Quicksand', fontWeight: '600' }}>{LocalStorage.getCurrencySymbol()}{checkplanAdvanced == 4 ? 99 : 999}</span>) will be applicable on all further renewals.</Text>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                                <Grid item container sm={12} xs={12} justifyContent={'center'} pt={1} my={1}>
                                                                    <LoadingButton
                                                                        blueButton
                                                                        loading={loader}
                                                                        variant="contained"
                                                                        onClick={() => { PlanSubmit() }}
                                                                        fullWidth
                                                                    >
                                                                        Complete Payment
                                                                    </LoadingButton>
                                                                </Grid>
                                                                <Grid item container xs={12} justifyContent={'center'} my={1}>
                                                                    <Text offBoardBodyFont>All transactions charged in USD</Text>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        <Grid item p={1} container xs={4} sx={{ boxShadow: "0px 2px 24px 0px #919EAB1F", borderRadius: "8px" }}>
                                                            <Grid item container sm={12} xs={12} pt={4} justifyContent='start'>
                                                                <Box display='flex' flexDirection='column' gap={1}>
                                                                    <Text popupHead1 sx={{ fontWeight: '600 !important' }}>Affordable & Powerful</Text>
                                                                    <Text mediumOverView>Feature-rich pricing that scales with your needs.</Text>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item container sm={12} xs={12} justifyContent={'center'} p={2} spacing={2}>
                                                                <Grid item xs={6} sm={6}>
                                                                    <Box sx={{ height: '143px', border: checkplanAdvanced == 5 ? '1px solid #0C75EB' : '1px solid #C7CCD3', padding: '24px, 10px, 24px, 10px', borderRadius: '10px', position: 'relative', display: 'inline-flex' }}>
                                                                        <Box sx={{ textAlign: 'center', alignItems: 'center', position: 'absolute', top: '5%', left: '-3.5%' }}>
                                                                            <img src={yearlyTag} alt='' />
                                                                        </Box>
                                                                        <Grid container xs={12} pt={3}>
                                                                            <Grid item container xs={12} justifyContent={'center'}>
                                                                                <Box pl={3}>
                                                                                    <RadioGroup
                                                                                        row
                                                                                        name="yearly"
                                                                                        value={checkplanAdvanced}
                                                                                        items={statusItems2}
                                                                                        // disabled={currentPlan == 'advanced-monthly' ? true : false}
                                                                                        onChange={(e) => handlechange(e, "yearly", 'advanced')}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                <Text blackFont14 sx={{ color: '#171717 !important', fontWeight: '400 !important' }}>Billed Yearly</Text>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                <Text blue14 sx={{ fontSize: '18px !important' }}>$83<span style={{ color: '#0C75EB', font: '14px Quicksand', fontWeight: '400' }}>.25</span></Text>
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'} pt={0.5} pb={2}>
                                                                                <Text greysmallLabel sx={{ color: '#949494 !important' }}>Per month</Text>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                                {
                                                                    currentPlan == 'starter-yearly' ?
                                                                        <Grid item xs={6} sm={6}>
                                                                            <Box sx={{ height: '143px', border: checkplanAdvanced == 4 ? '1px solid #0C75EB' : '1px solid #C7CCD3', padding: '24px, 10px, 24px, 10px', borderRadius: '10px', background: '#F6F6F6' }}>
                                                                                <Grid container xs={12} pt={3}>
                                                                                    <Grid item container xs={12} justifyContent={'center'}>
                                                                                        <Box pl={4}>
                                                                                            <RadioGroup
                                                                                                row
                                                                                                name="yearly"
                                                                                                value={checkplanAdvanced}
                                                                                                items={statusItems3}
                                                                                                disabled={(currentPlan == 'starter-yearly' || currentPlan == 'advanced-yearly') ? true : false}
                                                                                                onChange={(e) => handlechange(e, "monthly", 'advanced')}
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                        <Text blackFont14 sx={{ color: '#171717 !important', fontWeight: '400 !important' }}>Billed Monthly</Text>
                                                                                    </Grid>
                                                                                    <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                        <Text mediumBlue600>Contact Sales</Text>
                                                                                    </Grid>
                                                                                    <Grid item container xs={12} justifyContent={'center'} pt={0.5} pb={2}>
                                                                                        <Text onClick={() => { window.open('https://www.japfu.ai/contact', '_blank') }} mediumBlue sx={{ textDecorator: '1px solid 0C75EB !important', cursor: 'pointer', textDecoration: 'underline' }}>sales@japfu.ai</Text>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                        :
                                                                        <Grid item xs={6} sm={6}>
                                                                            <Box sx={{ height: '143px', border: checkplanAdvanced == 4 ? '1px solid #0C75EB' : '1px solid #C7CCD3', padding: '24px, 10px, 24px, 10px', borderRadius: '10px' }}>
                                                                                <Grid container xs={12} pt={3}>
                                                                                    <Grid item container xs={12} justifyContent={'center'}>
                                                                                        <Box pl={4}>
                                                                                            <RadioGroup
                                                                                                row
                                                                                                name="yearly"
                                                                                                value={checkplanAdvanced}
                                                                                                items={statusItems3}
                                                                                                disabled={(currentPlan == 'starter-yearly' || currentPlan == 'advanced-yearly') ? true : false}
                                                                                                onChange={(e) => handlechange(e, "monthly", 'advanced')}
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                        <Text blackFont14 sx={{ color: '#171717 !important', fontWeight: '400 !important' }}>Billed Monthly</Text>
                                                                                    </Grid>
                                                                                    <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                                                        <Text blue14 sx={{ fontSize: '18px !important' }}>$95<span style={{ font: '14px Quicksand', fontWeight: '400' }}>.00</span></Text>
                                                                                    </Grid>
                                                                                    <Grid item container xs={12} justifyContent={'center'} pt={0.5} pb={2}>
                                                                                        <Text greysmallLabel sx={{ color: '#949494 !important' }}>Per month</Text>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                }


                                                                <Grid item container pt={1} pb={4}>
                                                                    <Divider sx={{ width: '99%', borderColor: '#D9D9D9' }} />
                                                                </Grid>
                                                                {
                                                                    location?.state?.autoRenewAdvance && planData?.is_plan_auto_renewed && currentPlan != 'trail' ?
                                                                        <Box sx={{ background: '#F0F5FF', borderRadius: '8px', width: '100%' }}>
                                                                            <Grid item container xs={12} sx={{ padding: '12px' }}>
                                                                                <Grid item xs={6}>
                                                                                    <Text mediumBoldBlack>Bill Today</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6} textAlign='end'>
                                                                                    <Text mediumBoldBlack>{LocalStorage.getCurrencySymbol()} {proratedValue?.amount}</Text>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item >
                                                                                <Divider sx={{ width: '100%', borderColor: '#D9D9D9' }} />
                                                                            </Grid>
                                                                            <Grid item container xs={12} spacing={1} sx={{ padding: '8px 14px' }}>
                                                                                <Grid item xs={6}>
                                                                                    <Text offBoardBodyFont>Remaining Validity</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6} textAlign='end'>
                                                                                    <Text offBoardBodyFont>{proratedValue?.period_days} Days</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Text offBoardBodyFont>Prorated Amount</Text>
                                                                                </Grid>
                                                                                <Grid item xs={6} textAlign='end'>
                                                                                    <Text offBoardBodyFont>{LocalStorage.getCurrencySymbol()} {checkplanAdvanced == 4 ? (95 - proratedValue?.amount).toFixed(2) : (999 - proratedValue?.amount).toFixed(2)}</Text>
                                                                                </Grid>
                                                                                <Grid item container xs={12} py={2}>
                                                                                    <Grid item xs={.5}>
                                                                                        <img src={vector} alt='vestor' />
                                                                                    </Grid>
                                                                                    <Grid item xs={11.5}>
                                                                                        <Text mediumGreyHeader1>As your current subscription is active, you'll be charged for your next renewal based on the pro-rated price. After the first renewal, the original plan price (<span style={{ color: '#0C75EB', font: '14px Quicksand', fontWeight: '600' }}>{LocalStorage.getCurrencySymbol()}{checkplanAdvanced == 4 ? 99 : 999}</span>) will be applicable on all further renewals.</Text>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                        :
                                                                        <Box sx={{ height: '100px', width: '60vw', borderRadius: '8px', padding: '14px 12px 14px 12px', background: '#F0F5FF' }}>
                                                                            <Grid item container>
                                                                                <Grid container xs={6} justifyContent={'flex-start'}>
                                                                                    <Box p={1} pt={0} >
                                                                                        <Text mediumBoldBlack sx={{ fontWeight: '600 !important' }}>Bill Today</Text>
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item container xs={6} justifyContent={'flex-end'}>
                                                                                    <Box p={1} pt={0}>
                                                                                        <Text popupHead1 sx={{ fontWeight: '600 !important' }}>{checkplanAdvanced == 4 ? '$95' : '$999'}<span style={{ font: '15px Quicksand', color: '#262626' }}>.00</span></Text>
                                                                                    </Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Divider sx={{ width: '100%', borderColor: '#D9D9D9' }} />
                                                                            </Grid>
                                                                            <Grid item container xs={12} justifyContent={'center'}>
                                                                                <Box p={1} pt={2}>
                                                                                    <Text offBoardBodyFont>Auto-renews for {checkplanAdvanced == 4 ? '$95.00 every month' : '$999.00 every year'} including taxes.</Text>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Box>
                                                                }
                                                            </Grid>
                                                            {
                                                                (currentPlan == 'advanced-monthly' && location?.state?.status == 'renew') || (currentPlan == 'advanced-monthly' && location?.state?.autoRenewAdvance) ?
                                                                    <Grid item container sm={12} xs={12} justifyContent={'center'} mr={2} pl={2}>
                                                                        <LoadingButton
                                                                            blueButton
                                                                            loading={loader}
                                                                            variant="contained"
                                                                            onClick={() => { PlanSubmit() }}
                                                                            fullWidth
                                                                        >
                                                                            Complete Payment
                                                                        </LoadingButton>
                                                                    </Grid> :
                                                                    (currentPlan == 'advanced-monthly' || currentPlan == 'advanced-yearly') ? '' :
                                                                        <Grid item container sm={12} xs={12} justifyContent={'center'} mr={2} pl={2}>
                                                                            <LoadingButton
                                                                                blueButton
                                                                                loading={loader}
                                                                                variant="contained"
                                                                                onClick={() => { PlanSubmit() }}
                                                                                fullWidth
                                                                            >
                                                                                Complete Payment
                                                                            </LoadingButton>
                                                                        </Grid>
                                                            }
                                                            <Grid item container xs={12} justifyContent={'center'}>
                                                                <Text offBoardBodyFont>All transactions charged in USD</Text>
                                                            </Grid>
                                                        </Grid>

                                                :
                                                <Grid item container xs={4} justifyContent={'center'} alignItems={'center'} sx={{ boxShadow: "0px 2px 24px 0px #919EAB1F", borderRadius: "8px" }}>
                                                    <Grid item xs={12}>
                                                        <Box height={'8vh'} />
                                                    </Grid>
                                                    <Grid item container xs={12} justifyContent={'center'} alignItems={'center'}>
                                                        <img src={great} alt='logo' />
                                                    </Grid>
                                                    {
                                                        currentPlan == 'trail' ?
                                                            <Grid item container sm={12} xs={12} justifyContent={'center'} textAlign='center'>
                                                                <Grid item xs={12}>
                                                                    <Box p={1} pb={1}>
                                                                        <Text veryLargeLabel>Current plan - No cost!</Text>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box p={1} pb={1}>
                                                                        <Text offBoardBodyFont>You are on the No Cost Plan starting {startDate}.</Text>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                            :
                                                            (currentPlan == 'advanced-yearly' || currentPlan == 'advanced-monthly' || currentPlan == 'starter-yearly' || currentPlan == 'starter-monthly')
                                                                ?
                                                                <Grid item container spacing={0}>
                                                                    <Grid item container sm={12} xs={12} justifyContent={'center'} pt={0}>
                                                                        <Box p={1} pb={1}>
                                                                            <Text popupHead1 sx={{ color: '#090914 !important' }}>No Cost Plan</Text>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                                :
                                                                <Grid item container spacing={4}>
                                                                    <Grid item container sm={12} xs={12} justifyContent={'center'} pt={2}>
                                                                        <Box p={1} pb={1}>
                                                                            <Text popupHead1 sx={{ color: '#090914 !important' }}>No Cost</Text>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                                                        <Box className={classes.margin} p={0}>
                                                                            <Text grey16>No hidden charges. Free for lifetime</Text>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                                                        <Box className={classes.buttonStyles}>
                                                                            <LoadingButton
                                                                                className={classes.loginButton}
                                                                                loading={loader}
                                                                                variant="contained"
                                                                                onClick={() => { freePlanSubmit() }}
                                                                            >
                                                                                Continue
                                                                            </LoadingButton>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                    }
                                                    <Grid item xs={12}>
                                                        <Box height={'10vh'} />
                                                    </Grid>
                                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    </>
            }
        </Grid>
    )
}

export default Billing