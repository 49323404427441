import React, { useEffect, useState } from 'react'
import { Box, Grid, Skeleton } from '@mui/material';
import Text from '../../../components/customText/Text';
import { capitalizeAndAddSpace } from '../../../utils/utils';
import Input from '../../../components/input/Input';
import Select from '../../../components/select/Select';
import PlacementApi from '../../../apis/admin/placements/PlacementApi';
import ClientsApi from '../../../apis/admin/clients/ClientsApi';

function ClientDetails(props) {

    const { HtmlTooltip } = props;

    const placementID = props.id ? props.id : "";
    const [loading, setLoading] = useState(false);// eslint-disable-next-line
    const [edit, setEdit] = useState(false);// eslint-disable-next-line
    const [error, setError] = useState({});
    const [clientContacts, setClientContacts] = useState([]);
    const [endClientContacts, setEndClientContacts] = useState([]);
    const [state, setState] = useState({
        client_name: "",
        client_id: "",
        client_reference_id: "",
        client_contact_id: '',
        client_contact_name: "",
        end_client_name: "",
        end_client_id: "",
        end_client_reference_id: "",
        end_client_contact_id: '',
        end_client_contact_name: ""
    })

    useEffect(() => {
        getPlacementClientData(placementID)// eslint-disable-next-line
    }, []);
    // eslint-disable-next-line
    const [employeeId, setEmployeeId] = useState("")

    const getPlacementClientData = (id) => {
        setLoading(true);
        PlacementApi.getPlacementIndexConsultant("client-details", id).then((res) => {
            if (res.data.statusCode === 1003) {
                setLoading(false);
                setEmployeeId(res.data.data.employee_details.employee_id);

                if (res.data.data.client_details.client_id !== "" && res.data.data.client_details.client_id !== null) {
                    clientContactDropdown(res.data.data.client_details.client_id);
                    props.setclientDetails(res.data.data.client_details)
                }
                if (res.data.data.client_details.end_client_id !== "" && res.data.data.client_details.end_client_id !== null) {
                    endClientContactsDropdown(res.data.data.client_details.end_client_id);
                }
                setState({ ...res.data.data.client_details })

            } else {
                setLoading(false);
            }
        })
    }

    const clientContactDropdown = (id) => {
        ClientsApi.contactsDropdown('client', id).then((res) => {
            if (res.data.statusCode === 1003) {
                setClientContacts(res.data.data);
            }
        })
    }

    const endClientContactsDropdown = (id) => {
        ClientsApi.contactsDropdown('end-client', id).then((res) => {
            if (res.data.statusCode === 1003) {
                setEndClientContacts(res.data.data);
            }
        })
    }

    return (
        <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12} pt={5} p={0.1} mt={3}>
                <Box mb={2} sx={{ boxShadow: "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", height: "65vh", overflow: "auto !important" }}>
                    <Grid container p={3} alignItems={"center"} >
                        <Grid item lg={6} md={6} sm={6}>
                            <Text largeBlue noWrap>Client Details</Text>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} textAlign={"end"}>
                        </Grid>

                        {
                            edit ?
                                <Grid container columnSpacing={4} pt={4}>
                                    <Grid item lg={4} md={4} sm={4} >
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'client_name',
                                                value: state.client_name
                                            }}
                                            disabled={true}
                                            clientInput
                                            labelText={`Client Name`}
                                        />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4} >
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'client_reference_id',
                                                value: state.client_reference_id
                                            }}
                                            disabled={true}
                                            clientInput
                                            labelText={`Client ID`}
                                        />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4} >
                                        <Select
                                            name='client_contact_id'
                                            value={state.client_contact_id}
                                            options={clientContacts}
                                            label={'Contact'}
                                            helperText={error.client_contact_id ? <Text red>{error.client_contact_id}</Text> : ""}
                                        />
                                    </Grid>
                                </Grid> :
                                <>
                                    <Grid item lg={3} md={3} sm={3} pt={2}>
                                        {
                                            loading ?
                                                <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                                                :
                                                <>
                                                    <Text mediumGreyHeader1 noWrap>Client Name</Text>
                                                    <Text mediumBlack14 pt={0.8} noWrap>
                                                        {state.client_name === "" ? "--" : capitalizeAndAddSpace(state.client_name).length > 16 ?
                                                            <HtmlTooltip title={capitalizeAndAddSpace(state.client_name)} placement="right" arrow>
                                                                {capitalizeAndAddSpace(state.client_name).slice(0, 16) + (capitalizeAndAddSpace(state.client_name).length > 16 ? "..." : "")}
                                                            </HtmlTooltip>
                                                            :
                                                            capitalizeAndAddSpace(state.client_name)
                                                        }
                                                    </Text>
                                                </>
                                        }
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} pt={2}>
                                        {
                                            loading ?
                                                <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                                                :
                                                <>
                                                    <Text mediumGreyHeader1 noWrap>Client Id</Text>
                                                    <Text mediumBlack14 pt={0.8} noWrap>{state.client_reference_id ? state.client_reference_id : "--"}</Text>
                                                </>
                                        }
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4} pt={2}>
                                        {
                                            loading ?
                                                <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                                                :
                                                <>
                                                    <Text mediumGreyHeader1>Contact</Text>
                                                    <Text mediumBlack14 pt={0.8} noWrap>
                                                        {state.client_contact_name === "" ? "--" : capitalizeAndAddSpace(state.client_contact_name).length > 16 ?
                                                            <HtmlTooltip title={capitalizeAndAddSpace(state.client_contact_name)} placement="right" arrow>
                                                                {capitalizeAndAddSpace(state.client_contact_name).slice(0, 16) + (capitalizeAndAddSpace(state.client_contact_name).length > 16 ? "..." : "")}
                                                            </HtmlTooltip>
                                                            :
                                                            capitalizeAndAddSpace(state.client_contact_name)
                                                        }
                                                    </Text>
                                                </>
                                        }
                                    </Grid>
                                </>
                        }
                        <Grid item lg={12} md={12} sm={12} pt={5}>
                            <Text largeBlue>End Client Details</Text>
                        </Grid>
                        {
                            edit ?
                                <Grid container columnSpacing={4} pt={4}>
                                    <Grid item lg={4} md={4} sm={4} >
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'ebd_client_name',
                                                value: state.end_client_name
                                            }}
                                            disabled={true}
                                            clientInput
                                            labelText={`Client Name`}
                                        />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4} >
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'end_client_reference_id',
                                                value: state.end_client_reference_id
                                            }}
                                            disabled={true}
                                            clientInput
                                            labelText={`Client ID`}
                                        />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4} >
                                        <Select
                                            name='end_client_contact_id'
                                            value={state.end_client_contact_id}
                                            options={endClientContacts}
                                            label={'Contact'}
                                            disabled={state.end_client_id == ""}
                                            helperText={error.end_client_contact_id ? <Text red>{error.end_client_contact_id}</Text> : ""}
                                        />
                                    </Grid>
                                </Grid> :
                                <>
                                    <Grid item lg={3} md={3} sm={3} pt={2}>
                                        {
                                            loading ?
                                                <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                                                :
                                                <>
                                                    <Text mediumGreyHeader1>Client Name</Text>
                                                    <Text mediumBlack14 pt={0.8} noWrap>
                                                        {state.end_client_name === "" ? "--" : capitalizeAndAddSpace(state.end_client_name).length > 16 ?
                                                            <HtmlTooltip title={capitalizeAndAddSpace(state.end_client_name)} placement="right" arrow>
                                                                {capitalizeAndAddSpace(state.end_client_name).slice(0, 16) + (capitalizeAndAddSpace(state.end_client_name).length > 16 ? "..." : "")}
                                                            </HtmlTooltip>
                                                            :
                                                            capitalizeAndAddSpace(state.end_client_name)
                                                        }
                                                    </Text>
                                                </>
                                        }
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} pt={2}>
                                        {
                                            loading ?
                                                <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                                                :
                                                <>
                                                    <Text mediumGreyHeader1>Client Id</Text>
                                                    <Text mediumBlack14 pt={0.8} noWrap>{state.end_client_reference_id ? state.end_client_reference_id : "--"}</Text>
                                                </>
                                        }
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4} pt={2}>
                                        {
                                            loading ?
                                                <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                                                :
                                                <>
                                                    <Text mediumGreyHeader1>Contact</Text>
                                                    <Text mediumBlack14 pt={0.8} noWrap>
                                                        {state.end_client_contact_name === "" ? "--" : capitalizeAndAddSpace(state.end_client_contact_name).length > 16 ?
                                                            <HtmlTooltip title={capitalizeAndAddSpace(state.end_client_contact_name)} placement="right" arrow>
                                                                {capitalizeAndAddSpace(state.end_client_contact_name).slice(0, 16) + (capitalizeAndAddSpace(state.end_client_contact_name).length > 16 ? "..." : "")}
                                                            </HtmlTooltip>
                                                            :
                                                            capitalizeAndAddSpace(state.end_client_contact_name)
                                                        }
                                                    </Text>
                                                </>
                                        }
                                    </Grid>
                                </>
                        }

                    </Grid>
                </Box>

            </Grid>
        </Grid>
    )
}

export default ClientDetails