import React, { useState, useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Box, Stack, Grid, Checkbox, SwipeableDrawer, Divider, Menu, Backdrop, Badge, Chip, FormControlLabel, Drawer, Slider, Avatar, Breadcrumbs } from '@mui/material';
import crossIcon from '../../../../assets/svg/crossIcon.svg';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Skeleton from '@mui/material/Skeleton';
import Component87 from '../../../../assets/svg/Component87.svg';
import Search from '../../../../assets/svg/search1.svg';
import NewDownloadcloud from '../../../../assets/svg/download-cloud-new.svg';
import InviteViaLink from '../../../../assets/svg/invite-via-link.svg';
import Onboart from '../../../../assets/svg/onboard.svg';
import { addErrorMsg, addLoader, addLoaderPlanExpired, addSuccessMsg, BlackToolTip, capitalizeAndAddSpace, dateFormat, downloadExcel, NoDataFound, NoPermission, openDocumentInNewtab, removeExpiredLoader, removeLoader } from '../../../../utils/utils';
import { useLocation, useNavigate } from "react-router-dom";
import Text from '../../../../components/customText/Text';
import Button from '../../../../components/customButton/Button';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/cross.svg';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LocalStorage from '../../../../utils/LocalStorage';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import infoCircle from '../../../../assets/images/info-circle.png';
import ClearIcon from '@mui/icons-material/Clear';
import Loader from '../../../../assets/gif/japfuLoader.gif';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlacementDashboardStyles from '../../placements/newDashboard/PlacementDashboardStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Date from '../../../../components/datePicker/Date';
import nofilterplacement from '../../../../assets/svg/nofilterplacement.svg';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import { float_validation, onNumberFloatCurrency, onNumberWithTwoDecimalOnlyChangeWithNegative, validate_alphaNumeric, validate_emptyField } from '../../../../components/Validation';
import PlacementApi from '../../../../apis/admin/placements/PlacementApi';
import clientsApi from '../../../../apis/admin/clients/ClientsApi';
import CommonApi from '../../../../apis/CommonApi';
import { domain } from '../../../../config/Domain';
import DashboardAPI from '../../../../apis/admin/DashboardAPI';
import Pending from '../../../../assets/employee/Orange-PendingIcon.svg';
import CustomCircularProgress from '../../../../components/progressbar/CircularProgress';
import Userplus from '../../../../assets/svg/clients/user-plus-bold.svg';
import raisedImage from "../../../../assets/images/raised.png"
import approvedImage from "../../../../assets/images/check-circle.png"
import approvalImage from "../../../../assets/images/clock.png"
import totalImage from "../../../../assets/images/dollar-sign.png"
import Deduction from "./Deduction";
import Reimbursement from "./Reimbursement";
import Input from '../../../../components/input/Input';
import verified from '../../../../assets/svg/Component87.svg';
import PayrollApi from '../../../../apis/admin/payroll/ExpenseManagement';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import deleteAlert from "../../../../assets/svg/placementPop.svg";
import approvalIcon from '../../../../assets/svg/payroll/approvalIcon.svg';
import successIcon from '../../../../assets/svg/payroll/payrollApproved.svg';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} timeout={500} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

export default function ExpenseDashboard() {

    const location = useLocation();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = PlacementDashboardStyles();
    const navigate = useNavigate();
    const [placements, setPlacements] = useState([]);
    const [pagination, setPagination] = useState({ total: "6" });
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [open, setOpen] = React.useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [pendingApproval, setPendingApproval] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [activeState, setActiveState] = useState('reimbursement');
    const [openRejection, setOpenRejection] = useState(false);
    const [openApproval, setOpenApproval] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [status, setStatus] = useState('');
    const [statusErrors, setStatusErrors] = useState({
        approved_amount: '',
        reason_for_rejection: ''
    });
    const [viewData, setViewData] = useState({
        id: '',
        approved_amount: '',
        bank_name: '',
        date_of_expense: '',
        display_name: '',
        documents: [
            {
                id: '',
                document_name: ''
            }
        ],
        employee_reference_id: '',
        expense_effect_on: '',
        expense_type: '',
        last_digit_of_bank_account: '',
        name: '',
        pay_date: '',
        pay_period: '',
        payee_name: '',
        payment_mode: '',
        profile_picture_url: '',
        raised_amount: '',
        raised_on: '',
        remarks: '',
        status: '',
        transaction_id: '',
        visa_type: '',
        category_type: '',
        e_verified: ''
    });
    const [state, setState] = useState({
        approved_amount: '',
        reason_for_rejection: ''
    });
    const [filterData, setFilterData] = useState({
        limit: 10,
        page: 1,
        search: "",
        status: [],
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        deductionType: [],
        recruiter: [],
        timesheet_cycle: [],
        sort_column: "created_at",
        sort_order: "desc",
        expense_type_ids: [],
        raised_by: [],
        expense_effect_on: [],
        date_of_expense_from: "",
        date_of_expense_to: "",
        raised_amount_from: "",
        raised_amount_to: "",
        approved_amount_from: "",
        approved_amount_to: "",
        expenses: [],
        entity_type: activeState == 'reimbursement' ? 1 : 2,
        employee_id: LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id
    })
    const [openPopUp, setOpenPopup] = useState(false);
    const handleTab = (value) => {
        setActiveState(value)
        clearAllFilter()
        filterData.expense_type_ids = [];
        filterData.raised_by = [];
        filterData.expense_effect_on = [];
        filterData.status = []
        filterData.raised_amount_from = ''
        filterData.raised_amount_to = ''
        filterData.date_of_expense_from = ''
        filterData.date_of_expense_to = ''
        filterData.approved_amount_from = ''
        filterData.approved_amount_to = ''
        filterData.expenses = []
        setFilterData({ ...filterData });
    }

    const accordianHandler = (e, item) => {
        e.stopPropagation();
        setViewData(item);
        setOpenView(true);
    }

    const deductedFromDropdown = [
        {
            "id": 2,
            "value": "Balancesheet"
        },
        {
            "id": 1,
            "value": "Payroll"
        }
    ]
    const cardsJson = [
        {
            name: 'Total Reimbursements Raised',
            imgPath: raisedImage,
            mainKey: 'total_reimbursement_raised',
            color: '#E7F2FD'
        },
        {
            name: 'Total Reimbursements Approved',
            imgPath: approvedImage,
            mainKey: 'total_reimbursement_approved',
            color: '#EAF9EB'
        },
        {
            name: 'Total Reimbursements Pending Approval',
            imgPath: approvalImage,
            mainKey: 'total_reimbursement_pending_approval',
            color: '#F3F0FF'
        },
        {
            name: 'Total Reimbursed Amount',
            imgPath: totalImage,
            mainKey: 'total_reimbursed_amount',
            color: '#FFFBE1'
        }
    ]

    const DeductionCardsJson = [
        {
            name: 'Total Deductions',
            imgPath: raisedImage,
            mainKey: 'total_deductions',
            color: '#E7F2FD'
        },
        {
            name: 'Total Deductions Recovered',
            imgPath: approvedImage,
            mainKey: 'total_deductions_recovered',
            color: '#EAF9EB'
        },
        {
            name: 'Total Deductions Due',
            imgPath: approvalImage,
            mainKey: 'total_deduction_due',
            color: '#F3F0FF'
        }
    ]

    const [filterState, setFilterState] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        deductionType: [],
        status: [],
        recruiter: [],
        timesheet_cycle: [],
        expense_type_ids: [],
        raised_by: [],
        expense_effect_on: [],
        date_of_expense_from: "",
        date_of_expense_to: "",
        raised_amount_from: "",
        raised_amount_to: "",
        approved_amount_from: "",
        approved_amount_to: "",
        limit: 10,
        page: 1
    })
    const [datesError, setDatesError] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        date_of_expense_from: "",
        date_of_expense_to: "",
        raised_amount_from: "",
        raised_amount_to: "",
        approved_amount_from: "",
        approved_amount_to: "",
        approved_amount: "",
        reason_for_rejection: ""
    })
    const [openBacKLoader, setOpenBacKLoader] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [deductionTypeDropdown, setDeductionType] = useState([]);
    const [reimbursementTypeDropdown, setReimbursementType] = useState([]);
    const [employmentType, setEmploymentType] = useState([]);
    const [approver, setApprover] = useState('');
    const [cardDetails, setCardDetails] = useState([]);
    const [filterAmount, setFilterAmount] = useState({ raised_amount: { max: 0, min: 0 }, approved_amount: { max: 0, min: 0 } });
    const statusList = [{
        id: "Pending",
        value: "Pending"
    },
    {
        id: "Approved",
        value: "Approved"
    },
    {
        id: "Rejected",
        value: "Rejected"
    }
    ]

    useEffect(() => {
        if ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) {
            deductionTypeDropdownApi();
            employmentList()
            reimbursementTypeDropdownApi()
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const requestData = { ...filterData }
        requestData.entity_type = activeState == "reimbursement" ? 1 : 2
        setFilterData({ ...requestData })
        getAllPlacements(requestData);
        cardDetailsApi(activeState)
        // eslint-disable-next-line
    }, [activeState])


    useEffect(() => {
        getPlanCheck();
        // eslint-disable-next-line
    }, [])

    const employmentList = () => {
        CommonApi.employmentTypesList(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setEmploymentType(res.data.data);
            }
        })
    }

    const getPlanCheck = () => {
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
            }
        })
    }

    const deductionTypeDropdownApi = () => {
        clientsApi.deductionDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setDeductionType(res.data.data);
            }
        })
    }

    const reimbursementTypeDropdownApi = () => {
        clientsApi.reimbursementDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setReimbursementType(res.data.data);
            }
        })
    }


    const handleDownloadExport = () => {
        addLoader(true);
        filterData.request_id = LocalStorage.uid()
        PayrollApi.downLoadExpenseExcel(filterData).then((response) => {
            removeLoader();
            if (response?.data instanceof Blob) {
                const blob = response.data;
                const formattedDate = moment().format('DDMMYYhhmmssSSS');
                let filename = `ExpenseMangement_${formattedDate}.xlsx`
                downloadExcel(blob, filename)
            }
            else {
                addErrorMsg(response.data.message)
            }
        });
    }

    const cardDetailsApi = (args) => {
        let emp_id = LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id
        PlacementApi.getCarDetailListing(args ? args == 'reimbursement' ? 1 : 2 : activeState == 'reimbursement' ? 1 : 2, emp_id).then((res) => {
            if (res.data.statusCode === 1003) {
                setCardDetails(res.data.data);
            }
        })
    }


    const getAllPlacements = (filter) => {
        if (placements.length < 6) {
            setLoading(true);
        }
        PlacementApi.getExpenseListing(filter).then((response) => {
            removeLoader();
            setOpenBacKLoader(false);
            if (response.data.statusCode == 1003) {
                setDrawer(null);
                setLoading(false);
                setPlacements(response.data.data);
                setPagination({ total: response.data.pagination.total });
                setApprover(response.data.is_approver);
                setFilterAmount(response.data.filters)
            } else {
                setLoading(false);
                if (response.data.message == `You don't have access to perform this action. Please contact admin`) {

                }
                else {
                    addErrorMsg(response.data.message);
                }
            }
        })

    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleSearch = (e) => {
        // setOpenBacKLoader(true);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = e.target.value;
        setFilterData({ ...filterData });
        setLoading(true);
        // addLoader(true);
        getAllPlacements(filterData);

    }

    const handleSearchClear = () => {
        // setOpenBacKLoader(true);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = '';
        setFilterData({ ...filterData });
        getAllPlacements(filterData);

    }

    const handleDeleteChip = (id, name) => {
        if (name === "approved_amount" || name === "date_of_expense" || name === "raised_amount") {
            const updatedFilterState = { ...filterState }; // Create a copy of the filterState object

            // Update the start_date or end_date fields
            updatedFilterState[name + "_from"] = "";
            updatedFilterState[name + "_to"] = "";

            // Update the state with the modified filterState object
            setFilterState(updatedFilterState);
        } else {
            const newFilterState = { ...filterState };
            const updatedArray = newFilterState[name].filter(item => item !== id);
            newFilterState[name] = updatedArray;
            // if (filterState[name].includes(id)) {
            //     filterState[name].splice(filterState[name].findIndex(item => item === parseInt(id)), 1)
            // }
            setFilterState(newFilterState);
        }
    };

    const handleChangeCheckBox = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        const updatedArray = [...filterState[name]]; // Create a new array
        var finalValue = value
        if (e.target.name != "status") {
            finalValue = Number(value)
        }
        const index = updatedArray.findIndex(item => item === finalValue);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(finalValue); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }
    // eslint-disable-next-line
    const handleChangeRecruiters = (e) => {
        e.preventDefault();
        const { name } = e.target;
        var val = Number(e.target.value)
        const updatedArray = [...filterState[name]]; // Create a new array

        const index = updatedArray.findIndex(item => item === val);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(val); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const clearAllFilter = () => {
        datesError.start_date_from = '';
        datesError.start_date_to = '';
        datesError.end_date_from = '';
        datesError.end_date_to = '';
        setDatesError({ ...datesError });

        // filterState.timesheet_cycle = [];
        // filterState.deductionType = []
        // filterState.recruiter = [];
        // filterState.start_date = [];
        // filterState.end_date = [];
        // setFilterState({ ...filterState });
        setFilterState(prevState => ({
            ...prevState,
            expense_type_ids: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            status: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            raised_by: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            expense_effect_on: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            date_of_expense_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            date_of_expense_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            raised_amount_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            raised_amount_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            approved_amount_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            approved_amount_to: '' // Update the state with the new array
        }));
        // filterData.timesheet_cycle = [];
        // filterData.deductionType = []
        // filterData.recruiter = [];
        // filterData.start_date = [];
        // filterData.end_date = [];
        // setFilterData({ ...filterData });
        // setDrawer(null);
        // getAllPlacements(filterData);
    }

    const cancelFilter = () => {
        setDrawer(false);
    }

    const handleListItemClick = (param) => {
        setSelectedFilter(param);
    }

    const dateChange = (e, keyName) => {
        // let date = e.$d;
        // let event = { target: { name: keyName, value: date } }
        // datesState[keyName] = moment(date).format(dateFormat());
        // setDatesState({ ...datesState }, handleValidate(event));

        // if (datesState.start_range_one !== "" && datesState.end_range_one !== "") {
        //     filterState.start_date = [datesState.start_range_one, datesState.end_range_one];
        //     setFilterState({ ...filterState });
        //     // filterData.start_date = [datesState.start_range_one, datesState.end_range_one];
        //     // setFilterData({ ...filterData });
        // }
        // if (datesState.start_range_two !== "" && datesState.end_range_two !== "") {
        //     filterState.end_date = [datesState.start_range_two, datesState.end_range_two];
        //     setFilterState({ ...filterState });
        //     // filterData.end_date = [datesState.start_range_two, datesState.end_range_two];
        //     // setFilterData({ ...filterData });
        // }
        let date = e == null ? '' : e.$d
        let event = { target: { name: keyName, value: date } }
        filterState[keyName] = date == '' ? '' : moment(date).format(dateFormat());
        setFilterState({ ...filterState }, handleValidate(event));
    }

    const handleValidate = (e) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case 'start_date_to':
                datesError.start_date_to = validate_emptyField(input.value)
                break;
            case 'end_date_to':
                datesError.end_date_to = validate_emptyField(input.value)
                break;
            default:
                break;
        }
        setDatesError({ ...datesError });
    }

    const handleApplyFilters = () => {
        if (filterState.date_of_expense_from != "" && filterState.date_of_expense_to == "") {
            datesError.date_of_expense_to = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.date_of_expense_from == "" && filterState.date_of_expense_to != "") {
            datesError.date_of_expense_from = "Please select the from date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.date_of_expense_from !== "" && filterState.date_of_expense_to !== "") {
            if (moment(filterState.date_of_expense_from, dateFormat()).isSameOrBefore(moment(filterState.date_of_expense_to, dateFormat()))) {
                filterData.date_of_expense_from = filterState.date_of_expense_from;
                filterData.date_of_expense_to = filterState.date_of_expense_to;
            } else {
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
        } else {
            filterData.date_of_expense_from = '';
            filterData.date_of_expense_to = '';
        }
        if (filterState.raised_amount_from != "" && filterState.raised_amount_to == "") {
            datesError.raised_amount_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.raised_amount_from == "" && filterState.raised_amount_from != 0) && filterState.raised_amount_to != "") {
            datesError.raised_amount_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.raised_amount_from !== "" && filterState.raised_amount_to !== "") {
            if (filterState.raised_amount_from < filterState.raised_amount_to) {
                filterData.raised_amount_from = filterState.raised_amount_from;
                filterData.raised_amount_to = filterState.raised_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.raised_amount_from = '';
            filterData.raised_amount_to = '';
        }
        if (filterState.approved_amount_from != "" && filterState.approved_amount_to == "") {
            datesError.approved_amount_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.approved_amount_from == '' && filterState.approved_amount_from != 0) && filterState.approved_amount_to != "") {
            datesError.approved_amount_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.approved_amount_from !== "" && filterState.approved_amount_to !== "") {
            if (filterState.approved_amount_from < filterState.approved_amount_to) {
                filterData.approved_amount_from = filterState.approved_amount_from;
                filterData.approved_amount_to = filterState.approved_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.approved_amount_from = '';
            filterData.approved_amount_to = '';
        }
        filterData.expense_type_ids = filterState.expense_type_ids;
        filterData.raised_by = filterState.raised_by;
        filterData.expense_effect_on = filterState.expense_effect_on;
        filterData.status = filterState.status
        setFilterData({ ...filterData });
        addLoader(true)
        getAllPlacements({ ...filterData, limit: 10, page: 1 });
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const openStatus = Boolean(anchorEl1);
    const openFilter = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const handleStatusOpen = (event) => {
        setAnchorEl1(event.currentTarget);
    }

    const handleStatusClose = (param) => {
        setAnchorEl1(null);
        // setOpenBacKLoader(true);
        if (param === undefined) {
            filterData.limit = 10;
            filterData.page = 1;
            filterData.status = [];
            setFilterData({ ...filterData });
            setLoading(true);
            getAllPlacements(filterData);
        } else {
            filterData.limit = 10;
            filterData.page = 1;
            filterData['status'] = [param]
            setFilterData({ ...filterData });
            setLoading(true);
            getAllPlacements(filterData);
        }
    }

    const handleSort = (sort_column, sort_order) => {
        setAnchorEl(null);
        setOpenBacKLoader(true);
        filterData.sort_column = sort_column;
        filterData.sort_order = sort_order;
        setFilterData({ ...filterData });
        setLoading(true);
        getAllPlacements({ ...filterData });
        // setOpenBacKLoader(false);

    }

    const loadMoreData = () => {
        // addLoader(true);
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
        getAllPlacements(filterData);

    }

    const handleOpenDrawer = () => {
        setDrawer("filter");
        if (filterData.date_of_expense_from !== "" && filterData.date_of_expense_to !== "") {
            filterState.date_of_expense_from = filterData.date_of_expense_from;
            filterState.date_of_expense_to = filterData.date_of_expense_to;
            // setSelectedFilter(1);
        } else {
            filterState.date_of_expense_from = '';
            filterState.date_of_expense_to = '';
        }
        if (filterData.raised_amount_from !== "" && filterData.raised_amount_to !== "") {
            filterState.raised_amount_from = filterData.raised_amount_from;
            filterState.raised_amount_to = filterData.raised_amount_to;
            // setSelectedFilter(2);
        } else {
            filterState.raised_amount_from = '';
            filterState.raised_amount_to = '';
        }
        if (filterData.approved_amount_from !== "" && filterData.approved_amount_to !== "") {
            filterState.approved_amount_from = filterData.approved_amount_from;
            filterState.approved_amount_to = filterData.approved_amount_to;
            // setSelectedFilter(2);
        } else {
            filterState.approved_amount_from = '';
            filterState.approved_amount_to = '';
        }
        if (filterData.expense_type_ids.length > 0) {
            if (activeState == 'reimbursement') {
                setSelectedFilter(6);
            }
            else {
                setSelectedFilter(1);
            }

        }
        if (filterData.recruiter.length > 0) {
            setSelectedFilter(4);
        }
        if (filterData.timesheet_cycle.length > 0) {
            setSelectedFilter(5);
        }
        if (filterData.start_date_from == "" && filterData.start_date_to == "" && filterData.end_date_from == "" && filterData.end_date_to == "" && filterData.timesheet_cycle.length == 0 && filterData.deductionType.length == 0 && filterData.recruiter.length == 0) {
            setSelectedFilter(null);
        }
        filterState.deductionType = filterData.deductionType;
        filterState.expense_type_ids = filterData.expense_type_ids
        filterState.raised_by = filterData.raised_by
        filterState.expense_effect_on = filterData.expense_effect_on
        filterState.status = filterData.status
        filterState.recruiter = filterData.recruiter;
        filterState.timesheet_cycle = filterData.timesheet_cycle;
        setFilterState({ ...filterState });
        datesError.start_date_to = "";
        datesError.end_date_to = "";
        setDatesError({ ...datesError })
    }

    const scrollBox = useRef(null);

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(pagination.total) >= filterData.limit) {
                    setOpenBacKLoader(true);
                    loadMoreData();
                }
            } else {
            }
        }
    };

    const handleSliderChange = (event, newValue) => {
        filterState.approved_amount_from = newValue[0]
        filterState.approved_amount_to = newValue[1]
        setFilterState({ ...filterState })
    };

    const handleSliderChangeRaised = (event, newValue) => {
        filterState.raised_amount_from = newValue[0]
        filterState.raised_amount_to = newValue[1]
        setFilterState({ ...filterState })
    };

    const handleInputChange = (index, value, name, type) => {
        let numericValue;

        const baseName = name ? name.replace(/_from|_to$/, '') : '';
        const limitValue = type === "min"
            ? filterAmount[baseName].min
            : filterAmount[baseName].max;

        const maxValue = filterAmount[baseName].max;

        if (value === '-' || value === '' || value.endsWith('.')) {
            numericValue = value;
        } else if (!isNaN(Number(value))) {
            numericValue = Number(value);

            if (type === "min" && numericValue > maxValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), maxValue); // Ensure it stays above maxValue
            }
            else if (type === "min" && numericValue < limitValue) {
                numericValue = Math.max(Math.floor(numericValue / 10), limitValue); // Ensure it stays above minValue
            }
            if (type === "max" && numericValue > maxValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), maxValue); // Ensure it stays above maxValue
            } 
            else if (type === "max" && numericValue > limitValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), limitValue); // Ensure it stays below maxValue
            }
        } else {
            // Fallback to empty string for invalid input
            numericValue = '';
        }

        filterState[name] = numericValue;
        setFilterState({ ...filterState });

    };

    const handleFilterDrawerClose = () => {
        filterState.expense_type_ids = [];
        filterState.raised_by = []
        filterState.expense_effect_on = [];
        filterState.date_of_expense_from = '';
        filterState.date_of_expense_to = '';
        filterState.raised_amount_from = '';
        filterState.raised_amount_to = '';
        filterState.approved_amount_from = '';
        filterState.approved_amount_to = '';
        setFilterState({ ...filterState });
        setDrawer(null);
    }

    const pendingApprovalStatus = (args) => {
        filterData.expense_type_ids = [];
        filterData.raised_by = [];
        filterData.expense_effect_on = [];
        filterData.status = ["Pending"]
        filterData.raised_amount_from = ''
        filterData.raised_amount_to = ''
        filterData.date_of_expense_from = ''
        filterData.date_of_expense_to = ''
        filterData.approved_amount_from = ''
        filterData.approved_amount_to = ''
        filterData.expenses = []
        if (args == 'home') {
            filterData.status = []
            clearAllFilter()
            setPendingApproval(false)
        }
        else {
            filterData.status = ["Pending"]
            setFilterState(prevState => ({
                ...prevState,
                status: ["Pending"] // Update the state with the new array
            }));
            setPendingApproval(true)
        }
        setFilterData({ ...filterData });
        getAllPlacements(filterData)
    }

    const handleChangePendingApproval = (e, args, val) => {
        setOpenView(false);
        if (args == 'all') {
            if (placements.length == filterData.expenses.length) {
                setFilterData({ ...filterData, expenses: [] });
            }
            else {
                const ids = placements.map(item => item);
                setFilterData({ ...filterData, expenses: ids });
            }
        }
        else {
            const updatedArray = [...filterData['expenses']]; // Create a new array
            const finalValue = val.id;
            const index = updatedArray.findIndex(item => item.id === finalValue);

            if (index !== -1) {
                updatedArray.splice(index, 1); // Remove the object if it exists
            } else {
                // Find the object in the original array by its id and add it
                const newItem = placements.find(item => item.id == finalValue);
                if (newItem) {
                    updatedArray.push(newItem); // Add the full object if it doesn't exist
                }
            }


            setFilterData(prevState => ({
                ...prevState,
                expenses: updatedArray // Update the state with the new array
            }));
        }
    }

    const changeHandler = (e) => {
        if (e.target.name == 'approved_amount') {
            var value = e.target.value.trim();
            value = value.replace("$", "");
            if (value.length > 10) {
                const num = parseFloat(value);
                const isFloat = value.includes('.') || num % 1 !== 0;
                if (isFloat) {
                    state[e.target.name] = value
                } else {
                    if (value.length === 11) {
                        if (value.slice(-1) === ".") {
                            state[e.target.name] = value
                        } else {
                            return false;
                        }
                    }
                }
            }
            else {
                state[e.target.name] = value
            }
            setState({ ...state }, handleStatusValidate(e));
        } else {
            state[e.target.name] = e.target.value
            setState({ ...state }, handleStatusValidate(e));
        }
    }

    const handleStatusValidate = (e) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case 'approved_amount':
                statusErrors.approved_amount = float_validation(input.value)
                break;
            case 'reason_for_rejection':
                statusErrors.reason_for_rejection = validate_alphaNumeric(input.value)
                break;
            default:
                break;
        }
        setStatusErrors({ ...statusErrors });
    }

    const approveorRejectApi = (args) => {
        let expensesData = filterData.expenses
        let finalData = expensesData.map(item => ({
            ...item,
            status: "Rejected",
            reason_for_rejection: state.reason_for_rejection
        }));
        const data = {
            request_id: LocalStorage.uid(),
            expenses: filterData.expenses.length > 0 ? finalData : [
                {
                    id: viewData.id,
                    status: status,
                    approved_amount: state.approved_amount,
                    reason_for_rejection: state.reason_for_rejection,
                }
            ]
        }
        PayrollApi.updateStatus(data).then((res) => {
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
                handleCloseDrawer();
                if (args != 'reject') {
                    setOpenSuccess(true);
                }
                setOpenRejection(false);
                setOpenApproval(false);
                getAllPlacements(filterData)
            } else {
                addErrorMsg(res.data.message);
                getAllPlacements(filterData)
            }
        })
    }

    const handleCloseDrawer = () => {
        setOpenView(false);
        setOpenRejection(false);
        setState({ ...state, approved_amount: '', reason_for_rejection: '' })
    }

    const handleButtons = (args) => {
        if (args == 'ApproveAll') {
            setOpenApproval(true);
        }
        else if (args == 'Approved') {
            if (state.approved_amount == '') {
                statusErrors['approved_amount'] = 'This field is required';
                setStatusErrors({ ...statusErrors });
                addErrorMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.')
            } else {
                setStatus(args)
                setOpenApproval(true);
            }
        }
        else {
            setStatus('Rejected')
            setOpenRejection(true);
            setState({ ...state, reason_for_rejection: '' })
        }
    }

    const approveAPI = () => {
        let expensesData = filterData.expenses
        let finalData = expensesData.map(item => ({
            ...item,
            status: "Approved",
            approved_amount: item.raised_amount
        }));
        const data = {
            request_id: LocalStorage.uid(),
            expenses: finalData
        }
        PayrollApi.updateStatus(data).then((res) => {
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
                filterData.status = ['Pending']
                setOpenSuccess(true);
                setOpenRejection(false);
                setOpenApproval(false);
                getAllPlacements(filterData)
            } else {
                filterData.status = ['Pending']
                getAllPlacements(filterData)
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleRejectButtons = (args) => {
        if (args == 'Approved') {
            setStatus('Rejected');
            if (state.reason_for_rejection == '') {
                statusErrors['reason_for_rejection'] = 'This field is required';
                setStatusErrors({ ...statusErrors });
                addErrorMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.')
            } else {
                setStatus('Rejected');
                approveorRejectApi('reject')
            }
        } else {
            setStatus('Rejected');
            setOpenRejection(false);
        }
    }

    const handleApproveButtons = (args) => {
        setStatus('Approved');
        if (args == 'Approved') {
            console.log(filterData.expenses, 'filterData.expenses')
            if (filterData.expenses.length > 0) {
                approveAPI()
            }
            else {
                setStatus('Approved');
                approveorRejectApi(args);
            }
        } else {
            if (filterData.expenses.length == 0) {
                setStatus('Rejected');
            }
            setOpenApproval(false);
        }
    }

    const openInNewTab = (args, documentUrl) => {
        if ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) {
            if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
                const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
            } else if (args.aws_s3_status == undefined) {
                const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
            }
            else {
                openDocumentInNewtab('expense-management', args.document_path)
            }
        }
    }

    return (
        <>
            <Grid container pl={'130px'} pr={'65px'}>
                {
                    pendingApproval ?
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Text mediumGrey sx={{ cursor: 'pointer' }} onClick={() => { pendingApprovalStatus('home') }}>Expense Management</Text>
                                <Text mediumBlack>Pending Reimbursements</Text>
                            </Breadcrumbs>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Text mediumViewAmt>Expense Management</Text>
                        </Grid>
                }

                {
                    !pendingApproval ?
                        <Grid item xs={12} container pt={2}>
                            <Grid item container xl={8} md={6} xs={8}>
                                <Grid item xl={3.7} lg={5} md={7}>
                                    <Box className={classes.tabBg}>
                                        <Box className={activeState == 'reimbursement' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('reimbursement')}>
                                            {
                                                activeState == 'reimbursement' ?
                                                    <Text largeWhite400>Reimbursements</Text> :
                                                    <Text blackHeader>Reimbursements</Text>
                                            }
                                        </Box>
                                        <Box className={activeState == 'deductions' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('deductions')}>
                                            {
                                                activeState == 'deductions' ?
                                                    <Text largeWhite400>Deductions</Text> :
                                                    <Text blackHeader>Deductions</Text>
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item container xl={4} md={6} xs={4} justifyContent={'flex-end'} pr={1} spacing={2}>
                                {
                                    activeState == 'reimbursement' && LocalStorage.getUserData().admin_login && approver ?
                                        <Grid item xs={4} md={6} lg={6}>
                                            <Button sx={{ width: "100% !important", height: '45px !important' }} outlineBlue onClick={() => { pendingApprovalStatus('status') }}>Pending for Approval</Button>
                                        </Grid>
                                        : ''
                                }
                                <Grid item xs={6}>
                                    {
                                        (((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true)))) ?

                                            activeState != "reimbursement" && !LocalStorage.getUserData().admin_login ? ''
                                                :
                                                <Button sx={{ width: "100%" }} addButton onClick={() => { setOpenPopup(true) }}>
                                                    <img src={Userplus} alt='plus' className={classes.addIcon} />
                                                    &nbsp;Add {activeState == "reimbursement" ? 'Reimbursement' : activeState == "deductions" ? 'Deduction' : ""}
                                                </Button>


                                            :
                                            LocalStorage.getUserData().admin_login ?
                                                <>
                                                    {
                                                        activeState == "reimbursement" ?
                                                            < Button sx={{ width: "100%" }} addButton onClick={() => { setOpenPopup(true) }}>
                                                                <img src={Userplus} alt='plus' className={classes.addIcon} />
                                                                &nbsp;Add Reimbursement
                                                            </Button> : ''
                                                    }
                                                </> : ''
                                    }
                                </Grid>
                            </Grid>
                        </Grid> : ''
                }
                {
                    !pendingApproval ?
                        <Grid item container xs={12} pt={3} gap={2}>
                            <>
                                {
                                    (activeState == 'reimbursement' ? cardsJson : DeductionCardsJson).map((item) => (
                                        <Grid item container md={activeState == 'reimbursement' ? 2.8 : 3.8} xs={activeState == 'reimbursement' ? 2.88 : 3.89} sx={{ backgroundColor: item.color, borderRadius: '12px ' }} alignContent={'center'} >
                                            <Grid item xs={12}>
                                                <Box p={2} minHeight={'110px'}>
                                                    <Grid item xs={12}>
                                                        <Text blackHeader1 sx={{ fontSize: '13.5px !important' }}>{item.name}</Text>
                                                    </Grid>
                                                    <Box p={2} height={'2vh'}></Box>
                                                    <Grid item container xs={12}>
                                                        <Grid item xs={4}>
                                                            <Stack direction={'row'}>
                                                                <img src={item.imgPath} alt={item.name} />
                                                                {
                                                                    (activeState == 'reimbursement' && item.mainKey != "total_reimbursed_amount") ?
                                                                        <Text largeBlack22>&nbsp; {cardDetails[item.mainKey]?.count ? cardDetails[item.mainKey]?.count : '0'}</Text> : ''
                                                                }
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item xs={8} container justifyContent={'flex-end'}>
                                                            {
                                                                activeState == 'reimbursement' ?
                                                                    <Text largeBlack22>$&nbsp; {cardDetails[item.mainKey]?.amount ? cardDetails[item.mainKey]?.amount : '0'}</Text> :
                                                                    <Text largeBlack22>$&nbsp; {item.mainKey == 'total_deductions' ? cardDetails.total_deduction : item.mainKey == 'total_deductions_recovered' ? cardDetails.total_deduction_recovered : item.mainKey == 'total_deduction_due' ? cardDetails.total_deduction_due : ''}</Text>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </>
                        </Grid>
                        : ''}
                <Drawer
                    anchor={"right"}
                    open={openPopUp}
                    className={classes.miviDrawer}
                    onClose={() => { setOpenPopup(false) }}
                    sx={{
                        width: '55% !importat',
                        '& .MuiDrawer-paper': {
                            width: '55%',
                        },
                    }}
                >
                    {
                        activeState == "deductions" ?
                            <Deduction setOpenPopup={setOpenPopup} getAllPlacements={getAllPlacements} cardDetailsApi={cardDetailsApi} filterData={filterData} />
                            :
                            <Reimbursement setOpenPopup={setOpenPopup} getAllPlacements={getAllPlacements} cardDetailsApi={cardDetailsApi} filterData={filterData} />
                    }

                </Drawer>
            </Grid >
            <Box className={classes.flexBox} style={{ paddingLeft: '65px' }}>

                <Box sx={{ width: "92%" }} >
                    <Box style={{ padding: "20px 10px 10px 10px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <Box sx={{
                                    height: "44px", border: "1.5px solid #E2E5E6", width: "368px", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center",
                                    "@media (min-width: 900px) and (max-width: 1400px)": {
                                        width: "258px"
                                    },
                                }}>
                                    <input
                                        type="text"
                                        value={filterData.search}
                                        className={classes.EmployeesSearchInput}
                                        onChange={handleSearch}
                                        placeholder={LocalStorage.getUserData().admin_login ? "Search by Name / ID" : "Search by Name"}
                                    />
                                    <Box sx={{ paddingRight: "15px !important" }}>
                                        {
                                            filterData.search !== "" ?
                                                <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                :
                                                <img src={Search} alt="Search" />
                                        }
                                    </Box>
                                </Box>
                            </div>
                            {
                                ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                                    <div>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingRight: "5px" }}>
                                            <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5} display={"flex"} justifyContent={"center"}>
                                                <Text offBoardBodyFont>
                                                    Count
                                                    <HtmlTooltip
                                                        placement="bottom"
                                                        arrow
                                                        title={
                                                            <React.Fragment>
                                                                <Box>
                                                                    <Typography className={classes.profileTooltipText}>
                                                                        Total {LocalStorage.getUserData().admin_login ? 'Employee' : activeState == 'reimbursement' ? 'Reimbursements' : 'Deductions'} count is <br />based on the filters applied
                                                                    </Typography>
                                                                </Box>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                                    </HtmlTooltip>
                                                    {` - ${pagination ? pagination.total ? pagination.total : 0 : 0} ${LocalStorage.getUserData().admin_login ? 'Employees' : activeState == 'reimbursement' ? 'Reimbursements' : 'Deductions'} `}</Text>
                                            </Grid>
                                            <button title="Export" type="button" className={classes.borderButton}
                                                onClick={() => handleDownloadExport()}
                                            >
                                                <img src={NewDownloadcloud} alt="Userplus" />
                                            </button>
                                            <button title="Filter"
                                                type="button"
                                                className={classes.borderButton}
                                                onClick={() => { handleOpenDrawer() }}>
                                                <Badge badgeContent={filterData.raised_by.length + (filterData.status.length && !pendingApproval) + filterData.expense_type_ids.length + filterData.expense_effect_on.length + ((filterData.raised_amount_from !== "" && filterData.raised_amount_to !== "") && 1) + ((filterData.date_of_expense_from !== "" && filterData.date_of_expense_to !== "") && 1) + ((filterData.approved_amount_from !== "" && filterData.approved_amount_to !== "") && 1)} color="error">
                                                    <FilterListIcon sx={{ color: (filterData.raised_by.length > 0 || (filterData.status.length > 0 && !pendingApproval) || filterData.expense_type_ids.length > 0 || filterData.expense_effect_on.length > 0 || (filterData.raised_amount_from !== "" && filterData.raised_amount_to !== "") || (filterData.date_of_expense_from !== "" && filterData.date_of_expense_to !== "") || (filterData.approved_amount_from !== "" && filterData.approved_amount_to !== "")) ? "#2BEA2B" : "" }} />
                                                </Badge>
                                            </button>
                                        </div>
                                    </div>
                                    : ""
                            }
                        </div>

                    </Box>
                    {
                        ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                            <Box style={{ padding: "10px" }}>

                                <Box sx={{
                                    display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px",
                                    "@media (min-width: 200px) and (max-width: 1400px)": {
                                        font: "12px Quicksand",
                                        fontWeight: "600",
                                        paddingTop: '1px'
                                    },
                                }} >
                                    <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                        {!LocalStorage.getUserData().admin_login ?
                                            <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5} textAlign={'left'} letterSpacing={'0.5px'}><Text mediumBlack14> &nbsp; &nbsp; Name</Text></Grid>
                                            :
                                            <Grid item lg={3.5} md={3.5} sm={3.5} xs={3.5} textAlign={'left'} letterSpacing={'0.5px'}>
                                                <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                                    {
                                                        pendingApproval && placements.length != 0 ?
                                                            <Checkbox
                                                                size="small"
                                                                name={"expenses"}
                                                                onChange={(e) => { handleChangePendingApproval(e, 'all') }}
                                                                // onClick = {handleCheckClick()}
                                                                icon={<CheckBorderIcon />}
                                                                checkedIcon={<CheckedIcon />}
                                                                checked={filterData.expenses.length == placements.length} />
                                                            : ''
                                                    }
                                                    <Text mediumBlack14>Employee Name</Text>
                                                    <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} onClick={handleClick}>
                                                        <FilterListIcon sx={{ height: "19px", color: (filterData.sort_column == "name" || filterData.sort_column == "created_at") ? "#2BEA2B" : "" }} />
                                                    </IconButton>
                                                </Box>
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    id="account-menu"
                                                    open={openFilter}
                                                    onClose={handleCloseFilter}
                                                    onClick={handleCloseFilter}
                                                    PaperProps={{
                                                        elevation: 0,
                                                        sx: {
                                                            overflow: "visible",
                                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                            mt: 0.5,
                                                            ml: -2,
                                                            padding: "5px",
                                                            "& .MuiMenuItem-root:hover": {
                                                                backgroundColor: "#EAECF0 !important",
                                                            }
                                                        },
                                                    }}
                                                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                                >
                                                    <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'created_at' && filterData.sort_order == 'desc' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'desc')}> Newest First</MenuItem>
                                                    <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'created_at' && filterData.sort_order == 'asc' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'asc')}> Oldest First</MenuItem>
                                                    <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'name' && filterData.sort_order == 'asc' ? '#EAECF0' : "" }} onClick={() => handleSort('name', 'asc')}> Name (A-Z)</MenuItem>
                                                    <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'name' && filterData.sort_order == 'desc' ? '#EAECF0' : "" }} onClick={() => handleSort('name', 'desc')}> Name (Z-A)</MenuItem>
                                                </Menu>
                                            </Grid>
                                        }
                                        <Grid item lg={activeState == 'reimbursement' ? 1.5 : 2.3} md={activeState == 'reimbursement' ? 1.5 : 2.3} sm={activeState == 'reimbursement' ? 1.5 : 2.3} xs={activeState == 'reimbursement' ? 1.5 : 2.3} className={classes.headerNames}><Text mediumBlack14>Type</Text></Grid>
                                        <Grid item lg={activeState == 'reimbursement' ? 1.4 : 2.2} md={activeState == 'reimbursement' ? 1.4 : 2.2} sm={activeState == 'reimbursement' ? 1.5 : 2.3} xs={activeState == 'reimbursement' ? 1.5 : 2.3} className={classes.headerNames} ><Text mediumBlack14>{activeState == 'reimbursement' ? 'Add To' : 'Deducted From'}</Text></Grid>
                                        <Grid item lg={activeState == 'reimbursement' ? 1 : 2.4} md={activeState == 'reimbursement' ? 1 : 2.4} sm={1} xs={1} className={classes.headerEndDate}><Text mediumBlack14>Expense Date</Text></Grid>
                                        {activeState == 'reimbursement' &&
                                            <Grid aria-controls="status-menu" item lg={pendingApproval ? 2 : !LocalStorage.getUserData().admin_login ? 2.5 : 1} md={1} sm={1} xs={1} className={classes.headerStatus} onClick={handleStatusOpen} ><Text mediumBlack14>Status</Text>
                                                {
                                                    pendingApproval ? '' :
                                                        openStatus ?
                                                            <KeyboardArrowUpIcon sx={{ color: filterData.status.length > 0 ? "#2BEA2B !important" : "" }} />
                                                            :
                                                            <KeyboardArrowDownIcon sx={{ color: filterData.status.length > 0 ? "#2BEA2B !important" : "" }} />
                                                }
                                            </Grid>
                                        }
                                        <Menu
                                            anchorEl={anchorEl1}
                                            id="status-menu"
                                            open={openStatus}
                                            onClose={() => { setAnchorEl1(null) }}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: "visible",
                                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                    mt: 0.5,
                                                    width: "170px",
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#EAECF0 !important",
                                                    },
                                                }
                                            }}
                                            transformOrigin={{ horizontal: "left", vertical: "top" }}
                                            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                        >
                                            <MenuItem onClick={() => { handleStatusClose() }} className={classes.menuItem}>All</MenuItem> <Divider className={classes.divider} />
                                            <MenuItem onClick={() => { handleStatusClose("Pending") }} className={filterData.status.length > 0 && filterData.status.includes("Pending") ? classes.activeMenuItem : classes.menuItem}> Pending</MenuItem> <Divider className={classes.divider} />
                                            <MenuItem onClick={() => { handleStatusClose("Approved") }} className={filterData.status.length > 0 && filterData.status.includes("Approved") ? classes.activeMenuItem : classes.menuItem}> Approved</MenuItem> <Divider className={classes.divider} />
                                            <MenuItem onClick={() => { handleStatusClose("Rejected") }} className={filterData.status.length > 0 && filterData.status.includes("Rejected") ? classes.activeMenuItem : classes.menuItem}> Rejected</MenuItem>
                                        </Menu>
                                        {activeState == 'reimbursement' &&
                                            <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerEndDate}><Text mediumBlack14>Raised Amount</Text></Grid>
                                        }
                                        {
                                            pendingApproval ? '' :
                                                <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerEndDate}><Text mediumBlack14>{activeState == 'reimbursement' ? 'Approved Amount' : 'Amount'}</Text></Grid>
                                        }
                                    </Grid>
                                </Box>
                                {
                                    loading ?
                                        [1, 2, 3, 4].map((item) => (
                                            <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                    <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <Box>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        </Box>
                                                        <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        )) :
                                        <>
                                            {
                                                placements.length > 0 ?
                                                    <Box sx={{
                                                        height: pendingApproval ? "55vh" : "35vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
                                                        paddingLeft: '5px', paddingRight: '5px',
                                                        "@media (min-height: 850px)": {
                                                            height: pendingApproval ? "65vh" : '73.5vh'
                                                        }
                                                    }} ref={scrollBox} onScroll={handleScroll}>
                                                        {
                                                            placements.map((place, key) => (
                                                                <Accordion
                                                                    onClick={(e) => accordianHandler(e, place)}
                                                                    key={key}
                                                                    className={classes.newCustomAccordion}
                                                                >
                                                                    <AccordionSummary
                                                                        aria-controls="panel1bh-content"
                                                                        id="panel1bh-header"
                                                                        className={classes.AccordionSummary}
                                                                    >
                                                                        <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                                            {!LocalStorage.getUserData().admin_login ?
                                                                                <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5} textAlign='start' display={'flex'} flexDirection={'row'}><Text mediumBlack14 sx={{ textTransform: 'capitalize' }}>
                                                                                    <BlackToolTip arrow title={place.name}>{place.name ? place.name.length > 16 ? place.name.slice(0, 16) + '...' : place.name : "--"}
                                                                                    </BlackToolTip>
                                                                                </Text></Grid>
                                                                                :
                                                                                <Grid item lg={3.5} md={3.5} sm={3.5} xs={3.5} textAlign='start' display={'flex'} flexDirection={'row'}>
                                                                                    {
                                                                                        pendingApproval ?
                                                                                            <Checkbox
                                                                                                size="small"
                                                                                                name={"expenses"}
                                                                                                onChange={(e) => { handleChangePendingApproval(e, 'solo', place) }}
                                                                                                // onClick = {handleCheckClick()}
                                                                                                icon={<CheckBorderIcon />}
                                                                                                checkedIcon={<CheckedIcon />}
                                                                                                checked={filterData.expenses.some(item => item.id === place.id)} />
                                                                                            : ''}
                                                                                    <Box display='flex' alignItems='center' gap={2}>
                                                                                        <CustomCircularProgress
                                                                                            percentage={place.profile_percentage}
                                                                                            color={place.profile_percentage >= 76 ? 'green' : place.profile_percentage <= 75 && place.profile_percentage >= 51 ? 'yellow' : place.profile_percentage <= 50 ? "#FFBF00" : ''}
                                                                                            src={place.profile_picture_url}
                                                                                            name={place.profile_picture_url == undefined || place.profile_picture_url == null || place.profile_picture_url == '' ? place.display_name && capitalizeAndAddSpace(place.display_name[0]) : ''}
                                                                                            imgWidth='50px !important'
                                                                                            imgHeight='50px !important'
                                                                                            avatarWidth='50px !important'
                                                                                            avatarHeight='50px !important'
                                                                                            value={place.profile_percentage}
                                                                                            size={58}
                                                                                            tooltip
                                                                                            tooltipContent={
                                                                                                <Box p={'3px 0px'} width='100%'>
                                                                                                    <Text mediumBoldWhite400>{`Profile Completion - ${place.profile_percentage}%`}</Text>
                                                                                                </Box>
                                                                                            }
                                                                                        />
                                                                                        <Box>
                                                                                            {
                                                                                                place.status == 'In Active' ?
                                                                                                    <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                                        {place.display_name === "" ? "--" : capitalizeAndAddSpace(place.display_name).length > 16 ?
                                                                                                            <HtmlTooltip title={capitalizeAndAddSpace(place.display_name)} placement="right" arrow>
                                                                                                                {capitalizeAndAddSpace(place.display_name).slice(0, 16) + (capitalizeAndAddSpace(place.display_name).length > 16 ? "..." : "")}
                                                                                                            </HtmlTooltip>
                                                                                                            :
                                                                                                            capitalizeAndAddSpace(place.display_name)
                                                                                                        }
                                                                                                        {place.employee_e_verified == 1 || place.employee_e_verified == 4 ?
                                                                                                            <HtmlTooltip title={<Text smallWhite>E-verified</Text>} placement="right" arrow>
                                                                                                                <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                            </HtmlTooltip>
                                                                                                            : place.employee_e_verified == 2 ?
                                                                                                                <HtmlTooltip title={<Text smallWhite>E-Verification is pending</Text>} placement="right" arrow>
                                                                                                                    <img src={Pending} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                </HtmlTooltip> : ''
                                                                                                        }
                                                                                                    </Text>
                                                                                                    :
                                                                                                    <Text mediumBoldBlack noWrap>
                                                                                                        {place.display_name === "" ? "--" :
                                                                                                            capitalizeAndAddSpace(place.display_name).length > 16 ?
                                                                                                                <HtmlTooltip title={capitalizeAndAddSpace(place.display_name)} placement="right" arrow>
                                                                                                                    {capitalizeAndAddSpace(place.display_name).slice(0, 16) + (capitalizeAndAddSpace(place.display_name).length > 16 ? "..." : "")}
                                                                                                                </HtmlTooltip>
                                                                                                                :
                                                                                                                capitalizeAndAddSpace(place.display_name)
                                                                                                        }
                                                                                                        {place.employee_e_verified == 1 || place.employee_e_verified == 4 ?
                                                                                                            <HtmlTooltip title={<Text smallWhite>E-Verified</Text>} placement="right" arrow>
                                                                                                                <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                            </HtmlTooltip>
                                                                                                            : place.employee_e_verified == 2 ?
                                                                                                                <HtmlTooltip title={<Text smallWhite>E-Verification is pending</Text>} placement="right" arrow>
                                                                                                                    <img src={Pending} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                </HtmlTooltip> : ''
                                                                                                        }
                                                                                                    </Text>
                                                                                            }
                                                                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center' p={'4px 0px'}>
                                                                                                <Text greyLabel> {place.employee_reference_id ? place.employee_reference_id : "--"}</Text>
                                                                                                {
                                                                                                    place.category_type ?
                                                                                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                                                                            <Box sx={{ height: '4px', width: '4px', background: '#C7CCD3', borderRadius: '50%' }} />
                                                                                                            <Text greyLabel> {place.category_type}</Text>
                                                                                                        </Box> : ''
                                                                                                }
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Grid>
                                                                            }
                                                                            <Grid item lg={activeState == 'reimbursement' ? 1.5 : 2.4} md={activeState == 'reimbursement' ? 1.5 : 2.4} sm={activeState == 'reimbursement' ? 1.5 : 2.4} xs={activeState == 'reimbursement' ? 1.5 : 2.4} className={classes.headerNames}><Text mediumBlack14>
                                                                                <BlackToolTip arrow title={place.expense_type}>{place.expense_type ? place.expense_type.length > 16 ? place.expense_type.slice(0, 16) + '...' : place.expense_type : "--"}
                                                                                </BlackToolTip>
                                                                            </Text>
                                                                            </Grid>
                                                                            <Grid item lg={activeState == 'reimbursement' ? 1.5 : 2.3} md={activeState == 'reimbursement' ? 1.5 : 2.3} sm={activeState == 'reimbursement' ? 1.5 : 2.3} xs={activeState == 'reimbursement' ? 1.5 : 2.3} className={classes.headerNames} ><Text mediumBlack14>{place.expense_effect_on}</Text></Grid>
                                                                            <Grid item lg={activeState == 'reimbursement' ? 1 : 2.3} md={activeState == 'reimbursement' ? 1 : 2.3} sm={1} xs={1} className={classes.headerEndDate} ><Text mediumBlack14>{place.date_of_expense}</Text></Grid>
                                                                            {activeState == 'reimbursement' &&
                                                                                <Grid item lg={pendingApproval ? 2 : !LocalStorage.getUserData().admin_login ? 2.5 : 1} md={1} sm={2} xs={2} className={classes.headerStatus} pl={1}><Text mediumBlack14>{place.status}</Text></Grid>
                                                                            }
                                                                            <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerNames} pl={3}><Text mediumBlack14>{place.raised_amount ? `$ ${place.raised_amount}` : '-'}</Text></Grid>
                                                                            {pendingApproval ? '' : activeState == 'reimbursement' &&
                                                                                <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerNames} pl={4}><Text mediumBlack14> {place.approved_amount ? `$ ${place.approved_amount}` : '-'}</Text></Grid>
                                                                            }
                                                                        </Grid>
                                                                    </AccordionSummary>
                                                                </Accordion>
                                                            ))
                                                        }
                                                    </Box>
                                                    :
                                                    placements.length == 0 ?
                                                        <Box>
                                                            {NoDataFound(`To fetch the data. Please add ${activeState == 'reimbursement' ? "Reimbursements" : 'Deductions'}`, `No  ${activeState == 'reimbursement' ? "Reimbursements" : 'Deductions'} Yet`)}
                                                        </Box>
                                                        : ''
                                            }
                                            {
                                                filterData.expenses.length > 0 ?
                                                    <Grid item xs={12} justifyContent='end' pt={1}>
                                                        <Box display='flex' flexDirection='row' gap={2} justifyContent='end'>

                                                            <Button redBorder sx={{ width: '100px !important' }} onClick={() => handleButtons('Rejected')}>Reject</Button>

                                                            <Button saveBtn600 onClick={() => handleButtons('ApproveAll')}>Approve</Button>

                                                        </Box>
                                                    </Grid>
                                                    : ''
                                            }

                                        </>
                                }
                            </Box> :
                            <>
                                {NoPermission()}
                            </>
                    }
                </Box>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openBacKLoader}
                // onClick={handleClose}
                >
                    <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                </Backdrop>

                <BootstrapDialog
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth={true}
                    maxWidth={"md"}
                >

                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                            boxShadow: 'none !important',
                            "&:hover": {
                                boxShadow: 'none !important',
                                background: 'none !important'
                            }
                        }}
                    >
                        <img src={crossIcon} alt="cross" />
                        {/* <CloseIcon sx={{ color: "rgba(38, 38, 38, 1)" }} /> */}
                    </IconButton>
                    <DialogContent sx={{ margin: "50px", }}>
                        <Grid container spacing={0}>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box style={{ height: "400px", width: "400px", display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                    <Box sx={{ textAlign: "center" }}>
                                        <Box sx={{ margin: "12px 0px" }}>
                                            <img src={InviteViaLink} alt='invite-via-link' style={{ height: "200px" }} />
                                        </Box>
                                        <Box sx={{ margin: "8px 0px" }}>
                                            {/* <Typography sx={{ fontSize: "12px", fontFamily: "Quicksand", fontWeight: "400", color: "rgba(38, 38, 38, 1)", textAlign: "center", wordWrap: "break-word", }}>
                                            Send the employee an invite link to add them to <br /> the organization.
                                        </Typography> */}
                                            <Text mediumLabel>Send the employee an invite link to add them to <br /> the organization.</Text>
                                        </Box>
                                        <Box sx={{ margin: "4px 0px" }}>
                                            <button
                                                onClick={() => navigate("/employees/add")}
                                                type="button"
                                                className={classes.inviteLinkBtn}
                                            // style={{
                                            //     margin: "12px 0px", all: "unset", cursor: "pointer", textAlign: "center", fontSize: "15px", fontFamily: "Quicksand CY, sans-serif", fontWeight: "400", width: "150px", height: "40px", border: "1.5px solid rgba(12, 117, 235, 1)", borderRadius: "6px", color: "rgba(12, 117, 235, 1)",
                                            //     "&::hover": {
                                            //         backgroundColor:'rgba(12, 117, 235, 1)',color:'#FFFFFF'
                                            // } }}
                                            >
                                                Invite via link
                                            </button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box style={{ height: "400px", width: "400px", display: 'flex', justifyContent: "center", alignItems: "center", borderLeft: "1.5px solid rgba(199, 204, 211, 1)" }}>
                                    <Box sx={{ textAlign: "center" }}>
                                        <Box sx={{ margin: "16px 0px" }}>
                                            <img src={Onboart} alt='onboart' style={{ height: "200px" }} />
                                        </Box>
                                        <Box sx={{ margin: "6px 0px" }}>
                                            {/* <Typography sx={{ fontSize: "12px", fontFamily: "Quicksand CY, sans-serif", fontWeight: "400", color: "rgba(38, 38, 38, 1)", textAlign: "center", wordWrap: "break-word", }}>
                                        Onboard an employee manually, to add them to <br />the organization.
                                        </Typography> */}
                                            <Text mediumLabel> Onboard an employee manually, to add them to <br />the organization.</Text>
                                        </Box>
                                        <Box sx={{ margin: "20px 0px", }}>
                                            <button
                                                type="button"
                                                className={classes.onboardBtn}
                                                onClick={() => { navigate('/employees/onboard'); LocalStorage.removeEmployeeId(); LocalStorage.removeVisaID(); LocalStorage.removeFullName() }}
                                            >
                                                Onboard
                                            </button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </BootstrapDialog>

                <SwipeableDrawer
                    anchor={'right'}
                    open={drawer}
                    onClose={() => handleFilterDrawerClose()}
                    transitionDuration={300}
                    sx={{
                        ".MuiDrawer-paper ": {
                            borderRadius: '8px 0px 0px 8px !important',
                        },
                        "& .MuiBackdrop-root.MuiModal-backdrop": {
                            backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                        }
                    }
                    }
                >
                    {
                        drawer === "filter" ?
                            <Box width={'660px'} height={'100vh'} >
                                <Box height={'10vh'} borderBottom={'1px solid #EAECF0'} display={'flex'} alignItems={'center'} pl={4} >
                                    <Text headerBlack>Filters</Text>
                                </Box>

                                <Box display={'flex'} height={'7vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                                    <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={1} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>
                                        {
                                            filterState.date_of_expense_from !== "" && filterState.date_of_expense_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.date_of_expense_from} - {filterState.date_of_expense_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "date_of_expense")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }

                                        {
                                            filterState.approved_amount_from !== "" && filterState.approved_amount_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.approved_amount_from} - {filterState.approved_amount_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "approved_amount")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            filterState.raised_amount_from !== "" && filterState.raised_amount_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#2DC890 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.raised_amount_from} - {filterState.raised_amount_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "raised_amount")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            deductionTypeDropdown.map((item, key) => (
                                                filterState.expense_type_ids.includes(item.id) &&
                                                <Chip
                                                    sx={{ border: "1px solid #EA5151" }}
                                                    key={key}
                                                    icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                        <BlackToolTip title={item.value} placement="right" arrow>
                                                            {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                        </BlackToolTip>
                                                        :
                                                        item.value}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip(item.id, "expense_type_ids")}
                                                    deleteIcon={<CloseIcon />} />
                                            ))
                                        }
                                        {
                                            reimbursementTypeDropdown.map((item, key) => (
                                                filterState.expense_type_ids.includes(item.id) &&
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={key}
                                                    icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                        <BlackToolTip title={item.value} placement="right" arrow>
                                                            {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                        </BlackToolTip>
                                                        :
                                                        item.value}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip(item.id, "expense_type_ids")}
                                                    deleteIcon={<CloseIcon />} />
                                            ))
                                        }
                                        {
                                            employmentType.map((item, key) => (
                                                filterState.raised_by.includes(item.id) &&
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={key}
                                                    icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack nowrap>{
                                                        item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                            <BlackToolTip title={item.value} placement="right" arrow>
                                                                {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                            </BlackToolTip>
                                                            :
                                                            item.value
                                                    }</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip(item.id, "raised_by")}
                                                    deleteIcon={<CloseIcon />}
                                                />
                                            ))
                                        }
                                        {
                                            deductedFromDropdown.map((item, key) => (
                                                filterState.expense_effect_on.includes(item.id) &&
                                                <Chip key={key}
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{item.value}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip(item.id, "expense_effect_on")}
                                                    deleteIcon={<CloseIcon />}
                                                />
                                            ))
                                        }
                                        {
                                            pendingApproval ? '' :
                                                statusList.map((item, key) => (
                                                    filterState.status.includes(item.id) &&
                                                    <Chip key={key}
                                                        sx={{ border: "1px solid #EA5151" }}
                                                        icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack>{item.value}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip(item.id, "status")}
                                                        deleteIcon={<CloseIcon />}
                                                    />
                                                ))
                                        }
                                    </Box>
                                    {
                                        ((filterState.approved_amount_from !== "" && filterState.approved_amount_to !== "") || (filterState.date_of_expense_from !== "" && filterState.date_of_expense_to !== "") || (filterState.raised_amount_from !== "" && filterState.raised_amount_to !== "") || (filterState.status.length > 0 && !pendingApproval) || filterState.expense_type_ids.length > 0 || filterState.raised_by.length > 0 || filterState.expense_effect_on.length > 0) ?
                                            <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                            :
                                            ''
                                    }
                                </Box>

                                <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'73vh'} >
                                    <Box display={'flex'} flexDirection={'column'} height={'100%'} width={'43%'} borderRight={'1px solid #EAECF0'} pt={0} >
                                        {
                                            activeState != "reimbursement" ?
                                                <List component="nav" aria-label="secondary mailbox folder" sx={{ overflowY: 'scroll' }}>

                                                    <ListItem className={selectedFilter == 1 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(1)} secondaryAction={filterState.expense_type_ids.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterState.expense_type_ids.length}</Text></Box> : ""}>
                                                        <ListItemText primary={selectedFilter == 1 ? <Text ml={3} smallBlue1>Deduction Type</Text> : <Text ml={3} mediumBlack>Deduction Type</Text>} />
                                                    </ListItem>
                                                    <Divider className={classes.divider} />
                                                    <ListItem className={selectedFilter == 2 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(2)} secondaryAction={filterState.expense_effect_on.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{filterState.expense_effect_on.length}</Text></Box> : ""}>
                                                        <ListItemText primary={selectedFilter == 2 ? <Text ml={3} smallBlue1>Deduction From</Text> : <Text ml={3} mediumBlack>Deduction From</Text>} />
                                                    </ListItem>
                                                    <Divider />
                                                    <ListItem className={selectedFilter == 3 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(3)} secondaryAction={(filterState.date_of_expense_from !== "" && filterState.date_of_expense_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{(filterState.date_of_expense_from !== "" && filterState.date_of_expense_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                        <ListItemText primary={selectedFilter == 3 ? <Text ml={3} smallBlue1>Expense Date</Text> : <Text ml={3} mediumBlack>Expense Date</Text>} />
                                                    </ListItem>
                                                    <Divider />
                                                    <ListItem className={selectedFilter == 5 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(5)} secondaryAction={(filterState.raised_amount_from !== "" && filterState.raised_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#2DC890" }}><Text mediumWhite400>{(filterState.raised_amount_from !== "" && filterState.raised_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                        <ListItemText primary={selectedFilter == 5 ? <Text ml={3} smallBlue1>Amount</Text> : <Text ml={3} mediumBlack>Amount</Text>} />
                                                    </ListItem>
                                                    <Divider />
                                                </List>
                                                :
                                                <List component="nav" aria-label="secondary mailbox folder" sx={{ overflowY: 'scroll' }}>
                                                    <ListItem className={selectedFilter == 6 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(6)} secondaryAction={filterState.expense_type_ids.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterState.expense_type_ids.length}</Text></Box> : ""}>
                                                        <ListItemText primary={selectedFilter == 6 ? <Text ml={3} smallBlue1>Expense Type</Text> : <Text ml={3} mediumBlack>Expense Type</Text>} />
                                                    </ListItem>
                                                    <Divider />
                                                    {
                                                        LocalStorage.getUserData().admin_login ?
                                                            <ListItem className={selectedFilter == 7 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(7)} secondaryAction={filterState.raised_by.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{filterState.raised_by.length}</Text></Box> : ""}>
                                                                <ListItemText primary={selectedFilter == 7 ? <Text ml={3} smallBlue1>Expense Raised by</Text> : <Text ml={3} mediumBlack>Expense Raised by</Text>} />
                                                            </ListItem> : ''
                                                    }
                                                    {
                                                        LocalStorage.getUserData().admin_login ?
                                                            <Divider />
                                                            : ''}
                                                    <ListItem className={selectedFilter == 8 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(8)} secondaryAction={filterState.expense_effect_on.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{filterState.expense_effect_on.length}</Text></Box> : ""}>
                                                        <ListItemText primary={selectedFilter == 8 ? <Text ml={3} smallBlue1>Added To</Text> : <Text ml={3} mediumBlack>Added To</Text>} />
                                                    </ListItem>
                                                    <Divider />
                                                    <ListItem className={selectedFilter == 9 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(9)} secondaryAction={(filterState.date_of_expense_from !== "" && filterState.date_of_expense_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{(filterState.date_of_expense_from !== "" && filterState.date_of_expense_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                        <ListItemText primary={selectedFilter == 9 ? <Text ml={3} smallBlue1>Expense Date</Text> : <Text ml={3} mediumBlack>Expense Date</Text>} />
                                                    </ListItem>
                                                    <Divider />
                                                    <ListItem className={selectedFilter == 4 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(4)} secondaryAction={(filterState.raised_amount_from !== "" && filterState.raised_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#2DC890" }}><Text mediumWhite400>{(filterState.raised_amount_from !== "" && filterState.raised_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                        <ListItemText primary={selectedFilter == 4 ? <Text ml={3} smallBlue1>Raised Amount</Text> : <Text ml={3} mediumBlack>Raised Amount</Text>} />
                                                    </ListItem>
                                                    <Divider />
                                                    {pendingApproval ? '' :
                                                        <ListItem className={selectedFilter == 10 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(10)} secondaryAction={filterState.status.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterState.status.length}</Text></Box> : ""}>
                                                            <ListItemText primary={selectedFilter == 10 ? <Text ml={3} smallBlue1>Status</Text> : <Text ml={3} mediumBlack>Status</Text>} />
                                                        </ListItem>
                                                    }
                                                    <Divider />
                                                    <ListItem className={selectedFilter == 11 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(11)} secondaryAction={(filterState.approved_amount_from !== "" && filterState.approved_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{(filterState.approved_amount_from !== "" && filterState.approved_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                        <ListItemText primary={selectedFilter == 11 ? <Text ml={3} smallBlue1>Approved Amount</Text> : <Text ml={3} mediumBlack>Approved Amount</Text>} />
                                                    </ListItem>
                                                    <Divider />
                                                </List>
                                        }


                                    </Box>
                                    <Box display={'flex'} sx={{ overflow: "auto" }} flexDirection={'column'} height={'70vh'} width={'55%'} pl={'25px !important'} pt={2}>

                                        {
                                            selectedFilter == 1 ?
                                                <>
                                                    {
                                                        deductionTypeDropdown.length > 0 && deductionTypeDropdown.map((item, index) => (
                                                            <Grid container alignItems={"center"} pb={2}>
                                                                <FormControlLabel
                                                                    key={index}
                                                                    control={
                                                                        <Checkbox
                                                                            size="small"
                                                                            name={"expense_type_ids"}
                                                                            value={item.id}
                                                                            onChange={(e) => { handleChangeCheckBox(e) }}
                                                                            // onClick = {handleCheckClick()}
                                                                            icon={<CheckBorderIcon />}
                                                                            checkedIcon={<CheckedIcon />}
                                                                            checked={filterState.expense_type_ids.includes(item.id)} />}
                                                                    label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                        <BlackToolTip title={item.value} placement="right" arrow>
                                                                            {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        :
                                                                        item.value}</Text>}
                                                                />
                                                            </Grid>
                                                        ))
                                                    }
                                                </>
                                                :
                                                selectedFilter == 2 ?
                                                    <>
                                                        {
                                                            deductedFromDropdown.length > 0 && deductedFromDropdown.map((item, index) => (
                                                                <Grid container alignItems={"center"} pb={2}>
                                                                    <FormControlLabel
                                                                        key={index}
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                name={"expense_effect_on"}
                                                                                value={item.id}
                                                                                onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                // onClick = {handleCheckClick()}
                                                                                icon={<CheckBorderIcon />}
                                                                                checkedIcon={<CheckedIcon />}
                                                                                checked={filterState.expense_effect_on.includes(item.id)} />}
                                                                        label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                            <BlackToolTip title={item.value} placement="right" arrow>
                                                                                {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                            </BlackToolTip>
                                                                            :
                                                                            item.value}</Text>}
                                                                    />
                                                                </Grid>
                                                            ))
                                                        }
                                                    </>

                                                    :
                                                    selectedFilter == 3 ?
                                                        <Grid container alignItems={"center"}>
                                                            <Grid item xs={12} pb={2}>
                                                                <Text mediumBlack14 >
                                                                    Select Date Range
                                                                </Text>
                                                            </Grid>

                                                            <Grid item lg={5.5} md={5} sm={5}>
                                                                <Date
                                                                    labelText={`From`}
                                                                    name='date_of_expense_from'
                                                                    value={filterState.date_of_expense_from}
                                                                    onChange={(value) => dateChange(value, 'date_of_expense_from')}
                                                                    height='56px'
                                                                />
                                                            </Grid>
                                                            <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                                &#8594;
                                                            </Grid>
                                                            <Grid item lg={5.5} md={5} sm={5}>
                                                                <Date
                                                                    labelText={`To`}
                                                                    name='date_of_expense_to'
                                                                    value={filterState.date_of_expense_to}
                                                                    onChange={(value) => dateChange(value, 'date_of_expense_to')}
                                                                    height='56px'
                                                                    minDate={moment(filterState.date_of_expense_from).format(dateFormat())}
                                                                    disabled={filterState.date_of_expense_from == ""}
                                                                    error={datesError.date_of_expense_to}
                                                                />
                                                                <Box sx={{ height: "0px" }}>{datesError.date_of_expense_to ? <Text red>{datesError.date_of_expense_to ? datesError.date_of_expense_to : ''}</Text> : ''}</Box>
                                                            </Grid>
                                                        </Grid> :
                                                        selectedFilter == 4 ?
                                                            <>
                                                                <Grid container alignItems={"center"} pb={2}>
                                                                    <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                        <Box sx={{ width: 300, margin: "auto" }}>
                                                                            <Text mediumBlack14 pb={1}>
                                                                                Select Amount Range
                                                                            </Text>
                                                                            <Slider
                                                                                value={[filterState.raised_amount_from, filterState.raised_amount_to]}
                                                                                onChange={handleSliderChangeRaised}
                                                                                valueLabelDisplay="auto"
                                                                                min={filterAmount.raised_amount.min}
                                                                                max={filterAmount.raised_amount.max}
                                                                                sx={{ mb: 2 }}
                                                                            />
                                                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                <Input
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    inputProps={{
                                                                                        name: 'number',
                                                                                        value: filterState.raised_amount_from,
                                                                                        inputProps: { maxLength: 50 },
                                                                                    }}
                                                                                    onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                    handleChange={(e) => handleInputChange(0, e.target.value, 'raised_amount_from', 'min')}
                                                                                    clientInput
                                                                                    labelText={'$ Min'}
                                                                                    size="small"
                                                                                    sx={{ width: "35%" }}
                                                                                />
                                                                                <Input
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    inputProps={{
                                                                                        name: 'number',
                                                                                        value: filterState.raised_amount_to,
                                                                                    }}
                                                                                    onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                    handleChange={(e) => handleInputChange(1, e.target.value, 'raised_amount_to', 'max')}
                                                                                    clientInput
                                                                                    labelText={'$ Max'}
                                                                                    size="small"
                                                                                    sx={{ width: "35%" }}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </> :
                                                            selectedFilter == 5 ?
                                                                <>
                                                                    {
                                                                        <Grid container alignItems={"center"} pb={2}>
                                                                            <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                                <Box sx={{ width: 300, margin: "auto" }}>
                                                                                    <Text mediumBlack14 pb={1}>
                                                                                        Select Amount Range
                                                                                    </Text>
                                                                                    <Slider
                                                                                        value={[filterState.raised_amount_from, filterState.raised_amount_to]}
                                                                                        onChange={handleSliderChangeRaised}
                                                                                        valueLabelDisplay="auto"
                                                                                        min={filterAmount.raised_amount.min}
                                                                                        max={filterAmount.raised_amount.max}
                                                                                        sx={{ mb: 2 }}
                                                                                    />
                                                                                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                        <Input
                                                                                            formControlProps={{
                                                                                                fullWidth: true
                                                                                            }}
                                                                                            inputProps={{
                                                                                                name: 'raised_amount_from',
                                                                                                value: filterState.raised_amount_from,
                                                                                                inputProps: { maxLength: 50 },
                                                                                            }}
                                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                            handleChange={(e) => handleInputChange(0, e.target.value, 'raised_amount_from', 'min')}
                                                                                            clientInput
                                                                                            labelText={'$ Min'}
                                                                                            size="small"
                                                                                            sx={{ width: "35%" }}
                                                                                        />
                                                                                        <Input
                                                                                            formControlProps={{
                                                                                                fullWidth: true
                                                                                            }}
                                                                                            inputProps={{
                                                                                                name: 'raised_amount_to',
                                                                                                value: filterState.raised_amount_to,
                                                                                            }}
                                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                            handleChange={(e) => handleInputChange(1, e.target.value, 'raised_amount_to', 'max')}
                                                                                            clientInput
                                                                                            labelText={'$ Max'}
                                                                                            size="small"
                                                                                            sx={{ width: "35%" }}
                                                                                        />
                                                                                    </Box>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                                :
                                                                selectedFilter == 6 ?
                                                                    <>
                                                                        {
                                                                            reimbursementTypeDropdown.length > 0 && reimbursementTypeDropdown.map((item, index) => (
                                                                                <Grid container alignItems={"center"} pb={2}>
                                                                                    <FormControlLabel
                                                                                        key={index}
                                                                                        control={
                                                                                            <Checkbox
                                                                                                size="small"
                                                                                                name={"expense_type_ids"}
                                                                                                value={item.id}
                                                                                                onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                                // onClick = {handleCheckClick()}
                                                                                                icon={<CheckBorderIcon />}
                                                                                                checkedIcon={<CheckedIcon />}
                                                                                                checked={filterState.expense_type_ids.includes(item.id)} />}
                                                                                        label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                                            <BlackToolTip title={item.value} placement="right" arrow>
                                                                                                {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                                            </BlackToolTip>
                                                                                            :
                                                                                            item.value}</Text>}
                                                                                    />
                                                                                    {console.log(item.id, 'filterState')}
                                                                                    {console.log(filterState, 'filterState')}
                                                                                </Grid>
                                                                            ))
                                                                        }
                                                                    </>
                                                                    :
                                                                    selectedFilter == 7 ?
                                                                        <>
                                                                            {
                                                                                employmentType.length > 0 && employmentType.map((item, index) => (
                                                                                    <Grid container alignItems={"center"} pb={2}>
                                                                                        <FormControlLabel
                                                                                            key={index}
                                                                                            control={
                                                                                                <Checkbox
                                                                                                    size="small"
                                                                                                    name={"raised_by"}
                                                                                                    value={item.id}
                                                                                                    onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                                    // onClick = {handleCheckClick()}
                                                                                                    icon={<CheckBorderIcon />}
                                                                                                    checkedIcon={<CheckedIcon />}
                                                                                                    checked={filterState.raised_by.includes(item.id)} />}
                                                                                            label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                                                <BlackToolTip title={item.value} placement="right" arrow>
                                                                                                    {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                                                </BlackToolTip>
                                                                                                :
                                                                                                item.value}</Text>}
                                                                                        />
                                                                                    </Grid>
                                                                                ))
                                                                            }
                                                                        </>
                                                                        :
                                                                        selectedFilter == 8 ?
                                                                            <>
                                                                                {
                                                                                    deductedFromDropdown.length > 0 && deductedFromDropdown.map((item, index) => (
                                                                                        <Grid container alignItems={"center"} pb={2}>
                                                                                            <FormControlLabel
                                                                                                key={index}
                                                                                                control={
                                                                                                    <Checkbox
                                                                                                        size="small"
                                                                                                        name={"expense_effect_on"}
                                                                                                        value={item.id}
                                                                                                        onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                                        // onClick = {handleCheckClick()}
                                                                                                        icon={<CheckBorderIcon />}
                                                                                                        checkedIcon={<CheckedIcon />}
                                                                                                        checked={filterState.expense_effect_on.includes(item.id)} />}
                                                                                                label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                                                    <BlackToolTip title={item.value} placement="right" arrow>
                                                                                                        {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                                                    </BlackToolTip>
                                                                                                    :
                                                                                                    item.value}</Text>}
                                                                                            />
                                                                                        </Grid>
                                                                                    ))
                                                                                }
                                                                            </>
                                                                            :
                                                                            selectedFilter == 9 ?
                                                                                <Grid container alignItems={"center"}>
                                                                                    <Grid item xs={12} pb={2}>
                                                                                        <Text mediumBlack14 >
                                                                                            Select Date Range
                                                                                        </Text>
                                                                                    </Grid>
                                                                                    <Grid item lg={5.5} md={5} sm={5}>
                                                                                        <Date
                                                                                            labelText={`From`}
                                                                                            name='date_of_expense_from'
                                                                                            value={filterState.date_of_expense_from}
                                                                                            onChange={(value) => dateChange(value, 'date_of_expense_from')}
                                                                                            height='56px'
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                                                        &#8594;
                                                                                    </Grid>
                                                                                    <Grid item lg={5.5} md={5} sm={5}>
                                                                                        <Date
                                                                                            labelText={`To`}
                                                                                            name='date_of_expense_to'
                                                                                            value={filterState.date_of_expense_to}
                                                                                            onChange={(value) => dateChange(value, 'date_of_expense_to')}
                                                                                            height='56px'
                                                                                            minDate={moment(filterState.date_of_expense_from).format(dateFormat())}
                                                                                            disabled={filterState.date_of_expense_from == ""}
                                                                                            error={datesError.date_of_expense_to}
                                                                                        />
                                                                                        <Box sx={{ height: "0px" }}>{datesError.date_of_expense_to ? <Text red>{datesError.date_of_expense_to ? datesError.date_of_expense_to : ''}</Text> : ''}</Box>
                                                                                    </Grid>
                                                                                </Grid> :
                                                                                selectedFilter == 10 ?
                                                                                    <>
                                                                                        {
                                                                                            statusList.length > 0 && statusList.map((item, index) => (
                                                                                                <Grid container alignItems={"center"} pb={2}>
                                                                                                    <FormControlLabel
                                                                                                        key={index}
                                                                                                        control={
                                                                                                            <Checkbox
                                                                                                                size="small"
                                                                                                                name={"status"}
                                                                                                                value={item.id}
                                                                                                                onChange={(e) => handleChangeCheckBox(e)}
                                                                                                                icon={<CheckBorderIcon />}
                                                                                                                checkedIcon={<CheckedIcon />}
                                                                                                                checked={filterState.status.includes(item.id)} />}
                                                                                                        label={<Text mediumBlack >{item.value}</Text>}
                                                                                                    />
                                                                                                </Grid>
                                                                                            ))
                                                                                        }
                                                                                    </> :
                                                                                    selectedFilter == 11 ?
                                                                                        <>
                                                                                            {
                                                                                                <Grid container alignItems={"center"} pb={2}>
                                                                                                    <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                                                        <Box sx={{ width: 300, margin: "auto" }}>
                                                                                                            <Text mediumBlack14 pb={1}>
                                                                                                                Select Amount Range
                                                                                                            </Text>
                                                                                                            <Slider
                                                                                                                value={[filterState.approved_amount_from, filterState.approved_amount_to]}
                                                                                                                onChange={handleSliderChange}
                                                                                                                valueLabelDisplay="auto"
                                                                                                                min={filterAmount.approved_amount.min}
                                                                                                                max={filterAmount.approved_amount.max}
                                                                                                                sx={{ mb: 2 }}
                                                                                                            />
                                                                                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                                                <Input
                                                                                                                    formControlProps={{
                                                                                                                        fullWidth: true
                                                                                                                    }}
                                                                                                                    inputProps={{
                                                                                                                        name: 'approved_amount_from',
                                                                                                                        value: filterState.approved_amount_from,
                                                                                                                        inputProps: { maxLength: 50 },
                                                                                                                    }}
                                                                                                                    onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                                                    handleChange={(e) => handleInputChange(0, e.target.value, 'approved_amount_from', 'min')}
                                                                                                                    clientInput
                                                                                                                    labelText={'$ Min'}
                                                                                                                    size="small"
                                                                                                                    sx={{ width: "35%" }}
                                                                                                                />
                                                                                                                <Input
                                                                                                                    formControlProps={{
                                                                                                                        fullWidth: true
                                                                                                                    }}
                                                                                                                    inputProps={{
                                                                                                                        name: 'approved_amount_to',
                                                                                                                        value: filterState.approved_amount_to,
                                                                                                                    }}
                                                                                                                    onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                                                    handleChange={(e) => handleInputChange(1, e.target.value, 'approved_amount_to', 'max')}
                                                                                                                    clientInput
                                                                                                                    labelText={'$ Max'}
                                                                                                                    size="small"
                                                                                                                    sx={{ width: "35%" }}
                                                                                                                />
                                                                                                            </Box>
                                                                                                        </Box>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <Box sx={{ height: "60vh", width: "100%", display: "flex", alignItems: "center" }}>
                                                                                            <Grid container>
                                                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                                                    <img src={nofilterplacement} alt="nofilterplacement" style={{ paddingTop: "15px" }} />
                                                                                                </Grid>
                                                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"} >
                                                                                                    <Text veryLargeLabel>Gear Up!</Text>
                                                                                                </Grid>
                                                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                                                    <Text offBoardBodyFont>Select filters to scale our data peaks</Text>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Box>
                                            // nofilterplacement
                                        }
                                    </Box>
                                </Box>

                                <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={1} height={'73px'} pr={'26px'}>
                                    <Button cancelSmall onClick={() => cancelFilter()}>Cancel</Button>
                                    <Button saveSmall onClick={() => handleApplyFilters()} >Apply Filters</Button>
                                </Box>
                            </Box >
                            : null
                    }

                </SwipeableDrawer>


                <SwipeableDrawer
                    anchor={'right'}
                    open={openView}
                    onClose={() => { setOpenView(null); setState({ ...state, approved_amount: '' }) }}
                    transitionDuration={300}
                    sx={{
                        width: '50%',
                        ".MuiDrawer-paper ": {
                            width: '50%',
                            borderRadius: '8px 0px 0px 8px !important',
                        },
                        "& .MuiBackdrop-root.MuiModal-backdrop": {
                            backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                        }
                    }
                    }
                >
                    <Grid container p={'10px 15px 10px 20px'} mt={3} xs={12}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Text dashboardblack14>{activeState == 'reimbursement' ? 'Reimbursement' : 'Deduction'} Details</Text>
                            </Grid>
                            <Box sx={{ background: '#D1E1FF', borderRadius: '8px', padding: "15px", marginTop: '15px' }}>
                                <Grid item container xs={12} spacing={2} alignItems='center'>
                                    <Grid item container xs={6}>
                                        <Box display='flex' flexDirection='row' gap={2}>
                                            <Avatar src={viewData.profile_picture_url ? viewData.profile_picture_url : ''} alt='Profile' />
                                            <Box display='flex' flexDirection='column' gap={1}>
                                                <Box display='flex' flexDirection='row' gap={1}>
                                                    <Text dashboardblack14>{viewData.display_name ? viewData.display_name : '-'}</Text>
                                                    {
                                                        viewData.e_verified == 1 ?
                                                            <BlackToolTip arrow placement='right' title={<Text smallWhite>E-Verified</Text>}>
                                                                <img src={verified} alt='verified' />
                                                            </BlackToolTip> : ''
                                                    }
                                                </Box>
                                                <Text greysmallLabel>{viewData.employee_reference_id ? viewData.employee_reference_id : '-'}</Text>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {
                                        activeState == 'reimbursement' ?
                                            <Grid item container xs={6} lg={6} justifyContent='end' alignItems='center'>
                                                <Grid item xs={3} lg={3} justifyContent='flex-end' textAlign='center' alignItems='center' className={viewData.status == 'Pending' ? classes.pendingStatus : viewData.status == 'Approved' ? classes.successStatus : viewData.status == 'Rejected' ? classes.rejectedStatus : ''}>
                                                    {viewData.status}
                                                </Grid>
                                                <Grid item xs={12} lg={12} justifyContent='flex-end' pt={1.5}>
                                                    <Box display='flex' flexDirection='row' gap={1} justifyContent='end'>
                                                        <Text greyLabel14>Raised by: </Text>
                                                        <Text mediumBlack14>
                                                            {
                                                                viewData.raised_by && viewData.raised_by.length > 12 ?
                                                                    <BlackToolTip title={viewData.raised_by} placement="bottom" arrow>
                                                                        {viewData.raised_by.slice(0, 12) + (viewData.raised_by.length > 12 ? "..." : "")}
                                                                    </BlackToolTip>
                                                                    : viewData.raised_by
                                                            } on {viewData.raised_on ? viewData.raised_on : '-'}</Text>
                                                    </Box>
                                                </Grid>
                                            </Grid> : ''
                                    }
                                </Grid>
                            </Box>
                            <Grid item container xs={12} spacing={2} mt={3} pl={2}>
                                <Grid item xs={6}>
                                    <Box display='flex' flexDirection='column' gap={2}>
                                        <Text mediumGreyHeader>Name</Text>
                                        <Text mediumBlack14>{viewData.name ?
                                            <>
                                                {
                                                    viewData.name && viewData.name.length > 16 ?
                                                        <BlackToolTip title={viewData.name} placement="right" arrow>
                                                            {viewData.name.slice(0, 16) + (viewData.name.length > 16 ? "..." : "")}
                                                        </BlackToolTip>
                                                        : viewData.name
                                                }
                                            </>
                                            : '-'}</Text>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display='flex' flexDirection='column' gap={2}>
                                        <Text mediumGreyHeader>Type</Text>
                                        <Text mediumBlack14>{viewData.expense_type ? viewData.expense_type : '-'}</Text>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display='flex' flexDirection='column' gap={2}>
                                        <Text mediumGreyHeader>Date of Expense</Text>
                                        <Text mediumBlack14>{viewData.date_of_expense ? viewData.date_of_expense : '-'}</Text>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display='flex' flexDirection='column' gap={2}>
                                        <Text mediumGreyHeader>{activeState == 'reimbursement' ? 'Add To' : 'Deduct From'}</Text>
                                        <Text mediumBlack14>{viewData.expense_effect_on ? viewData.expense_effect_on : '-'}</Text>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display='flex' flexDirection='column' gap={2}>
                                        <Text mediumGreyHeader>{activeState == 'reimbursement' ? 'Reimbursement Amount' : 'Deduction Amount'}</Text>
                                        <Text mediumBlack14> {viewData.raised_amount ? `$ ${viewData.raised_amount}` : '-'}</Text>
                                    </Box>
                                </Grid>
                                {
                                    activeState == 'reimbursement' && viewData.approved_amount !== '' ?
                                        <Grid item xs={6}>
                                            <Box display='flex' flexDirection='column' gap={2}>
                                                <Text mediumGreyHeader>Approved amount</Text>
                                                <Text mediumBlack14>{viewData.approved_amount ? viewData.approved_amount : '-'}</Text>
                                            </Box>
                                        </Grid> : ''
                                }
                                <Grid item xs={6}>
                                    <Box display='flex' flexDirection='column' gap={2}>
                                        <Text mediumGreyHeader>Supporting Document</Text>
                                        <Text analyticsViewAll sx={{ cursor: 'pointer !important' }} onClick={() => { openInNewTab(viewData.documents[0], viewData.documents[0].document_url) }}>{viewData.documents && viewData.documents.length > 0 ?
                                            <>
                                                {
                                                    viewData.documents[0].document_name && viewData.documents[0].document_name.length > 16 ?
                                                        <BlackToolTip title={viewData.documents[0].document_name} placement="right" arrow>
                                                            {viewData.documents[0].document_name.slice(0, 16) + (viewData.documents[0].document_name.length > 16 ? "..." : "")}
                                                        </BlackToolTip>
                                                        : viewData.documents[0].document_name
                                                }
                                            </>
                                            : '-'}</Text>
                                    </Box>
                                </Grid>
                                {
                                    activeState == 'reimbursement' ?
                                        <>
                                            {
                                                viewData.expense_effect_on == 'Payroll' ?
                                                    < Grid item xs={6}>
                                                        <Box display='flex' flexDirection='column' gap={2}>
                                                            <Text mediumGreyHeader>Payment Mode</Text>
                                                            <Text mediumBlack14>{viewData.payment_mode ? viewData.payment_mode : '-'}</Text>
                                                        </Box>
                                                    </Grid> : ''
                                            }
                                        </>
                                        :
                                        <Grid item xs={6}>
                                            <Box display='flex' flexDirection='column' gap={2}>
                                                <Text mediumGreyHeader>Recurring Deduction</Text>
                                                <Text mediumBlack14>{viewData.is_recurring == false ? 'No' : viewData.is_recurring == true ? 'Yes' : '-'}</Text>
                                            </Box>
                                        </Grid>
                                }
                                {
                                    activeState == 'reimbursement' && viewData.payment_mode !== '' &&
                                    <>
                                        {
                                            viewData.payment_mode == 'Payroll cycle' ?
                                                <Grid item xs={6}>
                                                    <Box display='flex' flexDirection='column' gap={2}>
                                                        <Text mediumGreyHeader>Payment Period</Text>
                                                        <Text mediumBlack14>{viewData.pay_period ? viewData.pay_period : '-'}</Text>
                                                    </Box>
                                                </Grid> :
                                                <Grid item xs={6}>
                                                    <Box display='flex' flexDirection='column' gap={2}>
                                                        <Text mediumGreyHeader>Payment Date</Text>
                                                        <Text mediumBlack14>{viewData.pay_date ? viewData.pay_date : '-'}</Text>
                                                    </Box>
                                                </Grid>
                                        }
                                    </>
                                }
                                {
                                    activeState == 'reimbursement' && viewData.payment_mode == 'Off-payroll Cycle' ?
                                        <>
                                            <Grid item xs={12} m={'10px 0px'}>
                                                <Text smallBlack400>Transaction Details</Text>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box display='flex' flexDirection='column' gap={2}>
                                                    <Text mediumGreyHeader>Transaction ID</Text>
                                                    <Text mediumBlack14>{viewData.transaction_id ? viewData.transaction_id : '-'}</Text>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box display='flex' flexDirection='column' gap={2}>
                                                    <Text mediumGreyHeader>Payee Name</Text>
                                                    <Text mediumBlack14>{viewData.payee_name ? viewData.payee_name : '-'}</Text>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box display='flex' flexDirection='column' gap={2}>
                                                    <Text mediumGreyHeader>Bank Name</Text>
                                                    <Text mediumBlack14>{viewData.bank_name ? viewData.bank_name : '-'}</Text>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box display='flex' flexDirection='column' gap={2}>
                                                    <Text mediumGreyHeader>Last Four Digits of Bank Account</Text>
                                                    <Text mediumBlack14>{viewData.last_digit_of_bank_account ? viewData.last_digit_of_bank_account : '-'}</Text>
                                                </Box>
                                            </Grid>
                                        </> :
                                        activeState == 'deductions' ?
                                            <>
                                                <Grid item xs={6}>
                                                    <Box display='flex' flexDirection='column' gap={2}>
                                                        <Text mediumGreyHeader>Re-Occur Till</Text>
                                                        <Text mediumBlack14>{viewData.recurring_type ? viewData.recurring_type : '-'}</Text>
                                                    </Box>
                                                </Grid>
                                                {
                                                    viewData.is_recurring == true && viewData.recurring_type == 'Goal Amount' ?
                                                        <Grid item container xs={12} spacing={2}>
                                                            <Grid item xs={6}>
                                                                <Box display='flex' flexDirection='column' gap={2}>
                                                                    <Text mediumGreyHeader>Goal Amount</Text>
                                                                    <Text mediumBlack14>{viewData.goal_amount ? viewData.goal_amount : '-'}</Text>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Box display='flex' flexDirection='column' gap={2}>
                                                                    <Text mediumGreyHeader>Recovery Start Date</Text>
                                                                    <Text mediumBlack14>{viewData.recurring_start_date ? viewData.recurring_start_date : '-'}</Text>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Box display='flex' flexDirection='column' gap={2}>
                                                                    <Text mediumGreyHeader>No of Pay Cycles</Text>
                                                                    <Text mediumBlack14>{viewData.recurring_count ? viewData.recurring_count : '-'}</Text>
                                                                </Box>
                                                            </Grid>
                                                        </Grid> :
                                                        ''
                                                }
                                            </> : ''
                                }
                                <Grid item xs={12} >
                                    <Box display='flex' flexDirection='column' gap={1}>
                                        <Text mediumGreyHeader>Remarks</Text>
                                        <Text
                                            className={classes.remarksText}
                                            sx={{
                                                wordBreak: 'break-all',
                                                whiteSpace: 'normal'
                                            }}
                                        >
                                            {viewData.remarks ? viewData.remarks : '-'}
                                        </Text>
                                    </Box>
                                </Grid>
                                {
                                    viewData.status == 'Approved' || viewData.status == 'Pending' || viewData.status == undefined ? '' :
                                        <Grid item xs={12}>
                                            <Box display='flex' flexDirection='column' gap={2}>
                                                <Text mediumGreyHeader>Reason for Rejection</Text>
                                                <Text mediumBlack14>
                                                    {
                                                        viewData.reason_for_rejection && viewData.reason_for_rejection.length > 30 ?
                                                            <BlackToolTip title={viewData.reason_for_rejection} placement="right" arrow>
                                                                {viewData.reason_for_rejection.slice(0, 30) + (viewData.reason_for_rejection.length > 30 ? "..." : "-")}
                                                            </BlackToolTip>
                                                            : viewData.reason_for_rejection
                                                    }
                                                </Text>
                                            </Box>
                                        </Grid>
                                }
                                {
                                    LocalStorage.getUserData().admin_login && approver && activeState == 'reimbursement' && viewData.status == 'Pending' ?
                                        <>
                                            <Grid item xs={12}>
                                                <Divider sx={{ margin: '10px 0px' }} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box display='flex' flexDirection='column' gap={2}>
                                                    <Text mediumGreyHeader>Actions</Text>
                                                    <Input
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            name: 'approved_amount',
                                                            value: state.approved_amount,
                                                        }}
                                                        handleChange={(e) => changeHandler(e)}
                                                        onKeyPress={onNumberFloatCurrency}
                                                        clientInput
                                                        labelText={'Approved Amount'}
                                                        error={statusErrors.approved_amount ? <Text red>{statusErrors.approved_amount ? statusErrors.approved_amount : ''}</Text> : ''}
                                                    />
                                                    {statusErrors.approved_amount ? <Text red>{statusErrors.approved_amount ? statusErrors.approved_amount : ''}</Text> : ''}
                                                </Box>
                                            </Grid>
                                        </> : ''
                                }
                                {
                                    !LocalStorage.getUserData().admin_login ?
                                        <Grid item xs={11.5} textAlign='end'>
                                            <Button saveBtn600 onClick={() => setOpenView(false)}>Done</Button>
                                        </Grid>
                                        :
                                        activeState == 'deductions' ?
                                            <Grid item xs={11.5} textAlign='end'>
                                                <Button saveBtn600 onClick={() => setOpenView(false)}>Done</Button>
                                            </Grid>
                                            :
                                            <Grid item xs={12} justifyContent='end'>
                                                <Box display='flex' flexDirection='row' gap={2} justifyContent='end'>
                                                    {
                                                        viewData.status == 'Pending' && approver ?
                                                            <Button redBorder sx={{ width: '100px !important' }} onClick={() => handleButtons('Rejected')}>Reject</Button> :
                                                            <Button greyBorder sx={{ width: '100px !important' }} onClick={() => setOpenView(false)}>Close</Button>
                                                    }
                                                    {
                                                        viewData.status == 'Pending' && approver ?
                                                            <Button saveBtn600 onClick={() => handleButtons('Approved')}>Approve</Button> :
                                                            <Button saveBtn600 onClick={() => setOpenView(false)}>Done</Button>
                                                    }
                                                </Box>
                                            </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </SwipeableDrawer>

                <ReusablePopup openPopup={openRejection} setOpenPopup={setOpenRejection} white iconHide fixedWidth>
                    <Grid container p={2} spacing={2} textAlign='center'>
                        <Grid item xs={12}>
                            <img src={deleteAlert} alt='delete' />
                        </Grid>
                        <Grid item xs={12}>
                            <Text popupHead1>Are you Sure?</Text>
                        </Grid>
                        <Grid item xs={12}>
                            <Text mediumOverView>Do you want to reject reimbursements for the selected employees.</Text>
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'reason_for_rejection',
                                    value: state.reason_for_rejection,
                                    inputProps: { maxLength: 225 }
                                }}
                                handleChange={(e) => changeHandler(e)}
                                clientInput
                                labelText={'Reason for Rejection'}
                                error={statusErrors.reason_for_rejection ? <Text red>{statusErrors.reason_for_rejection ? statusErrors.reason_for_rejection : ''}</Text> : ''}
                            />
                            {statusErrors.reason_for_rejection ? <Text red sx={{ textAlign: 'start !important' }}>{statusErrors.reason_for_rejection ? statusErrors.reason_for_rejection : ''}</Text> : ''}
                        </Grid>
                        <Grid item xs={12} mt={1}>
                            <Box display='flex' flexDirection='row' gap={2} justifyContent='center'>
                                <Button redBorder sx={{ width: '110px !important' }} onClick={() => handleRejectButtons('Rejected')}>No,Cancel</Button>
                                <Button saveBtn400 sx={{ width: "110px !important" }} onClick={() => handleRejectButtons('Approved')}>Yes,Reject</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </ReusablePopup>
                <ReusablePopup openPopup={openApproval} setOpenPopup={setOpenApproval} white iconHide fixedWidth>
                    <Grid container p={2} spacing={2} textAlign='center'>
                        <Grid item xs={12}>
                            <img src={approvalIcon} alt='delete' />
                        </Grid>
                        <Grid item xs={12}>
                            <Text popupHead1>Are you Sure?</Text>
                        </Grid>
                        <Grid item xs={12}>
                            <Text mediumOverView>Do you want to approve reimbursements for the selected employees.</Text>
                        </Grid>
                        <Grid item xs={12} mt={1}>
                            <Box display='flex' flexDirection='row' gap={2} justifyContent='center'>
                                <Button redBorder sx={{ width: '110px !important' }} onClick={() => handleApproveButtons('Rejected')}>No,Cancel</Button>
                                <Button saveBtn400 sx={{ width: "110px !important" }} onClick={() => handleApproveButtons('Approved')}>Yes,Approve</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </ReusablePopup>
                <ReusablePopup openPopup={openSuccess} setOpenPopup={setOpenSuccess} white iconHide fixedWidth>
                    <Grid container p={2} spacing={2} textAlign='center'>
                        <Grid item xs={12}>
                            <img src={successIcon} alt='approved' />
                        </Grid>
                        <Grid item xs={12}>
                            <Text popupHead1>Approved</Text>
                        </Grid>
                        <Grid item xs={12}>
                            <Text mediumOverView>Selected Reimbursement are approved successfully</Text>
                        </Grid>
                        <Grid item xs={12} mt={1}>
                            <Button saveBtn400 sx={{ width: "110px !important" }} onClick={() => { setOpenSuccess(false); getAllPlacements(filterData) }}>Done</Button>
                        </Grid>
                    </Grid>
                </ReusablePopup>
            </Box >
        </>
    );
}