import { Box, Grid, Skeleton } from "@mui/material";
import { address_validation, validate_city, validate_empty_address, validate_emptyField, validate_zipcode } from "../../../components/Validation";
import { BlackToolTip } from "../../../utils/utils";
import Text from "../../../components/customText/Text";
import { debounce } from "lodash";
import CommonApi from "../../../apis/CommonApi";
import { useCallback } from "react";
import { ErrorMessages } from "../../../utils/ErrorMessages";

export const placement_address = {
  address_line_one: '',
  address_line_two: '',
  city: '',
  state_id: '',
  country_id: 2,
  zip_code: ''
};

export const placement_worklocation_address = {
  remote: {
    address_line_one: '',
    address_line_two: '',
    city: '',
    state_id: '',
    country_id: 2,
    zip_code: ''
  },
  office: {
    address_line_one: '',
    address_line_two: '',
    city: '',
    state_id: '',
    country_id: 2,
    zip_code: ''
  }
}

export const resetAddressErrors = (errorObj, addressType) => {
  errorObj[`${addressType}_address_line_one`] = '';
  errorObj[`${addressType}_address_line_two`] = '';
  errorObj[`${addressType}_city`] = '';
  errorObj[`${addressType}_country_id`] = '';
  errorObj[`${addressType}_state_id`] = '';
  errorObj[`${addressType}_zip_code`] = '';
};

// Reusable validation function
export const handleValidateAddress = (e, addressType, error) => {
  const input = e.target;

  const keyName = addressType === 'office' ? 'office' : 'remote';

  switch (input.name) {
    case 'address_line_one':
      error[`${keyName}_${'address_line_one'}`] = address_validation(input.value);
      break;
    case 'address_line_two':
      error[`${keyName}_${'address_line_two'}`] = validate_empty_address(input.value);
      break;
    case 'state_id':
      error[`${keyName}_${'state_id'}`] = validate_emptyField(input.value);
      break;
    case 'city':
      error[`${keyName}_${'city'}`] = validate_city(input.value);
      break;
    case 'zip_code':
      error[`${keyName}_${'zip_code'}`] = validate_zipcode(input.value, 'us');
      break;
    default:
      break;
  }
  return error
};

export const handleChangeAddress = (e, args, keyName, state, setState, error, setError) => {
  keyName[args][e.target.name] = e.target.value;
  setState({ ...state });
  const errors = handleValidateAddress(e, args, error);
  setError({ ...errors });
};

export const updateAddress = (e, state, setState, error, resetAddressErrors, addressKey) => {
  const { name, value } = e.target;
  if (name === 'work_location_type') {
    if (value === 'In-Office') {
      addressKey.remote = { ...placement_address };
      resetAddressErrors(error, 'remote');
    } else if (value === 'Remote') {
      addressKey.office = { ...placement_address };
      resetAddressErrors(error, 'office');
    }
    setState({ ...state });
  }
};

export const createAddressJson = (address) => [
  {
    label: 'Address - 1',
    keyValue: address.address_line_one
  },
  {
    label: 'Address Line-2 (Optional)',
    keyValue: address.address_line_two
  },
  {
    label: 'Country',
    keyValue: address.country_name
  },
  {
    label: 'State',
    keyValue: address.state_name
  },
  {
    label: 'City',
    keyValue: address.city
  }
];

export const AddressField = ({ label, value, loading }) => (
  <Grid item lg={4} md={4} sm={4} pt={3}>
    {
      loading ?
        <Skeleton variant='rounded' animation="wave" width={210} height={40} />
        :
        <>
          <Text mediumGreyHeader1>{label}</Text>
          <Text mediumBlack14 pt={0.8} noWrap>
            {value === "" ? "--" : value.length > 30 ?
              <BlackToolTip title={value} placement="right" arrow>
                {value.slice(0, 30) + (value.length > 30 ? "..." : "")}
              </BlackToolTip>
              :
              value
            }
          </Text>
        </>
    }
  </Grid>
);

export const AddressSection = ({ addressHeader, addressType, addressData, zipCodeLabel, zipCodeValue, loading }) => (
  <>
    {
      addressType == 'Hybrid' &&
      <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
        <Box display='flex' flexDirection='row' gap={2}>
          <Text largeBlue>{addressHeader}</Text>
        </Box>
      </Grid>
    }

    {
      addressData.map((item, index) => (
        <AddressField label={item.label} value={item.keyValue} loading={loading} key={index} />
      ))
    }
    <Grid item lg={4} md={4} sm={4} pt={4}>
      {
        loading ?
          <Skeleton variant='rounded' animation="wave" width={210} height={40} />
          :
          <>
            <Text mediumGreyHeader1>{zipCodeLabel}</Text>
            <Text mediumBlack14 pt={0.8} noWrap>{zipCodeValue || "--"}</Text>
          </>
      }
    </Grid>
  </>
);

// Function to update only specific fields without affecting the rest of the state
export const resetStateWithError = (data, setState, setError, state, key, type) => {
  const updateAddress = (data, type, key) => {
    setState((prevState) => {
      // Create deep copies of the parts of the state that need to be updated
      let updatedState = { ...prevState };

      // Handle 'add' type (placement_details.address)
      if (type === 'add') {
        updatedState = {
          ...updatedState,
          placement_details: {
            ...updatedState.placement_details,
            address: {
              ...updatedState.placement_details.address,
              [key]: {
                ...updatedState.placement_details.address[key],
                city: '', // Reset specific fields
                state_name: '',
                country_name: '',
                state_id: '',
                country_id: '',
                zip_code: data // Set zip_code from data
              }
            }
          }
        };
      } else {
        // Handle 'update' type (state.address)
        updatedState = {
          ...updatedState,
          address: {
            ...updatedState.address,
            [key]: {
              ...updatedState.address[key],
              city: '', // Reset specific fields
              state_name: '',
              country_name: '',
              state_id: '',
              country_id: '',
              zip_code: data // Set zip_code from data
            }
          }
        };
      }

      // Log the updated state for debugging purposes
      console.log('Updated State:', updatedState);

      return updatedState; // Return the new state
    });

    // Update the error state immutably
    setError((prevError) => ({
      ...prevError,
      [`${key}_city`]: '',
      [`${key}_zip_code`]:
      data.length == 0 ? ErrorMessages.fieldRequired :  
      data.length === 6
        ? ErrorMessages.invalidPincode
        : data.length === 5
          ? ErrorMessages.invalidZipcode
          : ErrorMessages.invalidZipcode,
      [`${key}_state_id`]: '',
      [`${key}_country_id`]: ''
    }));
  };

  // Call the function to update the address
  updateAddress(data, type, key);
};

export const useZipcodeAPICall = ({ setState, setError, state, type }) => {
  // eslint-disable-next-line
  return useCallback(
    debounce((data, key) => {
      CommonApi.getZipcodeData(data)
        .then((res) => {
          const { statusCode, data: responseData } = res.data;

          if (statusCode === 1003 && responseData.length > 0) {
            const { state_name, country_name, city, state_id, country_id } = responseData[0];

            setState((prevState) => {
              // Deep copy of the entire previous state
              let updatedState = { ...prevState };

              if (type === 'add') {
                // Deep copy placement_details.address and update only the specified key
                updatedState = {
                  ...updatedState,
                  placement_details: {
                    ...updatedState.placement_details,
                    address: {
                      ...updatedState.placement_details.address,
                      [key]: {
                        ...updatedState.placement_details.address[key],
                        city,
                        state_name,
                        country_name,
                        state_id,
                        country_id,
                        zip_code: data
                      }
                    }
                  }
                };
              } else {
                // Deep copy state.address and update only the specified key
                updatedState = {
                  ...updatedState,
                  address: {
                    ...updatedState.address,
                    [key]: {
                      ...updatedState.address[key],
                      city,
                      state_name,
                      country_name,
                      state_id,
                      country_id,
                      zip_code: data
                    }
                  }
                };
              }

              console.log('Updated State:', updatedState); // Debug log to verify state updates
              return updatedState;
            });

            // Clear errors immutably
            setError((prevError) => ({
              ...prevError,
              [`${key}_city`]: '',
              [`${key}_zip_code`]: '',
              [`${key}_state_id`]: '',
              [`${key}_country_id`]: ''
            }));
          } else {
            resetStateWithError(data, setState, setError, state, key, type);
          }
        })
        .catch(() => {
          resetStateWithError(data, setState, setError, state, key, type);
        });
    }, 500),
    [] // Ensure dependencies are always captured correctly
  );
};




