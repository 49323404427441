import axios from 'axios';
import { addErrorMsg, connectionFailed, getAxios, newAbortSignal, removeLoader } from '../utils/utils'

class BaseApi {
    get(URL, loader) {
        if (loader == undefined) {
            // addLoader()
        }
        return getAxios()
            .get(URL)
            .then(res => {
                // removeLoader()
                return res
            })
            .catch(err => {
                if (err.code == "ERR_NETWORK") {
                    connectionFailed();
                    if (window.navigator.onLine && !window.navigator.connection.effectiveType.endsWith("2g")) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000)
                    }
                }
                else if (err.response.data && err.response.data.statusCode == 1008) {
                    addErrorMsg(err.response.data.message);
                    removeLoader();
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    removeLoader();
                }
                // throw new Error(err)
            })
    }
    getWithParams(URL, token, loader) {
        if (loader == undefined) {
            // addLoader()
        }
        return getAxios()
            .get(URL,
                {
                    headers: {
                        'Authorization': `${token}`,
                    }
                })
            .then(res => {
                // removeLoader()
                return res
            })
            .catch(err => {
                if (err.code == "ERR_NETWORK") {
                    connectionFailed();
                    if (window.navigator.onLine && !window.navigator.connection.effectiveType.endsWith("2g")) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000)
                    }
                }
                else if (err.response.data && err.response.data.statusCode == 1008) {
                    addErrorMsg(err.response.data.message);
                    removeLoader();
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    removeLoader();
                }
                return err.response;
                // throw new Error(err)
            })
    }
    getWithTenantParams(URL, tenant_id, loader) {
        if (loader == undefined) {
            // addLoader()
        }
        return getAxios()
            .get(URL,
                {
                    headers: {
                        'tenantid': `${tenant_id}`,
                    }
                })
            .then(res => {
                // removeLoader()
                return res
            })
            .catch(err => {
                if (err.code == "ERR_NETWORK") {
                    connectionFailed();
                    if (window.navigator.onLine && !window.navigator.connection.effectiveType.endsWith("2g")) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000)
                    }
                }
                else if (err.response.data && err.response.data.statusCode == 1008) {
                    addErrorMsg(err.response.data.message);
                    removeLoader();
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    removeLoader();
                }
                return err.response;
                // throw new Error(err)
            })
    }
    getWithToken(URL, token, loader) {
        if (loader == undefined) {
            // addLoader()
        }
        return getAxios()
            .get(URL, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            .then(res => {
                // removeLoader()
                return res
            })
            .catch(err => {
                if (err.code == "ERR_NETWORK") {
                    connectionFailed();
                    if (window.navigator.onLine && !window.navigator.connection.effectiveType.endsWith("2g")) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000)
                    }
                }
                else if (err.response.data && err.response.data.statusCode == 1008) {
                    addErrorMsg(err.response.data.message);
                    removeLoader();
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    removeLoader();
                }
                // throw new Error(err)
            })
    }

    postWithToken(URL, data, token, loader) {
        if (loader == undefined) {
            // addLoader()
        }
        return getAxios()
            .post(URL, data,
                {
                    timeout: 180000,
                    headers: {
                        'Authorization': `${token}`
                        //  "Content-Type": "multipart/form-data"
                    }
                },
            )
            .then(res => {
                // removeLoader()
                return res
            })
            .catch(err => {
                if (err.code == "ERR_NETWORK") {
                    connectionFailed();
                    if (window.navigator.onLine && !window.navigator.connection.effectiveType.endsWith("2g")) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000)
                    }
                }
                else if (err.response.data && err.response.data.statusCode == 1008) {
                    addErrorMsg(err.response.data.message);
                    removeLoader();
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    removeLoader();
                }
                return err.response
                // throw new Error(err)
            })
    }

    postWithTokenZoho(URL, data, token, loader) {
        if (loader == undefined) {
            // addLoader()
        }
        return getAxios()
            .post(URL, data,
                {
                    // timeout: 180000,
                    headers: {
                        'Authorization': `${token}`
                        //  "Content-Type": "multipart/form-data"
                    }
                },
            )
            .then(res => {
                // removeLoader()
                return res
            })
            .catch(err => {
                if (err.code == "ERR_NETWORK") {
                    connectionFailed();
                    if (window.navigator.onLine && !window.navigator.connection.effectiveType.endsWith("2g")) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 200000)
                    }
                }
                else if (err.response.data && err.response.data.statusCode == 1008) {
                    addErrorMsg(err.response.data.message);
                    removeLoader();
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    removeLoader();
                }
                return err.response
                // throw new Error(err)
            })
    }

    postWithOutData(URL, token, loader) {
        if (loader == undefined) {
            // addLoader()
        }
        return getAxios()
            .post(URL, {},
                {
                    headers: {
                        'Authorization': `${token}`
                    }
                })
            .then(res => {
                // removeLoader()
                return res
            })
            .catch(err => {
                if (err.code == "ERR_NETWORK") {
                    connectionFailed();
                    if (window.navigator.onLine && !window.navigator.connection.effectiveType.endsWith("2g")) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000)
                    }
                }
                else if (err.response.data && err.response.data.statusCode == 1008) {
                    addErrorMsg(err.response.data.message);
                    removeLoader();
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    removeLoader();
                }
                return err.response
                // throw new Error(err)
            })
    }


    postFormData = async (URL, formData, token, loader) =>{
        if (loader == undefined) {
            // addLoader()
        }
        const file = formData.get('files');
        const arrayBuffer = await file.arrayBuffer();
        const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer); // Use browser's native crypto
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const checksum = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
        formData.append('expectedChecksum', checksum);
        return getAxios()
            .post(URL, formData, {
                headers: {
                    'Authorization': `${token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then(res => {
                // removeLoader()
                return res
            })
            .catch(err => {
                if (err.code == "ERR_NETWORK") {
                    connectionFailed();
                    if (window.navigator.onLine && !window.navigator.connection.effectiveType.endsWith("2g")) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000)
                    }
                }
                else if (err.response.data && err.response.data.statusCode == 1008) {
                    addErrorMsg(err.response.data.message);
                    removeLoader();
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    removeLoader();
                }
                return err.response
                // throw new Error(err)
            })
    }

    postWithData(URL, data, loader) {
        if (loader == undefined) {
            // addLoader()
        }
        return getAxios()
            .post(URL, data,
            )
            .then(res => {
                // removeLoader()
                return res
            })
            .catch(err => {
                if (err.code == "ERR_NETWORK") {
                    connectionFailed();
                    if (window.navigator.onLine && !window.navigator.connection.effectiveType.endsWith("2g")) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000)
                    }
                }
                else if (err.response.data && err.response.data.statusCode == 1008) {
                    addErrorMsg(err.response.data.message);
                    removeLoader();
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    removeLoader();
                }
                return err.response
                // throw new Error(err)
            })
    }

    putWithData(URL, data, loader) {
        if (loader == undefined) {
            // addLoader()
        }
        return getAxios()
            .put(URL, data)
            .then(res => {
                // removeLoader()
                return res
            })
            .catch(err => {
                if (err.code == "ERR_NETWORK") {
                    connectionFailed();
                    if (window.navigator.onLine && !window.navigator.connection.effectiveType.endsWith("2g")) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000)
                    }
                }
                else if (err.response.data && err.response.data.statusCode == 1008) {
                    addErrorMsg(err.response.data.message);
                    removeLoader();
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    removeLoader();
                }
                return err.response
                // throw new Error(err)
            })
    }
    putWithToken(URL, data, token, loader) {
        if (loader == undefined) {
            // addLoader()
        }
        return getAxios()
            .put(URL, data,
                {
                    headers: {
                        'Authorization': `${token}`,
                    }
                })
            .then(res => {
                // removeLoader()
                return res
            })
            .catch(err => {
                if (err.code == "ERR_NETWORK") {
                    connectionFailed();
                    if (window.navigator.onLine && !window.navigator.connection.effectiveType.endsWith("2g")) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000)
                    }
                }
                else if (err.response.data && err.response.data.statusCode == 1008) {
                    addErrorMsg(err.response.data.message);
                    removeLoader();
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    removeLoader();
                }
                return err.response
                // throw new Error(err)
            })
    }

    deleteWithToken(URL, data, token, loader) {
        if (loader == undefined) {
            // addLoader()
        }
        return getAxios()
            .delete(URL,
                {
                    headers: {
                        'Authorization': `${token}`,
                    },
                    data
                })
            .then(res => {
                // removeLoader()
                return res
            })
            .catch(err => {
                if (err.code == "ERR_NETWORK") {
                    connectionFailed();
                    if (window.navigator.onLine && !window.navigator.connection.effectiveType.endsWith("2g")) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000)
                    }
                }
                else if (err.response.data && err.response.data.statusCode == 1008) {
                    addErrorMsg(err.response.data.message);
                    removeLoader();
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    removeLoader();
                }
                return err.response
                // throw new Error(err)
            })
    }

    ocrPost(URL, data, token, loader) {
        if (loader == undefined) {
            // addLoader()
        }
        return axios.post(URL, data, { headers: { 'Authorization': token }, timeout: 120000,signal: newAbortSignal(120000),  }).then((response) =>{
            return response
        }).catch(err => {
            if (err.code == "ERR_NETWORK") {
                // connectionFailed();
                addErrorMsg('AI failed to fetch data, Please try again');
                removeLoader();
            }
            else if (err.response.data && err.response.data.statusCode == 1008) {
                addErrorMsg(err.response.data.message);
                removeLoader();
                localStorage.clear();
                window.location.reload();
            }
            else {
                removeLoader();
            }
            return err.response
            // throw new Error(err)
        })
    }

    getDownloadExel(URL, token) {
        return getAxios()
            .get(URL,
                {
                    headers: {
                        'Authorization': `${token}`,
                    },
                    responseType: 'blob', // Set response type to blob
                })
            .then(res => {
                return res
            })
            .catch(err => {
                if (err.code == "ERR_NETWORK") {
                    connectionFailed();
                    if (window.navigator.onLine && !window.navigator.connection.effectiveType.endsWith("2g")) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000)
                    }
                } else {
                    removeLoader();
                    return err.response
                }
            })
    }

    postDownloadExcel(URL, data, token, loader) {
        if (loader == undefined) {
            // addLoader()
        }
        return getAxios()
            .post(URL, data,
                {
                    timeout: 180000,
                    headers: {
                        'Authorization': `${token}`,
                    },
                    responseType: 'blob', // Set response type to blob
                },
            )
            .then(res => {
                // removeLoader()
                return res
            })
            .catch(err => {
                if (err.code == "ERR_NETWORK") {
                    connectionFailed();
                    if (window.navigator.onLine && !window.navigator.connection.effectiveType.endsWith("2g")) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000)
                    }
                }
                else if (err.response.data && err.response.data.statusCode == 1008) {
                    addErrorMsg(err.response.data.message);
                    removeLoader();
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    removeLoader();
                }
                return err.response
                // throw new Error(err)
            })
    }
}
// eslint-disable-next-line
export default new BaseApi()
