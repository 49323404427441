import React from 'react'
import { Box, Grid } from '@mui/material';
import Text from '../../../../components/customText/Text';
import payPeriod from '../../../../assets/svg/payroll/payperiodIcon.svg';
import currentCycle from '../../../../assets/svg/payroll/currentCycle.svg';
import checkDateIcon from '../../../../assets/svg/payroll/checkDate.svg';
import PayrollDashboardStyles from './PayrollDashboardStyles';

function UpcomingPayroll(props) {
    const { upcomingPayrollData } = props;

    const classes = PayrollDashboardStyles();

    const today = new Date(); // Use current date
    const currentDay = today.getDate();
    const totalDaysInMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
    ).getDate();

    const percentage = (currentDay / totalDaysInMonth) * 100;
    const finalDay = percentage.toFixed(2);
  
    return (
        <Box sx={{ width: '94.4%', height: '25vh', boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '12px' }}>
            <Grid item xs={12} lg={12} md={12}>
                <Text largeBlack>Upcoming Payroll</Text>
            </Grid>
            <Grid item container xs={12} lg={12} md={12}>
                <Box className={classes.upcomingPayroll}>
                    <Box className={classes.payperiod}>
                        <img src={payPeriod} alt='payPeriod' style={{ paddingRight: '5px' }} />
                        <Text mediumBoldWhite600>{upcomingPayrollData.pay_period.from_date} - {upcomingPayrollData.pay_period.to_date}</Text>
                    </Box>
                    <Box className={classes.currentCycle}>
                        <Box
                            sx={{
                                position: "absolute",
                                left: `${finalDay}%`,
                                top: "-35px",
                                transform: "translateX(-50%)",
                                textAlign: "center",
                                alignItems: "center"
                            }}
                        >
                            <Text smallBlack mb={1}>Today</Text>
                            <Box className={classes.currentDate}>
                                <Text largeWhite16>{today.getDate()}th</Text>
                            </Box>
                        </Box>
                        <Box display='flex' flexDirection='row' gap={1} alignItems='center' pt={'5px'}>
                            <img src={currentCycle} alt='currentCycle' style={{ paddingRight: '5px' }} />
                            <Text mediumBoldWhite600>{upcomingPayrollData.current_cycle.from_date} - {upcomingPayrollData.current_cycle.to_date}</Text>
                        </Box>
                    </Box>
                    <Box className={classes.checkDate}>
                        <Box display='flex' flexDirection='row' gap={1} alignItems='center' pt={'5px'}>
                            <img src={checkDateIcon} alt='checkDateIcon' style={{ paddingRight: '5px' }} />
                            <Text black12>{upcomingPayrollData.check_date}</Text>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item container xs={12} lg={12} md={12}>
                <Box sx={{ width: '20%' }}>
                    <Text black12>Pay Period</Text>
                </Box>
                <Box sx={{ width: '60%' }}>
                    <Text black12>Current cycle</Text>
                </Box>
                <Box sx={{ width: '20%' }}>
                    <Text black12>Check Date</Text>
                </Box>
            </Grid>
        </Box>
    )
}

export default UpcomingPayroll
