import React, { useEffect } from 'react';
import Slider from '@mui/material/Slider';

// Custom Range Slider Component
const CustomSlider = ({ min, max, onValueChange, value, name }) => {

    // Ensure the parent state initializes properly
    useEffect(() => {
        if (!value) {
            onValueChange?.(name, [min, max]);
        }
    }, [value, min, max, name, onValueChange]);

    const handleChange = (event, newValue) => {
        // Validate newValue and call onValueChange if valid
        if (Array.isArray(newValue) && newValue.length === 2) {
            onValueChange?.(name, newValue);
        }
    };

    return (
        <Slider
            value={value || [min, max]}
            min={min}
            max={max}
            step={1}
            onChange={handleChange}
            name={name}
            valueLabelDisplay="off"
            sx={{
                height: 8,
                boxShadow: '0 0 0 8px #fffff',
                '& .MuiSlider-thumb': {
                    height: 20,
                    width: 20,
                    border: '2px solid currentColor',
                    boxShadow: '0 0 0 8px white',
                    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                        boxShadow: 'white',
                    },
                },
            }}
        />
    );
};

export default CustomSlider;
