import BaseApi from '../../BaseApi'
import APIURL from "../../../config/development";
import LocalStorage from '../../../utils/LocalStorage';

class PayrollConfigApi {

    getPayrollConfig() {
        return BaseApi.getWithParams(APIURL.org_URL + `payroll-config/payroll-cycles/index?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    getPayrollCyclesTypes() {
        return BaseApi.getWithParams(APIURL.org_URL + `payroll-config/cycle-types/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }
    postPayrollConfig(data) {
        return BaseApi.postWithToken(APIURL.org_URL + 'payroll-config/payroll-cycles/store', data, LocalStorage.getAccessToken());
    }

    postPayrollCycleChangeConfig(data) {
        return BaseApi.postWithToken(APIURL.org_URL + 'payroll-config/payroll-cycles/change-cycle', data, LocalStorage.getAccessToken());
    }
    updatePayrollConfig(data) {
        return BaseApi.putWithToken(APIURL.org_URL + `payroll-config/payroll-cycles/update`, data, LocalStorage.getAccessToken())
    }
    
}

export default new PayrollConfigApi();