import { makeStyles } from "@mui/styles";

const AppIntegrationsStyles = makeStyles(() => ({

    mainBox: {
        marginTop: '10px',
        padding: '0px 40px 0px 120px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: '#FDFDFD',
    },
    mainBox1: {
        marginTop: '10px',
        padding: '0px 120px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: '#FDFDFD',
    },
    invoicingBox: {
        width: "69px",
        height: "21px",
        background: "#EAECF0",
        font: "10px Quicksand",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "4px"
    },
    configureButton: {
        width: "96px !important",
        height: "30px !important",
        font: "12px Quicksand !important",
        fontWeight: `${600} !important`,
        borderRadius: "4px !important",
    },
    deRegButton: {
        width: "113px !important",
        height: "30px !important",
        font: "12px Quicksand !important",
        fontWeight: `${600} !important`,
        borderRadius: "4px !important",
    },
    endIcon: {
        marginLeft: "0px !important",
        fontSize: "15px !important",
        marginTop: "3px !important",
    },
    cardBox: {
        width: "100% !important",
        boxShadow: "0px 2px 24px 0px #919EAB1F",
        padding: "16px",
        borderRadius: "8px"
    },
    breadcrumbsLink: {
        fontFamily: "Quicksand !important",
        color: "#849199 !important",
        fontSize: "14px !important",
        fontWeight: '700 !important',
        textDecoration: "none !important",
        cursor: "pointer !important",
    },
    helpText: {
        font: "14px Quicksand !important", borderBottom: "1px solid #0C75EB !important", width: "fit-content !important", cursor: "pointer !important", fontWeight: '400 !important'
    },
    configBtn: {
        height: "54px !important", width: "150px !important"
    },
    configBackBtn: {
        height: "43px !important", width: "100px !important"
    },
    configSave: {
        height: "43px !important", width: "125px !important"
    },
    configSaveBtn: {
        height: "43px !important", width: "334px !important", marginRight: "10px !important"
    },
    logoBox: {
        display: "flex", alignItems: "center"
    },
    continueBox: {
        height: "30vh", display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'
    },
    popupButtons: {
        width: "124px !important", height: "44px !important", font: "15px Quicksand !important", fontWeight: `${700} !important`
    },
    finish: {
        width: "126px !important", height: "54px !important",
    },
    doneBtn: {
        width: "125px !important", height: "39px !important", font: "15px Quicksand !important", fontWeight: `${600} !important`
    },
    progressContainer: {
        border: '1px solid #C7CCD3', borderRadius: '8px', padding: '10px', position: 'relative', display: 'inline-flex'
    },
    closeIcon: {
        textAlign: 'center', alignItems: 'center', position: 'absolute', top: '-10%', left: '98%', cursor: 'pointer'
    },
    helperTextError: {
        color: '#e72929 !important',
        font: '11px Quicksand !important',
    },
}))


export default AppIntegrationsStyles;