import React from 'react';
import LocalStorage from '../../utils/LocalStorage';
import Text from '../customText/Text';
import { MenuItem } from '@mui/material';


const EmployeeActions = ({ employee, rolePermission, handleStatus, classes,setCancelPopup }) => {
  const isAuthorized = LocalStorage.getUserData().super_admin || 
    (rolePermission !== "" && rolePermission.some(item => item.slug === "employee_edit" && item.is_allowed === true));

  const Item = ({ text, onClick, className }) => (
    <MenuItem 
      className={isAuthorized ? className : classes.inactiveDisable}
      onClick={isAuthorized ? onClick : undefined}
    >
      <Text smallBlackBold>{text}</Text>
    </MenuItem>
  );

  const renderOffboardingOptions = () => (
    <>
      <Item text="Last Working Date" onClick={() => handleStatus(0)} className={classes.inactive} />
      <Item text="Cancel Offboarding" onClick={() => setCancelPopup(true)} className={classes.inactive} />
    </>
  );

  const renderUserAccessOption = () => (
    <Item 
      text={`${employee.employment_details.enable_login === 1 ? 'Disable' : 'Enable'} User Access`}
      onClick={() => handleStatus(1)}
      className={classes.viewText}
    />
  );

  if (employee.status === 'In Active') {
    return employee.is_off_boarding_initiated ? 
      renderOffboardingOptions() : 
      <Item text="Offboard Employee" onClick={() => handleStatus(0)} className={classes.inactive} />;
  }

  if (!employee.is_off_boarding_initiated) {
    return (
      <>
        {renderUserAccessOption()}
        <Item text="Offboard Employee" onClick={() => handleStatus(0)} className={classes.inactive} />
      </>
    );
  }

  return (
    <>
      <Item text="Last Working Date" onClick={() => handleStatus(0)} className={classes.viewText} />
      <Item text="Cancel Offboarding" onClick={() => setCancelPopup(true)} className={classes.viewText} />
      {renderUserAccessOption()}
    </>
  );
};

export default EmployeeActions;