import { makeStyles } from "@mui/styles";
import { blue } from "../../../theme";
import { useTheme } from "@mui/material"

const Styles = makeStyles(() => ({
    mainContainer: {
        left: "60px",
        width: "calc(100% - 60px)",
        position: "relative",
        transition: "all .3s ease",
        backgroundColor: "rgba(253, 253, 253, 1)",
        [useTheme().breakpoints.down('sm')]: {
            width: '150% !important'
        }
    },
    profileName: {
        fontFamily: "Quicksand !important",
        color: "#092333 !important",
        fontSize: "18px !important",
        fontWeight: `${700} !important`,
        textAlign: "center",
        textTransform: "capitalize !important",
        [useTheme().breakpoints.down('xl')]: {
            fontSize: '14px !important',
            fontWeight: `${700} !important`,
        },
        [useTheme().breakpoints.down('lg')]: {
            fontSize: '14px !important',
            fontWeight: `${700} !important`,
        }
    },
    profileId: {
        fontFamily: "Quicksand !important",
        paddingTop: "5px",
        fontWeight: '600 !important',
        color: "#525252 !important",
        fontSize: "14px !important",
        textAlign: "center",
        lineHeight: "16.8px !important",
        [useTheme().breakpoints.down('xl')]: {
            fontSize: '12px !important',
            fontWeight: `${600} !important`,
        }
    },
    eVerifyBtn: {
        fontFamily: "Quicksand",
        fontSize: "15px !important",
        fontWeight: "700 !important",
        textTransform: "capitalize !important",
        color: "#0C75EB !important",
        height: "35px",
        width: "138px",
        padding: "0px 18px !important",
        borderRadius: "8px !important",
        cursor: 'default !important',
        [useTheme().breakpoints.down('xl')]: {
            fontSize: '14px !important'
        }
    },
    moreBtn: {
        fontFamily: "Quicksand",
        textTransform: "capitalize !important",
        color: "#9D9E9F !important",
        border: "1px solid #9D9E9F !important",
        height: "35px !important",
        minWidth: "35px !important",
        padding: "5px !important",
        borderRadius: "8px !important"
    },
    listItems: {
        fontFamily: "Quicksand",
        minHeight: "32px",
        margin: "6px 0px !important",
        color: '#262626',
        fontWeight: '600 !important',
        borderRadius: "4px !important",
        fontSize: "14px !important",
        transition: "all .3s ease",
        '&:hover': {
            backgroundColor: '#F5F6F6 !important',
        },
        [useTheme().breakpoints.down('xl')]: {
            fontSize: '12px !important'
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '10px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    listItemsActive: {
        color: '#FFFFFF !important',
        fontWeight: '600 !important',
        fontSize: "14px !important",
        fontFamily: "Quicksand !important",
        backgroundColor: '#0C75EB !important',
        transition: "all .3s ease",
        '&:hover': {
            backgroundColor: '#0C75EB !important',
            color: '#FFFFFF !important',
        },
        [useTheme().breakpoints.down('xl')]: {
            fontSize: '12px !important'
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '10px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    avatarBox: {
        display: "flex",
        borderRadius: "50%",
    },
    avatar: {
        width: "110px !important",
        height: "110px !important",
        margin: "8px"
    },
    tabTitle: {
        fontFamily: "Quicksand",
        color: "#849199 !important",
        textTransform: "capitalize !important",
        fontSize: "15px !important",
        fontWeight: 500,
        margin: "0px 12px !important",
        padding: "20px !important"
    },
    activeTabTitle: {
        color: "#0C75EB !important",
    },
    // Breadcrumbs Styles
    breadcrumbsName: {
        fontFamily: "Quicksand",
        color: "#092333 !important",
        fontSize: "14px !important",
    },
    breadcrumbsLink: {
        fontFamily: "Quicksand !important",
        color: "#849199 !important",
        fontSize: "14px !important",
        fontWeight: '700 !important',
        textDecoration: "none !important",
        cursor: "pointer !important",
    },
    sideTooltip: {
        height: "34px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
    },
    sideTooltipText: {
        fontSize: "14px",
        fontFamily: "Quicksand",
        fontWeight: "400",
        color: "#FFFFFF",
    },
    viewText: {
        borderBottom: '1px solid #EAECF0 !important',
        font: '13px Quicksand',
        height: '37px !important',
        "&:hover": {
            background: 'none !important'
        }
    },
    inactive: {
        height: '37px !important',
        "&:hover": {
            background: 'none !important'
        }
    },
    inactiveDisable: {
        height: '37px !important',
        cursor: "default !important",
        "&:hover": {
            background: 'none !important'
        }
    },
    optional: {
        font: '12px Quicksand',
        color: 'rgb(194 188 188) !important',
        marginLeft: '2px !important'
    },
    popupHead: {
        width: "100% !important",
        display: 'flex !important',
        justifyContent: 'center !important'
    },
    //offBoardingPopupStyle
    activeRow: {
        background: "#F7FFFA !important",
        borderRadius: "8px !important",
        border: "1px solid #22C55E !important",
        marginTop: '10px !important'
    },
    headerRow: {
        display: "flex !important",
        flexDirection: "column !important",
        justifyContent: "center !important"
    },
    disableRow: {
        background: "#FBFBFB !important",
        borderRadius: "10px !important",
        border: "none !important"
    },
    bodyBox: {
        width: "100% !important",
        display: 'flex !important',
        justifyContent: 'center !important',
        backgroundColor: "#3B4957 !important"
    },
    disabledColor: {
        cursor: "not-allowed !important",
        pointerEvents: "all !important",
        color: "#bebebe !important"
    },
    add: {
        color: "#0C75EB",
        cursor: 'pointer'
    },
    minus: {
        color: "#E51A1A",
        cursor: 'pointer',
    },
    ViewContainer: {
        height: '45vh',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center'
    },
    uploadBtn: {
        font: '12px Quicksand !important',
        fontWeight: '700 !important',
        textTransform: 'capitalize !important',
        color: '#0C75EB !important',
        backgroundColor: "#fff !important",
        padding: "0px 8px !important"
    },
    blueMenu: {
        fontFamily: "Quicksand",
        textTransform: "capitalize !important",
        color: "#9D9E9F !important",
        border: `1px solid ${blue} !important`,
        height: "35px !important",
        minWidth: "35px !important",
        padding: "5px !important",
        borderRadius: "8px !important"
    },
    root: {
        "& .MuiFilledInput-root": {
            background: "white"
        }
    },
    inputRoot: {
        '&$focused $input': {
            border: `1px solid red !important`,
        },
    },
    input: {
        border: `1px solid red !important`,
    },
    blueText: {
        font: '14px Quicksand !important',
        color: '#0C75EB !important',
        fontWeight: `${400} !important`
    },
    responsiveContainer: {
        height: '50vh',
        width: '100% !important',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    sidecardScroll: {
        height: '65vh !important', overflow: 'scroll', paddingRight: '0px', paddingBottom: '5px', width: '100%'
    },
    sidecard: {
        boxShadow: "0px 2px 24px -4px #919EAB1F !important", borderRadius: "8px", padding: '3px', marginBottom: '10px !important', cursor: 'pointer', width: '100p% !important',
        '&:hover': {
            border: '1px solid #0C75EB'
        }
    },
    statusBox: {
        background: '#037847', color: '#FFFFFF', minHeight: '22px', minWidth: '72px', padding: '2px 10px 4px 10px', borderRadius: '18px', textAlign: 'center !important'
    },
    orangeBox: {
        background: '#F59E0B', color: '#FFFFFF', minHeight: '22px', minWidth: '72px', padding: '2px', borderRadius: '18px', textAlign: 'center !important'
    },
    redBox: {
        background: '#F70D0D', color: '#FFFFFF', minHeight: '22px', minWidth: '72px', padding: '2px 10px 4px 10px', borderRadius: '18px', textAlign: 'center !important'
    },
    activeText: {
        font: "14px Quicksand !important",
        letterSpacing: '0px',
        color: `${blue} !important`,
        opacity: 1,
        fontWeight: `${600} !important`,
        textTransform: 'capitalize !important',
    },
    tabText: {
        font: "14px Quicksand !important",
        letterSpacing: '0px',
        color: `#262626 !important`,
        opacity: 1,
        fontWeight: `${600} !important`,
        textTransform: 'capitalize !important',
    },
    boldName: {
        font: "14px Quicksand !important",
        fontWeight: `${600} !important`,
    },
    textCenter: {
        textAlign: 'end',
        [useTheme().breakpoints.down('lg')]: {
            textAlign: 'start !important'
        }
    },
    greyDot: {
        height: '6px',
        width: '6px',
        borderRadius: '50%',
        background: '#D9D9D9 !important'
    },
    tsScroll: {
        height: '50vh', overflowY: 'scroll', width: '100%', paddingTop: '5px'
    },
    greenText: {
        color: '#15803D', font: '12px Quicksand', fontWeight: `${600} !important`
    },
    nodata: {
        width: '100%', height: '40vh !important', alignItems: 'center'
    },
    requestCount: {
        height: '18px', minWidth: '21px', borderRadius: '36px', background: '#F70D0D', color: '#fff', font: '12px Quicksand', fontWeight: '600', padding: '0px 4px !important'
    },
    searchInput: {
        border: "none",
        padding: "0px 0px 0px 10px",
        width: "100%",
        height: "100%",
        background: "transparent",
        color: "rgba(38, 38, 38, 1)",
        fontFamily: "Quicksand",
        fontSize: "12px",
        fontWeight: "400",
        transition: "all .3s ease",
        '&::-webkit-input-placeholder': {
            color: "rgba(199, 204, 211, 1)",
        },
        '&:focus': {
            outline: "none"
        }
    },
    tsinput: {
        margin: "10px 0px", minHeight: "46px", border: "1.5px solid #E2E5E6", width: "100%", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center"
    },
    borderButton1: {
        all: "unset", display: "flex !important", justifyContent: "center !important", alignItems: "center !important", cursor: "pointer", textAlign: "center !important", fontSize: "15px !important", fontFamily: "Quicksand CY !important", width: "52px !important", height: "44px !important", border: "1.5px solid rgba(199, 204, 211, 1) !important", background: "#ffffff !important", borderRadius: "8px !important"
    },
    circularIcon: {
        height: "14px !important",
        width: "14px !important"
    },
    divider: {
        margin: "0px !important"
    },
    listItem: {
        padding: "0px !important",
        height: "53px !important",
        cursor: "pointer"
    },
    listItemActive: {
        padding: "0px !important",
        height: "53px !important",
        background: "#E4F1FF !important",
        cursor: "pointer"
    },
    startDateBox: {
        display: "flex",
        alignItems: "center !important",
        justifyContent: "center !important",
        width: '30px !important',
        height: '14px !important',
        borderRadius: '50px',
        opacity: '0px',
    },
}))

export default Styles;
