import { makeStyles } from "@mui/styles";
import { btnBgGrey, } from "../../../../theme";

const TimeSheetStyles = makeStyles(() => ({

    // Breadcrumbs Styles
    breadcrumbsName: {
        fontFamily: "Quicksand !important",
        fontWeight: "600 !important",
        color: "#092333 !important",
        fontSize: "14px !important",
    },

    breadcrumbsLink: {
        fontFamily: "Quicksand !important",
        fontWeight: "600 !important",
        color: "#849199 !important",
        fontSize: "14px !important",
        textDecoration: "none !important",
        cursor: "pointer !important",
    },
    // Breadcrumbs Styles End
    sideTooltip: {
        height: "30px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
    },

    sideTooltipText: {
        fontSize: "11px !important",
        fontFamily: "Quicksand",
        fontWeight: "400",
        color: "#FFFFFF",
    },
    textarea: {
        width: "100% !important",
        font: "14px Quicksand !important",
        fontWeight: "400 !important",
        color: `${btnBgGrey.shade6} !important`,
    },
    cardCount: {
        fontFamily: "Quicksand !important",
        fontWeight: "600 !important",
        color: "#171717 !important",
        fontSize: "40px !important",
    },
    cardText: {
        fontFamily: "Quicksand !important",
        fontWeight: "500 !important",
        color: "#171717 !important",
        fontSize: "15px !important",
    },
    card: {
        padding: "10px",
        height: "160px",
        borderRadius: "12px",
        cursor: "pointer",
        border: "2px solid #FFFFFF",
    },
    cardHead: {
        width: "100%", height: "75px", display: "flex", justifyContent: "space-between", alignItems: "center"
    },      
    cardBody: {
        width: "100%", height: "75px", display: "flex", alignItems: "center"
    },
    moreiconBox: {
        height: "48px"
    },
    primarytext: {
        fontSize: "22px !important",
        fontFamily: "Quicksand !important",
        display: "flex !important",
        alignSelf: "center !important",
        fontWeight: "600 !important",
        color: "rgba(38, 38, 38, 1) !important"
    },
    primarytext2: {
        fontSize: "18px !important",
        fontFamily: "Quicksand !important",
        display: "flex !important",
        alignSelf: "center !important",
        fontWeight: "500 !important",
        color: "rgba(38, 38, 38, 1) !important"
    },
    secondarytext: {
        fontSize: "14px !important",
        fontFamily: "Quicksand !important",
        fontWeight: "500 !important",
        color: "rgba(115, 115, 115, 1) !important"
    },
    searchInput: {
        border: "none",
        padding: "0px 0px 0px 10px",
        width: "215px",
        height: "100%",
        background: "transparent",
        color: "rgba(38, 38, 38, 1)",
        fontFamily: "Quicksand",
        fontSize: "12px",
        fontWeight: "600",
        transition: "all .3s ease",
        '&::-webkit-input-placeholder': {
            color: "rgba(199, 204, 211, 1)",
        },
        '&:focus': {
            outline: "none"
        }
    },
    employeViewContainer: {
        width: "100%",
        height: "120px",
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "12px",
        boxShadow: "0px 0px 2.0015923976898193px 0px #919EAB4D, 0px 2.0015923976898193px 24.019107818603516px 0px #919EAB1F ",
        "@media (min-width:1200px) and (max-width:1400px)": {
            height: "160px !important",
        },
    },
    employeViewBox: {
        width: "100%",
        height: '80px'
    },
    flexAlineCenter: {
        display: 'flex',
        alignItems: "center"
    },
    placementCard: {
        padding: "10px",
        height: "160px",
        borderRadius: "12px",
        cursor: "pointer",
        border: "2px solid #F2F2F2",
    },
    step: {
        display: 'flex',
        marginBottom: '2px',
    }, 
    dropzoneMainBox: {
        width: '100%',
        height: "15vh",
    },
    dropBox: {
        width: '100%',
        minHeight: "15vh",
        display: 'inline-block',
        backgroundColor: "#F2F7FF",
        border: 'dashed 2px #0C75EB',
        borderRadius: "8px",
        cursor: "pointer"
    },
    statusBox: {
        width: "150px", height: "27px", background: "#0C75EB !important",borderRadius:"33px",padding:"5px 0px",textAlign:"center",
    },
    statusBoxP: {
        width: "163px", height: "27px", background: "#F59E0B !important",borderRadius:"33px",padding:"5px 0px",textAlign:"center",
    },
    statusBoxA: {
        width: "88px", height: "27px", background: "#16A34A !important",borderRadius:"33px",padding:"5px 0px",textAlign:"center",
    },
    statusBoxR: {
        width: "82px", height: "27px", background: "#E52525 !important",borderRadius:"33px",padding:"5px 0px",textAlign:"center",
    }, 
}))

export default TimeSheetStyles;