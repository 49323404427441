import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import Text from "../../../components/customText/Text";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import { BlackToolTip } from "../../../utils/utils";
import billed from '../../../assets/svg/dashboard/billed.svg';
import pay from '../../../assets/svg/dashboard/pay.svg';
import margin from '../../../assets/svg/dashboard/margin.svg';
import ReactApexChart from 'react-apexcharts';

function FinancialPerformance(props) {
const {financialOptions, currency, viewAnalytics, financial, dropDownChangeHandler, yearDropdwn, years, classes } = props
return (
    <Grid item container spacing={2} lg={12} md={12} sm={12} xs={12} pt={3} id='Financialchart'>
        <Grid item container lg={12} md={12} sm={12} xs={12} alignItems='center'>
            <Grid item lg={10} md={9} sm={6} xs={6}>
                <Text smallLabelblack16>Financial Performance</Text>
            </Grid>
            <Grid item lg={2} md={3} sm={6} xs={6}>
                <CustomSelect
                    name='year'
                    value={yearDropdwn.year}
                    viewDrop
                    scrollTrue={true}
                    options={years}
                    onChange={(e) => dropDownChangeHandler(e, 'financial')}
                />
            </Grid>
        </Grid>
        <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} spacing={2} columnSpacing={2}>
            <Grid item lg={4} md={12} sm={12} xs={12}>
                <Box alignItems='center' className={classes.bluebg}>
                    <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4}>
                        <Grid item lg={2} md={2} xs={2}>
                            <img loading="eager" src={billed} alt='clients' width='48' height='48' />
                        </Grid>
                        <Grid item lg={9} md={9} xs={9} ml={2}>
                            <Text mediumBoldBlack>Total Billed</Text>
                            <Text boldblack22>{currency}&nbsp;
                                {financial.total_billed ? financial.total_billed.length > 10 ?
                                    <BlackToolTip title={financial.total_billed}>
                                        {financial.total_billed.slice(0, 10) + financial.total_billed.length > 10 ? '...' : ''}
                                    </BlackToolTip> : financial.total_billed : financial.total_billed == 0 ? 0 : '--'
                                }
                            </Text>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
                <Box alignItems='center' className={classes.yellowbg}>
                    <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4}>
                        <Grid item lg={2} md={2} xs={2}>
                            <img loading="eager" src={pay} alt='clients' width='48' height='48' />
                        </Grid>
                        <Grid item lg={9} md={9} xs={9} ml={2}>
                            <Text mediumBoldBlack>Total Pay</Text>
                            <Text boldblack22>{currency}&nbsp;
                                {financial.total_pay ? financial.total_pay.length > 10 ?
                                    <BlackToolTip title={financial.total_pay}>
                                        {financial.total_pay.slice(0, 10) + financial.total_pay.length > 10 ? '...' : ''}
                                    </BlackToolTip> : financial.total_pay : financial.total_pay == 0 ? 0 : '--'
                                }
                            </Text>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
                <Box alignItems='center' className={classes.purplebg}>
                    <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4}>
                        <Grid item lg={2} md={2} xs={2}>
                            <img loading="eager" src={margin} alt='clients' width='48' height='48' />
                        </Grid>
                        <Grid item lg={9} md={9} xs={9} ml={2}>
                            <Text mediumBoldBlack>Margin</Text>
                            <Text boldblack22>{currency}&nbsp;
                                {financial.margin ? financial.margin.length > 10 ?
                                    <BlackToolTip title={financial.margin}>
                                        {financial.margin.slice(0, 10) + financial.margin.length > 10 ? '...' : ''}
                                    </BlackToolTip> : financial.margin : financial.margin == 0 ? 0 : '--'
                                }
                            </Text>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
            <ReactApexChart
                options={financialOptions}
                series={financialOptions.series}
                type="area"
                height='300px'
            />
        </Grid>
        <Grid item container lg={12} md={12} sm={10} xs={10} pt={2} justifyContent='end'>
            <Box minWidth='70px' textAlign='end'>
                <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={viewAnalytics}>View More</Text>
            </Box>
        </Grid>
        <Divider sx={{ width: '100%', margin: '20px 0px 30px 0px', borderColor: '#F5F5F5' }} />
    </Grid>
)
}
export default FinancialPerformance