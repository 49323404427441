import APIURL from '../../../config/development';
import LocalStorage from '../../../utils/LocalStorage';
import BaseApi from '../../BaseApi';

class DeductionApi {
    getDeductionDropdown() {
        return BaseApi.getWithParams(APIURL.API_URL + `payroll-config/expense-management/deduction/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    } 

    storeDeduction(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `expense-management/store`, data, LocalStorage.getAccessToken());
    }

    getDeductionListing() {
        return BaseApi.getWithParams(APIURL.API_URL + `payroll-config/expense-management/deduction/listing?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    } 
}

export default new DeductionApi();