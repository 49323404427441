import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

// const container = document.getElementById("root");

// const appTemplate = (
//   <React.StrictMode>
//     {/* <BrowserRouter> */}
//         <App />
//     {/* </BrowserRouter> */}
//   </React.StrictMode>
// );
ReactDOM.render(<App />, document.getElementById('root'));
// ReactDom.render(<App />, container);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
