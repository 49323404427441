import { useEffect } from "react"
import LocalStorage from "../../utils/LocalStorage"
import { useNavigate } from "react-router-dom"
import Grid from "@mui/material/Grid";
import loader from "../../assets/gif/japfuLoader.gif"
import { removeExpiredLoader } from "../../utils/utils";

export const CheckBilling = () => {
  const navigate = useNavigate()
  useEffect(() => {
    let planDetails = LocalStorage.getPlanCheckLocal()
    removeExpiredLoader()

    navigate('/billing', {
      state: {
        renew: true, status: (planDetails.is_subscription_cancelled && (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly')) ? 'upgrade' :
          ((planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') && (planDetails.subscription_expiring || planDetails.subscription_expired || !planDetails?.is_plan_auto_renewed)) ? 'renew' :
            (planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') ? 'advanceUpgrade' : (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly') ? 'activeUpgrade' : 'activeUpgrade',
        renewal: planDetails.subscription_expiring || planDetails.subscription_expired ? true : false,
        autoRenewAdvance: !planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly' ? false : true, autoRenewStarterMonthly: planDetails?.is_plan_auto_renewed ? false : true,
        autoRenew: (!planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly') ? true : false,
        autoRenewStarter: !planDetails?.is_plan_auto_renewed ? true : false
      }
    })
    removeExpiredLoader()
  })
  return (
    <Grid container justifyContent={'center'} alignContent={'center'} height={'100vh'}>
      <img src={loader} alt='loader' width={'100px'} height={'100px'} />
    </Grid>
  )
}