import APIURL from '../../../config/development';
import LocalStorage from '../../../utils/LocalStorage';
import BaseApi from '../../BaseApi';

class ExpenseManagementApi {
    getReimbursementDropdown() {
        return BaseApi.getWithParams(APIURL.API_URL + `payroll-config/expense-management/reimbursement/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    storeExpenseType(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `payroll-config/expense-management/store`, data, LocalStorage.getAccessToken());
    }

    storePayroll(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `expense-management/store`, data, LocalStorage.getAccessToken());
    }

    getReimbursementListing() {
        return BaseApi.getWithParams(APIURL.API_URL + `payroll-config/expense-management/reimbursement/listing?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    getdeductBalanceStatus() {
        return BaseApi.getWithParams(APIURL.API_URL + `payroll-config/reimbursement-config?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    updateStatus(data) {
        return BaseApi.putWithToken(APIURL.API_URL + `expense-management/update-status`, data, LocalStorage.getAccessToken())
    }

    payPeriodDropdown() {
        return BaseApi.getWithParams(APIURL.API_URL + `payroll-config/pay-period/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }
    downLoadExpenseExcel(data) {
        return BaseApi.postDownloadExcel(APIURL.API_URL + `expense-management/export`,data, LocalStorage.getAccessToken())
    }
    getBalanceSheetListing(param) {
        param.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.placement_URL + 'balance-sheet/listing', param, LocalStorage.getAccessToken())
    }
    getCarDetailBalanceSheet(emp_id){
        return BaseApi.getWithParams(APIURL.org_URL + `balance-sheet/employee-details?request_id=${LocalStorage.uid()}&employee_id=${emp_id}`, LocalStorage.getAccessToken())
    }
    getBalanceSheetIndex(param) {
        param.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.placement_URL + 'balance-sheet/employee-transactions', param, LocalStorage.getAccessToken())
    }
    downLoadBalanceSheetExcel(data) {
        return BaseApi.postDownloadExcel(APIURL.API_URL + `balance-sheet/export`,data, LocalStorage.getAccessToken())
    }
}

export default new ExpenseManagementApi();