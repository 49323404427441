import React, { useEffect, useRef, useState } from 'react'
import { Grid, Stack, Box, FormControlLabel, Checkbox, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses, IconButton, Popover, Skeleton } from "@mui/material";
import Text from '../../../../../components/customText/Text';
import MainStyles from '../../MainStyles'
import CustomMenu from '../../configComponents/customMenu/Menu'
import Button from '../../../../../components/customButton/Button';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Input from '../../../../../components/input/Input';
import RoleApi from '../../../../../apis/configurations/roleApi/RoleApi';
import LocalStorage from '../../../../../utils/LocalStorage';
import { BlackToolTip, NoDataFound, NoPermission, addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, removeLoader } from '../../../../../utils/utils';
import { isValid, onCharactersAndNumbersWithSpaceChange, validate_charWithSpace } from '../../../../../components/Validation';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { useLocation } from 'react-router-dom';
import CustomRoleTableStyles from '../../configComponents/dataTable/dataTable/CustomRoleTableStyles';
import { ReactComponent as CheckedIcon } from '../../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../../assets/svg/CheckedBorderIcon.svg';
import { ReactComponent as CheckedDisabled } from '../../../../../assets/svg/CheckedDisabled1.svg';
import { ReactComponent as CheckedBorderIconDisabled } from '../../../../../assets/svg/CheckboxDisabled.svg';
import { ReactComponent as MenuIcon } from '../../../../../assets/svg/MenuIcon.svg';
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import ConfigApi from '../../../../../apis/configurations/ConfigApi';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import Select from '../../../../../components/select/Select';
import ReusableAlert from '../../../../../components/reuablePopup/ReusableAlert';
import ReusableDelete from '../../../../../components/reuablePopup/ReusableDelete';
import blueLoader from '../../../../../assets/gif/japfuLoader.gif';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper ": {
    minHeight: '650px !important',
    padding: '0px !important',
    borderRadius: "12px",
    "@media (min-width: 900px) and (max-width: 1400px)": {
      minHeight: '420px !important',
    }
  },
  "& .MuiDialogContent-root": {
    padding: '0px !important',
    '&::-webkit-scrollbar': {
      display: 'none !important'
    },
  },
  "& .MuiDialogActions-root": {
    padding: '0px !important',
  },
  "& .MuiDialog-container": {
    background: 'rgba(0, 0, 0, 0.55) !important'
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F6F6F6 !important',
    color: '#171717 !important',
    borderRadius: '1px !important',
    font: '15px Quicksand !important',
    textAlign: 'left important',
  },
  [`&.${tableCellClasses.body}`]: {
    font: '14px Quicksand !important',
    textAlign: 'left !important',
    paddingTop: '4px !important',
    paddingBottom: '4px !important',
    color: '#262626 !important'
  },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "78px !important",
  borderBottom: "1pt solid #EAECF0 !important"
}));

//values for tablerow and table header
const Header = [
  {
    name: 'Feature',
    width: '20%',
  },
  {
    name: 'Full Access',
    width: '15%',
  },
  {
    name: 'Create',
    width: '15%',
  },
  {
    name: 'Edit',
    width: '15%',
  },
  {
    name: 'View',
    width: '15%',
  },
  {
    name: 'Delete',
    width: '15%',
  },
  // {
  //   name: 'More',
  //   width: '15%',
  // },
];

function Role({ current, setTab, setInternal }) {
  var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
  const classes = MainStyles()
  const customTableStyles = CustomRoleTableStyles();
  const routeState = useLocation();
  const [allPemissions, setAllPermissions] = useState({})
  const [state, setState] = useState({
    data: {
      name: "",
      description: "",
    },
    error: {},
    rows: [],
    checked: {},
    modules: {},
  })
  const [errors, setErrors] = useState({});
  const [action, setAction] = useState(true);
  const [editingIndex, setEditingIndex] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [clone, setClone] = useState("");
  const [cloneArr, setCloneArr] = useState([]);
  const [roles, setRoles] = useState([]);
  const [alert, setAlert] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [inactiveOpen, setInactiveOpen] = useState(false);
  const [inActiveData, setInactiveData] = useState(null);
  const [updateOpen, setUpdateOpen] = useState(false);// eslint-disable-next-line
  const [roleEdit, setRoleEdit] = useState(false);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    search: "",
  });

  const [total, setTotal] = useState();
  const [activityFilter, setActivityFilter] = useState({
    limit: 10,
    page: 1,
  })

  const [activityTotal, setActivityTotal] = useState()
  const activityRef = useRef(null);
  const [activityData, setActivityData] = useState([])
  const recentSection = useRef(null);
  const [recentActivityShow, setrecentActivityShow] = useState(false)
  const majorModules = ['Employee','Client','Vendor','Placement','Timesheet','End Client'];

  useEffect(() => {
    if( !LocalStorage.getStartTour()?.orgconfig){
    if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "roles_view" && item.is_allowed == true)))) {
      setTimeout(() => {
        setLoading(true)
        getRolesListing(filter);
        rolesDropdown();
        getAllPermissions();
      }, 300)
    }}// eslint-disable-next-line
  }, [])


  const scrollDown = () => {
    window.scroll({
      top: recentSection.current.scrollIntoView(),
      behavior: 'smooth'
    });
    setrecentActivityShow(false)
  }

  const getActivity = (args, popup) => {
    // setLoading(true)
    let id = 26;
    ConfigApi.getActivity(id, "", args).then((response) => {
      setTimeout(() => {
        if (response.data.statusCode == 1003) {
          // setLoading(false)
          setActivityData(response.data.data);
          setActivityTotal(response.data.pagination.total);
          if (popup) { }
          else {
            setrecentActivityShow(true)
          }
        }
      }, 300)
      removeLoader();
    });
  };

  const activityHandleScroll = () => {
    const { current } = activityRef;
    if (current) {
      const { scrollTop, scrollHeight, clientHeight } = current;
      if (scrollTop + clientHeight >= scrollHeight) {
        // getCategory({...filter})
        if (activityTotal >= activityFilter.limit) {
          addLoader(true);
          setActivityFilter({ ...activityFilter, limit: activityFilter.limit + 10, page: 1 })
          getActivity({ ...activityFilter, limit: activityFilter.limit + 10, page: 1, }, true);
        }
        // Reached the bottom of the inner box
        // console.log('Reached end of inner box, calling a function...');
      }
    }
  };

  const handleClone = (e) => {
    setClone(e.target.value);
    setAction(true);
    if (e.target.value != "") {
      getRolePermissions(e.target.value, 'clone');
    } else {
      if (editingIndex != null) {
        getAllPemissionsWithChecked(editingIndex);
      } else {
        setNewRolePermissions();
      }

    }
  }

  //handle input change for all fields
  const handleInputChange = (event) => {
    setState((prev) => ({ ...prev, "data": { ...state.data, [event.target.name]: event.target.value } }));
    handleValidate({ name: event.target.name, value: event.target.value });
  };

  const handleValidate = (input) => {
    switch (input.name) {
      case 'name':
        errors.name = validate_charWithSpace(input.value);
        break
      default:
        break
    }
    setErrors({ ...errors });
  }

  //   const [filter, setFilter] = useState({
  //     limit: 10,
  //     page: 1,
  //     search: "",
  // });

  // handle change for table to select and deselect all values
  const handlechangeTableSelectAll = (e, key) => {
    var trueArray = [];  //defining true array;
    // setAction(true);
    var keyName = routeState.state == null && action == false ? "checked" : "is_allowed";
    // var keyName = routeState.state == null && action == false ? "checked" : "is_allowed";
    var allLength = 0;
    // if (e.target.checked) {
    state.rows[key].totalcheck.totalchecked = e.target.checked;
    state.rows[key].checkBoxListrow.forEach((ele, index) => {
      if (ele.additional_permissions) {
        ele.additional_permissions.forEach((k, v) => {
          ele.additional_permissions[v][keyName] = e.target.checked;
          if (k[keyName] == true) {
            trueArray.push({ checked: true })
          }
        })
        allLength = state.rows[key].checkBoxListrow.length + ele.additional_permissions.length
      } else {
        state.rows[key].checkBoxListrow[index][keyName] = e.target.checked;
        if (ele[keyName] == true) {
          trueArray.push({ checked: true })
        }
      }
    });
    if (trueArray.length + 1 == allLength) {
      state.rows[key].totalcheck.totalchecked = true;
    } else {
      state.rows[key].totalcheck.totalchecked = false;
    }
    state.rows.forEach((each,index) => {
      if (majorModules.includes(each.title)) {
        each.checkBoxListrow[2][keyName] = true;
      }
    });
    setState({ ...state })
  };

  /**
   * Handles changes in checkbox states within a table.
   * 
   * @param {Event} event - The event object triggered by the checkbox change.
   * @param {number} key - The key/index of the row in the table.
   * @param {string} checkBox - The identifier of the checkbox in the row.
   */
  const handleChangeCheckboxTable = (event, key, checkBox) => {
    // console.log(event, "event");
    // console.log(key, "key");
    // console.log(checkBox, "checkBox");
    // console.log(state, "state");
    // Determine the key name based on certain conditions
    var keyName = routeState.state == null && action == false ? "checked" : "is_allowed";
    // Array to store elements with 'true' value
    var trueArray = [];
    if (state.rows[key].checkBoxListrow[checkBox].slug !== "dashboard_view") {
      var createPermission = "";
      var deletePermission = "";
      if (!["email_templates_edit", "email_templates_view", "notification_templates_view", "mivi_chat_bot_view", "working_hours_edit", "working_hours_view", "notification_templates_edit"].includes(state.rows[key].checkBoxListrow[checkBox].slug)) {
        createPermission = state.rows[key].checkBoxListrow.find(permission => permission.permission_name === "Create");
        deletePermission = state.rows[key].checkBoxListrow.find(permission => permission.permission_name === "Delete");
      }

      const editPermission = state.rows[key].checkBoxListrow.find(permission => permission.permission_name === "Edit");
      if (!["email_templates_edit", "email_templates_view", "mivi_chat_bot_view", "notification_templates_view", "notification_templates_edit"].includes(state.rows[key].checkBoxListrow[checkBox].slug)) {
        if (state.rows[key].checkBoxListrow[checkBox].permission_name !== "Create" && createPermission[`${keyName}`] === true && event.target.checked == false) {
          addErrorMsg("invalid response .");
          return false;
        }
        if (state.rows[key].checkBoxListrow[checkBox].permission_name === "Create" && deletePermission[`${keyName}`] === true) {
          addErrorMsg("invalid response .");
          return false;
        }
        if (state.rows[key].checkBoxListrow[checkBox].permission_name === "View" && (createPermission[`${keyName}`] === true || deletePermission[`${keyName}`] === true || editPermission[`${keyName}`] === true) && event.target.checked === false) {
          addErrorMsg("invalid response .");
          return false;
        }
        if (majorModules.includes(state.rows[key].title) && state.rows[key].checkBoxListrow[checkBox].permission_name === "View"){
          var check ;
          state.rows.forEach((each,index) => {
            if(majorModules.includes(each.title)){
              if(each.checkBoxListrow[0][keyName] || each.checkBoxListrow[1][keyName]){
                check = true
              }
            }
          })
          if(check){
            addErrorMsg("invalid response .");
            return false;
          }else{
            state.rows[key].checkBoxListrow[2][keyName] = event.target.checked
          }
        }

      }

      if (["email_templates_edit", "email_templates_view", "notification_templates_view", "notification_templates_edit"].includes(state.rows[key].checkBoxListrow[checkBox].slug)) {
        if (state.rows[key].checkBoxListrow[checkBox].permission_name === "View" && (editPermission[`${keyName}`] === true) && event.target.checked === false) {
          addErrorMsg("invalid response .");
          return false;
        }
      }

      if (state.rows[key].checkBoxListrow[checkBox].permission_name == "Edit") {
        if (event.target.checked == false) {
          state.rows[key].checkBoxListrow[checkBox][keyName] = event.target.checked;
          state.rows[key].checkBoxListrow.forEach((element, index) => {
            if (element[keyName] == true) {
              trueArray.push({ checked: true })
            }
          });
        } else {
          state.rows[key].checkBoxListrow.forEach((ele, index) => {
            if (state.rows[key].checkBoxListrow[index].permission_name == "View" || state.rows[key].checkBoxListrow[index].permission_name == "Edit") {
              state.rows[key].checkBoxListrow[index][keyName] = event.target.checked;
              if (ele[keyName] == true) {
                trueArray.push({ checked: true })
              }
            }
          });
          state.rows.forEach((each,index) => {
            if (majorModules.includes(each.title)) {
              each.checkBoxListrow[2][keyName] = true;
            }
          });
        }
      }

      if (!["email_templates_edit", "email_templates_view", "notification_templates_view", "notification_templates_edit"].includes(state.rows[key].checkBoxListrow[checkBox].slug)) {
        if (state.rows[key].checkBoxListrow[checkBox].permission_name == "Create" && event.target.checked === true) {
          state.rows[key].checkBoxListrow.forEach((ele, index) => {
            if (state.rows[key].checkBoxListrow[index].permission_name !== "Delete") {
              state.rows[key].checkBoxListrow[index][keyName] = event.target.checked;
              if (ele[keyName] == true) {
                trueArray.push({ checked: true })
              }
            }
          });
          state.rows.forEach((each,index) => {
            if (majorModules.includes(each.title)) {
              each.checkBoxListrow[2][keyName] = true;
            }
          });
        }
        if (state.rows[key].checkBoxListrow[checkBox].permission_name === "Create" && event.target.checked === false) {
          state.rows[key].checkBoxListrow[checkBox][keyName] = event.target.checked;
          state.rows[key].checkBoxListrow.forEach((element, index) => {
            if (element[keyName] == true) {
              trueArray.push({ checked: true })
            }
          });
        }
      }
    }

    state.rows[key].checkBoxListrow[checkBox][keyName] = event.target.checked;
    state.rows[key].checkBoxListrow.forEach((element, index) => {
      if (element[keyName] == true) {
        trueArray.push({ checked: true })
      }
    });
    if (trueArray.length == state.rows[key].checkBoxListrow.length) {
      state.rows[key].totalcheck.totalchecked = true;
    } else {
      state.rows[key].totalcheck.totalchecked = false;
    }
    setState({ ...state });
  };

  const handleChangeDeleteCheckboxTable = (event, key, checkBox) => {
    var keyName = routeState.state == null && action == false ? "checked" : "is_allowed";
    var trueArray = [];
    const createPermission = state.rows[key].checkBoxListrow.find(permission => permission.permission_name === "Delete");
    if (state.rows[key].checkBoxListrow[checkBox].permission_name != "Delete" && createPermission[`${keyName}`] == true && event.target.checked == false) {
      addErrorMsg("invalid response .");
      return false;
    }
    if (state.rows[key].checkBoxListrow[checkBox].permission_name == "Delete") {
      if (event.target.checked === true) {
        state.rows[key].checkBoxListrow.forEach((ele, index) => {
          if(index != 0){
            state.rows[key].checkBoxListrow[index][keyName] = event.target.checked
            state.rows.forEach((each,index) => {
              if (majorModules.includes(each.title)) {
                each.checkBoxListrow[2][keyName] = true;
              }
            });
            if (ele[keyName] == true) {
              trueArray.push({ checked: true })
            }
          }
        });
      } else {
        state.rows[key].checkBoxListrow[checkBox][keyName] = event.target.checked;
        state.rows[key].checkBoxListrow.forEach((element, index) => {
          if (element[keyName] == true) {
            trueArray.push({ checked: true })
          }
        });
      }
    }
    if (trueArray.length == state.rows[key].checkBoxListrow.length) {
      state.rows[key].totalcheck.totalchecked = true;
    } else {
      state.rows[key].totalcheck.totalchecked = false;
    }
    setState({ ...state });
  };

  const handleChangeCheckboxPopup = (e, index, key, ind) => {
    var keyName = routeState.state == null && action == false ? "checked" : "is_allowed";
    var trueArray = [];  //defining true array;

    state.rows[index].checkBoxListrow[key].additional_permissions[ind][keyName] = e.target.checked;

    state.rows[index].checkBoxListrow[key].additional_permissions.forEach((element, index) => {
      if (element[keyName] == true) {
        trueArray.push({ checked: true })
      }
    });
    if (trueArray.length == state.rows[index].checkBoxListrow[key].additional_permissions.length) {
      state.rows[index].totalcheck.totalchecked = true;
    } else {
      state.rows[index].totalcheck.totalchecked = false;
    }
    setState({ ...state })
  }

  const [updatedData, setUpdatedData] = useState(null);

  // handle submit for save button
  const handleSubmit = () => {
    var allRolesArray = [];
    let errors = validateAll();
    if (isValid(errors)) {
      var data = {
        request_id: LocalStorage.uid(),
        role_name: state.data.name,
        description: state.data.description,
        is_active: editingIndex != null ? state.data.is_active : 1,
        permissions: []
      }
      var keyName = routeState.state == null && action == false ? "checked" : "is_allowed";
      if (state.rows[0].title == 'Dashboard') {
        state.rows[0].checkBoxListrow[3].is_allowed = true
      }
      state.rows.forEach((module, key) => {
        module.checkBoxListrow.forEach((permission) => {
          if (Object.keys(permission)[0] == "additional_permissions") {
            const addArray = Object.values(permission)[0];
            addArray.forEach((item) => {
              if (item.permission_id != undefined) {
                data.permissions.push({
                  permission_id: item.permission_id,
                  is_allowed: item[keyName]
                })
              }
            })
          }
          else if (permission.slug == "dashboard_view") {
            data.permissions.push({
              permission_id: permission.permission_id,
              is_allowed: true
            })
          }
          else {
            if (permission.permission_id != undefined) {
              data.permissions.push({
                permission_id: permission.permission_id,
                is_allowed: permission[keyName]
              })
            }
          }
        })
      })

      setState({ ...state });

      Object.keys(state.modules).forEach((permission) => {
        for (let i = 0; i < state.modules[permission].length; i++) {
          if (Object.keys(state.modules[permission][i])[0] == "additional_permissions") {
            const addArray = Object.values(state.modules[permission][i])[0];
            addArray.forEach((item) => {
              if (item.permission_id != undefined) {
                allRolesArray.push({
                  permission_id: item.permission_id,
                  is_allowed: false,
                })
              }
            })
          } else {
            if (state.modules[permission][i].permission_id != undefined) {
              allRolesArray.push({
                permission_id: state.modules[permission][i].permission_id,
                is_allowed: false,
              })
            }
          }
        }
      })
      const allDataPermissionsSet = new Set(data.permissions.map(({ permission_id }) => permission_id));
      const combined = [
        ...data.permissions,
        ...allRolesArray.filter(({ permission_id }) => !allDataPermissionsSet.has(permission_id))
      ];
      data.permissions = combined;

      if (data.permissions.some(p => p.is_allowed === true)) {
        if (editingIndex != null) {
          setUpdateOpen(true);
          data.id = editingIndex;
          setUpdatedData(data);
        } else {
          createApiCall(data)
        }
      } else {
        setState((prev) => ({ ...prev }))
        addWarningMsg("please select the permissions")
      }
    } else {
      setState((prev) => ({ ...prev, error: errors }));
      setErrors(errors);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewClick = (item) => {
    if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "roles_view" && item.is_allowed == true)))) {
      setAction(true);
      setOpen(true);
      setAlert(false)
      setAnchorEl(null);
      setIsEditable(true);
      setRoleEdit(item.is_editable);
      setEditingIndex(item.id)
      getRolePermissions(item.id);
      setDialogTitle("Role Permissions");
    } else {
      addWarningMsg("You don't have permission to perform this action. Please contact the admin")
    }
  }
  // eslint-disable-next-line
  const handleEditClick = (item) => {
    if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "roles_edit" && item.is_allowed == true)))) {
      setOpen(true);
      setAlert(false)
      setAnchorEl(null);
      setIsEditable(false);
      setRoleEdit(false);
      getAllPemissionsWithChecked(item)
      setEditingIndex(item)
      setDialogTitle("Edit Role Permissions");
    } else {
      addWarningMsg("You don't have permission to perform this action. Please contact the admin")
    }
  }

  const handleDeleteClick = (item) => {
    if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "roles_delete" && item.is_allowed == true)))) {
      setOpen(true);
      setAlert(true);
      setEditingIndex(item);
    } else {
      addWarningMsg("You don't have permission to perform this action. Please contact the admin")
    }
  }

  const handleFormEdit = (item) => {
    if (isEditable) {
      setOpen(true);
      setAlert(false)
      setAnchorEl(null);
      setIsEditable(false);
      getAllPemissionsWithChecked(item)
      setDialogTitle("Edit Role Permissions")
    } else {
      handleSubmit();
    }
  }

  const handleDialogClose = () => {
    setOpen(false);
    setIsEditable(true);
    setEditingIndex(null)
    setClone("");
    setErrors({});
  };

  const handleAddNew = () => {
    setOpen(true);
    setAlert(false);
    setState({
      data: {
        name: "",
        description: "",
      },
      error: {},
      rows: [],
      checked: {},
      modules: {},
    })
    setNewRolePermissions();
    setRoleEdit(false);
    setIsEditable(false);
    setEditingIndex(null);
    setDialogTitle("Add New Role");
  }

  const deleteConfirm = (id) => {
    deleteApiCall(id);

  }

  const innerBoxRef = useRef(null);

  const handleScroll = () => {
    const { current } = innerBoxRef;
    if (current) {
      const { scrollTop, scrollHeight, clientHeight } = current;
      if (scrollTop + clientHeight >= scrollHeight) {
        // getCategory({...filter})
        if (total >= filter.limit) {
          setFilter({ ...filter, limit: filter.limit + 10, page: 1 })
          getRolesListing({ ...filter, limit: filter.limit + 10, page: 1, });
        }


        // Reached the bottom of the inner box
        // console.log('Reached end of inner box, calling a function...');
        // Call your function here
      }
    }
  };
  //Api integration starts here
  const getRolesListing = (args) => {
    // if (filter.limit < 10) {
    setLoading(true)
    // } else {
    //   setLoading(false)
    // }
    RoleApi.rolesList(args).then(response => {
      setTimeout(() => {
        setLoading(false)
        if (response.data.statusCode == 1003) {
          getActivity(activityFilter)
          setRoles(response.data.data);
          setTotal(response.data.pagination.total)
        } else {
          addErrorMsg(response.data.message);
        }
      }, 300)
    })
  }

  const getAllPermissions = async () => {
    var response = await RoleApi.getAllPermission()
    if (response.data.statusCode == 1003) {
      setAllPermissions(response.data.data);
      // console.log(response.data.data)
    } else {
      // console.log(response.data.message);
    }
  }

  const rolesDropdown = () => {
    RoleApi.rolesDropdown().then(response => {
      if (response.data.statusCode == 1003) {
        if (response.data.data.length > 0) {
          var newArr = [];
          for (var i in response.data.data) {
            if (response.data.data[i].id != 1 && response.data.data[i].value != 'Admin') {
              newArr.push(response.data.data[i])
            }
          }
          setCloneArr(newArr);
        } else {
          setCloneArr(response.data.data);
        }
      }
    })
  }
  // eslint-disable-next-line
  const handleStatus = (event, items) => {
    if (!items.is_editable) {
      return false
    }
    if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "roles_edit" && item.is_allowed == true)))) {
      if (event.target.checked) {
        items['is_active'] = event.target.checked
        updateRoleStatus(items)
      } else {
        items['is_active'] = event.target.checked;
        setInactiveData(items);
        setInactiveOpen(true);
      }
    } else {
      addWarningMsg("You don't have permission to perform this action. Please contact the admin")
    }
  }

  const updateRoleStatus = (item) => {
    const data = {
      request_id: LocalStorage.uid(),
      is_active: item.is_active
    };
    RoleApi.updateRoleStatus(item.id, data).then(response => {
      if (response.data.statusCode == 1003) {
        addSuccessMsg('Status Updated Successfully');
        setInactiveData(null);
        setInactiveOpen(false);
        getRolesListing(filter);
        rolesDropdown();
      } else {
        addErrorMsg(response.data.message);
      }
    })
  }

  const getRolePermissions = (args, clone) => {
    RoleApi.rolePermissions(LocalStorage.uid(), args).then(response => {
      if (response.data.statusCode == 1003) {
        var { is_active, role_name, description, permissions } = response.data.data;
        if (clone != undefined) {
          var dataObj = { ...state.data };
        } else { // eslint-disable-next-line
          var dataObj = { ...state.data, 'is_active': is_active ? is_active : '', 'name': role_name ? role_name : "", 'description': description ? description : "" };
        }
        let newRowArr = [];
        Object.keys(permissions).forEach((permission) => {
          for (let i = 0; i < permissions[permission].length; i++) {
            if (permissions[permission][i].is_allowed) {
              let obj = {
                title: permission,
                totalcheck:
                {
                  totalchecked: totalCheckReturn(permissions[permission]),
                  // checked1: totalcheckObjCount.true ? totalcheckObjCount.true < 5 ? true : false : false
                },
                checkBoxListrow: permissions[permission]
              }
              newRowArr.push(obj);
            }
          }
        })
        const finalRows = uniqByKeepLast(newRowArr, it => it.title);
        setState((prev) => ({ ...prev, "data": dataObj, "rows": finalRows, "modules": response.data.data.permissions }));
      }
      else {
        addErrorMsg(response.data.message)
        setState((prev) => ({ ...prev }));
      }
    })
  }

  const getAllPemissionsWithChecked = async (args) => {
    setLoader(true);
    var response = await RoleApi.rolePermissions(LocalStorage.uid(), args)
    if (response.data.statusCode == 1003) {
      let { role_name, description, permissions, is_active } = response.data.data;
      let dataObj = { ...state.data, 'name': role_name, 'description': description ? description : "", 'is_active': is_active ? 1 : 0 };
      let newRowArr = [];
      Object.keys(permissions).forEach((permission) => {
        for (let i = 0; i < permissions[permission].length; i++) {

          let obj = {
            title: permission,
            totalcheck:
            {
              totalchecked: totalCheckReturn(permissions[permission]),
            },
            checkBoxListrow: permissions[permission]
          }
          newRowArr.push(obj);
        }
      }
      )

      const finalRows = uniqByKeepLast(newRowArr, it => it.title);
      setState((prev) => ({ ...prev, "data": dataObj, "rows": finalRows, "modules": response.data.data.permissions }));
    }
    setLoader(false);
  }

  const setNewRolePermissions = () => {
    let dataObj = { ...state.data, 'name': "", 'description': "" };
    let newRowArr = [];
    let newPemissions = allPemissions
    Object.keys(newPemissions).forEach((permission) => {
      for (let i = 0; i < newPemissions[permission].length; i++) {
        if (newPemissions[permission][i]['additional_permissions'] !== undefined) {
          for (const addPermissions in newPemissions[permission][i]['additional_permissions']) {
            newPemissions[permission][i]['additional_permissions'][addPermissions].is_allowed = false;
            newPemissions[permission][i]['additional_permissions'][addPermissions].is_editable = true
          }
          continue
        }
        newPemissions[permission][i].is_allowed = false;
        newPemissions[permission][i].is_editable = true;
        let obj = {
          title: permission,
          totalcheck:
          {
            totalchecked: totalCheckReturn(newPemissions[permission]),
            // checked1: totalcheckObjCount.true ? totalcheckObjCount.true < 5 ? true : false : false
          },
          checkBoxListrow: newPemissions[permission]
        }
        newRowArr.push(obj)
      }
    }
    )
    // console.log(newPemissions)
    const finalRows = uniqByKeepLast(newRowArr, it => it.title);
    setState((prev) => ({ ...prev, "data": dataObj, "rows": finalRows, "modules": newPemissions }));
    // console.log(finalRows);
    // console.log(dataObj);
  }

  const updateApiCall = (data) => {
    if (editingIndex != null) {
      setLoading(true);
      RoleApi.updateRole(editingIndex, data).then((response) => {
        setTimeout(() => {
          setLoading(false);
          setUpdateOpen(false);
          if (response.data.statusCode == 1003) {
            addSuccessMsg(response.data.message);
            handleDialogClose();
            getRolesListing(filter);
          } else {
            addErrorMsg(response.data.message)
          }
        }, 600)
      })
    } else {
      // console.log("No index Selected")
    }
  }
  const createApiCall = (data) => {
    setLoading(true);
    RoleApi.storeRole(data).then((response) => {
      setTimeout(() => {
        setLoading(false);
        if (response.data.statusCode == 1003) {
          addSuccessMsg(response.data.message);
          getRolesListing(filter);
          handleDialogClose()
        } else {
          addErrorMsg(response.data.message)
        }
      }, 600)
    })
  }

  const [newAlertOpen, setNewAlertOpen] = useState(false);
  const deleteApiCall = (id) => {
    const data = { request_id: LocalStorage.uid() }
    RoleApi.deleteRole(data, id).then(response => {
      if (response.data.statusCode == 1003) {
        handleDialogClose();
        addSuccessMsg(response.data.message);
        getRolesListing(filter);
      } else if (response.data.statusCode == 1012) {
        handleDialogClose();
        setNewAlertOpen(true);
      } else {
        addErrorMsg(response.data.message);
      }
    })
  }

  //validation for save button
  const validateAll = () => {
    let { name } = state.data;
    let errors = {};
    errors.name = validate_charWithSpace(name);
    return errors;
  };

  // function for removing duplicate objects in array
  function uniqByKeepLast(data, key) {
    return [...new Map(data.map(x => [key(x), x])).values()]
  }

  // function for based all boolean values return true or false for total checkbox in row
  const totalCheckReturn = (args) => {
    var trueArray = [];
    var totalcheck = 0
    var keyName = routeState.state == null && action == false ? "checked" : "is_allowed";
    args.forEach((permission) => {
      if (Object.keys(permission)[0] == "additional_permissions") {
        const addArray = Object.values(permission)[0];
        addArray.forEach((item) => {
          totalcheck = totalcheck + 1
          if (item[keyName]) {
            trueArray.push(item[keyName]);
          }
        })
      } else {
        totalcheck = totalcheck + 1
        if (permission[keyName]) {
          trueArray.push(permission[keyName]);
        }
      }
    });
    if (totalcheck == trueArray.length) {
      return true;
    } else {
      return false;
    }
  }

  const ascendingOrder = (param) => {
    // console.log("param = ", param);
    const numAscending = param.sort((a, b) => a.permission_id - b.permission_id);

    if (numAscending.length > 0) {
      if (numAscending[0].slug === "dashboard_view") {
        numAscending.unshift({});
        numAscending.unshift({})
        numAscending.push({})
      }
      if (numAscending[0].slug === "mivi_chat_bot_view") {
        numAscending.unshift({});
        numAscending.unshift({})
        numAscending.push({})
      }
      if (["notification_templates_edit", "notification_templates_view", "email_templates_edit", "email_templates_view", "working_hours_view", "working_hours_edit"].includes(numAscending[0].slug)) {
        numAscending.unshift({})
        numAscending.push({})
      }
    }
    return numAscending;
    // const customOrderArray = ['Create', 'Edit', 'View', 'Delete'];

    // const customSorted = param.sort((a, b) => {
    //   const indexA = customOrderArray.indexOf(a.permission_name);
    //   const indexB = customOrderArray.indexOf(b.permission_name);

    //   return indexA - indexB;
    // });

    // return customSorted;
  }

  const handleCloseInActive = () => {
    setInactiveData(null);
    setInactiveOpen(false);
    getRolesListing(filter);
  }
  const Tablerow = (props) => {

    const { row, index } = props;

    const checkForFullAccess = () => {
      for (const obj in row.checkBoxListrow) {
        if (row.checkBoxListrow[obj]['additional_permissions'] !== undefined) {
          for (const permission in row.checkBoxListrow[obj]['additional_permissions']) {
            if (row.checkBoxListrow[obj]['additional_permissions'][permission].is_allowed === false) {
              return false
            }
          }
        }
        else if (row.checkBoxListrow[obj]['is_allowed'] === false) {
          return false
        }
      }
      return true
    }
    return (
      <StyledTableRow key={row} sx={{ background: isEditable ? "#F9F9F9" : "" }}>
        <StyledTableCell>
          <Text sx={{
            font: "14px Quicksand !important", fontWeight: `${700} !important`,
            "@media (min-width: 900px) and (max-width: 1400px)": {
              font: "12px Quicksand !important", fontWeight: `${700} !important`
            }
          }}>{row.title}</Text>
        </StyledTableCell>
        <StyledTableCell>
          {(row.title !== "Dashboard" && row.title !== "MiVi Chat Bot" && row.title !== "Working Hours") &&
            < Checkbox
              size="small"
              checked={checkForFullAccess()}
              // indeterminate={row.totalcheck.checked1}
              onClick={(event) => handlechangeTableSelectAll(event, index)}
              icon={isEditable ? <CheckedBorderIconDisabled /> : <CheckBorderIcon />}
              checkedIcon={isEditable ? <CheckedDisabled /> : <CheckedIcon />}
              disabled={isEditable}
            />
          }
        </StyledTableCell>
        {ascendingOrder(row.checkBoxListrow).map((ele, key) => (
          <>
            {
              ele.permission_name == "Create" ?
                <StyledTableCell key={ele.permission_id}>
                  <Checkbox size="small"
                    checked={action || routeState.state != null ? ele.is_allowed : ele.checked}
                    onClick={(event) => handleChangeCheckboxTable(event, index, key)}
                    icon={isEditable ? <CheckedBorderIconDisabled /> : <CheckBorderIcon />}
                    checkedIcon={isEditable ? <CheckedDisabled /> : <CheckedIcon />}
                    disabled={isEditable}
                  />
                </StyledTableCell>
                : ele.permission_name == "Edit" ?
                  <StyledTableCell key={ele.permission_id}>
                    <Checkbox size="small"
                      checked={action || routeState.state != null ? ele.is_allowed : ele.checked}
                      onClick={(event) => handleChangeCheckboxTable(event, index, key)}
                      icon={isEditable ? <CheckedBorderIconDisabled /> : <CheckBorderIcon />}
                      checkedIcon={isEditable ? <CheckedDisabled /> : <CheckedIcon />}
                      disabled={isEditable}
                    />
                  </StyledTableCell>
                  : ele.permission_name == "View" ?
                    ele.slug == "dashboard_view" ?
                      <StyledTableCell key={ele.permission_id}>
                        <Checkbox size="small"
                          checked={true}
                          disabled={true}
                          // onClick={(event) => handleChangeCheckboxTable(event, index, key)}
                          icon={isEditable ? <CheckedBorderIconDisabled /> : <CheckBorderIcon />}
                          checkedIcon={isEditable ? <CheckedDisabled /> : <CheckedIcon />}
                        />
                      </StyledTableCell>
                      :
                      <StyledTableCell key={ele.permission_id}>
                        <Checkbox size="small"
                          checked={action || routeState.state != null ? ele.is_allowed : ele.checked}
                          onClick={(event) => handleChangeCheckboxTable(event, index, key)}
                          icon={isEditable ? <CheckedBorderIconDisabled /> : <CheckBorderIcon />}
                          checkedIcon={isEditable ? <CheckedDisabled /> : <CheckedIcon />}
                          disabled={isEditable}
                        />
                      </StyledTableCell>
                    : ele.permission_name == "Delete" ?
                      <StyledTableCell key={ele.permission_id}>
                        <Checkbox size="small"
                          checked={action || routeState.state != null ? ele.is_allowed : ele.checked}
                          onClick={(event) => handleChangeDeleteCheckboxTable(event, index, key)}
                          icon={isEditable ? <CheckedBorderIconDisabled /> : <CheckBorderIcon />}
                          checkedIcon={isEditable ? <CheckedDisabled /> : <CheckedIcon />}
                          disabled={isEditable}
                        />
                      </StyledTableCell>
                      : <StyledTableCell></StyledTableCell>}

          </>
        ))}

        {row.checkBoxListrow.map((ele, key) => (
          (ele.additional_permissions && ele.additional_permissions.length > 0) &&
          <StyledTableCell>
            <PopupState variant="popover" popupId="demo-popup-popover" >
              {(popupState) => (
                <div>
                  <IconButton
                    {...bindTrigger(popupState)}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    // sx={{
                    //   transform: 'translateX(-50px) translateY(12px)',
                    // }}
                    className={customTableStyles.popover}
                  >
                    <Stack direction={'column'}>

                      {ele.additional_permissions.map((val, ind) => {
                        return (
                          <FormControlLabel
                            fullWidth
                            sx={{
                              m: 0,
                              width: '161px',
                              height: '46px',
                              fontSize: '12px',
                              '& .MuiFormControlLabel-label': {
                                font: '12px Nunito',
                                fontWeight: "500",
                                color: "#262626"
                              },
                            }}
                            key={ind}
                            label={<Text sx={{ font: "12px Quicksand !important", fontWeight: `${600} !important` }}>{val.permission_name}</Text>}
                            control={<Checkbox size="small"
                              checked={action || routeState.state != null ? val.is_allowed : val.checked}
                              onClick={(event) => handleChangeCheckboxPopup(event, index, key, ind, popupState)}
                              icon={isEditable ? <CheckedBorderIconDisabled /> : <CheckBorderIcon />}
                              checkedIcon={isEditable ? <CheckedDisabled /> : <CheckedIcon />}
                              disabled={isEditable}
                            />}
                          />
                        )
                      })
                      }
                    </Stack>
                  </Popover>
                </div>
              )}
            </PopupState>
          </StyledTableCell>
        ))
        }
      </StyledTableRow >
    )
  }

  const handleNavigateInternal = () => {
    setTab(2);
    setInternal(true);
    setNewAlertOpen(false);
  }

  return (
    <div>
      {LocalStorage.getStartTour()?.orgconfig ? NoDataFound():
        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "roles_view" && item.is_allowed == true))) ?
          <Box sx={{ height: '70vh', overflow: 'auto', padding: '16px' }}>
            <Box className={classes.activeItemBox}>
              <Box className={classes.activeBoxHeading}>
                {/* <Text sx={{ font: "15px Quicksand !important", fontWeight: "500 !important" }} >Roles</Text> */}

                <Grid container>
                  <Grid item lg={7} display={"flex"} justifyContent={'center'} flexDirection={"column"}>
                    <Text profileTitle>Employment {current}</Text>
                    <Text greysmallLabel pt={1}>*You can add max 5 new Role</Text>
                  </Grid>

                  {/* <Grid item lg={5}>
              {total > 10 ?
                <div className={classes.searchField}>
                  <input
                    type="text"
                    value={filter.search}
                    onChange={handleSearch}
                    className={classes.globalSearchInput}
                    placeholder="Search"
                  // onKeyUp={(e) => { e.key == 'Enter' && e.preventDefault(); }}
                  // onKeyDown={(e) => { e.key == 'Enter' && e.preventDefault(); }}
                  />
                  <button
                    type="button"
                    className={classes.searchIcon}
                  >
                    {filter.search.length == 0 ? <img src={Search} alt="Search" /> : <CloseRoundedIcon sx={{ cursor: "pointer" }}
                      onClick={closeBtn}
                    />}
                  </button>
                </div>
                :
                ""}

            </Grid> */}
                </Grid>
              </Box>
              <Box sx={{ height: '40vh', overflow: 'auto' }}
                ref={innerBoxRef}
                onScroll={handleScroll}
              // onScroll={handleInnerBoxScroll}
              >
                {
                  loading ?
                    <>
                      {[1, 2, 3].map((item, index) => (
                        <Grid spacing={2} container key={index} mt={3}>
                          <Grid item lg={4} md={4}>
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                          </Grid>
                          <Grid item lg={4} md={4}>
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                          </Grid>
                          <Grid item lg={2} md={2}>
                            <Skeleton animation="wave" height={40} />
                          </Grid>
                          <Grid item lg={1} md={1}>
                            <Skeleton animation="wave" />
                          </Grid>
                        </Grid>
                      ))}
                    </> :
                    <Box sx={{ height: '35vh', overflow: 'auto' }}>
                      {roles.map((item, index) => (
                        <Box className={classes.descriptionBoxStyle} key={index} mb={2}>
                          <Grid container alignItems="center">
                            <Grid item lg={7} md={6} sm={6} xs={12} container direction={'column'} gap={1}>
                              <Text mediumBlackColor> {item.name}</Text>
                              <Text greyLabel>
                                <BlackToolTip arrow title={item.description}>{item.description ? item.description.length > 40 ? item.description.slice(0, 40) + '...' : item.description : "--"}
                                </BlackToolTip>
                              </Text>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={12} container direction={'column'} gap={1}>
                              <Text mediumBlackColor>Created By</Text>
                              <Text greyLabel>{item.created_by && item.created_by.length > 16 ?
                                <BlackToolTip title={item.created_by} placement="top" arrow>
                                  {item.created_by.slice(0, 16) + (item.created_by.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                : item.created_by ? item.created_by : '--'
                              }</Text>
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={12} container alignItems={'center'}>
                              {/* <ToggleSwitch switchChange={(e) => handleStatus(e, item)} isActive={item.is_active} sx={{ height: '24px !important' }} /> */}
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={12} container alignItems={'center'} justifyContent={'center'}>
                              <CustomMenu
                                anchorEl={anchorEl}
                                isOpen={Boolean(anchorEl)}
                                onClose={handleClose}
                                children={
                                  ((LocalStorage.getUserData().super_admin && item.is_editable) || ((rolePermission !== "" && rolePermission.some(item => item.slug == "roles_delete" && item.is_allowed == true)) && item.is_editable)) ?
                                    [
                                      { label: "View", Click: () => handleViewClick(item) },
                                      // { label: "Edit", Click: () => item.is_editable ? handleEditClick(item.id) : '' },
                                      { label: "Delete", Click: () => handleDeleteClick(item.id) }
                                    ]
                                    :
                                    [{ label: "View", Click: () => handleViewClick(item) }]
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      ))
                      }
                    </Box>
                }
                {
                  roles.length >= 0 && roles.length < 10 &&
                  <>
                    {
                      (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "roles_create" && item.is_allowed == true))) ?
                        total >= 10 ? '' :
                          <Button blueHoverBtn onClick={handleAddNew}>Add New Role</Button> :
                        <Button addTitleBtnDisable>Add New Role</Button>
                    }
                  </>
                }
              </Box>
              {/*   <AlertBox handleDialogClose={handleDialogClose} handleDelete={() => { deleteConfirm(editingIndex) }} open={open} /> */}
              {
                alert ?
                  <ReusableDelete newDelete={true} content={"Do you want to delete this employment role?"} open={open} setOpen={setOpen} onClick={() => { deleteConfirm(editingIndex) }} />
                  :
                  <BootstrapDialog
                    keepMounted
                    onClose={handleDialogClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth={true}
                    maxWidth={"md"}
                  >
                    <DialogContent >
                      <Box sx={{
                        padding: '38px 30px 35px 30px',
                        "@media (min-width: 900px) and (max-width: 1400px)": {
                          padding: '50px',
                        }
                      }}>
                        <Grid container spacing={4}>
                          <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Text mediumViewAmt>{dialogTitle}</Text>
                          </Grid>
                          {/* <Grid item lg={6} md={6} sm={6} xs={12} textAlign={"end"}>
                      {roleEdit ? <img onClick={() => { setRoleEdit(false); setIsEditable(false) }} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} /> : ""}
                    </Grid> */}
                        </Grid>
                        <Box mt={'32px'} mb={'32px'}>
                          {
                            isEditable ?
                              <Grid container spacing={4}>
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                  <Text mediumGreyHeader1>Name</Text>
                                  <Text mediumBlack14 pt={0.8} noWrap>{state.data.name ? state.data.name : "--"}</Text>
                                </Grid>

                                <Grid item lg={9} md={9} sm={12} xs={12}>
                                  <Text mediumGreyHeader1>Description (Optional)</Text>
                                  <Text mediumBlack14 pt={0.8} noWrap>{state.data.description ? state.data.description : "--"}</Text>
                                </Grid>
                              </Grid>
                              :
                              <Grid container spacing={4}>
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'name',
                                      value: state.data.name,
                                      type: 'text',
                                      disabled: isEditable,
                                      inputProps: { maxLength: 33 }
                                    }}
                                    disabled={isEditable}
                                    clientInput
                                    labelText={'Name'}
                                    handleChange={handleInputChange}
                                    onKeyPress={onCharactersAndNumbersWithSpaceChange}
                                    error={errors.name}
                                  />
                                  {errors.name ? <Text red>{errors.name}</Text> : ""}
                                </Grid>
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                  <Box >
                                    <Select
                                      label={'Clone As'}
                                      name='clone'
                                      value={clone}
                                      onChange={handleClone}
                                      options={cloneArr}
                                      disabled={isEditable}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'description',
                                      value: state.data.description,
                                      type: 'text',
                                      disabled: isEditable,
                                      inputProps: { maxLength: 100 }
                                    }}
                                    disabled={isEditable}
                                    clientInput
                                    labelText={'Description (Optional)'}
                                    handleChange={handleInputChange}
                                  />
                                </Grid>
                              </Grid>
                          }
                        </Box>
                        <Box>
                          {/* <CustomRowTable rolesIndex={rolesIndex} isEditable={isEditable} /> */}
                          <TableContainer className={customTableStyles.tableContainer}>
                            <Table stickyHeader style={{ "borderCollapse": "collapse" }}>
                              <TableHead>
                                {Header.map((head, index) => (
                                  <StyledTableCell width={head.width} key={index}><Text sx={{
                                    font: "15px Quicksand !important", fontWeight: `${500} !important`,
                                    "@media (min-width: 900px) and (max-width: 1400px)": {
                                      font: "14px Quicksand !important", fontWeight: `${500} !important`
                                    }
                                  }}>{head.name}</Text></StyledTableCell>
                                ))}
                              </TableHead>
                              {
                                loader ?
                                  <TableBody >
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>
                                      <img src={blueLoader} height={100} width={100} alt='loading' />
                                    </StyledTableCell>
                                  </TableBody>
                                  :
                                  <TableBody>
                                    {state.rows.map((row, index) => (<>
                                      {!['MiVi Chat Bot','Working Hours','App Integrations'].includes(row.title) && <Tablerow row={row} index={index} />}
                                    </>
                                     
                                    ))}
                                  </TableBody>
                              }
                            </Table>
                          </TableContainer>
                        </Box>
                        {
                          isEditable ? '' :
                            <Stack justifyContent="flex-end" direction="row" gap={'20px'} mt={'30px'}>
                              <Button sx={{ width: "108px !important" }} cancelOutlineBlue onClick={handleDialogClose}>Cancel</Button>
                              <LoadingButton saveBtn loading={loading} onClick={() => handleFormEdit(editingIndex)}>Save</LoadingButton>
                            </Stack>
                        }
                      </Box>
                    </DialogContent>
                  </BootstrapDialog>
              }
              {
                inactiveOpen && <ReusableAlert open={inactiveOpen} handleClose={handleCloseInActive} onClick={() => { updateRoleStatus(inActiveData) }} />
              }
              {
                updateOpen && <ReusableDelete loading={loading} button={true} content={"By editing this role the new changes will be associated with the users who have perimission"} open={updateOpen} setOpen={setUpdateOpen} onClick={() => { updateApiCall(updatedData) }} />
              }
              {newAlertOpen && <ReusableDelete navigate={true} button={true} open={newAlertOpen} setOpen={setNewAlertOpen} onClick={() => { handleNavigateInternal() }} />}
            </Box>
            <Box className={classes.activeItemBox} mt={4} mb={6} ref={recentSection}>
              <Box mb={3}>
                <Text cardTitle>Recent Activities</Text>
              </Box>
              <Box sx={{ height: '40vh', overflowY: 'auto' }}
                ref={activityRef}
                onScroll={activityHandleScroll}>
                {loading ?
                  <>
                    {[1, 2, 3].map((item, index) => (
                      <Grid container key={index} mt={3}>
                        <Grid item lg={8}>
                          <Skeleton animation="wave" width="200px" />
                          <Skeleton animation="wave" width="200px" />
                        </Grid>
                        <Grid item lg={3}>
                          <Skeleton animation="wave" width="200px" />
                          <Skeleton animation="wave" width="200px" />
                        </Grid>
                      </Grid>
                    ))}
                  </> :
                  <>
                    {activityData.length > 0 ?
                      <>
                        {activityData.map((value) => (
                          <Box className={classes.descriptionBoxStyle} mb={2}>
                            <Grid container spacing={6}>
                              <Grid container item lg={4} md={4} sm={6} xs={12} alignItems={"center"}>
                                <Text mediumBlackColor>{
                                  value.user_name && value.user_name.length > 16 ?
                                    <BlackToolTip title={value.user_name} placement="top" arrow>
                                      {value.user_name.slice(0, 16) + (value.user_name.length > 16 ? "..." : "")}
                                    </BlackToolTip>
                                    : value.user_name
                                } {value.action}<br /><Text greyLabel mt={"5px"}>{value.created_at}</Text></Text>
                              </Grid>
                              <Grid item lg={8} md={8} sm={6} xs={12} display={"flex"} alignItems={"center"}>
                                {
                                  value.change_log.length > 0 && value.change_log.map((item) => (
                                    <Text mediumGrey2>{item}.</Text>
                                  ))
                                }
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      </> :
                      <>
                        {NoDataFound("", "No Data Found", "50%", "35")}
                      </>
                    }
                  </>
                }
              </Box>
            </Box>
            {recentActivityShow ?
              <Grid container xs={6} justifyContent={'center'}>
                <Button addButton
                  // scrollBtn
                  sx={{
                    position: 'fixed',
                    bottom: 20,
                    borderRadius: "30px !important",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    font: "14px Quicksand !important",
                    fontWeight: `${700} !important`,
                  }}
                  onClick={scrollDown}
                  endIcon={<ArrowDownwardRoundedIcon sx={{ width: "24px" }} />}>New Activity</Button>
              </Grid>
              : ''}
          </Box>
          :
          <>
            {NoPermission()}
          </>
      }
    </div>
  )
}

export default Role;
