import APIURL from "../../../config/development";
import LocalStorage from "../../../utils/LocalStorage";
import BaseApi from "../../BaseApi";

class EverifyApi {
    Login(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `employee/e-verify/login`, data, LocalStorage.getAccessToken())
    }
    documentTypes(citizen_type) {
        return BaseApi.getWithParams(APIURL.org_URL + `e-verify/document-types?request_id=${LocalStorage.uid()}&citizen_type=${citizen_type}`, LocalStorage.getAccessToken())
    }
    storeCase(id, data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `employee/e-verify/create-case/${id}`, data, LocalStorage.getAccessToken())
    }
    updateCase(id, data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `employee/e-verify/update-case/${id}`, data, LocalStorage.getAccessToken())
    }
    ssnduplicateCheck(ssn, id) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/e-verify/duplicate-check?request_id=${LocalStorage.uid()}&ssn=${ssn}&employee_id=${id}`, LocalStorage.getAccessToken())
    }
    everifyLogin() {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/e-verify/check-login?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }
    caseCloselist(case_number) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/e-verify/case-closure-reasons/${case_number}?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }
    caseCloseApi(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `employee/e-verify/close-case`, data, LocalStorage.getAccessToken())
    }
    everifyIndex(id) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/e-verify/details/${id}?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }
    refreshApi(id) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/e-verify/refresh-status?employee_id=${id}&request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }
    noActionApi(id, data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `employee/e-verify/no-action/${id}`, data, LocalStorage.getAccessToken())
    }
    photoMatch(id, data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `employee/e-verify/photo-match/${id}`, data, LocalStorage.getAccessToken())
    }
    scanandUpload(id, data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `employee/e-verify/scan-and-upload/${id}`, data, LocalStorage.getAccessToken())
    }
    referCase(case_id, data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `employee/e-verify/refer/${case_id}`, data, LocalStorage.getAccessToken());
    }
}

export default new EverifyApi();