import React from 'react'
import VendorProfileStyles from './VendorProfileStyles';
import { Box, Grid, Divider } from '@mui/material';
import Text from '../../../../components/customText/Text';
import Input from '../../../../components/input/Input';
import { useState } from 'react';
import { empty_name, isValidMulti, validate_Extension_Optional, validate_charWithSpace, validate_contact_number, validate_optional_contact, validates_emailId } from '../../../../components/Validation';
import Button from '../../../../components/customButton/Button';
import { useEffect } from 'react';
import { BlackToolTip, addErrorMsg, addSuccessMsg, addWarningMsg, capitalize, capitalizeAndAddSpace } from '../../../../utils/utils';
import LocalStorage from '../../../../utils/LocalStorage';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import EditIcon from '../../../../assets/svg/newEdit.svg';
import ClientsApi from '../../../../apis/admin/clients/ClientsApi';
import Sure from '../../../../assets/svg/ExpenseDeleteIcon.svg';
import { ReactComponent as Plus } from '../../../../assets/svg/plus.svg';
import deleteIcon from '../../../../assets/client/minus-circle.svg';
import ToggleSwitch from '../../../../components/toggle/CustomToggle';
import noDataFound from '../../../../assets/svg/NoDataFoundIcon.svg';
import LoadingScreen from '../../employees/userprofile/document/LoadingScreen';
import changePrimaryImg from '../../../../assets/svg/clients/change-primary.svg';
import LoaderButton from '../../../../components/customButton/LoadingButton';

function ContactDetails(props) {
    const { id, actionContact, setActionContact, status, getActivity, loading, setLoading, getCompanyDetails } = props;
    const classes = VendorProfileStyles();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const [clearPopup, setclearPopup] = useState(false);
    const [Index, setIndex] = useState('');
    const [formData, setFormData] = useState([]);
    const [erro, setErro] = useState([]);
    const [deleteIds, setDeleteIds] = useState([]);
    const [changePrimary, setChangePrimary] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    // eslint-disable-next-line
    const usMobileNumberValidator = new RegExp(/^[a-zA-Z@~`!@#$%^&* ()_=+\\';:"\/?>.<,-]*$/);

    useEffect(() => {
        indexApi(); // eslint-disable-next-line              
    }, [])

    const openAddForm = (args, index) => {
        props.setformEdit(true)
        if (args == 'add') {
            let obj = {
                id: '',
                first_name: "",
                middle_name: "",
                last_name: "",
                telephone_number: "",
                email_id: "",
                telephone_number_ext: "",
                mobile_number: "",
                is_primary: formData.length == 0 ? true : false,
                is_edit: false,
            };
            if (formData.length > 0) {
                let errors = validateAll();
                if (isValidMulti(errors)) {
                    formData.unshift(obj);
                    setFormData([...formData]);
                    setActionContact('disable');
                } else {
                    let s1 = { erro }
                    s1 = errors;
                    setErro(s1);
                    addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
                }
            } else {
                formData.unshift(obj);
                setFormData([...formData]);
                setActionContact('disable');
                setErro([]);
            }
        }
        else if (args == 'remove') {
            if (formData[index].id !== '' || formData[index].id !== undefined || formData[index].id !== null) {
                if (formData[index]['is_primary'] == true) {
                    setclearPopup(true);
                } else {
                    deleteIds.push(formData[index].id);
                    setDeleteIds([...deleteIds]);
                    formData.splice(index, 1);
                    setFormData([...formData]);
                }
                setIndex(index);
            } else {
                formData.splice(index, 1);
                setFormData([...formData]);
            }
            setFormData([...formData]);
        }
    }

    const editAction = (index) => {
        props.setformEdit(true)
        formData[index]['is_edit'] = false;
        setFormData([...formData]);
    }

    const deleteContact = () => {
        if (Index == 1) {
            formData[0].is_primary = true;
        } else if (Index == 0) {
            formData[Index + 1].is_primary = true;
        }
        else {
            formData[Index - 1].is_primary = true;
        }
        formData.splice(Index, 1);
        setFormData([...formData]);
        setclearPopup(false);
    }

    const changeHandler = (e, index) => {
        if (e.target.name === "mobile_number" || e.target.name === "telephone_number") {
            convertFormat(e, index)
        }
        else if (e.target.name == 'is_primary') {
            if (formData[index]['is_primary'] == false) {
                setChangePrimary(true);
                setIndex(index);
            } else {
                if (index == 1) {
                    formData.forEach(data => {
                        data['is_primary'] = false;
                    });
                    formData[index - 1]['is_primary'] = true
                } else if (index == 0) {
                    formData[index]['is_primary'] = false
                    formData[index + 1]['is_primary'] = true
                } else {
                    formData.forEach(data => {
                        data['is_primary'] = false;
                    });
                    formData[index]['is_primary'] = e.target.checked
                    formData[index - 1]['is_primary'] = !e.target.checked
                }
            }
            setFormData([...formData]);
            handleValidations(e, index);
        }
        else if (e.target.name == 'first_name' || e.target.name == 'last_name' || e.target.name == 'middle_name') {
            formData[index][e.target.name] = capitalize(e.target.value);
            setFormData([...formData]);
            handleValidations(e, index);
        }
        else {
            formData[index][e.target.name] = e.target.value
            setFormData([...formData]);
            handleValidations(e, index);
        }
    }

    const changePrimaryHandler = () => {
        formData.forEach(data => {
            data['is_primary'] = false;
        });
        formData[Index].is_primary = true;
        setFormData([...formData]);
        setChangePrimary(false);
    }

    const convertFormat = (e, index) => {
        const value = e.target.value;
        const name = e.target.name
        const input = value.replace(/\D/g, '').substring(0, 10);
        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'mobile_number' || name == 'telephone_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'mobile_number' || name == 'telephone_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'mobile_number' || name == 'telephone_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > (name == 'mobile_number' || name == 'telephone_number' ? 6 : 5)) {
            formData[index][e.target.name] = `${first}-${middle}-${last}`
            setFormData([...formData], handleValidations(e, index))
        }
        else if (input.length > 3) {
            formData[index][e.target.name] = `${first}-${middle}`
            setFormData([...formData], handleValidations(e, index))
        }
        else if (input.length >= 0) {
            formData[index][e.target.name] = input
            setFormData([...formData], handleValidations(e, index))
        }
    }

    const handleValidations = (e, index) => {
        let input = e.target;
        let error = erro.length > 0 ? (erro ? erro[index] : erro) : erro;
        for (var k = 0; k <= index; k++) {
            erro.push({});
        }
        let s2 = erro.length > 0 ? [...erro] : [{ ...erro }];
        switch (input.name || input.tagName) {
            case "first_name":
                error.first_name = validate_charWithSpace(input.value, 'first ');
                break;
            case "middle_name":
                error.middle_name = empty_name(input.value, 'middle ');
                break;
            case "last_name":
                error.last_name = validate_charWithSpace(input.value, 'last ');
                break;
            case "email_id":
                error.email_id = validates_emailId(input.value);
                break;
            case "telephone_number_ext":
                error.telephone_number_ext = validate_Extension_Optional(input.value);
                break;
            case "mobile_number":
                if (input.value == '' && formData[index].telephone_number == '') {
                    error.mobile_number = ''
                } else if (input.value == formData[index].telephone_number) {
                    error.mobile_number = 'mobile number and phone number should not be the same'
                } else {
                    error.mobile_number = usMobileNumberValidator.test(input.value) ? '' : validate_optional_contact(input.value, "mobile");
                }
                break;
            case "telephone_number":
                if (input.value == '' && formData[index].mobile_number == '') {
                    error.telephone_number = ''
                } else if (input.value == formData[index].mobile_number) {
                    error.telephone_number = 'phone number and Mobile number should not be the same'
                } else {
                    error.telephone_number = usMobileNumberValidator.test(input.value) ? '' : validate_contact_number(input.value, "phone number");
                }
                break;
            default:
                break;
        }
        setErro(s2);
    }

    const validateAll = () => {
        let errorsus = {};
        let err = [];
        formData.map((value) => {
            errorsus = {};
            errorsus.first_name = validate_charWithSpace(value.first_name, "first ");
            errorsus.last_name = validate_charWithSpace(value.last_name, "last ");
            errorsus.middle_name = empty_name(value.middle_name, "middle ");
            errorsus.email_id = validates_emailId(value.email_id);
            errorsus.telephone_number = validate_contact_number(value.telephone_number, "phone Number");
            errorsus.mobile_number = validate_optional_contact(value.mobile_number, "mobile");
            errorsus.telephone_number_ext = validate_Extension_Optional(value.telephone_number_ext);
            return err.push(errorsus);
        });
        return err;
    }

    const indexApi = () => {
        setLoading(true);
        ClientsApi.contactIndex(id, 'vendor').then((res) => {
            if (res.data.statusCode == 1003) {
                setLoading(false);
                if (res.data.data.length > 0) {
                    for (var i in res.data.data) {
                        res.data.data[i]['is_edit'] = true;
                    }
                    setFormData([...res.data.data]);
                    setDeleteIds([]);
                } else {
                    setFormData([...res.data.data]);
                    setDeleteIds([]);
                }
            } else {
                setLoading(false);
            }
        })
    }

    const handleSubmit = () => {
        let errors = validateAll();
        if (isValidMulti(errors)) {
            const data = {
                request_id: LocalStorage.uid(),
                company_id: id,
                contacts: formData,
                deleted_contact_ids: deleteIds
            }
            setButtonLoader(true);
            ClientsApi.storeContact(data, 'vendor').then((res) => {
                setButtonLoader(false);
                if (res.data.statusCode === 1003) {
                    addSuccessMsg(res.data.message);
                    indexApi();
                    props.setformEdit(false)
                    handleCancel();
                    setDeleteIds([]);
                } else {
                    addErrorMsg(res.data.message);
                }
            })
        } else {
            let s1 = { erro }
            s1 = errors;
            setErro(s1);
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
    }

    const handleCancel = () => {
        props.setformEdit(false)
        setActionContact('view');
        indexApi();
        getActivity();
        setErro([]);
        getCompanyDetails();
    }

    return (
        <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                    <Text largeBlue> Contact Details </Text>
                    {
                        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_edit" && item.is_allowed == true))) ?
                            (status === 'In Active' || loading) ? '' : formData.length > 0 && formData.length < 5 && <Button addNew startIcon={<Plus />} onClick={() => openAddForm('add')}>Add New Contact</Button> : ""
                    }
                </Box>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} mt={2} padding={2} className={classes.boxShadow}>
                <Box className={classes.contentScroll} p={'10px'}>
                    <>
                        {
                            loading ?
                                <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                                    <LoadingScreen />
                                </Grid>
                                :
                                formData.length > 0 ?
                                    formData.map((item, index) => (
                                        actionContact == 'view' ?
                                            <Grid item container lg={12} spacing={3}>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                                                        <Text largeBlue> Contact Details {` - ${index + 1}`} {item.is_primary == true ? ' (Primary)' : ''}</Text>
                                                        {
                                                            (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_edit" && item.is_allowed == true))) ?
                                                                status === 'In Active' ? '' : index == 0 && <img src={EditIcon} alt='Edit' className={classes.cursor} onClick={() => { setActionContact('update'); setErro([]); props.setformEdit(true) }} /> : ""
                                                        }
                                                    </Box>
                                                </Grid>
                                                <Grid item container lg={12}>
                                                    <Grid item lg={3} md={4} sm={4} xs={12}>
                                                        <Box p={1} pt={2}>
                                                            <Text mediumGreyHeader> First Name</Text>
                                                            <Text pt={1} mediumBlack14>
                                                                {
                                                                    item.first_name ? item.first_name.length > 16 ?
                                                                        <BlackToolTip title={capitalizeAndAddSpace(item.first_name)} placement="top" arrow>
                                                                            {capitalizeAndAddSpace(item.first_name.slice(0, 16)) + capitalizeAndAddSpace(item.first_name.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        : capitalizeAndAddSpace(item.first_name) : '--'
                                                                }
                                                            </Text>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={3} md={4} sm={4} xs={12}>
                                                        <Box p={1} pt={2}>
                                                            <Text mediumGreyHeader> Middle Name (Optional)</Text>
                                                            <Text pt={1} mediumBlack14>
                                                                {
                                                                    item.middle_name ? item.middle_name.length > 16 ?
                                                                        <BlackToolTip title={capitalizeAndAddSpace(item.middle_name)} placement="top" arrow>
                                                                            {capitalizeAndAddSpace(item.middle_name.slice(0, 16)) + capitalizeAndAddSpace(item.middle_name.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        : capitalizeAndAddSpace(item.middle_name) : '--'
                                                                }
                                                            </Text>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={3} md={4} sm={4} xs={12}>
                                                        <Box p={1} pt={2}>
                                                            <Text mediumGreyHeader> Last Name</Text>
                                                            <Text pt={1} mediumBlack14>
                                                                {
                                                                    item.last_name ? item.last_name.length > 16 ?
                                                                        <BlackToolTip title={capitalizeAndAddSpace(item.last_name)} placement="top" arrow>
                                                                            {capitalizeAndAddSpace(item.last_name.slice(0, 16)) + capitalizeAndAddSpace(item.last_name.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        : capitalizeAndAddSpace(item.last_name) : '--'
                                                                }
                                                            </Text>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container lg={12}>
                                                    <Grid item lg={3} md={4} sm={4} xs={12}>
                                                        <Box p={1} pt={2}>
                                                            <Text mediumGreyHeader> Email ID</Text>
                                                            <Text pt={1} mediumBlack14>
                                                                {
                                                                    item.email_id ? item.email_id.length > 20 ?
                                                                        <BlackToolTip title={item.email_id} placement="top" arrow>
                                                                            {item.email_id.slice(0, 20) + (item.email_id.length > 20 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        : item.email_id : '--'
                                                                }
                                                            </Text>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={3} md={4} sm={4} xs={12}>
                                                        <Box p={1} pt={2}>
                                                            <Text mediumGreyHeader> Phone Number</Text>
                                                            <Text pt={1} mediumBlack14>{item.telephone_number ? item.telephone_number : '--'}</Text>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={3} md={4} sm={4} xs={12}>
                                                        <Box p={1} pt={2}>
                                                            <Text mediumGreyHeader>Extension</Text>
                                                            <Text pt={1} mediumBlack14>{item.telephone_number_ext ? item.telephone_number_ext : '--'}</Text>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Box p={1} pt={2}>
                                                        <Text mediumGreyHeader> Mobile Number (Optional)</Text>
                                                        <Text pt={1} mediumBlack14>{item.mobile_number ? item.mobile_number : '--'}</Text>
                                                    </Box>
                                                </Grid>
                                                {
                                                    formData.length - 1 == index ? '' :
                                                        <Divider className={classes.divider} />
                                                }
                                            </Grid> : actionContact == 'update' || actionContact == 'disable' ?
                                                <Grid item container spacing={3}>
                                                    <Grid item lg={12} container pb={2} alignItems='center'>
                                                        <Grid item lg={8} md={8} sm={8} xs={8} >
                                                            {
                                                                actionContact == 'disable' && index == 0 ? <Text largeBlue>New Contact Details</Text> :
                                                                    <Text largeBlue>Contact Details {` - ${index + 1}`} {item.is_primary == true ? '(Primary)' : ''}</Text>
                                                            }
                                                        </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4} textAlign='end'>
                                                            {
                                                                (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_edit" && item.is_allowed !== true))) ? "" :
                                                                    actionContact === 'disable' ?
                                                                        <>
                                                                            {
                                                                                formData.length == 1 ? '' :
                                                                                    item.id === '' || status === 'In Active' ? '' : !item.is_edit ? <img src={deleteIcon} alt='delete' className={classes.cursor} onClick={() => openAddForm('remove', index)} /> : < img src={EditIcon} alt='Edit' className={classes.cursor} onClick={() => editAction(index)} />
                                                                            }
                                                                        </>
                                                                        :
                                                                        formData.length == 1 ? '' : item.id === '' ? '' : <img src={deleteIcon} alt='delete' className={classes.cursor} onClick={() => openAddForm('remove', index)} />
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container lg={12} spacing={3} p={1} pt={0}>
                                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'first_name',
                                                                    value: item.first_name,
                                                                    inputProps: { maxLength: 33 }
                                                                }}
                                                                disabled={(actionContact == 'disable' && index == 0) || !item.is_edit || actionContact == 'update' ? false : true}
                                                                error={erro[index] && erro[index].first_name}
                                                                handleChange={(e) => { changeHandler(e, index) }}
                                                                clientInput
                                                                labelText='First Name'
                                                            />
                                                            <Text errorText> {erro[index] ? erro[index].first_name : ""}</Text>
                                                        </Grid>
                                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'middle_name',
                                                                    value: item.middle_name,
                                                                    inputProps: { maxLength: 33 }
                                                                }}
                                                                disabled={index == 0 || !item.is_edit || actionContact == 'update' ? false : true}
                                                                handleChange={(e) => { changeHandler(e, index) }}
                                                                clientInput
                                                                error={erro[index] && erro[index].middle_name}
                                                                labelText='Middle Name (Optional)'
                                                            />
                                                            <Text errorText> {erro[index] && erro[index].middle_name}</Text>
                                                        </Grid>
                                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'last_name',
                                                                    value: item.last_name,
                                                                    inputProps: { maxLength: 33 }
                                                                }}
                                                                disabled={index == 0 || !item.is_edit || actionContact == 'update' ? false : true}
                                                                error={erro[index] && erro[index].last_name}
                                                                handleChange={(e) => { changeHandler(e, index) }}
                                                                clientInput
                                                                labelText='Last Name'
                                                            />
                                                            <Text errorText>{erro[index] && erro[index].last_name}</Text>
                                                        </Grid>
                                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'email_id',
                                                                    value: item.email_id,
                                                                }}
                                                                disabled={index == 0 || !item.is_edit || actionContact == 'update' ? false : true}
                                                                error={erro[index] && erro[index].email_id}
                                                                handleChange={(e) => { changeHandler(e, index) }}
                                                                clientInput
                                                                labelText='Email ID'
                                                            />
                                                            <Text errorText>{erro[index] && erro[index].email_id}</Text>
                                                        </Grid>
                                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'telephone_number',
                                                                    value: item.telephone_number,
                                                                    inputProps: { maxLength: 12 }
                                                                }}
                                                                disabled={index == 0 || !item.is_edit || actionContact == 'update' ? false : true}
                                                                error={erro[index] && erro[index].telephone_number}
                                                                handleChange={(e) => { changeHandler(e, index) }}
                                                                clientInput
                                                                labelText='Phone Number'
                                                            />
                                                            <Text errorText>{erro[index] && erro[index].telephone_number}</Text>
                                                        </Grid>
                                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'telephone_number_ext',
                                                                    value: item.telephone_number_ext,
                                                                    inputProps: { maxLength: 6 }
                                                                }}
                                                                disabled={index == 0 || !item.is_edit || actionContact == 'update' ? false : true}
                                                                error={erro[index] && erro[index].ext}
                                                                handleChange={(e) => { changeHandler(e, index) }}
                                                                clientInput
                                                                labelText='Extension (Optional)'
                                                            />
                                                            <Text errorText>{erro[index] && erro[index].telephone_number_ext}</Text>
                                                        </Grid>
                                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'mobile_number',
                                                                    value: item.mobile_number,
                                                                    inputProps: { maxLength: 12 }
                                                                }}
                                                                disabled={index == 0 || !item.is_edit || actionContact == 'update' ? false : true}
                                                                error={erro[index] && erro[index].mobile_number}
                                                                handleChange={(e) => { changeHandler(e, index) }}
                                                                clientInput
                                                                labelText='Mobile Number (Optional)'
                                                            />
                                                            <Text errorText>{erro[index] && erro[index].mobile_number}</Text>
                                                        </Grid>
                                                        <Grid item container lg={12} m='10px 0px 10px 10px !important'>
                                                            <Grid item lg={5}>
                                                                <ToggleSwitch name='is_primary' isActive={item.is_primary} disable={formData.length == 1} switchChange={(e) => changeHandler(e, index)} sx={{ height: '24px !important' }} label={<Text largeLabel sx={{ paddingLeft: '10px' }}>Make This as Primary Contact</Text>} />
                                                            </Grid>
                                                        </Grid>
                                                        {
                                                            formData.length - 1 == index ? '' :
                                                                <Divider className={classes.divider} />
                                                        }
                                                    </Grid>
                                                </Grid> : ''
                                    )) :
                                    formData.length == 0 ?
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} textAlign='center'>
                                            <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                                <img src={noDataFound} alt='nodata' />
                                            </Grid>
                                            <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                <Text largeBlack>No Contacts Added</Text>
                                            </Grid>
                                            {
                                                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_edit" && item.is_allowed == true))) &&
                                                <>
                                                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                        {status === 'In Active' ? '' : <Text mediumLabel sx={{ fontSize: '14px !important' }}>You can add Contact Details by clicking on the <br /> below button</Text>}
                                                    </Grid>
                                                    {
                                                        status === 'In Active' ? '' :
                                                            <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={3}>
                                                                <Button startIcon={<Plus />} finishFilledQuicksandLargeWidth onClick={() => openAddForm('add')}>Add New Contact</Button>
                                                            </Grid>
                                                    }
                                                </>
                                            }
                                        </Grid>
                                        : ''
                        }
                    </>
                </Box>
                {
                    !loading && formData.length > 0 && (actionContact == 'update' || actionContact == 'disable') &&
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box display='flex' flexDirection='row' justifyContent='space-between'>
                            <Button blackCancelBtn onClick={handleCancel}>Cancel</Button>
                            <LoaderButton loading={buttonLoader} saveNcontinueSmall onClick={handleSubmit}>Save</LoaderButton>
                        </Box>
                    </Grid>
                }
            </Grid>
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide fixedWidth>
                <Box m={2} textAlign='center' justifyContent='center'>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text popupHead1>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} mt={1}>
                        <Text mediumOverView>Would you like to remove the primary</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} mt={0}>
                        <Text mediumOverView>contact? By removing this, the next contact</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} mt={0}>
                        <Text mediumOverView>will be designated as primary.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3} columnSpacing={2}>
                        <Grid item lg={6} xs={6} textAlign='right'>
                            <Button blueboldCancel onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item lg={6} xs={6} textAlign='left'>
                            <Button whiteboldButton onClick={deleteContact}>
                                Yes,Delete
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup openPopup={changePrimary} setOpenPopup={setChangePrimary} white iconHide>
                <Box m={2} textAlign='center' justifyContent='center'>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={changePrimaryImg} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text popupHead1>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} mt={1}>
                        <Text mediumOverView>Do you want to make contact as primary contact</Text>
                    </Grid>
                    <Grid container lg={12} xs={12} justifyContent='center' alignContent={'center'} mt={3} columnSpacing={2}>
                        <Grid item lg={5} xs={6} textAlign='right'>
                            <Button redboldCancel onClick={() => { setChangePrimary(false) }}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item lg={5} xs={6} textAlign='left'>
                            <Button blueboldCancelClient onClick={changePrimaryHandler}>
                                Yes
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </ReusablePopup>
        </Grid>
    )
}

export default ContactDetails