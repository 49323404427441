import React from 'react';
import EmployeesDashboard from '../views/admin/employees/EmployeesDashboard';
import InviteEmployee from '../views/admin/employees/InviteEmployee';
import Placements from '../views/admin/placements/newDashboard/Dashboard';
// import Timesheet from '../views/admin/timesheets/Dashboard';
import AddTimesheet from '../views/admin/timesheets/newTimeSheets/AddTimesheet';
import Timesheetview from '../views/admin/timesheets/Timesheetview';
import ClientsDashboard from '../views/admin/clients/ClientsDashboard';
// import UserProfile from '../views/admin/employees/userprofile/UserProfile';
import Login from '../layouts/login/Login';
import AddClient from '../views/admin/clients/AddClient';
import AddVendor from '../views/admin/clients/AddVendor';
import AddEndClient from '../views/admin/clients/AddEndClient';
import OnboardEmployee from '../views/admin/employees/onBoard/OnboardEmployee';
import AddBillingDetails from '../views/admin/placements/AddBillingDetails';
import ClientAndEndClient from '../views/admin/placements/clients/ClientAndEndClient';
import PlacementInfo from '../views/admin/placements/PlacementInfo';
import AddPlacement from '../views/admin/placements/AddPlacement';
import TimesheetConfiguration from '../views/admin/placements/timesheetConfiguration/TimesheetConfiguration';
import InvoiceConfiguration from '../views/admin/placements/invoiceConfiguration/InvoiceConfiguration';
import OffboardingChecklist from '../views/admin/employees/userprofile/OffboardingChecklist';
import Rehire from '../views/admin/employees/userprofile/rehire/Rehire';
import EmployeeVerifyFLow from '../views/admin/employees/employeeVerify/EmployeeVerifyFLow';
import Billing from '../layouts/signUp/Billing';
import ChangePasswordForm from '../layouts/changePassword/ChangePasswordForm';
import Analytics from '../views/admin/timesheets/analytics/Analytics';
import ClientAnalystics from '../views/admin/timesheets/analytics/ClientAnalystics';
import PlacementAnalystics from '../views/admin/timesheets/analytics/PlacementAnalystics';
import TimesheetDashboard from '../views/admin/timesheets/newTimeSheets/TimesheetDashboard';
import TimesheetSummary from '../views/admin/timesheets/TimesheetSummary';
import SelectedApp from '../views/admin/appIntegrations/SelectedApp';
import SelectedAppConfigure from '../views/admin/appIntegrations/SelectedAppConfigure';
import DeregisterApps from '../views/admin/appIntegrations/DeregisterApps';
import EditAppIntegrations from '../views/admin/appIntegrations/EditAppIntegrations';
import EditTimesheet from '../views/admin/timesheets/newTimeSheets/EditTimesheet';
import AITimesheet from '../views/admin/myProfile/AITimesheet';
import PlanPurchase from '../views/admin/myProfile/PlanPurchase';
import Upgrading from '../views/admin/myProfile/Upgrading';
import LocalStorage from '../utils/LocalStorage';
import MIVIPricing from '../views/admin/myProfile/MIVIPricing';
import PlanSucess from '../views/admin/myProfile/PlanSucess';
import Notifications from '../views/settings/notifications/Notifications';
import ExpenseDashboard from '../views/admin/payroll/expenseManagement/ExpenseDashboard'
import PayrollDashboard from '../views/admin/payroll/payrollDashboard/PayrollDashboard';
import BalanceSheetDashboard from '../views/admin/payroll/balanceSheet/BalanceSheetDashboard';
import BalanceSheetIndex from '../views/admin/payroll/balanceSheet/BalanceSheetIndex';
import PayrollSummary from '../views/admin/payroll/payrollSummary/PayrollSummary';
import GeneratePayroll from '../views/admin/payroll/generatePayroll/GeneratePayroll';

var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

const AdminRoute = [
  {
    id: "admin",
    path: "/*",
    element: <Login />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/",
    element: <Login />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/employees",
    element: <EmployeesDashboard />,
    name: "Employees",
    main: "Main",
    slug: "dashboard",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true)) ? true : false
  },
  {
    id: "admin",
    path: "/employees/add",
    element: <InviteEmployee />,
    name: "Invite Employee",
    main: "Main",
    slug: "dashboard",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_create" && item.is_allowed == true)) ? true : false
  },
  // {
  //   id: "admin",
  //   path: "/employees/user-profile/:employee",
  //   element: <UserProfile />,
  //   name: "",
  //   slug: "dashboard",
  //   access: true
  // },
  {
    id: "admin",
    path: "/employees/onboard",
    element: <OnboardEmployee />,
    name: "Onboard Employee",
    slug: "dashboard",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_create" && item.is_allowed == true)) ? true : false
  },
  {
    id: "admin",
    path: "/employee/verify",
    element: <EmployeeVerifyFLow />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/placements",
    element: <Placements />,
    name: "Placements",
    slug: "dashboard",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true)) ? true : false
  },
  {
    id: "admin",
    path: "/timesheet",
    element: <TimesheetDashboard />,
    name: "Timesheets",
    slug: "timesheet_view",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true)) ? true : false
  },
  {
    id: "admin",
    path: "/timesheet/view",
    element: <Timesheetview />,
    name: "",
    slug: "timesheet_view",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true)) ? true : false
  },
  {
    id: "admin",
    path: "/timesheet/add-timesheet",
    element: <AddTimesheet />,
    name: "Add Timesheet",
    slug: "timesheet_create",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_create" && item.is_allowed == true)) ? true : false
  },
  {
    id: "admin",
    path: "/timesheet/edit-timesheet",
    element: <EditTimesheet />,
    name: "",
    slug: "timesheet_view",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_edit" && item.is_allowed == true)) ? true : false
  },
  {
    id: "admin",
    path: "/app-integrations/selected-app",
    element: <SelectedApp />,
    name: "",
    slug: "",
    access: true
  },
  {
    id: "admin",
    path: "/app-integrations/selected-app/configure",
    element: <SelectedAppConfigure />,
    name: "",
    slug: "",
    access: true
  },
  {
    id: "admin",
    path: "/app-integrations/selected-app/configure/*",
    element: <SelectedAppConfigure />,
    name: "",
    slug: "",
    access: true
  },
  {
    id: "admin",
    path: "/app-integrations/de-register",
    element: <DeregisterApps />,
    name: "",
    slug: "",
    access: true
  },
  {
    id: "admin",
    path: "/app-integrations/edit-app-integrations",
    element: <EditAppIntegrations />,
    name: "",
    slug: "",
    access: true
  },
  // 
  {
    id: 'admin',
    path: "/employees/user-profile/:employee/offboarding",
    element: <OffboardingChecklist />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/clients",
    element: <ClientsDashboard />,
    name: "Client Dashboard",
    slug: "dashboard",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true)) ? true : false
  },
  {
    id: "admin",
    path: "/addClient",
    element: <AddClient />,
    name: "Add Client",
    slug: "dashboard",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "client_create" && item.is_allowed == true)) ? true : false
  },
  {
    id: "admin",
    path: "/addVendor",
    element: <AddVendor />,
    name: "Add Vendor",
    slug: "dashboard",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_create" && item.is_allowed == true)) ? true : false
  },
  {
    id: "admin",
    path: "/addEnd-Client",
    element: <AddEndClient />,
    name: "Add End Client",
    slug: "dashboard",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_create" && item.is_allowed == true)) ? true : false
  },
  {
    id: "admin",
    path: "/placements/addBillingDetails",
    element: <AddBillingDetails />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/placements/addclientAndendclient",
    element: <ClientAndEndClient />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/placements/placementsInfo",
    element: <PlacementInfo />,
    name: "Add Placement",
    slug: "dashboard",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_create" && item.is_allowed == true)) ? true : false
  },
  // {
  //   id: "admin",
  //   path: "/placements/view-placement",
  //   element: <PlacementView />,
  //   name: "",
  //   slug: "placement_view",
  //   access: true
  // },
  {
    id: "admin",
    path: "/placements/addPlacement",
    element: <AddPlacement />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/placements/timesheets",
    element: <TimesheetConfiguration />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/placements/invoice",
    element: <InvoiceConfiguration />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/notifications",
    element: <Notifications />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/rehire",
    element: <Rehire />,
    name: "",
    slug: "",
    access: true
  },
  {
    id: "admin",
    path: "/billing",
    element: <Billing />,
    name: "",
    slug: "",
    access: true
  },
  {
    id: "admin",
    path: "/change-password",
    element: <ChangePasswordForm />,
    name: "",
    slug: "",
    access: true
  },
  {
    id: "admin",
    path: "/placement/timesheets-analytics",
    element: <Analytics />,
    name: "",
    slug: "",
    access: true
  },
  {
    id: "admin",
    path: "/client/timesheets-analytics",
    element: <Analytics />,
    name: "",
    slug: "",
    access: true
  },
  {
    id: "admin",
    path: "/client-analytics",
    element: <ClientAnalystics />,
    name: "",
    slug: "",
    access: true
  },
  {
    id: "admin",
    path: "/placement-analytics",
    element: <PlacementAnalystics />,
    name: "",
    slug: "",
    access: true
  },
  {
    id: "admin",
    path: "/timesheet/summary",
    element: <TimesheetSummary />,
    name: "Timesheet Summary",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/timesheet/summary/*",
    element: <TimesheetSummary />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/mivi-upgrade",
    element: <AITimesheet />,
    slug: "dashboard",
    name: "",
    access: true
  },
  {
    id: "Admin",
    path: "/plan-purchase",
    element: <PlanPurchase />,
    slug: "dashboard",
    name: "",
    access: true
  },
  {
    id: "Admin",
    path: "/plan-upgrade",
    element: <Upgrading />,
    slug: "dashboard",
    name: "",
    access: true
  },
  {
    id: "Admin",
    path: "/ai_timesheet",
    element: <MIVIPricing />,
    slug: "dashboard",
    name: "",
    access: true
  },
  {
    id: "Admin",
    path: "/plan-status",
    element: <PlanSucess />,
    slug: "dashboard",
    name: "",
    access: true
  },
  {
    id: "Admin",
    path: "/expenseDashboard",
    element: <ExpenseDashboard />,
    slug: "dashboard",
    name: "Expense Management",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true)) ? true : false
  },
  {
    id: "Admin",
    path: "/payrollDashboard",
    element: <PayrollDashboard />,
    slug: "dashboard",
    name: "",
    access: true
  },
  {
    id: "Admin",
    path: "/balanceSheet",
    element: <BalanceSheetDashboard />,
    slug: "BalanceSheetDashboard",
    name: "BalanceSheetDashboard",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true)) ? true : false
  },
  {
    id: "Admin",
    path: "/payroll/generate-payroll",
    element: <GeneratePayroll />,
    slug: "dashboard",
    name: "Generate Payroll",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true)) ? true : false
  },
  {
    id: "Admin",
    path: "/payrollDashboard",
    element: <PayrollDashboard />,
    slug: "dashboard",
    name: "",
    access: true
  },
  {
    id: "Admin",
    path: "/balanceSheet/index",
    element: <BalanceSheetIndex />,
    slug: "dashboard",
    name: "",
    access: true
  },
  {
    id: "Admin",
    path: "/payrollSummary",
    element: <PayrollSummary />,
    slug: "dashboard",
    name: "",
    access: true
  }
]

export default AdminRoute;