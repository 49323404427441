import { Box, Grid, Menu, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Text from '../../../../../components/customText/Text';
import CustomRadioGroup from '../../../../settings/configurations/configComponents/radio/CustomRadio';
import Button from '../../../../../components/customButton/Button';
import FileInput from '../../../../../components/muiFileInput/FileInput';
import File from '../../../../../assets/svg/File.svg'
import EverifyApi from '../../../../../apis/admin/employees/EverifyApi';
import { addErrorMsg, addLoader, removeLoader } from '../../../../../utils/utils';
import LocalStorage from '../../../../../utils/LocalStorage';
import CommonApi from '../../../../../apis/CommonApi';
import LoadingScreen from '../document/LoadingScreen';
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import sureImg from "../../../../../assets/svg/OffBoardPop.svg"
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';

function Everify(props) {
  const { employee, setOpenReason, getEmployeeApi, setResonsforclosingCase, setCaseNumber, setCloseCaseComplete, setloginEvPopup, setrefreshToken } = props;

  const citizenshipStatus3 = [
    { label: <Text black400>no-image</Text>, value: 'no-image' },
    { label: <Text black400>not-matching</Text>, value: 'not-matching' },
    { label: <Text black400>Matching</Text>, value: 'matching' }
  ]
  const [anchorEl, setAnchorEl] = useState(null);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [data, setData] = useState(employee);
  const [everifyId, setEverifiedId] = useState('');
  const [fileName, setFilename] = useState('');
  const [loader, setLoader] = useState(false);
  // const [checkLoader, setCheckLoader] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [scanloader, setScanLoader] = useState(false);
  const [downloadImage, setDownloadImage] = useState('');
  const [openSure, setOpenSure] = useState(false);
  const [state, setState] = useState({
    photoMatch: '',
    front_document_id: '',
    front_document_name: '',
    front_document_url: '',
    back_document_id: '',
    back_document_name: '',
    back_document_url: ''
  })
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (anchorEl) {
      handleClose()
    }
  };

  const caseCloseReasonApi = (caseNumber) => {
    EverifyApi.caseCloselist(caseNumber).then((res) => {
      if (res.data.statusCode === 1003) {
        setResonsforclosingCase(res.data.data);
      }
    })
  }

  const downloadSubmit = () => {
    const newWindow = window.open(downloadImage, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const download = (args) => {
    let updateData = {
      request_id: LocalStorage.uid(),
      document_key: args.additional_data.document_path
    }
    addLoader(true);
    CommonApi.documentViewS3('e_verify_document', updateData).then((res) => {
      removeLoader();
      if (res.data.statusCode == 1003) {
        if (args.status == 'PHOTO_MATCH') {
          setPreviewImage(res.data.data.document_url);
        } else {
          setDownloadImage(res.data.data.document_url)
        }
      }
    })
  }

  const submit = () => {
    if (data.status == 'SCAN_AND_UPLOAD') {
      setScanLoader(true)
      const updateData = {
        request_id: LocalStorage.uid(),
        id: data.id,
        back_document_id: state.back_document_id,
        front_document_id: state.front_document_id
      }
      EverifyApi.scanandUpload(data.id, updateData).then((res) => {
        if (res.data.statusCode === 1003) {
          setScanLoader(false)
          getEmployeeApi();
          indexApi();
        } else {
          setScanLoader(false)
          addErrorMsg(res.data.error_message ? res.data.error_message : res.data.message);
        }
      })
    } else {
      setSubmitLoader(true);
      const updateData = {
        request_id: LocalStorage.uid(),
        photo_match: state.photoMatch,
        id: data.id
      }
      EverifyApi.photoMatch(data.id, updateData).then((res) => {
        if (res.data.statusCode === 1003) {
          setSubmitLoader(false);
          getEmployeeApi();
          indexApi();
        } else {
          setSubmitLoader(false);
          addErrorMsg(res.data.error_message ? res.data.error_message : res.data.message);
        }
      })
    }
  }

  const indexApi = (args) => {
    setLoader(true);
    EverifyApi.everifyIndex(employee.id).then((res) => {
      if (res.data.statusCode === 1003) {
        setLoader(false);
        setData(res.data.data);
        setEverifiedId(res.data.data.id);
        setCaseNumber(res.data.data.case_number);
        setFilename(res.data.data.additional_data ? res.data.data.additional_data.document_path : '')
        if (args == 'index') {
          getEmployeeApi();
        }
        caseCloseReasonApi(res.data.data.case_number);
        download(res.data.data);
      } else {
        setLoader(false);
        addErrorMsg(res.data.message);
      }
    })
  }

  useEffect(() => {
    indexApi();
    // eslint-disable-next-line
  }, [])

  const refreshApi = () => {
    setLoader(true);
    EverifyApi.refreshApi(employee.id).then((res) => {
      if (res.data.statusCode === 1003) {
        setLoader(false);
        if (res.data.allow_login) {
          setloginEvPopup(true);
          setrefreshToken(true);
        } else {
          indexApi('index');
        }
      } else {
        setLoader(false);
        addErrorMsg(res.data.message);
      }
    })
  }

  const handleCloseCase = () => {
    setOpenSure(true);
  }

  const yesButton = () => {
    setOpenSure(false);
    EverifyApi.everifyLogin().then((res) => {
      if (res.data.statusCode === 1003) {
        handleClose();
        setCloseCaseComplete(true);
        if (res.data.allow_login) {
          setloginEvPopup(true);
        } else {
          setOpenReason(true);
        }
      } else {
        handleClose();
        addErrorMsg(res.data.error_message ? res.data.error_message : res.data.message);
      }
    })
  }

  const changeHandler = (e) => {
    state[e.target.name] = e.target.value
    setState({ ...state })
  }

  const referCase = () => {
    setLoader(true);
    const updateData = {
      request_id: LocalStorage.uid()
    }
    EverifyApi.referCase(data.id, updateData).then((res) => {
      if (res.data.statusCode === 1003) {
        setLoader(false);
        indexApi();
      } else {
        setLoader(false);
        addErrorMsg(res.data.error_message ? res.data.error_message : res.data.message);
      }
    })
  }

  const noAction = () => {
    setLoader(true);
    const data = {
      request_id: LocalStorage.uid(),
      id: everifyId
    }
    EverifyApi.noActionApi(everifyId, data).then((res) => {
      if (res.data.statusCode === 1003) {
        setLoader(false);
        getEmployeeApi();
      } else {
        setLoader(false);
        addErrorMsg(res.data.error_message ? res.data.error_message : res.data.message);
      }
    })
  }

  const uploadDocs = (e) => {
    if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg'].includes(r))) {
      if (((e.target.files[0].size / (1024 * 1024)).toFixed(2)) <= '4') {
        const formData = new FormData();
        formData.append('files', e.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        addLoader(true);
        CommonApi.documentUpload("everify-scan-upload", formData, LocalStorage.getAccessToken())
          .then((res) => {
            if (res.data.statusCode === 1003) {
              removeLoader();
              if (e.target.name == 'front_document_id') {
                state.front_document_id = res.data.data.id
                state.front_document_name = e.target.files[0].name
                state.front_document_url = res.data.data.document_url
              } else {
                state.back_document_id = res.data.data.id
                state.back_document_name = e.target.files[0].name
                state.back_document_url = res.data.data.document_url
              }
              setState({ ...state })
            } else {
              removeLoader();
              addErrorMsg(res.data.message);
            }
          });
      } else {
        addErrorMsg('File size must be 5 MB or less. Please upload a smaller file');
      }
    } else {
      addErrorMsg("Please upload files in PNG or JPG format only.");
    }
  }

  const deleteDocs = (args) => {
    if (args == 'front_document_id') {
      state.front_document_id = ''
      state.front_document_name = ''
      state.front_document_url = ''
    } else {
      state.back_document_id = ''
      state.back_document_name = ''
      state.back_document_url = ''
    }
    setState({ ...state })
  }

  return (
    <Grid container p='20px'>
      {
        loader ?
          <LoadingScreen /> :
          <Grid item container spacing={2}>
            <Grid item container lg={12} xs={12}>
              <Grid item lg={7}>
                <Text mediumBlack700>E-Verify</Text>
              </Grid>
              <Grid item lg={5} justifyContent='end'>
                <Box display='flex' flexDirection='row' gap={1} justifyContent='end'>
                  <Box sx={{ background: '#F59E0B', borderRadius: '18px', padding: '4px 20px', alignItems: 'center' }}>
                    <Text mediumBoldWhite>{data.status == 'PENDING_REFERRAL' ? 'Pending Referral' : data.status == 'REFERRED' ? 'Referred' : data.status == 'CLOSED' ? 'Closed' : data.status == 'UNCONFIRMED_DATA' ? 'Unconfirmed Data' : data.status == 'CLOSE_CASE_AND_RESUBMIT' ? 'Close case and Resubmit' : data.status == 'QUEUED' ? 'Queued' : data.status == 'PHOTO_MATCH' ? 'Photo match' : data.status == 'SCAN_AND_UPLOAD' ? 'Scan and upload' : data.status == 'MANUAL_REVIEW' ? 'Manual Review' : data.status == 'EMPLOYMENT_AUTHORIZED' ? 'Employment Authorized' : data.status == 'FINAL_NONCONFIRMATION' ? 'Final Nonconfirmation' : data.status == 'DRAFT' ? 'Draft' : data.status}</Text>
                  </Box>
                  {
                    data.status == 'CLOSED' ? '' :
                      <Box>
                        <Button sx={{ minWidth: '40px' }} onClick={handleClick} >
                          <MoreHorizIcon sx={{ fontSize: "18px", color: "#9D9E9F" }} />
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            sx={{
                              '& .MuiPaper-root': {
                                minWidth: '100px !important',
                                boxShadow: "#0000001F !important",
                                borderRadius: '8px !important',
                                padding: '0px 2px 0px 0px !important'
                              }
                            }}
                          >
                            {
                              data.status == 'QUEUED' || data.status == 'REFERRED' || data.status == 'MANUAL_REVIEW' ? '' :
                                <MenuItem sx={{ background: 'none !important', borderBottom: '1px solid #CCCCCC' }} onClick={() => handleCloseCase()}>
                                  <Box display='flex' flexDirection='row' gap={1.5}>
                                    <Text smallBlack400 >Close Case</Text>
                                    {/* {checkLoader ? <CircularProgress size={18} /> : ''} */}
                                  </Box>
                                </MenuItem>
                            }
                            {
                              data.status == 'PENDING_REFERRAL' ?
                                <MenuItem sx={{ background: 'none !important', borderBottom: '1px solid #CCCCCC' }} onClick={() => noAction()}>
                                  <Text smallBlack400>No Action</Text>
                                </MenuItem> : ''
                            }
                            {
                              data.status == 'PENDING_REFERRAL' ?
                                <MenuItem sx={{ background: 'none !important', borderBottom: '1px solid #CCCCCC' }} onClick={() => referCase()}>
                                  <Text smallBlack400>Refer Case</Text>
                                </MenuItem> : ''
                            }
                            <MenuItem sx={{ background: 'none !important' }} onClick={() => refreshApi()}>
                              <Text smallBlack400>Refresh</Text>
                            </MenuItem>
                          </Menu>
                        </Button>
                      </Box>
                  }
                </Box>
              </Grid>
            </Grid>

            {
              data.status == 'PENDING_REFERRAL' || data.status == 'REFERRED' || data.status == 'UNCONFIRMED_DATA' || data.status == 'PHOTO_MATCH' || data.status == 'SCAN_AND_UPLOAD' ?
                <Grid item container>
                  <Box sx={{ background: '#FFFBF8', border: '1px solid #D97706', width: "100%", padding: '20px', margin: '15px 10px', borderRadius: '8px' }}>
                    {
                      data.status == 'PHOTO_MATCH' ?
                        <Grid item container rowSpacing={2}>
                          <Grid item lg={12} xs={12}>
                            <Text mediumBlack14>Photo Miss Match</Text>
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <Text mediumGreyHeader1>Photo Miss MatchLooks like some of the information is unmatched. </Text>
                          </Grid>
                          <Grid item container lg={12} spacing={2} alignItems='center'>
                            <Grid item lg={1.9} xs={1.9}>
                              <Box sx={{ border: "1.5px dashed #0C75EB", padding: '10px', textAlign: 'center' }}>
                                <img src={previewImage} alt='dummy' style={{ width: "85px", height: "120px" }} />
                              </Box>
                            </Grid>
                            <Grid item lg={10.1} xs={10.1} textAlign='center'>
                              <CustomRadioGroup spacing={2} mediumBlack1 options={citizenshipStatus3} value={state.photoMatch} widthProp='4' name='photoMatch' onChange={(e) => changeHandler(e)} />
                            </Grid>
                          </Grid>
                        </Grid>
                        : data.status == 'SCAN_AND_UPLOAD' ?
                          <Grid item container rowSpacing={2}>
                            <Grid item lg={12} xs={12}>
                              <Text mediumBlack14>Scan & Upload</Text>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                              <Text mediumGreyHeader1>Looks like some of the information is un-confirmed. Please update the below information and re-apply.</Text>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                              <FileInput
                                name='front_document_id'
                                FileName={state.front_document_name}
                                handleChange={(e) => uploadDocs(e)}
                                label='Frontside of the Passport Document'
                                handleDelete={() => deleteDocs('front_document_id')}
                                actionState={state.front_document_name ? 1 : ''}
                                documentUrl={state.front_document_url}
                              />
                            </Grid>
                            <Grid item lg={12} xs={12}>
                              <FileInput
                                name='back_document_id'
                                FileName={state.back_document_name}
                                handleChange={(e) => uploadDocs(e)}
                                label='Backside of the Passport Document '
                                handleDelete={() => deleteDocs('back_document_id')}
                                actionState={state.back_document_name ? 1 : ''}
                                documentUrl={state.back_document_url}
                              />
                            </Grid>
                          </Grid> : data.status == 'PENDING_REFERRAL' ?
                            <Grid item container rowSpacing={2}>
                              <Grid item lg={12} xs={12}>
                                <Text mediumBlack14>Download further action notice</Text>
                              </Grid>
                              <Grid item lg={12} xs={12}>
                                <Text mediumGreyHeader1>Please download the attahment document and proceed further action.</Text>
                              </Grid>
                              <Grid item lg={12} xs={12}>
                                <Text largeLabel sx={{ fontWeight: '400px !important' }}>Document</Text>
                              </Grid>
                              <Grid item lg={12} xs={12}>
                                <Box display='flex' flexDirection='row' gap={1}>
                                  <img src={File} alt='file' />
                                  <Text analyticsViewAll>{fileName ? fileName.split('/').pop() : ''}</Text>
                                </Box>
                              </Grid>
                            </Grid> :
                            data.status == 'REFERRED' ?
                              <Grid item container rowSpacing={2}>
                                <Grid item lg={12} xs={12}>
                                  <Text mediumBlack14>Download Referral Date Confirmation letter</Text>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <Text mediumGreyHeader1>Please download the attahment document and proceed further action. And the action must be taken by {data.referral_due_date} </Text>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <Text largeLabel sx={{ fontWeight: '400px !important' }}>Document</Text>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <Box display='flex' flexDirection='row' gap={1}>
                                    <img src={File} alt='file' />
                                    <Text analyticsViewAll>{fileName ? fileName.split('/').pop() : ''}</Text>
                                  </Box>
                                </Grid>
                              </Grid> : data.status == 'UNCONFIRMED_DATA' ?
                                <Grid item container rowSpacing={2}>
                                  <Grid item lg={12} xs={12}>
                                    <Text mediumBlack14>Unconfirmed Data</Text>
                                  </Grid>
                                  <Grid item lg={12} xs={12}>
                                    <Text mediumGreyHeader1>We have received some data that requires confirmation. Kindly log into the E-Verify portal at your earliest convenience to verify the details. Your prompt attention to this matter is greatly appreciated.</Text>
                                  </Grid>
                                </Grid> :
                                ''
                    }
                    {
                      data.status == 'UNCONFIRMED_DATA' ? '' :
                        <Grid item lg={12} mt={2}>
                          <LoadingButton loading={data.status == 'SCAN_AND_UPLOAD' ? scanloader : submitLoader} submitButtonEverify onClick={() => { data.status == 'PHOTO_MATCH' || data.status == 'SCAN_AND_UPLOAD' ? submit() : data.status == 'PENDING_REFERRAL' || data.status == 'REFERRED' ? downloadSubmit() : download() }}>{data.status == 'PENDING_REFERRAL' || data.status == 'REFERRED' ? 'Download' : 'Submit'}</LoadingButton>
                        </Grid>
                    }
                  </Box>
                </Grid> : ''
            }
            <Grid item container lg={12} xs={12} mt={2}>
              <Grid item lg={12} xs={12} mb={2}>
                <Text largeBlue>Basic Details</Text>
              </Grid>
              <Grid item lg={4} xs={4}>
                <Text greysmallLabel mb={0.7}>First Name</Text>
                <Text headerText14>{data.first_name}</Text>
              </Grid>
              <Grid item lg={4} xs={4}>
                <Text greysmallLabel mb={0.7}>Middle Name</Text>
                <Text headerText14>{data.middle_name ? data.middle_name : '-'}</Text>
              </Grid>
              <Grid item lg={4} xs={4}>
                <Text greysmallLabel mb={0.7}>Last Name</Text>
                <Text headerText14>{data.last_name}</Text>
              </Grid>
            </Grid>
            <Grid item container lg={12} xs={12} mt={2}>
              <Grid item lg={4} xs={4}>
                <Text greysmallLabel mb={0.7}>Other Last Name (Optional)</Text>
                <Text headerText14>{data.other_last_name_used && data.other_last_name_used.length > 0 ? data.other_last_name_used[0] : '-'}</Text>
              </Grid>
              <Grid item lg={4} xs={4}>
                <Text greysmallLabel mb={0.7}>Date of Birth</Text>
                <Text headerText14>{data.date_of_birth}</Text>
              </Grid>
            </Grid>
            <Grid item container lg={12} xs={12} mt={2}>
              <Grid item lg={4} xs={4}>
                <Text greysmallLabel mb={0.7}>Employee Email ID</Text>
                <Text headerText14>{data.email_address}</Text>
              </Grid>
              <Grid item lg={4} xs={4}>
                <Text greysmallLabel mb={0.7}>Client Company Name</Text>
                <Text headerText14>{data.everify_company_name}</Text>
              </Grid>
            </Grid>
            <Grid item container lg={12} xs={12} rowSpacing={2} mt={1}>
              <Grid item lg={12}>
                <Text largeBlue>Citizenship Status</Text>
              </Grid>
              <Grid item lg={12}>
                <Text blackHeader2>{data.citizen_type == 1 ? 'A Citizen of the united States' : data.citizen_type == 2 ? 'A Noncitizen of the united states' : data.citizen_type == 3 ? 'A Law full permanent Resident' : data.citizen_type == 4 ? 'A Noncitizen Authorized to Work' : ''}</Text>
              </Grid>
            </Grid>

            {
              data.document_type == 1 ?
                <Grid item container lg={12} xs={12} rowSpacing={2} mt={1}>
                  <Text greysmallLabel>List A Document - {data.type_one_doc_name}</Text>
                </Grid> :
                <Grid item container lg={12} xs={12} rowSpacing={2}>
                  <Grid item lg={12}>
                    <Text greysmallLabel><b>List B Document</b> - {data.type_two_doc_name}</Text>
                  </Grid>
                  <Grid item lg={12}>
                    <Text greysmallLabel><b>List C Document</b> - {data.type_three_doc_name}</Text>
                  </Grid>
                </Grid>
            }
            {
              data.list_one_document_type_code == 'us_passport_or_card_1_1' || data.list_one_document_type_code == 'us_passport_or_card_2_1' || data.list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_3_1' ?
                <Grid item lg={12} container>
                  <Grid item lg={4}>
                    <Text greysmallLabel mb={0.7}>Document Number</Text>
                    <Text headerText14>{data.issued_state_idproof ? data.issued_state_idproof : data.us_passport_number ? data.us_passport_number : data.i551_number ? data.i551_number : data.a_uscis_number ? data.a_uscis_number : data.foreign_passport_number ? data.foreign_passport_number : ''}</Text>
                  </Grid>
                  <Grid item lg={4}>
                    <Text greysmallLabel mb={0.7}>Expiry Date</Text>
                    <Text headerText14>{data.expiration_date}</Text>
                  </Grid>
                </Grid> : data.list_two_document_type_code == 'us_dl_or_id_card_1_2' || data.list_two_document_type_code == 'us_dl_or_id_card_2_2' || data.list_two_document_type_code == 'us_dl_or_id_card_3_2' || data.list_two_document_type_code == 'us_dl_or_id_card_4_2' ?
                  <Grid item container lg={12} xs={12} mt={2} rowSpacing={2}>
                    <Grid item lg={4} xs={4}>
                      <Text greysmallLabel mb={0.7}>Issued State</Text>
                      <Text headerText14>{data.issued_state_idproof}</Text>
                    </Grid>
                    <Grid item lg={4} xs={4}>
                      <Text greysmallLabel mb={0.7}>Document Number</Text>
                      <Text headerText14>{data.issued_state_idproof ? data.issued_state_idproof : data.us_passport_number ? data.us_passport_number : data.i551_number ? data.i551_number : data.i766_number ? data.i766_number : data.foreign_passport_number ? data.foreign_passport_number : data.a_uscis_number ? data.a_uscis_number : ''}</Text>
                    </Grid>
                    <Grid item lg={4} xs={4}>
                      <Text greysmallLabel mb={0.7}>Expiration Date</Text>
                      <Text headerText14>{data.expiration_date}</Text>
                    </Grid>
                  </Grid> : (data.citizen_type == 3 && data.document_type == 1) ?
                    <Grid item container lg={12} xs={12} mt={2} rowSpacing={2}>
                      {
                        data.list_two_document_type_code = 'permanent_resident_alien_registration_i551_3_1' || data.list_two_document_type_code == 'foreign_passport_temp_i551_mrv_notation_3_1' ?
                          <Grid item lg={4} xs={4}>
                            <Text greysmallLabel mb={0.7}>Document Number</Text>
                            <Text headerText14>{data.issued_state_idproof ? data.issued_state_idproof : data.us_passport_number ? data.us_passport_number : data.i551_number ? data.i551_number : data.i766_number ? data.i766_number : data.foreign_passport_number ? data.foreign_passport_number : ''}</Text>
                          </Grid> : data.list_two_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_3_1' || data.list_two_document_type_code == 'foreign_passport_temp_i551_mrv_notation_3_1' ?
                            <Grid item lg={4} xs={4}>
                              <Text greysmallLabel mb={0.7}>Expiration Date</Text>
                              <Text headerText14>{data.expiration_date}</Text>
                            </Grid> : data.list_two_document_type_code == 'foreign_passport_temp_i551_mrv_notation_3_1' ?
                              <Grid item lg={4} xs={4}>
                                <Text greysmallLabel mb={0.7}>Country of Issurance</Text>
                                <Text headerText14>{data.country_code}</Text>
                              </Grid> : ''
                      }

                      <Grid item lg={4} xs={4}>
                        <Text greysmallLabel mb={0.7}>A/USCIS Number</Text>
                        <Text headerText14>{data.a_uscis_number}</Text>
                      </Grid>
                    </Grid> : data.list_two_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_4_1' || data.list_two_document_type_code == 'foreign_passport_with_arrive_dep_i94_4_1' ?
                      <Grid item container lg={12} xs={12} mt={2} rowSpacing={2}>
                        <Grid item lg={4} xs={4}>
                          <Text greysmallLabel mb={0.7}>Expiration Date</Text>
                          <Text headerText14>{data.expiration_date}</Text>
                        </Grid>
                      </Grid> : (data.list_two_document_type_code == 'employment_auth_doc_i766_4_1' || data.list_one_document_type_code == 'employment_auth_doc_i766_4_1') ?
                        <Grid item container lg={12} xs={12} mt={2} rowSpacing={2}>
                          <Grid item lg={4} xs={4}>
                            <Text greysmallLabel mb={0.7}>Document Number</Text>
                            <Text headerText14>{data.issued_state_idproof ? data.issued_state_idproof : data.us_passport_number ? data.us_passport_number : data.i551_number ? data.i551_number : data.i766_number ? data.i766_number : data.foreign_passport_number ? data.foreign_passport_number : ''}</Text>
                          </Grid>
                          <Grid item lg={4} xs={4}>
                            <Text greysmallLabel mb={0.7}>Expiration Date</Text>
                            <Text headerText14>{data.expiration_date}</Text>
                          </Grid>
                          <Grid item lg={4} xs={4}>
                            <Text greysmallLabel mb={0.7}>A/USCIS Number</Text>
                            <Text headerText14>{data.a_uscis_number}</Text>
                          </Grid>
                        </Grid> : ''
            }
          </Grid >
      }
      <ReusablePopup openPopup={openSure} setOpenPopup={setOpenSure} statusWidth white iconHide>
        <Grid container lg={12} xs={12} textAlign='center' spacing={2} p={2}>
          <Grid item lg={12} xs={12}>
            <img src={sureImg} alt="sureImg" />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Text mediumBlack700 sx={{ font: "18px Quicksand !important" }}>Are you Sure?</Text>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Text offBoardBodyFont>Do you want to Close this case.</Text>
          </Grid>
          <Grid container lg={12} xs={12} justifyContent={'center'} alignContent={'center'} mt={1} spacing={2}>
            <Grid item lg={5} xs={6} textAlign='right'>
              <Button onClick={() => { setOpenSure(false) }} redBorder>Cancel</Button>
            </Grid>
            <Grid item lg={5} xs={6} textAlign='center'>
              <Button onClick={() => yesButton()} blueButton>Yes</Button>
            </Grid>
          </Grid>
        </Grid>
      </ReusablePopup>
    </Grid >
  )
}

export default Everify