import { Box, Grid } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Text from '../../../components/customText/Text';
import ArrowLeft from '../../../assets/svg/fi_arrow-left.svg'
import PlanPurchaseStyles from './PlanPurchaseStyles';
import logoName from '../../../assets/svg/dashboard/New_Logo_Black.png';
import planPurchaseImg from '../../../assets/timeSheets/plan-purchase.svg';

function PlanPurchase() {
    const navigate = useNavigate();
    const classes = PlanPurchaseStyles();
    return (
        <Grid container lg={12} md={12} sm={11.5} xs={12} className={classes.paddingLeft} justifyContent='center'>
            <Grid item lg={12} p={2}>
                <img src={logoName} alt="logoName" width={'173px'} height={'51px'}></img>
            </Grid>
            <Grid item container lg={12} md={10} sm={12} xs={12} pl={2}>
                <Grid item lg={12} md={12} sm={12} xs={12} justifyContent='center'>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <img src={ArrowLeft} alt='ArrowLeft' style={{ cursor: 'pointer' }} onClick={() => navigate('/myprofile')}></img>
                        <Text blackHeader600 >My Profile</Text>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xl={12} lg={12} justifyContent='center' textAlign='center' pt={9} alignItems='center'>
                <img src={planPurchaseImg} alt='upgrade' />
                <Text greyDark22 pt={2} pb={3}>All AI credits consumed. Contact Super Admin to buy additional credits</Text>
            </Grid>
        </Grid>
    )
}

export default PlanPurchase