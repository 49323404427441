import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Skeleton } from '@mui/material'
import React, { useState, useEffect,useRef } from 'react'
import Text from '../../../../../components/customText/Text'
import Button from '../../../../../components/customButton/Button';
import GeneratePayrollStyles from '../GeneratePayrollStyles';
import NewDownloadcloud from '../../../../../assets/svg/download-cloud-new.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { addErrorMsg, downloadExcel, formatNumberWithCommas, getCurrencySymbol, NoDataFound } from '../../../../../utils/utils';
import { blue, cancelRed } from '../../../../../theme';
import GeneratePayrollApi from '../../../../../apis/configurations/generatePayroll/GeneratePayrollApi';
import moment from 'moment';


function BalanceSheet({ summary,allBalanceSheetProp ,currentSalaryRowProp,closePopUp,balanceSheetPagination,balanceSheetPaginationTotal,setUpdateStandardPayPopUp}) {

  const classes = GeneratePayrollStyles();

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balancesheets,setBalanceSheets] = useState(allBalanceSheetProp);
  // eslint-disable-next-line
  const [paginationBalanceSheet, setPaginationBalancesheet] = useState(balanceSheetPagination);
    const [paginationTotalBalanceSheet, setPaginationTotalBalanceSheet] = useState(balanceSheetPaginationTotal)

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [])
  const [filterData, setFilterData] = useState({
    limit: 10,
    page: 1,
   
});


  const handleDownloadExport = () => {
    GeneratePayrollApi.ExportBalanceSheet({employee_id:currentSalaryRowProp?.employee_id}).then((response)=>{
      if (response.data instanceof Blob) {
          const blob = response.data;
          const formattedDate = moment().format('DDMMYYhhmmssSSS');
          let filename = `BalanceSheet_${formattedDate}.xlsx`
          downloadExcel(blob, filename)
      }
      else {
          addErrorMsg(response.data.message)
      }
  })
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getAllBalanceSheetsOfEmployee = (filter) => {
    const data = {
        employee_id: currentSalaryRowProp?.employee_id,...filter
    }
    GeneratePayrollApi.GetBalanceSheet(data).then((res) => {
        if (res.data.statusCode === 1003) {
          setBalanceSheets(res.data.data);
          setPaginationBalancesheet(res.data.pagination);
          paginationTotalBalanceSheet.total = Number(res.data.pagination.total);
          paginationTotalBalanceSheet.balanceSheets = res.data.data;
          setPaginationTotalBalanceSheet({ ...paginationTotalBalanceSheet })
        }
    })
}

  const loadMoreData = () => {
    filterData.limit = Number(filterData.limit) + 10;
    setFilterData({ ...filterData });
    getAllBalanceSheetsOfEmployee(filterData)
}

  const scrollBox = useRef(null);

  const handleScroll = () => {
      const { current } = scrollBox;
      if (current) {
          const { scrollTop, scrollHeight, clientHeight } = current;
          if (scrollTop + clientHeight >= scrollHeight - 5) {
              if (Number(paginationTotalBalanceSheet.total) >= filterData.limit) {
                  loadMoreData();
              }
          } 
      }
  };

  return (
    <div>
      <Box px={3} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Text black18px noWrap>BalanceSheet</Text>
        <button title="Export" type="button" className={classes.borderButton1} onClick={() => handleDownloadExport()}><img src={NewDownloadcloud} alt="Userplus" /></button>
      </Box>
      <Box px={3} pt={1}>
        <Box sx={{ display: "flex", background: "#FBFBFB", padding: "12px 4px", borderRadius: "8px" }}>
          <Box width={"20%"}><Text black400 noWrap>Payment Mode</Text></Box>
          <Box width={"20%"}><Text black400 noWrap>Deducted / Added to</Text></Box>
          <Box width={"15%"}><Text black400 noWrap>Expense Date</Text></Box>
          <Box width={"20%"}><Text black400 noWrap>Balance Impact</Text></Box>
          <Box width={"15%"}><Text black400 noWrap>Current balance</Text></Box>
        </Box>

        <Box sx={{ height: "51vh", overflowY: "auto" }} ref={scrollBox} onScroll={handleScroll}>

          {balancesheets?.length > 0 ? 
            balancesheets.map((data, key) => (
              <Accordion key={key} className={classes.customAccordion}
                expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}
              >
                <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header" className={classes.customSummary}>
                  <Grid container alignItems='center' justifyContent={"space-between"}>
                    <Box width={"20%"}><Text black18px noWrap>{data?.payment_cycle}</Text></Box>
                    <Box width={"20%"}><Text black18px noWrap>{data?.added_deducted_to}</Text></Box>
                    <Box width={"20%"}><Text black18px noWrap>{data?.pay_check_date}</Text></Box>
                    <Box width={"20%"} textAlign={"center"}><Text black18px noWrap pr={6} sx={{ color: `#15803D !important` }}>+ {getCurrencySymbol()} {formatNumberWithCommas(data?.balance_impact)}</Text></Box>
                    <Box width={"10%"} textAlign={"end"}><Text black18px noWrap>{getCurrencySymbol()} {formatNumberWithCommas(data?.current_balance)}</Text></Box>
                    <Box width={"10%"} textAlign={"end"}><ExpandMoreIcon /></Box>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ borderRadius: "8px", border: `1px solid ${blue}`, background: "#F9FCFF" }}>
                    <Box p={3}>
                      <Grid container spacing={3} pb={2}>
                        <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Opening Balance</Text></Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                        <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600>{getCurrencySymbol()} {formatNumberWithCommas(data?.current_balance_data?.opening_balance)}</Text>} </Grid>
                      </Grid>
                      <Divider />
                      {data?.payment_mode == 1 ?
                        <>
                          <Grid container spacing={3} py={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14> Wages</Text></Grid>

                            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Earned Amount</Text></Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600 sx={{ color: `#15803D !important` }} noWrap>+ {getCurrencySymbol()} {formatNumberWithCommas(data?.current_balance_data?.earned_amount)}</Text>} </Grid>

                            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Salary Amount</Text></Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600 sx={{ color: `{getCurrencySymbol()}{cancelRed} !important` }} noWrap>- {getCurrencySymbol()} {formatNumberWithCommas(data?.current_balance_data?.salary_amount)}</Text>}</Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14>Deductions</Text></Grid>
                            {data?.current_balance_data?.deductions.map((eachItem) => <>
                              <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>{eachItem?.name}</Text></Grid>
                              <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                              <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600 sx={{ color: `${cancelRed} !important` }} noWrap>- {getCurrencySymbol()} {formatNumberWithCommas(eachItem?.raised_amount)}</Text>} </Grid>

                            </>)}
                            <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14>Reimbursements</Text></Grid>
                            {data?.current_balance_data?.reimbursements.map((eachItem) => <> <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>{eachItem.name}</Text></Grid>
                              <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                              <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600 sx={{ color: `#15803D !important` }} noWrap>+ {getCurrencySymbol()} {formatNumberWithCommas(eachItem?.raised_amount)}</Text>} </Grid>
                            </>
                            )}
                          </Grid>
                          <Divider />
                          <Grid container spacing={3} py={2}>
                            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Balance Impact</Text></Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600 sx={{ color: `#15803D !important` }} noWrap>+ {getCurrencySymbol()} {formatNumberWithCommas(data?.current_balance_data?.balance_impact)}</Text>} </Grid>
                          </Grid>

                        </> :
                         <>
                          <Grid container spacing={3} pb={2}>
                            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Deducted Amount</Text></Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600 sx={{ color: `#15803D !important` }} noWrap>+ {getCurrencySymbol()} {formatNumberWithCommas(data?.balance_impact)}</Text>} </Grid>
                          </Grid>


                          <Grid container spacing={3} pb={2}><Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Transaction ID</Text></Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600 sx={{ color: `#15803D !important` }} noWrap>+ {getCurrencySymbol()} {formatNumberWithCommas(data?.transaction_id)}</Text>} </Grid>
                          </Grid>


                          <Divider />

                          <Grid item lg={12} md={12} sm={12} xs={12}><Text blackHeader600>Comments</Text></Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"start"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600  noWrap> {data?.remarks ? data?.remarks :''}</Text>} </Grid>



                        </>
                      }<>
                          <Divider />

                        <Grid container spacing={3} py={2}>
                          <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Current Balance</Text></Grid>
                          <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                          <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600 noWrap>{getCurrencySymbol()} {formatNumberWithCommas(data?.current_balance_data?.current_balance)}</Text>}</Grid>
                        </Grid>
                      </>

                    </Box>
                  </Box>
                </AccordionDetails>

              </Accordion>
            )) : <Box>
              {NoDataFound()}</Box>}
        </Box>

      </Box>
      <Box sx={{ background: "#EAECF0" }}>
        <Grid container item lg={12} md={12} sm={12} xs={12} alignItems={"center"} p={1} px={3} gap={2} justifyContent={"end"}>
          <Button cancelBtn sx={{ width: "50px !important", height: '40px !important' }} onClick={closePopUp}>Cancel</Button>
          {!summary ? <Button save14 sx={{ width: "50px !important" }} onClick={setUpdateStandardPayPopUp}>Finalize</Button>: <Button save14 sx={{ width: "50px !important" }} onClick={closePopUp}>Done</Button>}

        </Grid>
      </Box>
    </div>
  )
}

export default BalanceSheet
