import React from 'react'
import logoName from '../../../assets/svg/dashboard/New_Logo_Black.png';
import { Grid } from '@mui/material';
import upgrade from '../../../assets/svg/plan-upgrade.svg';
import Text from '../../../components/customText/Text';
import Button from '../../../components/customButton/Button';
import { useNavigate } from 'react-router-dom';

function Upgrading() {
    const navigate = useNavigate();
    return (
        <Grid container>
            <Grid item lg={2} p={2}>
                <img src={logoName} alt="logoName" width={'173px'} height={'51px'}></img>
            </Grid>
            <Grid item xl={12} lg={12} justifyContent='center' textAlign='center' pt={6} alignItems='center'>
                <img src={upgrade} alt='upgrade' />
                <Text mediumBoldBlack pt={3} pb={3}>Payment Received! Your credits are upgraded successfully.</Text>
                <Button upgradePlan sx={{ width: '211px !important' }} onClick={() => navigate('/myprofile')}>Go to My Profile</Button>
            </Grid>
        </Grid>
    )
}

export default Upgrading