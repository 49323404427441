import React from 'react';
import { Box, RadioGroup, FormControlLabel, Radio ,Grid} from '@mui/material';
import Text from '../../../../../components/customText/Text';
import { ReactComponent as RadioIcon } from '../../../../../assets/svg/RadioIcon.svg';
import { ReactComponent as RadioCheckedIcon } from '../../../../../assets/svg/RadioCheckedIcon.svg';
import { ReactComponent as RadioDisabledIcon } from '../../../../../assets/svg/RadioDisabled.svg';
import { ReactComponent as RadioCheckedDisabedIcon } from '../../../../../assets/svg/RadioCheckedDisabled.svg';



const CustomRadioGroup = ({ options, value, onChange, name, disabled,widthProp=4,spacing }) => {
  return (
    <Box display="flex" flexDirection="row" sx={{width:"100%"}}>
      <RadioGroup sx={{width:"100%"}} row value={value} name={name} onChange={onChange} flexDirection="column">
        <Grid container spacing={spacing}>
          {options.map((option) => (
            <Grid item  lg={widthProp} md={widthProp} sm={widthProp} ><FormControlLabel
           
              key={option.value}
              control={<Radio icon={disabled ? <RadioDisabledIcon /> : <RadioIcon />}
                checkedIcon={disabled ? <RadioCheckedDisabedIcon /> : <RadioCheckedIcon />} />}
              label={<Text mediumGrey2 >{option.label}</Text>}
              value={option.value}
              disabled={disabled}
            /></Grid>
            
          ))}
        </Grid>
       
      </RadioGroup>
    </Box>
  );
};

export default CustomRadioGroup;