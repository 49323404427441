import React, { useEffect, useState } from 'react';
import UserProfileStyles from '../UserProfileStyles'; // eslint-disable-next-line
import { Box, Typography, Tabs, Tab, Skeleton, Grid, ButtonGroup } from '@mui/material';
// eslint-disable-next-line
import EducationDocs from './EducationDocs'; // eslint-disable-next-line
import PersonalDocs from './PersonalDocs'; // eslint-disable-next-line
import WorkAuthorization from './WorkAuthorization'; // eslint-disable-next-line
// import EmployeesApi from '../../../../../apis/admin/employees/EmployeesApi';
// import { addErrorMsg } from '../../../../../utils/utils'; // eslint-disable-next-line
// import Button from '../../../../../components/customButton/Button';
import EducationDocument from './EducationDocument';
import WorkAuth from './WorkAuth';
import PersonalDocuments from './PersonalDocuments';

export default function AllSections(props) {
    const { getIndex } = props;
    // eslint-disable-next-line
    const classes = UserProfileStyles(); // eslint-disable-next-line
    const [tab, setTab] = useState(0);
    // eslint-disable-next-line
    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
        setCurrent(event)
    };
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [formLoader, setformLoader] = useState(false) // eslint-disable-next-line
    const [list, setList] = useState([]);
    const [current, setCurrent] = useState("education");
    useEffect(() => {
        setCurrent(props.documentPosition) // eslint-disable-next-line
    }, [props])

    return (
        <Box m={.5} mr={0.1} sx={{ width: '100% !important' }}>
            {
                current === 'education' ?
                    <EducationDocument rolePermission={props.rolePermission} formEdit={props.formEdit} setformEdit={props.setformEdit} employee={props.employee} documentPosition={props.documentPosition} /> :
                    current === 'work' ?
                        <WorkAuth rolePermission={props.rolePermission} formEdit={props.formEdit} setformEdit={props.setformEdit} employee={props.employee} documentPosition={props.documentPosition} getIndex={getIndex}/>
                        :
                        <PersonalDocuments rolePermission={props.rolePermission} formEdit={props.formEdit} setformEdit={props.setformEdit} employee={props.employee} documentPosition={props.documentPosition} />
            }
        </Box>
    );
}