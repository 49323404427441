import BaseApi from '../../BaseApi'
import APIURL from "../../../config/development";
import LocalStorage from '../../../utils/LocalStorage';

class TourGuideApiConfig {
    //listing
   

    getApi() {                           
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/guiding-tour?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }
    updateApi(data) {
        return BaseApi.putWithToken(APIURL.employee_URL + `employee/guiding-tour`, data, LocalStorage.getAccessToken())
    } 
}
// eslint-disable-next-line
export default new TourGuideApiConfig();