import APIURL from "../../../config/development";
import LocalStorage from "../../../utils/LocalStorage";
import BaseApi from "../../BaseApi";

class EmployeeVerifyApi {

    getVerifyEmployeeIndex(id) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/invite-via-link/index?request_id=${LocalStorage.uid()}&id=${id}`, LocalStorage.getAccessToken())
    }

    updateVerifyEmployeeIndex(data){
        return BaseApi.postWithToken(APIURL.employee_URL + `employee/invite-via-link/update-document-status`, data, LocalStorage.getAccessToken())
    }
}
// eslint-disable-next-line
export default new EmployeeVerifyApi();