import React, { useState, useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import DashboardStyles from '../DasboardStyles';
import { Box, Avatar, Stack, Grid, FormControlLabel, Checkbox, Chip, FormControl, SwipeableDrawer, Divider, Menu, Badge, List, ListItem, ListItemText, Hidden } from '@mui/material';
import crossIcon from '../../../assets/svg/crossIcon.svg';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Skeleton from '@mui/material/Skeleton';
import Component87 from '../../../assets/svg/Component87.svg';
import disableVerified from '../../../assets/client/disableVerified.svg';
import Search from '../../../assets/svg/search1.svg';
import NewDownloadcloud from '../../../assets/svg/download-cloud-new.svg';
import Userplus from '../../../assets/svg/user-plus.svg';
import InviteViaLink from '../../../assets/svg/invite-via-link.svg';
import Onboart from '../../../assets/svg/onboard.svg';
import EmployeeAPI from '../../../apis/admin/employees/EmployeesApi';
import { addErrorMsg, addLoader, addLoaderPlanExpired, addSuccessMsg, BlackToolTip, capitalizeAndAddSpace, ListLoadingIcon, NoDataFound, NoPermission, NotApplicable, removeExpiredLoader, removeLoader } from '../../../utils/utils';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Text from '../../../components/customText/Text';
import Button from '../../../components/customButton/Button';
import { ReactComponent as CloseIcon } from '../../../assets/svg/cross.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../assets/svg/CheckedBorderIcon.svg';
// import { ReactComponent as RadioIcon } from '../../../assets/svg/RadioIcon.svg';
// import { ReactComponent as RadioCheckedIcon } from '../../../assets/svg/RadioCheckedIcon.svg';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CommonApi from '../../../apis/CommonApi';
import LocalStorage from '../../../utils/LocalStorage';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import infoCircle from '../../../assets/images/info-circle.png';
import ClearIcon from '@mui/icons-material/Clear';
// import Loader from '../../../assets/gif/japfuLoader.gif';
import deleteAlert from '../../../assets/svg/dleteAlert.svg';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FileSaver from 'file-saver';
import nofilterplacement from '../../../assets/svg/nofilterplacement.svg';
import CircleIcon from '@mui/icons-material/Circle';
import CustomCircularProgress from '../../../components/progressbar/CircularProgress';
import { domain } from '../../../config/Domain';
import DashboardAPI from '../../../apis/admin/DashboardAPI';
import Pending from '../../../assets/employee/Orange-PendingIcon.svg';
import Zohosyncnow  from '../../../assets/svg/zohosyncnow.svg';
import Zohosyncing  from '../../../assets/svg/zohosyncing.svg';
import Zohosynced  from '../../../assets/svg/zohosynced.svg';
import Zohorefresh  from '../../../assets/svg/zohorefresh.svg';

// import Rejected from '../../../assets/svg/Rejected.svg';
import TourEmployees from '../../../components/tourGuideDetails/TourEmployees';
import TourGuideConfigApi from '../../../apis/configurations/tourguideConfig/TourGuideConfigApi';
import InstalledAppsApi from '../../../apis/InstalledAppsApi';
const statusDropdown = [{ id: "Active", value: "Active" }, { id: "In Active", value: "In-Active" }];

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} timeout={500} />;
});
const TransitionUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} timeout={200} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

const BootstrapDialog1 = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "12px",
        width: "400px"
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

export default function ControlledAccordions() {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = DashboardStyles();
    const param = new URLSearchParams(window.location.search);
    const tab = param.get('tab');
    const navigate = useNavigate();
    const location = useLocation();
    const createEmployee = location && location.state && location.state.from
    const [employees, setEmployees] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [filterData, setFilterData] = useState({
        limit: 10,
        page: 1,
        search: "",
        employment: [],
        category: [],
        status: [],
        visa: [],
        sort_column: "emp.created_at",
        sort_order: "DESC"
    });
    const employeeId = LocalStorage.getUserData().login_id
    const [filterState, setFilterState] = useState({
        employment: [],
        category: [],
        status: [],
        visa: [],
    })

    const [visaTypeDropdown, setVisaTypeDropdown] = useState([]);
    const [employeeExportData, setEmployeeExportData] = useState([]);
    const [employeeExportFields, setEmployeeExportFields] = useState([]);
    const [currentLabel, setCurrentLabel] = useState(0);
    const [checkAll, setCheckAll] = useState({});
    const [category, setCategory] = useState([]);
    const [employmentTypeDropdown, setEmploymentTypeDropdown] = useState([]);
    const [startTour, setStartTour] = useState(false);
    // const [openBacKLoader, setOpenBacKLoader] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [selectedOption, setSelectedOption] = useState('All employees');
    const [syncing,setsyncing]=useState(false);
    const [currentSyncingId,setCurrentSyncingId] = useState('')
    const [appIntegrations,setAppIntegrations] = useState({});

    const [selfOnboardFilter, setSelfOnboardFilter] = useState({
        request_id: LocalStorage.uid(),
        page: 1,
        limit: 10,
        sort_column: "created_at",
        sort_order: "DESC",
        search: "",
        status: []
    })

    const [internalState, setInternalState] = useState({
        page: 1,
        limit: 10,
        sort_column: "created_at",
        sort_order: "DESC",
        search: ""
    })
    const [listLoading, setListLoading] = useState(false);

    const profile = (tab == 'internal-employees' || LocalStorage.getRedirectedModule() == 'employee > internalemployees' || LocalStorage.getRedirectedModule() == 'organization') ? 1 : location.state && location.state.profile && location.state.profile

    useEffect(() => {
        if (LocalStorage.getRedirectedModule() == 'employee > onboaders > offer rejected') {
            setSelectedOption('Self Onboarded');
            selfOnboardFilter.status = ['Offer Rejected'];
            setSelfOnboardFilter({ ...selfOnboardFilter })
            getAllEmployees(selfOnboardFilter, "selfonboardfilter");
            return true
        }
        if (tab == 'internal-employees') {
            setSelectedOption('Internal Employees');
            getAllInternalEmployees(internalState);
        }
        if (LocalStorage.getRedirectedModule() == 'employee > internalemployees' || LocalStorage.getRedirectedModule() == 'organization') {
            setSelectedOption('Internal Employees');
            getAllInternalEmployees(internalState);
        } else if (LocalStorage.getRedirectedModule() == 'employee > allemployees') {
            setSelectedOption('All employees');
        }
        if (createEmployee == 'consultant') {
            filterData.employment = [2]
            setFilterData({ ...filterData })
        } else if (createEmployee == 'contractor') {
            filterData.employment = [3]
            setFilterData({ ...filterData })
        }
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) {
            setLoading(true)
            categoryList();
            if (location && location.state && location.state.from == 'notification') {
                setSelectedOption('Internal Employees');
                setLoading(true);
                setEmployees([]);
                getAllInternalEmployees(internalState);
            } else if (profile) {
                setSelectedOption("Internal Employees");
                setLoading(true);
                setEmployees([]);
                getAllInternalEmployees(internalState);
            } else {
                getAllEmployees(filterData);
            }
        }
        if (createEmployee == 'dashboard') {
            setOpen(true);
        } else {
            setOpen(false);
        }
        if (LocalStorage.getFromPage() !== '' ? LocalStorage.getFromPage() : location && location.state && location.state.from == 'notification') {
            setSelectedOption('Internal Employees')
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getPlanCheck();
        getAllmasterData();
        getAllApps();

        // eslint-disable-next-line
    }, [])

    const getAllApps = () => {
        InstalledAppsApi.getApps('').then((res) => {
            if (res.data.statusCode === 1003) {
                setAppIntegrations(res.data.data[0]);
            }
        })
    }

    const getTourGuideDetails = async () => {
        let response = await TourGuideConfigApi.getApi();
        if (((LocalStorage.getStartTour() && LocalStorage.getStartTour().employees == true) || (response.data.statusCode == 1003 && response.data.data?.EMPLOYEE == false)) && LocalStorage.getUserData().admin_login == true) {
            LocalStorage.setStartTour({ 'employees': true });
            setStartTour(true);
        }

    }

    const getPlanCheck = () => {
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
                if (!res.data.plan_not_set && !res.data.plan_expired) {
                    if (LocalStorage.getUserData().admin_login && (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) {
                        getTourGuideDetails();
                    }
                    else {
                        LocalStorage.setStartTour({ 'employees': false });

                    }
                }
            }
        })
    }

    const getAllmasterData = () => {
        CommonApi.getAllMasterData(LocalStorage.uid()).then((response) => {
            if (response.data.statusCode == 1003) {
                setVisaTypeDropdown(response.data.data.visa_types);
                setEmploymentTypeDropdown(response.data.data.employment_types);
            } else {
                addErrorMsg(response.data.message);
            }
        });
    }

    // Get Employee Export data
    const getEmployeeExportData = () => {
        addLoader(true)
        EmployeeAPI.EmployeeExportFields().then((response) => {
            if (response.data.statusCode == 1003) {
                let responseData = response.data.data;
                responseData.map((i) => i.status = false);
                responseData[0].status = true;
                setEmployeeExportData(responseData);
                setEmployeeExportFields(responseData[0].data)
                setCurrentLabel(0)
                removeLoader()
            } else {
                addErrorMsg(response.data.message);
                removeLoader()
            }
        })
    }

    const categoryList = () => {
        CommonApi.CategoryList(LocalStorage.uid(), '2,3', LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setCategory(res.data.data);
            }
        })
    }

    const [paginationTotal, setPaginationTotal] = useState({
        total: null,
        employees: []
    })


    const getAllEmployees = (filter, param) => {
        setDrawer(false);
        if (param === undefined) {
            EmployeeAPI.getAllEmployees(filter).then((response) => {
                // setOpenBacKLoader(false);
                setListLoading(false);
                setTimeout(() => {
                    setLoading(false);
                    // setOpenBacKLoader(false);
                    setListLoading(false);
                    removeLoader();
                    if (response.data.statusCode == 1003) {
                        setEmployees(response.data.data);
                        setPagination(response.data.pagination);
                        paginationTotal.total = Number(response.data.pagination.total);
                        paginationTotal.employees = response.data.data;
                        setPaginationTotal({ ...paginationTotal });
                        setsyncing(false);
                        setCurrentSyncingId('');
                    } else {
                        addErrorMsg(response.data.message);
                    }
                }, 400)
            });
        } else {
            EmployeeAPI.selfOnboardedList(filter).then((response) => {
                // setOpenBacKLoader(false);
                setListLoading(false);
                setTimeout(() => {
                    setLoading(false);
                    // setOpenBacKLoader(false);
                    setListLoading(false);
                    removeLoader();
                    if (response.data.statusCode == 1003) {
                        setEmployees(response.data.data);
                        setPagination(response.data.pagination);
                        paginationTotal.total = Number(response.data.pagination.total);
                        paginationTotal.employees = response.data.data;
                        setPaginationTotal({ ...paginationTotal })
                    } else {
                        addErrorMsg(response.data.message);
                    }
                }, 400)
            });
        }
    }

    const getAllInternalEmployees = (args) => {
        EmployeeAPI.getInternalEmployees(args).then((response) => {
            if (response.data.statusCode == 1003) {
                setLoading(false);
                // setOpenBacKLoader(false);
                setListLoading(false);
                setEmployees(response.data.data);
                setPagination(response.data.pagination);
                paginationTotal.total = Number(response.data.pagination.total);
                paginationTotal.employees = response.data.data;
                setPaginationTotal({ ...paginationTotal })
            } else {
                setLoading(false);
                // setOpenBacKLoader(false);
                setListLoading(false);
                addErrorMsg(response.data.message);
            }
        });
    }

    // eslint-disable-next-line
    const completeProfile = (Emp) => {
        navigate('/employees/user-profile', { state: { id: Emp.id, full_name: Emp.full_name, e_verify: Emp.e_verified, reference_id: Emp.reference_id, avatar_url: Emp.profile_picture_url, enable_login: Emp.enable_login, stage: Emp.drafted_stage, progress: Emp.profile_progress, from: 'employmentDashboard' } })
    }

    const employeeExport = () => {
        setLoading(true)
        let data = {};
        data.request_id = LocalStorage.uid()
        data.name = employeeExportData[currentLabel].name;
        data.table_name = employeeExportData[currentLabel].table_name;
        data.data = employeeExportData[currentLabel].data;
        EmployeeAPI.employeeExport(data).then((response) => {
            setLoading(false)
            const url = response.data.path;
            const link = document.createElement('a');
            link.href = url;
            let file_name = url.split('/').pop();
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();
            setDrawer(false)
        });
    }

    const [limitOpen, setLimitOpen] = useState(false);

    const handleClickOpen = () => {
        LocalStorage.removeEmployeeVerifyId();
        addLoader(true);
        EmployeeAPI.checkEmployeeLimit(2).then((response) => {
            removeLoader();
            if (response.data.statusCode == 1003) {
                if (response.data.allowToAdd) {
                    setOpen(true);
                } else {
                    setLimitOpen(true);
                }
            }
        });

    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Onchange of the Label radio button
    const changeExportLabel = (key, event) => {
        // if (event.target.checked == true) { // If check is true
        setCurrentLabel(key) // Set Current label state to selected label
        //employeeExportData[key].status = true;
        setEmployeeExportFields(employeeExportData[key].data); // Set Export fields of the selected label
        //setEmployeeExportData([...employeeExportData]);
        // } else {
        //     employeeExportData[key].status = false;
        //     //setCurrentLabel(key) // Set Current label state to selected label
        //     setEmployeeExportFields(employeeExportData[key].data); // Set Export fields of the selected label
        //     //setEmployeeExportData([...employeeExportData]);
        //     clearAllFields();
        // }
    }

    // Onchange of the Check All checkbox
    const checkAllFields = (event) => {
        if (event.target.checked == true) { // If check is true
            employeeExportFields.filter((obj) => obj.field_name != 'reference_id').map((i) => i.status = true);
            setEmployeeExportFields(employeeExportFields); // select all fields
            employeeExportData[currentLabel].data = employeeExportFields;
            setEmployeeExportData(employeeExportData)
            checkAll[currentLabel] = true
            setCheckAll({ ...checkAll }) // set check true for All checkbox
        } else {
            employeeExportFields.filter((obj) => obj.field_name != 'reference_id').map((i) => i.status = false);
            setEmployeeExportFields(employeeExportFields); // unselect all fields
            employeeExportData[currentLabel].data = employeeExportFields;
            setEmployeeExportData(employeeExportData)
            checkAll[currentLabel] = false
            setCheckAll({ ...checkAll }) // set check false for All checkbox
        }
    }

    const clearAllFields = () => {
        //let a =[]
        employeeExportFields.filter((obj) => obj.field_name != 'reference_id').map((i) => i.status = false);
        setEmployeeExportFields(employeeExportFields); // unselect all fields
        //employeeExportData[currentLabel].data.push(employeeExportFields);
        //setEmployeeExportData(employeeExportData)
        checkAll[currentLabel] = false
        setCheckAll({ ...checkAll }) // set check false for All checkbox
    }

    //check/uncheck each field
    const checkUncheckField = (event, id) => {
        if (event.target.checked == true) { // if checked true
            employeeExportFields[id].status = true;
            //checkedFields.push(id); // push the selected field to the array
            if (employeeExportFields.length == employeeExportFields.filter((obj) => obj.status === true).length) { // if all the fields are checked
                checkAll[id] = true
                setCheckAll({ ...checkAll }) // set check true for All checkbox
            }
        } else { // if checked false
            if (employeeExportFields[id].field_name != 'reference_id') {
                checkAll[id] = false;
                setCheckAll({ ...checkAll }) // set check false for All checkbox
                employeeExportFields[id].status = false; // remove the field from selected list
            }
        }
        //exportElements[currentLabel] = [...checkedFields];
        setCheckAll({ ...id });
        setEmployeeExportFields(employeeExportFields); // push the selected fields to exportElement Object
        //employeeExportData[currentLabel].data.push(employeeExportFields);
        //setEmployeeExportData(employeeExportData)
    }

    const handleSearch = (e) => {
        // setOpenBacKLoader(true);
        setLoading(true);
        setExpanded(false);
        if (selectedOption === "All employees") {
            filterData.limit = 10;
            filterData.page = 1;
            filterData.search = e.target.value;
            setFilterData({ ...filterData });
            getAllEmployees(filterData);
        } else if (selectedOption === "Self Onboarded") {
            var newState = selfOnboardFilter;
            newState.page = 1;
            newState.limit = 10;
            newState.search = e.target.value;
            setSelfOnboardFilter({ ...newState })
            getAllEmployees(newState, "selfonboardfilter");
        } else if (selectedOption === "Internal Employees") {
            internalState.limit = 10;
            internalState.page = 1;
            internalState.search = e.target.value;
            setInternalState({ ...internalState });
            getAllInternalEmployees(internalState);
        }
    }

    const handleSearchClear = () => {
        // setOpenBacKLoader(true);
        setLoading(true);
        setExpanded(false);
        if (selectedOption === "All employees") {
            filterData.limit = 10;
            filterData.page = 1;
            filterData.search = '';
            setFilterData({ ...filterData });
            getAllEmployees(filterData);
        } else if (selectedOption === "Self Onboarded") {
            var newState = selfOnboardFilter;
            newState.page = 1;
            newState.limit = 10;
            newState.search = '';
            setSelfOnboardFilter({ ...newState })
            getAllEmployees(newState, "selfonboardfilter");
        } else if (selectedOption === "Internal Employees") {
            internalState.limit = 10;
            internalState.page = 1;
            internalState.search = '';
            setInternalState({ ...internalState });
            getAllInternalEmployees(internalState);
        }
    }

    const handleDeleteChip = (id, name) => {
        const newFilterState = { ...filterState };
        if (name === "status") {
            const updatedArray = newFilterState[name].filter(item => item !== id);
            newFilterState[name] = updatedArray;
        } else {
            const updatedArray = newFilterState[name].filter(item => item !== id);
            newFilterState[name] = updatedArray;
        }
        setFilterState(newFilterState);
    };

    const handleChangeCheckBox = (e) => {
        const { name, value } = e.target;
        const updatedArray = [...filterState[name]]; // Create a new array
        const index = updatedArray.findIndex(item => item === parseInt(value));
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(parseInt(value)); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const handleChangeCheckBoxStatus = (e) => {
        const { name, value } = e.target;
        const updatedArray = [...filterState[name]]; // Create a new array

        const index = updatedArray.findIndex(item => item === value);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(value); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const clearAllFilter = () => {
        // setSelectedFilter("");
        // filterState.employment = [];
        // filterState.category = [];
        // filterState.status = [];
        // filterState.visa = [];
        // setFilterState({ ...filterState });
        setFilterState(prevState => ({
            ...prevState,
            employment: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            category: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            status: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            visa: [] // Update the state with the new array
        }));
        // filterData.employment = [];
        // filterData.category = [];
        // filterData.status = [];
        // filterData.visa = [];
        // setFilterData({ ...filterData });
        // setDrawer(null);
        // setLoading(true);
        // getAllEmployees(filterData);
    }

    const cancelFilter = () => {
        setDrawer(false);
        setSelectedFilter("");
        // clearAllFilter()
        // getAllEmployees({ ...filterData, employment: [], category: [], status: [], visa: [], });
    }

    const handleApplyFilter = () => {
        setExpanded(false);
        filterData.employment = filterState.employment;
        filterData.category = filterState.category;
        filterData.visa = filterState.visa;
        filterData.status = filterState.status;
        filterData.limit = 10;
        filterData.page = 1;
        setFilterData({ ...filterData });
        addLoader(true);
        getAllEmployees(filterData)
    }
    const handleListItemClick = (param) => {
        setSelectedFilter(param);
    }

    const ExportView = () => (
        <Box sx={{
            width: '660px', height: '100vh',
            overflow: "auto",
            '&::-webkit-scrollbar': {
                width: '4px',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px #ffffff',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#C7CCD3',
                outline: '1px solid #C7CCD3',
                borderRadius: "4px",
            }
        }}>
            <Box height={'10vh'} borderBottom={'1px solid #EAECF0'} display={'flex'} alignItems={'center'} pl={4} >
                <Text headerBlack>Export</Text>
            </Box>

            <Box display={'flex'} height={'7vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={1} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>
                    <>
                        {
                            employeeExportData.length > 0 && employeeExportData[currentLabel].data
                                .map((item, key) => (
                                    <>
                                        {
                                            item.status === true
                                                ?
                                                <Chip label={item.label_name} variant="outlined" onDelete={(e) => checkUncheckField(e, key)} deleteIcon={<CloseIcon />} />
                                                : ''
                                        }
                                    </>
                                )
                                )
                        }
                    </>

                </Box>
                <Button clearAll startIcon={<CloseIcon />} onClick={clearAllFields}>Clear All</Button>
            </Box>

            <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'73vh'} >
                <Box display={'flex'} flexDirection={'column'} gap={1} height={'100%'} width={'45%'} borderRight={'1px solid #EAECF0'} >
                    <List component="nav" aria-label="secondary mailbox folder">
                        {
                            employeeExportData.length > 0 && employeeExportData.map((item, key) => (
                                <>
                                    <ListItem className={currentLabel == key ? classes.listItemActive : classes.listItem} onClick={() => changeExportLabel(key)} >
                                        <ListItemText primary={currentLabel == key ? <Text smallBlue1 ml={3}>{item.name}</Text> : <Text mediumBlack ml={3}>{item.name}</Text>} />
                                    </ListItem>
                                    <Divider />
                                </>
                            ))
                        }

                    </List>

                    {/* <RadioGroup sx={{
                        gap: '16px !important'
                    }}
                        defaultValue="Personal Information"

                    >  {
                            employeeExportData.map((item, key) => (
                                <FormControlLabel
                                    key={key}
                                    value={item.name}
                                    control={<Radio icon={<RadioIcon />}
                                        onChange={(e) => changeExportLabel(key, e)}
                                        value={item.name}
                                        checked={currentLabel == key}
                                        checkedIcon={<RadioCheckedIcon />} />}
                                    label={<Text checkboxlable >{item.name}</Text>}
                                />
                            ))
                        }
                    </RadioGroup> */}

                </Box>
                <Box
                    pl={5} pt={2}
                    sx={{
                        isplay: "flex",
                        flexDirection: "column",
                        gap: 1,
                        height: "100%",
                        width: "55%", paddingLeft: 5, overflow: "auto",
                        '&::-webkit-scrollbar': {
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                            '-webkit-box-shadow': 'inset 0 0 6px #ffffff'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#C7CCD3',
                            outline: '1px solid #C7CCD3',
                            borderRadius: "4px"
                        }
                    }} >
                    <FormControl sx={{
                        gap: '16px !important'
                    }}>
                        <FormControlLabel control={<Checkbox icon={<CheckBorderIcon />} onChange={checkAllFields} checked={employeeExportFields.length == employeeExportFields.filter((obj) => obj.status === true).length} checkedIcon={<CheckedIcon />} />} label={<Text checkboxlable >All</Text>} />
                        {
                            employeeExportFields.map((item, key) => (
                                <FormControlLabel key={key} control={<Checkbox icon={<CheckBorderIcon />} onChange={(e) => checkUncheckField(e, key)} value={item} checked={item.status} checkedIcon={<CheckedIcon />} />} label={<Text checkboxlable >{item.label_name}</Text>} />
                            ))
                        }
                    </FormControl>


                </Box>
            </Box>

            <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={1} height={'73px'} pr={'26px'}>
                <Button cancelSmall onClick={() => setDrawer(false)}>Cancel</Button>
                <Button exportSmall onClick={employeeExport}>Export</Button>
            </Box>

        </Box >
    )

    const handleSelectChange = (value) => {
        LocalStorage.removeEmployeeVerifyId();
        setExpanded(false);
        setSelectedOption(value);
        paginationTotal.total = null;
        paginationTotal.employees = [];
        setPaginationTotal({ ...paginationTotal });
        if (value === 'All employees') {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) {
                setLoading(true);
                setSelfOnboardFilter({ ...selfOnboardFilter, page: 1, limit: 10, sort_column: "created_at", sort_order: "DESC", status: [] })
                setEmployees([]);
                setFilterData({ ...filterData, limit: 10, page: 1, employment: [], category: [], status: [], visa: [], sort_column: "emp.created_at", sort_order: "DESC" })
                getAllEmployees({ ...filterData, limit: 10, page: 1, employment: [], category: [], status: [], visa: [], sort_column: "emp.created_at", sort_order: "DESC" });
            }
        } else if (value == "Internal Employees") {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed == true))) {
                setLoading(true);
                setEmployees([]);
                internalState.limit = 10;
                internalState.page = 1;
                internalState.search = '';
                setInternalState({ ...internalState });
                getAllInternalEmployees(internalState);
            }
        } else if (value == "Self Onboarded") {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) {
                setLoading(true);
                setFilterData({ ...filterData, limit: 10, page: 1, employment: [], category: [], status: [], visa: [], sort_column: "emp.created_at", sort_order: "DESC" })
                setEmployees([]);
                setSelfOnboardFilter({ ...selfOnboardFilter, page: 1, limit: 10, sort_column: "created_at", sort_order: "DESC", status: [] })
                getAllEmployees({ ...selfOnboardFilter, page: 1, limit: 10, sort_column: "created_at", sort_order: "DESC", status: [] }, "selfonboardfilter");
            }
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const openStatus = Boolean(anchorEl1);
    const openFilter = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const handleStatusOpen = (event) => {
        setAnchorEl1(event.currentTarget);
    }
    const handleStatusClose = (param) => {
        setAnchorEl1(null);
        // setOpenBacKLoader(true);
        setLoading(true);
        setExpanded(false);
        if (param === undefined) {
            filterData.limit = 10;
            filterData.page = 1;
            filterData.status = [];
            setFilterData({ ...filterData });
            getAllEmployees(filterData);
        } else {
            filterData.limit = 10;
            filterData.page = 1;
            filterData['status'] = [param]
            setFilterData({ ...filterData });
            getAllEmployees(filterData);
        }
    }

    const handleSelfStatusClose = (param) => {
        setExpanded(false);
        setAnchorEl1(null);
        // setOpenBacKLoader(true);
        setLoading(true);
        if (param === undefined) {
            var newState1 = selfOnboardFilter;
            newState1.status = [];
            setSelfOnboardFilter({ ...newState1 })
            getAllEmployees(newState1, "selfonboardfilter");
        } else {
            if (param === 2) {
                var newState2 = selfOnboardFilter;
                newState2.status = ["Waiting", "Pending", "Invitation Sent"];
                setSelfOnboardFilter({ ...newState2 })
                getAllEmployees(newState2, "selfonboardfilter");
            } else {
                var newState = selfOnboardFilter;
                newState.status = [param];
                setSelfOnboardFilter({ ...newState })
                getAllEmployees(newState, "selfonboardfilter");
            }
        }
    }

    const handleSort = (sort_column, sort_order) => {
        setExpanded(false);
        setAnchorEl(null);
        // setOpenBacKLoader(true);
        setLoading(true);
        if (selectedOption == "All employees") {
            filterData.sort_column = sort_column;
            filterData.sort_order = sort_order;
            setFilterData({ ...filterData });
            getAllEmployees({ ...filterData });
        } else if (selectedOption === "Self Onboarded") {
            selfOnboardFilter.sort_column = sort_column;
            selfOnboardFilter.sort_order = sort_order;
            setSelfOnboardFilter({ ...selfOnboardFilter });
            getAllEmployees({ ...selfOnboardFilter }, "selfonboardfilter");
        } else {
            internalState.sort_column = sort_column;
            internalState.sort_order = sort_order;
            setInternalState({ ...internalState });
            getAllInternalEmployees(internalState);
        }
    }

    const loadMoreData = () => {
        if (selectedOption == "All employees") {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) {
                filterData.limit = Number(filterData.limit) + 6;
                setFilterData({ ...filterData });
                getAllEmployees(filterData);
            }
        } else if (selectedOption == "Internal Employees") {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed == true))) {
                internalState.limit = Number(internalState.limit) + 6;
                setInternalState({ ...internalState });
                getAllInternalEmployees(internalState);
            }
        } else {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) {
                var newState = selfOnboardFilter;
                newState.limit = Number(newState.limit) + 6;
                setSelfOnboardFilter({ ...newState })
                getAllEmployees(selfOnboardFilter, "selfonboardfilter");
            }
        }
    }

    const handleOpenDrawer = () => {
        let selected = '';

        if (filterData.employment.length > 0) {
            selected = 'type';
        } else if (filterData.category.length > 0) {
            selected = 'category';
        } else if (filterData.visa.length > 0) {
            selected = 'visa';
        } else if (filterData.status.length > 0) {
            selected = 'status';
        }

        setFilterState({
            employment: filterData.employment,
            category: filterData.category,
            visa: filterData.visa,
            status: filterData.status
        });

        setSelectedFilter(selected);
        setDrawer("filter");
    }

    /**
* Function to replace the last occurrence of '/' in the current URL with a specified new segment.
* If no '/' is found, it logs an error.
* 
* @returns {string} The modified URL with the last '/' replaced by the new segment.
*/

    const replaceLastSlash = (param) => {
        const originalUrl = window.location.href;
        // Find the last occurrence of '/'
        const lastSlashIndex = originalUrl.lastIndexOf('/');

        // Check if a '/' is found in the URL
        if (lastSlashIndex !== -1) {
            // Replace the last '/' with another character or string
            const newSegment = '/employee/verify';
            const modified = originalUrl.substring(0, lastSlashIndex) + newSegment;
            return modified;
        } else {
            console.error("No '/' found in the URL");
        }
    };

    const handleVerify = (param) => {
        LocalStorage.setEmployeeVerifyId(param);
        window.open(`${replaceLastSlash()}`, '_blank');
    }
    const scrollBox = useRef(null);

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(paginationTotal.total) >= filterData.limit) {
                    if (selectedOption == "Internal Employees" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed == true)))) {
                        // setOpenBacKLoader(true);
                        setListLoading(true);
                    }
                    if (selectedOption !== "Internal Employees" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true)))) {
                        // setOpenBacKLoader(true);
                        setListLoading(true);
                    }
                    loadMoreData();
                }
            } else {
            }
        }
    };

    const handleInternalExport = () => {
        EmployeeAPI.getInternalExport().then((res) => {
            if (res.data.statusCode == 1003) {
                setTimeout(() => {
                    addSuccessMsg("File downloaded successfully");
                }, 1000)
                FileSaver.saveAs(res.data.data[0].file_path);
            } else {
                addErrorMsg(res.data.message)
            }
        })
    }

    const upgradePlan = () => {
        let planDetails = LocalStorage.getPlanCheckLocal()
        LocalStorage.setlocationPath(location.pathname)
        navigate('/billing', {
            state: {
                renew: true, status: (planDetails.is_subscription_cancelled && (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly')) ? 'upgrade' :
                    ((planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') && (planDetails.subscription_expiring || planDetails.subscription_expired || !planDetails?.is_plan_auto_renewed)) ? 'renew' :
                        (planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') ? 'advanceUpgrade' : (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly') ? 'activeUpgrade' : 'activeUpgrade',
                renewal: planDetails.subscription_expiring || planDetails.subscription_expired ? true : false,
                autoRenewAdvance: !planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly' ? false : true, autoRenewStarterMonthly: planDetails?.is_plan_auto_renewed ? false : true,
                autoRenew: (!planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly') ? true : false,
                autoRenewStarter: !planDetails?.is_plan_auto_renewed ? true : false
            }
        })
        setLimitOpen(false)
    }

    const unLinkAssignee = (id, name) => {
        let data = {
            request_id: LocalStorage.uid(),
            id: id
        }
        EmployeeAPI.employeeUnlinkAssign(data).then((res) => {
            if (res.data.statusCode == 1003) {
                addSuccessMsg(`Un-linked successfully. ${name} Moved back to verification pool`);
                handleSelectChange("Self Onboarded")
            }
            else {
                addErrorMsg(res.data.message)
            }
        })
    }

    const handleZohosync = (e,id) => {
        e.stopPropagation();
        setsyncing(true);
        setCurrentSyncingId(id);
        const data = {
            request_id:LocalStorage.uid(),
            id
        }
        EmployeeAPI.zohoIntegration(data).then((res) => {
            if (res.data.statusCode == 1003) {
                setTimeout(() => {
                    getAllEmployees(filterData);
                    addSuccessMsg(res.data.message)
                }, 2000);
                
            } else {
                setsyncing(false);
                setCurrentSyncingId('');
                addErrorMsg(res.data.message);
                
            }
        })
    }


    return (
        <Box className={classes.flexBox} sx={{
            "@media (min-width: 968px)": {
                paddingLeft: '65px'
            }
        }}>
            {startTour ? <TourEmployees startTour={startTour} setStartTour={() => setStartTour(false)} /> : <>

                {
                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => (item.slug == "employee_view" || item.slug == "internal_employee_view") && item.is_allowed == true))) ?
                        <Box sx={{ width: "90%" }} >
                            <Box style={{ padding: "20px 10px 10px 10px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Box className={classes.btnBox}>
                                        <Button onClick={() => handleSelectChange("All employees")} saveBtn className={selectedOption == "All employees" ? classes.activeButton : classes.inActiveButton}>All Employees</Button>
                                        <Button onClick={() => handleSelectChange("Self Onboarded")} saveBtn className={selectedOption == "Self Onboarded" ? classes.activeButton : classes.inActiveButton} sx={{ marginLeft: "8px" }}>Onboarders</Button>
                                        <Button onClick={() => handleSelectChange("Internal Employees")} saveBtn className={selectedOption == "Internal Employees" ? classes.activeButton1 : classes.inActiveButton1} sx={{ marginLeft: "8px" }}>Internal Employees</Button>
                                    </Box>
                                    <div>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingRight: "5px" }}>
                                            {selectedOption == "Internal Employees" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed !== true))) ? "" :
                                                selectedOption !== "Internal Employees" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed !== true))) ? "" :
                                                    <Hidden mdDown>
                                                        <Box sx={{
                                                            height: "44px", border: "1.5px solid #E2E5E6", width: "438px", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center",
                                                            "@media (min-width: 1100px) and (max-width: 1400px)": {
                                                                width: "238px"
                                                            },
                                                            "@media (min-width: 900px) and (max-width: 1100px)": {
                                                                width: "188px",
                                                                marginLeft: '5px'
                                                            },
                                                        }}>
                                                            <input
                                                                type="text"
                                                                value={selectedOption == "Internal Employees" ? internalState.search : selectedOption == "All employees" ? filterData.search : selfOnboardFilter.search}
                                                                className={classes.EmployeesSearchInput}
                                                                onChange={handleSearch}
                                                                placeholder={`${selectedOption == 'All employees' ? 'Search by Name / ID' : 'Search by Name'}`}
                                                            />
                                                            <Box sx={{ paddingRight: "15px !important" }}>
                                                                {
                                                                    (internalState.search !== "" || filterData.search !== "" || selfOnboardFilter.search !== "") ?
                                                                        <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                                        :
                                                                        <img src={Search} alt="Search" />
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </Hidden>
                                            }

                                            {selectedOption == "All employees" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) &&
                                                <Hidden mdDown>
                                                    <button title="Filter" type="button" className={classes.borderButton} onClick={() => { handleOpenDrawer() }}>
                                                        <Badge badgeContent={filterData.employment.length + filterData.category.length + filterData.visa.length + filterData.status.length} color="error">
                                                            <FilterListIcon sx={{ color: filterData.employment.length > 0 || filterData.category.length > 0 || filterData.visa.length > 0 || filterData.status.length > 0 ? "#2BEA2B" : "" }} />
                                                        </Badge>
                                                    </button>
                                                </Hidden>
                                            }
                                            {selectedOption == "All employees" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) &&
                                                <Hidden mdDown>
                                                    <button title="Export" type="button" className={classes.borderButton} onClick={() => { getEmployeeExportData(); setDrawer("export") }}>
                                                        <img src={NewDownloadcloud} alt="Userplus" />
                                                    </button>
                                                </Hidden>
                                            }
                                            {selectedOption == "Internal Employees" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed == true))) &&
                                                <Hidden mdDown>
                                                    <button title="Export" type="button" className={classes.borderButton} onClick={() => handleInternalExport()}>
                                                        <img src={NewDownloadcloud} alt="Userplus" />
                                                    </button>
                                                </Hidden>
                                            }

                                            {selectedOption !== "Internal Employees" &&
                                                <>
                                                    {
                                                        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) ?
                                                            <Hidden mdDown>
                                                                <Button sx={{ padding: "0px 15px !important" }} addButton id="add-employee" onClick={() => handleClickOpen()} startIcon={<img src={Userplus} alt="Userplus" className={classes.addIcon} />}>
                                                                    Add Employee
                                                                </Button>
                                                            </Hidden>
                                                            :
                                                            ""
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>

                                </div>
                                <Hidden mdUp>
                                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: "end", alignItems: "center", padding: '10px', gap: '10px' }}>
                                        <Hidden mdUp>
                                            <div style={{ height: "44px", border: "1.5px solid #E2E5E6", width: "368px", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                                <input
                                                    type="text"
                                                    value={selectedOption == "Internal Employees" ? internalState.search : selectedOption == "All employees" ? filterData.search : selfOnboardFilter.search}
                                                    className={classes.EmployeesSearchInput}
                                                    onChange={handleSearch}
                                                    placeholder="Search by Name / ID"
                                                />
                                                <Box sx={{ paddingRight: "15px !important" }}>
                                                    {
                                                        (internalState.search !== "" || filterData.search !== "" || selfOnboardFilter.search !== "") ?
                                                            <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                            :
                                                            <img src={Search} alt="Search" />
                                                    }
                                                </Box>
                                            </div>
                                        </Hidden>
                                        {selectedOption == "All employees" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) &&
                                            <button type="button" className={classes.borderButton} onClick={() => { handleOpenDrawer() }}>
                                                <Badge badgeContent={filterData.employment.length + filterData.category.length + filterData.visa.length + filterData.status.length} color="error">
                                                    <FilterListIcon sx={{ color: filterData.employment.length > 0 || filterData.category.length > 0 || filterData.visa.length > 0 || filterData.status.length > 0 ? "#2BEA2B" : "" }} />
                                                </Badge>
                                            </button>
                                        }
                                        {selectedOption == "All employees" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) &&
                                            <button type="button" className={classes.borderButton} onClick={() => { getEmployeeExportData(); setDrawer("export") }}>
                                                <img src={NewDownloadcloud} alt="Userplus" />
                                            </button>
                                        }
                                        {selectedOption == "Internal Employees" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed == true))) &&
                                            <button type="button" className={classes.borderButton} onClick={() => handleInternalExport()}>
                                                <img src={NewDownloadcloud} alt="Userplus" />
                                            </button>}

                                        {selectedOption !== "Internal Employees" &&
                                            <>
                                                {
                                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) ?
                                                        <Button sx={{ padding: "0px 15px !important" }} addButton id="add-employee" onClick={() => handleClickOpen()} startIcon={<img src={Userplus} alt="Userplus" className={classes.addIcon} />}>
                                                            Add Employee
                                                        </Button>
                                                        :
                                                        ""
                                                }
                                            </>
                                        }
                                    </div>

                                </Hidden>
                            </Box>

                            <Box style={{ padding: "10px" }}>
                                {selectedOption !== "Internal Employees" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed !== true))) ? "" :
                                    selectedOption == "Internal Employees" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed !== true))) ? "" :
                                        <Box sx={{
                                            display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px",
                                            "@media (min-width: 200px) and (max-width: 1400px)": {
                                                fontSize: "12px"
                                            },
                                        }} >
                                            {
                                                selectedOption === "Internal Employees" ?
                                                    <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                        <Hidden mdUp>
                                                            <Grid item lg={3} md={4} sm={4} xs={5} textAlign={'left'} letterSpacing={'0.5px'}>Employee Name
                                                                <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} onClick={handleClick}>
                                                                    <FilterListIcon sx={{ height: "19px", color: (selectedOption === "Internal Employees" && (internalState.sort_column == "display_name" || internalState.sort_column == "created_at")) ? "#2BEA2B" : "" }} />
                                                                </IconButton>
                                                                <Menu
                                                                    anchorEl={anchorEl}
                                                                    id="account-menu"
                                                                    open={openFilter}
                                                                    onClose={handleCloseFilter}
                                                                    onClick={handleCloseFilter}
                                                                    PaperProps={{
                                                                        elevation: 0,
                                                                        sx: {
                                                                            overflow: "visible",
                                                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                            mt: 0.5,
                                                                            ml: -2,
                                                                            padding: "5px",
                                                                            "& .MuiMenuItem-root:hover": {
                                                                                backgroundColor: "#EAECF0 !important",
                                                                            }
                                                                        },
                                                                    }}
                                                                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                                                >
                                                                    <MenuItem className={classes.menuItem1} sx={{ background: internalState.sort_column == 'created_at' && internalState.sort_order == 'DESC' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'DESC')}> Newest First</MenuItem>
                                                                    <MenuItem className={classes.menuItem1} sx={{ background: internalState.sort_column == 'created_at' && internalState.sort_order == 'ASC' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'ASC')}> Oldest First</MenuItem>
                                                                    <MenuItem className={classes.menuItem1} sx={{ background: internalState.sort_column == 'display_name' && internalState.sort_order == 'ASC' ? '#EAECF0' : "" }} onClick={() => handleSort('display_name', 'ASC')}> Name (A-Z)</MenuItem>
                                                                    <MenuItem className={classes.menuItem1} sx={{ background: internalState.sort_column == 'display_name' && internalState.sort_order == 'DESC' ? '#EAECF0' : "" }} onClick={() => handleSort('display_name', 'DESC')}> Name (Z-A)</MenuItem>
                                                                </Menu>
                                                            </Grid>
                                                        </Hidden>
                                                        <Hidden mdDown>
                                                            <Grid item lg={4.5} md={4} sm={4} xs={5} textAlign={'left'} letterSpacing={'0.5px'}>Employee Name
                                                                <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} onClick={handleClick}>
                                                                    <FilterListIcon sx={{ height: "19px", color: (selectedOption === "Internal Employees" && (internalState.sort_column == "display_name" || internalState.sort_column == "created_at")) ? "#2BEA2B" : "" }} />
                                                                </IconButton>
                                                                <Menu
                                                                    anchorEl={anchorEl}
                                                                    id="account-menu"
                                                                    open={openFilter}
                                                                    onClose={handleCloseFilter}
                                                                    onClick={handleCloseFilter}
                                                                    PaperProps={{
                                                                        elevation: 0,
                                                                        sx: {
                                                                            overflow: "visible",
                                                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                            mt: 0.5,
                                                                            ml: -2,
                                                                            padding: "5px",
                                                                            "& .MuiMenuItem-root:hover": {
                                                                                backgroundColor: "#EAECF0 !important",
                                                                            }
                                                                        },
                                                                    }}
                                                                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                                                >
                                                                    <MenuItem className={classes.menuItem1} sx={{ background: internalState.sort_column == 'created_at' && internalState.sort_order == 'DESC' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'DESC')}> Newest First</MenuItem>
                                                                    <MenuItem className={classes.menuItem1} sx={{ background: internalState.sort_column == 'created_at' && internalState.sort_order == 'ASC' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'ASC')}> Oldest First</MenuItem>
                                                                    <MenuItem className={classes.menuItem1} sx={{ background: internalState.sort_column == 'display_name' && internalState.sort_order == 'ASC' ? '#EAECF0' : "" }} onClick={() => handleSort('display_name', 'ASC')}> Name (A-Z)</MenuItem>
                                                                    <MenuItem className={classes.menuItem1} sx={{ background: internalState.sort_column == 'display_name' && internalState.sort_order == 'DESC' ? '#EAECF0' : "" }} onClick={() => handleSort('display_name', 'DESC')}> Name (Z-A)</MenuItem>
                                                                </Menu>
                                                            </Grid>
                                                        </Hidden>
                                                        <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerNames} >Role</Grid>
                                                        <Grid item lg={2.8} md={3} sm={3} xs={3} textAlign={'left'}>E-mail Id</Grid>
                                                        <Hidden mdUp>
                                                            <Grid item container lg={2.4} md={2} sm={3} xs={2} textAlign={'left'}>
                                                                <Grid item sm={8}>
                                                                    Mobile No
                                                                </Grid>
                                                                <Grid item sm={4} >
                                                                    <Text offBoardBodyFont noWrap>
                                                                        Count {` - ${pagination ? pagination.total ? pagination.total : 0 : 0}`}
                                                                    </Text>
                                                                </Grid>


                                                            </Grid>
                                                        </Hidden>
                                                        <Hidden mdDown>
                                                            <Grid item lg={2.4} md={3} sm={3} xs={2} display={"flex"} textAlign={'left'} flexGrow={'1'}>
                                                                <Stack direction={'row'}>
                                                                    <Box>
                                                                        Mobile No &nbsp;&nbsp;
                                                                    </Box>
                                                                    <Box>
                                                                        <Text offBoardBodyFont >
                                                                            Count
                                                                            <HtmlTooltip
                                                                                placement="bottom"
                                                                                arrow
                                                                                title={
                                                                                    <React.Fragment>
                                                                                        <Box>
                                                                                            <Typography className={classes.profileTooltipText} noWrap>
                                                                                                Total Employee count is based on the filters applied
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </React.Fragment>
                                                                                }
                                                                            >
                                                                                <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                                                            </HtmlTooltip>
                                                                            {` - ${pagination ? pagination.total ? pagination.total : 0 : 0} Employees`}</Text>
                                                                    </Box>
                                                                </Stack>
                                                            </Grid>
                                                        </Hidden>

                                                    </Grid>
                                                    :
                                                    <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                        <Hidden mdUp>
                                                            <Grid item lg={3} md={5} sm={4} xs={5} textAlign={'left'} letterSpacing={'0.5px'}>Employee Name
                                                                <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} onClick={handleClick}>
                                                                    <FilterListIcon sx={{ height: "19px", color: (selectedOption === "All employees" && (filterData.sort_column == "emp.display_name" || filterData.sort_column == "emp.created_at")) || (selectedOption === "Self Onboarded" && (selfOnboardFilter.sort_column == 'created_at' || selfOnboardFilter.sort_column == 'first_name')) ? "#2BEA2B" : "" }} />
                                                                </IconButton>
                                                                <Menu
                                                                    anchorEl={anchorEl}
                                                                    id="account-menu"
                                                                    open={openFilter}
                                                                    onClose={handleCloseFilter}
                                                                    onClick={handleCloseFilter}
                                                                    PaperProps={{
                                                                        elevation: 0,
                                                                        sx: {
                                                                            overflow: "visible",
                                                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                            mt: 0.5,
                                                                            ml: -2,
                                                                            padding: "5px",
                                                                            "& .MuiMenuItem-root:hover": {
                                                                                backgroundColor: "#EAECF0 !important",
                                                                            }
                                                                        },
                                                                    }}
                                                                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                                                >
                                                                    {
                                                                        selectedOption == "All employees" ?
                                                                            <>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'emp.created_at' && filterData.sort_order == 'DESC' ? '#EAECF0' : "" }} onClick={() => handleSort('emp.created_at', 'DESC')}> Newest First</MenuItem>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'emp.created_at' && filterData.sort_order == 'ASC' ? '#EAECF0' : "" }} onClick={() => handleSort('emp.created_at', 'ASC')}> Oldest First</MenuItem>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'emp.display_name' && filterData.sort_order == 'ASC' ? '#EAECF0' : "" }} onClick={() => handleSort('emp.display_name', 'ASC')}> Name (A-Z)</MenuItem>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'emp.display_name' && filterData.sort_order == 'DESC' ? '#EAECF0' : "" }} onClick={() => handleSort('emp.display_name', 'DESC')}> Name (Z-A)</MenuItem>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: selfOnboardFilter.sort_column == 'created_at' && selfOnboardFilter.sort_order == 'DESC' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'DESC')}> Newest First</MenuItem>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: selfOnboardFilter.sort_column == 'created_at' && selfOnboardFilter.sort_order == 'ASC' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'ASC')}> Oldest First</MenuItem>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: selfOnboardFilter.sort_column == 'first_name' && selfOnboardFilter.sort_order == 'ASC' ? '#EAECF0' : "" }} onClick={() => handleSort('first_name', 'ASC')}> Name (A-Z)</MenuItem>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: selfOnboardFilter.sort_column == 'first_name' && selfOnboardFilter.sort_order == 'DESC' ? '#EAECF0' : "" }} onClick={() => handleSort('first_name', 'DESC')}> Name (Z-A)</MenuItem>
                                                                            </>

                                                                    }

                                                                </Menu>
                                                            </Grid>
                                                        </Hidden>
                                                        <Hidden mdDown>
                                                            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={'left'} letterSpacing={'0.5px'}>Employee Name
                                                                <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} onClick={handleClick}>
                                                                    <FilterListIcon sx={{ height: "19px", color: (selectedOption === "All employees" && (filterData.sort_column == "emp.display_name" || filterData.sort_column == "emp.created_at")) || (selectedOption === "Self Onboarded" && (selfOnboardFilter.sort_column == 'created_at' || selfOnboardFilter.sort_column == 'first_name')) ? "#2BEA2B" : "" }} />
                                                                </IconButton>
                                                                <Menu
                                                                    anchorEl={anchorEl}
                                                                    id="account-menu"
                                                                    open={openFilter}
                                                                    onClose={handleCloseFilter}
                                                                    onClick={handleCloseFilter}
                                                                    PaperProps={{
                                                                        elevation: 0,
                                                                        sx: {
                                                                            overflow: "visible",
                                                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                            mt: 0.5,
                                                                            ml: -2,
                                                                            padding: "5px",
                                                                            "& .MuiMenuItem-root:hover": {
                                                                                backgroundColor: "#EAECF0 !important",
                                                                            }
                                                                        },
                                                                    }}
                                                                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                                                >
                                                                    {
                                                                        selectedOption == "All employees" ?
                                                                            <>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'emp.created_at' && filterData.sort_order == 'DESC' ? '#EAECF0' : "" }} onClick={() => handleSort('emp.created_at', 'DESC')}> Newest First</MenuItem>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'emp.created_at' && filterData.sort_order == 'ASC' ? '#EAECF0' : "" }} onClick={() => handleSort('emp.created_at', 'ASC')}> Oldest First</MenuItem>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'emp.display_name' && filterData.sort_order == 'ASC' ? '#EAECF0' : "" }} onClick={() => handleSort('emp.display_name', 'ASC')}> Name (A-Z)</MenuItem>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'emp.display_name' && filterData.sort_order == 'DESC' ? '#EAECF0' : "" }} onClick={() => handleSort('emp.display_name', 'DESC')}> Name (Z-A)</MenuItem>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: selfOnboardFilter.sort_column == 'created_at' && selfOnboardFilter.sort_order == 'DESC' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'DESC')}> Newest First</MenuItem>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: selfOnboardFilter.sort_column == 'created_at' && selfOnboardFilter.sort_order == 'ASC' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'ASC')}> Oldest First</MenuItem>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: selfOnboardFilter.sort_column == 'first_name' && selfOnboardFilter.sort_order == 'ASC' ? '#EAECF0' : "" }} onClick={() => handleSort('first_name', 'ASC')}> Name (A-Z)</MenuItem>
                                                                                <MenuItem className={classes.menuItem1} sx={{ background: selfOnboardFilter.sort_column == 'first_name' && selfOnboardFilter.sort_order == 'DESC' ? '#EAECF0' : "" }} onClick={() => handleSort('first_name', 'DESC')}> Name (Z-A)</MenuItem>
                                                                            </>

                                                                    }

                                                                </Menu>
                                                            </Grid>
                                                        </Hidden>
                                                        <Grid item lg={3} md={2} sm={3} xs={3} paddingRight={'17px'} style={{ display: "flex", alignItems: "center", justifyContent: "left", marginLeft: "-5px" }} >Employment Type</Grid>
                                                        <Hidden mdUp>
                                                            <Grid container item lg={2} md={3} sm={3} xs={2}>
                                                                {selectedOption === "All employees" &&
                                                                    <Grid item lg={6} style={{ display: "flex", alignItems: "center", justifyContent: "left", marginLeft: "-14px" }} >Visa Type</Grid>}
                                                                <Grid aria-controls="status-menu" item lg={6} md={6} sm={6} xs={6} style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: selectedOption === "All employees" ? "end" : "start" }} onClick={handleStatusOpen} >Status
                                                                    {
                                                                        openStatus ?
                                                                            <KeyboardArrowUpIcon sx={{ color: (filterData.sort_column == 'emp.sub_status' || filterData.status.length > 0) || (selectedOption !== "All employees" && selfOnboardFilter.status.length > 0) ? "#2BEA2B !important" : "" }} />
                                                                            :
                                                                            <KeyboardArrowDownIcon sx={{ color: (filterData.sort_column == 'emp.sub_status' || filterData.status.length > 0) || (selectedOption !== "All employees" && selfOnboardFilter.status.length > 0) ? "#2BEA2B !important" : "" }} />
                                                                    }
                                                                </Grid>
                                                                <Menu
                                                                    anchorEl={anchorEl1}
                                                                    id="status-menu"
                                                                    open={openStatus}
                                                                    onClose={() => { setAnchorEl1(null) }}
                                                                    PaperProps={{
                                                                        elevation: 0,
                                                                        sx: {
                                                                            overflow: "visible",
                                                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                            mt: 0.5,
                                                                            width: "170px",
                                                                            "& .MuiMenuItem-root:hover": {
                                                                                backgroundColor: "#EAECF0 !important",
                                                                            },
                                                                        }
                                                                    }}
                                                                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                                                >
                                                                    {selectedOption === "All employees" ?
                                                                        <>
                                                                            <MenuItem onClick={() => { handleStatusClose() }} className={classes.menuItem}>All</MenuItem> <Divider className={classes.divider} />
                                                                            <MenuItem onClick={() => { handleStatusClose("Active") }} className={filterData.status.length > 0 && filterData.status[0] == "Active" ? classes.activeMenuItem : classes.menuItem}> Active Employees</MenuItem> <Divider className={classes.divider} />
                                                                            <MenuItem onClick={() => { handleStatusClose("In Active") }} className={filterData.status.length > 0 && filterData.status[0] == "In Active" ? classes.activeMenuItem : classes.menuItem}> Inactive Employees</MenuItem>
                                                                        </> :
                                                                        <>
                                                                            <MenuItem onClick={() => { handleSelfStatusClose() }} className={classes.menuItem}>All</MenuItem> <Divider className={classes.divider} />
                                                                            <MenuItem onClick={() => { handleSelfStatusClose("Offer Accepted") }} className={selfOnboardFilter.status.length > 0 && selfOnboardFilter.status[0] == "Offer Accepted" ? classes.activeMenuItem : classes.menuItem} >Offer Accepted</MenuItem> <Divider className={classes.divider} />
                                                                            <MenuItem onClick={() => { handleSelfStatusClose("In Progress") }} className={selfOnboardFilter.status.length > 0 && selfOnboardFilter.status[0] == "In Progress" ? classes.activeMenuItem : classes.menuItem} >InProgress</MenuItem><Divider className={classes.divider} />
                                                                            <MenuItem onClick={() => { handleSelfStatusClose("Link Expired") }} className={selfOnboardFilter.status.length > 0 && selfOnboardFilter.status[0] == "Link Expired" ? classes.activeMenuItem : classes.menuItem}>Link Expired</MenuItem> <Divider className={classes.divider} />
                                                                            <MenuItem onClick={() => { handleSelfStatusClose(2) }} className={selfOnboardFilter.status.length > 0 && selfOnboardFilter.status[0] == "Waiting" ? classes.activeMenuItem : classes.menuItem} >Waiting</MenuItem> <Divider className={classes.divider} />
                                                                            <MenuItem onClick={() => { handleSelfStatusClose("Offer Rejected") }} className={selfOnboardFilter.status.length > 0 && selfOnboardFilter.status[0] == "Offer Rejected" ? classes.activeMenuItem : classes.menuItem} >Offer Rejected</MenuItem>
                                                                        </>
                                                                    }
                                                                </Menu>
                                                            </Grid>
                                                        </Hidden>
                                                        <Hidden mdDown>
                                                            <Grid container item lg={2} md={3} sm={2} xs={2}>
                                                                {selectedOption === "All employees" &&
                                                                    <Grid item lg={6} style={{ display: "flex", alignItems: "center", justifyContent: "left", marginLeft: "-14px" }} >Visa Type</Grid>}
                                                                <Grid aria-controls="status-menu" item lg={6} md={6} sm={6} xs={6} style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: selectedOption === "All employees" ? "end" : "start" }} onClick={handleStatusOpen} >Status
                                                                    {
                                                                        openStatus ?
                                                                            <KeyboardArrowUpIcon sx={{ color: (filterData.sort_column == 'emp.sub_status' || filterData.status.length > 0) || (selectedOption !== "All employees" && selfOnboardFilter.status.length > 0) ? "#2BEA2B !important" : "" }} />
                                                                            :
                                                                            <KeyboardArrowDownIcon sx={{ color: (filterData.sort_column == 'emp.sub_status' || filterData.status.length > 0) || (selectedOption !== "All employees" && selfOnboardFilter.status.length > 0) ? "#2BEA2B !important" : "" }} />
                                                                    }
                                                                </Grid>
                                                                <Menu
                                                                    anchorEl={anchorEl1}
                                                                    id="status-menu"
                                                                    open={openStatus}
                                                                    onClose={() => { setAnchorEl1(null) }}
                                                                    PaperProps={{
                                                                        elevation: 0,
                                                                        sx: {
                                                                            overflow: "visible",
                                                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                            mt: 0.5,
                                                                            width: "170px",
                                                                            "& .MuiMenuItem-root:hover": {
                                                                                backgroundColor: "#EAECF0 !important",
                                                                            },
                                                                        }
                                                                    }}
                                                                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                                                >
                                                                    {selectedOption === "All employees" ?
                                                                        <>
                                                                            <MenuItem onClick={() => { handleStatusClose() }} className={classes.menuItem}>All</MenuItem> <Divider className={classes.divider} />
                                                                            <MenuItem onClick={() => { handleStatusClose("Active") }} className={filterData.status.length > 0 && filterData.status[0] == "Active" ? classes.activeMenuItem : classes.menuItem}> Active Employees</MenuItem> <Divider className={classes.divider} />
                                                                            <MenuItem onClick={() => { handleStatusClose("In Active") }} className={filterData.status.length > 0 && filterData.status[0] == "In Active" ? classes.activeMenuItem : classes.menuItem}> Inactive Employees</MenuItem>
                                                                        </> :
                                                                        <>
                                                                            <MenuItem onClick={() => { handleSelfStatusClose() }} className={classes.menuItem}>All</MenuItem> <Divider className={classes.divider} />
                                                                            <MenuItem onClick={() => { handleSelfStatusClose("Offer Accepted") }} className={selfOnboardFilter.status.length > 0 && selfOnboardFilter.status[0] == "Offer Accepted" ? classes.activeMenuItem : classes.menuItem} >Offer Accepted</MenuItem> <Divider className={classes.divider} />
                                                                            <MenuItem onClick={() => { handleSelfStatusClose("In Progress") }} className={selfOnboardFilter.status.length > 0 && selfOnboardFilter.status[0] == "In Progress" ? classes.activeMenuItem : classes.menuItem} >InProgress</MenuItem><Divider className={classes.divider} />
                                                                            <MenuItem onClick={() => { handleSelfStatusClose("Link Expired") }} className={selfOnboardFilter.status.length > 0 && selfOnboardFilter.status[0] == "Link Expired" ? classes.activeMenuItem : classes.menuItem}>Link Expired</MenuItem> <Divider className={classes.divider} />
                                                                            <MenuItem onClick={() => { handleSelfStatusClose(2) }} className={selfOnboardFilter.status.length > 0 && selfOnboardFilter.status[0] == "Waiting" ? classes.activeMenuItem : classes.menuItem} >Waiting</MenuItem> <Divider className={classes.divider} />
                                                                            <MenuItem onClick={() => { handleSelfStatusClose("Offer Rejected") }} className={selfOnboardFilter.status.length > 0 && selfOnboardFilter.status[0] == "Offer Rejected" ? classes.activeMenuItem : classes.menuItem} >Offer Rejected</MenuItem>
                                                                        </>
                                                                    }
                                                                </Menu>
                                                            </Grid>
                                                        </Hidden>


                                                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2} display={"flex"}>
                                                            <Text sx={{
                                                                font: '14px Quicksand !important', letterSpacing: '0px', opacity: 1, color: "#737373 !important",
                                                                "@media (min-width: 900px) and (max-width: 1500px)": {
                                                                    font: "11px Quicksand !important",
                                                                },
                                                                "@media (min-width: 200px) and (max-width: 900px)": {
                                                                    font: "9px Quicksand !important",
                                                                },
                                                            }}>
                                                                Count
                                                                <HtmlTooltip
                                                                    placement="bottom"
                                                                    arrow
                                                                    title={
                                                                        <React.Fragment>
                                                                            <Box>
                                                                                <Typography className={classes.profileTooltipText}>
                                                                                    Total Employee count is based on the filters applied
                                                                                </Typography>
                                                                            </Box>
                                                                        </React.Fragment>
                                                                    }
                                                                >
                                                                    <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                                                </HtmlTooltip>
                                                                {` - ${pagination ? pagination.total ? pagination.total : 0 : 0} Employees`}</Text>
                                                        </Grid>
                                                    </Grid>

                                            }
                                        </Box>
                                }
                                {
                                    loading ?
                                        [1, 2, 3, 4].map((item) => (
                                            <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                    <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <Box>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        </Box>
                                                        <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        )) :
                                        <>
                                            {selectedOption !== "Internal Employees" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed !== true))) ? <>{NoPermission()}</> :
                                                selectedOption == "Internal Employees" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed !== true))) ? <>{NoPermission()}</> :
                                                    <>
                                                        {
                                                            employees.length > 0 ?
                                                                <Box sx={{
                                                                    height: '65vh', overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
                                                                    paddingLeft: '5px', paddingRight: '5px', paddingBottom: '5px',
                                                                    "@media (min-height: 850px)": {
                                                                        height: '73vh'
                                                                    }
                                                                }} ref={scrollBox} onScroll={handleScroll}>
                                                                    {
                                                                        selectedOption == "Internal Employees" ?
                                                                            <>
                                                                                {
                                                                                    employees.map((Emp, key) => (
                                                                                        <Accordion key={key} className={classes.newCustomAccordion}>
                                                                                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header" className={classes.AccordionSummary}>
                                                                                                <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                                                                    <Grid item lg={4.5} md={4.5} sm={4} xs={4.5} textAlign='start'>
                                                                                                        <Box display='flex' alignItems='center' gap={2}>
                                                                                                            <Avatar
                                                                                                                src={Emp.profile_picture_url == null || Emp.profile_picture_url == '' ? Emp.display_name[0] : Emp.profile_picture_url}
                                                                                                                alt={Emp.profile_picture_url == null ? Emp.display_name[0] : ""}
                                                                                                                sx={{ width: 56, height: 56, background: Emp.profile_picture_url == null || Emp.profile_picture_url == '' ? '' : '#e5b256 !important' }}
                                                                                                            />
                                                                                                            <Box>
                                                                                                                <Hidden smDown>
                                                                                                                    <Text mediumBoldBlack style={{ margin: '4px 0' }}>
                                                                                                                        {
                                                                                                                            Emp.display_name ? Emp.display_name.length > 16 ?
                                                                                                                                <BlackToolTip title={capitalizeAndAddSpace(Emp.display_name)} placement="top" arrow>
                                                                                                                                    {capitalizeAndAddSpace(Emp.display_name).slice(0, 16) + capitalizeAndAddSpace(Emp.display_name.length > 16 ? "..." : "")}
                                                                                                                                </BlackToolTip>
                                                                                                                                : capitalizeAndAddSpace(Emp.display_name) : '--'
                                                                                                                        }
                                                                                                                    </Text>
                                                                                                                </Hidden>
                                                                                                                <Hidden smUp>
                                                                                                                    <Text mediumBoldBlack style={{ margin: '4px 0' }}>
                                                                                                                        {
                                                                                                                            Emp.display_name ? Emp.display_name.length > 16 ?
                                                                                                                                <BlackToolTip title={capitalizeAndAddSpace(Emp.display_name)} placement="top" arrow>
                                                                                                                                    {capitalizeAndAddSpace(Emp.display_name).slice(0, 16) + capitalizeAndAddSpace(Emp.display_name.length > 16 ? "..." : "")}
                                                                                                                                </BlackToolTip>
                                                                                                                                : capitalizeAndAddSpace(Emp.display_name) : '--'
                                                                                                                        }
                                                                                                                    </Text>
                                                                                                                </Hidden>

                                                                                                            </Box>
                                                                                                        </Box>

                                                                                                    </Grid>
                                                                                                    <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"start"}>
                                                                                                        <Text smallBoldBlack noWrap >{Emp.role_name ? Emp.role_name : "--"}</Text>
                                                                                                    </Grid>
                                                                                                    <Grid container lg={3} md={3} sm={3} xs={3} textAlign={"start"}>
                                                                                                        {/* <Text smallBoldBlack noWrap ml={1}>{Emp.email_id ? Emp.email_id : "--"}</Text> */}
                                                                                                        <Text smallBoldBlack my={1} >
                                                                                                            {
                                                                                                                Emp.email_id ? Emp.email_id.length > 16 ?
                                                                                                                    <BlackToolTip title={Emp.email_id} placement="top" arrow>
                                                                                                                        {(Emp.email_id.slice(0, 16)) + (Emp.email_id.length > 16 ? "..." : "")}
                                                                                                                    </BlackToolTip>
                                                                                                                    : Emp.email_id : '--'
                                                                                                            }
                                                                                                        </Text>
                                                                                                    </Grid>
                                                                                                    <Grid item lg={2} md={2} sm={3} xs={2} textAlign={"start"}>
                                                                                                        <Text smallBoldBlack noWrap >{Emp.contact_number ? Emp.contact_number : "--"}</Text>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </AccordionSummary>
                                                                                        </Accordion>
                                                                                    ))
                                                                                }

                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    employees.map((Emp, key) => (
                                                                                        <Accordion
                                                                                            key={key}
                                                                                            className={classes.newCustomAccordion}
                                                                                            expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                                                                                            <AccordionSummary
                                                                                                aria-controls="panel1bh-content"
                                                                                                id="panel1bh-header"
                                                                                                className={classes.AccordionSummary}
                                                                                            >
                                                                                                <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                                                                    <Hidden mdUp>
                                                                                                        <Grid item lg={5} md={5} sm={4} xs={5} textAlign='start' >
                                                                                                            <Box display='flex' alignItems='center' gap={1}  width={'50%'}>
                                                                                                                {
                                                                                                                    selectedOption == 'All employees'
                                                                                                                        ?
                                                                                                                        <CustomCircularProgress
                                                                                                                            percentage={Emp.profile_progress}
                                                                                                                            color={Emp.profile_progress >= 76 ? 'green' : Emp.profile_progress <= 75 && Emp.profile_progress >= 51 ? 'yellow' : Emp.profile_progress <= 50 ? "#FFBF00" : ''}
                                                                                                                            src={Emp.profile_picture_url}
                                                                                                                            name={Emp.profile_picture_url == undefined || Emp.profile_picture_url == null || Emp.profile_picture_url == '' ? Emp.full_name && Emp.full_name[0] : ''}
                                                                                                                            imgWidth='50px !important'
                                                                                                                            imgHeight='50px !important'
                                                                                                                            avatarWidth='50px !important'
                                                                                                                            avatarHeight='50px !important'
                                                                                                                            value={Emp.profile_progress}
                                                                                                                            size={58}
                                                                                                                            tooltip
                                                                                                                            tooltipContent={
                                                                                                                                <Box p={'3px 0px'} width='100%'>
                                                                                                                                    <Text mediumBoldWhite400>{`Profile Completion - ${Emp.profile_progress}%`}</Text>
                                                                                                                                </Box>
                                                                                                                            }
                                                                                                                            dashboardContent={Emp.status == 'Active' && Emp.profile_progress < 100 ? <Button addButtonTooltip sx={{ margin: '10px 0px 5px 0px' }} onClick={() => completeProfile(Emp)}>Complete Profile</Button> : ''}
                                                                                                                        />
                                                                                                                        :
                                                                                                                        <Avatar
                                                                                                                            src={Emp.profile_picture_url == null || Emp.profile_picture_url == '' ? Emp.full_name[0] : Emp.profile_picture_url}
                                                                                                                            alt={Emp.full_name ? Emp.full_name[0] : ""}
                                                                                                                            sx={{ width: 56, height: 56, background: Emp.full_name ? '' : '#e5b256 !important' }}
                                                                                                                        />
                                                                                                                }
                                                                                                                <Box>
                                                                                                                    <Hidden smUp>
                                                                                                                        {
                                                                                                                            Emp.status == 'In Active' ?
                                                                                                                                <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                                                                    {Emp.full_name === "" ? "--" : capitalizeAndAddSpace(Emp.full_name).length > 16 ?
                                                                                                                                        <HtmlTooltip title={capitalizeAndAddSpace(Emp.full_name)} placement="right" arrow>
                                                                                                                                            {capitalizeAndAddSpace(Emp.full_name).slice(0, 16) + (capitalizeAndAddSpace(Emp.full_name).length > 16 ? "..." : "")}
                                                                                                                                        </HtmlTooltip>
                                                                                                                                        :
                                                                                                                                        capitalizeAndAddSpace(Emp.full_name)
                                                                                                                                    }
                                                                                                                                    {Emp.e_verified == 1 || Emp.e_verified == 4 ?
                                                                                                                                        <HtmlTooltip title={<Text smallWhite>E-verified</Text>} placement="right" arrow>
                                                                                                                                            <img src={disableVerified} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                                        </HtmlTooltip>
                                                                                                                                        : Emp.e_verified == 2 ?
                                                                                                                                            <BlackToolTip arrow placement='right' title={<Text smallWhite>E-verification is Pending</Text>}>
                                                                                                                                                <img src={Pending} alt='Verified' style={{ margin: '0px 0px -3px 5px' }} />
                                                                                                                                            </BlackToolTip> : ''
                                                                                                                                    }
                                                                                                                                </Text>
                                                                                                                                :
                                                                                                                                <Text mediumBoldBlack noWrap>
                                                                                                                                    {Emp.full_name === "" ? "--" :
                                                                                                                                        capitalizeAndAddSpace(Emp.full_name).length > 16 ?
                                                                                                                                            <HtmlTooltip title={capitalizeAndAddSpace(Emp.full_name)} placement="right" arrow>
                                                                                                                                                {capitalizeAndAddSpace(Emp.full_name).slice(0, 16) + (capitalizeAndAddSpace(Emp.full_name).length > 16 ? "..." : "")}
                                                                                                                                            </HtmlTooltip>
                                                                                                                                            :
                                                                                                                                            capitalizeAndAddSpace(Emp.full_name)
                                                                                                                                    }
                                                                                                                                    {Emp.e_verified == 1 || Emp.e_verified == 4 ?
                                                                                                                                        <HtmlTooltip title={<Text smallWhite>E-verified</Text>} placement="right" arrow>
                                                                                                                                            <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                                        </HtmlTooltip>
                                                                                                                                        : Emp.e_verified == 2 ?
                                                                                                                                            <BlackToolTip arrow placement='right' title={<Text smallWhite>E-verification is Pending</Text>}>
                                                                                                                                                <img src={Pending} alt='Verified' style={{ margin: '0px 0px -3px 5px' }} />
                                                                                                                                            </BlackToolTip> : ''
                                                                                                                                    }
                                                                                                                                </Text>
                                                                                                                        }
                                                                                                                        <Text mediumLabel noWrap style={{ margin: '4px 0' }}>  {Emp.reference_id === "" ? "--" : Emp.reference_id}</Text>
                                                                                                                    </Hidden>
                                                                                                                    <Hidden smDown>
                                                                                                                        {
                                                                                                                            Emp.status == 'In Active' ?
                                                                                                                                <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                                                                    {Emp.full_name === "" ? "--" : capitalizeAndAddSpace(Emp.full_name).length > 16 ?
                                                                                                                                        <HtmlTooltip title={capitalizeAndAddSpace(Emp.full_name)} placement="right" arrow>
                                                                                                                                            {capitalizeAndAddSpace(Emp.full_name).slice(0, 16) + (capitalizeAndAddSpace(Emp.full_name).length > 16 ? "..." : "")}
                                                                                                                                        </HtmlTooltip>
                                                                                                                                        :
                                                                                                                                        capitalizeAndAddSpace(Emp.full_name)
                                                                                                                                    }
                                                                                                                                    {Emp.e_verified == 1 || Emp.e_verified == 4 ?
                                                                                                                                        <HtmlTooltip title={<Text smallWhite>E-verified</Text>} placement="right" arrow>
                                                                                                                                            <img src={disableVerified} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                                        </HtmlTooltip>
                                                                                                                                        : Emp.e_verified == 2 ?
                                                                                                                                            <BlackToolTip arrow placement='right' title={<Text smallWhite>E-verification is Pending</Text>}>
                                                                                                                                                <img src={Pending} alt='Verified' style={{ margin: '0px 0px -3px 5px' }} />
                                                                                                                                            </BlackToolTip> : ''
                                                                                                                                    }</Text>
                                                                                                                                :
                                                                                                                                <Text mediumBoldBlack noWrap>
                                                                                                                                    {Emp.full_name === "" ? "--" :
                                                                                                                                        capitalizeAndAddSpace(Emp.full_name).length > 16 ?
                                                                                                                                            <HtmlTooltip title={capitalizeAndAddSpace(Emp.full_name)} placement="right" arrow>
                                                                                                                                                {capitalizeAndAddSpace(Emp.full_name).slice(0, 16) + (capitalizeAndAddSpace(Emp.full_name).length > 16 ? "..." : "")}
                                                                                                                                            </HtmlTooltip>
                                                                                                                                            :
                                                                                                                                            capitalizeAndAddSpace(Emp.full_name)
                                                                                                                                    }
                                                                                                                                    {Emp.e_verified == 1 || Emp.e_verified == 4 ?
                                                                                                                                        <HtmlTooltip title={<Text smallWhite>E-verified</Text>} placement="right" arrow>
                                                                                                                                            <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                                        </HtmlTooltip>
                                                                                                                                        : Emp.e_verified == 2 ?
                                                                                                                                            <BlackToolTip arrow placement='right' title={<Text smallWhite>E-verification is Pending</Text>}>
                                                                                                                                                <img src={Pending} alt='Verified' style={{ margin: '0px 0px -3px 5px' }} />
                                                                                                                                            </BlackToolTip> : ''
                                                                                                                                    }
                                                                                                                                </Text>
                                                                                                                        }
                                                                                                                        <Text mediumLabel noWrap style={{ margin: '4px 0' }}>  {Emp.reference_id === "" ? "--" : Emp.reference_id}</Text>
                                                                                                                    </Hidden>


                                                                                                                </Box>
                                                                                                            </Box>
                                                                                                            {(selectedOption == "All employees" && Emp.status == 'Active' && appIntegrations?.connection_status == "connected" && appIntegrations?.redirect_to == "") &&
                                                                                                                <Box display='flex' alignItems='center' justifyContent='start' width={'50%'} paddingLeft={'10%'}>
                                                                                                                {((Emp?.zoho_migration_status == "Not Migrated" || Emp?.zoho_migration_status == "Error") && currentSyncingId!==Emp?.id) &&
                                                                                                                    <Button zohosyncnow onClick={(e) => handleZohosync(e,Emp?.id)}><img alt='zohoicon' src={Zohosyncnow} className={classes.zohoIcon} />Sync Now</Button>}
    
                                                                                                                {(syncing && currentSyncingId==Emp?.id) &&
                                                                                                                    <Button zohosyncing  ><img alt='zohoicon' src={Zohosyncing} className={classes.zohoIcon} />Syncing...</Button>}
                                                                                                                {(Emp?.zoho_migration_status == "Migrated"  && currentSyncingId!==Emp?.id) && <>
                                                                                                                    <Button zohosynced  >Synced<span className={classes.syncedSpan}><img alt='zohoicon' src={Zohosynced} className={classes.zohoIcon} /></span></Button>
                                                                                                                    <Box display='flex' alignItems='center' justifyContent='center'>
                                                                                                                        <img alt='zohoicon' src={Zohorefresh} className={classes.zohoRefresh} onClick={(e) => handleZohosync(e,Emp?.id)} />
                                                                                                                    </Box>
                                                                                                                </>}
    
                                                                                                            </Box>}
                                                                                                        </Grid>
                                                                                                    </Hidden>
                                                                                                    <Hidden mdDown>
                                                                                                        <Grid item container lg={5} md={5} sm={5} xs={5} textAlign='start' >
                                                                                                            <Box display='flex' alignItems='center' gap={2} width={'50%'}>
                                                                                                                {
                                                                                                                    selectedOption == 'All employees'
                                                                                                                        ?
                                                                                                                        <CustomCircularProgress
                                                                                                                            percentage={Emp.profile_progress}
                                                                                                                            color={Emp.profile_progress >= 76 ? 'green' : Emp.profile_progress <= 75 && Emp.profile_progress >= 51 ? 'yellow' : Emp.profile_progress <= 50 ? "#FFBF00" : ''}
                                                                                                                            src={Emp.profile_picture_url}
                                                                                                                            name={Emp.profile_picture_url == undefined || Emp.profile_picture_url == null || Emp.profile_picture_url == '' ? Emp.full_name && Emp.full_name[0] : ''}
                                                                                                                            imgWidth='50px !important'
                                                                                                                            imgHeight='50px !important'
                                                                                                                            avatarWidth='50px !important'
                                                                                                                            avatarHeight='50px !important'
                                                                                                                            value={Emp.profile_progress}
                                                                                                                            size={58}
                                                                                                                            tooltip
                                                                                                                            tooltipContent={
                                                                                                                                <Box p={'3px 0px'} width='100%'>
                                                                                                                                    <Text mediumBoldWhite400>{`Profile Completion - ${Emp.profile_progress}%`}</Text>
                                                                                                                                </Box>
                                                                                                                            }
                                                                                                                            dashboardContent={Emp.status == 'Active' && Emp.profile_progress < 100 ? <Button addButtonTooltip sx={{ margin: '10px 0px 5px 0px' }} onClick={() => completeProfile(Emp)}>Complete Profile</Button> : ''}
                                                                                                                        />
                                                                                                                        :
                                                                                                                        <Avatar
                                                                                                                            src={Emp.profile_picture_url == null || Emp.profile_picture_url == '' ? Emp.display_name[0] : Emp.profile_picture_url}
                                                                                                                            alt={Emp.display_name ? Emp.display_name[0] : ""}
                                                                                                                            sx={{ width: 56, height: 56, background: Emp.display_name ? '' : '#e5b256 !important' }}
                                                                                                                        />
                                                                                                                }
                                                                                                                <Box>
                                                                                                                    {
                                                                                                                        Emp.status == 'In Active' ?
                                                                                                                            <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                                                                {Emp.full_name === "" ? "--" : capitalizeAndAddSpace(Emp.full_name).length > 16 ?
                                                                                                                                    <HtmlTooltip title={capitalizeAndAddSpace(Emp.full_name)} placement="right" arrow>
                                                                                                                                        {capitalizeAndAddSpace(Emp.full_name).slice(0, 16) + (capitalizeAndAddSpace(Emp.full_name).length > 16 ? "..." : "")}
                                                                                                                                    </HtmlTooltip>
                                                                                                                                    :
                                                                                                                                    capitalizeAndAddSpace(Emp.full_name)
                                                                                                                                }
                                                                                                                                {Emp.e_verified == 1 || Emp.e_verified == 4 ?
                                                                                                                                    <HtmlTooltip title={<Text smallWhite>E-verified</Text>} placement="right" arrow>
                                                                                                                                        <img src={disableVerified} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                                    </HtmlTooltip>
                                                                                                                                    : Emp.e_verified == 2 ?
                                                                                                                                        <BlackToolTip arrow placement='right' title={<Text smallWhite>E-verification is Pending</Text>}>
                                                                                                                                            <img src={Pending} alt='Verified' style={{ margin: '0px 0px -3px 5px' }} />
                                                                                                                                        </BlackToolTip> : ''
                                                                                                                                }</Text>
                                                                                                                            :
                                                                                                                            <Text mediumBoldBlack noWrap>
                                                                                                                                {Emp.full_name === "" ? "--" :
                                                                                                                                    capitalizeAndAddSpace(Emp.full_name).length > 16 ?
                                                                                                                                        <HtmlTooltip title={capitalizeAndAddSpace(Emp.full_name)} placement="right" arrow>
                                                                                                                                            {capitalizeAndAddSpace(Emp.full_name).slice(0, 16) + (capitalizeAndAddSpace(Emp.full_name).length > 16 ? "..." : "")}
                                                                                                                                        </HtmlTooltip>
                                                                                                                                        :
                                                                                                                                        capitalizeAndAddSpace(Emp.full_name)
                                                                                                                                }
                                                                                                                                {Emp.e_verified == 1 || Emp.e_verified == 4 ?
                                                                                                                                    <HtmlTooltip title={<Text smallWhite>E-verified</Text>} placement="right" arrow>
                                                                                                                                        <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                                    </HtmlTooltip>
                                                                                                                                    : Emp.e_verified == 2 ?
                                                                                                                                        <BlackToolTip arrow placement='right' title={<Text smallWhite>E-verification is Pending</Text>}>
                                                                                                                                            <img src={Pending} alt='Verified' style={{ margin: '0px 0px -3px 5px' }} />
                                                                                                                                        </BlackToolTip> : ''
                                                                                                                                }
                                                                                                                            </Text>
                                                                                                                    }
                                                                                                                    <Text mediumLabel noWrap style={{ margin: '4px 0' }}>  {Emp.reference_id === "" ? "--" : Emp.reference_id}</Text>
                                                                                                                </Box>
                                                                                                            </Box>
                                                                                                            {(selectedOption == "All employees" && Emp.status == 'Active' && appIntegrations?.connection_status == "connected" && appIntegrations?.redirect_to == "") &&
                                                                                                                <Box display='flex' alignItems='center' justifyContent='start' width={'50%'} paddingLeft={'10%'}>
                                                                                                                {((Emp?.zoho_migration_status == "Not Migrated" || Emp?.zoho_migration_status == "Error") && currentSyncingId!==Emp?.id) &&
                                                                                                                    <Button zohosyncnow onClick={(e) => handleZohosync(e,Emp?.id)}><img alt='zohoicon' src={Zohosyncnow} className={classes.zohoIcon} />Sync Now</Button>}
    
                                                                                                                {(syncing && currentSyncingId==Emp?.id) &&
                                                                                                                    <Button zohosyncing  ><img alt='zohoicon' src={Zohosyncing} className={classes.zohoIcon} />Syncing...</Button>}
                                                                                                                {(Emp?.zoho_migration_status == "Migrated"  && currentSyncingId!==Emp?.id) && <>
                                                                                                                    <Button zohosynced  >Synced<span className={classes.syncedSpan}><img alt='zohoicon' src={Zohosynced} className={classes.zohoIcon} /></span></Button>
                                                                                                                    <Box display='flex' alignItems='center' justifyContent='center'>
                                                                                                                        <img alt='zohoicon' src={Zohorefresh} className={classes.zohoRefresh} onClick={(e) => handleZohosync(e,Emp?.id)} />
                                                                                                                    </Box>
                                                                                                                </>}
    
                                                                                                            </Box>}
                                                                                                        </Grid>
                                                                                                    </Hidden>

                                                                                                    <Grid item lg={3} md={2} sm={3} xs={3} textAlign={"start"}>
                                                                                                        {selectedOption === "All employees" ? <Text smallBoldBlack noWrap >{Emp.employment_type === "" ? "--" : Emp.employment_type}</Text>
                                                                                                            :
                                                                                                            <Text smallBoldBlack noWrap >Consultant</Text>
                                                                                                        }
                                                                                                    </Grid>
                                                                                                    <Grid container lg={selectedOption === "All employees" ? 3 : 2} md={selectedOption === "All employees" ? 3 : 2} sm={selectedOption === "All employees" ? 3 : 2} xs={selectedOption === "All employees" ? 3 : 2} textAlign={"start"}>
                                                                                                        {selectedOption === "All employees" ?
                                                                                                            <Stack
                                                                                                                direction="row"
                                                                                                                useFlexGap
                                                                                                                width='80%'
                                                                                                                divider={<Divider orientation="vertical" flexItem style={{ height: '50px' }} />}
                                                                                                                spacing={3}
                                                                                                                textAlign='center' alignItems='center'
                                                                                                            >
                                                                                                                <Grid item lg={6} md={6} sm={6} xs={6} textAlign={"start"}>

                                                                                                                    <Text smallBoldBlack noWrap >
                                                                                                                        {Emp.visa_type === "" || Emp.visa_type == null ?
                                                                                                                            <>
                                                                                                                                {NotApplicable()}
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            Emp.visa_type}
                                                                                                                    </Text>
                                                                                                                </Grid>

                                                                                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                                                                                    {
                                                                                                                        Emp.status == 'In Active' ?
                                                                                                                            <Box sx={{ height: "25px", borderRadius: "20px", padding: "3px 20px", background: "#494747" }}>
                                                                                                                                <Text sx={{ fontWeight: "600 !important", font: "14px Quicksand", color: "#ffffff" }}>Inactive</Text>
                                                                                                                            </Box> :
                                                                                                                            <HtmlTooltip
                                                                                                                                placement="bottom"
                                                                                                                                arrow
                                                                                                                                title={
                                                                                                                                    <React.Fragment>
                                                                                                                                        <Box>
                                                                                                                                            <Typography className={classes.profileTooltipText}>
                                                                                                                                                {Emp.sub_status}
                                                                                                                                            </Typography>
                                                                                                                                        </Box>
                                                                                                                                    </React.Fragment>
                                                                                                                                }
                                                                                                                            >

                                                                                                                                <Box sx={{
                                                                                                                                    height: "25px", borderRadius: "20px", padding: "3px 20px", background: "#16A34A",
                                                                                                                                    "@media (min-width: 00px) and (max-width: 900px)": {
                                                                                                                                        padding: "3px"
                                                                                                                                    }
                                                                                                                                }}>
                                                                                                                                    <Text sx={{
                                                                                                                                        fontWeight: "600 !important", font: "14px Quicksand", color: "#ffffff",
                                                                                                                                        "@media (min-width: 900px) and (max-width: 1400px)": {
                                                                                                                                            font: "12px Quicksand",
                                                                                                                                            paddingTop: '1px'
                                                                                                                                        },
                                                                                                                                        "@media (min-width: 00px) and (max-width: 900px)": {
                                                                                                                                            font: "10px Quicksand",
                                                                                                                                            paddingTop: '1px'
                                                                                                                                        },
                                                                                                                                    }}>Active</Text>
                                                                                                                                </Box>
                                                                                                                            </HtmlTooltip>
                                                                                                                    }
                                                                                                                </Grid>
                                                                                                            </Stack> :
                                                                                                            <Box
                                                                                                                className={`${classes.empStatusBox} ${Emp.status == "Offer Rejected" ? classes.empStatusBlackBox : (Emp.status == "Invitation Sent" || Emp.status == "Pending" || Emp.status == "Waiting") ? classes.empStatusPurpleBox : Emp.status == "In Progress" ? classes.empStatusBlueBox : Emp.status == "Link Expired" ? classes.empStatusOrangeBox : classes.empStatusGreenBox}`}>
                                                                                                                {(Emp.status == "Invitation Sent" || Emp.status == "Pending" || Emp.status == "Waiting") ? "Waiting" : Emp.status}
                                                                                                            </Box>
                                                                                                        }
                                                                                                    </Grid>

                                                                                                    {selectedOption === "All employees" ?
                                                                                                        <Grid item lg={1}>
                                                                                                            {
                                                                                                                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) ?
                                                                                                                    <Typography
                                                                                                                        onClick={() => {
                                                                                                                            LocalStorage.removeRedirectedModule();
                                                                                                                            LocalStorage.removeSubModule();
                                                                                                                            LocalStorage.removeNotificationEmpId(); LocalStorage.removeEmployeeVerifyId()
                                                                                                                        }}
                                                                                                                        component={Link}
                                                                                                                        to={true ? `/employees/user-profile/${Emp.full_name === "" ? "" : Emp.full_name.trim().split(/ +/).join('-')}` : '/employees/onboard'}
                                                                                                                        state={{ id: Emp.id, full_name: Emp.full_name, e_verify: Emp.e_verified, reference_id: Emp.reference_id, avatar_url: Emp.profile_picture_url, enable_login: Emp.enable_login, stage: Emp.drafted_stage, progress: Emp.profile_progress, from: 'employmentDashboard' }}
                                                                                                                        className={classes.linkText}
                                                                                                                    >
                                                                                                                        {true ? "View Profile" : "Complete Profile"}
                                                                                                                    </Typography> :
                                                                                                                    <Text defaultmediumText noWrap >View Profile</Text>
                                                                                                            }
                                                                                                        </Grid>
                                                                                                        :
                                                                                                        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_create" && item.is_allowed == true))) ?
                                                                                                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>
                                                                                                                {
                                                                                                                    (Emp.status == "Invitation Sent" || Emp.status == "Pending" || Emp.status == "Waiting") ?
                                                                                                                        <Text defaultmediumText className={classes.linkInactive}>Verify</Text>
                                                                                                                        :
                                                                                                                        (Emp.status == "In Progress" || Emp.status == "Offer Accepted") ?
                                                                                                                            <Text sx={{ cursor: "pointer" }} smallBlue className={classes.linkText}>
                                                                                                                                {Emp.status == "In Progress" ?
                                                                                                                                    <Stack direction={'row'} justifyContent={'center'}>
                                                                                                                                        <Text smallBlue sx={{
                                                                                                                                            "&:hover": {
                                                                                                                                                textDecoration: (Emp.assignee_id == employeeId || Emp.assignee_id == null) ? "underline" : ''
                                                                                                                                            }
                                                                                                                                        }} onClick={() => {
                                                                                                                                            if (Emp.assignee_id == employeeId || Emp.assignee_id == null) {
                                                                                                                                                handleVerify(Emp.id)
                                                                                                                                            }
                                                                                                                                        }} className={(Emp.assignee_id == employeeId || Emp.assignee_id == null) ? classes.linkText : classes.linkInactive}>Continue</Text>
                                                                                                                                        <Text smallBlue className={classes.linkText}>&nbsp;|&nbsp;</Text>
                                                                                                                                        <Text smallBlue onClick={() => {
                                                                                                                                            if (Emp.assignee_id != null) {
                                                                                                                                                unLinkAssignee(Emp.id, Emp.full_name);
                                                                                                                                            }
                                                                                                                                        }} sx={{
                                                                                                                                            "&:hover": {
                                                                                                                                                textDecoration: Emp.assignee_id == null ? '' : "underline"
                                                                                                                                            }
                                                                                                                                        }} className={Emp.assignee_id == null ? classes.linkInactive : classes.linkText}>Unlink</Text>
                                                                                                                                    </Stack>
                                                                                                                                    :
                                                                                                                                    <Text onClick={() => { handleVerify(Emp.id) }} sx={{ cursor: "pointer" }} smallBlue className={classes.linkText}>Verify</Text>
                                                                                                                                }
                                                                                                                            </Text>
                                                                                                                            :
                                                                                                                            Emp.status == "Link Expired" ?
                                                                                                                                <Text onClick={() => { LocalStorage.setEmployeeVerifyId(Emp.id); navigate("/employees/add") }} sx={{ cursor: "pointer" }} smallBlue className={classes.linkText}>Re - Invite</Text>
                                                                                                                                :
                                                                                                                                <Text sx={{ cursor: "pointer" }} smallBlue>
                                                                                                                                    {Emp.status == "Offer Rejected" ? "" : ""}
                                                                                                                                </Text>
                                                                                                                }
                                                                                                            </Grid> :
                                                                                                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>
                                                                                                                {
                                                                                                                    (Emp.status == "Invitation Sent" || Emp.status == "Pending" || Emp.status == "Waiting") ?
                                                                                                                        <Text defaultmediumText className={classes.linkInactive}>Verify</Text>
                                                                                                                        :
                                                                                                                        (Emp.status == "In Progress" || Emp.status == "Offer Accepted") ?
                                                                                                                            <Text defaultmediumText className={classes.linkInactive}> {Emp.status == "In Progress" ? "Continue" : "Verify"}</Text>
                                                                                                                            :
                                                                                                                            Emp.status == "Link Expired" ?
                                                                                                                                <Text defaultmediumText className={classes.linkInactive}>Re - Invite</Text>
                                                                                                                                :
                                                                                                                                <Text defaultmediumText className={classes.linkInactive}>
                                                                                                                                    {Emp.status == "Offer Rejected" ? "" : ""}
                                                                                                                                </Text>
                                                                                                                }
                                                                                                            </Grid>

                                                                                                    }
                                                                                                </Grid>
                                                                                            </AccordionSummary>
                                                                                            <AccordionDetails>
                                                                                                {
                                                                                                    selectedOption == "All employees" ?
                                                                                                        <Box className={classes.AccordionDetails} pt={1}>
                                                                                                            <Box width={'100%'}>
                                                                                                                <Typography className={classes.text1}>
                                                                                                                    Employee Category
                                                                                                                </Typography>
                                                                                                                <Typography my={1} className={classes.text2}>
                                                                                                                    {Emp.employee_category ? Emp.employee_category : "--"}
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                            <Box width={'100%'}>
                                                                                                                <Typography className={classes.text1}>
                                                                                                                    Sub-Status
                                                                                                                </Typography>
                                                                                                                <Typography my={1} className={classes.text2}>
                                                                                                                    {Emp.sub_status === "" ? "--" : Emp.sub_status}
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                            <Box width={'100%'}>
                                                                                                                <Typography className={classes.text1}>
                                                                                                                    Email ID
                                                                                                                </Typography>

                                                                                                                <Typography my={1} className={classes.text2}>
                                                                                                                    {
                                                                                                                        Emp.email_id ? Emp.email_id.length > 16 ?
                                                                                                                            <BlackToolTip title={Emp.email_id} placement="top" arrow>
                                                                                                                                {(Emp.email_id.slice(0, 16)) + (Emp.email_id.length > 16 ? "..." : "")}
                                                                                                                            </BlackToolTip>
                                                                                                                            : Emp.email_id : '--'
                                                                                                                    }
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                            <Box width={'100%'} >
                                                                                                                <Typography className={classes.text1}>
                                                                                                                    Phone No
                                                                                                                </Typography>

                                                                                                                <Typography my={1} className={classes.text2}>
                                                                                                                    {Emp.contact_number === "" ? "" : Emp.contact_number}
                                                                                                                </Typography>
                                                                                                            </Box>

                                                                                                        </Box> :
                                                                                                        <Box className={classes.AccordionDetails} pt={1}>
                                                                                                            <Grid container>

                                                                                                                <Grid item lg={2} textAlign={"center"}>
                                                                                                                    <Typography className={classes.selftext1}>
                                                                                                                        Category
                                                                                                                    </Typography>
                                                                                                                    <Typography my={1} className={classes.selftext2}>
                                                                                                                        {Emp.employee_category_name ? Emp.employee_category_name : "--"}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid item lg={2}></Grid>
                                                                                                                <Grid item lg={3} textAlign={"center"}>
                                                                                                                    <Typography className={classes.selftext1}>
                                                                                                                        Email ID
                                                                                                                    </Typography>
                                                                                                                    <Typography my={1} className={classes.selftext2}>
                                                                                                                        {
                                                                                                                            Emp.email_id ? Emp.email_id.length > 16 ?
                                                                                                                                <BlackToolTip title={Emp.email_id} placement="top" arrow>
                                                                                                                                    {(Emp.email_id.slice(0, 16)) + (Emp.email_id.length > 16 ? "..." : "")}
                                                                                                                                </BlackToolTip>
                                                                                                                                : Emp.email_id : '--'
                                                                                                                        }
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid item lg={3}></Grid>
                                                                                                                <Grid item lg={2} textAlign={"center"}>
                                                                                                                    <Typography className={classes.selftext1}>
                                                                                                                        Phone No
                                                                                                                    </Typography>
                                                                                                                    <Typography my={1} className={classes.selftext2}>
                                                                                                                        {Emp.mobile_number ? Emp.mobile_number : "--"}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                            </Grid>

                                                                                                        </Box>
                                                                                                }

                                                                                            </AccordionDetails>
                                                                                        </Accordion>
                                                                                    ))
                                                                                }
                                                                            </>
                                                                    }
                                                                    {
                                                                        listLoading && (
                                                                            <ListLoadingIcon />
                                                                        )
                                                                    }
                                                                </Box>
                                                                : employees.length == 0 ?
                                                                    <Box>
                                                                        {NoDataFound("To fetch the data. Please add employee", "No Data Found")}
                                                                    </Box>
                                                                    : ''
                                                        }
                                                    </>
                                            }
                                        </>
                                }
                            </Box>

                        </Box>
                        :
                        <Grid container>
                            <Text mediumViewAmt ml={4}>Employees</Text>
                            {NoPermission()}
                        </Grid>
                }</>}
            {/* <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBacKLoader}
            // onClick={handleClose}
            >
                <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
            </Backdrop> */}

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={"md"}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        boxShadow: 'none !important',
                        "&:hover": {
                            boxShadow: 'none !important',
                            background: 'none !important'
                        }
                    }}
                >
                    <img src={crossIcon} alt="cross" />
                    {/* <CloseIcon sx={{ color: "rgba(38, 38, 38, 1)" }} /> */}
                </IconButton>
                <DialogContent sx={{
                    margin: "50px",
                    "@media (min-width: 800px) and (max-width: 1240px)": {
                        margin: "30px",
                    }
                }}>
                    {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_create" && item.is_allowed == true))) ?
                        <Grid container spacing={0}>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box style={{ height: "400px", width: "400px", display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                    <Box sx={{ textAlign: "center" }}>
                                        <Box sx={{ margin: "12px 0px" }}>
                                            <img src={InviteViaLink} alt='invite-via-link' style={{ height: "200px" }} />
                                        </Box>
                                        <Box sx={{ margin: "8px 0px" }}>
                                            {/* <Typography sx={{ fontSize: "12px", fontFamily: "Quicksand", fontWeight: "400", color: "rgba(38, 38, 38, 1)", textAlign: "center", wordWrap: "break-word", }}>
                                            Send the employee an invite link to add them to <br /> the organization.
                                        </Typography> */}
                                            <Text mediumLabel>Send the employee an invite link to add them to <br /> the organization.</Text>
                                        </Box>
                                        <Box sx={{ margin: "4px 0px" }}>
                                            <button
                                                onClick={() => { navigate("/employees/add"); LocalStorage.removeEmployeeId(); LocalStorage.removeRedirectedModule(); LocalStorage.removeNotificationId(); LocalStorage.removeNotificationEmpId() }}
                                                type="button"
                                                className={classes.inviteLinkBtn}
                                            // style={{
                                            //     margin: "12px 0px", all: "unset", cursor: "pointer", textAlign: "center", fontSize: "15px", fontFamily: "Quicksand CY, sans-serif", fontWeight: "400", width: "150px", height: "40px", border: "1.5px solid rgba(12, 117, 235, 1)", borderRadius: "6px", color: "rgba(12, 117, 235, 1)",
                                            //     "&::hover": {
                                            //         backgroundColor:'rgba(12, 117, 235, 1)',color:'#FFFFFF'
                                            // } }}
                                            >
                                                Invite via link
                                            </button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box style={{ height: "400px", width: "400px", display: 'flex', justifyContent: "center", alignItems: "center", borderLeft: "1.5px solid rgba(199, 204, 211, 1)" }}>
                                    <Box sx={{ textAlign: "center" }}>
                                        <Box sx={{ margin: "16px 0px" }}>
                                            <img src={Onboart} alt='onboart' style={{ height: "200px" }} />
                                        </Box>
                                        <Box sx={{ margin: "6px 0px 0px 2px" }}>
                                            {/* <Typography sx={{ fontSize: "12px", fontFamily: "Quicksand CY, sans-serif", fontWeight: "400", color: "rgba(38, 38, 38, 1)", textAlign: "center", wordWrap: "break-word", }}>
                                        Onboard an employee manually, to add them to <br />the organization.
                                        </Typography> */}
                                            <Text mediumLabel>Complete the onboarding process on behalf of the employee to help him  <br /> gain access to your organization.</Text>
                                        </Box>
                                        <Box sx={{ margin: "20px 0px", }}>
                                            <button
                                                type="button"
                                                className={classes.onboardBtn}
                                                onClick={() => { navigate('/employees/onboard'); LocalStorage.removeEmployeeId(); LocalStorage.removeVisaID(); LocalStorage.removeFullName(); LocalStorage.removeRedirectedModule(); LocalStorage.removeNotificationId(); LocalStorage.removeNotificationEmpId();  }}
                                            >
                                                Onboard
                                            </button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        :
                        <>
                            {NoPermission()}
                        </>
                    }
                </DialogContent>
            </BootstrapDialog>

            <BootstrapDialog1
                TransitionComponent={TransitionUp}
                keepMounted
                onClose={() => { setLimitOpen(false) }}
                aria-labelledby="customized-dialog-title"
                open={limitOpen}
                fullWidth={true}
            // maxWidth={"md"}
            >
                <DialogContent sx={{ margin: "0px" }}>
                    <Grid container p={"10px 25px"} textAlign={"center"}>
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <img src={deleteAlert} alt="deleteAlert" />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2} textAlign={"center"}>
                            <Text popupHead1>Limit Exceeded!</Text>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={3} >
                            {
                                LocalStorage.getUserData().super_admin ?
                                    <Text offBoardBodyFont>You have exceeded the maximum limit of adding employees in this plan. Please upgrade to add more Employees.</Text>
                                    :
                                    <Text offBoardBodyFont>You have exceeded the maximum limit of adding employees in this plan. Please contact your super admin to upgrade.</Text>
                            }
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} pt={2} justifyContent={"center"} columnGap={2}>
                            {
                                LocalStorage.getUserData().super_admin ?
                                    <>
                                        <Button cancelOutlineBlue onClick={() => { setLimitOpen(false) }} sx={{ font: "14px Quicksand !important", fontWeight: `${700} !important`, height: "44px !important", width: "98px !important" }}>Cancel</Button>
                                        <Button save14 onClick={() => { upgradePlan(); }} sx={{ background: "#0C75EB !important", color: "#FFFFFF !important", font: "14px Quicksand !important", fontWeight: `${700} !important`, height: "44px !important", width: "98px !important" }}>Upgrade</Button>
                                    </>
                                    :
                                    <Button addNewFull onClick={() => { setLimitOpen(false) }} sx={{ background: "#0C75EB !important", color: "#FFFFFF !important", font: "14px Quicksand !important", fontWeight: `${700} !important`, height: "44px !important" }}>Cancel</Button>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog1>

            <SwipeableDrawer
                anchor={'right'}
                open={drawer}
                onClose={() => { setDrawer(null) }}
                // onOpen={toggleDrawer(true, state.view)}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                {
                    drawer === "filter" ?
                        <Box width={'660px'} height={'100vh'} >
                            <Box height={'10vh'} borderBottom={'1px solid #EAECF0'} display={'flex'} alignItems={'center'} pl={4} >
                                <Text headerBlack>Filters</Text>
                            </Box>

                            <Box display={'flex'} height={'7vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                                <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={2} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>
                                    {
                                        employmentTypeDropdown.map((item, key) => (
                                            filterState.employment.includes(item.id) &&
                                            <Chip
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                key={key}
                                                label={<Text smallBlack>{item.value}</Text>}
                                                variant="outlined"
                                                onDelete={() => handleDeleteChip(item.id, "employment")}
                                                deleteIcon={<CloseIcon />} />
                                        ))
                                    }
                                    {
                                        category.map((item, key) => (
                                            filterState.category.includes(item.id) &&
                                            <Chip
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                key={key}
                                                label={<Text smallBlack>{item.value}</Text>}
                                                variant="outlined"
                                                onDelete={() => handleDeleteChip(item.id, "category")}
                                                deleteIcon={<CloseIcon />}
                                            />
                                        ))
                                    }
                                    {
                                        visaTypeDropdown.map((item, key) => (
                                            filterState.visa.includes(item.id) &&
                                            <Chip
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                key={key}
                                                label={<Text smallBlack>{item.value}</Text>} variant="outlined" onDelete={() => handleDeleteChip(item.id, "visa")} deleteIcon={<CloseIcon />} />
                                        ))
                                    }
                                    {
                                        statusDropdown.map((item, key) => (
                                            filterState.status.includes(item.id) &&
                                            <Chip sx={{ border: "1px solid #E5E8E9" }}
                                                icon={<CircleIcon sx={{ color: "#7643A3 !important" }} className={classes.circularIcon} />}
                                                key={key}
                                                label={<Text smallBlack>{item.value}</Text>} variant="outlined" onDelete={() => handleDeleteChip(item.id, "status")} deleteIcon={<CloseIcon />} />
                                        ))
                                    }
                                </Box>
                                {
                                    (filterState.employment.length > 0 || filterState.category.length > 0 || filterState.visa.length > 0 || filterState.status.length > 0) ?
                                        <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                        :
                                        ''
                                }

                            </Box>

                            <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'73vh'} >

                                <Box display={'flex'} flexDirection={'column'} height={'100%'} width={'45%'} borderRight={'1px solid #EAECF0'} pt={0} >
                                    <List component="nav" aria-label="secondary mailbox folder">
                                        <ListItem className={selectedFilter === "type" ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick('type')} secondaryAction={filterState.employment.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterState.employment.length}</Text></Box> : ""}>
                                            <ListItemText primary={selectedFilter == "type" ? <Text smallBlue1 ml={3}>Employee Type</Text> : <Text mediumBlack ml={3}>Employee Type</Text>} />
                                        </ListItem>
                                        <Divider />
                                        <ListItem className={selectedFilter === 'category' ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick('category')} secondaryAction={filterState.category.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{filterState.category.length}</Text></Box> : ""}>
                                            <ListItemText primary={selectedFilter == 'category' ? <Text ml={3} smallBlue1>Category</Text> : <Text ml={3} mediumBlack>Category</Text>} />
                                        </ListItem>
                                        <Divider className={classes.divider} />
                                        <ListItem className={selectedFilter === 'visa' ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick('visa')} secondaryAction={filterState.visa.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{filterState.visa.length}</Text></Box> : ""}>
                                            <ListItemText primary={selectedFilter == 'visa' ? <Text ml={3} smallBlue1>Visa Type</Text> : <Text ml={3} mediumBlack>Visa Type</Text>} />
                                        </ListItem>
                                        <Divider />
                                        <ListItem className={selectedFilter === 'status' ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick('status')} secondaryAction={filterState.status.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#7643A3" }}><Text mediumWhite400>{filterState.status.length}</Text></Box> : ""}>
                                            <ListItemText primary={selectedFilter == 'status' ? <Text ml={3} smallBlue1>Status</Text> : <Text ml={3} mediumBlack>Status</Text>} />
                                        </ListItem>
                                        <Divider />
                                    </List>

                                </Box>

                                <Box display={'flex'} flexDirection={'column'} height={'100%'} width={'55%'} pl={5} pt={2} >
                                    {
                                        selectedFilter === 'type' ?
                                            <>
                                                {employmentTypeDropdown.map((item, key) => (
                                                    <Grid container alignItems={"center"} pb={2}>
                                                        <FormControlLabel
                                                            key={key}
                                                            control={
                                                                <Checkbox
                                                                    size="small"
                                                                    name={"employment"}
                                                                    value={item.id}
                                                                    onChange={(e) => { handleChangeCheckBox(e) }}
                                                                    icon={<CheckBorderIcon />}
                                                                    checkedIcon={<CheckedIcon />}
                                                                    checked={filterState.employment.includes(item.id)}
                                                                />}
                                                            label={<Text mediumBlack noWrap >{item.value}</Text>}
                                                        />
                                                    </Grid>
                                                ))}
                                            </>
                                            :
                                            selectedFilter === 'category' ?
                                                <>
                                                    {category.map((item, key) => (
                                                        <Grid container alignItems={"center"} pb={2}>
                                                            <FormControlLabel
                                                                key={key}
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        name={"category"}
                                                                        value={item.id}
                                                                        onChange={(e) => { handleChangeCheckBox(e) }}
                                                                        icon={<CheckBorderIcon />}
                                                                        checkedIcon={<CheckedIcon />}
                                                                        checked={filterState.category.includes(item.id)}
                                                                    />}
                                                                label={<Text mediumBlack noWrap >{item.value}</Text>}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </>
                                                :
                                                selectedFilter === 'visa' ?
                                                    <Box pl={1} sx={{ overflow: 'scroll' }}>
                                                        {visaTypeDropdown.map((item, key) => (
                                                            <Grid container alignItems={"center"} pb={2}>
                                                                <FormControlLabel
                                                                    key={key}
                                                                    control={
                                                                        <Checkbox
                                                                            size="small"
                                                                            name={"visa"}
                                                                            value={item.id}
                                                                            onChange={(e) => { handleChangeCheckBox(e) }}
                                                                            icon={<CheckBorderIcon />}
                                                                            checkedIcon={<CheckedIcon />}
                                                                            checked={filterState.visa.includes(item.id)}
                                                                        />}
                                                                    label={<Text mediumBlack noWrap >{item.value}</Text>} />
                                                            </Grid>
                                                        ))}
                                                        {/* {visaTypeDropdown.map((item, key) => (
                                                            <Grid container alignItems={"center"} pb={2}>
                                                                <FormControlLabel
                                                                    key={key}
                                                                    control={
                                                                        <Checkbox
                                                                            size="small"
                                                                            name={"visa"}
                                                                            value={item.id}
                                                                            onChange={(e) => { handleChangeCheckBox(e) }}
                                                                            icon={<CheckBorderIcon />}
                                                                            checkedIcon={<CheckedIcon />}
                                                                            checked={filterState.visa.includes(item.id)}
                                                                        />}
                                                                    label={<Text mediumBlack noWrap >{item.value}</Text>} />
                                                            </Grid>
                                                        ))}
                                                        {visaTypeDropdown.map((item, key) => (
                                                            <Grid container alignItems={"center"} pb={2}>
                                                                <FormControlLabel
                                                                    key={key}
                                                                    control={
                                                                        <Checkbox
                                                                            size="small"
                                                                            name={"visa"}
                                                                            value={item.id}
                                                                            onChange={(e) => { handleChangeCheckBox(e) }}
                                                                            icon={<CheckBorderIcon />}
                                                                            checkedIcon={<CheckedIcon />}
                                                                            checked={filterState.visa.includes(item.id)}
                                                                        />}
                                                                    label={<Text mediumBlack noWrap >{item.value}</Text>} />
                                                            </Grid>
                                                        ))} */}
                                                    </Box>
                                                    : selectedFilter === 'status' ?
                                                        <>
                                                            {statusDropdown.map((item, key) => (
                                                                <Grid container alignItems={"center"} pb={2}>
                                                                    <FormControlLabel
                                                                        key={key}
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                name={"status"}
                                                                                value={item.id}
                                                                                onChange={(e) => { handleChangeCheckBoxStatus(e) }}
                                                                                icon={<CheckBorderIcon />}
                                                                                checkedIcon={<CheckedIcon />}
                                                                                checked={filterState.status.includes(item.id)}
                                                                            />}
                                                                        label={<Text mediumBlack noWrap >{item.value}</Text>} />
                                                                </Grid>
                                                            ))}
                                                        </>
                                                        :
                                                        <Box sx={{ height: "60vh", width: "100%", display: "flex", alignItems: "center" }}>
                                                            <Grid container>
                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                    <img src={nofilterplacement} alt="nofilterplacement" style={{ paddingTop: "15px" }} />
                                                                </Grid>
                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"} >
                                                                    <Text veryLargeLabel>Gear Up!</Text>
                                                                </Grid>
                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                    <Text offBoardBodyFont>Select filters to scale our data peaks</Text>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                    }

                                </Box>
                            </Box>

                            <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={1} height={'73px'} pr={'26px'}>
                                <Button cancelSmall onClick={() => cancelFilter()}>Cancel</Button>
                                <Button saveSmall onClick={() => handleApplyFilter()} >Apply Filters</Button>
                            </Box>
                        </Box >
                        : null
                }
                {
                    drawer === "export" ? ExportView() : null
                }
            </SwipeableDrawer>
        </Box >
    );
}