import { domain } from "../../config/Domain";
import APIURL from "../../config/development";
import LocalStorage from "../../utils/LocalStorage";
import BaseApi from "../BaseApi";

class onBoardLinkApi {

    inviteViaLink(id) {
        let data = {
            tenant_id: LocalStorage.getPathId()
        };
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/invite-via-link?request_id=${LocalStorage.uid()}&id=${id}&subdomain_name=${domain}`, data);
    }

    documentUpload(formdata) {
        return BaseApi.postFormData(APIURL.API_URL + `open-upload?subdomain_name=${domain}`, formdata);
    }

    documentUploadInvite(slug, formdata) {
        return BaseApi.postFormData(APIURL.org_URL + `open-upload/${slug}?subdomain_name=${domain}`, formdata);
    }

    updateInviteLink(id, status) {
        return BaseApi.putWithData(APIURL.API_URL + `employee/update_invite_link/${id}?request_id=${LocalStorage.uid()}&status=${status}&subdomain_name=${domain}`);
    }

    ApproveTheInviteLink(id, data) {
        return BaseApi.putWithToken(APIURL.API_URL + `employee/update_invite_link/${id}`, data);
    }

    relation() {
        return BaseApi.getWithTenantParams(APIURL.org_URL + `relationship-type/dropdown-without-token?request_id=${LocalStorage.uid()}`, LocalStorage.getPathId());
    }

    getCountryList() {
        return BaseApi.getWithTenantParams(APIURL.org_URL + `country/dropdown-without-token?request_id=${LocalStorage.uid()}`, LocalStorage.getPathId());
    }

    getStatesList(country) {
        return BaseApi.getWithTenantParams(APIURL.org_URL + `state/dropdown-without-token?request_id=${LocalStorage.uid()}&country_id=${country}`, LocalStorage.getPathId());
    }

    uploadDocsUpdate(id, data) {
        return BaseApi.putWithData(APIURL.employee_URL + `employee/update-invite-link/${id}`, data)
    }

    uploadDocsIndex(id) {
        return BaseApi.getWithTenantParams(APIURL.employee_URL + `employee/invite-via-link?id=${id}&request_id=${LocalStorage.uid()}&subdomain_name=${domain}`, LocalStorage.getPathId())
    }


}
// eslint-disable-next-line
const onBoardLinkApiInstance = new onBoardLinkApi();
export default onBoardLinkApiInstance;