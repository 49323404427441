import React, { useEffect, useState } from 'react';
import UserProfileStyles from '../UserProfileStyles'; // eslint-disable-next-line
import { Box, Typography, Tabs, Tab, Skeleton, Grid, ButtonGroup, Stack, useTheme } from '@mui/material';

// import EducationDocs from './EducationDocs';
// import PersonalDocs from './PersonalDocs';
// import WorkAuthorization from './WorkAuthorization'; 
// import EmployeesApi from '../../../../apis/admin/employees/EmployeesApi'; 
// import { addErrorMsg } from '../../../../utils/utils';
import Button from '../../../../components/customButton/Button';
import offBoardSave from "../../../../assets/svg/offBoardSave.svg"
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import Text from '../../../../components/customText/Text';


export default function ControlledAccordions(props) {
    // eslint-disable-next-line
    const classes = UserProfileStyles(); // eslint-disable-next-line
    const [tab, setTab] = useState(0);

    // eslint-disable-next-line
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [formLoader, setformLoader] = useState(false) // eslint-disable-next-line
    const [list, setList] = useState([]);
    const [current, setCurrent] = useState(props.documentPosition);
    const [openPopup, setOpenpopup] = useState(false)
    const [currentDummy, setcurrentDummy] = useState(props.documentPosition);
    const [tabDummy, settabDummy] = useState(0);

    useEffect(() => {
        setCurrent(props.documentPosition) // eslint-disable-next-line
    }, [props])


    const handleChangeTab = (event, newValue) => {
        if (props.formEdit) {
            setOpenpopup(true)
        }
        else {
            setTab(newValue);
            setCurrent(event);
            props.setdocumentPosition(event)
        }
        setcurrentDummy(event)
        settabDummy(newValue)
    };

    const handleChangeTabSave = (event, newValue) => {
        setTab(tabDummy);
        setCurrent(currentDummy);
        props.setdocumentPosition(currentDummy)
    };

    return (
        <Box>
            <Box>
                <ButtonGroup
                    variant="outlined"
                    fullWidth
                    aria-label="outlined button group"
                >
                    <Button
                        sx={{
                            font: "15px Quicksand ",
                            textTransform: 'capitalize ',
                            fontWeight: "700",
                            padding: "9px 15px 9px 15px",
                            borderRight: `${current === "education" ? 'none' : ''}`,
                            borderColor: `${current === "education" ? "#0C75EB" : "#E2E5E6"} `,
                            background: `${current === "education" ? "#0C75EB" : "#F9FAFA"} `,
                            color: `${current === "education" ? "#ffffff" : "#849199"} `,
                            "&:hover": { borderColor: `${current === "education" ? "#0C75EB" : "#E2E5E6"} `, },
                            cursor: `${formLoader ? 'default' : 'pointer'}`,
                            [useTheme().breakpoints.down('xl')]: {
                                font: "12px Quicksand",
                                fontWeight: "700",
                            },
                            [useTheme().breakpoints.down('lg')]: {
                                font: "10px Quicksand",
                                fontWeight: "700",
                            }
                        }}
                        variant={`${current === "education" ? "contained" : "outlined"}`}
                        onClick={() => formLoader ? '' : handleChangeTab("education")}
                    >
                        Education Documents
                    </Button>
                    <Button
                        sx={{
                            font: "15px Quicksand ",
                            textTransform: 'capitalize ',
                            fontWeight: "700",
                            padding: "9px 15px 9px 15px",
                            borderLeft: `${current === "work" ? 'none' : ''}`,
                            borderColor: `${current === "work" ? "#0C75EB" : "#E2E5E6"} `,
                            background: `${current === "work" ? "#0C75EB" : "#F9FAFA"} `,
                            color: `${current === "work" ? "#ffffff" : "#849199"}`,
                            cursor: `${formLoader ? 'default' : 'pointer'}`,
                            "&:hover": {
                                borderColor: `${current === "work" ? "#0C75EB" : "#E2E5E6"}`,
                                borderLeft: "none",
                            },
                            [useTheme().breakpoints.down('xl')]: {
                                font: "12px Quicksand",
                                fontWeight: "700",
                            },
                            [useTheme().breakpoints.down('lg')]: {
                                font: "10px Quicksand",
                                fontWeight: "700",
                            }
                        }}
                        variant={`${current === "work" ? "contained" : "outlined"}`}
                        onClick={() => formLoader ? '' : handleChangeTab("work")}
                    >
                        Work Authorization Documents
                    </Button>
                    <Button
                        sx={{
                            font: "15px Quicksand ",
                            textTransform: 'capitalize ',
                            fontWeight: "700",
                            padding: "9px 15px 9px 15px",
                            borderLeft: `${current === "visa" ? 'none' : ''}`,
                            borderColor: `${current === "visa" ? "#0C75EB" : "#E2E5E6"} `,
                            color: `${current === "visa" ? "#ffffff" : "#849199"} `,
                            background: `${current === "visa" ? "#0C75EB" : "#F9FAFA"} `,
                            "&:hover": { borderColor: `${current === "visa" ? "#0C75EB" : "#E2E5E6"} `, },
                            cursor: `${formLoader ? 'default' : 'pointer'}`,
                            [useTheme().breakpoints.down('xl')]: {
                                font: "12px Quicksand",
                                fontWeight: "700",
                            },
                            [useTheme().breakpoints.down('lg')]: {
                                font: "10px Quicksand",
                                fontWeight: "700",
                            }
                        }}
                        variant={`${current === "visa" ? "contained" : "outlined"}`}
                        onClick={() => formLoader ? '' : handleChangeTab("visa")}
                    >
                        Personal Documents
                    </Button>
                </ButtonGroup>
            </Box>
            <ReusablePopup openPopup={openPopup} setopenPopup={setOpenpopup} white iconHide fullWidth>
                <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                    <img src={offBoardSave} alt='noactive' />
                </Grid>
                <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                    <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
                </Grid>
                <Grid container justifyContent={'center'} alignContent={'center'}>
                    <Text offBoardBodyFont>Your progress will not be saved</Text>
                </Grid>
                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                    <Stack direction={"row"} spacing={3}>
                        <Button smallBlackOutline onClick={() => { setOpenpopup(false) }} >
                            Cancel
                        </Button>
                        <Button smallBlue redBackground onClick={() => { props.setformEdit(false); setOpenpopup(false); handleChangeTabSave() }} >
                            Yes
                        </Button>
                    </Stack>
                </Grid>
            </ReusablePopup>
        </Box>
    );
}