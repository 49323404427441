import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import DasboardStyles from '../../views/admin/dashboard/DasboardStyles';
import UserProfileStyles from '../../views/admin/employees/userprofile/UserProfileStyles'
import { Box, Stack, Grid, Divider, Menu, Backdrop, Badge, Hidden, Breadcrumbs, ListItemButton } from '@mui/material';
import warning from '../../assets/employee/info.svg';
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Search from '../../assets/svg/search1.svg';
import NewDownloadcloud from '../../assets/svg/download-cloud-new.svg';
import Userplus from '../../assets/svg/user-plus.svg';
import { BlackToolTip, capitalizeAndAddSpace, NoDataFound, NoPermission, NotApplicable } from '../../utils/utils';
import { Link, useNavigate, } from "react-router-dom";
import Text from '../../components/customText/Text';
import Button from '../../components/customButton/Button';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LocalStorage from '../../utils/LocalStorage';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import infoCircle from '../../assets/images/info-circle.png';
import Loader from '../../assets/gif/japfuLoader.gif';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomCircularProgress from '../../components/progressbar/CircularProgress';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import noDataFound from '../../assets/svg/NoDataFoundIcon.svg'
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import LeftNavigateArrow from '../../assets/svg/LeftNavigateArrow.svg'
import TourGuideConfigApi from '../../apis/configurations/tourguideConfig/TourGuideConfigApi';
import { employee_URL } from '../../config/development';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));




export default function ControlledAccordions({ setStartTour }) {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = DasboardStyles();
    const classesUserProfile = UserProfileStyles();
    const dataArr = ["General Details", "Documents", "Bank Details", "Skills"];
    const [current, setCurrent] = useState("General Details");
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);


    const employees = [
        {
            id: "22c56328-3e1d-4843-9a28-8d4694f11727",
            first_name: "ABC",
            middle_name: null,
            last_name: "EFG",
            full_name: "ABC EFG",
            profile_picture_url: `${employee_URL + 'documents/avatar/male/2.png'}`,
            contact_number: "654-756-7567",
            email_id: "abc@efg.jhgf",
            reference_id: "CON-139",
            sub_status: "Marketing",
            status: "Active",
            e_verified: 2,
            gender: "Male",
            visa_type_id: 1,
            visa_type: "H-1B",
            employee_category: "W2",
            employment_type: "Consultant",
            profile_progress: "50.00",
            enable_placement: false,
            placement_exists: false,
            complete_profile: "education_documents",
            date_of_joining: "10/24/2024"
        },
        {
            id: "fafe94e5-2725-4d7f-94a2-a404d68f08be",
            first_name: "HIJ",
            middle_name: null,
            last_name: "KLM",
            full_name: "HIJ KLM",
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            contact_number: "965-325-8962",
            email_id: "HIJ@KLM.dfdsf",
            reference_id: "CON-138",
            sub_status: "Marketing",
            status: "Active",
            e_verified: 2,
            gender: "Male",
            visa_type_id: null,
            visa_type: null,
            employee_category: "W2",
            employment_type: "Consultant",
            profile_progress: "55.56",
            enable_placement: false,
            placement_exists: false,
            complete_profile: "education_documents",
            date_of_joining: "10/24/2024"
        },
        {
            id: "9e636b37-2f2b-4327-92ae-829b6b53d13a",
            first_name: "IJKL",
            middle_name: null,
            last_name: "FERW",
            full_name: "ABCDEFGH",
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            contact_number: "988-899-9488",
            email_id: "EFT@mail.fr",
            reference_id: "CON-137",
            sub_status: "Marketing",
            status: "Active",
            e_verified: 3,
            gender: "Male",
            visa_type_id: null,
            visa_type: null,
            employee_category: "W2",
            employment_type: "Consultant",
            profile_progress: "55.56",
            enable_placement: false,
            placement_exists: false,
            complete_profile: "education_documents",
            date_of_joining: "10/24/2024"
        },
        {
            id: "d0c3fd67-8900-481e-9473-d01404e59c15",
            first_name: "DFG",
            middle_name: null,
            last_name: "FGH",
            full_name: "DFG FGH",
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            contact_number: "897-897-9879",
            email_id: "rty@ty.gg",
            reference_id: "CON-136",
            sub_status: "Marketing",
            status: "Active",
            e_verified: 2,
            gender: "Male",
            visa_type_id: null,
            visa_type: null,
            employee_category: "W2",
            employment_type: "Consultant",
            profile_progress: "77.78",
            enable_placement: false,
            placement_exists: false,
            complete_profile: "bank_accounts",
            date_of_joining: "10/18/2024"
        },
        {
            id: "ea2d4328-af9f-44d3-8ee4-e24d8bff3c41",
            first_name: "HIJ",
            middle_name: null,
            last_name: "KLM",
            full_name: "HIJ KLM",
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            contact_number: "879-879-8788",
            email_id: "HIJ@KLM.FGH",
            reference_id: "CON-135",
            sub_status: "Marketing",
            status: "Active",
            e_verified: 0,
            gender: "Male",
            visa_type_id: null,
            visa_type: null,
            employee_category: "W2",
            employment_type: "Consultant",
            profile_progress: "55.56",
            enable_placement: false,
            placement_exists: false,
            complete_profile: "education_documents",
            date_of_joining: "10/23/2024"
        },


    ];
    const employee = {
        id: "22c56328-3e1d-4843-9a28-8d4694f11727",
        status: "Active",
        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
        gender: "Male",
        last_working_day: null,
        company_id: null,
        vendor_name: null,
        company_name: null,
        profile_progress: "57.14",
        e_verified: 2,
        project_status: "Marketing",
        label_name: "Active - Marketing",
        basic_details: {
            first_name: "ABC",
            middle_name: "",
            last_name: "EFG",
            full_name: "ABC EFG",
            dob: "10/24/2006",
            gender: "Male",
            blood_group: null,
            marital_status_id: null,
            vendor_id: "vendor_price"
        },
        contact_details: {
            contact_number: "654-756-7567",
            alternate_contact_number: "",
            email_id: "ABC@EFG.HIJ",
            alternate_email_id: ""
        },
        emergency_contacts: [
            {
                id: 409,
                city: null,
                name: "ABC",
                email_id: null,
                state_id: null,
                zip_code: null,
                address_1: "ABC",
                address_2: null,
                country_id: null,
                state_name: null,
                country_name: null,
                contact_number: "456-786-5432",
                relationship_id: 1,
                relationship_name: "Father"
            }
        ],
        current_address: {
            address_line_one: "JKL",
            address_line_two: null,
            city: "MNO",
            state_id: 72,
            state_name: null,
            country_id: 1,
            country_name: null,
            zip_code: "654675"
        },
        employment_details: {
            reference_id: "CON-139",
            employment_type: "Consultant",
            employment_type_id: 2,
            date_of_joining: "10/24/2024",
            rejoin_date: null,
            employee_category: "W2",
            employment_category_id: 1,
            is_usc: 0,
            ssn: "123-45-6789",
            visa_type: "H-1B",
            visa_type_id: 1,
            role_id: null,
            role_name: null,
            enable_login: 0
        },
        confirm_rehire: null,
        enable_payroll: false,
        complete_profile: "education_documents",
        documents_pending: true,
        bank_pending: false,
        skills_pending: true,
        is_off_boarding_initiated: false,
        date_of_joining: "10/24/2024",
        off_boarding_percentage: 0,
        is_configurable_timesheet_available: false,
        min_last_working_day: "10/25/2024",
        first_name: "ABC",
        middle_name: "",
        last_name: "EFG",
        full_name: "ABC EFG",
        dob: "10/24/2006",
        blood_group: null,
        marital_status_id: null,
        vendor_id: "vendor_price",
        contact_number: "654-756-7567",
        alternate_contact_number: "",
        email_id: "ABC@EFG.HIJ",
        alternate_email_id: "",
        address_line_one: "ABC",
        address_line_two: null,
        city: "EFG",
        state_id: 72,
        state_name: null,
        country_id: 1,
        country_name: null,
        zip_code: "654675",
        reference_id: "CON-139",
        employment_type: "Consultant",
        employment_type_id: 2,
        rejoin_date: null,
        employee_category: "W2",
        employment_category_id: 1,
        is_usc: 0,
        ssn: "245-23-4542",
        visa_type: "H-1B",
        visa_type_id: 1,
        role_id: null,
        role_name: null,
        enable_login: 0,
        enable_rehire: true,
        visa_information_exists: true,
        pending_requests: {
            general_details: false,
            education_documents: false,
            passport: false,
            i94: false,
            visa_documents: false,
            personal_documents: false,
            ssn_documents: false,
            other_documents: false,
            bank_details: false
        }
    }

    const expanded = false;


    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if (anchorEl) {
            handleClose()
        }
    };

    const openBacKLoader = false;
    const selectedOption = 'All employees';

    const steps = [
        {
            element: '#addemployee', popover: {
                title: 'Add Employee', description: 'Welcome to the Add Employee section! Here, you can input all necessary details to create a new employee profile. Fill in the required fields and get your employees onboarded.', side: "left",
                align: 'start'
            }
        },
        {
            element: '#tabs', popover: {
                title: 'Emp Category Tabs', description: 'Manage and organize different categories of employees based on their work types like 1099, W2, etc.', side: "bottom",
                align: 'end'
            }
        },
        {
            element: '#viewprofile', popover: {
                title: 'View Profile', description: 'Click here to view detailed information about an employee. This includes personal details, employment & E-verification status and a lot more.', side: "left",
                align: 'start'
            }
        },
        {
            element: '#GeneralDetails', popover: {
                title: 'General Details', description: 'Provides the essential personal and professional details of the employee, such as name, contact information, job title, placements etc.', side: "right",
                align: 'start'
            }
        },
        {
            element: '#Documents', popover: {
                title: 'Documents', description: 'Upload and manage the necessary documents for the employee. This can include identification proofs, certifications, and other relevant files.', side: "right",
                align: 'start'
            }
        }, {
            element: '#BankDetails', popover: {
                title: 'Banks Details', description: `Enter or manage the employee's banking information here for payroll purposes. Ensure that the details are accurate for seamless salary processing.`, side: "right",
                align: 'end'
            }
        },
        {
            element: '#Skills', popover: {
                title: 'Skills', description: 'Add and update the skills that the employee possesses. This will help in better job placement and project assignments.', side: "right",
                align: 'end'
            }
        },
        {
            element: '#everifybutton', popover: {
                title: 'E-verify CTA', description: 'Use this button to initiate the e-verification process for employee documents. This ensures that all provided documents are verified and the employee is clear to work for employers as per the law.', side: "right",
                align: 'start'
            }
        },
    ];

    if((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true)))){
        steps.push(...[{
            element: '#disableuser', popover: {
                title: 'Disable User Access', description: 'Use this button to immediately disable the selected user’s access to the system. Once disabled, the user will no longer be able to log in to the application.', side: "right",
                align: 'start'
            }
        }, {
            element: '#offboardemployee', popover: {
                title: 'Offboarding Option', description: 'Manage the offboarding process when an employee is leaving the organization or ending a placement. You can either offboard or disable the user access entirely.', side: "right",
                align: 'start'
            }
        },])
    }
        


    
    const [showUserProfile, setUserProfile] = useState('allemployees');

    useEffect(() => {
        const driverObj = driver({
            popoverClass: 'driverjs-theme',
            allowClose: false,
            stagePadding: 0,
            stageRadius: 0,
            animate: false,
            allowKeyboardControl: false,
            disableActiveInteraction: true,
            steps: steps,
            popoverOffset: 15,

            onPopoverRender: (popover, opts) => {//creates the popover with required design 
                const currentStep = opts.state.activeIndex;
                const totalSteps = opts.config.steps.length;
                const updateTourGuideStatus = async (tourModule) => {
                    await TourGuideConfigApi.updateApi({
                        "request_id": LocalStorage.uid(),
                        "module": tourModule,
                        "status": true
                    })
                }
                const title = document.createElement('div');
                title.style.display = 'flex';
                title.style.alignItems = 'center';
                title.style.marginBottom = '8px';

                const icon = document.createElement('img');
                icon.src = LeftNavigateArrow;
                icon.alt = 'Back Arrow';
                icon.style.width = '16px';
                icon.style.height = '16px';
                icon.style.marginRight = '2px';
                icon.style.cursor = 'pointer';
                if (currentStep !== 0) {
                    icon.onclick = (event) => {
                        event.stopPropagation();
                        if (currentStep == 3) {
                            setTimeout(() => {
                                driverObj.movePrevious();
                            }, 400)
                            setUserProfile('allemployees')
                        }
                        else if (currentStep == 7) {
                            driverObj.movePrevious();
                            setCurrent('Skills')
                        }
                        else if (currentStep == 6) {
                            driverObj.movePrevious();
                            setCurrent('Bank Details')
                        }
                        else if (currentStep == 5) {
                            driverObj.movePrevious();
                            setCurrent('Documents')
                        } else if (currentStep == 4) {
                            driverObj.movePrevious();
                            setCurrent('General Details')
                        }
                        else if (currentStep == 8) {
                            const threedots = document.getElementById('threedots');
                            threedots.click();
                            setTimeout(() => { driverObj.movePrevious(); }, 300)
                        }
                        else {
                            driverObj.movePrevious();
                        }

                    };
                    title.appendChild(icon);
                }
                const textContainer = document.createElement('div');
                textContainer.style.display = 'flex';
                textContainer.style.justifyContent = 'space-between';
                textContainer.style.width = '100%';
                const titleText = document.createElement('p');
                titleText.textContent = opts.config.steps[currentStep].popover.title;
                titleText.style.fontFamily = 'Quicksand';
                titleText.style.fontSize = '15px';
                titleText.style.fontWeight = '700';
                const stepSpan = document.createElement('span');
                stepSpan.textContent = `(${currentStep + 1}/${totalSteps})`;
                stepSpan.style.display = 'flex';
                stepSpan.style.alignItems = 'center';
                stepSpan.style.fontSize = '12px';
                stepSpan.style.fontWeight = '600';
                stepSpan.style.color = '#525252';
                stepSpan.style.fontFamily = 'Quicksand';
                textContainer.appendChild(titleText);
                textContainer.appendChild(stepSpan);
                title.appendChild(textContainer);

                const description = document.createElement('div');
                description.innerText = opts.config.steps[currentStep].popover.description;
                description.style.marginBottom = '24px';
                description.style.fontSize = '14px';
                description.style.fontWeight = '400';
                description.style.color = '#404040';
                description.style.fontFamily = 'Quicksand';

                const footer = document.createElement('div');
                footer.style.display = 'flex';
                footer.style.justifyContent = 'space-between';
                footer.style.width = '100%';
                footer.style.boxSizing = 'border-box';
                const applyInviteLinkBtnStyles = (element, text) => {
                    element.style.cursor = 'pointer';
                    element.style.textAlign = 'center';
                    element.style.fontSize = '12px';
                    element.style.fontFamily = 'Quicksand';
                    element.style.fontWeight = '600';
                    element.style.width = (text == 'finish') ? '100%' : '130px';
                    element.style.height = '32px';
                    element.style.border = '1.5px solid rgba(12, 117, 235, 1)';
                    element.style.borderRadius = '4px';
                    element.style.color = (text == 'skip') ? '#0C75EB' : '#ffffff';
                    element.style.transition = 'all .3s ease';
                    element.style.outline = 'none';
                    element.style.backgroundColor = (text == 'skip') ? '#ffffff' : '#0C75EB';

                };
                const skipButton = document.createElement('button');
                skipButton.innerText = 'Skip';
                applyInviteLinkBtnStyles(skipButton, 'skip');

                skipButton.onclick = async () => {
                    setStartTour();
                    LocalStorage.setStartTour({ 'employees': false })
                    updateTourGuideStatus("EMPLOYEE");
                    driverObj.destroy();
                    if(LocalStorage.getUserData().org_config_set == false){
                        navigate('/configuration');
                    }

                };
                const finishButton = document.createElement('button');
                finishButton.innerText = 'Finish';
                applyInviteLinkBtnStyles(finishButton, 'finish');
                finishButton.onclick = () => {
                    setStartTour();
                    LocalStorage.setStartTour({ 'employees': false })
                    updateTourGuideStatus("EMPLOYEE");
                    driverObj.destroy();
                    if(LocalStorage.getUserData().org_config_set == false){
                        navigate('/configuration');
                    }

                };
                const nextButton = document.createElement('button');
                nextButton.innerText = 'Next';
                applyInviteLinkBtnStyles(nextButton, 'next');
                nextButton.onclick = () => {
                    if (currentStep == 2) {
                        setTimeout(() => {
                            driverObj.moveNext();
                        }, 400)
                        setUserProfile('employeeprofile')
                        setCurrent('General Details')


                    }
                    else if (currentStep == 3) {
                        driverObj.moveNext();
                        setCurrent('Documents')
                    } else if (currentStep == 4) {
                        driverObj.moveNext();
                        setCurrent('Bank Details')
                    } else if (currentStep == 5) {
                        driverObj.moveNext();
                        setCurrent('Skills')
                    } else if (currentStep == 6) {
                        setCurrent('General Details')
                        driverObj.moveNext();

                    }

                    else if (currentStep == 7) {
                        const threedots = document.getElementById('threedots');
                        threedots.click();
                        setTimeout(() => { driverObj.moveNext(); }, 300)

                    } else {
                        driverObj.moveNext();
                    }

                };
                if (currentStep === totalSteps - 1) {
                    footer.appendChild(finishButton);
                } else {
                    footer.appendChild(skipButton);
                    footer.appendChild(nextButton);
                }
                popover.wrapper.innerHTML = '';
                const arrow = popover.arrow;
                if (arrow) {
                    popover.wrapper.appendChild(arrow);
                }
                popover.wrapper.appendChild(title);
                popover.wrapper.appendChild(description);
                popover.wrapper.appendChild(footer);

            }
        });
        setTimeout(() => {
            driverObj.drive()

        }, 500);

        return () => {
            driverObj.destroy();
        };
        // eslint-disable-next-line
    }, [])


    return (
        <>
            {showUserProfile == 'allemployees' && <>
                {
                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => (item.slug == "employee_view" || item.slug == "internal_employee_view") && item.is_allowed == true))) ?
                        <Box sx={{ width: "90%" }} >
                            <Box style={{ padding: "20px 10px 10px 10px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Box id='tabs' sx={{ padding: '5px' }}>
                                        <Box className={classes.btnBox} >
                                            <Button saveBtn className={selectedOption == "All employees" ? classes.activeButton : classes.inActiveButton}>All Employees</Button>
                                            <Button saveBtn className={selectedOption == "Self Onboarded" ? classes.activeButton : classes.inActiveButton} sx={{ marginLeft: "8px" }}>Onboarders</Button>
                                            <Button saveBtn className={selectedOption == "Internal Employees" ? classes.activeButton1 : classes.inActiveButton1} sx={{ marginLeft: "8px" }}>Internal Employees</Button>
                                        </Box>
                                    </Box>

                                    <div>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingRight: "5px" }}>
                                            {selectedOption == "Internal Employees" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed !== true))) ? "" :
                                                selectedOption !== "Internal Employees" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed !== true))) ? "" :
                                                    <Hidden mdDown>
                                                        <Box sx={{
                                                            height: "44px", border: "1.5px solid #E2E5E6", width: "438px", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center",
                                                            "@media (min-width: 1100px) and (max-width: 1400px)": {
                                                                width: "238px"
                                                            },
                                                            "@media (min-width: 900px) and (max-width: 1100px)": {
                                                                width: "188px",
                                                                marginLeft: '5px'
                                                            },
                                                        }}>
                                                            <input
                                                                type="text"
                                                                className={classes.EmployeesSearchInput}

                                                                placeholder={`${selectedOption == 'All employees' ? 'Search by Name / ID' : 'Search by Name'}`}
                                                            />
                                                            <Box sx={{ paddingRight: "15px !important" }}>

                                                                <img src={Search} alt="Search" />

                                                            </Box>
                                                        </Box>
                                                    </Hidden>
                                            }

                                            {selectedOption == "All employees" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) &&
                                                <Hidden mdDown>
                                                    <button title="Filter" type="button" className={classes.borderButton}>
                                                        <Badge color="error">
                                                            <FilterListIcon sx={{ color: "#2BEA2B" }} />
                                                        </Badge>
                                                    </button>
                                                </Hidden>
                                            }
                                            {selectedOption == "All employees" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) &&
                                                <Hidden mdDown>
                                                    <button title="Export" type="button" className={classes.borderButton}>
                                                        <img src={NewDownloadcloud} alt="Userplus" />
                                                    </button>
                                                </Hidden>
                                            }
                                            {selectedOption == "Internal Employees" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed == true))) &&
                                                <Hidden mdDown>
                                                    <button title="Export" type="button" className={classes.borderButton}>
                                                        <img src={NewDownloadcloud} alt="Userplus" />
                                                    </button>
                                                </Hidden>
                                            }

                                            {selectedOption !== "Internal Employees" &&
                                                <>
                                                    {
                                                        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) ?
                                                            <Hidden mdDown>
                                                                <Box id="addemployee" sx={{ padding: '5px' }}>
                                                                    <Button sx={{ padding: "0px 15px !important" }} addButton startIcon={<img src={Userplus} alt="Userplus" className={classes.addIcon} />}>
                                                                        Add Employee
                                                                    </Button>
                                                                </Box>

                                                            </Hidden>
                                                            :
                                                            ""
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>

                                </div>
                                <Hidden mdUp>
                                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: "end", alignItems: "center", padding: '10px', gap: '10px' }}>
                                        <Hidden mdUp>
                                            <div style={{ height: "44px", border: "1.5px solid #E2E5E6", width: "368px", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                                <input
                                                    type="text"
                                                    className={classes.EmployeesSearchInput}

                                                    placeholder="Search by Name / ID"
                                                />
                                                <Box sx={{ paddingRight: "15px !important" }}>

                                                    <img src={Search} alt="Search" />

                                                </Box>
                                            </div>
                                        </Hidden>
                                        {selectedOption == "All employees" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) &&
                                            <button type="button" className={classes.borderButton} >
                                                <Badge color="error">
                                                    <FilterListIcon sx={{ color: "#2BEA2B" }} />
                                                </Badge>
                                            </button>
                                        }
                                        {selectedOption == "All employees" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) &&
                                            <button type="button" className={classes.borderButton}>
                                                <img src={NewDownloadcloud} alt="Userplus" />
                                            </button>
                                        }
                                        {selectedOption == "Internal Employees" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed == true))) &&
                                            <button type="button" className={classes.borderButton} >
                                                <img src={NewDownloadcloud} alt="Userplus" />
                                            </button>}

                                        {selectedOption !== "Internal Employees" &&
                                            <>
                                                {
                                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) ?
                                                        <Box id="addemployee" sx={{ padding: '5px' }}>
                                                            <Button sx={{ padding: "0px 15px !important" }} addButton startIcon={<img src={Userplus} alt="Userplus" className={classes.addIcon} />}>
                                                                Add Employee
                                                            </Button>
                                                        </Box>
                                                        :
                                                        ""
                                                }
                                            </>
                                        }
                                    </div>

                                </Hidden>
                            </Box>

                            <Box style={{ padding: "10px" }}>
                                {selectedOption !== "Internal Employees" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed !== true))) ? "" :
                                    selectedOption == "Internal Employees" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed !== true))) ? "" :
                                        <Box sx={{
                                            display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px",
                                            "@media (min-width: 200px) and (max-width: 1400px)": {
                                                fontSize: "12px"
                                            },
                                        }} >

                                            <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                <Hidden mdUp>
                                                    <Grid item lg={3} md={5} sm={4} xs={5} textAlign={'left'} letterSpacing={'0.5px'}>Employee Name
                                                        <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} >
                                                            <FilterListIcon sx={{ height: "19px", color: "#2BEA2B" }} />
                                                        </IconButton>

                                                    </Grid>
                                                </Hidden>
                                                <Hidden mdDown>
                                                    <Grid item lg={5} md={5} sm={5} xs={5} textAlign={'left'} letterSpacing={'0.5px'}>Employee Name
                                                        <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} >
                                                            <FilterListIcon sx={{ height: "19px", color: "#2BEA2B" }} />
                                                        </IconButton>

                                                    </Grid>
                                                </Hidden>
                                                <Grid item lg={3} md={2} sm={3} xs={3} paddingRight={'17px'} style={{ display: "flex", alignItems: "center", justifyContent: "left", marginLeft: "-5px" }} >Employment Type</Grid>
                                                <Hidden mdUp>
                                                    <Grid container item lg={2} md={3} sm={3} xs={2}>
                                                        {selectedOption === "All employees" &&
                                                            <Grid item lg={6} style={{ display: "flex", alignItems: "center", justifyContent: "left", marginLeft: "-14px" }} >Visa Type</Grid>}
                                                        <Grid aria-controls="status-menu" item lg={6} md={6} sm={6} xs={6} style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: selectedOption === "All employees" ? "end" : "start" }}  >Status

                                                            <KeyboardArrowDownIcon sx={{ color: "#2BEA2B !important" }} />

                                                        </Grid>

                                                    </Grid>
                                                </Hidden>
                                                <Hidden mdDown>
                                                    <Grid container item lg={2} md={3} sm={2} xs={2}>
                                                        {selectedOption === "All employees" &&
                                                            <Grid item lg={6} style={{ display: "flex", alignItems: "center", justifyContent: "left", marginLeft: "-14px" }} >Visa Type</Grid>}
                                                        <Grid aria-controls="status-menu" item lg={6} md={6} sm={6} xs={6} style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: selectedOption === "All employees" ? "end" : "start" }}  >Status

                                                            <KeyboardArrowDownIcon sx={{ color: "#2BEA2B !important" }} />

                                                        </Grid>

                                                    </Grid>
                                                </Hidden>


                                                <Grid item xl={2} lg={2} md={2} sm={2} xs={2} display={"flex"}>
                                                    <Text sx={{
                                                        font: '14px Quicksand !important', letterSpacing: '0px', opacity: 1, color: "#737373 !important",
                                                        "@media (min-width: 900px) and (max-width: 1500px)": {
                                                            font: "11px Quicksand !important",
                                                        },
                                                        "@media (min-width: 200px) and (max-width: 900px)": {
                                                            font: "9px Quicksand !important",
                                                        },
                                                    }}>
                                                        Count
                                                        <HtmlTooltip
                                                            placement="bottom"
                                                            arrow
                                                            title={
                                                                <React.Fragment>
                                                                    <Box>
                                                                        <Typography className={classes.profileTooltipText}>
                                                                            Total Employee count is based on the filters applied
                                                                        </Typography>
                                                                    </Box>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                                        </HtmlTooltip>
                                                    </Text>
                                                </Grid>
                                            </Grid>


                                        </Box>
                                }


                                <>
                                    {selectedOption !== "Internal Employees" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed !== true))) ? <>{NoPermission()}</> :
                                        selectedOption == "Internal Employees" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed !== true))) ? <>{NoPermission()}</> :
                                            <>
                                                {
                                                    employees.length > 0 ?
                                                        <Box sx={{
                                                            height: '65vh', overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
                                                            paddingLeft: '5px', paddingRight: '5px', paddingBottom: '5px',
                                                            "@media (min-height: 850px)": {
                                                                height: '73vh'
                                                            }
                                                        }} >

                                                            <>
                                                                {
                                                                    employees.map((Emp, key) => (
                                                                        <Accordion
                                                                            key={key}
                                                                            className={classes.newCustomAccordion}
                                                                            expanded={expanded === `panel${key}`} >
                                                                            <AccordionSummary
                                                                                aria-controls="panel1bh-content"
                                                                                id="panel1bh-header"
                                                                                className={classes.AccordionSummary}
                                                                            >
                                                                                <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                                                    <Hidden mdUp>
                                                                                        <Grid item lg={5} md={5} sm={4} xs={5} textAlign='start'>
                                                                                            <Box display='flex' alignItems='center' gap={1}>

                                                                                                <CustomCircularProgress
                                                                                                    color={"#ffffff"}
                                                                                                    src={Emp.profile_picture_url}
                                                                                                    name={Emp.profile_picture_url == undefined || Emp.profile_picture_url == null || Emp.profile_picture_url == '' ? Emp.full_name && Emp.full_name[0] : ''}
                                                                                                    imgWidth='50px !important'
                                                                                                    imgHeight='50px !important'
                                                                                                    avatarWidth='50px !important'
                                                                                                    avatarHeight='50px !important'
                                                                                                    size={58}

                                                                                                    dashboardContent={Emp.status == 'Active' && Emp.profile_progress < 100 ? <Button addButtonTooltip sx={{ margin: '10px 0px 5px 0px' }} >Complete Profile</Button> : ''}
                                                                                                />



                                                                                                <Box>
                                                                                                    <Hidden smUp>
                                                                                                        {
                                                                                                            Emp.status == 'In Active' ?
                                                                                                                <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                                                    {
                                                                                                                        capitalizeAndAddSpace(Emp.full_name)
                                                                                                                    }
                                                                                                                </Text>
                                                                                                                :
                                                                                                                <Text mediumBoldBlack noWrap>
                                                                                                                    {
                                                                                                                        capitalizeAndAddSpace(Emp.full_name)
                                                                                                                    }

                                                                                                                </Text>
                                                                                                        }
                                                                                                        <Text mediumLabel noWrap style={{ margin: '4px 0' }}>  {Emp.reference_id === "" ? "--" : Emp.reference_id}</Text>
                                                                                                    </Hidden>
                                                                                                    <Hidden smDown>
                                                                                                        {
                                                                                                            Emp.status == 'In Active' ?
                                                                                                                <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                                                    {
                                                                                                                        capitalizeAndAddSpace(Emp.full_name)
                                                                                                                    }
                                                                                                                </Text>
                                                                                                                :
                                                                                                                <Text mediumBoldBlack noWrap>
                                                                                                                    {
                                                                                                                        capitalizeAndAddSpace(Emp.full_name)
                                                                                                                    }

                                                                                                                </Text>
                                                                                                        }
                                                                                                        <Text mediumLabel noWrap style={{ margin: '4px 0' }}>  {Emp.reference_id === "" ? "--" : Emp.reference_id}</Text>
                                                                                                    </Hidden>


                                                                                                </Box>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    </Hidden>
                                                                                    <Hidden mdDown>
                                                                                        <Grid item lg={5} md={5} sm={5} xs={5} textAlign='start'>
                                                                                            <Box display='flex' alignItems='center' gap={2}>

                                                                                                <CustomCircularProgress
                                                                                                    color={"#ffffff"}
                                                                                                    src={Emp.profile_picture_url}
                                                                                                    name={Emp.profile_picture_url == undefined || Emp.profile_picture_url == null || Emp.profile_picture_url == '' ? Emp.full_name && Emp.full_name[0] : ''}
                                                                                                    imgWidth='50px !important'
                                                                                                    imgHeight='50px !important'
                                                                                                    avatarWidth='50px !important'
                                                                                                    avatarHeight='50px !important'
                                                                                                    size={58}

                                                                                                    dashboardContent={Emp.status == 'Active' && Emp.profile_progress < 100 ? <Button addButtonTooltip sx={{ margin: '10px 0px 5px 0px' }} >Complete Profile</Button> : ''}
                                                                                                />
                                                                                                <Box>
                                                                                                    {
                                                                                                        Emp.status == 'In Active' ?
                                                                                                            <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                                                {
                                                                                                                    capitalizeAndAddSpace(Emp.full_name)
                                                                                                                }
                                                                                                            </Text>
                                                                                                            :
                                                                                                            <Text mediumBoldBlack noWrap>
                                                                                                                {
                                                                                                                    capitalizeAndAddSpace(Emp.full_name)
                                                                                                                }

                                                                                                            </Text>
                                                                                                    }
                                                                                                    <Text mediumLabel noWrap style={{ margin: '4px 0' }}>  {Emp.reference_id === "" ? "--" : Emp.reference_id}</Text>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    </Hidden>

                                                                                    <Grid item lg={3} md={2} sm={3} xs={3} textAlign={"start"}>
                                                                                        {selectedOption === "All employees" ? <Text smallBoldBlack noWrap >{Emp.employment_type === "" ? "--" : Emp.employment_type}</Text>
                                                                                            :
                                                                                            <Text smallBoldBlack noWrap >Consultant</Text>
                                                                                        }
                                                                                    </Grid>
                                                                                    <Grid container lg={selectedOption === "All employees" ? 3 : 2} md={selectedOption === "All employees" ? 3 : 2} sm={selectedOption === "All employees" ? 3 : 2} xs={selectedOption === "All employees" ? 3 : 2} textAlign={"start"}>
                                                                                        {selectedOption === "All employees" ?
                                                                                            <Stack
                                                                                                direction="row"
                                                                                                useFlexGap
                                                                                                width='80%'
                                                                                                divider={<Divider orientation="vertical" flexItem style={{ height: '50px' }} />}
                                                                                                spacing={3}
                                                                                                textAlign='center' alignItems='center'
                                                                                            >
                                                                                                <Grid item lg={6} md={6} sm={6} xs={6} textAlign={"start"}>

                                                                                                    <Text smallBoldBlack noWrap >
                                                                                                        {Emp.visa_type === "" || Emp.visa_type == null ?
                                                                                                            <>
                                                                                                                {NotApplicable()}
                                                                                                            </>
                                                                                                            :
                                                                                                            Emp.visa_type}
                                                                                                    </Text>
                                                                                                </Grid>

                                                                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                                                                    {
                                                                                                        Emp.status == 'In Active' ?
                                                                                                            <Box sx={{ height: "25px", borderRadius: "20px", padding: "3px 20px", background: "#494747" }}>
                                                                                                                <Text sx={{ fontWeight: "600 !important", font: "14px Quicksand", color: "#ffffff" }}>Inactive</Text>
                                                                                                            </Box> :
                                                                                                            <HtmlTooltip
                                                                                                                placement="bottom"
                                                                                                                arrow
                                                                                                                title={
                                                                                                                    <React.Fragment>
                                                                                                                        <Box>
                                                                                                                            <Typography className={classes.profileTooltipText}>
                                                                                                                                {Emp.sub_status}
                                                                                                                            </Typography>
                                                                                                                        </Box>
                                                                                                                    </React.Fragment>
                                                                                                                }
                                                                                                            >

                                                                                                                <Box sx={{
                                                                                                                    height: "25px", borderRadius: "20px", padding: "3px 20px", background: "#16A34A",
                                                                                                                    "@media (min-width: 00px) and (max-width: 900px)": {
                                                                                                                        padding: "3px"
                                                                                                                    }
                                                                                                                }}>
                                                                                                                    <Text sx={{
                                                                                                                        fontWeight: "600 !important", font: "14px Quicksand", color: "#ffffff",
                                                                                                                        "@media (min-width: 900px) and (max-width: 1400px)": {
                                                                                                                            font: "12px Quicksand",
                                                                                                                            paddingTop: '1px'
                                                                                                                        },
                                                                                                                        "@media (min-width: 00px) and (max-width: 900px)": {
                                                                                                                            font: "10px Quicksand",
                                                                                                                            paddingTop: '1px'
                                                                                                                        },
                                                                                                                    }}>Active</Text>
                                                                                                                </Box>
                                                                                                            </HtmlTooltip>
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Stack> :
                                                                                            <Box
                                                                                                className={`${classes.empStatusBox} ${Emp.status == "Offer Rejected" ? classes.empStatusBlackBox : (Emp.status == "Invitation Sent" || Emp.status == "Pending" || Emp.status == "Waiting") ? classes.empStatusPurpleBox : Emp.status == "In Progress" ? classes.empStatusBlueBox : Emp.status == "Link Expired" ? classes.empStatusOrangeBox : classes.empStatusGreenBox}`}>
                                                                                                {(Emp.status == "Invitation Sent" || Emp.status == "Pending" || Emp.status == "Waiting") ? "Waiting" : Emp.status}
                                                                                            </Box>
                                                                                        }
                                                                                    </Grid>

                                                                                    {selectedOption === "All employees" ?
                                                                                        <Grid item lg={1} id='viewprofile' p={1}>
                                                                                            {
                                                                                                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) ?
                                                                                                    <Typography

                                                                                                        component={Link}
                                                                                                        to={true ? `/employees/user-profile/${Emp.full_name === "" ? "" : Emp.full_name.trim().split(/ +/).join('-')}` : '/employees/onboard'}
                                                                                                        state={{ id: Emp.id, full_name: Emp.full_name, e_verify: Emp.e_verified, reference_id: Emp.reference_id, avatar_url: Emp.profile_picture_url, enable_login: Emp.enable_login, stage: Emp.drafted_stage, progress: Emp.profile_progress, from: 'employmentDashboard' }}
                                                                                                        className={classes.linkText}
                                                                                                    >
                                                                                                        {true ? "View Profile" : "Complete Profile"}
                                                                                                    </Typography> :
                                                                                                    <Text defaultmediumText noWrap >View Profile</Text>
                                                                                            }
                                                                                        </Grid>
                                                                                        :
                                                                                        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_create" && item.is_allowed == true))) ?
                                                                                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>
                                                                                                {
                                                                                                    (Emp.status == "Invitation Sent" || Emp.status == "Pending" || Emp.status == "Waiting") ?
                                                                                                        <Text defaultmediumText className={classes.linkInactive}>Verify</Text>
                                                                                                        :
                                                                                                        (Emp.status == "In Progress" || Emp.status == "Offer Accepted") ?
                                                                                                            <Text smallBlue className={classes.linkText}> {Emp.status == "In Progress" ? "Continue" : "Verify"}</Text>
                                                                                                            :
                                                                                                            Emp.status == "Link Expired" ?
                                                                                                                <Text smallBlue className={classes.linkText}>Re - Invite</Text>
                                                                                                                :
                                                                                                                <Text sx={{ cursor: "pointer" }} smallBlue>
                                                                                                                    {Emp.status == "Offer Rejected" ? "" : ""}
                                                                                                                </Text>
                                                                                                }
                                                                                            </Grid> :
                                                                                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>
                                                                                                {
                                                                                                    (Emp.status == "Invitation Sent" || Emp.status == "Pending" || Emp.status == "Waiting") ?
                                                                                                        <Text defaultmediumText className={classes.linkInactive}>Verify</Text>
                                                                                                        :
                                                                                                        (Emp.status == "In Progress" || Emp.status == "Offer Accepted") ?
                                                                                                            <Text defaultmediumText className={classes.linkInactive}> {Emp.status == "In Progress" ? "Continue" : "Verify"}</Text>
                                                                                                            :
                                                                                                            Emp.status == "Link Expired" ?
                                                                                                                <Text defaultmediumText className={classes.linkInactive}>Re - Invite</Text>
                                                                                                                :
                                                                                                                <Text defaultmediumText className={classes.linkInactive}>
                                                                                                                    {Emp.status == "Offer Rejected" ? "" : ""}
                                                                                                                </Text>
                                                                                                }
                                                                                            </Grid>

                                                                                    }
                                                                                </Grid>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                {
                                                                                    selectedOption == "All employees" ?
                                                                                        <Box className={classes.AccordionDetails} pt={1}>
                                                                                            <Box width={'100%'}>
                                                                                                <Typography className={classes.text1}>
                                                                                                    Employee Category
                                                                                                </Typography>
                                                                                                <Typography my={1} className={classes.text2}>
                                                                                                    {Emp.employee_category ? Emp.employee_category : "--"}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                            <Box width={'100%'}>
                                                                                                <Typography className={classes.text1}>
                                                                                                    Sub-Status
                                                                                                </Typography>
                                                                                                <Typography my={1} className={classes.text2}>
                                                                                                    {Emp.sub_status === "" ? "--" : Emp.sub_status}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                            <Box width={'100%'}>
                                                                                                <Typography className={classes.text1}>
                                                                                                    Email ID
                                                                                                </Typography>

                                                                                                <Typography my={1} className={classes.text2}>
                                                                                                    {
                                                                                                        Emp.email_id ? Emp.email_id.length > 16 ?
                                                                                                            <BlackToolTip title={Emp.email_id} placement="top" arrow>
                                                                                                                {(Emp.email_id.slice(0, 16)) + (Emp.email_id.length > 16 ? "..." : "")}
                                                                                                            </BlackToolTip>
                                                                                                            : Emp.email_id : '--'
                                                                                                    }
                                                                                                </Typography>
                                                                                            </Box>
                                                                                            <Box width={'100%'} >
                                                                                                <Typography className={classes.text1}>
                                                                                                    Phone No
                                                                                                </Typography>

                                                                                                <Typography my={1} className={classes.text2}>
                                                                                                    {Emp.contact_number === "" ? "" : Emp.contact_number}
                                                                                                </Typography>
                                                                                            </Box>

                                                                                        </Box> :
                                                                                        <Box className={classes.AccordionDetails} pt={1}>
                                                                                            <Grid container>

                                                                                                <Grid item lg={2} textAlign={"center"}>
                                                                                                    <Typography className={classes.selftext1}>
                                                                                                        Category
                                                                                                    </Typography>
                                                                                                    <Typography my={1} className={classes.selftext2}>
                                                                                                        {Emp.employee_category_name ? Emp.employee_category_name : "--"}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item lg={2}></Grid>
                                                                                                <Grid item lg={3} textAlign={"center"}>
                                                                                                    <Typography className={classes.selftext1}>
                                                                                                        Email ID
                                                                                                    </Typography>
                                                                                                    <Typography my={1} className={classes.selftext2}>
                                                                                                        {
                                                                                                            Emp.email_id ? Emp.email_id.length > 16 ?
                                                                                                                <BlackToolTip title={Emp.email_id} placement="top" arrow>
                                                                                                                    {(Emp.email_id.slice(0, 16)) + (Emp.email_id.length > 16 ? "..." : "")}
                                                                                                                </BlackToolTip>
                                                                                                                : Emp.email_id : '--'
                                                                                                        }
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item lg={3}></Grid>
                                                                                                <Grid item lg={2} textAlign={"center"}>
                                                                                                    <Typography className={classes.selftext1}>
                                                                                                        Phone No
                                                                                                    </Typography>
                                                                                                    <Typography my={1} className={classes.selftext2}>
                                                                                                        {Emp.mobile_number ? Emp.mobile_number : "--"}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                        </Box>
                                                                                }

                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    ))
                                                                }
                                                            </>

                                                        </Box>
                                                        : employees.length == 0 ?
                                                            <Box>
                                                                {NoDataFound("To fetch the data. Please add employee", "No Data Found")}
                                                            </Box>
                                                            : ''
                                                }
                                            </>
                                    }
                                </>
                            </Box>
                        </Box>
                        :
                        <Grid container>
                            <Text mediumViewAmt ml={4}>Employees</Text>
                            {NoPermission()}
                        </Grid>
                }
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openBacKLoader}
                >
                    <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                </Backdrop>
            </>}
            {showUserProfile == 'employeeprofile' &&
                <Box sx={{ width: "100%" }} px={5} py={1}>
                    <Box mx={2} pl={3}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography c className={classesUserProfile.breadcrumbsLink}>Employees</Typography>
                            <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>User Profile</Text>
                        </Breadcrumbs>
                    </Box>

                    {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) ?
                        <Box my={2} mx={2}>
                            <Grid container lg={12} columnSpacing={4}>
                                <Grid item container lg={3.2} md={3} sm={3} xs={12}>
                                    <Box style={{ width: "100%", height: "78.5vh", borderRadius: "8px" }}>
                                        <Box px={3} py={1} pt={4}>
                                            <Box my={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                                <CustomCircularProgress

                                                    color={'#ffffff'}
                                                    src={employee.profile_picture_url}
                                                    imgWidth='80px !important'
                                                    imgHeight='80px !important'
                                                    avatarWidth='80px !important'
                                                    avatarHeight='80px !important'
                                                    value={100}
                                                    size={88}

                                                    tooltip

                                                />
                                            </Box>
                                            <Grid item container lg={12} pt={1} spacing={0} alignItems='center'>
                                                <Grid item lg={12} >
                                                    <Box display='flex' flexDirection='row' gap={1} justifyContent='center' sx={{ width: '100%' }}>

                                                        <Text className={classesUserProfile.profileName} >
                                                            ABCD
                                                        </Text>

                                                    </Box>
                                                    {/* } */}
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={12} pt={0}>
                                                <Text className={classesUserProfile.profileId}>{employee.employment_details.reference_id}</Text>
                                            </Grid>
                                            <Grid item lg={12} xs={12} pt={0} p={1} textAlign='center' justifyContent='center'>
                                                <Box sx={{ cursor: 'pointer', width: '100%', display: 'flex', justifyContent: 'center' }} className={classesUserProfile.uploadBtn}   >
                                                    <Box sx={{ width: '60%', padding: '5px' }} id='activitylog'>

                                                        Activity log
                                                    </Box>
                                                </Box>

                                            </Grid>
                                            <Grid item lg={12}>
                                                <Stack my={1} direction="row" justifyContent={"center"} spacing={2}>
                                                    <Box id='everifybutton' sx={{ padding: '5px' }}>
                                                        <Button className={classesUserProfile.eVerifyBtn} sx={{ border: '1px solid #0C75EB !important', backgroundColor: "#ffffff !important", color: '#ffffff' }} >
                                                            <Stack direction='row' gap={1} sx={{ color: (true ? "#0C75EB  !important" : employee.e_verified == 1 ? "#ffffff !important" : employee.e_verified == 2 ? "#ffffff !important" : employee.e_verified == 3 ? "#ffffff !important" : '') }}>
                                                                E-Verify </Stack>
                                                        </Button>
                                                    </Box>
                                                    {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true)))&&
                                                    <Box sx={{ padding: '5px' }}>

                                                        <Button variant="outlined" className={classesUserProfile.moreBtn} id="threedots" onClick={handleClick} >
                                                            <MoreHorizIcon sx={{ fontSize: "18px", color: true ? `#9D9E9F` : "#9D9E9F" }} />
                                                            <Menu
                                                                anchorEl={anchorEl}
                                                                open={open}
                                                                onClose={handleClose}
                                                                id="basic-menu"
                                                                sx={{
                                                                    '& .MuiPaper-root': {
                                                                        border: '1px solid #EAECF0 !important',
                                                                        width: '150px !important',
                                                                        boxShadow: "#0000001F !important",
                                                                        borderRadius: '8px !important',
                                                                        padding: '0px 2px 0px 0px !important'
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem id='disableuser'
                                                                    className={classesUserProfile.inactiveDisable}
                                                                >
                                                                    <Text smallBlackBold>Disable User Access</Text>
                                                                </MenuItem>
                                                                <MenuItem id='offboardemployee'
                                                                    className={classesUserProfile.inactiveDisable}
                                                                >
                                                                    <Text smallBlackBold>Offboard Employee</Text>
                                                                </MenuItem>
                                                            </Menu>
                                                        </Button>
                                                    </Box>}
                                                </Stack>
                                            </Grid>
                                        </Box>
                                        <Box px={3}>
                                            <Divider />
                                        </Box>
                                        <Box >
                                            <Box sx={{
                                                height: "35vh",
                                                overflow: "auto",
                                                width: '100%',
                                                '&::-webkit-scrollbar': {
                                                    width: '4px',
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                    '-webkit-box-shadow': 'inset 0 0 6px #ffffff',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                    backgroundColor: '#C7CCD3',
                                                    outline: '1px solid #C7CCD3',
                                                    borderRadius: "4px",
                                                },
                                                "@media (min-height: 750px)": {
                                                    height: '36vh'
                                                }
                                            }}>
                                                {
                                                    dataArr.map((item, key) => (
                                                        <Box
                                                            id={item.replace(' ', '')}

                                                            sx={{ padding: '5px', width: '100%', display: 'flex ', flexDirection: "column", justifyContent: 'center', alignItems: 'center', }} >
                                                            <ListItemButton
                                                                key={key}
                                                                sx={{ width: '90% ' }}
                                                                className={`${classesUserProfile.listItems} ${current === item ? classesUserProfile.listItemsActive : null}`}
                                                                onClick={() => setCurrent(item)}>
                                                                <Grid container>
                                                                    <Grid item xs={10}>{item}</Grid>

                                                                </Grid>
                                                            </ListItemButton>
                                                        </Box>

                                                    ))}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item lg={.1}></Grid>
                                <Grid item lg={8.4} md={8.4} sm={8} xs={12}>
                                    {
                                        true ? '' : <Grid item pb={2}>
                                            <Box display='flex' flexDirection='row' gap={1.5} sx={{ height: '60px', width: '100%', borderRadius: '8px', background: '#FDECCE', textAlign: 'center', alignItems: 'center', paddingLeft: '20px' }}>
                                                <Box pt={'4px'}>
                                                    <img src={warning} alt='warning' />
                                                </Box>
                                                <Box>
                                                    <Text mediumOrangeRehire> This employee has been re-hired recently, please re-confirm all the details </Text>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    }
                                    <Box sx={{ width: '100%', height: true ? "78.5vh" : "70vh", borderRadius: "8px", overflow: 'auto', overflowX: 'hidden' }}>
                                        <Box>
                                            <Box m={0.5} mr={0.1} mb={0} sx={{ width: '100% !important', overflowX: 'hidden' }}>
                                                <Box sx={{ height: '70vh', width: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                    <Box m={.5} mr={0.1} sx={{ width: '100% !important' }}>  <Grid container p={1} pr={0} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", }}>
                                                        <Grid container>
                                                            <Grid item lg={11} md={11} sm={11} xs={11}>
                                                                <Box p={1} pl={2} pt={2}>
                                                                    <Text largeBlue>
                                                                        {current}
                                                                    </Text>
                                                                </Box>
                                                            </Grid>
                                                            <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                                                <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                                                    <img src={noDataFound} alt='nodata' />
                                                                </Grid>
                                                                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                                    <Text largeBlack>No Data Found</Text>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Box height={'5vh'} />
                                                            </Grid>
                                                            <Backdrop
                                                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                                                open={openBacKLoader}
                                                            >
                                                                <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                                                            </Backdrop>
                                                        </Grid>
                                                    </Grid>  </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        :
                        <>
                            {NoPermission()}
                        </>
                    }
                </Box >}

        </>
    );
}