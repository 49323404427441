import { Avatar, Box, Breadcrumbs, CircularProgress, Grid, Menu, MenuItem, Stack, Divider, Skeleton, Backdrop } from '@mui/material'
import React, { useRef } from 'react'
import Button from '../../../components/customButton/Button'
import { useNavigate } from 'react-router-dom';
import LayoutStyles from '../DasboardStyles';
import Search from '../../../assets/client/greySearch.svg';
import { useState } from 'react';
import { BlackToolTip, NoDataFound, addErrorMsg, NoPermission, addLoader, capitalizeAndAddSpace, removeLoader, NotApplicable } from '../../../utils/utils';
import Text from '../../../components/customText/Text';
import EmployeeCreateAPI from '../../../apis/admin/employees/EmployeesApi';
import { useEffect } from 'react';
import ReusablePopup from '../../../components/reuablePopup/ReusablePopup';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import arrowDown from '../../../assets/svg/clients/blackdownArrow.svg';
import arrowUp from '../../../assets/svg/clients/blackarrowUp.svg';
import Loader from '../../../assets/gif/japfuLoader.gif';
import greenUp from '../../../assets/svg/clients/greenupArrow.svg'
import greendown from '../../../assets/svg/clients/greendownArrow.svg'
import LocalStorage from '../../../utils/LocalStorage';

function PlacementInfo() {
    const navigate = useNavigate();
    const classes = LayoutStyles();
    const [placements, setPlacements] = useState([]);
    const [searchName, setSearchName] = useState('');
    const [openConfigure, setOpenConfigure] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({});
    const [rowData, setRowData] = useState({});
    const [searchImg, setSearchImg] = useState(true);
    const [openStatusArrow, setOpenStatusArrow] = useState(false);
    const scrollBox = useRef(null);
    const [loader, setLoader] = useState(false);
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    const [filterData, setFilterData] = useState({
        limit: 6,
        page: 1,
        search: searchName,
        employment: [],
        category: [],
        status: ['Active'],
        visa: [],
        e_verified: ''
    })

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_create" && item.is_allowed == true))) {
            getPlacements(filterData);
        }
        // eslint-disable-next-line   
    }, [searchName])

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(pagination.total) >= filterData.limit) {
                    loadMore();
                } else {
                    setLoader(false);
                }
            }
        }
    };

    const loadMore = () => {
        addLoader(true);
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
        getPlacements(filterData);
    }

    const handleStatusOpen = (e) => {
        setOpenStatusArrow(e.currentTarget);
    }

    const handleSearch = (e) => {
        // setIsComponentVisible(false)
        setSearchName(e.target.value);
        setFilterData({ ...filterData, limit: 4, page: 1, search: e.target.value });
        if (e.target.value.length > 0) {
            setSearchImg(false);
        } else {
            setSearchImg(true);
        }
    }

    const getPlacements = (args) => {
        if (placements.length < 6) {
            setLoading(true);
        }
        EmployeeCreateAPI.getAllEmployees(args).then((response) => {
            setTimeout(() => {
                setLoading(false)
                removeLoader();
                if (response.data.statusCode == 1003) {
                    setPlacements(response.data.data);
                    setPagination(response.data.pagination);
                } else {
                    addErrorMsg(response.data.message);
                }
            }, 400)
        })
    }

    const addPlacement = (args) => {
        setRowData(args);
        navigate('/placements/addclientAndendclient', { state: { empData: args, actionState: 'viewPlacement' } })
    }
    // eslint-disable-next-line
    const everifyEmployee = (args) => {
        LocalStorage.removeRedirectedModule();
        LocalStorage.removeSubModule();
        LocalStorage.removeNotificationEmpId();
        navigate(`/employees/user-profile/${args.full_name === "" ? "" : args.full_name.trim().split(/ +/).join('-')}`, { state: { id: args.id } })
    }

    const closeBtn = () => {
        setSearchName('');
        setSearchImg(true);
        setFilterData({ ...filterData, search: "" })
        EmployeeCreateAPI.getAllEmployees({ ...filterData, search: "" }, { ...pagination, currentPage: 1, limit: 5 }).then((res) => {
            setPlacements(res.data.data);
            setPagination(res.data.pagination);
        })
    }

    const handleStatus = (args) => {
        filterData.e_verified = args
        setFilterData({ ...filterData })
        getPlacements(filterData);
        setOpenStatusArrow(null);
    }

    return (
        <Grid container lg={12} md={12} sm={12} xs={12} pl={15} justifyContent='center'>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Text mediumGrey sx={{ cursor: 'pointer' }} onClick={() => navigate('/placements')}>All Placements</Text>
                    <Text mediumBlack>Select Employee</Text>
                </Breadcrumbs>
            </Grid>
            {
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_create" && item.is_allowed == true))) ?
                    <Grid item lg={8} md={8} sm={8} xs={8} mt={5}>
                        <div style={{ height: "40px", width: '100%', border: "1.5px solid #EEEEEE", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                            <input
                                type="text"
                                name='searchName'
                                value={searchName}
                                className={classes.EmployeesSearchInput}
                                onChange={handleSearch}
                                // onClick={() => { local.length > 0 && setIsComponentVisible(false) }}
                                placeholder="Search Employee"
                            />
                            <button
                                type="button"
                                style={{ all: "unset", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", textAlign: "center", fontSize: "15px", fontFamily: "Quicksand", width: "45px", height: "38px", border: "none", backgroundColor: "#FFFFFF", borderRadius: "6px", }}
                            >
                                {searchImg ? <img src={Search} alt="Search" /> : <CloseRoundedIcon sx={{ cursor: "pointer" }} onClick={closeBtn} />}
                            </button>
                        </div>
                    </Grid>
                    : ""}
            {
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_create" && item.is_allowed == true))) ?
                    <Grid item lg={11} md={11} sm={12} xs={12} textAlign='end' mt={2}>
                        <Grid item container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center' sx={{ padding: "15px 12px 5px 12px" }} mb={2}>
                            <Grid item lg={5} md={6} sm={6} xs={4} textAlign={'left'} display='flex' flexDirection='row' alignItems='center'>
                                <Text mediumBoldBlack2>Employee Name</Text>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2}>
                                <Text mediumBoldBlack2>Visa Type</Text>
                            </Grid>
                            <Grid item container lg={2} md={2} sm={2} xs={2} justifyContent='center'>
                                <Grid aria-controls="status-menu" item lg={6} onClick={handleStatusOpen} className={classes.statusGrid}>
                                    <Text mediumBoldBlack2 sx={{ paddingRight: '5px' }}>Status</Text>
                                    {
                                        openStatusArrow ?
                                            filterData.e_verified === '' ?
                                                <img src={arrowUp} alt='up' className={classes.cursor} /> : <img src={greenUp} alt='up' className={classes.cursor} />
                                            :
                                            filterData.e_verified === '' ?
                                                <img src={arrowDown} alt='down' className={classes.cursor} /> : <img src={greendown} alt='down' className={classes.cursor} />
                                    }
                                </Grid>
                                <Menu
                                    anchorEl={openStatusArrow}
                                    id="status-menu"
                                    open={openStatusArrow}
                                    onClose={() => { setOpenStatusArrow(null) }}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: "visible",
                                            boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                                            mt: 1,
                                            width: '200px'
                                        }
                                    }}
                                    transformOrigin={{ horizontal: "center", vertical: "top" }}
                                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                                >
                                    <MenuItem className={filterData.e_verified === '' ? classes.activeMenuItem : classes.menuItem} onClick={() => handleStatus('')}><Text smallBlack>All</Text></MenuItem> <Divider className={classes.divider} />
                                    <MenuItem className={filterData.e_verified === 1 ? classes.activeMenuItem : classes.menuItem} onClick={() => handleStatus(1)}> <Text smallBlack>E-Verified</Text></MenuItem> <Divider className={classes.divider} />
                                    <MenuItem className={filterData.e_verified === 0 ? classes.activeMenuItem : classes.menuItem} onClick={() => handleStatus(0)}> <Text smallBlack>Not E-Verified</Text></MenuItem>
                                </Menu>
                            </Grid>
                            <Grid item lg={3} md={2} sm={2} xs={2}>
                                <Text mediumBoldBlack2>Action</Text>
                            </Grid>
                        </Grid>
                        {

                            loading ?
                                [1, 2, 3].map((item) => (
                                    <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                        <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                            <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <Box>
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                </Box>
                                                <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                            </Box>
                                        </Box>
                                    </Stack>
                                )) :
                                <Grid container className={classes.scrollContent} ref={scrollBox} onScroll={handleScroll}>
                                    {
                                        placements.length > 0 && placements.map((item, index) => (
                                            <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} alignItems='center' className={classes.newCustomAccordion}>
                                                <Grid item container lg={5} md={6} sm={5} xs={5} alignItems='center'>
                                                    <Grid item xl={2} lg={2.5} md={3} sm={2} xs={12}>
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            <Box sx={{ position: 'relative', display: 'flex' }}>
                                                                <CircularProgress variant="determinate" value={item.profile_progress} size={60} thickness={1} sx={{ backgroundColor: "#F2F2F2", color: item.profile_progress == 100 ? 'green' : item.profile_progress <= 99 && item.profile_progress >= 40 ? 'orange' : item.profile_progress < 40 ? "red" : "", borderRadius: "100%" }} />
                                                                <Box
                                                                    sx={{
                                                                        top: 0,
                                                                        left: 0,
                                                                        bottom: 0,
                                                                        right: 0,
                                                                        position: 'absolute',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                    }}
                                                                >
                                                                    <Box sx={{ display: "flex", padding: "3px", borderRadius: "50%", backgroundColor: "#ffffff" }}>
                                                                        <BlackToolTip arrow title={
                                                                            <Text mediumWhite sx={{ padding: '6px !important' }}> {`Profile Completion - ${item.profile_progress}%`}</Text>
                                                                        }>
                                                                            <Avatar
                                                                                alt="Remy Sharp"
                                                                                src={item.avatar == '' ? item.full_name[0] : item.profile_picture_url}
                                                                                sx={{ width: 50, height: 50 }}
                                                                            />
                                                                        </BlackToolTip>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item container lg={9} md={9} sm={10} xs={12} spacing={2} alignItems='center'>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign='start'>
                                                            <Text mediumBoldBlack sx={{ cursor: 'pointer !important' }} onClick={() => {
                                                                LocalStorage.removeRedirectedModule();
                                                                LocalStorage.removeSubModule();
                                                                LocalStorage.removeNotificationEmpId();
                                                                navigate(`/employees/user-profile/${item.full_name}`, {
                                                                    state: {
                                                                        full_name: item.full_name, reference_id: item.reference_id, avatar_url: item.avatar_url, enable_login: item.enable_login, id: item.id, from: 'employmentDashboard'
                                                                    }
                                                                })
                                                            }}>
                                                                {item.full_name === "" ? "--" : capitalizeAndAddSpace(item.full_name).length > 20 ?
                                                                    <BlackToolTip title={capitalizeAndAddSpace(item.full_name)} placement="right" arrow>
                                                                        {capitalizeAndAddSpace(item.full_name).slice(0, 20) + (capitalizeAndAddSpace(item.full_name).length > 20 ? "..." : "")}
                                                                    </BlackToolTip>
                                                                    :
                                                                    capitalizeAndAddSpace(item.full_name)
                                                                }
                                                            </Text>
                                                            <Text mediumLabel pt={'5px'} noWrap>
                                                                {item.reference_id === "" ? "-" : item.reference_id}
                                                            </Text>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={2} textAlign='center'>
                                                    <Text mediumBoldBlack2>{item.visa_type ? item.visa_type : <>
                                                        {NotApplicable()}
                                                    </>}</Text>
                                                </Grid>
                                                <Grid item container lg={4.5} md={4} sm={4} xs={12} justifyContent='space-around' display='flex' flexDirection='row' alignItems='center'>
                                                    <Divider orientation="vertical" flexItem style={{ height: '50px' }} />
                                                    <Box className={item.e_verified == 1 ? classes.verified : classes.notVerified}>
                                                        {item.e_verified == 1 ? 'E-Verified' : 'Not E-Verified'}
                                                    </Box>
                                                    <Grid item lg={5} md={5} sm={5} xs={12}>
                                                        {
                                                            // item.employment_type == 'Consultant' && item.e_verified !== 1 ?
                                                            //     <Button EditBlue onClick={() => everifyEmployee(item)}>E-Verify</Button> :
                                                            <Button EditBlue onClick={() => addPlacement(item)}>Add</Button>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        ))
                                    }
                                </Grid>
                        }
                        {
                            placements.length == 0 && !loading &&
                            <Box justifyContent='center' width='100%'>
                                {NoDataFound(`To fetch the data, Please add employee`, "No Data Found")}
                            </Box>
                        }
                        {/* {
                    !loading &&
                        pagination.totalPages ? pagination.currentPage < pagination.totalPages ?
                        <Box sx={{ textAlign: "center", padding: "10px" }} mt={5}>
                            <Button outlineBlue onClick={() => loading ? null : loadeMore()}> {loading ? "Loading..." : "Load more"}</Button>
                        </Box> : null : null
                } */}
                    </Grid>
                    :
                    <>
                        {NoPermission()}
                    </>
            }

            <ReusablePopup openPopup={openConfigure} setOpenPopup={setOpenConfigure} iconHide white fixedWidth>
                <Box p={'10px 0px'} textAlign='center'>
                    <Text blackFont14 noWrap>Please Configure payroll to add New Placement</Text>
                    <Stack display='flex' flexDirection='row' gap={2} justifyContent='center' pt={5}>
                        <Button popupSaveBlue onClick={() => {
                            LocalStorage.removeRedirectedModule();
                            LocalStorage.removeSubModule();
                            LocalStorage.removeNotificationEmpId();
                            navigate(`/employees/user-profile/${rowData.full_name}`,
                                { state: { id: rowData.id, full_name: rowData.full_name, e_verify: rowData.e_verified, reference_id: rowData.reference_id, avatar_url: rowData.avatar, enable_login: '', from: 'employmentDashboard' } })
                        }}
                        >Yes</Button>
                        <Button popupCancel onClick={() => setOpenConfigure(false)}>No</Button>
                    </Stack>
                </Box>
                {/* <PayrollUpdate setOpen={setOpenConfigure} id={rowData.id} /> */}
            </ReusablePopup>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <img src={Loader} alt="Loader" width={'60px'} height={'60px'} />
            </Backdrop>
        </Grid >
    )
}

export default PlacementInfo

