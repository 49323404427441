import React, { useState } from 'react'
import MainStyles from '../MainStyles';
import PayrollSetup from './PayrollSetup';
import { Box, Grid, ListItemButton } from '@mui/material';
import Text from '../../../../components/customText/Text';
import Reimbursements from './Reimbursements';
import Deductions from './Deductions';

const prefernces = ['Payroll Setup', 'Reimbursements', 'Deductions'];
function PayrollConfig() {

  const classes = MainStyles()
  const [current, setCurrent] = useState(0);

  return (
    <Box display={'flex'} justifyContent={'center'} width={'100%'}>
      <Box padding={'20px 0px 0px 0px'} width={{ sm: '95%', md: '95%', lg: '85%' }}>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Box className={classes.mainListBox} >
              <Box className={classes.prefTitle}>
                <Text blackHeader600>Preference</Text>
              </Box>
              <Box className={classes.listContainer} sx={{ maxHeight: '58vh' }}>
                {
                  prefernces.map((item, key) => (
                    <ListItemButton
                      key={key}
                      className={`${classes.listItems} ${current === key ? classes.listItemsActive : null}`}
                      onClick={() => setCurrent(key)}
                      id={key}
                    >
                      {item}
                    </ListItemButton>
                  ))
                }
              </Box>
            </Box>
          </Grid>
          <Grid item lg={8} md={8} sm={8} xs={12}>
            {
              current === 0 ? <PayrollSetup /> : current === 1 ? <Reimbursements /> : current === 2 ? <Deductions /> : null
            }
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default PayrollConfig
