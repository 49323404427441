import { Box, Divider, Grid, InputBase, Paper, Skeleton, Stack } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Text from '../../../../components/customText/Text';
import CustomSelect from '../../../../components/customSelect/CustomSelect';
import clients from '../../../../assets/client/clients.svg';
import arrow from '../../../../assets/svg/clients/right-arrowblack.svg';
import placements from '../../../../assets/svg/clients/placements-activity.svg';
import LocalStorage from '../../../../utils/LocalStorage';
import Search from '../../../../assets/svg/search1.svg';
import AnalyticStyles from './AnalyticStyles';
import cloud from '../../../../assets/svg/download-cloud-new.svg';
import CircularProgress from '../../../../components/progressbar/CircularProgress';
import Verified from '../../../../assets/svg/Component87.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { BlackToolTip, ListLoadingIcon, NoDataFound, NoPermission, addErrorMsg, addLoader, capitalizeAndAddSpace, removeLoader } from '../../../../utils/utils';
import AnalyticsApi from '../../../../apis/admin/timesheets/AnalyticsApiTimesheet';
import FileSaver from 'file-saver';
import blueinfo from '../../../../assets/svg/placement/blueinfo.svg';
import CommonApi from '../../../../apis/CommonApi';
import Pending from '../../../../assets/employee/Orange-PendingIcon.svg';

function Analytics() {
    const classes = AnalyticStyles();
    const messagesEndRef = useRef(null);
    const quarterList = require('../../../../utils/jsons/QuarterList.json');

    const currency = LocalStorage.getCurrencySymbol();
    const navigate = useNavigate();
    const location = useLocation();
    const checkactive = location && location.state && location.state.activeState
    const [loading, setLoading] = useState(false);
    const [sidebarLoader, setSidebarLoader] = useState(false);

    const [state, setState] = useState({
        selectedYear: '2024',
        selectQuarter: 'All',
        searchClientName: '',
        searchPlacementName: ''
    })

    const [count, setCount] = useState({
        margin: '',
        total_billed: '',
        total_clients: '',
        total_hours_logged: '',
        total_pay: '',
        total_placements: ''
    })
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    const changeHandler = (e) => {
        state[e.target.name] = e.target.value
        setState({ ...state });
        setLoading(true);
        clientAnalytics(state.selectedYear, state.selectQuarter == "All" ? "" : state.selectQuarter, e.target.value, filter.limit);
        placementAnalytics(state.selectedYear, state.selectQuarter == "All" ? "" : state.selectQuarter, e.target.value, placementfilter.limit);
        countDetails();
    }

    const [active, setActive] = useState('clients');
    const [clientsRows, setClientRows] = useState([]);
    const [placementRows, setPlacementRows] = useState([]);
    const scrollBox = useRef(null);
    const [pagination, setPagination] = useState(
        {
            total: "",
            currentPage: 0,
            perPage: 6,
            totalPages: ""
        }
    );

    const [placementPagination, setPlacementPagination] = useState(
        {
            total: "",
            currentPage: 0,
            perPage: 6,
            totalPages: ""
        }
    );
    const [years, setYears] = useState([]);

    const [filter, setFilter] = useState({
        request_id: LocalStorage.uid(),
        limit: 6,
        page: 1
    })

    const [placementfilter, setPlacementFilter] = useState({
        request_id: LocalStorage.uid(),
        search: "",
        limit: 6,
        page: 1,
        status: ""
    })
    const revertDirections = LocalStorage.getAnalyticsRoute();
    const [listLoading, setListLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        countDetails();
        defaultYears();
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) {
            clientAnalytics(state.selectedYear, state.selectQuarter == "All" ? "" : state.selectQuarter, '', filter.limit);
        }
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true))) {
            placementAnalytics(state.selectedYear, state.selectQuarter == "All" ? "" : state.selectQuarter, '', placementfilter.limit);
        }
        if (checkactive == 'client' || revertDirections == 'client') {
            setActive('clients')
        } else if (checkactive == 'placement' || revertDirections == 'placement') {
            setActive('placements')
        } else {
            setActive('clients')
        }// eslint-disable-next-line
    }, [])

    const defaultYears = () => {
        CommonApi.defaultYearsList().then((res) => {
            if (res.data.statusCode == 1003) {
                setYears(res.data.data);
            }
        })
    }

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (active == 'clients') {
                    if (Number(pagination.total) >= filter.limit) {
                        loadMore();
                    }
                } else if (active == 'placements') {
                    if (Number(placementPagination.total) >= placementfilter.limit) {
                        loadMore();
                    }
                }
            }
        }
    };

    const loadMore = () => {
        if (active == "clients") {
            // addLoader(true);
            setListLoading(true);
            filter.limit = Number(filter.limit) + 6;
            setFilter({ ...filter });
            clientAnalytics(state.selectedYear, state.selectQuarter == "All" ? "" : state.selectQuarter, '', filter.limit);
        } else if (active == 'placements') {
            // addLoader(true);
            setListLoading(true);
            placementfilter.limit = Number(placementfilter.limit) + 6;
            setPlacementFilter({ ...placementfilter });
            placementAnalytics(state.selectedYear, state.selectQuarter == "All" ? "" : state.selectQuarter, '', placementfilter.limit);
        }
    }

    const countDetails = () => {
        setSidebarLoader(true);
        AnalyticsApi.getAnalytics(state.selectedYear, state.selectQuarter == "All" ? "" : state.selectQuarter).then((res) => {
            if (res.data.statusCode == 1003) {
                setSidebarLoader(false);
                setCount(res.data.data);
            } else {
                setSidebarLoader(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const clientAnalytics = () => {
        if (clientsRows.length < 6) {
            setLoading(true);
        }
        AnalyticsApi.getClientAnalytics(state.selectedYear, state.selectQuarter == "All" ? "" : state.selectQuarter, state.searchClientName, filter.limit).then((res) => {
            // removeLoader();
            setListLoading(false);
            if (res.data.statusCode == 1003) {
                setLoading(false);
                setClientRows(res.data.data);
                setPagination(res.data.pagination);
                setFilter({
                    ...res.data.pagination,
                    limit: res.data.pagination.perPage,
                    page: res.data.pagination.currentPage
                });
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const placementAnalytics = () => {
        if (placementRows.length < 6) {
            setLoading(true);
        }
        AnalyticsApi.getPlacementAnalytics(state.selectedYear, state.selectQuarter == "All" ? "" : state.selectQuarter, state.searchPlacementName, placementfilter.limit, 1).then((res) => {
            if (res.data.statusCode == 1003) {
                setLoading(false);
                // removeLoader();
                setListLoading(false);
                setPlacementRows(res.data.data);
                setPlacementPagination(res.data.pagination);
                setPlacementFilter({
                    ...res.data.pagination,
                    limit: res.data.pagination.perPage,
                    page: res.data.pagination.currentPage
                });
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleTab = (args) => {
        setActive(args);
        state['searchClientName'] = ''
        state['searchPlacementName'] = ''
        setState({ ...state })
        if (args == 'clients') {
            filter.limit = 6
            setFilter({ ...filter })
            clientAnalytics(state.selectedYear, state.selectQuarter == "All" ? "" : state.selectQuarter, state.searchClientName, filter.limit);
        } else {
            placementfilter.limit = 6
            setPlacementFilter({ ...placementfilter })
            placementAnalytics(state.selectedYear, state.selectQuarter == "All" ? "" : state.selectQuarter, state.searchPlacementName, placementfilter.limit);
        }
    }

    const downloadExport = () => {
        addLoader(true);
        AnalyticsApi.exportApi(active == 'clients' ? 'client' : 'placement').then((res) => {
            if (res.data.statusCode == 1003) {
                removeLoader();
                FileSaver.saveAs(res.data.data[0].file_path);
            } else {
                removeLoader();
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleAccordian = (args) => {
        if (active === 'clients') {
            navigate('/client-analytics', { state: { data: args, dropdown: state } })
        } else {
            navigate('/placement-analytics', { state: { data: args, dropdown: state } })
        }
    }

    const redirections = () => {
        if (revertDirections == 'client') {
            navigate('/clients')
        } else if (revertDirections == 'placement') {
            navigate('/placements')
        } else if (revertDirections == 'dashboard') {
            navigate('/dashboard');
        }
    }

    return (
        <Grid container className={classes.paddingLeft} pt={2}>
            <Grid item lg={12} xs={12} display='flex' flexDirection='row' gap={1} alignItems='center'>
                <ArrowBackIcon sx={{ cursor: "pointer" }} onClick={redirections} />
                <Text boldblack600 pl={1}>Analytics Home</Text>
            </Grid>
            {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => (item.slug == "placement_view" || item.slug == "client_view") && item.is_allowed == true))) ?
                <Grid item container lg={12} xs={12} mt={4}>
                    <Grid item lg={3} xs={3} container spacing={2} sx={{
                        maxHeight: '75vh',
                        overflowY: 'scroll',
                        paddingBottom: '10px',
                        borderRight: '1px solid #F3EFEF'
                    }}>
                        <Grid item container spacing={2} lg={12} xs={12}>
                            <Grid item lg={4} xs={12}>
                                {
                                    sidebarLoader ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} /> :
                                        <CustomSelect
                                            sx={{
                                                '.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.MuiSelect-select': {
                                                    // 'min-height': '2em',
                                                    'minWidth': 'auto'
                                                }
                                            }}
                                            viewDrop
                                            scrollTrue={true}
                                            name='selectedYear'
                                            options={years}
                                            value={state.selectedYear}
                                            onChange={changeHandler}
                                        />
                                }
                            </Grid>
                            <Grid item lg={5} xs={12}>
                                {
                                    sidebarLoader ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} /> :
                                        <CustomSelect
                                            sx={{
                                                '.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.MuiSelect-select': {
                                                    // 'min-height': '2em',
                                                    'minWidth': 'auto'
                                                }
                                            }}
                                            viewDrop
                                            scrollTrue={true}
                                            name='selectQuarter'
                                            options={quarterList}
                                            value={state.selectQuarter}
                                            onChange={changeHandler}
                                        />
                                }
                            </Grid>
                        </Grid>
                        <Grid item lg={10} xs={12} alignItems='center'>
                            {
                                sidebarLoader ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} /> :
                                    <Box alignItems='center' sx={{
                                        background: '#E7F2FD',
                                        borderRadius: '12px',
                                        padding: '15px 12px',
                                        border: active == 'clients' ? '2px solid #0C75EB' : '2px solid transparent',
                                        cursor: 'pointer'
                                    }}>
                                        <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4} onClick={() => handleTab('clients')}>
                                            <Grid item lg={2} md={2} xs={2}>
                                                <img src={clients} alt='clients' />
                                            </Grid>
                                            <Grid item lg={7} md={6} xs={6} ml={2}>
                                                <Text mediumBoldBlack>Total Clients</Text>
                                                <Text boldblack22>{count.total_clients}</Text>
                                            </Grid>
                                            <Grid item lg={1} md={1} xs={1}>
                                                <img src={arrow} alt='arrow' />
                                            </Grid>
                                        </Grid>
                                    </Box>
                            }
                        </Grid>
                        <Grid item lg={10} alignItems='center'>
                            {
                                sidebarLoader ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} /> :
                                    <Box alignItems='center' sx={{
                                        background: '#E7E0FE',
                                        borderRadius: '12px',
                                        padding: '15px 12px',
                                        border: active == 'placements' ? '2px solid #8763FC' : '2px solid transparent',
                                        cursor: 'pointer'
                                    }}>
                                        <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4} onClick={() => handleTab('placements')}>
                                            <Grid item lg={2} md={2} xs={2}>
                                                <img src={placements} alt='clients' />
                                            </Grid>
                                            <Grid item lg={7} md={6} xs={6} ml={2}>
                                                <Text mediumBoldBlack>Total Placements</Text>
                                                <Text boldblack22>{count.total_placements}</Text>
                                            </Grid>
                                            <Grid item lg={1} md={1} xs={1}>
                                                <img src={arrow} alt='arrow' />
                                            </Grid>
                                        </Grid>
                                    </Box>
                            }
                        </Grid>
                        <Grid item lg={10} xs={12} alignItems='center'>
                            {
                                sidebarLoader ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} /> :
                                    <Box alignItems='center' sx={{
                                        background: '#FAFAFA',
                                        borderRadius: '12px',
                                        padding: '12px',
                                    }}>
                                        <Grid item lg={12} xs={12} alignItems='center' pl={1}>
                                            <Text mediumBoldBlack>Total Hours Logged</Text>
                                            <Text boldblack22>{count.total_hours_logged || count.total_hours_logged == 0 ? count.total_hours_logged : '-'} </Text>
                                        </Grid>
                                    </Box>
                            }
                        </Grid>
                        <Grid item lg={10} xs={12} alignItems='center'>
                            {
                                sidebarLoader ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} /> :
                                    <Box alignItems='center' sx={{
                                        background: '#FAFAFA',
                                        borderRadius: '12px',
                                        padding: '12px',
                                    }}>
                                        <Grid item lg={12} xs={12} alignItems='center' pl={1}>
                                            <Text mediumBoldBlack>Total Billed</Text>
                                            <Text boldblack22>{currency} {count.total_billed}</Text>
                                        </Grid>
                                    </Box>
                            }
                        </Grid>
                        <Grid item lg={10} xs={12} alignItems='center'>
                            {
                                sidebarLoader ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} /> :
                                    <Box alignItems='center' sx={{
                                        background: '#FAFAFA',
                                        borderRadius: '12px',
                                        padding: '12px',
                                    }}>
                                        <Grid item lg={12} xs={12} alignItems='center' pl={1}>
                                            <Text mediumBoldBlack>Total Pay</Text>
                                            <Text boldblack22>{currency} {count.total_pay}</Text>
                                        </Grid>
                                    </Box>
                            }
                        </Grid>
                        <Grid item lg={10} xs={12} alignItems='center'>
                            {
                                sidebarLoader ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} /> :
                                    <Box alignItems='center' sx={{
                                        background: '#FAFAFA',
                                        borderRadius: '12px',
                                        padding: '12px',
                                    }}>
                                        <Grid item lg={12} xs={12} alignItems='center' pl={1}>
                                            <Text mediumBoldBlack>Margin</Text>
                                            <Text boldblack22>{currency} {count.margin}</Text>
                                        </Grid>
                                    </Box>
                            }
                        </Grid>
                    </Grid>
                    <Grid item lg={9} xs={9} container spacing={2} justifyContent='center'>
                        <Grid item container lg={11} xs={11} alignItems='center'>
                            <Grid item lg={7} md={6} xs={7}>
                                <Text largeBlack700>Total {active === 'clients' ? 'Clients' : 'Placements'}</Text>
                            </Grid>
                            <Grid item lg={5} md={5} xs={5} display='flex' flexDirection='row' gap={2} justifyContent='end'>
                                {/* {
                                    loading ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} /> : */}
                                <Paper className={classes.Paper} display={'flex'}>
                                    <InputBase
                                        className={classes.InputBase}
                                        fullWidth
                                        name={active == 'clients' ? 'searchClientName' : 'searchPlacementName'}
                                        onChange={(e) => changeHandler(e)}
                                        placeholder='Search by Name / ID'
                                        value={active == 'clients' ? state.searchClientName : state.searchPlacementName}
                                    />
                                    <img src={Search} alt="Search" className={classes.searchIcon} />
                                </Paper>
                                {/* }
                                {
                                    loading ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: "23px", height: '23px' }} /> : */}
                                <button title='Export' className={classes.cloudBox} onClick={downloadExport}>
                                    <img src={cloud} alt="Userplus" style={{ height: '23px', width: '23px', cursor: 'pointer' }} />
                                </button>
                                {/* } */}
                            </Grid>
                        </Grid>
                        <Grid item container lg={11} xs={11} alignItems='center'>
                            <Box display='flex' flexDirection='row' gap={1.5} className={classes.payrateNote}>
                                <Box pt={'4px'}>
                                    <img src={blueinfo} alt='warning' />
                                </Box>
                                <Box textAlign='start'>
                                    <Text mediumOverView>The Total Pay and Margin values are approximated and not accurate for salaried employees.</Text>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item container lg={11} xs={11} mt={2}>
                            <Grid item lg={4} xs={4}>
                                <Text largeLabel>{active === 'clients' ? 'Client' : 'Employee'} Name </Text>
                            </Grid>
                            <Grid item lg={2} xs={2}>
                                <Text largeLabel>{active === 'clients' ? 'Total Placements' : 'Client Name'}</Text>
                            </Grid>
                            <Grid item lg={2} xs={2}>
                                <Text largeLabel>Total Logged Hours</Text>
                            </Grid>
                            <Grid item lg={2} xs={2}>
                                <Text largeLabel>Total Billed</Text>
                            </Grid>
                            <Grid item lg={2} xs={2}>
                                <Text largeLabel>Total Pay</Text>
                            </Grid>
                        </Grid>
                        <Grid item lg={11} xs={11}>
                            <Divider />
                        </Grid>
                        <Grid item container lg={11} xs={11}>
                            {
                                loading ?
                                    [1, 2, 3].map((item) => (
                                        <Stack key={item} direction="row" my={2} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                            <Box sx={{ width: "45%", display: "flex", alignItems: "center", gap: 2 }}>
                                                <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Box>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    </Box>
                                                    <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={{ width: "55%", display: "flex", alignItems: "center", gap: 2 }}>
                                                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                </Box>
                                            </Box>
                                        </Stack>
                                    )) : (active === 'clients' ? clientsRows.length == 0 && !loading : placementRows.length == 0 && !loading) ?
                                        NoDataFound() :
                                        <Grid container>
                                            <Box className={classes.scrollContent} ref={scrollBox} onScroll={handleScroll}>
                                                {
                                                    (active === 'clients' ? clientsRows.length > 0 && clientsRows : placementRows.length > 0 && placementRows).map((item) => (
                                                        <Grid item container lg={12} xs={12} spacing={2} className={classes.accordian} onClick={() => handleAccordian(item)}>
                                                            <Grid item lg={4} md={4} xs={4}>
                                                                <Box display='flex' alignItems='center' gap={2}>
                                                                    <CircularProgress
                                                                        percentage={item.profile_percentage}
                                                                        color={item.profile_percentage >= 76 ? 'green' : item.profile_percentage <= 75 && item.profile_percentage >= 51 ? 'yellow' : item.profile_percentage <= 50 ? "#FFBF00" : ''}
                                                                        src={item.logo_url}
                                                                        name={item.logo_url == undefined || item.logo_url == null || item.logo_url == '' ? active == 'clients' ? item.client_name && item.client_name[0] : item.employee_name[0] : ''}
                                                                        imgWidth='50px !important'
                                                                        imgHeight='50px !important'
                                                                        avatarWidth='50px !important'
                                                                        avatarHeight='50px !important'
                                                                        value={item.profile_percentage}
                                                                        size={58}
                                                                        tooltip
                                                                        tooltipContent={
                                                                            <Box p={'3px 0px'} width='100%'>
                                                                                <Text mediumBoldWhite400>{`Profile Completion - ${item.profile_percentage}%`}</Text>
                                                                            </Box>
                                                                        }
                                                                    />
                                                                    <Box>
                                                                        <Text mediumBoldBlack>
                                                                            <Box display='flex' flexDirection='row' gap={1}>
                                                                                <Box>
                                                                                    {active == 'clients' ?
                                                                                        item.client_name.length > 16 ?
                                                                                            <BlackToolTip title={capitalizeAndAddSpace(item.client_name)} placement="top" arrow>
                                                                                                {capitalizeAndAddSpace(item.client_name.slice(0, 16)) + capitalizeAndAddSpace(item.client_name.length > 16 ? "..." : "")}
                                                                                            </BlackToolTip>
                                                                                            : capitalizeAndAddSpace(item.client_name)
                                                                                        : active == 'placements' ?
                                                                                            item.employee_name.length > 16 ?
                                                                                                <BlackToolTip title={capitalizeAndAddSpace(item.employee_name)} placement="top" arrow>
                                                                                                    {capitalizeAndAddSpace(item.employee_name.slice(0, 16)) + capitalizeAndAddSpace(item.employee_name.length > 16 ? "..." : "")}
                                                                                                </BlackToolTip>
                                                                                                : capitalizeAndAddSpace(item.employee_name) : ''
                                                                                    }
                                                                                </Box>
                                                                                <Box>
                                                                                    {active === 'clients' ? '' :
                                                                                        <BlackToolTip arrow placement='right' title={<Text smallWhite>{(item.e_verified == 1 || item.e_verified == 4) ? 'E-Verified' : 'E-verification is pending'}</Text>}>
                                                                                            {
                                                                                                item.e_verified == 1 || item.e_verified == 4 ?
                                                                                                    <img src={Verified} alt='Verified' style={{ marginBottom: '-3px' }} /> :
                                                                                                    item.e_verified == 2 ?
                                                                                                        <img src={Pending} alt='svg' style={{ marginBottom: '-3px' }} /> : ''
                                                                                            }
                                                                                        </BlackToolTip>
                                                                                    }
                                                                                </Box>
                                                                            </Box>
                                                                        </Text>
                                                                        <Text mediumGreybold noWrap sx={{ paddingTop: '8px' }}>{item.reference_id}</Text>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item lg={2} xs={2}>
                                                                <Text mediumBlack14>{active == 'clients' ? item.total_placements ? item.total_placements : '-' :
                                                                    item.client_name.length > 16 ?
                                                                        <BlackToolTip title={capitalizeAndAddSpace(item.client_name)} placement="top" arrow>
                                                                            {capitalizeAndAddSpace(item.client_name.slice(0, 16)) + capitalizeAndAddSpace(item.client_name.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        : capitalizeAndAddSpace(item.client_name)
                                                                }</Text>
                                                            </Grid>
                                                            <Grid item lg={2} xs={2}>
                                                                <Text mediumBlack14>{item.total_hours_logged ? `${item.total_hours_logged} hrs` : '-'} </Text>
                                                            </Grid>
                                                            <Grid item lg={2} xs={2}>
                                                                <Text mediumBlack14>{currency} {item.total_billed ? item.total_billed : '-'}</Text>
                                                            </Grid>
                                                            <Grid item lg={2} xs={2}>
                                                                <Text mediumBlack14>{currency} {item.total_pay ? item.total_pay : '-'}</Text>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                }
                                                {
                                                    listLoading && (<ListLoadingIcon />)
                                                }
                                            </Box>
                                            <div ref={messagesEndRef} />
                                        </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                :
                <>
                    {NoPermission()}
                </>
            }
        </Grid >
    )
}

export default Analytics