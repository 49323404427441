import React, { useCallback, useEffect, useState } from 'react';
import { Box, Checkbox, Grid, Stack } from '@mui/material';
import Text from '../../../../../components/customText/Text';
import Button from '../../../../../components/customButton/Button';
import Input from '../../../../../components/input/Input';
import moment from 'moment';
import Date from '../../../../../components/datePicker/Date';
import CommonApi from '../../../../../apis/CommonApi';
import { addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, capitalize, checkZipcode, dateFormat, findVisa, getCountryDetails, removeLoader } from '../../../../../utils/utils';
import LocalStorage from '../../../../../utils/LocalStorage';
import Select from '../../../../../components/select/Select';
import { address_validation, empty_Email_id, empty_fields, empty_name, empty_usContact, gender_validations, isValid, onNumberWithTwoDecimalOnlyChangeCurrency, onNumberWithTwoDecimalOnlyChangeWithNegativeCurrency, validate_charWithSpace, validate_city, validate_empty_address, validate_emptyField, validate_ssn_mandatory, validate_usContact, validate_zipcode, validates_emailId } from '../../../../../components/Validation';
import OnBoardApi from '../../../../../apis/admin/employees/OnBoardApi';
import EmployeesApi from '../../../../../apis/admin/employees/EmployeesApi';
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import Sure from '../../../../../assets/svg/ExpenseDeleteIcon.svg'
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import FileInput from '../../../../../components/muiFileInput/FileInput';
import { debounce } from 'lodash';
import Zipcode from '../../../Zipcode';
import { useZipcodeAPICall } from '../../../ZipcodeCommon';
import { ErrorMessages } from '../../../../../utils/ErrorMessages';
import { ReactComponent as CheckedIcon } from '../../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../../assets/svg/CheckedBorderIcon.svg';

export default function EditGeneralDetails(props) {
    // eslint-disable-next-line
    const [error, setError] = useState({})// eslint-disable-next-line
    const usMobileNumberValidator = new RegExp(/^[a-zA-Z@~`!@#$%^&* ()_=+\\';:"\/?>.<,-]*$/);
    const genderList = require('../../../../../utils/jsons/Gender.json');
    const bloodGroups = require('../../../../../utils/jsons/BloodGroup.json');
    const IsEmpUSA = [
        {
            id: 1,
            value: 'Yes'
        },
        {
            id: 0,
            value: 'No'
        }
    ]

    const [state, setState] = useState(props.employee)// eslint-disable-next-line
    const [visaList, setVisaList] = useState([]);
    const [employmentType, setEmploymentType] = useState([]);// eslint-disable-next-line
    const [args, setArgs] = useState('')
    const [val, setVal] = useState('')
    const [category, setCategory] = useState([]);// eslint-disable-next-line
    const [countries, setCountries] = useState(getCountryDetails() ? getCountryDetails() : []);
    const [clearPopup, setclearPopup] = useState(false);// eslint-disable-next-line
    const [loader, setLoader] = useState(false);
    const [w4Show, setw4Show] = useState(false);
    const [vendorTypes, setVendorTypes] = useState([]);
    const [clearPopup4, setclearPopup4] = useState(false);
    const [maritalList, setMaritalList] = useState([]);
    const currency = LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$';

    useEffect(() => {
        employmentList();
        visaListApi();
        vendorListApi();
        maritalListApi();
        categoryList(state.employment_type_id)
        EmployeesApi.getEmployee(props.employee.id).then((response) => {
            setState({ ...response.data.data });
            if (response.data.data.deposit_form_documents == undefined || response.data.data.deposit_form_documents == null) {
                state.deposit_form_documents = [{
                    id: '',
                    new_document_id: '',
                    document_url: '',
                    document_name: ''
                }]
                setState({ ...state })
            }
        }
        )
        if (state.deposit_form_documents == undefined || state.deposit_form_documents == null) {
            state.deposit_form_documents = [{
                id: '',
                new_document_id: '',
                document_url: '',
                document_name: ''
            }]
            setState({ ...state })
        }
        // eslint-disable-next-line
    }, [])

    const employmentList = () => {
        CommonApi.employmentTypesList(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setEmploymentType(res.data.data);
            }
        })
    }
    // eslint-disable-next-line
    const categoryList = (args) => {
        CommonApi.CategoryList(LocalStorage.uid(), args, LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setCategory(res.data.data);
            }
        })
    }

    const visaListApi = () => {
        CommonApi.visaTypes(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setVisaList(res.data.data);
            }
        })
    }

    const fetchZipcodeData = useZipcodeAPICall({ setState, setError });
    const handleChange = (e) => {
        if (e.target.name == 'zip_code') {
            fetchZipcodeData(e.target.value)
        }
        if (e.target.name === "health_insurance_amount" || e.target.name === "standard_pay_amount" || e.target.name === "opening_balance") {
            let value1 = e.target.value.trim();
            if (value1 == "$" || value1 == "") {
                state[e.target.name] = "";
                setState({ ...state }, handleValidate(e));
                return;
            }
            value1 = value1.replace(currency, "");
            const regex = /^(?:\d{1,10}|\d{1,10}\.\d{0,2})$/;
            const numericValue = parseFloat(value1);
            if (regex.test(value1) && (!isNaN(numericValue) && numericValue <= 9999999999.99)) {
                state[e.target.name] = value1;
            } else {
                return false;
            }
            setState({ ...state }, handleValidate(e));
        }
        else if (e.target.name == "enable_health_insurance" || e.target.name == "make_standard_pay_as_salary") {
            setState({
                ...state,
                [e.target.name]: e.target.checked
            }, handleValidate(e))
        }
        else if (e.target.name == 'contact_number' || e.target.name === 'ssn' || e.target.name === 'alternate_contact_number') {
            convertFormat(e);
            if (e.target.name == 'contact_number') {
                checkEmailHandle(e.target.value, 'mobile')
            }
            else {
                checkEmailHandle(e.target.value, 'ssn')
            }
        }
        else if (e.target.name == 'first_name' || e.target.name == 'last_name' || e.target.name == 'middle_name') {
            setState({
                ...state,
                [e.target.name]: capitalize(e.target.value)
            }, handleValidate(e))
        }
        else if (e.target.name == 'is_usc') {
            if (e.target.value == 1) {
                setclearPopup(true)
            }
            else {
                setState({
                    ...state,
                    [e.target.name]: e.target.value
                }, handleValidate(e))
            }
        }
        else if (e.target.name == 'company_id') {
            state.company_id = e.target.value;
            setState({ ...state })
            handleValidate(e)
        } else if (e.target.name == 'gender') {
            state[e.target.name] = e.target.value;
            state['gender_description'] = ''
            setState({ ...state })
            handleValidate(e)
        }
        else {
            if (e.target.name === "marital_status_id") {
                if ((props.employee.marital_status_id == 1 && e.target.value == 2) ||
                    (props.employee.marital_status_id == 2 && ([1, 3, 4, 5].includes(e.target.value)))) {
                    if (props.employee.bank_pending) {
                        setclearPopup4(true)
                        return true
                    } else {
                        setw4Show(true)
                    }
                }
                else {
                    setw4Show(false);
                    state.deposit_form_documents[0].new_document_id = ''
                    state.deposit_form_documents[0].id = ''
                    state.deposit_form_documents[0].document_url = ''
                    state.deposit_form_documents[0].document_name = ''
                }
                state.basic_details[e.target.name] = e.target.value
                state[e.target.name] = e.target.value
                setState({
                    ...state
                }, handleValidate(e))
            }
            if (e.target.name === 'country_id') {
                state.state_id = ''
                state.city = ''
                state.zip_code = ''
            }
            if (e.target.name === 'employment_type_id') {
                categoryList(e.target.value)
                state.employment_category_id = ''
            }
            setState({
                ...state,
                [e.target.name]: e.target.value
            }, handleValidate(e))

            if (e.target.name === 'email_id') {
                checkEmailHandle(e.target.value, 'email')
            }
        }

    }

    const resetStateWithError = (data) => {
        setState((prevState) => ({
            ...prevState,
            state_name: '',
            city: '',
            state_id: '',
            country_name: '',
            country_id: ''
        }));

        setError((prevError) => ({
            ...prevError,
            city: '',
            zip_code:
                data.length == 0 ? ErrorMessages.fieldRequired :
                    data.length == 6 ? ErrorMessages.invalidPincode : data.length == 5 ? ErrorMessages.invalidZipcode : ErrorMessages.invalidPinZipcode,
            state_id: '',
            country_id: ''
        }));
    };

    // eslint-disable-next-line
    const zipcodeAPICall = useCallback(
        debounce((data) => {
            CommonApi.getZipcodeData(data).then((res) => {
                const { statusCode, data: responseData } = res.data;

                if (statusCode === 1003) {
                    if (responseData.length > 0) {
                        const { state_name, country_name, city, state_id, country_id } = responseData[0];

                        setState((prevState) => ({
                            ...prevState,
                            state_name,
                            country_name, // Ensure country is being set in state
                            city,
                            state_id,
                            country_id
                        }));
                        setError((prevError) => ({
                            ...prevError,
                            city: '',
                            zip_code: '',
                            state_id: '',
                            country_id: ''
                        }));
                    } else {
                        resetStateWithError(data);
                    }
                } else {
                    resetStateWithError(data);
                }
            });

        }, 500), // 500ms delay
        []
    );




    const checkEmail = (args, val) => {
        const delayDebounceFn = setTimeout(() => {
            var obj = {
                request_id: LocalStorage.uid(),
                email_id: args,
                id: props.employee.id
            }
            var Mobileobj = {
                request_id: LocalStorage.uid(),
                contact_number: args,
                id: props.employee.id
            }
            var ssnObj = {
                request_id: LocalStorage.uid(),
                ssn: args,
                id: props.employee.id
            }
            EmployeesApi.checkDuplicate(val == 'mobile' ? Mobileobj : val == 'email' ? obj : ssnObj).then((res) => {
                if (res.data.statusCode === 1003) {
                    if (res.data.valid) {

                    } else {
                        if (val == 'email') {
                            setError({
                                ...error,
                                email_id: "Email ID already exists"
                            })

                        }
                        else if (val == 'mobile') {
                            setError({
                                ...error,
                                contact_number: "Mobile Number already exists"
                            })
                        }
                        else {
                            setError({
                                ...error,
                                ssn: "SSN already exists"
                            })
                        }
                    }
                }
                else {
                    addErrorMsg(res.data.message)
                }
            });
        }, 300)

        return () => clearTimeout(delayDebounceFn)
    }

    const checkEmailHandle = (args, val) => {
        if (val == 'email' && props.employee.email_id == args) {
            return
        }
        setArgs(args);
        setVal(val)

    }

    const deleteDoc = (index, args) => {
        state.deposit_form_documents[0].new_document_id = ''
        state.deposit_form_documents[0].document_name = ''
        setState({ ...state })
    }



    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            var obj = {
                request_id: LocalStorage.uid(),
                email_id: args,
                id: props.employee.id
            }
            var Mobileobj = {
                request_id: LocalStorage.uid(),
                contact_number: args,
                id: props.employee.id
            }
            var ssnObj = {
                request_id: LocalStorage.uid(),
                ssn: args,
                id: props.employee.id
            }
            let err = validates_emailId(obj.email_id)
            let errSSN = validate_ssn_mandatory(ssnObj.ssn)
            if (Mobileobj.contact_number.length > 11 || (err == '') || (errSSN == '')) {
                EmployeesApi.checkDuplicate(val == 'mobile' ? Mobileobj : val == 'email' ? obj : ssnObj).then((res) => {
                    if (res.data.statusCode === 1003) {
                        if (res.data.valid) {

                        } else {
                            if (val == 'email') {
                                error.email_id = 'Email ID already exists'
                                setError({ ...error })
                            }
                            else if (val == 'mobile') {
                                error.contact_number = "Mobile Number already exists"
                                setError({ ...error })
                            }
                            else {
                                error.ssn = "SSN already exists"
                                setError({ ...error })
                            }
                        }
                    }
                    else {
                        addErrorMsg(res.data.message)
                    }
                });
            }

        }, 300)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line
    }, [args, val])

    const convertFormat = (e) => {
        const value = e.target.value;
        const name = e.target.name
        const input = value.replace(/\D/g, '').substring(0, 10);

        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > (name == 'contact_number' || name == 'alternate_contact_number' ? 6 : 5)) {
            setState(
                {
                    ...state,
                    [e.target.name]: `${first}-${middle}-${last}`
                }, handleValidate(e));
            if (name == 'ssn') {
                if (last == '0000') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
        else if (input.length > 3) {
            setState(
                {
                    ...state,
                    [e.target.name]: `${first}-${middle}`
                }, handleValidate(e));
            if (name == 'ssn') {
                if (middle == '00') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
        else if (input.length >= 0) {
            setState(
                {
                    ...state,
                    [e.target.name]: input
                }, handleValidate(e));
            if (name == 'ssn') {
                if (first == '000' || first == '666' || first == '900') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
    }

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setState({
            ...state,
            [name]: date == '' ? '' : moment(date).format(dateFormat())
        }, handleValidate(event))
    }

    const handleValidate = (e) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case "first_name":
                error.first_name = validate_charWithSpace(input.value, 'first ');
                break;
            case "middle_name":
                error.middle_name = empty_name(input.value, 'middle ');
                break;
            case "last_name":
                error.last_name = validate_charWithSpace(input.value, 'last ');
                break;
            case 'dob':
                error.dob = validate_emptyField(input.value)
                break
            case 'gender':
                error.gender = validate_emptyField(input.value)
                break
            case 'blood_group':
                error.blood_group = ''
                break
            case 'marital_status_id':
                error.marital_status_id = empty_fields(input.value)
                break
            case 'contact_number':
                if ((input.value == state.alternate_contact_number) && input.value != "") {
                    error.contact_number = "Mobile Number and Alternate Mobile Number Not be Same"
                }
                // error.alternate_contact_number = usMobileNumberValidator.test(state.alternate_contact_number) ? '' : empty_usContact(state.alternate_contact_number, 'Alternate Mobile Number')
                // error.contact_number = validate_usContact(state.contact_number, 'Mobile Number')
                else {
                    error.contact_number = input.value == '' ? 'This field is required' : usMobileNumberValidator.test(input.value) ? '' : validate_usContact(input.value, 'Mobile Number')
                    error.alternate_contact_number = usMobileNumberValidator.test(state.alternate_contact_number) ? '' : empty_usContact(state.alternate_contact_number, 'Alternate Mobile Number')
                    // error.alternate_contact_number = ''
                }
                break
            case 'alternate_contact_number':
                if (input.value == state.contact_number && input.value != "") {
                    error.alternate_contact_number = "Mobile Number and Alternate Mobile Number Not be Same"
                } else {
                    error.alternate_contact_number = usMobileNumberValidator.test(input.value) ? '' : empty_usContact(input.value, 'Alternate Mobile Number')
                    error.contact_number = error.contact_number != 'Mobile Number already exists' ? validate_usContact(state.contact_number, 'Mobile Number') : error.contact_number
                    // error.contact_number = ''
                }
                break
            case 'email_id':
                if (input.value != "" && (input.value.toLowerCase()) == (state.alternate_email_id.toLowerCase())) {
                    error.email_id = "Email ID and Alternate Email ID Not be Same"
                } else {
                    error.email_id = validates_emailId(input.value, 'Email ID')
                    error.alternate_email_id = empty_Email_id(state.alternate_email_id, 'Alternate Email ID')
                    // error.alternate_email_id = ''
                }
                break
            case 'alternate_email_id':
                if (input.value !== '' && ((input.value.toLowerCase()) == (state.email_id.toLowerCase()))) {
                    error.alternate_email_id = "Email ID and Alternate Email ID Not be Same"
                } else {
                    error.alternate_email_id = empty_Email_id(input.value, 'Alternate Email ID')
                    error.email_id = error.email_id != 'Email ID already exists' ? validates_emailId(state.email_id, 'Email ID') : error.email_id
                    // error.email_id = ''
                }

                break
            case 'address_line_one':
                error.address_line_one = address_validation(input.value)
                break
            case 'address_line_two':
                error.address_line_two = validate_empty_address(input.value)
                break
            case 'zip_code':
                error.zip_code = validate_zipcode(input.value, state.country_id);
                break
            case 'city':
                error.city = validate_city(input.value)
                break
            case 'country_id':
                error.country_id = validate_emptyField(input.value)
                break
            case 'state_id':
                error.state_id = validate_emptyField(input.value)
                break
            case 'reference_id':
                error.reference_id = validate_emptyField(input.value)
                break
            case 'date_of_joining':
                error.date_of_joining = validate_emptyField(input.value)
                break
            case 'employment_type_id':
                error.employment_type_id = validate_emptyField(input.value)
                error.role_id = "";
                break
            case 'employment_category_id':
                error.employment_category_id = validate_emptyField(input.value)
                break
            case 'company_id':
                error.company_id = validate_emptyField(input.value)
                break
            case 'department_id':
                error.department_id = validate_emptyField(input.value);
                break;
            case 'team_id':
                error.team_id = validate_emptyField(input.value);
                break;
            case 'ssn':
                error.ssn = usMobileNumberValidator.test(input.value) ? '' : validate_ssn_mandatory(input.value)
                break
            case 'is_usc':
                error.is_usc = validate_emptyField(input.value)
                break
            case 'visa_type_id':
                error.visa_type_id = validate_emptyField(input.value)
                break
            case 'role_id':
                error.role_id = validate_emptyField(input.value)
                break
            case 'gender_description':
                error.gender_description = gender_validations(input.value)
                break
            case 'deposit_form_documents':
                error.deposit_form_documents = validate_emptyField(input.value)
                break
            case 'health_insurance_amount':
                error.health_insurance_amount = ''
                break
            case 'standard_pay_amount':
                error.standard_pay_amount = ''
                break
            default:
                break
        }
        setError({ ...error })
    }

    const checkJoiningDateError = (date_of_joining, rejoin_date) => {
        if (date_of_joining && date_of_joining !== '' && date_of_joining != 'Invalid Date') {
            if (rejoin_date && rejoin_date !== '' && rejoin_date !== 'Invalid Date') {
                error.date_of_joining = moment(date_of_joining, dateFormat()).isBefore(rejoin_date) ? 'Please select a date that is greater than Re-Joining date' : ''
            }
            else {
                error.date_of_joining = validate_emptyField(date_of_joining)
            }
        } else {
            error.date_of_joining = 'Please select a valid date'
        }
        setError({ ...error })
    }

    const validateAll = async () => {
        const { first_name, last_name, dob, gender, contact_number, employment_type_id, email_id, address_line_one, zip_code, city, country_id, state_id, date_of_joining, employment_category_id, ssn, // eslint-disable-next-line
            is_usc, visa_type_id, address_line_two, company_id, rejoin_date, gender_description, health_insurance_amount, standard_pay_amount } = state
        let belowDOB = moment().subtract(18, "years")
        let actualDOB = moment(dob, dateFormat()).format('YYYY-MM-DD');
        error.health_insurance_amount = state.enable_health_insurance ? health_insurance_amount == 0 ? ErrorMessages.greaterThanZero : validate_emptyField(health_insurance_amount) : ''
        error.standard_pay_amount = findVisa(visaList, state.visa_type_id) ? standard_pay_amount == 0 ? ErrorMessages.greaterThanZero :  validate_emptyField(standard_pay_amount) : ''
        error.first_name = validate_charWithSpace(first_name, 'first ')
        error.last_name = validate_charWithSpace(last_name, 'last ');
        error.visa_type_id = state.is_usc == 0 ? validate_emptyField(visa_type_id) : ''
        error.dob = dob !== '' ? moment(actualDOB).isBefore(belowDOB) ? '' : 'Minimum age should be greater than 18' : validate_emptyField(dob);
        error.gender = validate_emptyField(gender);
        error.contact_number = error.contact_number == 'Mobile Number already exists' ? 'Mobile Number already exists' : validate_usContact(contact_number, 'Mobile Number');
        error.email_id = error.email_id == 'Email ID already exists' ? 'Email ID already exists' : validates_emailId(email_id);
        error.address_line_one = address_validation(address_line_one);
        error.address_line_two = validate_empty_address(address_line_two)
        error.zip_code = await checkZipcode(zip_code)
        error.city = validate_city(city)
        error.country_id = validate_emptyField(country_id)
        error.state_id = validate_emptyField(state_id)
        error.employment_type_id = validate_emptyField(employment_type_id)
        error.employment_category_id = employment_type_id != 1 ? validate_emptyField(employment_category_id) : ''
        error.company_id = employment_type_id == 3 ? validate_emptyField(company_id) : '';
        error.ssn = error.ssn == 'SSN already exists' ? 'SSN already exists' : validate_ssn_mandatory(ssn)
        error.is_usc = validate_emptyField(is_usc)
        error.deposit_form_documents = w4Show ? validate_emptyField(state.deposit_form_documents[0].new_document_id) : ''
        error.gender_description = state.gender == 'Prefer to self-describe' ? gender_validations(gender_description) : '';
        if (props.employee.email_id !== email_id) {
            checkEmail(email_id, 'email');
        }
        checkJoiningDateError(date_of_joining, rejoin_date)
        checkEmail(contact_number, 'mobile');
        checkEmail(state.ssn, 'ssn');
        setError({ ...error })
        return error;
    }

    const uploadDocs = (value) => {
        addLoader()
        const formData = new FormData();
        formData.append("files", value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        if (Number(((value.target.files[0].size / (1024 * 1024)).toFixed(2))) < 25) {
            CommonApi
                .documentUpload("bank-document", formData, LocalStorage.getAccessToken())
                .then((response) => {
                    if (response.data.statusCode == 1003) {
                        removeLoader();
                        let docInfo = response.data.data;
                        removeLoader()
                        state.deposit_form_documents[0].new_document_id = docInfo.id;
                        state.deposit_form_documents[0].document_url = docInfo.document_url;
                        state.deposit_form_documents[0].document_name = value.target.files[0].name;
                        error.deposit_form_documents = ''
                        setError({ ...error })
                        setState({ ...state });
                        removeLoader();
                    } else {
                        addErrorMsg(response.data.message);
                        removeLoader();
                    }
                });
        }
        else {
            removeLoader()
            addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
        }
    };

    const saveForm = async () => {
        let errors = await validateAll();
        if (errors.deposit_form_documents == '') {
            setLoader(true);
            if (isValid(errors)) {
                state['request_id'] = LocalStorage.uid()
                state['marital_status_id'] = state.basic_details.marital_status_id
                OnBoardApi.employeeUpdate(state, LocalStorage.getAccessToken()).then((res) => {
                    if (res.data.statusCode === 1003) {
                        setLoader(false)
                        props.getIndex()
                        addSuccessMsg('Changes Saved Successfully')
                        props.setEdit(false);
                        props.setformEdit(false)
                    }
                    else {
                        setLoader(false)
                        addErrorMsg(res.data.message)
                    }
                })
            }
            else {
                setLoader(false)
                addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
            }
        } else {
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
    }

    const cancelForm = () => {
        props.setEdit(false)
        props.setformEdit(false)
        setState(props.employee)
        setError({})
    }

    const vendorListApi = () => {
        CommonApi.getVendorList('Active').then((res) => {
            if (res.data.statusCode === 1003) {
                setVendorTypes(res.data.data);
            }
        })
    }

    const maritalListApi = () => {
        CommonApi.maritalStatusList().then((res) => {
            if (res.data.statusCode === 1003) {
                setMaritalList(res.data.data);
            }
        })
    }

    return (
        <Grid container lg={12} p={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Basic Details
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'first_name',
                            value: state.first_name,
                            inputProps: { maxLength: 33 }
                        }}
                        clientInput
                        handleChange={handleChange}
                        labelText={'First Name'}
                        error={error.first_name}
                    />
                    {
                        error.first_name ?
                            <Text red>{error.first_name ? error.first_name : ''}</Text> : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'middle_name',
                            value: state.middle_name,
                            inputProps: { maxLength: 33 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.middle_name}
                        labelText='Middle Name (Optional)'
                    // labelText={error.middle_name ? 'Middle Name (Optional)' : <span className={classes.optional}>(Optional)</span>}
                    />
                    {
                        error.middle_name ?
                            <Text red>{error.middle_name ? error.middle_name : ''}</Text> : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'last_name',
                            value: state.last_name,
                            inputProps: { maxLength: 33 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        labelText={'Last Name'}
                        error={error.last_name}
                    />
                    {
                        error.last_name ?
                            <Text red>{error.last_name ? error.last_name : ''}</Text> : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Date
                        labelText={'Date of Birth'}
                        name='dob'
                        height='54px'
                        value={state.dob}
                        onChange={(value => dateChange(value, 'dob'))}
                        maxDate={moment().subtract(18, "years")}
                        error={error.dob}
                    />
                    {
                        error.dob ?
                            <Text red>{error.dob ? error.dob : ''}</Text> : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='gender'
                        value={state.gender}
                        onChange={handleChange}
                        options={genderList}
                        label={'Gender'}
                        helperText={
                            error.gender ?
                                <Text red>{error.gender ? error.gender : ''}</Text> : ''
                        }
                    />
                </Box>
            </Grid>
            {
                state.gender == 'Prefer to self-describe' ?
                    <Grid item lg={12} md={12} sm={12} xs={12} py={'4px'} px={1}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'gender_description',
                                value: state.gender_description,
                                inputProps: { minLength: 3, maxLength: 50 }
                            }}
                            handleChange={handleChange}
                            clientInput
                            labelText={'Gender Description'}
                            error={error.gender_description}
                        />
                        {
                            error.gender_description &&
                            <Text red>{error.gender_description ? error.gender_description : ''}</Text>
                        }
                    </Grid> : ''
            }
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='blood_group'
                        value={state.blood_group}
                        onChange={handleChange}
                        options={bloodGroups}
                        label={'Blood Group (Optional)'}
                        helperText={error.blood_group ? <Text red>{error.blood_group ? error.blood_group : ''}</Text> : ''}
                    />
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='marital_status_id'
                        value={state.marital_status_id}
                        onChange={handleChange}
                        options={maritalList}
                        label={'Marital Status (Optional)'}
                        helperText={
                            error.marital_status_id ?
                                <Text red>{error.marital_status_id ? error.marital_status_id : ''}</Text> : ''
                        }
                    />
                </Box>
            </Grid>
            {
                w4Show
                    ?
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box p={1} pt={2}>
                            <FileInput
                                name={"deposit_form_documents"}
                                FileName={state.deposit_form_documents ? state.deposit_form_documents[0].document_name : ''}
                                handleChange={(e) => uploadDocs(e, 0, "deposit_form_documents")}
                                label={"W-4 Form"} isDisabled={false}
                                handleDelete={() => deleteDoc()}
                                actionState={state.deposit_form_documents && state.deposit_form_documents[0].document_name ? 1 : ''}
                                documentUrl={state.deposit_form_documents && state.deposit_form_documents[0].document_url}
                                error={error.deposit_form_documents ? error.deposit_form_documents : ""}
                                state={state.deposit_form_documents && state.deposit_form_documents[0]}
                            />
                            <Text errorText> {error.deposit_form_documents ? error.deposit_form_documents : ""}</Text>
                        </Box>
                    </Grid>
                    : ''
            }

            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Contact Details
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'contact_number',
                            value: state.contact_number,
                            inputProps: { maxLength: 12 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        labelText={'Mobile Number'}
                        error={error.contact_number}
                    />
                    {
                        error.contact_number ?
                            <Text red>{error.contact_number ? error.contact_number : ''}</Text>
                            : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'alternate_contact_number',
                            value: state.alternate_contact_number,
                            inputProps: { maxLength: 12 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.alternate_contact_number}
                        labelText={'Alternate Mobile Number(Optional)'}
                    />
                    {
                        error.alternate_contact_number ?
                            <Text red>{error.alternate_contact_number ? error.alternate_contact_number : ''}</Text>
                            : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={4} md={0} sm={0} xs={12}></Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'email_id',
                            value: state.email_id
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.email_id}
                        labelText={'Email ID'}
                    />
                    {
                        error.email_id ?
                            <Text red>{error.email_id ? error.email_id : ''}</Text>
                            : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'alternate_email_id',
                            value: state.alternate_email_id
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.alternate_email_id}
                        labelText={'Alternate Email ID (Optional)'}
                    />
                    {
                        error.alternate_email_id ?
                            <Text red>{error.alternate_email_id ? error.alternate_email_id : ''}</Text> : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Employment Details
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'reference_id',
                            value: state.reference_id,
                            disabled: true
                        }}
                        disabled={true}
                        handleChange={handleChange}
                        clientInput
                        labelText={'Employee ID'}
                    />
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Date
                        name='date_of_joining'
                        value={state.date_of_joining}
                        onChange={(value => dateChange(value, 'date_of_joining'))}
                        height='54px'
                        error={error.date_of_joining}
                        labelText='Joining Date'
                        minDate={state.rejoin_date ? state.rejoin_date : state.dob}
                    />
                    {error.date_of_joining && <Text red>{error.date_of_joining ? error.date_of_joining : ''}</Text>}
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='employment_type_id'
                        value={state.employment_type_id}
                        disabled={true}
                        onChange={handleChange}
                        options={employmentType}
                        label={'Employment Type'}
                    />
                </Box>
            </Grid>
            {
                state.employment_type_id !== 1 &&
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Select
                            name='employment_category_id'
                            value={state.employment_category_id}
                            onChange={handleChange}
                            options={category}
                            label={'Employment Category'}
                            helperText={error.employment_category_id && <Text red>{error.employment_category_id ? error.employment_category_id : ''}</Text>}
                        />
                    </Box>
                </Grid>
            }
            {
                state.employment_type_id == 3 &&
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Select
                            name='company_id'
                            value={state.company_id}
                            onChange={handleChange}
                            options={vendorTypes}
                            label={'Vendor'}
                            helperText={error.company_id && <Text red>{error.company_id ? error.company_id : ''}</Text>}
                        />
                    </Box>
                </Grid>
            }
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'ssn',
                            value: state.ssn,
                            inputProps: { maxLength: 11 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.ssn}
                        labelText='SSN'
                        disabled={(state?.e_verified == '0' || state?.e_verified == '3') ? false : true}
                    />
                    {error.ssn && <Text red>{error.ssn ? error.ssn : ''}</Text>}
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='is_usc'
                        value={state.is_usc}
                        onChange={handleChange}
                        options={IsEmpUSA}
                        label={'Is the Employee a US Citizen?'}
                        helperText={
                            error.is_usc ?
                                <Text red>{error.is_usc ? error.is_usc : ''}</Text> : ''
                        }
                    />
                </Box>
            </Grid>
            {
                state.is_usc === 0 &&
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Select
                            name='visa_type_id'
                            value={state.visa_type_id}
                            onChange={handleChange}
                            options={visaList}
                            label={'Visa Type'}
                            helperText={
                                error.visa_type_id ?
                                    <Text red>{error.visa_type_id ? error.visa_type_id : ''}</Text> : ''
                            }
                        />
                    </Box>
                </Grid>
            }
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'opening_balance',
                            value: currency + '' + state.opening_balance,
                            inputProps: {
                                maxLength: 14, // Maximum length for "9999999999.99"
                                pattern: "\\d*\\.?\\d{0,2}", // Allow numbers with up to 2 decimals
                            },
                        }}
                        handleChange={(e) => handleChange(e)}
                        onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegativeCurrency}
                        clientInput
                        disabled={true}
                        error={error.opening_balance}
                        labelText='Opening Balance (Optional)'
                    />
                    {error.opening_balance && <Text red>{error.opening_balance ? error.opening_balance : ''}</Text>}
                </Box>
            </Grid>
            {
                findVisa(visaList, state.visa_type_id) ?
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box p={1} pt={2}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'standard_pay_amount',
                                    value: currency + '' + state.standard_pay_amount,
                                    inputProps: {
                                        maxLength: 14, // Maximum length for "9999999999.99"
                                        pattern: "\\d*\\.?\\d{0,2}", // Allow numbers with up to 2 decimals
                                    },
                                }}
                                handleChange={(e) => handleChange(e)}
                                onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                clientInput
                                error={error.standard_pay_amount}
                                labelText='Standard Wage'
                            />
                            {error.standard_pay_amount && <Text red>{error.standard_pay_amount ? error.standard_pay_amount : ''}</Text>}
                        </Box>
                    </Grid>
                    : ''
            }
            {state.employment_type_id != 3 ?
                <Grid item lg={12} md={12} sm={12} xs={12} pt={2} pl={2}>
                    <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                        <Checkbox name='enable_health_insurance' value={state.enable_health_insurance} checked={state.enable_health_insurance}
                            onChange={handleChange} sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                            checkedIcon={<CheckedIcon />} />
                        <Text mediumBlack>Do you want to enable health insurance for this employee.</Text>
                    </Box>
                </Grid> : ''}
            {
                state.enable_health_insurance ?
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box p={1} pt={2}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'health_insurance_amount',
                                    value: currency + '' + state.health_insurance_amount,
                                    inputProps: {
                                        maxLength: 14, // Maximum length for "9999999999.99"
                                        pattern: "\\d*\\.?\\d{0,2}", // Allow numbers with up to 2 decimals
                                    },
                                }}
                                handleChange={(e) => handleChange(e)}
                                onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                clientInput
                                error={error.health_insurance_amount}
                                labelText='Insurance Amount'
                            />
                            {error.health_insurance_amount && <Text red>{error.health_insurance_amount ? error.health_insurance_amount : ''}</Text>}
                        </Box>
                    </Grid> : ''
            }
            {
                findVisa(visaList, state.visa_type_id) ?
                    <Grid item lg={12} md={12} sm={12} xs={12} pt={2} pl={2}>
                        <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                            <Checkbox name='make_standard_pay_as_salary' value={state.make_standard_pay_as_salary} checked={state.make_standard_pay_as_salary}
                                onChange={handleChange} sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                checkedIcon={<CheckedIcon />} />
                            <Text mediumBlack>Make Standard Wage the Default Salary</Text>
                        </Box>
                    </Grid>
                    : ''
            }
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Current Address
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'address_line_one',
                            value: state.address_line_one,
                            inputProps: { maxLength: 225 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        labelText={'Address Line 1'}
                        error={error.address_line_one}
                    />
                    {error.address_line_one && <Text red>{error.address_line_one ? error.address_line_one : ''}</Text>}
                </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'address_line_two',
                            value: state.address_line_two,
                            inputProps: { maxLength: 225 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.address_line_two}
                        labelText='Address Line 2 (Optional)'
                    />
                    {error.address_line_two && <Text red>{error.address_line_two ? error.address_line_two : ''}</Text>}
                </Box>
            </Grid>

            <Zipcode state={state} error={error} handleChange={handleChange} columnLength={4} />

            <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Button cancelOutlineQuicksand onClick={() => cancelForm()}>Cancel</Button>
                </Box>
            </Grid>
            <Grid item container justifyContent={'flex-end'} lg={6} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <LoadingButton loading={loader} smallSaveLoader onClick={() => saveForm()}>Save</LoadingButton>
                </Box>
            </Grid>
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to change the citizenship status for </Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>employee? By changing the status previous visa</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>documents for this employee will be cleared.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => {
                                setState({
                                    ...state,
                                    is_usc: 1
                                })
                                setError({
                                    ...error,
                                    is_usc: ''
                                })
                                setclearPopup(false)
                            }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>

            <ReusablePopup openPopup={clearPopup4} setOpenPopup={setclearPopup4} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Editing Denied!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Please add bank details before adding W4</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>document to the employee</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button finishFilledQuicksand onClick={() => { setclearPopup4(false) }} >
                                Okay
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Grid>
    );
}