import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material';
import logo from '../../assets/svg/logoName.svg'
import signupVerify from '../../assets/svg/signupVerify.svg'
import Text from '../../components/customText/Text';
import attempts from '../../assets/svg/attempts.svg'
import SignUpStyles from './SignUpStyles';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useLocation } from 'react-router-dom';
import CommonApi from '../../apis/CommonApi';
import LocalStorage from '../../utils/LocalStorage';
import { domain } from '../../config/Domain';
import { addErrorMsg, addSuccessMsg } from '../../utils/utils';
import loaderGIF from "../../assets/video/Loader-Japfu.gif"

function VerifyAccount() { // eslint-disable-next-line
    const classes = SignUpStyles(); // eslint-disable-next-line
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        email: '',
        subdomain_name: ''
    });
    const navigate = useNavigate()
    const location = useLocation()
    const [status, setStatus] = useState(location.state && location.state.status ? location.state.status : '')
    // const token = location.search.split('=')
    const subdomain = LocalStorage.getURLNAME() == "localhost" ? 'jeedimetla' : LocalStorage.getURLNAME()

    useEffect(() => {
        let tokenData = location.search.split('=')
        let data = {
            request_id: LocalStorage.uid(),
            subdomain: domain,
            token: tokenData[1]
        }
        if(location.state && location.state.status){}
        else{
            CommonApi.verifyAccount(data).then(
                (res) => {
                    if (res.data.statusCode == 1003) {
    
                        if (res.data.already_verified) {
                            navigate('login')
                        }
                        else if (res.data.link_expires == false) {
                            setState({
                                ...state,
                                email: res.data.email_id,
                                subdomain_name: res.data.subdomain,
                                token: res.data.temp_access_token
                            })
                            setStatus('verifySucess')
                            setTimeout(() => {
                                navigate('/create-password', {
                                    state: {
                                        data: {
                                            domain: subdomain,
                                            token: res.data.temp_access_token,
                                            email: res.data.email_id,
                                            subdomain_name: res.data.subdomain
                                        }
                                    }
                                })
                            }, 2000);
                        }
                        else if (res.data.link_expires) {
                            setState({
                                ...state,
                                email: res.data.email_id,
                                subdomain_name: res.data.subdomain,
                                token: res.data.temp_access_token
                            })
                            setStatus('linkExpired')
                        }
                        else {
                            setState({
                                ...state,
                                email: res.data.email_id,
                                subdomain_name: res.data.subdomain,
                                token: res.data.temp_access_token
                            })
                            setStatus('attempts')
                        }
                    }
                    else {
                        addErrorMsg(res.data.message)
                        // navigate('login')
                    }
    
                }
            )
        }
      
        // eslint-disable-next-line
    }, [])

    const resend = () => {
        const data = {
            request_id: LocalStorage.uid(),
            subdomain_name: state.subdomain_name ? state.subdomain_name : domain
        }
        CommonApi.resendEmail(data).then((response) => {
            if (response.data.statusCode == 1003) {
                if(response.data.allow_re_attempt == false){
                    setStatus('attempts')
                }
                else{
                    addSuccessMsg(`Email Sent`);
                }
                // setOpen(true);
            } else {
                addErrorMsg(response.data.message);
            }
        })
    };

    let array = [
        `Hang tight! We're setting up your access, it takes less than a minute.`,
        `Did you know? Automation and AI reshape employee management with predictive analytics.`,
        `Did you know? Employee management traces back to ancient civilizations like Rome, using tablets for records`,
        `Almost there! Providing some finishing touches..`
    ]
    // eslint-disable-next-line
    const [images, setImages] = useState(array);
    const [currentImage, setCurrentImage] = useState(0);

    function changeBackgroundImage() {
        if (currentImage == 0) {
            setTimeout(() => {
                setCurrentImage(1)
            }, 3000)
        }
        else if (currentImage == 1) {
            setTimeout(() => {
                setCurrentImage(2)
            }, 3000)
        }
        else if (currentImage == 2) {
            setTimeout(() => {
                setCurrentImage(3)
            }, 3000)
        }
        else if (currentImage == 3) {
            setTimeout(() => {
                setCurrentImage(0)
            }, 3000)
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => changeBackgroundImage(), 2000);
        return () => clearInterval(intervalId);
    });

    return (
        <Grid container pt={2} p={4}>
            {
                status == '' ?
                    <Grid container justifyContent={'center'} alignContent={'center'} height={'100vh'}>
                        <Grid item container justifyContent={'center'} alignContent={'center'}>
                            <img src={loaderGIF} alt='loader' />
                        </Grid>
                        <Grid item container justifyContent={'center'} alignContent={'center'}>
                            <Box p={1} pb={0}>
                                <Text grey16>{array[currentImage]}</Text>
                            </Box>
                        </Grid>
                    </Grid>
                    :
                    status == 'attempts' ?
                        <Grid container>
                            <Grid item xs={12}>
                                <img src={logo} alt='logo' />
                            </Grid>
                            <Grid item xs={12}>
                                <Box height={'15vh'} />
                            </Grid>
                            <Grid item container xs={12} justifyContent={'center'} alignItems={'center'}>
                                <img src={attempts} alt='attempts' />
                            </Grid>
                            <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                <Box p={1} pt={4}>
                                    <Text popupHead1 sx={{ color: '#090914 !important' }}>Out of attempts!</Text>
                                </Box>
                            </Grid>
                            <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                <Box p={1} pb={0}>
                                    <Text grey16>It looks like you've exceeded the verification limit.</Text>
                                </Box>
                            </Grid>
                            <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                <Box >
                                    <Text grey16>Please wait for 24hrs or provide a detailed explanation</Text>
                                </Box>
                            </Grid>
                            <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                <Box >
                                    <Text grey16>of the problem in E-mail so that our sales staff can </Text>
                                </Box>
                            </Grid>
                            <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                <Box >
                                    <Text grey16>respond asap.</Text>
                                </Box>
                            </Grid>
                            <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                <Box className={classes.buttonStyles}>
                                    <LoadingButton
                                        className={classes.loginButton}
                                        loading={loader}
                                        variant="contained"
                                        type="submit"
                                        href={'mailto:support@japfu.ai'}
                                    >
                                        Send E-mail
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Grid>
                        :
                        status == 'linkExpired' ?
                            <Grid container>
                                <Grid item xs={12}>
                                    <img src={logo} alt='logo' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box height={'15vh'} />
                                </Grid>
                                <Grid item container xs={12} justifyContent={'center'} alignItems={'center'}>
                                    <img src={attempts} alt='attempts' />
                                </Grid>
                                <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                    <Box p={1} pt={4}>
                                        <Text popupHead1 sx={{ color: '#090914 !important' }}>Session Expired</Text>
                                    </Box>
                                </Grid>
                                <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                    <Box p={1} pb={0}>
                                        <Text grey16>It seems your verification session has expired. Don't </Text>
                                    </Box>
                                </Grid>
                                <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                    <Box >
                                        <Text grey16>worry, we've got you covered. Just click the resend</Text>
                                    </Box>
                                </Grid>
                                <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                    <Box >
                                        <Text grey16>button below to get a new verification E-mail. </Text>
                                    </Box>
                                </Grid>
                                <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                    <Box className={classes.buttonStyles}>
                                        <LoadingButton
                                            className={classes.loginButton}
                                            loading={loader}
                                            variant="contained"
                                            type="submit"
                                            onClick={() => { resend() }}
                                        >
                                            Resend Verification Email
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </Grid>
                            :
                            status == 'verifySucess' ?
                                <Grid container>
                                    <Grid item xs={12}>
                                        <img src={logo} alt='logo' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box height={'18vh'} />
                                    </Grid>
                                    <Grid item container xs={12} justifyContent={'center'} alignItems={'center'}>
                                        <img src={signupVerify} alt='attempts' />
                                    </Grid>
                                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                        <Box p={1}>
                                            <Text popupHead1 sx={{ color: '#090914 !important' }}>Verification Successful</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                    <Box p={1}>
                                            <Text offBoardBodyFont>Hang tight! We're setting up your access, it takes less than a minute.</Text>
                                        </Box>
                                    
                                    </Grid>
                                    {/* <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                    <Box className={classes.buttonStyles}>
                                        <LoadingButton
                                            className={classes.loginButton}
                                            loading={loader}
                                            variant="contained"
                                            onClick={() => {
                                                navigate('/create-password', {
                                                    state: {
                                                        data: {
                                                            domain: subdomain,
                                                            token: state.token,
                                                            email: state.email,
                                                            subdomain_name: state.subdomain_name
                                                        }
                                                    }
                                                })
                                            }}
                                        >
                                            Create Password
                                        </LoadingButton>
                                    </Box>
                                </Grid> */}
                                </Grid>
                                :
                                <Grid container>
                                    <Grid item xs={12}>
                                        <img src={logo} alt='logo' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box height={'20vh'} />
                                    </Grid>
                                    <Grid item container xs={12} justifyContent={'center'} alignItems={'center'}>
                                        <img src={signupVerify} alt='logo' />
                                    </Grid>
                                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                        <Box p={1}>
                                            <Text popupHead1 sx={{ color: '#090914 !important' }}>We will get back to you soon</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                        <Box pt={1}>
                                            <Text grey16>Our sales team has received your request, they will </Text>
                                        </Box>
                                    </Grid>
                                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                        <Box p={0}>
                                            <Text grey16>reach out to you asap. This might take few hours.</Text>
                                        </Box>
                                    </Grid>
                                </Grid>
            }


        </Grid>
    )
}

export default VerifyAccount