import React, { useState, useEffect } from 'react';
import ArrowLeft from '../../../../assets/svg/fi_arrow-left.svg'
import raisedImage from "../../../../assets/images/raised.png"
import approvedImage from "../../../../assets/images/deduction.png"
import approvalImage from "../../../../assets/images/paidBalancesheet.png"
import totalImage from "../../../../assets/images/reimburesementImg.png"
import Typography from '@mui/material/Typography';
import { Box, Stack, Grid, Checkbox, SwipeableDrawer, Divider, Badge, Chip, FormControlLabel, Slider, IconButton, Avatar, tableCellClasses, Menu, MenuItem } from '@mui/material';
import { styled } from "@mui/material/styles";
import Skeleton from '@mui/material/Skeleton';
import NewDownloadcloud from '../../../../assets/svg/download-cloud-new.svg';
import { addErrorMsg, addLoader, addLoaderPlanExpired, BlackToolTip, capitalizeAndAddSpace, dateFormat, downloadExcel, getCurrencySymbol, NoDataFound, removeExpiredLoader, removeLoader } from '../../../../utils/utils';
import { useLocation, useNavigate } from "react-router-dom";
import Text from '../../../../components/customText/Text';
import Button from '../../../../components/customButton/Button';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/cross.svg';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LocalStorage from '../../../../utils/LocalStorage';
import infoCircle from '../../../../assets/images/info-circle.png';
import FilterListIcon from '@mui/icons-material/FilterList';
import PlacementDashboardStyles from '../../placements/newDashboard/PlacementDashboardStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import nofilterplacement from '../../../../assets/svg/nofilterplacement.svg';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import { domain } from '../../../../config/Domain';
import DashboardAPI from '../../../../apis/admin/DashboardAPI';
import Input from '../../../../components/input/Input';
import PayrollApi from '../../../../apis/admin/payroll/ExpenseManagement';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import CurrentBalancePopup from '../payrollTable/CurrentBalancePopup';
import ClientsApi from '../../../../apis/admin/clients/ClientsApi';
import CommonApi from '../../../../apis/CommonApi';
import GeneratePayrollApi from '../../../../apis/configurations/generatePayroll/GeneratePayrollApi';
import Calendar from '../../../../assets/svg/calendar.svg';
import CalanderOrange from '../../../../assets/svg/calendar_orange.svg';
import CalanderBlack from '../../../../assets/svg/calendar_black.svg';
import Search from '../../../../assets/svg/search1.svg';
import ClearIcon from '@mui/icons-material/Clear';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as CommentRead } from '../../../../assets/svg/comment_read.svg';
import GeneratePayrollStyles from '../generatePayroll/GeneratePayrollStyles';
import EarnedAmountPopup from '../payrollTable/EarnedAmountPopup';
import SalaryAmountDrawer from '../generatePayroll/SalaryAmountDrawer';
import DeductionsDrawer from '../generatePayroll/DeductionsDrawer';
import ReimbursementsDrawer from '../generatePayroll/ReimbursementsDrawer';
import { onNumberWithTwoDecimalOnlyChange } from '../../../../components/Validation';
import CommentsDrawer from '../generatePayroll/CommentsDrawer';
import Date from '../../../../components/datePicker/Date';
import calendar from '../../../../assets/svg/payroll/calendar.svg';
import closeIcon from '../../../../assets/svg/payroll/close-Icon.svg';
import DashboardApi from '../../../../apis/admin/payroll/DashboardApi';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.black,
        font: '14px',
        backgroundColor: "#FBFBFB",
        padding: "6px 16px",
        fontFamily: "Quicksand !important",
    },
    [`&.${tableCellClasses.body}`]: {
        padding: "6px 16px",
        color: "#000000",
        font: '14px',
        fontFamily: "Quicksand !important",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "&:td blue": {
        backgroundColor: "red !important",
    },
}));



export default function PayrollSummary() {
    const navigate = useNavigate();

    const classesGenerate = GeneratePayrollStyles()
    const [commentedOn, setCommentedOn] = useState(false);
    const [commentsList, setCommentsList] = useState([]);
    const [commentRow, setCommentRow] = useState({ comments: [] });
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const cardsJson = [
        {
            name: 'Earned Amount',
            imgPath: raisedImage,
            mainKey: 'total_earned_amount',
            color: '#E7F2FD'
        },
        {
            name: 'Total Reimbursements',
            imgPath: approvedImage,
            mainKey: 'total_deduction_amount',
            color: '#EAF9EB'
        },
        {
            name: 'Total Deductions',
            imgPath: approvalImage,
            mainKey: 'total_salary_amount',
            color: '#FFF8EA'
        },
        {
            name: 'Salary Amount',
            imgPath: totalImage,
            mainKey: 'total_reimbursement_amount',
            color: '#F3F0FF'
        }
    ]
    const [currentOpen, setCurrentOpen] = useState(false);
    const [currentBalanceRow, setCurrentBalanceRow] = useState({});
    const [tableData, setTabledata] = useState([]);
    const [payrollData, setpayrollData] = useState({});
    const [payrollConfigId, setPayRollConfigId] = useState('');
    const [reimbursementPayPeriodDropDown, setReimbursementPayPeriodDropDown] = useState([]);
    const [reimbursementTypeDropDown, setReimbursementTypeDropDown] = useState([]);
    const [deductionTypeDropDown, setDeductionTypeDropDown] = useState([]);
    ;

    const visaTypes = ["H-1B", "E-3", "H-1B1", "E3", "H1B1", "H1B"];


    const getReimbursementPayPeriodDropdown = () => {
        GeneratePayrollApi.ReimbursementPayPeriodDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setReimbursementPayPeriodDropDown(res.data.data);
            }
        })
    }

    const getReimbursementTypeDropdown = () => {
        GeneratePayrollApi.ReimbursementTypeDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setReimbursementTypeDropDown(res.data.data);
            }
        })
    }

    const getDeductionTypeDropdown = () => {
        GeneratePayrollApi.DeductionTypeDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setDeductionTypeDropDown(res.data.data);
            }
        })
    }

    const getAllEmployees = (payrollId) => {
        setLoading(true)
        GeneratePayrollApi.getAllEmployees(filterData, payrollId).then((res) => {
            removeLoader()
            if (res.data.statusCode === 1003) {
                setDrawer(null);
                setLoading(false)
                setTimeout(() => {
                    setTabledata(res.data.data);
                    setpayrollData(res.data.payroll_data);
                    setFinalizedEmployees(res.data.data.filter(item => item.is_finalized === true));
                    setNonFinalizedEmployees(res.data.data.filter(item => item.is_finalized === false));
                }, 500);
            }
            else {
                addErrorMsg(res.data.message);
                setChoosePeriod(true);
                setLoading(false)
            }
        })
    }


    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = PlacementDashboardStyles();// eslint-disable-next-line
    const [pagination, setPagination] = useState({ total: "6" });
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [selectedOption, setSelectedOption] = useState(1);// eslint-disable-next-line
    const [finalizedEmployees, setFinalizedEmployees] = useState(() => {
        return tableData.filter(item => item.is_finalized === true);
    });
    const [nonfinalizedEmployees, setNonFinalizedEmployees] = useState(() => {
        return tableData.filter(item => item.is_finalized === false);
    });

    const [filterData, setFilterData] = useState({
        limit: '',
        page: '',
        search: "",
        sort_column: "created_at",
        sort_order: "desc",
        clients: [],
        visa: [],
        earned_amount_from: "",
        earned_amount_to: "",
        reimbursement_amount_from: '',
        reimbursement_amount_to: '',
        salary_amount_from: "",
        salary_amount_to: "",
        deduction_amount_from: "",
        deduction_amount_to: "",
        balance_amount_from: "",
        balance_amount_to: "",
        employee_id: LocalStorage.getUserData().login_id
    })
    const [clientDropdown, setClientDropdown] = useState([]);
    const [visaTypeDropdown, setVisaTypeDropdown] = useState([]);

    const [filterState, setFilterState] = useState({
        search: "",
        clients: [],
        visa: [],
        earned_amount_from: "",
        earned_amount_to: "",
        reimbursement_amount_from: '',
        reimbursement_amount_to: '',
        salary_amount_from: "",
        salary_amount_to: "",
        deduction_amount_from: "",
        deduction_amount_to: "",
        balance_amount_from: "",
        balance_amount_to: "",
    });


    const [datesError, setDatesError] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        pay_period_from: "",
        pay_period_to: "",
        pay_check_date_from: "",
        pay_check_date_to: "",
        earned_amount_from: "",
        earned_amount_to: "",
        approved_amount: "",
        reason_for_rejection: ""
    })
    const [payrollCycles, setPayrollCycles] = useState('');
    useEffect(() => {
        if ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) {
            clientDropdownApi()
            getAllmasterData()
            setPayRollConfigId(location.state.id)
            getAllEmployees(location.state.id)
        }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        getPlanCheck();
        getReimbursementPayPeriodDropdown();
        getReimbursementTypeDropdown();
        getDeductionTypeDropdown();
        getpayrollCycles()
        // eslint-disable-next-line
    }, [])

    const clientDropdownApi = () => {
        ClientsApi.dropdown('client', "").then((res) => {
            if (res.data.statusCode === 1003) {
                setClientDropdown(res.data.data);
            }
        })
    }

    const getpayrollCycles = () => {
        DashboardApi.getpayrollCycles().then((res) => {
            if (res.data.statusCode === 1003) {
                setPayrollCycles(res.data)
            }
        })
    }


    const getAllmasterData = () => {
        CommonApi.getAllMasterData(LocalStorage.uid()).then((response) => {
            if (response.data.statusCode == 1003) {
                setVisaTypeDropdown(response.data.data.visa_types);
            } else {
                addErrorMsg(response.data.message);
            }
        });
    }

    const getPlanCheck = () => {
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
            }
        })
    }

    const handleDownloadExport = () => {
        addLoader(true);
        filterData.request_id = LocalStorage.uid()
        PayrollApi.downLoadBalanceSheetExcel(filterData).then((response) => {
            removeLoader();
            if (response?.data instanceof Blob) {
                const blob = response.data;
                const formattedDate = moment().format('DDMMYYhhmmssSSS');
                let filename = `BalanceSheet_${formattedDate}.xlsx`
                downloadExcel(blob, filename)
            }
            else {
                addErrorMsg(response.data.message)
            }
        });
    }

    const handleDeleteChip = (id, name) => {
        if (name === "pay_period" || name === "balance_impact" || name === "pay_check_date" || name === "current_balance") {
            const updatedFilterState = { ...filterState }; // Create a copy of the filterState object

            // Update the start_date or end_date fields
            updatedFilterState[name + "_from"] = "";
            updatedFilterState[name + "_to"] = "";

            // Update the state with the modified filterState object
            setFilterState(updatedFilterState);
        } else {
            const newFilterState = { ...filterState };
            const updatedArray = newFilterState[name].filter(item => item !== id);
            newFilterState[name] = updatedArray;
            setFilterState(newFilterState);
        }
    };

    const handleChangeCheckBox = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        const updatedArray = [...filterState[name]]; // Create a new array
        var finalValue = value;
        if (e.target.name !== "status") {
            finalValue = isNaN(Number(value)) ? value : Number(value);
        }
        const index = updatedArray.findIndex(item => item === finalValue);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(finalValue); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const clearAllFilter = () => {
        datesError.start_date_from = '';
        datesError.start_date_to = '';
        datesError.end_date_from = '';
        datesError.end_date_to = '';
        setDatesError({ ...datesError });
        setFilterState(prevState => ({
            ...prevState,
            clients: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            visa: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            salary_amount_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            salary_amount_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            balance_amount_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            balance_amount_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            earned_amount_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            earned_amount_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            deduction_amount_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            deduction_amount_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            reimbursement_amount_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            reimbursement_amount_to: '' // Update the state with the new array
        }));
    }

    const cancelFilter = () => {
        setDrawer(false);
    }

    const handleListItemClick = (param) => {
        setSelectedFilter(param);
    }

    const handleApplyFilters = () => {
        if (filterState.earned_amount_from != "" && filterState.earned_amount_to == "") {
            datesError.earned_amount_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.earned_amount_from == "" && filterState.earned_amount_from != 0) && filterState.earned_amount_to != "") {
            datesError.earned_amount_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.earned_amount_from !== "" && filterState.earned_amount_to !== "") {
            if (filterState.earned_amount_from < filterState.earned_amount_to) {
                filterData.earned_amount_from = filterState.earned_amount_from;
                filterData.earned_amount_to = filterState.earned_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.earned_amount_from = '';
            filterData.earned_amount_to = '';
        }
        if (filterState.reimbursement_amount_from != "" && filterState.reimbursement_amount_to == "") {
            datesError.reimbursement_amount_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.reimbursement_amount_from == "" && filterState.reimbursement_amount_from != 0) && filterState.reimbursement_amount_to != "") {
            datesError.reimbursement_amount_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.reimbursement_amount_from !== "" && filterState.reimbursement_amount_to !== "") {
            if (filterState.reimbursement_amount_from < filterState.reimbursement_amount_to) {
                filterData.reimbursement_amount_from = filterState.reimbursement_amount_from;
                filterData.reimbursement_amount_to = filterState.reimbursement_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.reimbursement_amount_from = '';
            filterData.reimbursement_amount_to = '';
        }
        if (filterState.salary_amount_from != "" && filterState.salary_amount_to == "") {
            datesError.salary_amount_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.salary_amount_from == "" && filterState.salary_amount_from != 0) && filterState.salary_amount_to != "") {
            datesError.salary_amount_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.salary_amount_from !== "" && filterState.salary_amount_to !== "") {
            if (filterState.salary_amount_from < filterState.salary_amount_to) {
                filterData.salary_amount_from = filterState.salary_amount_from;
                filterData.salary_amount_to = filterState.salary_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.salary_amount_from = '';
            filterData.salary_amount_to = '';
        }
        if (filterState.deduction_amount_from != "" && filterState.deduction_amount_to == "") {
            datesError.deduction_amount_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.deduction_amount_from == "" && filterState.deduction_amount_from != 0) && filterState.deduction_amount_to != "") {
            datesError.deduction_amount_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.deduction_amount_from !== "" && filterState.deduction_amount_to !== "") {
            if (filterState.deduction_amount_from < filterState.deduction_amount_to) {
                filterData.deduction_amount_from = filterState.deduction_amount_from;
                filterData.deduction_amount_to = filterState.deduction_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.deduction_amount_from = '';
            filterData.deduction_amount_to = '';
        }
        if (filterState.balance_amount_from != "" && filterState.balance_amount_to == "") {
            datesError.balance_amount_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.balance_amount_from == "" && filterState.balance_amount_from != 0) && filterState.balance_amount_to != "") {
            datesError.balance_amount_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.balance_amount_from !== "" && filterState.balance_amount_to !== "") {
            if (filterState.balance_amount_from < filterState.balance_amount_to) {
                filterData.balance_amount_from = filterState.balance_amount_from;
                filterData.balance_amount_to = filterState.balance_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.balance_amount_from = '';
            filterData.balance_amount_to = '';
        }
        filterData.clients = filterState.clients;
        filterData.visa = filterState.visa;
        setFilterData({ ...filterData });
        addLoader(true)
        getAllEmployees(payrollConfigId)
    }
    // eslint-disable-next-line
    const loadMoreData = () => {
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });

    }

    const [viewClientEarnedAmount, setViewClientEarnedAmount] = useState({});
    const [earnedOpen, setEarnedOpen] = useState(false);



    const handleEarnedAmountData = (param) => {
        setViewClientEarnedAmount(param);
        setEarnedOpen(true);
    }

    const handleSliderChangeRaised = (event, newValue) => {
        filterState.earned_amount_from = newValue[0]
        filterState.earned_amount_to = newValue[1]
        setFilterState({ ...filterState })
    };

    const handleSliderChangeCurrent = (event, newValue) => {
        filterState.balance_amount_from = newValue[0]
        filterState.balance_amount_to = newValue[1]
        setFilterState({ ...filterState })
    };

    const handleSliderChangeSalary = (event, newValue) => {
        filterState.salary_amount_from = newValue[0]
        filterState.salary_amount_to = newValue[1]
        setFilterState({ ...filterState })
    };

    const handleSliderChangeReimbursement = (event, newValue) => {
        filterState.reimbursement_amount_from = newValue[0]
        filterState.reimbursement_amount_to = newValue[1]
        setFilterState({ ...filterState })
    };

    const handleSliderChangeDeduction = (event, newValue) => {
        filterState.deduction_amount_from = newValue[0]
        filterState.deduction_amount_to = newValue[1]
        setFilterState({ ...filterState })
    };

    const handleInputChange = (index, value, name) => {
        if (name) {
            if (index == 0) {
                filterState[name] = value ? Number(value) : '';
            }
            else {
                filterState[name] = value ? Number(value) : '';
            }
        }
        else {
            if (index == 0) {
                filterState.earned_amount_from = value ? Number(value) : '';
            }
            else {
                filterState.earned_amount_to = value ? Number(value) : '';
            }
        }

        setFilterState({ ...filterState })
    };

    const handleFilterDrawerClose = () => {
        filterState.clients = []
        filterState.deducted_or_added_to = [];
        filterState.pay_period_from = '';
        filterState.pay_period_to = '';
        filterState.pay_check_date_from = '';
        filterState.pay_check_date_to = '';
        filterState.earned_amount_from = '';
        filterState.earned_amount_to = '';
        setFilterState({ ...filterState });
        setDrawer(null);
    }

    const handleSelectChange = (param) => {
        setSelectedOption(param)
    }
    const [active, setActive] = useState(0);
    const [salaryOpen, setSalaryOpen] = useState(false);
    const [timesheetList, setTimesheetList] = useState([]);
    const [reimbursementDeduction, setReimbursementDeduction] = useState({});
    const [remDrawer, setRemDrawer] = useState(false);
    const [deductionDrawer, setDeductionsDrawer] = useState(false);
    const [currentSalaryRow, setCurrentSalaryRow] = useState({});
    const [balanceSheet, setBalanceSheet] = useState([]);
    const [allBalanceSheet, setAllBalanceSheet] = useState([]);


    const getTimeSheetofEmployee = (param) => {
        GeneratePayrollApi.Timesheet(payrollConfigId, param?.employee_id).then((res) => {
            if (res.data.statusCode === 1003) {
                setTimesheetList(res.data.data);


            }
        })
    }
    const [clickedEmpId, setClickedEmpId] = useState('');

    const getReimbursementandDeduction = (param, text) => {
        setClickedEmpId(param?.employee_id);
        GeneratePayrollApi.GetReimbursementDeductions(payrollConfigId, param?.employee_id).then((res) => {
            if (res.data.statusCode === 1003) {
                setReimbursementDeduction(res.data.data);
                if (text == 'reimbursement') {
                    setRemDrawer(true);
                }
                if (text == 'deduction') {
                    setDeductionsDrawer(true);
                }
            }
        })
    }

    const handleReimbursements = (param) => {
        getReimbursementandDeduction(param, 'reimbursement')

    }

    const handleDeduction = (param) => {
        getReimbursementandDeduction(param, 'deduction')

    }



    const getAllBalanceSheetsOfEmployee = (param) => {
        const data = {
            employee_id: param?.employee_id
        }
        GeneratePayrollApi.GetBalanceSheet(data).then((res) => {
            if (res.data.statusCode === 1003) {
                setAllBalanceSheet(res.data.data);

            }
        })
    }
    const getDetailsOfEmployee = (param) => {
        setActive(0)
        setSalaryOpen(true);
        getTimeSheetofEmployee(param);
        getReimbursementandDeduction(param, '');
        getAllBalanceSheetsOfEmployee(param);


    }

    const handleSalaryData = (param) => {
        setCurrentSalaryRow(param);
        getDetailsOfEmployee(param);
    }

    const [drawer, setDrawer] = useState(false);

    const handleOpenDrawer = () => {
        setDrawer("filter");
        if (filterData.earned_amount_from !== "" && filterData.earned_amount_to !== "") {
            filterState.earned_amount_from = filterData.earned_amount_from;
            filterState.earned_amount_to = filterData.earned_amount_to;
            setSelectedFilter(3);
        } else {
            filterState.earned_amount_from = '';
            filterState.earned_amount_to = '';
        }
        if (filterData.reimbursement_amount_from !== "" && filterData.reimbursement_amount_to !== "") {
            filterState.reimbursement_amount_from = filterData.reimbursement_amount_from;
            filterState.reimbursement_amount_to = filterData.reimbursement_amount_to;
            setSelectedFilter(5);
        } else {
            filterState.reimbursement_amount_from = '';
            filterState.reimbursement_amount_to = '';
        }
        if (filterData.salary_amount_from !== "" && filterData.salary_amount_to !== "") {
            filterState.salary_amount_from = filterData.salary_amount_from;
            filterState.salary_amount_to = filterData.salary_amount_to;
            setSelectedFilter(4);
        } else {
            filterState.salary_amount_from = '';
            filterState.salary_amount_to = '';
        }
        if (filterData.deduction_amount_from !== "" && filterData.deduction_amount_to !== "") {
            filterState.deduction_amount_from = filterData.deduction_amount_from;
            filterState.deduction_amount_to = filterData.deduction_amount_to;
            setSelectedFilter(6);
        } else {
            filterState.deduction_amount_from = '';
            filterState.deduction_amount_to = '';
        }
        if (filterData.balance_amount_from !== "" && filterData.balance_amount_to !== "") {
            filterState.balance_amount_from = filterData.balance_amount_from;
            filterState.balance_amount_to = filterData.balance_amount_to;
            setSelectedFilter(7);
        } else {
            filterState.balance_amount_from = '';
            filterState.balance_amount_to = '';
        }
        if (filterData.clients.length > 0) {
            setSelectedFilter(1);
        }
        if (filterData.visa.length > 0) {
            setSelectedFilter(2);
        }
        filterState.clients = filterData.clients;
        filterState.visa = filterData.visa;
        setFilterState({ ...filterState });
        datesError.start_date_to = "";
        datesError.end_date_to = "";
        setDatesError({ ...datesError })
    }

    const handleSearch = (e) => {
        filterData.search = e.target.value;
        setFilterData({ ...filterData });
        getAllEmployees(payrollConfigId);
    }

    const handleSearchClear = () => {
        filterData.search = '';
        setFilterData({ ...filterData });
        getAllEmployees(payrollConfigId);
    }

    const getBalanceSheetOfEmployee = (param) => {
        const data = {
            employee_id: param?.employee_id,
            pay_period_from: payrollData?.from_date,
            pay_period_to: payrollData?.to_date
        }
        GeneratePayrollApi.GetBalanceSheet(data).then((res) => {
            if (res.data.statusCode === 1003) {
                setBalanceSheet(res.data.data)

            }
        })
    }
    const handleCurrentBalanceData = (param) => {

        setCurrentBalanceRow(param);
        getBalanceSheetOfEmployee(param);

        setCurrentOpen(true);
    }

    const handleCommentOpen = (param) => {
        setCommentedOn(true);
        setCommentRow(param);
        getCommentsOfEmployee(param.payroll_payment_detail_id)
    }

    const getCommentsOfEmployee = (paymentId) => {
        GeneratePayrollApi.Comments(paymentId).then((res) => {
            if (res.data.statusCode === 1003) {
                setCommentsList(res.data.data);
                setTimeout(() => {
                }, 300);
            }
        })
    }

    const handleCloseCommentDrawerAndUpdate = () => {
        setCommentedOn(false);
        getAllEmployees(payrollConfigId);
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)
    const [state, setState] = useState({
        pHStartDate: '',
        pHEndDate: '',
        pHTemporaryStartDate: '',
        pHTemporaryEndDate: '',
    })
    const [choosePeriod, setChoosePeriod] = useState(true);

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d;
        if (!date) {
            addErrorMsg("Please select a valid date");
            return;
        }

        const formattedInputDate = moment(date, dateFormat()); // Ensure consistent formatting
        console.log("Selected date: ", formattedInputDate.format());

        // Find matching payroll cycle
        const match = payrollCycles.data.find(({ from_date, to_date }) => {
            const fromDate = moment(from_date, dateFormat());
            const toDate = moment(to_date, dateFormat());
            return formattedInputDate.isBetween(fromDate, toDate, 'day', '[]');
        });

        if (match) {
            const { from_date: startDate, to_date: endDate } = match;
            console.log("Match found: ", { startDate, endDate });

            // Only update if the new range is different
            setState((prevState) => {
                if (
                    prevState.pHTemporaryStartDate === startDate &&
                    prevState.pHTemporaryEndDate === endDate
                ) {
                    return prevState; // Avoid unnecessary re-renders
                }
                return {
                    ...prevState,
                    pHTemporaryStartDate: startDate,
                    pHTemporaryEndDate: endDate,
                };
            });

            return { startDate, endDate };
        } else {
            console.log("No match found for the selected date.");
            setState((prevState) => ({
                ...prevState,
                pHTemporaryStartDate: '',
                pHTemporaryEndDate: '',
            }));
            addErrorMsg(
                "Based on the provided 'Start Date' or 'End Date', no payroll cycle exists"
            );
            return null; // Handle cases where the date is not found
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setChoosePeriod(true);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const applyFilter = () => {
        if (state.pHTemporaryStartDate !== '' && state.pHTemporaryEndDate !== '') {
            const match = payrollCycles?.data.find(item => item.to_date === state.pHTemporaryEndDate);
            if (match) {
                setPayRollConfigId(match.id);
                getAllEmployees(match.id)
            } else {
                addErrorMsg(
                    "Based on the provided 'Start Date' or 'End Date', no payroll cycle exists"
                );
            }
        }
        else {
            addErrorMsg(
                "Based on the provided 'Start Date' or 'End Date', no payroll cycle exists"
            );
        }
    }

    return (
        <Grid container pl={'130px'} pr={'65px'}>
            <Grid item lg={12} pb={2}>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                    {
                        LocalStorage.getUserData().admin_login ?
                            <IconButton disableRipple onClick={() => { navigate('/payrollDashboard') }}>
                                <img src={ArrowLeft} alt='ArrowLeft'></img>
                            </IconButton> : ''
                    }

                    <Text blackHeader600 >Payroll Summary</Text>
                </Box>
            </Grid>
            <Grid item xs={12} pb={2}>

                <Box style={{ padding: "0px 10px 10px 20px" }}>

                    <Box mt={2} p={2} sx={{ background: " #FAFAFA", borderRadius: "8px" }}>
                        <Grid container>
                            <Grid container alignItems={"center"} item lg={7} md={6} sm={12} xs={12} columnGap={1}>
                                <img src={Calendar} alt="Calendar" />
                                {
                                    loading ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                        :
                                        <Text blue14>Pay Period - </Text>
                                }

                                {
                                    loading ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} /> : choosePeriod ?
                                        <Box display='flex' flexDirection='row' gap={1} className={classes.choosePeriod}>
                                            <Box><Text offBoardBodyFont>{payrollData?.from_date} - {payrollData?.to_date}</Text></Box>
                                            <Box><img src={closeIcon} alt='close' style={{ cursor: 'pointer' }} onClick={() => setChoosePeriod(false)} /></Box>
                                        </Box> :
                                        <>
                                            <Button
                                                choosePeriod
                                                id="basic-button"
                                                aria-controls={open ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={handleClick}
                                            >
                                                <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                                    <Text offBoardBodyFont>Choose period</Text>
                                                    <img src={calendar} alt='calendar' />
                                                </Box>
                                            </Button>
                                            <Menu
                                                anchorEl={anchorEl}
                                                id="account-menu"
                                                open={open}
                                                onClose={() => handleClose()}
                                                MenuListProps={{
                                                    'aria-labelledby': 'account-menu',
                                                }}
                                                style={{ left: "-52px", top: '20px', width: '100%' }}
                                            >
                                                <MenuItem
                                                    disableRipple={true}
                                                    sx={{ backgroundColor: 'transparent !important' }}>
                                                    <Grid item xs={12}>
                                                        <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                                            <Box>
                                                                <Date
                                                                    labelText='Start Date'
                                                                    name='pHTemporaryStartDate'
                                                                    value={state.pHTemporaryStartDate}
                                                                    minDate={payrollCycles.min_date}
                                                                    maxDate={payrollCycles.max_date}
                                                                    onChange={(value => dateChange(value, 'pHTemporaryStartDate'))}
                                                                    height='45px'
                                                                />
                                                            </Box>
                                                            <Box>
                                                                -
                                                            </Box>
                                                            <Box>
                                                                <Date
                                                                    labelText='End Date'
                                                                    name='pHTemporaryEndDate'
                                                                    value={state.pHTemporaryEndDate}
                                                                    disabled={true}
                                                                    minDate={state.pHTemporaryStartDate}
                                                                    maxDate={payrollCycles.max_date}
                                                                    onChange={(value => dateChange(value, 'pHTemporaryEndDate'))}
                                                                    height='45px'
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Grid item xs={12}>
                                                            <Box my={2}>
                                                                <Divider />
                                                            </Box>
                                                            <Box display='flex' flexDirection='row' gap={1} justifyContent='space-between'>
                                                                <Button payrollHistoryCancel onClick={handleClose}>Cancel</Button>
                                                                <Button blueButton onClick={applyFilter}>Apply Filter</Button>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </MenuItem>
                                            </Menu>
                                        </>
                                }
                            </Grid>

                            <Grid container alignItems={"center"} item lg={2.5} md={3} sm={6} xs={12} columnGap={1}>
                                <img src={CalanderBlack} alt="Calendar" />
                                <Text black16 sx={{ fontSize: "14px !important" }}>Today</Text>
                                <Text black14>&nbsp;-&nbsp; {moment().format('MM/DD/YYYY')}</Text>
                            </Grid>
                            <Grid container alignItems={"center"} item lg={2.5} md={3} sm={6} xs={12} columnGap={1}>
                                <img src={CalanderOrange} alt="Calendar" />
                                <Text largeOrange16 sx={{ fontSize: "14px !important", fontWeight: `${400} !important` }}>Check Date</Text>
                                <Text black14>&nbsp;- &nbsp;{payrollData?.check_date}</Text>
                            </Grid>
                        </Grid>
                    </Box>
                </Box></Grid>
            <Grid item container lg={12} gap={2}>
                {
                    cardsJson.map((item) => (
                        <Grid item container xl={2.88} md={2.8} xs={12} sx={{ backgroundColor: item.color, borderRadius: '12px ' }} alignContent={'center'} >
                            <Grid item xs={12}>
                                <Box p={2} minHeight={'110px'}>
                                    <Grid item xs={12}>
                                        <Text blackHeader1 sx={{ fontSize: '13.5px !important' }}>{item.name}</Text>
                                    </Grid>
                                    <Box p={2} height={'2vh'}></Box>
                                    <Grid item container xs={12}>
                                        <Grid item xs={4}>
                                            <Stack direction={'row'}>
                                                <img src={item.imgPath} alt={item.name} />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={8} container justifyContent={'flex-end'}>
                                            <Text largeBlack22>$&nbsp; {payrollData && payrollData[item.mainKey] ? payrollData[item.mainKey] : '0'}</Text>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
            <Grid item xs={12}>
                <Box className={classes.flexBox}>

                    <Box sx={{ width: "100%" }} >
                        <Box style={{ padding: "20px 10px 10px 10px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div>
                                    <Box className={classesGenerate.btnBox} my={2}>
                                        <Button onClick={() => handleSelectChange(1)} saveBtn className={selectedOption == 1 ? classesGenerate.activeButton : classesGenerate.inActiveButton}>Finalized </Button>
                                        <Button onClick={() => handleSelectChange(2)} saveBtn className={selectedOption == 2 ? classesGenerate.activeButton : classesGenerate.inActiveButton} sx={{ marginLeft: "8px" }}>Not Finalized </Button>
                                    </Box>
                                </div>

                                {
                                    ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingRight: "5px" }}>
                                                <Box display={"flex"} justifyContent={"center"}>
                                                    <Text offBoardBodyFont>
                                                        Count
                                                        <HtmlTooltip
                                                            placement="bottom"
                                                            arrow
                                                            title={
                                                                <React.Fragment>
                                                                    <Box>
                                                                        <Typography className={classes.profileTooltipText}>
                                                                            Total Employee count is <br />based on the filters applied
                                                                        </Typography>
                                                                    </Box>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                                        </HtmlTooltip>
                                                        {` - ${tableData?.length} Employees`}</Text>
                                                </Box>
                                                <div style={{ height: "44px", border: "1.5px solid #E2E5E6", width: "350px", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                                    <input
                                                        type="text"
                                                        value={filterData.search}
                                                        className={classesGenerate.searchInput}
                                                        onChange={handleSearch}
                                                        placeholder="Search by Name / ID / Client Name"
                                                    />
                                                    <Box sx={{ paddingRight: "15px !important" }}>
                                                        {
                                                            filterState.search !== "" ?
                                                                <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                                :
                                                                <img src={Search} alt="Search" />
                                                        }
                                                    </Box>
                                                </div>
                                                <button title="Export" type="button" className={classes.borderButton}
                                                    onClick={() => handleDownloadExport()}
                                                >
                                                    <img src={NewDownloadcloud} alt="Userplus" />
                                                </button>
                                                <button title="Filter"
                                                    type="button"
                                                    className={classes.borderButton}
                                                    onClick={() => { handleOpenDrawer() }}>
                                                    <Badge badgeContent={filterData.clients?.length + filterData.visa.length + ((filterData.balance_amount_from !== "" && filterData.balance_amount_to !== "") && 1) + ((filterData.earned_amount_from !== "" && filterData.earned_amount_to !== "") && 1) + ((filterData.reimbursement_amount_from !== "" && filterData.reimbursement_amount_to !== "") && 1) + ((filterData.salary_amount_from !== "" && filterData.salary_amount_to !== "") && 1) + ((filterData.deduction_amount_from !== "" && filterData.deduction_amount_to !== "") && 1)} color="error">
                                                        <FilterListIcon sx={{ color: (filterData.clients.length > 0 || filterData.visa.length > 0 || (filterData.reimbursement_amount_from !== "" && filterData.reimbursement_amount_to !== "") || (filterData.deduction_amount_from !== "" && filterData.deduction_amount_to !== "") || (filterData.earned_amount_from !== "" && filterData.earned_amount_to !== "") || (filterData.balance_amount_from !== "" && filterData.balance_amount_to !== "") || (filterData.salary_amount_from !== "" && filterData.salary_amount_to !== "")) ? "#2BEA2B" : "" }} />
                                                    </Badge>
                                                </button>
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>

                        </Box>

                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            {tableData.length > 0 ?
                                <Box sx={{ margin: 1 }}>
                                    <TableContainer sx={{ maxHeight: '54vh', overflow: 'scroll', width: '100%' }}>
                                        <Table size="small" stickyHeader aria-label="collapsible table" sx={{
                                            width: '100%',
                                            tableLayout: 'fixed',
                                        }}
                                        >
                                            <TableHead >
                                                <StyledTableRow>

                                                    <StyledTableCell sx={{ width: '275px' }}>Employee</StyledTableCell>
                                                    <StyledTableCell align="start" sx={{ minWidth: 130 }}>
                                                        Client Name
                                                    </StyledTableCell>
                                                    <StyledTableCell align="start" sx={{ minWidth: 100 }}>
                                                        Visa Type
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right" sx={{ minWidth: 120 }}>
                                                        Earned Amount
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right" sx={{ minWidth: 170 }}>
                                                        Salary Amount
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right" sx={{ minWidth: 120 }}>
                                                        Reimbursements
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right" sx={{ minWidth: 120 }}>
                                                        Deductions
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right" sx={{ minWidth: 140 }}>
                                                        Current Balance
                                                    </StyledTableCell>
                                                </StyledTableRow>

                                            </TableHead>

                                            <TableBody>
                                                {(selectedOption == 1 ? finalizedEmployees : nonfinalizedEmployees).map((row, index) => (
                                                    <StyledTableRow
                                                        sx={{
                                                            height: "74px !important",
                                                            boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)",
                                                            borderBottomLeftRadius: "20px",
                                                            borderBottomRightRadius: "20px",
                                                            '&:hover': {
                                                                borderRadius: '8px',
                                                                transition: "all .3s ease",
                                                                boxShadow: '0px 0px 15px 1px #cce9ffeb !important'
                                                            },

                                                            '& > *': {
                                                                borderBottom: 'none !important',
                                                                paddingTop: '10px !important',
                                                                paddingBottom: '10px !important',
                                                            },
                                                        }}

                                                    >


                                                        {/* Employee Details Column */}
                                                        <StyledTableCell sx={{ width: '280px', paddingRight: "0px !important" }} >
                                                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <Box sx={{ display: "flex", alignItems: "center", gap: 0.7 }}>
                                                                    <Box sx={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                        <Avatar
                                                                            alt={row.display_name}
                                                                            src={row.profile_picture_url}
                                                                            sx={{ width: 56, height: 56 }}
                                                                        />

                                                                    </Box>

                                                                    <Box >
                                                                        <Text mediumBoldBlack>
                                                                            {row.display_name && row.display_name.length > 16 ?
                                                                                <BlackToolTip title={capitalizeAndAddSpace(row.display_name)} placement="top" arrow>
                                                                                    {capitalizeAndAddSpace(row.display_name).slice(0, 16) + (capitalizeAndAddSpace(row.display_name.length > 16 ? "..." : ""))}
                                                                                </BlackToolTip>
                                                                                : capitalizeAndAddSpace(row.display_name)
                                                                            }
                                                                        </Text>
                                                                        <Text textAlign="start" greyLabel>
                                                                            Emp ID: {row?.emp_reference_id}
                                                                        </Text>
                                                                        <Text className={classesGenerate.viewDetails}
                                                                            onClick={() => { handleSalaryData(row) }}
                                                                            blue14>
                                                                            View Details
                                                                        </Text>
                                                                    </Box>
                                                                </Box>
                                                                {row.comments_exist ? <CommentRead
                                                                    onClick={() => { handleCommentOpen(row) }}
                                                                    className={classes.commentIcon} /> : ''}
                                                            </Box>

                                                        </StyledTableCell>

                                                        {/* Placement Info Columns */}
                                                        <StyledTableCell colSpan={3} sx={{ padding: 0 }}>
                                                            {row?.placements?.length > 0 ? (
                                                                row.placements.map((pld, index) => (
                                                                    <StyledTableRow
                                                                        key={index}
                                                                        sx={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            padding: 0,
                                                                        }}
                                                                    >
                                                                        <StyledTableCell
                                                                            align="start"
                                                                            sx={{
                                                                                flex: 1,
                                                                                minWidth: 150,
                                                                                overflow: "hidden",
                                                                                textOverflow: "ellipsis",
                                                                                whiteSpace: "nowrap",
                                                                                paddingLeft: "0px !important"
                                                                            }}
                                                                        >
                                                                            <BlackToolTip arrow placement="top" title={pld?.client_name}>{pld.client_name.slice(0, 16)}{pld?.client_name.length > 16 ? "..." : ""}</BlackToolTip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            align="center"
                                                                            sx={{
                                                                                flex: 1,
                                                                                minWidth: 100,
                                                                                paddingLeft: "0px !important"
                                                                            }}
                                                                        >
                                                                            {row?.visa_type}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            align="right"
                                                                            sx={{
                                                                                flex: 1,
                                                                                minWidth: 120,
                                                                                paddingRight: "0px !important"
                                                                            }}
                                                                        >
                                                                            {pld.earned_amount ? (
                                                                                <Text
                                                                                    onClick={() => { handleEarnedAmountData(pld); }}
                                                                                    blue14 sx={{ cursor: "pointer !important", textDecoration: 'underline' }}>
                                                                                    {getCurrencySymbol()} {pld?.earned_amount}
                                                                                </Text>
                                                                            ) : (
                                                                                "-"
                                                                            )}
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                ))
                                                            ) : (
                                                                <StyledTableRow
                                                                    sx={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        justifyContent: "center",
                                                                        padding: 0,
                                                                    }}
                                                                >
                                                                    <StyledTableCell
                                                                        colSpan={3}
                                                                        align="center"
                                                                        sx={{ flex: 1, padding: "10px 0" }}
                                                                    >
                                                                        --
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            )}
                                                        </StyledTableCell>


                                                        {/* Remaining Columns */}
                                                        <StyledTableCell align="center" sx={{ textAlign: '-webkit-center', minWidth: 150, paddingRight: "0px !important" }}>


                                                            <Box
                                                                // onClick={() => { handleSalaryData(row) }}
                                                                className={classes.amountBox} >
                                                                {row?.amount_paid > 0 ? <Text black14 sx={{ textDecoration: 'underline' }}> $ {row?.amount_paid}</Text> : row?.make_standard_pay_as_salary && visaTypes.includes(row?.visa_type) ?
                                                                    <Text black14 sx={{ textDecoration: 'underline' }}> $ {row?.standard_pay_amount}</Text> :
                                                                    row?.lca_wage > 0 && visaTypes.includes(row?.visa_type) ?
                                                                        <Text black14 sx={{ textDecoration: 'underline' }} > $ {row?.lca_wage}</Text> :
                                                                        <Text black14 sx={{ textDecoration: 'underline' }}> $ {row?.earned_pay}</Text>
                                                                }
                                                                {/* <img style={{ paddingLeft: "5px" }} src={blueEdit} alt='edit' /> */}
                                                            </Box>

                                                        </StyledTableCell>
                                                        <StyledTableCell align="right" sx={{ textAlign: '-webkit-right' }}>
                                                            {row?.reimbursement_amount !== 0 ? (
                                                                <Box className={classes.amountBox}
                                                                    onClick={() => { handleReimbursements(row) }}
                                                                >
                                                                    <Text blue14 sx={{ textDecoration: 'underline', cursor: 'pointer' }}>$ {row?.reimbursement_amount}</Text>
                                                                    {/* <img style={{ paddingLeft: "5px" }} src={blueEdit} alt='edit' /> */}
                                                                </Box>
                                                            ) : (
                                                                <Box className={classes.amountBox}
                                                                    onClick={() => { handleReimbursements(row) }}
                                                                >
                                                                    {/* <ControlPointIcon sx={{ color: "#0C75EB" }} /> */}
                                                                    <Text blue14 sx={{ textDecoration: 'underline', cursor: 'pointer' }}>$ {row?.reimbursement_amount}</Text>
                                                                </Box>
                                                            )}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right" sx={{ textAlign: '-webkit-right' }}>
                                                            {row?.deduction_amount !== 0 ? (
                                                                <Box className={classes.amountBox}
                                                                    onClick={() => { handleDeduction(row) }}
                                                                >
                                                                    <Text blue14 sx={{ textDecoration: 'underline', cursor: 'pointer' }}>$ {row?.deduction_amount}</Text>
                                                                    {/* <img style={{ paddingLeft: "5px" }} src={blueEdit} alt='edit' /> */}
                                                                </Box>
                                                            ) : (
                                                                <Box className={classes.amountBox}
                                                                    onClick={() => { handleDeduction(row) }}
                                                                >
                                                                    {/* <ControlPointIcon sx={{ color: "#0C75EB" }} />  */}
                                                                    <Text blue14 sx={{ textDecoration: 'underline', cursor: 'pointer' }}>$ {row?.deduction_amount}</Text>

                                                                </Box>
                                                            )}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right" >
                                                            {row?.balance_amount ? (
                                                                <Text
                                                                    onClick={() => { handleCurrentBalanceData(row) }}
                                                                    blue14 sx={{ cursor: "pointer !important", textDecoration: 'underline' }}>${row.balance_amount}</Text>
                                                            ) : (
                                                                <Text
                                                                    onClick={() => { handleCurrentBalanceData(row) }}
                                                                    blue14 sx={{ cursor: "pointer !important", textDecoration: 'underline' }}>${row.balance_amount}</Text>
                                                            )}
                                                        </StyledTableCell>

                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </Box> :
                                loading ?
                                    [1, 2, 3, 4].map((item) => (
                                        <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                            <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Box>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    </Box>
                                                    <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                </Box>
                                            </Box>
                                        </Stack>
                                    ))
                                    :
                                    NoDataFound()

                            }
                        </Grid>


                    </Box>

                    <SwipeableDrawer
                        anchor={'right'}
                        open={drawer}
                        onClose={() => handleFilterDrawerClose()}
                        transitionDuration={300}
                        sx={{
                            ".MuiDrawer-paper ": {
                                borderRadius: '8px 0px 0px 8px !important',
                            },
                            "& .MuiBackdrop-root.MuiModal-backdrop": {
                                backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                            }
                        }
                        }
                    >
                        {
                            drawer === "filter" ?
                                <Box width={'660px'} height={'100vh'} >
                                    <Box height={'10vh'} borderBottom={'1px solid #EAECF0'} display={'flex'} alignItems={'center'} pl={4} >
                                        <Text headerBlack>Filters</Text>
                                    </Box>

                                    <Box display={'flex'} height={'7vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                                        <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={1} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>
                                            {
                                                filterState.earned_amount_from !== "" && filterState.earned_amount_to !== "" &&
                                                <>
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={1}
                                                        icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack>{filterState.earned_amount_from} - {filterState.earned_amount_to}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip('', "earned_amount")}
                                                        deleteIcon={<CloseIcon />} />
                                                </>
                                            }
                                            {
                                                filterState.reimbursement_amount_from !== "" && filterState.reimbursement_amount_to !== "" &&
                                                <>
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={1}
                                                        icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack>{filterState.reimbursement_amount_from} - {filterState.reimbursement_amount_to}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip('', "reimbursement_amount")}
                                                        deleteIcon={<CloseIcon />} />
                                                </>
                                            }
                                            {
                                                filterState.deduction_amount_from !== "" && filterState.deduction_amount_to !== "" &&
                                                <>
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={1}
                                                        icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack>{filterState.deduction_amount_from} - {filterState.deduction_amount_to}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip('', "deduction_amount")}
                                                        deleteIcon={<CloseIcon />} />
                                                </>
                                            }
                                            {
                                                filterState.salary_amount_from !== "" && filterState.salary_amount_to !== "" &&
                                                <>
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={1}
                                                        icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack>{filterState.salary_amount_from} - {filterState.salary_amount_to}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip('', "current_balance")}
                                                        deleteIcon={<CloseIcon />} />
                                                </>
                                            }
                                            {
                                                filterState.balance_amount_from !== "" && filterState.balance_amount_to !== "" &&
                                                <>
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={1}
                                                        icon={<CircleIcon sx={{ color: "#2DC890 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack>{filterState.balance_amount_from} - {filterState.balance_amount_to}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip('', "balance_amount")}
                                                        deleteIcon={<CloseIcon />} />
                                                </>
                                            }
                                            {
                                                clientDropdown.map((item, key) => (
                                                    filterState.clients.includes(item.id) &&
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={key}
                                                        icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack nowrap>{
                                                            item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                <BlackToolTip title={item.value} placement="right" arrow>
                                                                    {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                </BlackToolTip>
                                                                :
                                                                item.value
                                                        }</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip(item.id, "clients")}
                                                        deleteIcon={<CloseIcon />}
                                                    />
                                                ))
                                            }
                                            {
                                                visaTypeDropdown.map((item, key) => (
                                                    filterState?.visa?.includes(item.id) &&
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={key}
                                                        icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack nowrap>{
                                                            item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                <BlackToolTip title={item.value} placement="right" arrow>
                                                                    {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                </BlackToolTip>
                                                                :
                                                                item.value
                                                        }</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip(item.id, "visa")}
                                                        deleteIcon={<CloseIcon />}
                                                    />
                                                ))
                                            }

                                        </Box>
                                        {
                                            ((filterState.earned_amount_from !== "" && filterState.earned_amount_to !== "") || (filterState.reimbursement_amount_from !== "" && filterState.reimbursement_amount_to !== "") || (filterState.balance_amount_from !== "" && filterState.balance_amount_to !== "") || (filterState.salary_amount_from !== "" && filterState.salary_amount_to !== "") || (filterState.deduction_amount_from !== "" && filterState.deduction_amount_to !== "") || filterState.clients.length > 0 || filterState.visa.length > 0) ?
                                                <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                                :
                                                ''
                                        }
                                    </Box>

                                    <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'73vh'} >
                                        <Box display={'flex'} flexDirection={'column'} height={'100%'} width={'43%'} borderRight={'1px solid #EAECF0'} pt={0} >
                                            <List component="nav" aria-label="secondary mailbox folder" sx={{ overflowY: 'scroll' }}>
                                                <ListItem className={selectedFilter == 1 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(1)} secondaryAction={filterState.clients.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterState.clients.length}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 1 ? <Text ml={3} smallBlue1>Client Name</Text> : <Text ml={3} mediumBlack>Client Name</Text>} />
                                                </ListItem>
                                                <Divider className={classes.divider} />
                                                <ListItem className={selectedFilter == 2 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(2)} secondaryAction={filterState.visa.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{filterState.visa.length}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 2 ? <Text ml={3} smallBlue1>Visa Type</Text> : <Text ml={3} mediumBlack>Visa Type</Text>} />
                                                </ListItem>
                                                <Divider />
                                                <ListItem className={selectedFilter == 3 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(3)} secondaryAction={(filterState.earned_amount_from !== "" && filterState.earned_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{(filterState.earned_amount_from !== "" && filterState.earned_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 3 ? <Text ml={3} smallBlue1>Earned Amount</Text> : <Text ml={3} mediumBlack>Earned Amount</Text>} />
                                                </ListItem>
                                                <Divider />
                                                <ListItem className={selectedFilter == 4 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(4)} secondaryAction={(filterState.salary_amount_from !== "" && filterState.salary_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{(filterState.salary_amount_from !== "" && filterState.salary_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 4 ? <Text ml={3} smallBlue1>Salary Amount</Text> : <Text ml={3} mediumBlack>Salary Amount</Text>} />
                                                </ListItem>
                                                <Divider />
                                                <ListItem className={selectedFilter == 5 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(5)} secondaryAction={(filterState.reimbursement_amount_from !== "" && filterState.reimbursement_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{(filterState.reimbursement_amount_from !== "" && filterState.reimbursement_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 5 ? <Text ml={3} smallBlue1>Reimbursements</Text> : <Text ml={3} mediumBlack>Reimbursements</Text>} />
                                                </ListItem>
                                                <Divider />
                                                <ListItem className={selectedFilter == 6 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(6)} secondaryAction={(filterState.deduction_amount_from !== "" && filterState.deduction_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{(filterState.deduction_amount_from !== "" && filterState.deduction_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 6 ? <Text ml={3} smallBlue1>Deductions</Text> : <Text ml={3} mediumBlack>Deductions</Text>} />
                                                </ListItem>
                                                <Divider />
                                                <ListItem className={selectedFilter == 7 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(7)} secondaryAction={(filterState.balance_amount_from !== "" && filterState.balance_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#2DC890" }}><Text mediumWhite400>{(filterState.balance_amount_from !== "" && filterState.balance_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 7 ? <Text ml={3} smallBlue1>Current Balance</Text> : <Text ml={3} mediumBlack>Current Balance</Text>} />
                                                </ListItem>
                                                <Divider />
                                            </List>
                                        </Box>
                                        <Box display={'flex'} sx={{ overflow: "auto" }} flexDirection={'column'} height={'70vh'} width={'55%'} pl={'25px !important'} pt={2}>

                                            {
                                                selectedFilter == 1 ?
                                                    <>
                                                        {
                                                            clientDropdown.length > 0 && clientDropdown.map((item, index) => (
                                                                <Grid container alignItems={"center"} pb={2}>
                                                                    <FormControlLabel
                                                                        key={index}
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                name={"clients"}
                                                                                value={item.id}
                                                                                onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                // onClick = {handleCheckClick()}
                                                                                icon={<CheckBorderIcon />}
                                                                                checkedIcon={<CheckedIcon />}
                                                                                checked={filterState.clients.includes(item.id)} />}
                                                                        label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                            <BlackToolTip title={item.value} placement="right" arrow>
                                                                                {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                            </BlackToolTip>
                                                                            :
                                                                            item.value}</Text>}
                                                                    />
                                                                </Grid>
                                                            ))
                                                        }
                                                    </>
                                                    :
                                                    selectedFilter == 2 ?
                                                        <>
                                                            {
                                                                visaTypeDropdown.length > 0 && visaTypeDropdown.map((item, index) => (
                                                                    <Grid container alignItems={"center"} pb={2}>
                                                                        <FormControlLabel
                                                                            key={index}
                                                                            control={
                                                                                <Checkbox
                                                                                    size="small"
                                                                                    name={"visa"}
                                                                                    value={item.id}
                                                                                    onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                    // onClick = {handleCheckClick()}
                                                                                    icon={<CheckBorderIcon />}
                                                                                    checkedIcon={<CheckedIcon />}
                                                                                    checked={filterState.visa.includes(item.id)} />}
                                                                            label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                                <BlackToolTip title={item.value} placement="right" arrow>
                                                                                    {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                                </BlackToolTip>
                                                                                :
                                                                                item.value}</Text>}
                                                                        />
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </>
                                                        :
                                                        selectedFilter == 3 ?
                                                            <>
                                                                {
                                                                    <Grid container alignItems={"center"} pb={2}>
                                                                        <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                            <Box sx={{ width: 300, margin: "auto" }}>
                                                                                <Text mediumBlack14 pb={1}>
                                                                                    Select Amount Range
                                                                                </Text>
                                                                                <Slider
                                                                                    value={[filterState.earned_amount_from, filterState.earned_amount_to]}
                                                                                    onChange={handleSliderChangeRaised}
                                                                                    valueLabelDisplay="auto"
                                                                                    min={0}
                                                                                    max={10000000}
                                                                                    sx={{ mb: 2 }}
                                                                                />
                                                                                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                    <Input
                                                                                        formControlProps={{
                                                                                            fullWidth: true
                                                                                        }}
                                                                                        inputProps={{
                                                                                            name: 'earned_amount_from',
                                                                                            value: filterState.earned_amount_from,
                                                                                            inputProps: { maxLength: 50 },
                                                                                        }}
                                                                                        onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                                                                        handleChange={(e) => handleInputChange(0, e.target.value)}
                                                                                        clientInput
                                                                                        labelText={'$ Min'}
                                                                                        size="small"
                                                                                        sx={{ width: "35%" }}
                                                                                    />
                                                                                    <Input
                                                                                        formControlProps={{
                                                                                            fullWidth: true
                                                                                        }}
                                                                                        inputProps={{
                                                                                            name: 'earned_amount_to',
                                                                                            value: filterState.earned_amount_to,
                                                                                        }}
                                                                                        onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                                                                        handleChange={(e) => handleInputChange(1, e.target.value)}
                                                                                        clientInput
                                                                                        labelText={'$ Max'}
                                                                                        size="small"
                                                                                        sx={{ width: "35%" }}
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            </>
                                                            :
                                                            selectedFilter == 4 ?
                                                                <>
                                                                    {
                                                                        <Grid container alignItems={"center"} pb={2}>
                                                                            <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                                <Box sx={{ width: 300, margin: "auto" }}>
                                                                                    <Text mediumBlack14 pb={1}>
                                                                                        Select Amount Range
                                                                                    </Text>
                                                                                    <Slider
                                                                                        value={[filterState.salary_amount_from, filterState.salary_amount_to]}
                                                                                        onChange={handleSliderChangeSalary}
                                                                                        valueLabelDisplay="auto"
                                                                                        min={0}
                                                                                        max={10000000}
                                                                                        sx={{ mb: 2 }}
                                                                                    />
                                                                                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                        <Input
                                                                                            formControlProps={{
                                                                                                fullWidth: true
                                                                                            }}
                                                                                            inputProps={{
                                                                                                name: 'salary_amount_from',
                                                                                                value: filterState.salary_amount_from,
                                                                                                inputProps: { maxLength: 50 },
                                                                                            }}
                                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                                                                            handleChange={(e) => handleInputChange(0, e.target.value)}
                                                                                            clientInput
                                                                                            labelText={'$ Min'}
                                                                                            size="small"
                                                                                            sx={{ width: "35%" }}
                                                                                        />
                                                                                        <Input
                                                                                            formControlProps={{
                                                                                                fullWidth: true
                                                                                            }}
                                                                                            inputProps={{
                                                                                                name: 'salary_amount_to',
                                                                                                value: filterState.salary_amount_to,
                                                                                            }}
                                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                                                                            handleChange={(e) => handleInputChange(1, e.target.value)}
                                                                                            clientInput
                                                                                            labelText={'$ Max'}
                                                                                            size="small"
                                                                                            sx={{ width: "35%" }}
                                                                                        />
                                                                                    </Box>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                                :
                                                                selectedFilter == 5 ?
                                                                    <>
                                                                        {
                                                                            <Grid container alignItems={"center"} pb={2}>
                                                                                <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                                    <Box sx={{ width: 300, margin: "auto" }}>
                                                                                        <Text mediumBlack14 pb={1}>
                                                                                            Select Amount Range
                                                                                        </Text>
                                                                                        <Slider
                                                                                            value={[filterState.reimbursement_amount_from, filterState.reimbursement_amount_to]}
                                                                                            onChange={handleSliderChangeReimbursement}
                                                                                            valueLabelDisplay="auto"
                                                                                            min={0}
                                                                                            max={10000000}
                                                                                            sx={{ mb: 2 }}
                                                                                        />
                                                                                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                            <Input
                                                                                                formControlProps={{
                                                                                                    fullWidth: true
                                                                                                }}
                                                                                                inputProps={{
                                                                                                    name: 'reimbursement_amount_from',
                                                                                                    value: filterState.reimbursement_amount_from,
                                                                                                    inputProps: { maxLength: 50 },
                                                                                                }}
                                                                                                onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                                                                                handleChange={(e) => handleInputChange(0, e.target.value)}
                                                                                                clientInput
                                                                                                labelText={'$ Min'}
                                                                                                size="small"
                                                                                                sx={{ width: "35%" }}
                                                                                            />
                                                                                            <Input
                                                                                                formControlProps={{
                                                                                                    fullWidth: true
                                                                                                }}
                                                                                                inputProps={{
                                                                                                    name: 'reimbursement_amount_to',
                                                                                                    value: filterState.reimbursement_amount_to,
                                                                                                }}
                                                                                                onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                                                                                handleChange={(e) => handleInputChange(1, e.target.value)}
                                                                                                clientInput
                                                                                                labelText={'$ Max'}
                                                                                                size="small"
                                                                                                sx={{ width: "35%" }}
                                                                                            />
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                        }
                                                                    </>
                                                                    :
                                                                    selectedFilter == 6 ?
                                                                        <>
                                                                            {
                                                                                <Grid container alignItems={"center"} pb={2}>
                                                                                    <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                                        <Box sx={{ width: 300, margin: "auto" }}>
                                                                                            <Text mediumBlack14 pb={1}>
                                                                                                Select Amount Range
                                                                                            </Text>
                                                                                            <Slider
                                                                                                value={[filterState.deduction_amount_from, filterState.deduction_amount_to]}
                                                                                                onChange={handleSliderChangeDeduction}
                                                                                                valueLabelDisplay="auto"
                                                                                                min={0}
                                                                                                max={10000000}
                                                                                                sx={{ mb: 2 }}
                                                                                            />
                                                                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                                <Input
                                                                                                    formControlProps={{
                                                                                                        fullWidth: true
                                                                                                    }}
                                                                                                    inputProps={{
                                                                                                        name: 'deduction_amount_from',
                                                                                                        value: filterState.deduction_amount_from,
                                                                                                        inputProps: { maxLength: 50 },
                                                                                                    }}
                                                                                                    onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                                                                                    handleChange={(e) => handleInputChange(0, e.target.value)}
                                                                                                    clientInput
                                                                                                    labelText={'$ Min'}
                                                                                                    size="small"
                                                                                                    sx={{ width: "35%" }}
                                                                                                />
                                                                                                <Input
                                                                                                    formControlProps={{
                                                                                                        fullWidth: true
                                                                                                    }}
                                                                                                    inputProps={{
                                                                                                        name: 'deduction_amount_to',
                                                                                                        value: filterState.deduction_amount_to,
                                                                                                    }}
                                                                                                    onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                                                                                    handleChange={(e) => handleInputChange(1, e.target.value)}
                                                                                                    clientInput
                                                                                                    labelText={'$ Max'}
                                                                                                    size="small"
                                                                                                    sx={{ width: "35%" }}
                                                                                                />
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            }
                                                                        </>
                                                                        :
                                                                        selectedFilter == 7 ?
                                                                            <>
                                                                                {
                                                                                    <Grid container alignItems={"center"} pb={2}>
                                                                                        <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                                            <Box sx={{ width: 300, margin: "auto" }}>
                                                                                                <Text mediumBlack14 pb={1}>
                                                                                                    Select Amount Range
                                                                                                </Text>
                                                                                                <Slider
                                                                                                    value={[filterState.balance_amount_from, filterState.balance_amount_to]}
                                                                                                    onChange={handleSliderChangeCurrent}
                                                                                                    valueLabelDisplay="auto"
                                                                                                    min={0}
                                                                                                    max={10000000}
                                                                                                    sx={{ mb: 2 }}
                                                                                                />
                                                                                                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                                    <Input
                                                                                                        formControlProps={{
                                                                                                            fullWidth: true
                                                                                                        }}
                                                                                                        inputProps={{
                                                                                                            name: 'balance_amount_from',
                                                                                                            value: filterState.balance_amount_from,
                                                                                                            inputProps: { maxLength: 50 },
                                                                                                        }}
                                                                                                        onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                                                                                        handleChange={(e) => handleInputChange(0, e.target.value)}
                                                                                                        clientInput
                                                                                                        labelText={'$ Min'}
                                                                                                        size="small"
                                                                                                        sx={{ width: "35%" }}
                                                                                                    />
                                                                                                    <Input
                                                                                                        formControlProps={{
                                                                                                            fullWidth: true
                                                                                                        }}
                                                                                                        inputProps={{
                                                                                                            name: 'balance_amount_to',
                                                                                                            value: filterState.balance_amount_to,
                                                                                                        }}
                                                                                                        onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                                                                                        handleChange={(e) => handleInputChange(1, e.target.value)}
                                                                                                        clientInput
                                                                                                        labelText={'$ Max'}
                                                                                                        size="small"
                                                                                                        sx={{ width: "35%" }}
                                                                                                    />
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <Box sx={{ height: "60vh", width: "100%", display: "flex", alignItems: "center" }}>
                                                                                <Grid container>
                                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                                        <img src={nofilterplacement} alt="nofilterplacement" style={{ paddingTop: "15px" }} />
                                                                                    </Grid>
                                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"} >
                                                                                        <Text veryLargeLabel>Gear Up!</Text>
                                                                                    </Grid>
                                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                                        <Text offBoardBodyFont>Select filters to scale our data peaks</Text>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                // nofilterplacement
                                            }
                                        </Box>
                                    </Box>

                                    <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={1} height={'73px'} pr={'26px'}>
                                        <Button cancelSmall onClick={() => cancelFilter()}>Cancel</Button>
                                        <Button saveSmall onClick={() => handleApplyFilters()} >Apply Filters</Button>
                                    </Box>
                                </Box >
                                : null
                        }

                    </SwipeableDrawer>
                </Box >
                <ReusablePopup white iconHide statusWidth openPopup={currentOpen} setOpenPopup={setCurrentOpen} onClose={() => { setCurrentOpen(false) }} showTitle={true}>
                    <CurrentBalancePopup currentOpen={currentOpen} currentBalanceRow={currentBalanceRow} balanceSheetProp={balanceSheet} />
                </ReusablePopup>



                <ReusablePopup white iconHide statusWidth openPopup={earnedOpen} setOpenPopup={setEarnedOpen} onClose={() => { setEarnedOpen(false) }} showTitle={true}>
                    <EarnedAmountPopup earnedOpen={earnedOpen} viewClientEarnedAmount={viewClientEarnedAmount} />
                </ReusablePopup>

                <SwipeableDrawer
                    anchor={'right'}
                    open={salaryOpen}
                    onClose={() => { setSalaryOpen(false); setCurrentSalaryRow({}) }}
                    transitionDuration={300}
                    sx={{
                        ".MuiDrawer-paper ": {
                            borderRadius: '8px 0px 0px 8px !important',
                        },
                        "& .MuiBackdrop-root.MuiModal-backdrop": {
                            backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                        }
                    }
                    }
                >

                    <SalaryAmountDrawer summary={true} active={active} setActive={setActive} getAllEmployees={() => getAllEmployees(payrollConfigId)}
                        currentSalaryRowProp={currentSalaryRow}
                        timesheetListProp={timesheetList}
                        reimbursementDeductionProp={reimbursementDeduction}
                        allBalanceSheetProp={allBalanceSheet}
                        closePopUp={() => { setSalaryOpen(false); setCurrentSalaryRow({}) }}
                        balanceSheetPagination={pagination}
                        balanceSheetPaginationTotal={0} payrollConfigId={payrollConfigId} salaryOpen={salaryOpen} />
                </SwipeableDrawer>

                <SwipeableDrawer
                    anchor={'right'}
                    open={remDrawer}
                    onClose={() => { setRemDrawer(false) }}
                    transitionDuration={300}
                    sx={{
                        ".MuiDrawer-paper ": {
                            borderRadius: '8px 0px 0px 8px !important',
                        },
                        "& .MuiBackdrop-root.MuiModal-backdrop": {
                            backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                        }
                    }
                    }
                >
                    <ReimbursementsDrawer summary={true} closeReimbursement={() => { setRemDrawer(false) }} getAllEmployees={() => getAllEmployees(payrollConfigId)} remDrawer={remDrawer} clickedEmpId={clickedEmpId} reimbursementDeductionProp={reimbursementDeduction} reimbursementTypeDropDown={reimbursementTypeDropDown} reimbursementPayPeriodDropDown={reimbursementPayPeriodDropDown} />
                </SwipeableDrawer>

                {/* Deductions */}
                <SwipeableDrawer
                    anchor={'right'}
                    open={deductionDrawer}
                    onClose={() => { setDeductionsDrawer(false) }}
                    transitionDuration={300}
                    sx={{
                        ".MuiDrawer-paper ": {
                            borderRadius: '8px 0px 0px 8px !important',
                        },
                        "& .MuiBackdrop-root.MuiModal-backdrop": {
                            backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                        }
                    }
                    }
                >
                    <DeductionsDrawer summary={true} getAllEmployees={() => getAllEmployees(payrollConfigId)} deductionDrawer={deductionDrawer} clickedEmpId={clickedEmpId} reimbursementDeductionProp={reimbursementDeduction} deductionTypeDropDown={deductionTypeDropDown} closeDeductionDrawer={() => { setDeductionsDrawer(false) }} />
                </SwipeableDrawer>

                {/* Comments Drawer */}

                <SwipeableDrawer
                    anchor={'right'}
                    open={commentedOn}
                    onClose={() => { setCommentedOn(false) }}
                    transitionDuration={300}
                    sx={{
                        ".MuiDrawer-paper ": {
                            borderRadius: '8px 0px 0px 8px !important',
                        },
                        "& .MuiBackdrop-root.MuiModal-backdrop": {
                            backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                        }
                    }
                    }
                >
                    <CommentsDrawer commentedOn={commentedOn} comments={commentsList} paymentId={commentRow?.payroll_payment_detail_id} handleCloseDrawer={handleCloseCommentDrawerAndUpdate} />
                </SwipeableDrawer>
            </Grid >
        </Grid >
    )
}