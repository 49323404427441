import { Box, Divider, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Text from '../../../../components/customText/Text'
import Button from '../../../../components/customButton/Button'
import ReactApexChart from 'react-apexcharts'
import increment from '../../../../assets/svg/payroll/incrementIcon.svg'
import decrement from '../../../../assets/svg/payroll/decrementIcon.svg'
import PayrollDashboardStyles from './PayrollDashboardStyles'
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import PayrollHistorySelection from './PayrollHistorySelection';
import { addErrorMsg, NoDataFound, getPercentage } from '../../../../utils/utils'
import PayrollAnalysis from './PayrollAnalysis'
import UpcomingPayroll from './UpcomingPayroll'
import DashboardApi from '../../../../apis/admin/payroll/DashboardApi'
import PayrollComparison from './PayrollComparison'
import emptyPayroll from '../../../../assets/svg/payroll/emptyPayroll.svg';
import { useNavigate } from 'react-router-dom'
import loaderImg from '../../../../assets/gif/japfuLoader.gif';

function PayrollDashboard() {

  const classes = PayrollDashboardStyles();
  const navigate = useNavigate();
  const [state, setState] = useState({
    pHStartDate: '',
    pHEndDate: '',
    pHTemporaryStartDate: '',
    pHTemporaryEndDate: '',
    previous_from_date: '',
    previous_to_date: '',
    present_from_date: '',
    present_to_date: ''
  })
  const [choosePeriod, setChoosePeriod] = useState(false);
  const [chooseComparison, setOpenChooseComparison] = useState(false);
  const [noData, setNodata] = useState(false);
  const [totalPercentage, setTotalPercentage] = useState('');
  const [totalPercentageProj, setTotalPercentageProj] = useState('');
  const [loader, setLoader] = useState(false);

  const [upcomingPayrollData, setUpcomingPayrollData] = useState({
    pay_period: {
      from_date: '',
      id: '',
      to_date: ''
    },
    current_cycle: {
      from_date: '',
      to_date: ''
    },
    check_date: '',
    current_balance: '',
    is_submited: ''
  })
  const [values, setValues] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl)
  const [finalized, setFinalized] = useState('');
  const [payrollCycles, setPayrollCycles] = useState('');
  const [payrollHistory, setPayrollHistory] = useState({
    payroll_history: {
      total_paid: '',
      final_balance: '',
      total_reimbursement: '',
      total_deduction: '',
      finalized: '',
      not_finalized: '',
      total_employees: 0,
      from_date: '',
      to_date: '',
      configuration_id: '',
      pending_dues: '',
      margin_earned: ''
    },
    expense_management: {
      reimbursement: {
        total_reimbursement_raised: '',
        total_reimbursement_pending_approval: '',
        total_reimbursed_amount: '',
        expense_reimbursed: [],
        expense_pending: [
          {
            expense_type_id: '',
            expense_type_name: '',
            amount: ''
          }
        ]
      },
      deduction: {
        total_deduction: '',
        total_dedcution_peding: '',
        total_recovered_amount: '',
        expense_recovered: [],
        expense_pending: [
          {
            expense_type_id: '',
            expense_type_name: '',
            amount: ''
          },
          {
            expense_type_id: '',
            expense_type_name: '',
            amount: ''
          }
        ]
      }
    },
    placement_payrate_breakdown: {
      total_placements: '',
      payrate_details: []
    },
    employee_breakdown: {
      employees: [],
      visa_types: []
    },
    project_breakdown: {
      placements: [],
      visa_types: []
    }
  })

  useEffect(() => {
    payrollHistoryApi(state.pHStartDate, state.pHEndDate);
    upcomingPayrollApi();
    getpayrollCycles();
    // eslint-disable-next-line 
  }, [])

  const upcomingPayrollApi = () => {
    DashboardApi.getupcomingPayroll().then((res) => {
      if (res.data.statusCode === 1003) {
        setUpcomingPayrollData(res.data.data);
      }
    })
  }

  const getpayrollCycles = () => {
    DashboardApi.getpayrollCycles().then((res) => {
      if (res.data.statusCode === 1003) {
        setPayrollCycles(res.data)
      }
    })
  }

  const payrollHistoryApi = (startDate, endDate) => {
    setLoader(true);
    DashboardApi.getPayrollHistory(startDate, endDate).then((res) => {
      if (res.data.statusCode === 1003) {
        setLoader(false);
        state['pHStartDate'] = startDate
        state['pHEndDate'] = endDate
        setState({ ...state })
        if (res.data.data.length > 0) {
          setNodata(false);
          setPayrollHistory(res.data.data[0]);
          let sum = 0;
          res.data.data[0]?.placement_payrate_breakdown?.payrate_details?.map((item => sum += parseInt(item.count)))
          setValues(res.data.data[0]?.placement_payrate_breakdown?.payrate_details?.map((item => getPercentage(sum, parseInt(item.count)))))


          let empSum = 0;
          res.data.data[0]?.employee_breakdown?.visa_types?.map((item => empSum += parseInt(item.total_emploees)))
          const totalempValue = res.data.data[0]?.employee_breakdown?.visa_types?.map((item => getPercentage(empSum, parseInt(item.total_emploees))))
          setTotalPercentage(+ parseInt(totalempValue))

          let projSum = 0;
          res.data.data[0]?.project_breakdown?.visa_types?.map((item => projSum += parseInt(item.total_placements)))
          const totalprojValue = res.data.data[0]?.project_breakdown?.visa_types?.map((item => getPercentage(empSum, parseInt(item.total_placements))))
          setTotalPercentageProj(+ parseInt(totalprojValue))
          const total = res.data.data[0]?.payroll_history?.total_employees
          const totalfinalized = res.data.data[0]?.payroll_history?.finalized
          let finalizedEmp = getPercentage(total, totalfinalized)
          setFinalized(finalizedEmp);
        } else {
          setNodata(true);
        }
        setAnchorEl(null);
        setChoosePeriod(true);
      } else {
        state['pHTemporaryStartDate'] = ''
        state['pHTemporaryEndDate'] = ''
        setState({ ...state })
        setLoader(false);
        setAnchorEl(null);
        setNodata(true);
        addErrorMsg(res.data.message);
      }
    })
  }

  const [openEmpBreakdown, setOpenEmpBreakdown] = useState(false);
  const [openEmpBreakdownProject, setOpenEmpBreakdownProject] = useState(false);

  const placements = {
    series: values,
    options: {
      chart: {
        type: 'radialBar',
        offsetY: 0,
        sparkline: {
          enabled: false
        }
      },
      stroke: {
        lineCap: "round",
        curve: 'smooth',
      },
      states: {
        hover: {
          filter: {
            type: 'none'
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none'
          }
        }
      },
      fill: {
        colors: ['#165DFF', '#14C9C9', '#F7BA1E', '#722ED1', "#14C9C9"],
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 3,
            size: '40%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            }
          },
          barLabels: {
            enabled: true,
            useSeriesColors: true,
            offsetX: -8,
            fontSize: '16px',
            formatter: function (seriesName, opts) {
              return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
            },
          },
        }
      },
      colors: ['#165DFF', '#14C9C9', '#F7BA1E', '#722ED1', "#14C9C9"],
      labels: ['Vimeo', 'Messenger', 'Facebook', 'LinkedIn', 'LinkedIn'],
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            show: false
          }
        }
      }],
      grid: {
        padding: {
          top: -10
        }
      },
    },
  }

  const [activeState, setActiveState] = useState('reimbursements');
  const deduction = getPercentage(parseInt(payrollHistory?.expense_management?.deduction?.total_deduction), parseInt(payrollHistory?.expense_management?.deduction?.total_dedcution_peding))
  const reimbursed = getPercentage(parseInt(payrollHistory?.expense_management?.reimbursement?.total_reimbursed_amount), parseInt(payrollHistory?.expense_management?.reimbursement?.total_reimbursement_pending_approval))
  const deductionpending = getPercentage(parseInt(payrollHistory?.expense_management?.deduction?.total_deduction), parseInt(payrollHistory?.expense_management?.deduction?.total_recovered_amount))
  const reimbursedpending = getPercentage(parseInt(payrollHistory?.expense_management?.reimbursement?.total_reimbursed_amount), parseInt(payrollHistory?.expense_management?.reimbursement?.total_reimbursement_raised))

  const expenseManagement = {
    series: activeState == 'reimbursements' ? [reimbursed, reimbursedpending] : [deduction, deductionpending],
    options: {
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      tooltip: {
        enabled: false
      },
      chart: {
        type: 'pie',
        offsetY: 0,
        sparkline: {
          enabled: false
        }
      },
      states: {
        hover: {
          filter: {
            type: 'none'
          },
          tooltip: {
            enabled: false
          },
          legend: {
            show: false
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none'
          }
        }
      },
      fill: {
        colors: ['#D1E1FF', '#0C75EB'],
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 3,
            size: '40%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            }
          },
          barLabels: {
            enabled: false,
            useSeriesColors: false,
            offsetX: -8,
            fontSize: '16px'
          },
        }
      },
      colors: ['#D1E1FF', '#0C75EB'],
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            show: false
          }
        }
      }],
      grid: {
        padding: {
          top: -10
        }
      },
    },
  }

  const employeeDetails = {
    headerNames: ['VisaType', 'Employee Count', 'Employees Newly Added', 'Employees Left'],
    projectHeaderNames: ['VisaType', 'Projects', 'Projects Added', 'Projects Completed'],
    values: [
      {
        color: '#FF8C42',
        name: 'H1B',
        count: '280',
        newly_added: '20',
        left: '2'
      },
      {
        color: '#3772C6',
        name: 'CPT',
        count: '1800',
        newly_added: '20',
        left: '1'
      },
      {
        color: '#D1EF0D',
        name: 'GC',
        count: '100',
        newly_added: '16',
        left: '8'
      },
      {
        color: '#F62393',
        name: 'Others',
        count: '100',
        newly_added: '16',
        left: '8'
      }
    ]
  }

  const EmpBreakdrowns = {
    totalLines: totalPercentage,
    lineWidth: 2,
    lineSpacing: 2
  }

  const ProjectBreakdrowns = {
    totalLines: totalPercentageProj,
    lineWidth: 2,
    lineSpacing: 2
  }

  const handleTab = (args) => {
    setActiveState(args);
  }

  return (
    <Grid container pl={14} mt={2}>
      <Grid item container xs={12} lg={12} md={12} alignItems='center'>
        <Grid item xs={6} lg={6} md={6}>
          <Box display='flex' flexDirection='column' gap={1}>
            <Text largeLabel>Current Balance</Text>
            <Text veryLargeLabel>{upcomingPayrollData.current_balance ? `$ ${upcomingPayrollData.current_balance}` : '-'}</Text>
          </Box>
        </Grid>
        {
          upcomingPayrollData.is_submited == false ?
            <>
              {
                upcomingPayrollData.pay_period.from_date == '' || upcomingPayrollData.pay_period.to_date == '' ? '' :
                  <Grid item xs={5.3} lg={5.3} md={5.3} textAlign='end'>
                    <Button generatePayroll onClick={() => navigate('/payroll/generate-payroll')}>$ Generate Payroll</Button>
                  </Grid>
              }
            </> :
            <Grid item xs={5.3} lg={5.3} md={5.3} textAlign='end'>
              <Button generatePayroll onClick={() => navigate('/payrollSummary', { state: { id: upcomingPayrollData.pay_period.id } })}>Payroll Summary</Button>
            </Grid>
        }
      </Grid>
      {
        loader ?
          <Box alignItems='center !important' textAlign='center' justifyContent='center' width='100%'>
            <img src={loaderImg} alt='loader' style={{ height: '100px', width: '100px' }} />
          </Box> :
          upcomingPayrollData.pay_period.from_date == '' || upcomingPayrollData.pay_period.to_date == '' ?
            <Grid item container spacing={2} rowSpacing={3} textAlign='center' justifyContent='center' alignItems='center'>
              <Grid item xs={12}>
                <img src={emptyPayroll} alt='emptyPayroll' />
              </Grid>
              <Grid item xs={12}>
                <Text mediumViewAmt>Your Payroll Dashboard Will Be Ready Soon..</Text>
              </Grid>
              <Grid item xs={12}>
                <Text mediumOverView>Payroll analytics will be created once you have successfully generated a payroll summary.</Text>
              </Grid>
              <Grid item xs={4}>
                <Button blueButton onClick={() => navigate('/payroll/generate-payroll')}>Generate Payroll</Button>
              </Grid>
            </Grid>
            :
            <>
              <Grid item container xs={12} lg={12} md={12} mt={2}>
                <UpcomingPayroll upcomingPayrollData={upcomingPayrollData} upcomingPayrollApi={upcomingPayrollApi} />
              </Grid>
              <Grid item container xs={12} lg={12} md={12} my={4}>
                <Divider sx={{ border: '0.5px solid #EAECF0 !important', width: '94.4%' }} />
              </Grid>
              <PayrollHistorySelection state={state} payrollCycles={payrollCycles} setState={setState} payrollHistory={payrollHistory} payrollHistoryApi={payrollHistoryApi} choosePeriod={choosePeriod} setChoosePeriod={setChoosePeriod} anchorEl={anchorEl} setAnchorEl={setAnchorEl} open={open} finalized={finalized} />
              <Grid item container xs={11.3} lg={11.3} md={11.3} mt={3} spacing={2} columnSpacing={4}>
                <Grid item xs={6} lg={6} md={6}>
                  <Box sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '12px' }}>
                    <Grid item xs={12} lg={12} md={12}>
                      <Text largeBlack20>Employees Breakdown</Text>
                    </Grid>
                    {
                      noData ?
                        NoDataFound() :
                        <>
                          <Grid item container xs={12} lg={12} md={12} pl={2}>
                            {
                              payrollHistory?.employee_breakdown?.employees?.map((data) => (
                                <Grid item xs={4} lg={4} md={4}>
                                  <Box display='flex' flexDirection='column' gap={1} width='100% !important' mt={2}>
                                    <Text smallBlack>{data.label}</Text>
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                      <Text largeBlack22>{data.employees_count}</Text>
                                      <Box sx={{ background: data.status == 'increment' ? '#E5FFEE' : '#FFEFEF', color: data.status == 'increment' ? '#22C55E' : '#F70D0D', font: '16px Quicksand !important', fontWeight: '600px !important', borderRadius: '27px', minWidth: '45px', padding: '0px 4px' }} >
                                        <Box display='flex' flexDirection='row' gap={1}>
                                          <img src={data.status == 'increment' ? increment : decrement} alt='increment' />
                                          <Text>{data.employees_left}</Text>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              ))
                            }
                          </Grid>
                          <Grid item container xs={12} lg={12} md={12} mt={3} pl={2}>
                            <Box
                              sx={{
                                display: "flex",
                                width: `${(EmpBreakdrowns.lineWidth + EmpBreakdrowns.lineSpacing) * EmpBreakdrowns.totalLines}px`, // Total width of the container
                                height: "50px", // Height of the container
                              }}
                            >
                              {Array.from({ length: EmpBreakdrowns.totalLines }).map((_, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    width: `${EmpBreakdrowns.lineWidth}px`,
                                    height: "100%",
                                    marginRight: `${EmpBreakdrowns.lineSpacing}px`,
                                    background: `hsl(${(index / EmpBreakdrowns.totalLines) * 360}, 80%, 50%)`, // Gradual hue shift
                                  }}
                                />
                              ))}
                            </Box>
                          </Grid>
                          <Grid item container xs={12} lg={12} md={12} mt={3} p={'0px 20px'} textAlign='left'>
                            {
                              employeeDetails.headerNames.map((data) => (
                                <Grid item xs={3}>
                                  <Text greyLabel>{data}</Text>
                                </Grid>
                              ))
                            }
                          </Grid>
                          <Grid item container xs={12} lg={12} md={12} mt={1.5} pl={2}>
                            {
                              (payrollHistory?.employee_breakdown?.visa_types?.length > 4 ? (payrollHistory?.employee_breakdown?.visa_types?.slice(0, 4)) : payrollHistory?.employee_breakdown?.visa_types)
                                .map((data) => (
                                  <Grid item container xs={12} lg={12} md={12} spacing={2} my={1} p={'0px 12px'}>
                                    <Box sx={{ background: '#F6F6F6', borderRadius: '6px', padding: '16px', width: '100%', alignItems: 'center' }}>
                                      <Grid item container xs={12} lg={12} md={12} spacing={2}>
                                        <Grid item xs={3} lg={3} md={3}>
                                          <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                            <Box sx={{ background: data.color, height: '10px', width: '10px', borderRadius: '50%' }} />
                                            <Text mediumBlack600>{data.name}</Text>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={3} lg={3} md={3}>
                                          <Text greyColor16>{data.total_emploees}</Text>
                                        </Grid>
                                        <Grid item xs={3} lg={3} md={3}>
                                          <Text greyColor16>{data.newly_added_employees}</Text>
                                        </Grid>
                                        <Grid item xs={3} lg={3} md={3}>
                                          <Text greyColor16>{data.total_employees_left}</Text>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                ))
                            }
                            {
                              payrollHistory?.employee_breakdown?.visa_types?.length > 4 ?
                                <Box mt={1}>
                                  <Text blueText16 sx={{ textDecoration: '1.5px solid underline #0C75EB', cursor: 'pointer !important' }} onClick={() => setOpenEmpBreakdown(true)}>Others</Text>
                                </Box> : ''
                            }
                          </Grid>
                        </>
                    }
                  </Box>
                </Grid>
                <Grid item xs={6} lg={6} md={6}>
                  <Box sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '12px' }}>
                    <Grid item xs={12} lg={12} md={12}>
                      <Text largeBlack20>Projects Breakdown</Text>
                    </Grid>
                    {
                      noData ?
                        NoDataFound() :
                        <>
                          <Grid item container xs={12} lg={12} md={12} pl={2}>
                            {
                              payrollHistory?.project_breakdown?.placements?.map((data) => (
                                <Grid item xs={4} lg={4} md={4}>
                                  <Box display='flex' flexDirection='column' gap={1} width='100% !important' mt={2}>
                                    <Text smallBlack>{data.label}</Text>
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                      <Text largeBlack22>{data.placements_count}</Text>
                                      <Box sx={{ background: data.status == 'increment' ? '#E5FFEE' : '#FFEFEF', color: data.status == 'increment' ? '#22C55E' : '#F70D0D', font: '16px Quicksand !important', fontWeight: '600px !important', borderRadius: '27px', minWidth: '45px', padding: '0px 4px' }} >
                                        <Box display='flex' flexDirection='row' gap={1}>
                                          <img src={data.status == 'increment' ? increment : decrement} alt='increment' />
                                          <Text>{data.placement_left}</Text>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              ))
                            }
                          </Grid>
                          <Grid item container xs={12} lg={12} md={12} mt={3} pl={2}>
                            <Box
                              sx={{
                                display: "flex",
                                width: `${(ProjectBreakdrowns.lineWidth + ProjectBreakdrowns.lineSpacing) * ProjectBreakdrowns.totalLines}px`, // Total width of the container
                                height: "50px", // Height of the container
                              }}
                            >
                              {Array.from({ length: ProjectBreakdrowns.totalLines }).map((_, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    width: `${ProjectBreakdrowns.lineWidth}px`,
                                    height: "100%",
                                    marginRight: `${ProjectBreakdrowns.lineSpacing}px`,
                                    background: `hsl(${(index / ProjectBreakdrowns.totalLines) * 360}, 80%, 50%,70%)`, // Gradual hue shift
                                  }}
                                />
                              ))}
                            </Box>
                          </Grid>
                          <Grid item container xs={12} lg={12} md={12} mt={3} p={'0px 20px'}>
                            {
                              employeeDetails.projectHeaderNames.map((data) => (
                                <Grid item xs={3} lg={3} md={3}>
                                  <Text greyLabel>{data}</Text>
                                </Grid>
                              ))
                            }
                          </Grid>
                          <Grid item container xs={12} lg={12} md={12} mt={1.5} pl={2}>
                            {
                              (payrollHistory?.project_breakdown?.visa_types?.length > 4 ? payrollHistory?.project_breakdown?.visa_types?.slice(0, 4) : payrollHistory?.project_breakdown?.visa_types)
                                .map((data) => (
                                  <Grid item container xs={12} lg={12} md={12} spacing={2} my={1} p={'0px 12px'}>
                                    <Box sx={{ background: '#F6F6F6', borderRadius: '6px', padding: '16px', width: '100%', alignItems: 'center' }}>
                                      <Grid item container xs={12} spacing={2}>
                                        <Grid item xs={3}>
                                          <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                            <Box sx={{ background: data.color, height: '10px', width: '10px', borderRadius: '50%' }} />
                                            <Text mediumBlack600>{data.name}</Text>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Text greyColor16>{data.total_placements}</Text>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Text greyColor16>{data.placements_added}</Text>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Text greyColor16>{data.placements_completed}</Text>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                ))
                            }
                            {
                              payrollHistory?.project_breakdown?.visa_types?.length > 4 ?
                                <Box mt={1}>
                                  <Text blueText16 sx={{ textDecoration: '1.5px solid underline #0C75EB', cursor: 'pointer !important' }} onClick={() => setOpenEmpBreakdownProject(true)}>Others</Text>
                                </Box> : ''
                            }
                          </Grid>
                        </>
                    }
                  </Box>
                </Grid>
              </Grid>
              <Grid item container xs={11.3} lg={11.3} md={11.3} mt={3} spacing={2} columnSpacing={4}>
                <Grid item container xs={6} lg={6} md={6} pl={2} mb={2}>
                  <Box sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '14px', width: '100%' }}>
                    <Grid item xs={12} lg={12} md={12}>
                      <Text largeBlack20>Placement Breakdown Based on Payrate</Text>
                    </Grid>
                    {
                      noData ?
                        NoDataFound() :
                        <>
                          <Grid item xs={12} lg={12} md={12} mt={2}>
                            <Box display='flex' flexDirection='column' gap={1}>
                              <Text black12>Total Placements</Text>
                              <Text largeBlack22>{payrollHistory?.placement_payrate_breakdown?.total_placements}</Text>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={12} md={12} mt={2}>
                            <Box display='flex' flexDirection='row' justifyContent='center' marginTop={4} width='100% !important'>
                              <Box>
                                {payrollHistory.placement_payrate_breakdown.payrate_details.map((item) => (
                                  <Text lightGrey10>{item.value}</Text>
                                ))
                                }
                              </Box>
                              <ReactApexChart options={placements.options} series={placements.series} type="radialBar" height='380px' width='400px' />
                            </Box>
                          </Grid>
                        </>
                    }
                  </Box>
                </Grid>
                <Grid item container xs={6} lg={6} md={6} pl={2} mb={2}>
                  <Box sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '14px', width: '100%' }}>
                    <Grid item xs={12} lg={12} md={12}>
                      <Text largeBlack20>Expense Management</Text>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} my={2}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} my={2} mb={5}>
                      <Box className={classes.tabBg}>
                        <Box className={activeState == 'reimbursements' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('reimbursements')}>
                          {
                            activeState == 'reimbursements' ?
                              <Text largeWhite400>Reimbursements</Text> :
                              <Text blackHeader>Reimbursements</Text>
                          }
                        </Box>
                        <Box className={activeState == 'deductions' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('deductions')}>
                          {
                            activeState == 'deductions' ?
                              <Text largeWhite400>Deductions</Text> :
                              <Text blackHeader>Deductions</Text>
                          }
                        </Box>
                      </Box>
                    </Grid>
                    {
                      noData || (payrollHistory?.expense_management?.reimbursement?.total_reimbursed_amount == 0 || payrollHistory?.expense_management?.deduction?.total_deduction == 0) ?
                        NoDataFound() :
                        <>
                          <Grid item xs={12} lg={12} md={12} my={3}>
                            <ReactApexChart options={expenseManagement.options} series={expenseManagement.series} type="pie" height='300px' />
                          </Grid>
                          <Grid item container xs={12} lg={12} md={12} my={2} textAlign='center' justifyContent='center'>
                            <Grid item xs={4} lg={4} md={4}>
                              <Box display='flex' flexDirection='column' gap={1}>
                                <Text black12>{activeState == 'reimbursements' ? 'Reimbursements Raised' : 'Total Deductions'}</Text>
                                <Text blueText16>{activeState == 'reimbursements' ? payrollHistory.expense_management.reimbursement.total_reimbursed_amount : payrollHistory.expense_management.deduction.total_deduction}</Text>
                              </Box>
                            </Grid>
                            <Grid item xs={4} lg={4} md={4}>
                              <Box display='flex' flexDirection='column' gap={1}>
                                <Box display='flex' flexDirection='row' gap={0.8} alignItems='center'>
                                  <Box className={classes.pendingBg} />
                                  <Text black12>{activeState == 'reimbursements' ? 'Reimbursed amounts' : 'Recovered Amounts'}</Text>
                                </Box>
                                <Text blueText16>{activeState == 'reimbursements' ? payrollHistory?.expense_management?.reimbursement?.total_reimbursement_raised : payrollHistory?.expense_management?.deduction?.total_recovered_amount}</Text>
                              </Box>
                            </Grid>
                            <Grid item xs={4} lg={4} md={4}>
                              <Box display='flex' flexDirection='column' gap={1}>
                                <Box display='flex' flexDirection='row' gap={0.8} alignItems='center'>
                                  <Box className={classes.RecoveredBg} />
                                  <Text black12>{activeState == 'reimbursements' ? 'Reimbursements Pending' : 'Recoveries Pending'}</Text>
                                </Box>
                                <Text blueText16>{activeState == 'reimbursements' ? payrollHistory?.expense_management?.reimbursement?.total_reimbursement_pending_approval : payrollHistory?.expense_management?.deduction.total_dedcution_peding}</Text>
                              </Box>
                            </Grid>
                            {
                              activeState == 'deductions' ?
                                <Grid item xs={12} mt={2}>
                                  <Text mediumBlack1>* This deductions include only recoveries</Text>
                                </Grid> : ''
                            }
                          </Grid>
                        </>
                    }
                  </Box>
                </Grid>
              </Grid>
              <Grid xs={12} lg={12} md={12} my={2}>
                <Divider />
              </Grid>
              <Grid xs={11.3} lg={11.3} md={11.3} my={2}>
                <PayrollComparison state={state} setState={setState} chooseComparison={chooseComparison} setOpenChooseComparison={setOpenChooseComparison} />
              </Grid>
              <Grid xs={11.3} lg={11.3} md={11.3} my={2}>
                <PayrollAnalysis />
              </Grid>
            </>
      }
      <ReusablePopup openPopup={openEmpBreakdown} setOpenPopup={setOpenEmpBreakdown} white iconHide>
        <Box sx={{ borderRadius: '8px', paddingLeft: '15px' }}>
          <Grid item xs={12} lg={12} md={12}>
            <Text largeBlack20>Employees Breakdown</Text>
          </Grid>
          <Grid item container xs={12} lg={12} md={12}>
            {
              payrollHistory?.employee_breakdown?.employees?.map((data) => (
                <Grid item xs={4} lg={4} md={4}>
                  <Box display='flex' flexDirection='column' gap={1} width='100% !important' mt={2}>
                    <Text smallBlack>{data.label}</Text>
                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                      <Text largeBlack22>{data.employees_count}</Text>
                      <Box sx={{ background: data.status == 'increment' ? '#E5FFEE' : '#FFEFEF', color: data.status == 'increment' ? '#22C55E' : '#F70D0D', font: '16px Quicksand !important', fontWeight: '600px !important', borderRadius: '27px', minWidth: '45px', padding: '0px 4px' }} >
                        <Box display='flex' flexDirection='row' gap={1}>
                          <img src={data.status == 'increment' ? increment : decrement} alt='increment' />
                          <Text>{data.employees_left}</Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))
            }
          </Grid>
          <Grid item container xs={12} lg={12} md={12} mt={3}>
            <Box
              sx={{
                display: "flex",
                width: `${(EmpBreakdrowns.lineWidth + EmpBreakdrowns.lineSpacing) * EmpBreakdrowns.totalLines}px`, // Total width of the container
                height: "50px", // Height of the container
              }}
            >
              {Array.from({ length: EmpBreakdrowns.totalLines }).map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    width: `${EmpBreakdrowns.lineWidth}px`,
                    height: "100%",
                    marginRight: `${EmpBreakdrowns.lineSpacing}px`,
                    background: `hsl(${(index / EmpBreakdrowns.totalLines) * 360}, 80%, 50%)`, // Gradual hue shift
                  }}
                />
              ))}
            </Box>
          </Grid>
          <Grid item container xs={12} lg={12} md={12} mt={3} p={'0px 20px'}>
            {
              employeeDetails.headerNames.map((data) => (
                <Grid item xs={3} >
                  <Text greyLabel>{data}</Text>
                </Grid>
              ))
            }
          </Grid>
          <Grid item container xs={12} lg={12} md={12} mt={1.5}>
            {
              payrollHistory?.employee_breakdown?.visa_types.map((data) => (
                <Grid item container xs={12} lg={12} md={12} spacing={2} my={1} p={'0px 12px'}>
                  <Box sx={{ background: '#F6F6F6', borderRadius: '6px', padding: '16px', width: '100%', alignItems: 'center' }}>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={3}>
                        <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                          <Box sx={{ background: data.color, height: '10px', width: '10px', borderRadius: '50%' }} />
                          <Text mediumBlack600>{data.name}</Text>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Text greyColor16>{data.total_emploees}</Text>
                      </Grid>
                      <Grid item xs={3}>
                        <Text greyColor16>{data.newly_added_employees}</Text>
                      </Grid>
                      <Grid item xs={3}>
                        <Text greyColor16>{data.total_employees_left}</Text>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))
            }
          </Grid>
          <Grid item xs={12} lg={12} md={12} mt={2} textAlign='end' pr={1}>
            <Button saveBtn400 onClick={() => setOpenEmpBreakdown(false)}>Done</Button>
          </Grid>
        </Box>
      </ReusablePopup>
      <ReusablePopup openPopup={openEmpBreakdownProject} setOpenPopup={setOpenEmpBreakdownProject} white iconHide>
        <Box sx={{ borderRadius: '8px', paddingLeft: '15px' }}>
          <Grid item xs={12} lg={12} md={12}>
            <Text largeBlack20>Project Breakdown</Text>
          </Grid>
          <Grid item container xs={12} lg={12} md={12}>
            {
              payrollHistory?.project_breakdown?.placements.map((data) => (
                <Grid item xs={4} lg={4} md={4}>
                  <Box display='flex' flexDirection='column' gap={1} width='100% !important' mt={2}>
                    <Text smallBlack>{data.label}</Text>
                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                      <Text largeBlack22>{data.placements_count}</Text>
                      <Box sx={{ background: data.status == 'increment' ? '#E5FFEE' : '#FFEFEF', color: data.status == 'increment' ? '#22C55E' : '#F70D0D', font: '16px Quicksand !important', fontWeight: '600px !important', borderRadius: '27px', minWidth: '45px', padding: '0px 4px' }} >
                        <Box display='flex' flexDirection='row' gap={1}>
                          <img src={data.status == 'increment' ? increment : decrement} alt='increment' />
                          <Text>{data.placement_left}</Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))
            }
          </Grid>
          <Grid item container xs={12} lg={12} md={12} mt={3}>
            <Box
              sx={{
                display: "flex",
                width: `${(ProjectBreakdrowns.lineWidth + ProjectBreakdrowns.lineSpacing) * ProjectBreakdrowns.totalLines}px`, // Total width of the container
                height: "50px", // Height of the container
              }}
            >
              {Array.from({ length: ProjectBreakdrowns.totalLines }).map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    width: `${ProjectBreakdrowns.lineWidth}px`,
                    height: "100%",
                    marginRight: `${ProjectBreakdrowns.lineSpacing}px`,
                    background: `hsl(${(index / ProjectBreakdrowns.totalLines) * 360}, 80%, 50%)`, // Gradual hue shift
                  }}
                />
              ))}
            </Box>
          </Grid>
          <Grid item container xs={12} lg={12} md={12} mt={3} p={'0px 20px'}>
            {
              employeeDetails.projectHeaderNames.map((data) => (
                <Grid item xs={3} >
                  <Text greyLabel>{data}</Text>
                </Grid>
              ))
            }
          </Grid>
          <Grid item container xs={12} lg={12} md={12} mt={1.5}>
            {
              payrollHistory?.project_breakdown?.visa_types.map((data) => (
                <Grid item container xs={12} lg={12} md={12} spacing={2} my={1} p={'0px 12px'}>
                  <Box sx={{ background: '#F6F6F6', borderRadius: '6px', padding: '16px', width: '100%', alignItems: 'center' }}>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={3}>
                        <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                          <Box sx={{ background: data.color, height: '10px', width: '10px', borderRadius: '50%' }} />
                          <Text mediumBlack600>{data.name}</Text>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Text greyColor16>{data.total_placements}</Text>
                      </Grid>
                      <Grid item xs={3}>
                        <Text greyColor16>{data.placements_added}</Text>
                      </Grid>
                      <Grid item xs={3}>
                        <Text greyColor16>{data.placements_completed}</Text>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))
            }
          </Grid>
          <Grid item xs={12} lg={12} md={12} mt={2} textAlign='end' pr={1}>
            <Button saveBtn400 onClick={() => setOpenEmpBreakdownProject(false)}>Done</Button>
          </Grid>
        </Box>
      </ReusablePopup>
    </Grid >
  )
}

export default PayrollDashboard