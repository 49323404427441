import Chart from "react-apexcharts";

const LineChart = (props) => {
  const defaultOptions = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "straight",
    },
    title: {
      text: props.title ? props.title : "",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: props.categories ? props.categories : [],
    },
  };

  const options = props.options ? props.options : defaultOptions;
  const series = props.series;
  const height = props.height ? props.height : "160px";
  const width = props.width ? props.width : "100%";

  return (
    <Chart
      {...props}
      options={options}
      series={series}
      type="line"
      height={height}
      width={width}
    />
  );
};

export default LineChart;
