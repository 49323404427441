import { Grid } from "@mui/material"
import accountDeactivated from "../assets/svg/accountDeactivated.svg"
import Text from "../components/customText/Text"

function DeactivatedScreen() {
    return(
        <Grid container lg={12} md={12} sm={12} x={12} justifyContent={'center'} alignContent={'center'} height={'100vh'}>
            <Grid item container lg={12} md={12} sm={12} x={12} justifyContent={'center'} alignContent={'center'}>
                <img src={accountDeactivated} alt='img' />
            </Grid>
            <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
              <Text veryLargeLabel >Account Deactivated</Text>
            </Grid>
            <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
              <Text offBoardBodyFont>Looks like you have transferred your ownership to new</Text>
            </Grid>
            <Grid container justifyContent={'center'} alignContent={'center'}>
              <Text offBoardBodyFont>account.</Text>
            </Grid>
        </Grid>
    )
}
export default DeactivatedScreen