import BaseApi from '../../BaseApi';
import APIURL from '../../../config/development';
import LocalStorage from '../../../utils/LocalStorage';

class AnalyticsApiTimesheet {
    getAnalytics(year, quarter) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/analytics/counts?request_id=${LocalStorage.uid()}&year=${year}&quarter=${quarter}`, LocalStorage.getAccessToken());
    }

    getClientAnalytics(year, quarter, search, limit) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/analytics/client/listing?request_id=${LocalStorage.uid()}&year=${year}&quarter=${quarter}&search=${search}&limit=${limit}`, LocalStorage.getAccessToken());
    }

    getPlacementAnalytics(year, quarter, search, limit, page) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/analytics/placement/listing?request_id=${LocalStorage.uid()}&year=${year}&quarter=${quarter}&search=${search}&limit=${limit}&page=${page}`, LocalStorage.getAccessToken());
    }

    // PLacement Analytics
    getPlacementList(year, quarter, empId) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/analytics/employee/placements?request_id=${LocalStorage.uid()}&year=${year}&quarter=${quarter}&employee_id=${empId}`, LocalStorage.getAccessToken())
    }

    getClientCardDetails(year, quarter, id) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/analytics/client?request_id=${LocalStorage.uid()}&year=${year}&quarter=${quarter}&client_id=${id}`, LocalStorage.getAccessToken())
    }

    getPlacementCardDetails(year, quarter, placementId, id) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/analytics/placement?request_id=${LocalStorage.uid()}&year=${year}&quarter=${quarter}&placement_id=${placementId}&employee_id=${id}`, LocalStorage.getAccessToken())
    }

    exportApi(type) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/analytics/${type}/export?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    buyPlan(data) {
        return BaseApi.postWithToken(APIURL.AdminURL + `ai-timesheet-payment-initiated`, data, LocalStorage.getAccessToken())
    }

    upgradePlan(data) {
        return BaseApi.postWithToken(APIURL.AdminURL + `payment-initiated`, data, LocalStorage.getAccessToken());
    }

    upgradeMIVIPlan(data) {
        return BaseApi.postWithToken(APIURL.AdminURL + `mivi-payment-initiated`, data, LocalStorage.getAccessToken())
    }
    
    upgradeAIPlan(data){
        return BaseApi.postWithToken(APIURL.AdminURL + `ai-timesheet-payment-initiated`, data, LocalStorage.getAccessToken())
    }

}

export default new AnalyticsApiTimesheet();