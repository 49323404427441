import BaseApi from "../../BaseApi";
import APIURL from "../../../config/development";
import LocalStorage from "../../../utils/LocalStorage";

class OrganizationDetailsAPI {
    // get method
    getOrganizationDetails(params, token) {
        return BaseApi.getWithParams(APIURL.org_URL + "organization/index?request_id=" + params, token);
    }  
    // update method
    addOrganizationDetails(data, token) {
        return BaseApi.postWithToken(APIURL.org_URL + "organization/store", data, token);
    }
    // update method
    updateOrganizationDetails(data,id, token) {
        return BaseApi.putWithToken(APIURL.org_URL + `organization/update/${id}`, data, token);
    }
    
    purchaseHistory(data, token) {
        return BaseApi.postWithToken(APIURL.AdminURL + "my-profile/purchase-history", data, token);
    }

    downloadExport(data,) {
        return BaseApi.postWithToken(APIURL.AdminURL + `my-profile/download-invoice`, data, LocalStorage.getAccessToken())
    }

    getCardDetails(){
        return BaseApi.getWithParams(APIURL.AdminURL + `get-cards?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    makePrimaryCard(data){
        return BaseApi.postWithToken(APIURL.AdminURL + `update-card-details`, data, LocalStorage.getAccessToken())
    }
    
    deleteCard(data){
        return BaseApi.deleteWithToken(APIURL.AdminURL + `delete-card/${data.id}`, data, LocalStorage.getAccessToken())
    }

    addNewCard(data){
        return BaseApi.postWithToken(APIURL.AdminURL + `create-setup-intent`, data, LocalStorage.getAccessToken())
    }
  }
// eslint-disable-next-line
export default new OrganizationDetailsAPI();
