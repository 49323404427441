import BaseApi from "../../BaseApi";
import APIURL from "../../../config/development";
import LocalStorage from "../../../utils/LocalStorage";

class UserProfileApi {
    getUserProfileDetails() {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/get-profile?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }
    updateUserProfileDetails(data) {
        return BaseApi.putWithToken(APIURL.org_URL + "employee/profile/update", data, LocalStorage.getAccessToken());
    }
    changePassword(data) {
        return BaseApi.postWithToken(APIURL.org_URL + "changepassword", data, LocalStorage.getAccessToken());
    }
    updateUserProfile(data, id) {
        return BaseApi.putWithToken(APIURL.employee_URL + `employee/update-profile/${id}`, data, LocalStorage.getAccessToken())
    }
    getMyusers() {
        return BaseApi.getWithParams(APIURL.AdminURL + `my-profile/users-employees-count?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }
    transferOwnership(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + "my-profile/transfer-ownership", data, LocalStorage.getAccessToken());
    }
    subscriptionDetails() {
        return BaseApi.getWithParams(APIURL.AdminURL + `my-profile/subscription-info?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }
}
// eslint-disable-next-line
export default new UserProfileApi();