import React, { useState } from "react";
import { Box, Typography, Divider, Slide, DialogContent, Grid, Dialog, Stack } from "@mui/material";
import SidebarStyles from "./SidebarStyles";
// import Logo from '../assets/svg/logo.svg';
import Logo from '../../assets/logo.svg';
import DashboardLogo from '../../assets/svg/dashboard.svg';
import EmployeesLogo from '../../assets/svg/employees.svg';
import PlacementLogo from '../../assets/svg/placement.svg';
// import SalesLogo from '../../assets/svg/sales.svg';
import FiHevronLeft from '../../assets/svg/fi-hevron-left.svg';
// import BalanceSheetLogo from '../../assets/svg/balancesheet.svg';
// import ExpenseManagementLogo from '../../assets/svg/expense-management.svg';
// import clientLogo from '../../assets/svg/clientIcon.svg';
// import payrollLogo from '../../assets/svg/payrollLogo.svg';
// import EmpselfserLogo from '../../assets/svg/headphones.svg';
// import immigrationLogo from '../../assets/svg/immigration.svg';
import TimeSheetLogo from "../../assets/svg/TimesheetIcon.svg"
// import empSelfsSerLogo from '../../assets/svg/'
// import ledgerLogo from '../../assets/svg/ledger.svg';
// import SearchGlobal from '../../assets/svg/search2.svg';
// import Profile from '../../assets/svg/profile.svg';
// import Notification from '../../assets/svg/notification.svg';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
// import Invitelinkform from './employees/Stepper';
// import FormBox from './employees/TextInput';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Text from '../../components/customText/Text';
// import logo from '../../assets/images/codetru-sidebar.png';
import offBoardSave from "../../assets/svg/offBoardSave.svg"
import CustomButton from '../../components/customButton/Button';
import LocalStorage from "../../utils/LocalStorage";
import payrollIcon from '../../assets/svg/dashboard/payrollWhitebg.svg';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#393939",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #393939"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#393939",
        "&::before": {
            backgroundColor: "#393939",
            border: "1px solid #393939"
        }
    },
}));

export default function SidebarEmployee(props) {
    const classes = SidebarStyles();
    const navigate = useNavigate();
    let location = useLocation();
    const [sidebar, setSidebar] = useState({ open: false, subMenu: false, subMenuName: "" });
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialog-paper ": {
            borderRadius: "15px",
            width: "500px"
        }
    }));// eslint-disable-next-line
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });// eslint-disable-next-line
    const handleClose = () => {
        if (sidebar.open) {
            setSidebar(prev => ({ ...prev, open: !prev.open, subMenu: false }))
        }
    }
    const [openPopup, setOpenpopup] = useState(false)
    const [url, setUrl] = useState('')

    let placementRoutes = ["/placement/dashboard", "/placements/placementsInfo", "/placements/addclientAndendclient", "/placements/addBillingDetails", "/placements/addPlacement", "/placements/timesheets", "/placements/invoice", "/placements/dashboard", '/placements/view-placement']
    let timesheetRoutes = ["/timesheet", "/timesheet/view", "/timesheet/add-timesheet", "/timesheet/edit-timesheet", '/timesheet/summary', '/timesheet/view']
    // let salesRoutes = ["/sales/add-invoices", "/sales/invoices", "/sales/add-invoices", "/sales/invoices/invoices-history", "/sales/bills", "/sales/add-bills", "/sales/bills/bills-history"]
    // let ledgerRoutes = ["/ledgers/Newpayment", "/ledgers/NewBills", "/ledger"]
    // let balanceRoutes = ["/balance-sheet", "/balance-sheet/balance-view"]
    // let expenseRoutes = ["/expense-management", "/addExpense", "/addExpenseForm"]
    // let payrollRoute = ["/payroll", "/payroll-view", "/payroll-summary", "/pending", "/drafted", "/upcoming-payroll", "/summary", "/skipped"]
    // let clientRoute = ["/clients", "/addClient", "/clients/clients-user-profile", "/clients/end-clients-user-profile", '/vendor/user-profile']
    // let serviceRoute = ["/employee-self-service", "/employee-self-service/chat-panel", "/employee-self-service/raise-request"]

    let formsIncude = ['/employees/onboard', '/employees/add', '/timesheet/add-timesheet', '/placements/addclientAndendclient', '/addClient', '/addVendor', '/addEnd-Client']

    const checkEmployee = () => {
        return location.pathname.includes('/employees/user-profile') || location.pathname.includes('user-profile')
    }


    let urlLogo = (props.logoUrl != undefined && props.logoUrl != '') ? props.logoUrl : (LocalStorage.getUserData().logo_url != undefined && LocalStorage.getUserData().logo_url != '') ? LocalStorage.getUserData().logo_url : Logo

    const payrollHandler = (args, submenu) => {
        if (submenu == true) {
            setSidebar({ ...sidebar, open: false })
            navigate(args);
        } else {
            setSidebar({ ...sidebar, open: true })
        }
    }
    let payrollRoutes = ['/balanceSheet', '/expenseDashboard']

    return (
        <>
            {location.pathname !== "/configuration" ?
                <Box component={"section"} className={`${classes.sidebar} ${sidebar.open ? classes.sidebarOpen : null}`} sx={{ overflow: 'hidden' }}>
                    {formsIncude.includes(location.pathname) || props.formEdit ?
                        <Box onClick={() => { setOpenpopup(true); handleClose(); setUrl('/') }} className={classes.brand} >
                            <img src={urlLogo} alt="Logo" style={{ height: "54px", width: '54px', borderRadius: '50%' }} />
                        </Box>
                        :
                        <Link to={'/'} className={classes.brand}>
                            <img src={urlLogo} alt="Logo" style={{ height: "54px", width: '54px', borderRadius: '50%' }} />
                            {/* <img src={Logo} alt="Logo" />  <span style={{ margin: "0px 14px" }}>BrownMonster <br/><span style={{fontSize:"12px"}}> Efficiency made easy </span></span> */}
                        </Link>
                    }
                    <Box sx={{ position: "relative", }}>
                        <button
                            onClick={() => setSidebar(prev => ({ ...prev, open: !prev.open, subMenu: false }))}
                            style={{ all: "unset", cursor: "pointer", position: "fixed", zIndex: 300, left: `${sidebar.open ? "16.4%" : "80px"}`, top: "64px", padding: "0px", margin: "0px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", transform: `${sidebar.open ? "rotate(180deg)" : "rotate(0deg)"}`, transition: "all .3s ease", }}>
                            <img src={FiHevronLeft} alt="FiHevronLeft" />
                        </button>
                    </Box>
                    <ul className={classes.sideMenu}>
                        <li>
                            <HtmlTooltip
                                placement="right"
                                sx={{ display: `${sidebar.open ? "none" : "block"}` }}
                                arrow
                                title={
                                    <React.Fragment>
                                        <Box className={classes.sideTooltip}>
                                            <Typography className={classes.sideTooltipText}>
                                                Dashboard
                                            </Typography>
                                        </Box>
                                    </React.Fragment>
                                }
                            >
                                {formsIncude.includes(location.pathname) || props.formEdit ?
                                    <Box onClick={() => { setOpenpopup(true); handleClose(); setUrl('/dashboard') }} className={`${classes.sidebarLink} ${location.pathname === "/dashboard" ? classes.sidebarLinkActive : null}`} >
                                        <img src={DashboardLogo} alt="dashboard" style={{ filter: `${location.pathname === "/dashboard" ? "brightness(0) invert(1)" : "brightness(0)"}` }} />
                                        <span className={`${sidebar.open ? classes.sidebarLinkTextMs : classes.sidebarLinkTextMl} `} style={{ fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", color: `${location.pathname === "/mainDashboard" ? "#FFFFFF" : "rgba(24, 26, 24, 1)"}`, transition: "all .3s ease", }} >Dashboard</span>
                                    </Box>
                                    :
                                    <Link to={'/dashboard'} className={`${classes.sidebarLink} ${location.pathname === "/dashboard" ? classes.sidebarLinkActive : null}`} onClick={handleClose}>
                                        <img src={DashboardLogo} alt="dashboard" style={{ filter: `${location.pathname === "/dashboard" ? "brightness(0) invert(1)" : "brightness(0)"}` }} />
                                        <span className={`${sidebar.open ? classes.sidebarLinkTextMs : classes.sidebarLinkTextMl} `} style={{ fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", color: `${location.pathname === "/mainDashboard" ? "#FFFFFF" : "rgba(24, 26, 24, 1)"}`, transition: "all .3s ease", }} >Dashboard</span>
                                    </Link>
                                }
                            </HtmlTooltip>
                        </li>
                        <li style={{ padding: '6px 10px' }}> <Divider /></li>
                        <li>
                            <HtmlTooltip
                                placement="right"
                                sx={{ display: `${sidebar.open ? "none" : "block"}` }}
                                arrow
                                title={
                                    <React.Fragment>
                                        <Box className={classes.sideTooltip}>
                                            <Typography className={classes.sideTooltipText}>
                                                My Profile
                                            </Typography>
                                        </Box>
                                    </React.Fragment>
                                }
                            >
                                {formsIncude.includes(location.pathname) || props.formEdit ?
                                    <Box onClick={() => { setOpenpopup(true); handleClose(); setUrl('/user-profile') }} className={`${classes.sidebarLink} ${["/user-profile", "/employees/add", "/employees/onboard", "/employees/user-profile"].includes(location.pathname) || checkEmployee() ? classes.sidebarLinkActive : null}`} >
                                        <img src={EmployeesLogo} alt="employees" style={{ filter: `${["/user-profile"].includes(location.pathname) || checkEmployee() ? "brightness(0) invert(1)" : "brightness(0)"}` }} />
                                        <span className={`${sidebar.open ? classes.sidebarLinkTextMs : classes.sidebarLinkTextMl} `} style={{ fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", color: `${["/user-profile"].includes(location.pathname) || checkEmployee() ? "#FFFFFF" : "rgba(24, 26, 24, 1)"}` }} >My Profile</span>
                                    </Box>
                                    :
                                    <Link to={'/user-profile'} className={`${classes.sidebarLink} ${["/user-profile"].includes(location.pathname) || checkEmployee() ? classes.sidebarLinkActive : null}`} onClick={handleClose}>
                                        <img src={EmployeesLogo} alt="employees" style={{ filter: `${["/user-profile"].includes(location.pathname) || checkEmployee() ? "brightness(0) invert(1)" : "brightness(0)"}` }} />
                                        <span className={`${sidebar.open ? classes.sidebarLinkTextMs : classes.sidebarLinkTextMl} `} style={{ fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", color: `${["/user-profile"].includes(location.pathname) || checkEmployee() ? "#FFFFFF" : "rgba(24, 26, 24, 1)"}` }} >My Profile</span>
                                    </Link>
                                }
                            </HtmlTooltip>
                        </li>
                        <li>
                            <HtmlTooltip
                                placement="right"
                                sx={{ display: `${sidebar.open ? "none" : "block"}` }}
                                arrow
                                title={
                                    <React.Fragment>
                                        <Box className={classes.sideTooltip}>
                                            <Typography className={classes.sideTooltipText}>
                                                Placements
                                            </Typography>
                                        </Box>
                                    </React.Fragment>
                                }
                            >
                                {formsIncude.includes(location.pathname) || props.formEdit ?
                                    <Box onClick={() => { setOpenpopup(true); handleClose(); setUrl('/placement/dashboard') }} className={`${classes.sidebarLink} ${placementRoutes.includes(location.pathname) ? classes.sidebarLinkActive : null}`} >
                                        <img src={PlacementLogo} alt="placement" style={{ filter: `${placementRoutes.includes(location.pathname) ? "brightness(0) invert(1)" : "brightness(0)"}` }} />
                                        <span className={`${sidebar.open ? classes.sidebarLinkTextMs : classes.sidebarLinkTextMl} `} style={{ fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", color: `${placementRoutes.includes(location.pathname) ? "#FFFFFF" : "rgba(24, 26, 24, 1)"}` }}>Placements</span>
                                    </Box>
                                    :
                                    <Link to={"/placement/dashboard"} className={`${classes.sidebarLink} ${placementRoutes.includes(location.pathname) ? classes.sidebarLinkActive : null}`} onClick={handleClose}>
                                        <img src={PlacementLogo} alt="placement" style={{ filter: `${placementRoutes.includes(location.pathname) ? "brightness(0) invert(1)" : "brightness(0)"}` }} />
                                        <span className={`${sidebar.open ? classes.sidebarLinkTextMs : classes.sidebarLinkTextMl} `} style={{ fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", color: `${placementRoutes.includes(location.pathname) ? "#FFFFFF" : "rgba(24, 26, 24, 1)"}` }}>Placements</span>
                                    </Link>
                                }
                            </HtmlTooltip>
                        </li>
                        <li>
                            <HtmlTooltip
                                placement="right"
                                sx={{ display: `${sidebar.open ? "none" : "block"}` }}
                                arrow
                                title={
                                    <React.Fragment>
                                        <Box className={classes.sideTooltip}>
                                            <Typography className={classes.sideTooltipText}>
                                                Timesheet
                                            </Typography>
                                        </Box>
                                    </React.Fragment>
                                }
                            >
                                {formsIncude.includes(location.pathname) || props.formEdit ?
                                    <Box onClick={() => { setOpenpopup(true); handleClose(); setUrl('/timesheet') }} className={`${classes.sidebarLink} ${timesheetRoutes.includes(location.pathname) ? classes.sidebarLinkActive : null}`} >
                                        <img src={TimeSheetLogo} alt="employees" style={{ filter: `${timesheetRoutes.includes(location.pathname) ? "brightness(0) invert(1)" : "brightness(0)"}` }} />
                                        <span className={`${sidebar.open ? classes.sidebarLinkTextMs : classes.sidebarLinkTextMl} `} style={{ fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", color: `${timesheetRoutes.includes(location.pathname) ? "#FFFFFF" : "rgba(24, 26, 24, 1)"}` }}>Timesheet</span>
                                    </Box>
                                    :
                                    <Link to={'/timesheet'} className={`${classes.sidebarLink} ${timesheetRoutes.includes(location.pathname) ? classes.sidebarLinkActive : null}`} >
                                        <img src={TimeSheetLogo} alt="employees" style={{ filter: `${timesheetRoutes.includes(location.pathname) ? "brightness(0) invert(1)" : "brightness(0)"}` }} />
                                        <span className={`${sidebar.open ? classes.sidebarLinkTextMs : classes.sidebarLinkTextMl} `} style={{ fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", color: `${timesheetRoutes.includes(location.pathname) ? "#FFFFFF" : "rgba(24, 26, 24, 1)"}` }}>Timesheet</span>
                                    </Link>
                                }
                            </HtmlTooltip>
                        </li>
                        <li>
                        <HtmlTooltip
                                placement="right"
                                sx={{ display: `${sidebar.open ? "none" : "none"}` }}
                                arrow
                                title={
                                    <React.Fragment>
                                        <Box className={classes.sideTooltip}>
                                            <Typography className={classes.sideTooltipText}>
                                                Payroll
                                            </Typography>
                                        </Box>
                                    </React.Fragment>
                                }
                            >
                                {formsIncude.includes(location.pathname) || props.formEdit ?
                                    <Box onClick={() => { setOpenpopup(true); handleClose(); setUrl("/expenseDashboard");setOpenpopup(true); LocalStorage.removeRedirectedModule() }} className={`${classes.sidebarLink} ${payrollRoutes.includes(location.pathname) ? classes.sidebarLinkActive : null}`}>
                                        <img src={payrollIcon} alt="payroll" style={{ filter: `${payrollRoutes.includes(location.pathname) ? "brightness(0) invert(1)" : "brightness(0)"}` }} />
                                        <span className={`${sidebar.open ? classes.sidebarLinkTextMs : classes.sidebarLinkTextMl} `} style={{ fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", color: `${payrollRoutes.includes(location.pathname) ? "#FFFFFF" : "rgba(24, 26, 24, 1)"}` }}>Payroll</span>
                                    </Box>
                                    :
                                    <Box>
                                        {
                                            sidebar.open ?
                                                <Grid container sx={{ background: '#FAFAFA', padding: '10px 0px' }}>
                                                    <Grid item xs={12}>
                                                        <Box display='flex' flexDirection='row' gap={2} alignItems='center' pl={1.5}>
                                                            <img src={payrollIcon} alt="payroll" style={{ filter: "brightness(0)" }} />
                                                            <span style={{ fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", color: "rgba(24, 26, 24, 1)" }}>Payroll</span>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ padding: '10px 10px 10px 2px' }}>
                                                        <Box display='flex' flexDirection='column' gap={1}>
                                                            <Box className={location.pathname == '/balanceSheet' ? classes.payrollSideMenuActive : classes.payrollSideMenu} onClick={() => payrollHandler('/balanceSheet', true)}>Balance Sheet</Box>
                                                            <Box className={location.pathname == '/expenseDashboard' ? classes.payrollSideMenuActive : classes.payrollSideMenu} onClick={() => payrollHandler('/expenseDashboard', true)}>Expense Management</Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid> :
                                                <Box onClick={() => payrollHandler('/expenseDashboard')} className={`${classes.sidebarLink} ${payrollRoutes.includes(location.pathname) ? classes.sidebarLinkActive : null}`} >
                                                    <img src={payrollIcon} alt="payroll" style={{ filter: `${payrollRoutes.includes(location.pathname) ? "brightness(0) invert(1)" : "brightness(0)"}` }} />
                                                    <span className={`${sidebar.open ? classes.sidebarLinkTextMs : classes.sidebarLinkTextMl} `} style={{ fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", color: `${payrollRoutes.includes(location.pathname) ? "#FFFFFF" : "rgba(24, 26, 24, 1)"}` }}>Payroll</span>
                                                </Box>
                                        }
                                    </Box>
                                }
                            </HtmlTooltip>
                        </li>                        
                    </ul>
                </Box> :
                <Box component={"section"} className={`${classes.sidebar2}`}>
                    <Box className={classes.brand}>
                        <img src={Logo} alt="Logo" style={{ height: "54px" }} />
                    </Box>
                </Box>}
            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={openPopup}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent sx={{ margin: "50px", }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={offBoardSave} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Your progress will not be saved</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <CustomButton smallBlackOutline onClick={() => { setOpenpopup(false) }} >
                                Cancel
                            </CustomButton>
                            <CustomButton smallBlue redBackground onClick={() => { navigate(url); setOpenpopup(false); LocalStorage.removeEmployeeVerifyId(); props.setformEdit(false); LocalStorage.setEditForm(false) }} >
                                Yes
                            </CustomButton>
                        </Stack>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        </>
    )
};