import { Box, Breadcrumbs, Grid, Tab, Tabs, Typography, Avatar} from '@mui/material'
import React,{useEffect} from 'react'
import Text from '../../components/customText/Text'
import { styled } from "@mui/styles";
import Button from '../../components/customButton/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocalStorage from '../../utils/LocalStorage';
import { NoPermission } from '../../utils/utils';
import infoCircle from '../../assets/images/info-circle.png';
import AppIntegrationsStyles from '../../views/admin/appIntegrations/AppIntegrationsStyles';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import TourGuideConfigApi from '../../apis/configurations/tourguideConfig/TourGuideConfigApi';
import {  useNavigate } from 'react-router-dom';


const ConfigurationTab = ['All Apps', 'Installed Apps'];

// custom Tabs container
const TabsContainer = styled(Box)({
    borderBottom: '1px solid #C7CCD3',
    display: 'inline-block', // Display as inline-block
    width: 'auto', // Set width to fit content
    overflowX: 'auto', // Enable horizontal scrolling if needed
    whiteSpace: 'nowrap', // Prevent line breaks
});

// custom Tabs
const CustomTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        borderBottom: '1px solid #0C75EB !important',
    },
});

// custom Tab
const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    fontFamily: "Quicksand !important",
    color: "#737373 !important",
    fontSize: "15px !important",
    fontWeight: `${600} !important`,
    padding: "8px 10px !important",
    textTransform: 'none !important',
    minWidth: "130px !important",
    // minWidth: 0,
    '&.Mui-selected': {
        color: '#0C75EB !important',
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff !important',
    },
}));


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ width: "100%" }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function AppIntegrations({setStartTour}) {
    const navigate = useNavigate();
    const classes = AppIntegrationsStyles();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const data = [
        {
            id: "131e4124-c32b-40f5-bacc-62ccbeefa5f5",
            app_name: "ABC Books",
            subject: "ABC Books Integrations by Japfu",
            description: "Transfer Timesheet Data and create invoices for your clients",
            redirection_show: false,
            connection_status: "connected",
            redirect_to: "organization",
            help_documentation_url: ""
        }
    ]
    const countData = {
        migrated_count: 0,
        total_count: 0,
        error_count: 0,
        not_migrated_count: 0,
        show_progress: false

    }

    const steps = [
        {
            element: '#integration', popover: {
                title: 'Integrations', description:
                    'Use the integration screen to connect and sync your tools, apps, and systems for a seamless workflow.', side: "right",
                align: 'start'
            }
        },]

        useEffect(() => {
            const driverObj = driver({
                popoverClass: 'driverjs-theme',
                allowClose: false,
                stagePadding: 0,
                stageRadius: 0,
                animate: false,
                allowKeyboardControl: false,
                disableActiveInteraction: true,
                steps: steps,
                popoverOffset: 15,
    
                onPopoverRender: (popover, opts) => {//creates the popover with required design 
                    const currentStep = opts.state.activeIndex;
                    const totalSteps = opts.config.steps.length;
                    const updateTourGuideStatus = async (tourModule) => {
                        await TourGuideConfigApi.updateApi({
                            "request_id": LocalStorage.uid(),
                            "module": tourModule,
                            "status": true
                        })
                    }
                    const title = document.createElement('div');
                    title.style.display = 'flex';
                    title.style.alignItems = 'center';
                    title.style.marginBottom = '8px';
    
                    
                    const textContainer = document.createElement('div');
                    textContainer.style.display = 'flex';
                    textContainer.style.justifyContent = 'space-between';
                    textContainer.style.width = '100%';
                    const titleText = document.createElement('p');
                    titleText.textContent = opts.config.steps[currentStep].popover.title;
                    titleText.style.fontFamily = 'Quicksand';
                    titleText.style.fontSize = '15px';
                    titleText.style.fontWeight = '700';
                    const stepSpan = document.createElement('span');
                    stepSpan.textContent = `(${currentStep + 1}/${totalSteps})`;
                    stepSpan.style.display = 'flex';
                    stepSpan.style.alignItems = 'center';
                    stepSpan.style.fontSize = '12px';
                    stepSpan.style.fontWeight = '600';
                    stepSpan.style.color = '#525252';
                    stepSpan.style.fontFamily = 'Quicksand';
                    textContainer.appendChild(titleText);
                    textContainer.appendChild(stepSpan);
                    title.appendChild(textContainer);
    
                    const description = document.createElement('div');
                    description.innerText = opts.config.steps[currentStep].popover.description;
                    description.style.marginBottom = '24px';
                    description.style.fontSize = '14px';
                    description.style.fontWeight = '400';
                    description.style.color = '#404040';
                    description.style.fontFamily = 'Quicksand';
    
                    const footer = document.createElement('div');
                    footer.style.display = 'flex';
                    footer.style.justifyContent = 'space-between';
                    footer.style.width = '100%';
                    footer.style.boxSizing = 'border-box';
                    const applyInviteLinkBtnStyles = (element, text) => {
                        element.style.cursor = 'pointer';
                        element.style.textAlign = 'center';
                        element.style.fontSize = '12px';
                        element.style.fontFamily = 'Quicksand';
                        element.style.fontWeight = '600';
                        element.style.width = (text == 'finish') ? '100%' : '130px';
                        element.style.height = '32px';
                        element.style.border = '1.5px solid rgba(12, 117, 235, 1)';
                        element.style.borderRadius = '4px';
                        element.style.color = (text == 'skip') ? '#0C75EB' : '#ffffff';
                        element.style.transition = 'all .3s ease';
                        element.style.outline = 'none';
                        element.style.backgroundColor = (text == 'skip') ? '#ffffff' : '#0C75EB';
    
                    };
                   
                    
                    const finishButton = document.createElement('button');
                    finishButton.innerText = 'Finish';
                    applyInviteLinkBtnStyles(finishButton, 'finish');
                    finishButton.onclick = () => {
                        LocalStorage.setStartTour({ 'appintegrations': false });
                        updateTourGuideStatus('APP_INTEGRATIONS');
                        setStartTour();
                        driverObj.destroy();
                        if(LocalStorage.getUserData().org_config_set == false){
                            navigate('/configuration');
                        }
                    };
                    
                    
                        footer.appendChild(finishButton);
                    
                    popover.wrapper.innerHTML = '';
                    const arrow = popover.arrow;
                    if (arrow) {
                        popover.wrapper.appendChild(arrow);
                    }
                    popover.wrapper.appendChild(title);
                    popover.wrapper.appendChild(description);
                    popover.wrapper.appendChild(footer);
    
                }
            });
            setTimeout(() => {
                driverObj.drive();
            }, 500);
    
            return () => {
                driverObj.destroy();
            };
            // eslint-disable-next-line
        }, [])
    return (
        <Box className={classes.mainBox}>
            <Box mx={2}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography className={classes.breadcrumbsLink}>Profile</Typography>
                    <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>App Integrations</Text>
                </Breadcrumbs>
            </Box>
            {
                LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "app_integrations_view" && item.is_allowed == true)) ?
                    <>
                        <Grid container item lg={12} md={12} sm={12} xs={12} pt={2}>
                            <Box sx={{ borderBottom: '1px', borderColor: 'divider', width: "100%" }}>
                                <TabsContainer>
                                    <CustomTabs value={0} variant="scrollable" scrollButtons="auto">
                                        {
                                            ConfigurationTab.map((item, key) => (
                                                <CustomTab key={key} label={item} {...a11yProps(0)} />
                                            ))
                                        }
                                    </CustomTabs>
                                </TabsContainer>
                                <Text pt={1} largeLabel sx={{ fontWeight: `${400} !important` }}>Third party applications to make the best use of Japfu</Text>
                            </Box>
                        </Grid>
                        <CustomTabPanel value={0} index={0} >
                            <Grid container columnGap={3}>
                                {
                                    data.map((item) => (
                                        <Grid item lg={2.8} md={3.5} sm={5} xs={12} pt={3} >
                                            <Box id='integration'>
                                            <Box className={classes.cardBox} >
                                                <Grid container columnSpacing={1}>
                                                    <Grid item lg={5} md={5} sm={5} xs={5}>
                                                    <Avatar sx={{ background: '#e5b256', width: '35px', height: '35px' }} ><Typography sx={{ textTransform: 'capitalize' }}>A</Typography></Avatar>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={6} textAlign={"-webkit-right"}>
                                                        <Box className={classes.invoicingBox}>Invoicing</Box>
                                                    </Grid>
                                                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>

                                                        <img src={infoCircle} alt="infoCircle" height={"16px"} style={{ marginBottom: "0px", cursor: "pointer" }} />

                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                                        <Text mediumGreyHeader sx={{ font: "10px Quicksand !important" }}>{item.subject}</Text>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                                        <Text mediumGreyHeader sx={{ font: "9px Quicksand !important" }}>{item.description}</Text>
                                                    </Grid>
                                                    <Grid item container lg={12} md={12} sm={12} xs={12} pt={2}>
                                                           <>
                                                                    {
                                                                        item.connection_status == 'not_connected' ?
                                                                            <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent='end'>
                                                                                <Button saveBtn className={classes.configureButton} classes={{ endIcon: classes.endIcon }} endIcon={<ArrowForwardIosIcon sx={{ fontSize: "15px !important" }} />}>Configure</Button>
                                                                            </Grid> :
                                                                            (item.connection_status == 'connected') && (item.redirect_to == 'organization' || item.redirect_to == 'authorization') ?
                                                                                <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent='end'>
                                                                                    <Button saveBtn className={classes.configureButton} >{item.redirect_to == 'authorization' ? 'Disconnect' : 'Configure'}</Button>
                                                                                </Grid> :
                                                                                item.connection_status == 'connected' ?
                                                                                    <Grid item lg={12} md={12} sm={12} xs={12} container alignItems='center'>
                                                                                        {/* <Grid item lg={6} md={5} sm={5} xs={12}> */}
                                                                                        <Box display='flex' flexDirection='row' gap={1} sx={{ height: '22px', minWidth: '100px', padding: '4px', border: '1px solid #0C75EB', borderRadius: '8px', alignItems: 'center' }}>
                                                                                            <Text mediumBlue>Count : {countData.migrated_count} of {countData.total_count}</Text>
                                                                                        </Box>
                                                                                        {/* </Grid> */}
                                                                                    </Grid> :
                                                                                    <Grid item lg={12} md={12} sm={12} xs={12} container alignItems='center'>
                                                                                        {/* <Grid item lg={6} md={5} sm={5} xs={12} textAlign='start'> */}
                                                                                        <Box display='flex' flexDirection='row' textAlign='start' gap={1} sx={{ height: '22px', minWidth: '100px', padding: '4px', border: '1px solid #0C75EB', borderRadius: '8px', alignItems: 'center' }}>
                                                                                            <Text mediumBlue>Count : {countData.migrated_count} of {countData.total_count}</Text>
                                                                                        </Box>
                                                                                        {/* </Grid> */}
                                                                                        <Grid item lg={5} md={5} sm={5} xs={12} textAlign='end'>
                                                                                            <Button saveBtn className={classes.configureButton} classes={{ endIcon: classes.endIcon }} endIcon={<ArrowForwardIosIcon sx={{ fontSize: "15px !important" }} />}>Configure</Button>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                    }
                                                                </> 

                                                    </Grid>
                                                </Grid>
                                            </Box></Box>
                                        </Grid>
                                    ))
                                }
                            </Grid >
                        </CustomTabPanel >

                    </>
                    :
                    <>
                        {NoPermission()}
                    </>
            }

        </Box >
    )
}

export default AppIntegrations 