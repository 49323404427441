import React from 'react'
import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material'
import Text from '../../../../../components/customText/Text'
import { useState } from 'react'
import LocalStorage from '../../../../../utils/LocalStorage'
import { useEffect } from 'react'
import Button from '../../../../../components/customButton/Button'
import PropTypes from 'prop-types';// eslint-disable-next-line
import { isValid, isValidMulti, validate_emptyField } from '../../../../../components/Validation'// eslint-disable-next-line
import { addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, dateFormat, removeLoader } from '../../../../../utils/utils'
import EducationForm from './EducationForm'
import OnBoardApi from '../../../../../apis/admin/employees/OnBoardApi'
// import EmployeesApi from '../../../../../apis/admin/employees/EmployeesApi'
import ReusableClearAll from '../../../../../components/reuablePopup/ReusableClearAll'
import LoadingButton from '../../../../../components/customButton/LoadingButton'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Text>{children}</Text>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function EducationalDetails({ value, setValue, docStepper, setDocStepper, inviteData, setInviteData }) {
    // const [deleteDocsEnable, setDeleteDocsEnable] = useState(false);
    const emp_id = LocalStorage.getEmployeeId();

    const [open, setOpen] = useState(false);
    const [education, setEducation] = useState({
        id: '',
        documents: [],
        documents_deleted_ids: [],
        clear_all: false
    })

    useEffect(() => {
        if (emp_id) {
            educIndexApi(emp_id)
        }
        // eslint-disable-next-line
    }, [])

    const handleFormStates = (param) => {
        for (const key in param.upload_documents) {
            const dataArray = param.upload_documents[key].data;
            if (dataArray.length >= 1) {
                const item = dataArray[0];
                if (item.name == "education_documents") {
                    var eduDocs = education.documents;
                    for (var i in dataArray) {
                        if (dataArray[i].approval_status == "Approved") {
                            eduDocs.push({
                                document_url: dataArray[i].document_url,
                                slug: "invite_via_link",
                                name: dataArray[i].document_name,
                                document_slug: dataArray[i].name,
                                id: "",
                                new_document_id: dataArray[i].id
                            })
                        }
                    }
                    setEducation({ ...education, documents: eduDocs })
                }
            }
        }
    }
    const [loader, setLoader] = useState(false);

    const handleSubmit = () => {
        education['request_id'] = LocalStorage.uid();
        education['employee_id'] = emp_id;
        if (education.id != "") {
            if (education.documents.length == 0) {
                education['clear_all'] = true
            }
            // if (education.documents.length == 0) {
            //     var deleteObj = {
            //         request_id: LocalStorage.uid(),
            //         employee_id: emp_id,
            //         id: education.id
            //     }
            //     EmployeesApi.deleteEmployeeEducation(deleteObj, education.id).then((res) => {
            //         if (res.data.statusCode === 1003) {
            //             addSuccessMsg(`Education Details Updated Successfully`);
            //             educIndexApi(emp_id);
            //             setDocStepper(docStepper + 1);
            //             setValue(value + 1);
            //         } else {
            //             addErrorMsg(res.data.message);
            //         }
            //     })

            // } else {
                setLoader(true)
            OnBoardApi.educationUpdate(education, education.id ? education.id : '', LocalStorage.getAccessToken()).then((res) => {
                if (res.data.statusCode === 1003) {
                    if (education.documents.length == 0) {
                        education.id = "";
                        setEducation({ ...education });
                    }
                    addSuccessMsg(`Education Details Updated Successfully`);
                    educIndexApi(emp_id);
                    setDocStepper(docStepper + 1);
                    setValue(value + 1);
                    setLoader(false)
                } else {
                    addErrorMsg(res.data.message);
                    setLoader(false)
                }
            })
            // }
        } else {
            if (education.documents.length == 0) {
                setDocStepper(docStepper + 1);
                setValue(value + 1);
            }
            if (education.documents.length > 0) {
                setLoader(true)
                OnBoardApi.educationStore(education, LocalStorage.getAccessToken()).then((res) => {
                    if (res.data.statusCode === 1003) {
                        if (inviteData != null) {
                            inviteData.upload_documents.education_documents.data = [];
                            setInviteData({ ...inviteData });
                        }
                        educIndexApi(emp_id);
                        addSuccessMsg(`Education Details Added Successfully`);
                        setDocStepper(docStepper + 1);
                        setValue(value + 1);
                        setLoader(false)
                    } else {
                        addErrorMsg(res.data.message);
                        setLoader(false)
                    }
                })
            }
        }

    }

    const nextStep = () => {
        setValue(value + 1);
        setDocStepper(3);
    }

    const back = () => {
        setValue(value - 1);
        setDocStepper(docStepper - 1);
    }

    const clearAll = () => {
        if (inviteData != null) {
            inviteData.upload_documents.education_documents.data = [];
            setInviteData({ ...inviteData });
        }
        setEducation({
            ...education,
            documents: [],
            clear_all: true
        })
        setOpen(false);
    }

    const educIndexApi = (id) => {
        OnBoardApi.educationIndex(LocalStorage.uid(), id ? id : '', LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                if (res.data.data.documents.length > 0) {
                    setEducation({
                        ...education,
                        id: res.data.data.id,
                        employee_id: res.data.data.employee_id,
                        documents: res.data.data.documents,
                        documents_deleted_ids: []
                    })
                }
                else {
                    if (inviteData != null) {
                        handleFormStates(inviteData);
                    } else {
                        setEducation({
                            ...education,
                            id: res.data.data.id,
                            employee_id: res.data.data.employee_id,
                            documents: [],
                            documents_deleted_ids: []
                        })
                    }

                }
            }
        })
    }

    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card sx={{ padding: '10px !important', boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.05) !important", borderRadius: '15px' }}>
                <CardHeader sx={{ padding: '15px 0px 0px 23px !important' }} title={
                    <Text largeBlack>Add Education Details</Text>
                } />
                <CardContent sx={{ padding: '30px 0px 20px 20px !important' }}
                >
                    <Grid item lg={12} md={12} xs={12} sm={12}>
                        <EducationForm
                            education={education}
                            setEducation={setEducation}
                            handleSubmit={handleSubmit}
                        />
                    </Grid>
                </CardContent>
            </Card>
            <Grid item container m={'25px 0px 10px 0px'}>
                <Grid item lg={4} md={4}>
                    <Button blackCancel onClick={back}>Back</Button>
                </Grid>
                <Grid item lg={8} md={8} textAlign='end'>
                    <Button blackCancel sx={{ marginRight: '10px', border: "none !important" }} onClick={() => { nextStep() }} >Skip</Button>
                    {education.documents.length > 0 && <Button clearall sx={{ marginRight: '15px !important' }} onClick={() => setOpen(true)}>Clear all</Button>}
                    <LoadingButton loading={loader} saveNcontinue onClick={handleSubmit}>Save & Continue</LoadingButton>
                </Grid>
            </Grid>
            {
                open && <ReusableClearAll open={open} setOpen={setOpen} onClick={() => { clearAll() }} />
            }
        </Grid >
    )
}

export default EducationalDetails