import { Avatar, AvatarGroup, Box, Divider, Grid } from "@mui/material";
import React from "react";
import Text from "../../../components/customText/Text";
import LocalStorage from "../../../utils/LocalStorage"
import CustomSelect from "../../../components/customSelect/CustomSelect";
import { BlackToolTip, BorderLinearProgress, NoDataFound } from "../../../utils/utils";
import Chart from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';

function EmployeeAndClientAttribution(props) {
    const { dropDownChangeHandler,classes, placementsCompleted, years, totalEmp,pieData, linearProgress,
        capitalizeAndAddSpace, companies, yearDropdwn, areaOptions, placementsAdded, placementDetails, navigate }
        = props
return (
    <>
        <Grid container spacing={2} pt={2} alignItems='flex-start'>
            <Grid item container lg={6} md={12} sm={12} xs={12} justifyContent='center' id='employeechart'>
                <Grid item lg={12} md={6} sm={12} xs={12} mb={2}>
                    <Text smallLabelblack16>Employees Data</Text>
                </Grid>
                {
                    (totalEmp.consultant == 0 && totalEmp.contractor == 0) ?
                        <Grid item container lg={12} md={8} sm={12} xs={12} pt={2} className={classes.nodata} textAlign='center' justifyContent='center'>
                            {NoDataFound('')}
                        </Grid> :
                        <Grid item xl={10} lg={12} md={12} sm={12} xs={12} pt={2} container justifyContent={'center'} textAlign='center' sx={{ maxHeight: '280px', overflowY: 'scroll', "&::-webkit-scrollbar": { display: 'none !important' } }}>
                            <Chart
                                options={pieData.options}
                                series={pieData.series}
                                type="donut"
                                height='210px'
                                width='210px'
                            />
                            <Grid item container lg={12} justifyContent='center' textAlign='center' pt={2}>
                                <Grid item container lg={6} sm={6} xs={12}>
                                    <Box display='flex' flexDirection='row' gap={1}>
                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                            <Box className={classes.greenDot} />
                                            <Text BlackExtraDark>Consultants</Text>
                                        </Box>
                                        <Text largeLightBlue sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={() => {
                                            LocalStorage.removeRedirectedModule();
                                            LocalStorage.removeFromPage();
                                            navigate('/employees', { state: { from: 'consultant' } })
                                        }}
                                        >{totalEmp.consultant}</Text>
                                    </Box>
                                </Grid>
                                <Grid item container lg={6} sm={6} xs={12} textAlign='start'>
                                    <Box display='flex' flexDirection='row' gap={1}>
                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                            <Box className={classes.pinkDot} />
                                            <Text BlackExtraDark>Contractor</Text>
                                        </Box>
                                        <Text largeLightBlue sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={() => {
                                            LocalStorage.removeRedirectedModule();
                                            LocalStorage.removeFromPage();
                                            navigate('/employees', { state: { from: 'contractor' } })
                                        }}>{totalEmp.contractor}</Text>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                }
            </Grid>
            <Grid item container lg={6} md={12} sm={12} xs={12} justifyContent='center'  id='clientchart'>
                <Grid item lg={12} md={12} sm={12} xs={12} pl={2} mb={2}>
                    <Text smallLabelblack16>Clients</Text>
                </Grid>
                {
                    companies.length == 0 ?
                        <Grid item container lg={12} md={8} sm={12} xs={12} pt={2} className={classes.nodata} textAlign='center' justifyContent='center'>
                            {NoDataFound('')}
                        </Grid> :
                        <Grid item container textAlign='center' alignItems='center' lg={12} md={12} sm={12} xs={12} spacing={2} mt={1} pl={2} sx={{ maxHeight: '280px', overflowY: 'scroll', "&::-webkit-scrollbar": { display: 'none !important' } }}>
                            {
                                companies.length > 0 ? companies.map((item, index) => (
                                    <>
                                        <Grid item lg={1} md={1} sm={1} xs={1}><Text black400>{index + 1}</Text></Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} textAlign='start'>
                                            <Text largeBlack>
                                                {
                                                    item.name ? item.name.length > 8 ?
                                                        <BlackToolTip title={capitalizeAndAddSpace(item.name)} placement="top" arrow>
                                                            {capitalizeAndAddSpace(item.name.slice(0, 8)) + capitalizeAndAddSpace(item.name.length > 8 ? "..." : "")}
                                                        </BlackToolTip>
                                                        : capitalizeAndAddSpace(item.name) : '--'
                                                }
                                            </Text>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={4} justifyContent='center'>
                                            <BorderLinearProgress variant="determinate" value={item.percentage} barColor={linearProgress[index].bgColor} bgColor={linearProgress[index].barColor} />
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={4} textAlign='right' sx={{ display: 'block' }}>
                                            <AvatarGroup total={item.placement_count} spacing={18} renderSurplus={(surplus) => <span>{surplus.toString()}</span>}
                                                sx={{
                                                    justifyContent: 'flex-end',
                                                    "& .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar": {
                                                        color: '#0C75EB !important',
                                                        backgroundColor: '#D1E1FF !important',
                                                        font: '12px Quicksand !important',
                                                        fontWeight: `${600} !important`,
                                                    },
                                                    "& .MuiAvatar-root": {
                                                        position: "static !important",
                                                        border: "none !important",
                                                        color: '#0C75EB !important',
                                                        backgroundColor: '#D1E1FF !important',
                                                        font: '12px Quicksand !important',
                                                        fontWeight: `${600} !important`,
                                                        // marginX: '-10px'
                                                    },
                                                    "& .MuiAvatar-root-MuiAvatarGroup-avatar": {
                                                        color: '#0C75EB !important',
                                                        backgroundColor: '#D1E1FF !important',
                                                        font: '12px Quicksand !important',
                                                        fontWeight: `${600} !important`,
                                                        // marginX: '-10px'
                                                    }
                                                }}
                                            >
                                                {item && item.employees && item.employees.map((avt, avtIndex) =>
                                                    <Avatar alt={`Avatar ${avtIndex + 1}`} src={avt ? avt.profile_picture_url : ''} sx={{ width: "35px", height: "35px" }} />)}
                                            </AvatarGroup>
                                        </Grid>
                                    </>
                                )) : ''
                            }
                        </Grid>
                }
                {
                    companies.length > 1 &&
                    <Grid item container lg={10} md={10} sm={10} xs={10} pt={3} justifyContent='end'>
                        <Box minWidth='55px' textAlign='end'>
                            <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={() => navigate('/clients')}>View All</Text>
                        </Box>
                    </Grid>
                }
            </Grid>
        </Grid>
        <Divider sx={{ width: '100%', margin: '30px 0px 20px 0px', borderColor: '#F5F5F5' }} />
        <>  <Grid id="additionattrition" container>
            <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} alignItems='center'>
                <Grid item lg={10} md={9} sm={8} xs={6}>
                    <Text smallLabelblack16>Addition & Attrition Rate</Text>
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                    <CustomSelect
                        name='placementYear'
                        value={yearDropdwn.placementYear}
                        viewDrop
                        scrollTrue={true}
                        options={years}
                        onChange={(e) => dropDownChangeHandler(e, '')}
                    />
                </Grid>
            </Grid>
            
            <Grid item lg={12} xs={12} spacing={2} pt={2} id="additionattrition">
                <ReactApexChart
                    options={areaOptions}
                    series={areaOptions.series}
                    type="area"
                    height='300px'
                />
            </Grid></Grid>
            <Grid item container lg={12} xs={12}>
                <Grid item container lg={6} md={12} sm={12} xs={12} className={classes.borderRight}>
                    <Grid item lg={12} xs={12}>
                        <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                            <Box className={classes.greenDot} />
                            <Text overViewLable>Placement Added</Text>
                        </Box>
                    </Grid>
                    <Grid item lg={12} xs={12} py={1}>
                        <Text largeblack40>{placementDetails.total_placements_added}</Text>
                    </Grid>
                    <Grid item container lg={12} xs={12}>
                        {
                            placementsAdded.length > 0 && placementsAdded.map((item) => (
                                <Grid item container lg={12} xs={12} py='6px'>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Text smallBlack400>{item.name}</Text>
                                    </Grid>
                                    <Grid item lg={1} md={1} sm={1} xs={1}>-</Grid>
                                    <Grid item lg={4.5} md={4.5} sm={4.5} xs={4.5} textAlign='end'>
                                        <Text black12>{item.count}</Text>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid item container lg={6} className={classes.paddingLeft}>
                    <Grid item lg={12} xs={12}>
                        <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                            <Box className={classes.yellowDot} />
                            <Text overViewLable>Placement Completed</Text>
                        </Box>
                    </Grid>
                    <Grid item lg={12} xs={12} py={1}>
                        <Text largeblack40>{placementDetails.total_placements_completed}</Text>
                    </Grid>
                    <Grid item container lg={12} xs={12}>
                        {
                            placementsCompleted.map((item) => (
                                <Grid item container lg={12} xs={12} py='6px'>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Text smallBlack400>{item.name}</Text>
                                    </Grid>
                                    <Grid item lg={1} md={1} sm={1} xs={1}>-</Grid>
                                    <Grid item lg={4.5} md={4.5} sm={4.5} xs={4.5} textAlign='end'>
                                        <Text black12>{item.count}</Text>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} justifyContent='end'>
                    <Box minWidth='55px' textAlign='end'>
                        <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={() => navigate('/placements')}>View All</Text>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ width: '100%', margin: '30px 0px 20px 0px', borderColor: '#F5F5F5' }} />
        </>
    </>
)
}
export default EmployeeAndClientAttribution