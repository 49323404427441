import BaseApi from '../../BaseApi'
import APIURL from "../../../config/development";
import LocalStorage from '../../../utils/LocalStorage';

class InternalEmployeeApi {


    // get method
    // getInternalEmployees(id, token) {
    //     return BaseApi.getWithParams(APIURL.API_URL + `employee/internal/listing?request_id=${LocalStorage.uid()}&id=${id}`, LocalStorage.getAccessToken());
    // }
    getInternalEmployees(params) {
        let { search, limit, page } = params
        return BaseApi.getWithParams(APIURL.org_URL + `employee/internal/listing?request_id=${LocalStorage.uid()}&search=${search}&page=${page}&limit=${limit}&sort_column=created_at&sort_order=DESC`, LocalStorage.getAccessToken());
    }
    getEmploymentIndex(id) {
        return BaseApi.getWithParams(APIURL.org_URL + `employee/internal/${id}?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }
    postEmployee(data) {
        return BaseApi.postWithToken(APIURL.org_URL + 'employee/internal', data, LocalStorage.getAccessToken());
    }
    deleteEmployee(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `employee/internal/delete`, data, LocalStorage.getAccessToken())
    }
    updateEmployee(data, id) {
        return BaseApi.putWithToken(APIURL.org_URL + `employee/internal/${id}`, data, LocalStorage.getAccessToken())
    }
		getRoleDropDown(search){
			return BaseApi.getWithParams(APIURL.org_URL + `role/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken());
    }
}
// eslint-disable-next-line
export default new InternalEmployeeApi();
