import React, { useState, useEffect } from 'react';
import { Box, Grid, Divider } from '@mui/material';
import UserProfileStyles from '../UserProfileStyles';
import Input from '../../../../../components/input/Input';
import Text from '../../../../../components/customText/Text';
import { empty_name, isValidMulti, validate_optional_contact, validate_Extension_Optional, validate_charWithSpace, validates_emailId, validate_contact_number } from "../../../../../components/Validation";
import Button from '../../../../../components/customButton/Button';
import { BlackToolTip, addErrorMsg, addSuccessMsg, addWarningMsg, capitalize, capitalizeAndAddSpace } from '../../../../../utils/utils';
import LocalStorage from "../../../../../utils/LocalStorage";
import ClientsAPI from '../../../../../apis/admin/clients/ClientsApi';
import { ReactComponent as Plus } from '../../../../../assets/svg/plus.svg';
import EditIcon from '../../../../../assets/svg/newEdit.svg';
import noDataFound from '../../../../../assets/svg/NoDataFoundIcon.svg';
import deleteIcon from '../../../../../assets/client/minus-circle.svg';
import Sure from '../../../../../assets/svg/ExpenseDeleteIcon.svg';
import ToggleSwitch from '../../../../../components/toggle/CustomToggle';
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import LoadingScreen from '../../../employees/userprofile/document/LoadingScreen';
import changePrimaryImg from '../../../../../assets/svg/clients/change-primary.svg';
import success from '../../../../../assets/client/clientSuccess.svg';
import { useNavigate } from 'react-router-dom';
import deactivatedPlacement from '../../../../../assets/svg/clients/deactivated-placement.svg';
import LoaderButton from '../../../../../components/customButton/LoadingButton';
import { ErrorMessages } from '../../../../../utils/ErrorMessages';

export default function ContactDetails(props) {
    const { id, actionContact, setActionContact, status, getActivity, getCompanyDetails, loading, setLoading, flow } = props;
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = UserProfileStyles();
    const [erro, setErro] = useState([]);
    const [clearPopup, setclearPopup] = useState(false);
    const [Index, setIndex] = useState('');
    const [formData, setFormData] = useState([]);// eslint-disable-next-line
    const [deleteIds, setDeleteIds] = useState([]);
    const [changePrimary, setChangePrimary] = useState(false);
    const [placementNavigate, setPlacementNavigate] = useState(false);
    const [placementEnable, setPlacementEnable] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const navigate = useNavigate();
    // eslint-disable-next-line
    const usMobileNumberValidator = new RegExp(/^[a-zA-Z@~`!@#$%^&* ()_=+\\';:"\/?>.<,-]*$/);

    useEffect(() => {
        getContactsDetails(); // eslint-disable-next-line
    }, []);

    const getContactsDetails = () => {
        setLoading(true);
        ClientsAPI.contactIndex(id, 'end-client').then((response) => {
            setLoading(false)
            if (response.data.statusCode == 1003) {
                if (response.data.data.length > 0) {
                    for (var i in response.data.data) {
                        response.data.data[i]['is_edit'] = true;
                    }
                    setFormData([...response.data.data]);
                    setDeleteIds([]);
                } else {
                    setFormData([...response.data.data]);
                    setDeleteIds([]);
                }
            }
        });
    }
    const changeHandler = (e, index) => {
        if (e.target.name === "mobile_number" || e.target.name === "telephone_number") {
            convertFormat(e, index)
        }
        else if (e.target.name == 'is_primary') {
            if (formData[index]['is_primary'] == false) {
                setChangePrimary(true);
                setIndex(index);
            } else {
                if (index == 1) {
                    formData.forEach(data => {
                        data['is_primary'] = false;
                    });
                    formData[index - 1]['is_primary'] = true
                } else if (index == 0) {
                    formData[index]['is_primary'] = false
                    formData[index + 1]['is_primary'] = true
                } else {
                    formData.forEach(data => {
                        data['is_primary'] = false;
                    });
                    formData[index]['is_primary'] = e.target.checked
                    formData[index - 1]['is_primary'] = !e.target.checked
                }
            }
            setFormData([...formData]);
            handleValidations(e, index);
        }
        else if (e.target.name == 'first_name' || e.target.name == 'last_name' || e.target.name == 'middle_name') {
            formData[index][e.target.name] = capitalize(e.target.value);
            setFormData([...formData]);
            handleValidations(e, index);
        }
        else {
            formData[index][e.target.name] = e.target.value
            setFormData([...formData]);
            handleValidations(e, index);
        }
    }

    const convertFormat = (e, index) => {
        const value = e.target.value;
        const name = e.target.name
        const input = value.replace(/\D/g, '').substring(0, 10);
        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'mobile_number' || name == 'telephone_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'mobile_number' || name == 'telephone_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'mobile_number' || name == 'telephone_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > (name == 'mobile_number' || name == 'telephone_number' ? 6 : 5)) {
            formData[index][e.target.name] = `${first}-${middle}-${last}`
            setFormData([...formData], handleValidations(e, index))
        }
        else if (input.length > 3) {
            formData[index][e.target.name] = `${first}-${middle}`
            setFormData([...formData], handleValidations(e, index))
        }
        else if (input.length >= 0) {
            formData[index][e.target.name] = input
            setFormData([...formData], handleValidations(e, index))
        }
    }

    const handleValidations = (e, index) => {
        const input = e.target;
        const { name, value } = input;
        let updatedErrors = [...erro];
        while (updatedErrors.length <= index) {
            updatedErrors.push({});
        }
        
        const currentError = updatedErrors[index] || {};
    
        switch (name || input.tagName) {
            case "first_name":
                currentError.first_name = validate_charWithSpace(value, 'first');
                break;
            case "middle_name":
                currentError.middle_name = empty_name(value, 'middle');
                break;
            case "last_name":
                currentError.last_name = validate_charWithSpace(value, 'last');
                break;
            case "email_id":
                currentError.email_id = validates_emailId(value);
                break;
            case "telephone_number_ext":
                currentError.telephone_number_ext = validate_Extension_Optional(value);
                break;
            case "mobile_number":
                currentError.mobile_number = 
                    value === formData[index].telephone_number
                        ? 'Mobile number and phone number should not be the same'
                        : usMobileNumberValidator.test(value)
                            ? ''
                            : validate_contact_number(value, "mobile");
                break;
            case "telephone_number":
                if (value === '' && formData[index].mobile_number === '') {
                    currentError.telephone_number = ErrorMessages.fieldRequired;
                } else if (value === formData[index].mobile_number) {
                    currentError.telephone_number = 'Phone number and mobile number should not be the same';
                } else {
                    currentError.telephone_number = 
                        usMobileNumberValidator.test(value)
                            ? ''
                            : validate_optional_contact(value, "phone number");
                }
                break;
            default:
                break;
        }
    
        updatedErrors[index] = currentError;
        setErro(updatedErrors);
    };

    const validateAll = () => {
        let errorsus = {};
        let err = [];
        formData.map((value) => {
            errorsus = {};
            errorsus.first_name = validate_charWithSpace(value.first_name, "first ");
            errorsus.last_name = validate_charWithSpace(value.last_name, "last ");
            errorsus.middle_name = empty_name(value.middle_name, "middle ");
            errorsus.email_id = validates_emailId(value.email_id);
            errorsus.telephone_number = validate_contact_number(value.telephone_number, "phone number");
            errorsus.mobile_number = validate_optional_contact(value.mobile_number, "mobile");
            errorsus.telephone_number_ext = validate_Extension_Optional(value.telephone_number_ext);
            return err.push(errorsus);
        });
        return err;
    }

    const handleSubmit = () => {
        let errors = validateAll();
        if (isValidMulti(errors)) {
            updateContactsDetails();
        } else {
            let s1 = { erro }
            s1 = errors;
            setErro(s1);
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
    }

    const updateContactsDetails = () => {
        let data = {
            request_id: LocalStorage.uid(),
            company_id: id,
            contacts: formData,
            deleted_contact_ids: deleteIds
        };
        setButtonLoader(true)
        ClientsAPI.storeContact(data, "end-client").then((response) => {
            setTimeout(() => {
                setButtonLoader(false)
                if (response.data.statusCode == 1003) {
                    props.setformEdit(false)
                    handleCancel();
                    setDeleteIds([]);
                    getActivity();
                    if (flow == 'placementContact') {
                        setPlacementNavigate(true);
                    } else {
                        addSuccessMsg(response.data.message);
                    }
                } else {
                    addErrorMsg(response.data.message);
                }
            }, 400)
        });
    }

    const changePrimaryHandler = () => {
        formData.forEach(data => {
            data['is_primary'] = false;
        });
        formData[Index].is_primary = true;
        setFormData([...formData]);
        setChangePrimary(false);
    }

    const editAction = (index) => {
        props.setformEdit(true)
        formData[index]['is_edit'] = false;
        setFormData([...formData]);
    }

    const openAddForm = (args, index) => {
        props.setformEdit(true)
        if (args == 'add') {
            let obj = {
                id: '',
                first_name: "",
                middle_name: "",
                last_name: "",
                telephone_number: "",
                email_id: "",
                telephone_number_ext: "",
                mobile_number: "",
                is_primary: formData.length == 0 ? true : false,
                is_edit: false,
            };
            if (formData.length > 0) {
                let errors = validateAll();
                if (isValidMulti(errors)) {
                    formData.unshift(obj);
                    setFormData([...formData]);
                    setActionContact('disable');
                } else {
                    let s1 = { erro }
                    s1 = errors;
                    setErro(s1);
                    addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
                }
            } else {
                formData.unshift(obj);
                setFormData([...formData]);
                setActionContact('disable');
                setErro([]);
            }
        }
        else if (args == 'remove') {
            if (formData[index].id !== '' || formData[index].id !== undefined || formData[index].id !== null) {
                if (formData[index]['placement_exists'] == true) {
                    setPlacementEnable(true);
                } else {
                    deleteIds.push(formData[index].id);
                    setDeleteIds([...deleteIds]);
                    if (formData[index]['is_primary'] == true) {
                        setclearPopup(true);
                    } else {
                        formData.splice(index, 1);
                        erro.splice(index, 1);
                        setErro([...erro])
                        setFormData([...formData]);
                    }
                }
                setIndex(index);
            } else {
                formData.splice(index, 1);
                setFormData([...formData]);
            }
            setFormData([...formData]);
        }
    }

    const handleCancel = () => {
        props.setformEdit(false)
        setActionContact('view');
        getContactsDetails();
        getCompanyDetails();
        getActivity();
        setErro([])
    }

    const deleteContact = () => {
        if (Index == 1) {
            formData[0].is_primary = true;
        } else if (Index == 0) {
            formData[Index + 1].is_primary = true;
        }
        else {
            formData[Index - 1].is_primary = true;
        }
        formData.splice(Index, 1);
        setFormData([...formData]);
        setclearPopup(false);
    }

    return (
        <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                    <Text largeBlue> Contact Details </Text>
                    {
                        (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_edit" && item.is_allowed !== true))) ? "" : (status === 'In Active' || loading) ? '' : formData.length > 0 && formData.length < 5 && <Button addNew startIcon={<Plus />} onClick={() => openAddForm('add')}>Add New Contact</Button>
                    }
                </Box>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} mt={2} padding={2} className={classes.boxShadow}>
                <Box className={classes.contentScroll} p={'10px'}>
                    {
                        loading ?
                            <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                                <LoadingScreen />
                            </Grid>
                            :
                            formData.length > 0 ?
                                formData.map((item, index) => (
                                    actionContact == 'view' ?
                                        <Grid item container lg={12} spacing={3}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                                                    <Text largeBlue> Contact Details {` - ${index + 1}`} {item.is_primary == true ? '(Primary)' : ''}</Text>
                                                    {
                                                        (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_edit" && item.is_allowed !== true))) ? "" :
                                                            status === 'In Active' ? '' : index == 0 && <img src={EditIcon} alt='Edit' className={classes.cursor} onClick={() => { setActionContact('update'); setErro([]); props.setformEdit(true) }} />
                                                    }
                                                </Box>
                                            </Grid>
                                            <Grid item container lg={12}>
                                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                                    <Box p={1} pt={2}>
                                                        <Text mediumGreyHeader> First Name</Text>
                                                        <Text pt={1} mediumBlack14>
                                                            {
                                                                item.first_name ? item.first_name.length > 16 ?
                                                                    <BlackToolTip title={capitalizeAndAddSpace(item.first_name)} placement="top" arrow>
                                                                        {capitalizeAndAddSpace(item.first_name.slice(0, 16)) + capitalizeAndAddSpace(item.first_name.length > 16 ? "..." : "")}
                                                                    </BlackToolTip>
                                                                    : capitalizeAndAddSpace(item.first_name) : '--'
                                                            }
                                                        </Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                                    <Box p={1} pt={2}>
                                                        <Text mediumGreyHeader> Middle Name (Optional)</Text>
                                                        <Text pt={1} mediumBlack14>
                                                            {
                                                                item.middle_name ? item.middle_name.length > 16 ?
                                                                    <BlackToolTip title={capitalizeAndAddSpace(item.middle_name)} placement="top" arrow>
                                                                        {capitalizeAndAddSpace(item.middle_name.slice(0, 16)) + capitalizeAndAddSpace(item.middle_name.length > 16 ? "..." : "")}
                                                                    </BlackToolTip>
                                                                    : capitalizeAndAddSpace(item.middle_name) : '--'
                                                            }
                                                        </Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                                    <Box p={1} pt={2}>
                                                        <Text mediumGreyHeader> Last Name</Text>
                                                        <Text pt={1} mediumBlack14>
                                                            {
                                                                item.last_name ? item.last_name.length > 16 ?
                                                                    <BlackToolTip title={capitalizeAndAddSpace(item.last_name)} placement="top" arrow>
                                                                        {capitalizeAndAddSpace(item.last_name.slice(0, 16)) + capitalizeAndAddSpace(item.last_name.length > 16 ? "..." : "")}
                                                                    </BlackToolTip>
                                                                    : capitalizeAndAddSpace(item.last_name) : '--'
                                                            }
                                                        </Text>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid item container lg={12}>
                                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                                    <Box p={1} pt={2}>
                                                        <Text mediumGreyHeader> Email ID</Text>
                                                        <Text pt={1} mediumBlack14>
                                                            {
                                                                item.email_id ? item.email_id.length > 20 ?
                                                                    <BlackToolTip title={item.email_id} placement="top" arrow>
                                                                        {item.email_id.slice(0, 20) + (item.email_id.length > 20 ? "..." : "")}
                                                                    </BlackToolTip>
                                                                    : item.email_id : '--'
                                                            }
                                                        </Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                                    <Box p={1} pt={2}>
                                                        <Text mediumGreyHeader> Phone Number</Text>
                                                        <Text pt={1} mediumBlack14>{item.telephone_number ? item.telephone_number : '--'}</Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                                    <Box p={1} pt={2}>
                                                        <Text mediumGreyHeader>Extension</Text>
                                                        <Text pt={1} mediumBlack14>{item.telephone_number_ext ? item.telephone_number_ext : '--'}</Text>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={12} md={6} sm={6} xs={12}>
                                                <Box p={1} pt={2}>
                                                    <Text mediumGreyHeader> Mobile Number (Optional)</Text>
                                                    <Text pt={1} mediumBlack14>{item.mobile_number ? item.mobile_number : '--'}</Text>
                                                </Box>
                                            </Grid>
                                            {
                                                formData.length - 1 == index ? '' :
                                                    <Divider className={classes.divider} />
                                            }
                                        </Grid> : actionContact == 'update' || actionContact == 'disable' ?
                                            <Grid item container spacing={3}>
                                                <Grid item lg={12} container pb={2}>
                                                    <Grid item lg={8} md={8} sm={8} xs={10}>
                                                        {
                                                            actionContact == 'disable' && index == 0 ? <Text largeBlue>New Contact Details</Text> :
                                                                <Text largeBlue>Contact Details {` - ${index + 1}`} {item.is_primary == true ? '(Primary)' : ''}</Text>
                                                        }
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={4} xs={2} textAlign='end'>
                                                        {
                                                            (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_edit" && item.is_allowed !== true))) ? "" :
                                                                actionContact == 'disable' ?
                                                                    <>
                                                                        {
                                                                            formData.length == 1 ? '' : item.id === '' || status === 'In Active' ? '' : !item.is_edit ? <img src={deleteIcon} alt='delete' style={{ cursor: 'pointer' }} onClick={() => openAddForm('remove', index)} /> : <img src={EditIcon} alt='Edit' className={classes.cursor} onClick={() => editAction(index)} />
                                                                        }
                                                                    </>
                                                                    :
                                                                    formData.length == 1 ? '' : item.id === '' ? '' : <img src={deleteIcon} alt='delete' style={{ cursor: 'pointer' }} onClick={() => openAddForm('remove', index)} />
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item container lg={12} spacing={3} p={1}>
                                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'first_name',
                                                                value: item.first_name,
                                                                inputProps: { maxLength: 33 }
                                                            }}
                                                            disabled={(actionContact == 'disable' && index == 0) || !item.is_edit || actionContact == 'update' ? false : true}
                                                            error={erro[index] && erro[index].first_name}
                                                            handleChange={(e) => { changeHandler(e, index) }}
                                                            clientInput
                                                            labelText='First Name'
                                                        />
                                                        <Text errorText> {erro[index] ? erro[index].first_name : ""}</Text>
                                                    </Grid>
                                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'middle_name',
                                                                value: item.middle_name,
                                                                inputProps: { maxLength: 33 }
                                                            }}
                                                            disabled={index == 0 || !item.is_edit || actionContact == 'update' ? false : true}
                                                            handleChange={(e) => { changeHandler(e, index) }}
                                                            clientInput
                                                            error={erro[index] && erro[index].middle_name}
                                                            labelText='Middle Name (Optional)'
                                                        />
                                                        <Text errorText> {erro[index] && erro[index].middle_name}</Text>
                                                    </Grid>
                                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'last_name',
                                                                value: item.last_name,
                                                                inputProps: { maxLength: 33 }
                                                            }}
                                                            disabled={index == 0 || !item.is_edit || actionContact == 'update' ? false : true}
                                                            error={erro[index] && erro[index].last_name}
                                                            handleChange={(e) => { changeHandler(e, index) }}
                                                            clientInput
                                                            labelText='Last Name'
                                                        />
                                                        <Text errorText>{erro[index] && erro[index].last_name}</Text>
                                                    </Grid>
                                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'email_id',
                                                                value: item.email_id,
                                                            }}
                                                            disabled={index == 0 || !item.is_edit || actionContact == 'update' ? false : true}
                                                            error={erro[index] && erro[index].email_id}
                                                            handleChange={(e) => { changeHandler(e, index) }}
                                                            clientInput
                                                            labelText='Email ID'
                                                        />
                                                        <Text errorText>{erro[index] && erro[index].email_id}</Text>
                                                    </Grid>
                                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'telephone_number',
                                                                value: item.telephone_number,
                                                                inputProps: { maxLength: 12 }
                                                            }}
                                                            disabled={index == 0 || !item.is_edit || actionContact == 'update' ? false : true}
                                                            error={erro[index] && erro[index].telephone_number}
                                                            handleChange={(e) => { changeHandler(e, index) }}
                                                            clientInput
                                                            labelText='Phone Number'
                                                        />
                                                        <Text errorText>{erro[index] && erro[index].telephone_number}</Text>
                                                    </Grid>
                                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'telephone_number_ext',
                                                                value: item.telephone_number_ext,
                                                                inputProps: { maxLength: 6 }
                                                            }}
                                                            disabled={index == 0 || !item.is_edit || actionContact == 'update' ? false : true}
                                                            error={erro[index] && erro[index].telephone_number_ext}
                                                            handleChange={(e) => { changeHandler(e, index) }}
                                                            clientInput
                                                            labelText='Extension (Optional)'
                                                        />
                                                        <Text errorText>{erro[index] && erro[index].telephone_number_ext}</Text>
                                                    </Grid>
                                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'mobile_number',
                                                                value: item.mobile_number,
                                                                inputProps: { maxLength: 12 }
                                                            }}
                                                            disabled={index == 0 || !item.is_edit || actionContact == 'update' ? false : true}
                                                            error={erro[index] && erro[index].mobile_number}
                                                            handleChange={(e) => { changeHandler(e, index) }}
                                                            clientInput
                                                            labelText='Mobile Number (Optional)'
                                                        />
                                                        <Text errorText>{erro[index] && erro[index].mobile_number}</Text>
                                                    </Grid>
                                                    <Grid item container lg={12} m='10px 0px 10px 10px !important'>
                                                        <Grid item lg={5}>
                                                            <ToggleSwitch name='is_primary' isActive={item.is_primary} disable={formData.length == 1} switchChange={(e) => changeHandler(e, index)} sx={{ height: '24px !important' }} label={<Text largeLabel sx={{ paddingLeft: '10px' }}>Make This as Primary Contact</Text>} />
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        formData.length - 1 == index ? '' :
                                                            <Divider className={classes.divider} />
                                                    }
                                                </Grid>
                                            </Grid> : ''
                                )) :
                                formData.length == 0 ?
                                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                            <img src={noDataFound} alt='nodata' />
                                        </Grid>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                            <Text largeBlack>No Contacts Added</Text>
                                        </Grid>
                                        {
                                            (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_edit" && item.is_allowed == true))) &&
                                            <>
                                                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                    {status === 'In Active' ? '' : <Text mediumLabel sx={{ fontSize: '14px !important' }}>You can add Contact Details by clicking on the <br /> below button</Text>}
                                                </Grid>
                                                {
                                                    status === 'In Active' ? '' :
                                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={3}>
                                                            <Button startIcon={<Plus />} finishFilledQuicksandLargeWidth onClick={() => openAddForm('add')}>Add New Contact</Button>
                                                        </Grid>
                                                }
                                            </>
                                        }



                                    </Grid>
                                    : ''
                    }
                </Box>
                {
                    !loading && formData.length > 0 && (actionContact == 'update' || actionContact == 'disable') &&
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box display='flex' flexDirection='row' justifyContent='space-between'>
                            <Button blackCancelBtn onClick={handleCancel}>Cancel</Button>
                            <LoaderButton loading={buttonLoader} saveNcontinueSmall onClick={handleSubmit}>Save</LoaderButton>
                        </Box>
                    </Grid>
                }
            </Grid>
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide fixedWidth>
                <Box m={2}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text popupHead1>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} mt={1}>
                        <Text mediumOverView>Would you like to remove the primary</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} mt={0}>
                        <Text mediumOverView>contact? By removing this, the next contact</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} mt={0}>
                        <Text mediumOverView>will be designated as primary.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3} columnSpacing={2}>
                        <Grid item lg={6} xs={6} textAlign='right'>
                            <Button blueboldCancel onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item lg={6} xs={6} textAlign='left'>
                            <Button whiteboldButton onClick={deleteContact}>
                                Yes,Delete
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup openPopup={changePrimary} setOpenPopup={setChangePrimary} white iconHide>
                <Box m={2} textAlign='center' justifyContent='center'>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={changePrimaryImg} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text popupHead1>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} mt={1}>
                        <Text mediumOverView>Do you want to make contact as primary contact</Text>
                    </Grid>
                    <Grid container lg={12} xs={12} justifyContent='center' alignContent={'center'} mt={3} columnSpacing={2}>
                        <Grid item lg={6} xs={6} textAlign='right'>
                            <Button redboldCancel onClick={() => { setChangePrimary(false) }}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item lg={6} xs={6} textAlign='left'>
                            <Button blueboldCancelClient onClick={changePrimaryHandler}>
                                Yes
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup iconHide openPopup={placementNavigate} setOpenPopup={setPlacementNavigate} white statusWidth>
                <Box textAlign='center' p={'0px 20px 0px 20px'}>
                    <img src={success} alt='success' />
                    <Text veryLargeLabel sx={{ paddingTop: '25px !important' }}>Succesfully Added!</Text>
                    <Text mediumLabelColor sx={{ padding: '25px 0px !important' }}>You Have Successfully Added Contact <br /> information.</Text>
                    <Button onClick={() => navigate('/placements/placementsInfo')} blueButton>Go To Placement</Button>
                </Box>
            </ReusablePopup>
            <ReusablePopup openPopup={placementEnable} setOpenPopup={setPlacementEnable} white iconHide crossIcon fixedWidth450 closePopup={setPlacementEnable}>
                <Grid container justifyContent={'center'} textAlign='center' alignContent={'center'} pb={2}>
                    <img src={deactivatedPlacement} alt='noactive' />
                </Grid>
                <Grid container justifyContent={'center'} textAlign={'center'}>
                    <Text mediumOverView>This contact is currently mapped with<br /> active placements involving consultants. Please update the<br /> contact information in the placements to remove<br /> this contact.</Text>
                </Grid>
                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                    <Button save14 onClick={() => navigate('/placements')}>
                        Go To Placements
                    </Button>
                </Grid>
            </ReusablePopup>
        </Grid>
    );
}