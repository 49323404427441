import React, { useState, useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Box, Stack, Grid, Checkbox, SwipeableDrawer, Divider, Menu, Badge, Chip, FormControlLabel } from '@mui/material';
import crossIcon from '../../../../assets/svg/crossIcon.svg';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Skeleton from '@mui/material/Skeleton';
import Component87 from '../../../../assets/svg/Component87.svg';
// import disableVerified from '../../../../assets/client/disableVerified.svg';
import Search from '../../../../assets/svg/search1.svg';
import NewDownloadcloud from '../../../../assets/svg/download-cloud-new.svg';
import recruiter from '../../../../assets/svg/recruiter.svg';
import InviteViaLink from '../../../../assets/svg/invite-via-link.svg';
import Onboart from '../../../../assets/svg/onboard.svg';
import { addErrorMsg, addSuccessMsg, addLoader, addLoaderPlanExpired, BlackToolTip, capitalizeAndAddSpace, dateFormat, NoDataFound, NoPermission, removeExpiredLoader, removeLoader, ListLoadingIcon } from '../../../../utils/utils';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Text from '../../../../components/customText/Text';
import Button from '../../../../components/customButton/Button';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/cross.svg';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LocalStorage from '../../../../utils/LocalStorage';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import infoCircle from '../../../../assets/images/info-circle.png';
import ClearIcon from '@mui/icons-material/Clear';
// import Loader from '../../../../assets/gif/japfuLoader.gif';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlacementDashboardStyles from './PlacementDashboardStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Date from '../../../../components/datePicker/Date';
import RecruitersTable from './RecruitersTable';
import recruitersActive from '../../../../assets/svg/recruitersActive.svg';
import nofilterplacement from '../../../../assets/svg/nofilterplacement.svg';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import { validate_emptyField } from '../../../../components/Validation';
import PlacementApi from '../../../../apis/admin/placements/PlacementApi';
import ClientsApi from '../../../../apis/admin/clients/ClientsApi';
import CommonApi from '../../../../apis/CommonApi';
import AddIcon from '@mui/icons-material/Add';
import FileSaver from 'file-saver';
import { domain } from '../../../../config/Domain';
import DashboardAPI from '../../../../apis/admin/DashboardAPI';
import TourGuideConfigApi from '../../../../apis/configurations/tourguideConfig/TourGuideConfigApi';
import TourPlacement from '../../../../components/tourGuideDetails/TourPlacement';
import Pending from '../../../../assets/employee/Orange-PendingIcon.svg';
import CustomCircularProgress from '../../../../components/progressbar/CircularProgress';
import Zohosyncnow  from '../../../../assets/svg/zohosyncnow.svg';
import Zohosyncing  from '../../../../assets/svg/zohosyncing.svg';
import Zohosynced  from '../../../../assets/svg/zohosynced.svg';
import Zohorefresh  from '../../../../assets/svg/zohorefresh.svg';
import InstalledAppsApi from '../../../../apis/InstalledAppsApi';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} timeout={500} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

export default function Dashboard() {

    const location = useLocation();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = PlacementDashboardStyles();
    const timeSheetCycles = require('../../../../utils/jsons/Cycle.json');
    const navigate = useNavigate();
    const [placements, setPlacements] = useState([]);
    const [pagination, setPagination] = useState({ total: "6" });
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [open, setOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [startTour, setStartTour] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [filterData, setFilterData] = useState({
        limit: 10,
        page: 1,
        search: "",
        status: [],
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        clients: [],
        recruiter: [],
        timesheet_cycle: [],
        sort_column: "created_at",
        sort_order: "desc"
    })

    const [filterState, setFilterState] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        clients: [],
        recruiter: [],
        timesheet_cycle: [],
        limit: 10,
        page: 1
    })
    const [datesError, setDatesError] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
    })
    const [cycleDropdown, setCycleDropdown] = useState([]);
    // const [openBacKLoader, setOpenBacKLoader] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [recruitersTableShow, setRecruitersTableShow] = useState(false);
    const [clientDropdown, setClientDropdown] = useState([]);
    const [recruiters, setRecruiters] = useState([]);
    const [syncing,setsyncing]=useState(false);
    const [currentSyncingId,setCurrentSyncingId] = useState('');
    const [appIntegrations,setAppIntegrations] = useState({});
    const [listLoading, setListLoading] = useState(false);

    const handleZohosync = (e,id) => {
        e.stopPropagation();
        setsyncing(true);
        setCurrentSyncingId(id);
        const data = {
            request_id:LocalStorage.uid(),
            id
        }
        DashboardAPI.zohoIntegration(data).then((res) => {
            if (res.data.statusCode == 1003) {
                setTimeout(() => {
                    getAllPlacements(filterData);
                    addSuccessMsg(res.data.message)
                }, 2000);
            } else {
                setsyncing(false);
                setCurrentSyncingId('');
                addErrorMsg(res.data.message);
            }
        })
    }

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true))) {
            clientDropdownApi();
            getAllRecruiters();
            setCycleDropdown(timeSheetCycles);
            getAllPlacements(filterData);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getPlanCheck();
        getAllApps();

        // eslint-disable-next-line
    }, []);

    const getAllApps = () => {
        InstalledAppsApi.getApps('').then((res) => {
            if (res.data.statusCode === 1003) {
                setAppIntegrations(res.data.data[0]);
            }
        })
    }


    const getTourGuideDetails = async () => {
        let response = await TourGuideConfigApi.getApi();
        if (((LocalStorage.getStartTour() && LocalStorage.getStartTour().placements == true) || (response.data.statusCode == 1003 && response.data.data?.PLACEMENTS == false)) && LocalStorage.getUserData().admin_login == true) {
            LocalStorage.setStartTour({ 'placements': true });
            setStartTour(true);
        }

    }


    const getPlanCheck = () => {
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
                if (!res.data.plan_not_set && !res.data.plan_expired) {
                    if (LocalStorage.getUserData().admin_login && (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true))) {
                        getTourGuideDetails();
                    }
                    else {
                        LocalStorage.setStartTour({ 'placements': false });

                    }
                }
            }
        })
    }

    const clientDropdownApi = () => {
        ClientsApi.dropdown('client', "").then((res) => {
            if (res.data.statusCode === 1003) {
                setClientDropdown(res.data.data);
            }
        })
    }

    const handleDownloadExport = () => {
        addLoader(true);
        PlacementApi.downLoadPlacementExcel().then((response) => {
            removeLoader();
            if (response.data.statusCode == 1003) {
                FileSaver.saveAs(response.data.data[0].file_path);
            }
            else {
                addErrorMsg(response.data.message)
            }
        });
    }

    const getAllPlacements = (filter) => {
        setExpanded(false)
        if (placements.length < 6) {
            setLoading(true);
        }
        PlacementApi.getPlacementListing(filter).then((response) => {
            removeLoader();
            setListLoading(false);
            // setOpenBacKLoader(false);
            if (response.data.statusCode == 1003) {
                setDrawer(null);
                setLoading(false);
                setPlacements(response.data.data);
                setPagination({ total: response.data.pagination.total });
                setsyncing(false);
                setCurrentSyncingId('');
            } else {
                setLoading(false);
                if (response.data.message == `You don't have access to perform this action. Please contact admin`) {

                }
                else {
                    addErrorMsg(response.data.message);
                }
            }
        })

    }

    const getAllRecruiters = () => {
        CommonApi.getAllRecruiters().then((res) => {
            if (res.data.statusCode === 1003) {
                setRecruiters(res.data.data);
            }
        })
    }

    const handleClose = () => {
        setOpen(false);
    };

    const viewAnalytics = () => {
        LocalStorage.setAnalyticsRoute('placement')
        navigate('/placement/timesheets-analytics', { state: { activeState: 'placement', from: 'placement' } })
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSearch = (e) => {
        // setOpenBacKLoader(true);
        setExpanded(false);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = e.target.value;
        setFilterData({ ...filterData });
        setLoading(true);
        // addLoader(true);
        getAllPlacements(filterData);

    }

    const handleSearchClear = () => {
        // setOpenBacKLoader(true);
        setExpanded(false);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = '';
        setFilterData({ ...filterData });
        getAllPlacements(filterData);

    }

    const handleDeleteChip = (id, name) => {
        if (name === "start_date" || name === "end_date") {
            const updatedFilterState = { ...filterState }; // Create a copy of the filterState object

            // Update the start_date or end_date fields
            updatedFilterState[name + "_from"] = "";
            updatedFilterState[name + "_to"] = "";

            // Update the state with the modified filterState object
            setFilterState(updatedFilterState);
        } else {
            const newFilterState = { ...filterState };
            const updatedArray = newFilterState[name].filter(item => item !== id);
            newFilterState[name] = updatedArray;
            // if (filterState[name].includes(id)) {
            //     filterState[name].splice(filterState[name].findIndex(item => item === parseInt(id)), 1)
            // }
            setFilterState(newFilterState);
        }
    };

    const handleChangeCheckBox = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        const updatedArray = [...filterState[name]]; // Create a new array

        const index = updatedArray.findIndex(item => item === value);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(value); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const handleChangeRecruiters = (e) => {
        e.preventDefault();
        const { name } = e.target;
        var val = Number(e.target.value)
        const updatedArray = [...filterState[name]]; // Create a new array

        const index = updatedArray.findIndex(item => item === val);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(val); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const clearAllFilter = () => {
        datesError.start_date_from = '';
        datesError.start_date_to = '';
        datesError.end_date_from = '';
        datesError.end_date_to = '';
        setDatesError({ ...datesError });

        // filterState.timesheet_cycle = [];
        // filterState.clients = []
        // filterState.recruiter = [];
        // filterState.start_date = [];
        // filterState.end_date = [];
        // setFilterState({ ...filterState });
        setFilterState(prevState => ({
            ...prevState,
            timesheet_cycle: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            clients: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            recruiter: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            start_date_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            start_date_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            end_date_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            end_date_to: '' // Update the state with the new array
        }));
        // filterData.timesheet_cycle = [];
        // filterData.clients = []
        // filterData.recruiter = [];
        // filterData.start_date = [];
        // filterData.end_date = [];
        // setFilterData({ ...filterData });
        // setDrawer(null);
        // getAllPlacements(filterData);
    }

    const cancelFilter = () => {
        setDrawer(false);
    }

    const handleListItemClick = (param) => {
        setSelectedFilter(param);
    }

    const dateChange = (e, keyName) => {
        // let date = e.$d;
        // let event = { target: { name: keyName, value: date } }
        // datesState[keyName] = moment(date).format(dateFormat());
        // setDatesState({ ...datesState }, handleValidate(event));

        // if (datesState.start_range_one !== "" && datesState.end_range_one !== "") {
        //     filterState.start_date = [datesState.start_range_one, datesState.end_range_one];
        //     setFilterState({ ...filterState });
        //     // filterData.start_date = [datesState.start_range_one, datesState.end_range_one];
        //     // setFilterData({ ...filterData });
        // }
        // if (datesState.start_range_two !== "" && datesState.end_range_two !== "") {
        //     filterState.end_date = [datesState.start_range_two, datesState.end_range_two];
        //     setFilterState({ ...filterState });
        //     // filterData.end_date = [datesState.start_range_two, datesState.end_range_two];
        //     // setFilterData({ ...filterData });
        // }
        let date = e == null ? '' : e.$d
        let event = { target: { name: keyName, value: date } }
        filterState[keyName] = date == '' ? '' : moment(date).format(dateFormat());
        setFilterState({ ...filterState }, handleValidate(event));
    }

    const handleValidate = (e) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case 'start_date_to':
                datesError.start_date_to = validate_emptyField(input.value)
                break;
            case 'end_date_to':
                datesError.end_date_to = validate_emptyField(input.value)
                break;
            default:
                break;
        }
        setDatesError({ ...datesError });
    }

    const handleApplyFilters = () => {
        if (filterState.start_date_from != "" && filterState.start_date_to == "" && filterState.end_date_from != "" && filterState.end_date_to == "") {
            datesError.start_date_to = "Please select the To date.";
            datesError.end_date_to = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.start_date_from == "" && filterState.start_date_to != "") {
            datesError.start_date_from = "Please select the from date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.end_date_from == "" && filterState.end_date_to != "") {
            datesError.end_date_from = "Please select the from date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.start_date_from != "" && filterState.start_date_to == "") {
            datesError.start_date_to = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.end_date_from != "" && filterState.end_date_to == "") {
            datesError.end_date_to = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.start_date_from !== "" && filterState.start_date_to !== "") {
            if (moment(filterState.start_date_from, dateFormat()).isSameOrBefore(moment(filterState.start_date_to, dateFormat()))) {
                filterData.start_date_from = filterState.start_date_from;
                filterData.start_date_to = filterState.start_date_to;
            } else {
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
        } else {
            filterData.start_date_from = '';
            filterData.start_date_to = '';
        }
        if (filterState.end_date_from !== "" && filterState.end_date_to !== "") {
            if (moment(filterState.end_date_from, dateFormat()).isSameOrBefore(moment(filterState.end_date_to, dateFormat()))) {
                filterData.end_date_from = filterState.end_date_from;
                filterData.end_date_to = filterState.end_date_to;
            } else {
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
        } else {
            filterData.end_date_from = '';
            filterData.end_date_to = '';
        }
        filterData.clients = filterState.clients;
        filterData.recruiter = filterState.recruiter;
        filterData.timesheet_cycle = filterState.timesheet_cycle;
        setFilterData({ ...filterData });
        addLoader(true)
        getAllPlacements({ ...filterData, limit: 10, page: 1 });
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const openStatus = Boolean(anchorEl1);
    const openFilter = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const handleStatusOpen = (event) => {
        setAnchorEl1(event.currentTarget);
    }

    const handleStatusClose = (param) => {
        setAnchorEl1(null);
        setExpanded(false);
        // setOpenBacKLoader(true);
        if (param === undefined) {
            filterData.limit = 10;
            filterData.page = 1;
            filterData.status = [];
            setFilterData({ ...filterData });
            setLoading(true);
            getAllPlacements(filterData);
        } else {
            filterData.limit = 10;
            filterData.page = 1;
            filterData['status'] = [param]
            setFilterData({ ...filterData });
            setLoading(true);
            getAllPlacements(filterData);
        }
    }

    const handleSort = (sort_column, sort_order) => {
        setExpanded(false);
        setAnchorEl(null);
        // setOpenBacKLoader(true);
        setListLoading(true);
        filterData.sort_column = sort_column;
        filterData.sort_order = sort_order;
        setFilterData({ ...filterData });
        setLoading(true);
        getAllPlacements({ ...filterData });
        // setOpenBacKLoader(false);

    }

    const loadMoreData = () => {
        // addLoader(true);
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
        getAllPlacements(filterData);

    }

    const handleOpenDrawer = () => {
        setDrawer("filter");
        if (filterData.start_date_from !== "" && filterData.start_date_to !== "") {
            filterState.start_date_from = filterData.start_date_from;
            filterState.start_date_to = filterData.start_date_to;
            setSelectedFilter(1);
        } else {
            filterState.start_date_from = '';
            filterState.start_date_to = '';
        }
        if (filterData.end_date_from !== "" && filterData.end_date_to !== "") {
            filterState.end_date_from = filterData.end_date_from;
            filterState.end_date_to = filterData.end_date_to;
            setSelectedFilter(2);
        } else {
            filterState.end_date_from = '';
            filterState.end_date_to = '';
        }
        if (filterData.clients.length > 0) {
            setSelectedFilter(3);
        }
        if (filterData.recruiter.length > 0) {
            setSelectedFilter(4);
        }
        if (filterData.timesheet_cycle.length > 0) {
            setSelectedFilter(5);
        }
        if (filterData.start_date_from == "" && filterData.start_date_to == "" && filterData.end_date_from == "" && filterData.end_date_to == "" && filterData.timesheet_cycle.length == 0 && filterData.clients.length == 0 && filterData.recruiter.length == 0) {
            setSelectedFilter(null);
        }
        filterState.clients = filterData.clients;
        filterState.recruiter = filterData.recruiter;
        filterState.timesheet_cycle = filterData.timesheet_cycle;
        setFilterState({ ...filterState });
        datesError.start_date_to = "";
        datesError.end_date_to = "";
        setDatesError({ ...datesError })
    }

    const scrollBox = useRef(null);

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(pagination.total) >= filterData.limit) {
                    // setOpenBacKLoader(true);
                    setListLoading(true);
                    loadMoreData();
                }
            } else {
            }
        }
    };

    /**
     * Handles the filtering when a recruiter placement count is clicked. 
     * Updates the filter based on the recruiter selected.
     * @param {string} param - The ID of the recruiter to filter by.
     */
    const handleRecFilter = (param) => {
        // Find the recruiter with the given id and mark it as checked
        const updatedRecruiters = recruiters.map(recruiter => {
            if (recruiter.id === param) {
                return { ...recruiter, check: true };
            } else {
                return recruiter;
            }
        });
        setRecruiters(updatedRecruiters);
        setFilterData(prevFilterData => ({
            ...prevFilterData,
            recruiter: Array.from(new Set([...prevFilterData.recruiter, param]))
        }));
        setLoading(true);
        setExpanded(false);
        getAllPlacements({ ...filterData, recruiter: [...filterData.recruiter, param] });
        setRecruitersTableShow(false);
    }

    return (
        <>
            {startTour ? <TourPlacement startTour={startTour} setStartTour={() => setStartTour(false)} /> :
                <Box className={classes.flexBox} style={{ paddingLeft: '65px' }}>
                    {recruitersTableShow ?
                        <RecruitersTable setRecruitersTableShow={setRecruitersTableShow} recruiters={recruiters} handleRecFilter={handleRecFilter} />
                        :
                        <Box sx={{ width: "90%" }} >
                            <Box style={{ padding: "20px 10px 10px 10px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div>
                                        <Text mediumViewAmt>All Placements</Text>
                                    </div>
                                    {
                                        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true))) ?
                                            <div>
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingRight: "5px" }}>
                                                    <Box sx={{
                                                        height: "44px", border: "1.5px solid #E2E5E6", width: "368px", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center",
                                                        "@media (min-width: 900px) and (max-width: 1400px)": {
                                                            width: "258px"
                                                        },
                                                    }}>
                                                        <input
                                                            type="text"
                                                            value={filterData.search}
                                                            className={classes.EmployeesSearchInput}
                                                            onChange={handleSearch}
                                                            placeholder="Search by Name / ID"
                                                        />
                                                        <Box sx={{ paddingRight: "15px !important" }}>
                                                            {
                                                                filterData.search !== "" ?
                                                                    <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                                    :
                                                                    <img src={Search} alt="Search" />
                                                            }
                                                        </Box>
                                                    </Box>
                                                    <button title="Export" type="button" className={classes.borderButton}
                                                        onClick={() => handleDownloadExport()}
                                                    >
                                                        <img src={NewDownloadcloud} alt="Userplus" />
                                                    </button>
                                                    <button title="Filter"
                                                        type="button"
                                                        className={classes.borderButton}
                                                        onClick={() => { handleOpenDrawer() }}>
                                                        <Badge badgeContent={filterData.recruiter.length + filterData.timesheet_cycle.length + filterData.clients.length + ((filterData.start_date_from !== "" && filterData.start_date_to !== "") && 1) + ((filterData.end_date_from !== "" && filterData.end_date_to !== "") && 1)} color="error">
                                                            <FilterListIcon sx={{ color: (filterData.timesheet_cycle.length > 0 || filterData.recruiter.length > 0 || filterData.clients.length > 0 || (filterData.start_date_from !== "" && filterData.start_date_to !== "") || (filterData.end_date_from !== "" && filterData.end_date_to !== "")) ? "#2BEA2B" : "" }} />
                                                        </Badge>
                                                    </button>
                                                    <HtmlTooltip
                                                        placement="bottom"
                                                        arrow
                                                        title={
                                                            <React.Fragment>
                                                                <Box textAlign={"center"}>
                                                                    <Typography className={classes.profileTooltipText}>
                                                                        Recruiters
                                                                    </Typography>
                                                                </Box>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <button
                                                            type="button"
                                                            className={classes.borderButton}
                                                            onClick={() => setRecruitersTableShow(true)}
                                                        >
                                                            {
                                                                filterData.recruiter.length > 0 ?
                                                                    <Badge className={classes.badge} badgeContent={filterData.recruiter.length} color="error">
                                                                        <img src={recruitersActive} alt="recruitersActive" />
                                                                    </Badge>
                                                                    :
                                                                    <img src={recruiter} alt="recruiter" />
                                                            }
                                                        </button>
                                                    </HtmlTooltip>
                                                    <Button analyticsView onClick={viewAnalytics}>View Analytics</Button>
                                                    {/* {
                                                    (LocalStorage.getUserData().super_admin || (rolePermission != "" && rolePermission.some(item => item.slug == "placement_create" && item.is_allowed == true))) ? */}
                                                    <Button sx={{ padding: "0px 15px !important" }} addButton id="add-employee" onClick={() => { navigate("/placements/placementsInfo") }} startIcon={<AddIcon />}>
                                                        Add Placement
                                                    </Button>
                                                    {/* :
                                                        ""
                                                } */}
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </div>

                            </Box>
                            {
                                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true))) ?
                                    <Box style={{ padding: "10px" }}>

                                        <Box sx={{
                                            display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px",
                                            "@media (min-width: 200px) and (max-width: 1400px)": {
                                                font: "12px Quicksand",
                                                fontWeight: "600",
                                                paddingTop: '1px'
                                            },
                                        }} >
                                            <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                <Grid item lg={4.5} md={4} sm={4} xs={4.5} textAlign={'left'} letterSpacing={'0.5px'}>Employee Name
                                                    <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} onClick={handleClick}>
                                                        <FilterListIcon sx={{ height: "19px", color: (filterData.sort_column == "name" || filterData.sort_column == "created_at") ? "#2BEA2B" : "" }} />
                                                    </IconButton>
                                                    <Menu
                                                        anchorEl={anchorEl}
                                                        id="account-menu"
                                                        open={openFilter}
                                                        onClose={handleCloseFilter}
                                                        onClick={handleCloseFilter}
                                                        PaperProps={{
                                                            elevation: 0,
                                                            sx: {
                                                                overflow: "visible",
                                                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                mt: 0.5,
                                                                ml: -2,
                                                                padding: "5px",
                                                                "& .MuiMenuItem-root:hover": {
                                                                    backgroundColor: "#EAECF0 !important",
                                                                }
                                                            },
                                                        }}
                                                        transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                                    >
                                                        <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'created_at' && filterData.sort_order == 'desc' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'desc')}> Newest First</MenuItem>
                                                        <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'created_at' && filterData.sort_order == 'asc' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'asc')}> Oldest First</MenuItem>
                                                        <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'name' && filterData.sort_order == 'asc' ? '#EAECF0' : "" }} onClick={() => handleSort('name', 'asc')}> Name (A-Z)</MenuItem>
                                                        <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'name' && filterData.sort_order == 'desc' ? '#EAECF0' : "" }} onClick={() => handleSort('name', 'desc')}> Name (Z-A)</MenuItem>
                                                    </Menu>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerNames}>Client Name</Grid>
                                                <Grid item lg={1} md={1.5} sm={1} xs={1} className={classes.headerNames} >Start Date</Grid>
                                                <Grid container item lg={2} md={2.1} sm={2} xs={2}>
                                                    <Grid item lg={6} md={6} sm={6} xs={6} className={classes.headerEndDate} >End Date</Grid>
                                                    <Grid aria-controls="status-menu" item lg={6} md={6} sm={6} xs={6} className={classes.headerStatus} onClick={handleStatusOpen} >Status
                                                        {
                                                            openStatus ?
                                                                <KeyboardArrowUpIcon sx={{ color: filterData.status.length > 0 ? "#2BEA2B !important" : "" }} />
                                                                :
                                                                <KeyboardArrowDownIcon sx={{ color: filterData.status.length > 0 ? "#2BEA2B !important" : "" }} />
                                                        }
                                                    </Grid>
                                                    <Menu
                                                        anchorEl={anchorEl1}
                                                        id="status-menu"
                                                        open={openStatus}
                                                        onClose={() => { setAnchorEl1(null) }}
                                                        PaperProps={{
                                                            elevation: 0,
                                                            sx: {
                                                                overflow: "visible",
                                                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                mt: 0.5,
                                                                width: "170px",
                                                                "& .MuiMenuItem-root:hover": {
                                                                    backgroundColor: "#EAECF0 !important",
                                                                },
                                                            }
                                                        }}
                                                        transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                                    >
                                                        <MenuItem onClick={() => { handleStatusClose() }} className={classes.menuItem}>All</MenuItem> <Divider className={classes.divider} />
                                                        <MenuItem onClick={() => { handleStatusClose("Active") }} className={filterData.status.length > 0 && filterData.status[0] == "Active" ? classes.activeMenuItem : classes.menuItem}> Active Placements</MenuItem> <Divider className={classes.divider} />
                                                        <MenuItem onClick={() => { handleStatusClose("In Active") }} className={filterData.status.length > 0 && filterData.status[0] == "In Active" ? classes.activeMenuItem : classes.menuItem}> Inactive Placements</MenuItem>
                                                    </Menu>
                                                </Grid>
                                                <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5} display={"flex"} justifyContent={"center"}>
                                                    <Text offBoardBodyFont>
                                                        Count
                                                        <HtmlTooltip
                                                            placement="bottom"
                                                            arrow
                                                            title={
                                                                <React.Fragment>
                                                                    <Box>
                                                                        <Typography className={classes.profileTooltipText}>
                                                                            Total Placements count is <br />based on the filters applied
                                                                        </Typography>
                                                                    </Box>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                                        </HtmlTooltip>
                                                        {` - ${pagination ? pagination.total ? pagination.total : 0 : 0} Placements`}</Text>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {
                                            loading ?
                                                [1, 2, 3, 4].map((item) => (
                                                    <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                        <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                            <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <Box>
                                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                                </Box>
                                                                <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                            </Box>
                                                        </Box>
                                                    </Stack>
                                                )) :
                                                <>
                                                    {
                                                        placements.length > 0 ?
                                                            <Box sx={{
                                                                height: "65.3vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
                                                                paddingLeft: '5px', paddingRight: '5px',
                                                                "@media (min-height: 850px)": {
                                                                    height: '73.5vh'
                                                                }
                                                            }} ref={scrollBox} onScroll={handleScroll}>
                                                                {
                                                                    placements.map((place, key) => (
                                                                        <Accordion
                                                                            key={key}
                                                                            className={classes.newCustomAccordion}
                                                                            expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                                                                            <AccordionSummary
                                                                                aria-controls="panel1bh-content"
                                                                                id="panel1bh-header"
                                                                                className={classes.AccordionSummary}
                                                                            >
                                                                                <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                                                    <Grid item container lg={4.5} md={4} sm={4} xs={4.5} textAlign='start' >
                                                                                        <Box display='flex' alignItems='center' gap={2} width={'50%'} flexGrow={0.5}>                                                                                          
                                                                                            <CustomCircularProgress
                                                                                                percentage={place.profile_progress}
                                                                                                color={place.profile_progress >= 76 ? 'green' : place.profile_progress <= 75 && place.profile_progress >= 51 ? 'yellow' : place.profile_progress <= 50 ? "#FFBF00" : ''}
                                                                                                src={place.profile_picture_url}
                                                                                                name={place.profile_picture_url == undefined || place.profile_picture_url == null || place.profile_picture_url == '' ? place.employee_name && capitalizeAndAddSpace(place.employee_name[0]) : ''}
                                                                                                imgWidth='50px !important'
                                                                                                imgHeight='50px !important'
                                                                                                avatarWidth='50px !important'
                                                                                                avatarHeight='50px !important'
                                                                                                value={place.profile_progress}
                                                                                                size={58}
                                                                                                tooltip
                                                                                                tooltipContent={
                                                                                                    <Box p={'3px 0px'} width='100%'>
                                                                                                        <Text mediumBoldWhite400>{`Profile Completion - ${place.profile_progress}%`}</Text>
                                                                                                    </Box>
                                                                                                }
                                                                                            />
                                                                                            <Box>
                                                                                                {
                                                                                                    place.status == 'In Active' ?
                                                                                                        <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                                            {place.employee_name === "" ? "--" : capitalizeAndAddSpace(place.employee_name).length > 16 ?
                                                                                                                <HtmlTooltip title={capitalizeAndAddSpace(place.employee_name)} placement="right" arrow>
                                                                                                                    {capitalizeAndAddSpace(place.employee_name).slice(0, 16) + (capitalizeAndAddSpace(place.employee_name).length > 16 ? "..." : "")}
                                                                                                                </HtmlTooltip>
                                                                                                                :
                                                                                                                capitalizeAndAddSpace(place.employee_name)
                                                                                                            }
                                                                                                            {place.employee_e_verified == 1 || place.employee_e_verified == 4 ?
                                                                                                                <HtmlTooltip title={<Text smallWhite>E-verified</Text>} placement="right" arrow>
                                                                                                                    <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                </HtmlTooltip>
                                                                                                                : place.employee_e_verified == 2 ?
                                                                                                                    <HtmlTooltip title={<Text smallWhite>E-Verification is pending</Text>} placement="right" arrow>
                                                                                                                        <img src={Pending} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                    </HtmlTooltip> : ''
                                                                                                            }
                                                                                                        </Text>
                                                                                                        :
                                                                                                        <Text mediumBoldBlack noWrap>
                                                                                                            {place.employee_name === "" ? "--" :
                                                                                                                capitalizeAndAddSpace(place.employee_name).length > 16 ?
                                                                                                                    <HtmlTooltip title={capitalizeAndAddSpace(place.employee_name)} placement="right" arrow>
                                                                                                                        {capitalizeAndAddSpace(place.employee_name).slice(0, 16) + (capitalizeAndAddSpace(place.employee_name).length > 16 ? "..." : "")}
                                                                                                                    </HtmlTooltip>
                                                                                                                    :
                                                                                                                    capitalizeAndAddSpace(place.employee_name)
                                                                                                            }
                                                                                                            {place.employee_e_verified == 1 || place.employee_e_verified == 4 ?
                                                                                                                <HtmlTooltip title={<Text smallWhite>E-Verified</Text>} placement="right" arrow>
                                                                                                                    <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                </HtmlTooltip>
                                                                                                                : place.employee_e_verified == 2 ?
                                                                                                                    <HtmlTooltip title={<Text smallWhite>E-Verification is pending</Text>} placement="right" arrow>
                                                                                                                        <img src={Pending} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                    </HtmlTooltip> : ''
                                                                                                            }
                                                                                                        </Text>
                                                                                                }
                                                                                                <Text mediumLabel noWrap style={{ margin: '4px 0' }}>  {place.employee_reference_id ? place.employee_reference_id : "--"} &#128900; {place.placement_reference_id ? place.placement_reference_id : "--"}</Text>

                                                                                            </Box>
                                                                                        </Box>
                                                                                        {(place.status == 'Active' &&  appIntegrations?.connection_status == "connected" && appIntegrations?.redirect_to == "") && 
                                                                                        <Box display='flex' alignItems='center' justifyContent='start' paddingLeft={'10%'} width={'50%'}>
                                                                                            {((place?.zoho_migration_status == "Not Migrated" || place?.zoho_migration_status == "Error") && currentSyncingId !== place?.id) &&
                                                                                                <Button zohosyncnow onClick={(e) => handleZohosync(e, place?.id)}><img alt='zohoicon' src={Zohosyncnow} className={classes.zohoIcon} />Sync Now</Button>}

                                                                                            {(syncing && currentSyncingId == place?.id) &&
                                                                                                <Button zohosyncing  ><img alt='zohoicon' src={Zohosyncing} className={classes.zohoIcon} />Syncing...</Button>}
                                                                                            {(place?.zoho_migration_status == "Migrated" && currentSyncingId !== place?.id) && <>
                                                                                                <Button zohosynced  >Synced<span className={classes.syncedSpan}><img alt='zohoicon' src={Zohosynced} className={classes.zohoIcon} /></span></Button>
                                                                                                <Box display='flex' alignItems='center' justifyContent='center'>
                                                                                                    <img alt='zohoicon' src={Zohorefresh} className={classes.zohoRefresh} onClick={(e) => handleZohosync(e, place?.id)} />
                                                                                                </Box>
                                                                                            </>}

                                                                                        </Box>}

                                                                                    </Grid>
                                                                                    <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"start"}>
                                                                                        <Text smallBoldBlack noWrap >
                                                                                            {place.client_name === "" ? "--" : capitalizeAndAddSpace(place.client_name).length > 16 ?
                                                                                                <HtmlTooltip title={capitalizeAndAddSpace(place.client_name)} placement="right" arrow>
                                                                                                    {capitalizeAndAddSpace(place.client_name).slice(0, 16) + (capitalizeAndAddSpace(place.client_name).length > 16 ? "..." : "")}
                                                                                                </HtmlTooltip>
                                                                                                :
                                                                                                capitalizeAndAddSpace(place.client_name)
                                                                                            }
                                                                                        </Text>
                                                                                    </Grid>
                                                                                    <Grid item lg={1} md={1.5} sm={1} xs={1} textAlign={"start"}>
                                                                                        <Text smallBoldBlack noWrap >{place.start_date ? place.start_date : "--"}</Text>
                                                                                    </Grid>
                                                                                    <Grid container lg={3} md={3} sm={3} xs={3} textAlign={"start"}>
                                                                                        <Stack
                                                                                            direction="row"
                                                                                            useFlexGap
                                                                                            width='80%'
                                                                                            divider={<Divider orientation="vertical" flexItem style={{ height: '50px' }} />}
                                                                                            spacing={3}
                                                                                            textAlign='center' alignItems='center'
                                                                                        >
                                                                                            <Grid item lg={6} md={6} sm={6} xs={6} textAlign={"start"}>
                                                                                                <Text smallBoldBlack noWrap pr={place.end_date ? 0 : 2.4} >{place.end_date ? place.end_date : `Current`}</Text>
                                                                                            </Grid>

                                                                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                                                                {
                                                                                                    place.status == 'In Active' ?
                                                                                                        <Box className={classes.inActiveBox}>
                                                                                                            <Text mediumBoldWhite>Inactive</Text>
                                                                                                        </Box> :
                                                                                                        <Box className={classes.activeBox}>
                                                                                                            <Text mediumBoldWhite>Active</Text>
                                                                                                        </Box>
                                                                                                }
                                                                                            </Grid>
                                                                                        </Stack>

                                                                                    </Grid>

                                                                                    <Grid item lg={1.5} md={1.5}>
                                                                                        {
                                                                                            (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true))) ?
                                                                                                <Typography
                                                                                                    onClick={() => { LocalStorage.removeEmployeeVerifyId(); LocalStorage.removeRedirectedModule(); LocalStorage.removeNotificationId(); }}
                                                                                                    component={Link}
                                                                                                    to={`/placements/view-placement`}
                                                                                                    state={{ id: place.employee_id, placement_id: place.id }}
                                                                                                    className={classes.linkText}
                                                                                                >
                                                                                                    View Placement
                                                                                                </Typography> :
                                                                                                <Text defaultmediumText className={classes.linkInactive} noWrap >View Placement</Text>
                                                                                        }
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <Box className={classes.AccordionDetails} pt={1}>
                                                                                    <Box width={'100%'}>
                                                                                        <Typography className={classes.text1}>
                                                                                            Timesheet Cycle
                                                                                        </Typography>
                                                                                        <Typography my={1} className={classes.text2}>
                                                                                            {place.timesheet_cycle ? place.timesheet_cycle : "--"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box width={'100%'}>
                                                                                        <Typography className={classes.text1}>
                                                                                            Pay Rate ({place.pay_type == 2 ? "Salary" : "Hourly"})
                                                                                        </Typography>
                                                                                        <Typography my={1} className={classes.text2}>
                                                                                            {place.pay_type == 2 ? "--" : place.pay_rate ? `${LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} ${place.pay_rate}` : "--"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box width={'100%'}>
                                                                                        <Typography className={classes.text1}>
                                                                                            Bill Rate
                                                                                        </Typography>
                                                                                        <Typography my={1} className={classes.text2}>
                                                                                            {place.bill_rate ? `${LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} ${place.bill_rate}` : "--"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box width={'100%'} >
                                                                                        <Typography className={classes.text1}>
                                                                                            Recruiter Name
                                                                                        </Typography>
                                                                                        <Typography my={1} className={classes.text2}>
                                                                                            {place.recruiter_name === "" ? "Not Assigned" : capitalizeAndAddSpace(place.recruiter_name).length > 16 ?
                                                                                                <HtmlTooltip title={capitalizeAndAddSpace(place.recruiter_name)} placement="right" arrow>
                                                                                                    {capitalizeAndAddSpace(place.recruiter_name).slice(0, 16) + (capitalizeAndAddSpace(place.recruiter_name).length > 16 ? "..." : "")}
                                                                                                </HtmlTooltip>
                                                                                                :
                                                                                                capitalizeAndAddSpace(place.recruiter_name)
                                                                                            }
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    ))
                                                                }
                                                                {
                                                                    listLoading && (<ListLoadingIcon />)
                                                                }
                                                            </Box>
                                                            :
                                                            placements.length == 0 ?
                                                                <Box>
                                                                    {NoDataFound("To fetch the data. Please add placements", 'No Placement Yet')}
                                                                </Box>
                                                                : ''
                                                    }
                                                </>
                                        }
                                    </Box> :
                                    <>
                                        {NoPermission()}
                                    </>
                            }
                        </Box>
                    }

                    {/* <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openBacKLoader}
                    // onClick={handleClose}
                    >
                        <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                    </Backdrop> */}

                    <BootstrapDialog
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                        fullWidth={true}
                        maxWidth={"md"}
                    >

                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                                boxShadow: 'none !important',
                                "&:hover": {
                                    boxShadow: 'none !important',
                                    background: 'none !important'
                                }
                            }}
                        >
                            <img src={crossIcon} alt="cross" />
                            {/* <CloseIcon sx={{ color: "rgba(38, 38, 38, 1)" }} /> */}
                        </IconButton>
                        <DialogContent sx={{ margin: "50px", }}>
                            <Grid container spacing={0}>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box style={{ height: "400px", width: "400px", display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                        <Box sx={{ textAlign: "center" }}>
                                            <Box sx={{ margin: "12px 0px" }}>
                                                <img src={InviteViaLink} alt='invite-via-link' style={{ height: "200px" }} />
                                            </Box>
                                            <Box sx={{ margin: "8px 0px" }}>
                                                {/* <Typography sx={{ fontSize: "12px", fontFamily: "Quicksand", fontWeight: "400", color: "rgba(38, 38, 38, 1)", textAlign: "center", wordWrap: "break-word", }}>
                                            Send the employee an invite link to add them to <br /> the organization.
                                        </Typography> */}
                                                <Text mediumLabel>Send the employee an invite link to add them to <br /> the organization.</Text>
                                            </Box>
                                            <Box sx={{ margin: "4px 0px" }}>
                                                <button
                                                    onClick={() => navigate("/employees/add")}
                                                    type="button"
                                                    className={classes.inviteLinkBtn}
                                                // style={{
                                                //     margin: "12px 0px", all: "unset", cursor: "pointer", textAlign: "center", fontSize: "15px", fontFamily: "Quicksand CY, sans-serif", fontWeight: "400", width: "150px", height: "40px", border: "1.5px solid rgba(12, 117, 235, 1)", borderRadius: "6px", color: "rgba(12, 117, 235, 1)",
                                                //     "&::hover": {
                                                //         backgroundColor:'rgba(12, 117, 235, 1)',color:'#FFFFFF'
                                                // } }}
                                                >
                                                    Invite via link
                                                </button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box style={{ height: "400px", width: "400px", display: 'flex', justifyContent: "center", alignItems: "center", borderLeft: "1.5px solid rgba(199, 204, 211, 1)" }}>
                                        <Box sx={{ textAlign: "center" }}>
                                            <Box sx={{ margin: "16px 0px" }}>
                                                <img src={Onboart} alt='onboart' style={{ height: "200px" }} />
                                            </Box>
                                            <Box sx={{ margin: "6px 0px" }}>
                                                {/* <Typography sx={{ fontSize: "12px", fontFamily: "Quicksand CY, sans-serif", fontWeight: "400", color: "rgba(38, 38, 38, 1)", textAlign: "center", wordWrap: "break-word", }}>
                                        Onboard an employee manually, to add them to <br />the organization.
                                        </Typography> */}
                                                <Text mediumLabel> Onboard an employee manually, to add them to <br />the organization.</Text>
                                            </Box>
                                            <Box sx={{ margin: "20px 0px", }}>
                                                <button
                                                    type="button"
                                                    className={classes.onboardBtn}
                                                    onClick={() => { navigate('/employees/onboard'); LocalStorage.removeEmployeeId(); LocalStorage.removeVisaID(); LocalStorage.removeFullName(); }}
                                                >
                                                    Onboard
                                                </button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </BootstrapDialog>

                    <SwipeableDrawer
                        anchor={'right'}
                        open={drawer}
                        onClose={() => { setDrawer(null) }}
                        transitionDuration={300}
                        sx={{
                            ".MuiDrawer-paper ": {
                                borderRadius: '8px 0px 0px 8px !important',
                            },
                            "& .MuiBackdrop-root.MuiModal-backdrop": {
                                backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                            }
                        }
                        }
                    >
                        {
                            drawer === "filter" ?
                                <Box width={'660px'} height={'100vh'} >
                                    <Box height={'10vh'} borderBottom={'1px solid #EAECF0'} display={'flex'} alignItems={'center'} pl={4} >
                                        <Text headerBlack>Filters</Text>
                                    </Box>

                                    <Box display={'flex'} height={'7vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                                        <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={1} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>

                                            {
                                                filterState.start_date_from !== "" && filterState.start_date_to !== "" &&
                                                <>
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={1}
                                                        icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack>{filterState.start_date_from} - {filterState.start_date_to}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip('', "start_date")}
                                                        deleteIcon={<CloseIcon />} />
                                                </>
                                            }

                                            {
                                                filterState.end_date_from !== "" && filterState.end_date_to !== "" &&
                                                <>
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={1}
                                                        icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack>{filterState.end_date_from} - {filterState.end_date_to}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip('', "end_date")}
                                                        deleteIcon={<CloseIcon />} />
                                                </>
                                            }
                                            {
                                                clientDropdown.map((item, key) => (
                                                    filterState.clients.includes(item.id) &&
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={key}
                                                        icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                            <BlackToolTip title={item.value} placement="right" arrow>
                                                                {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                            </BlackToolTip>
                                                            :
                                                            item.value}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip(item.id, "clients")}
                                                        deleteIcon={<CloseIcon />} />
                                                ))
                                            }
                                            {
                                                recruiters.map((item, key) => (
                                                    filterState.recruiter.includes(item.id) &&
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={key}
                                                        icon={<CircleIcon sx={{ color: "#2DC890 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack nowrap>{
                                                            item.display_name === "" ? "--" : item.display_name && item.display_name.length > 16 ?
                                                                <BlackToolTip title={item.display_name} placement="right" arrow>
                                                                    {item.display_name.slice(0, 16) + (item.display_name.length > 16 ? "..." : "")}
                                                                </BlackToolTip>
                                                                :
                                                                item.display_name
                                                        }</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip(item.id, "recruiter")}
                                                        deleteIcon={<CloseIcon />}
                                                    />
                                                ))
                                            }
                                            {
                                                cycleDropdown.map((item, key) => (
                                                    filterState.timesheet_cycle.includes(item.id) &&
                                                    <Chip key={key}
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        icon={<CircleIcon sx={{ color: "#7643A3 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack>{item.value}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip(item.id, "timesheet_cycle")}
                                                        deleteIcon={<CloseIcon />}
                                                    />
                                                ))
                                            }
                                        </Box>
                                        {
                                            ((filterState.start_date_from !== "" && filterState.start_date_to !== "") || (filterState.end_date_from !== "" && filterState.end_date_to !== "") || filterState.recruiter.length > 0 || filterState.clients.length > 0 || filterState.timesheet_cycle.length > 0) ?
                                                <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                                :
                                                ''
                                        }
                                    </Box>

                                    <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'73vh'} >
                                        <Box display={'flex'} flexDirection={'column'} height={'100%'} width={'43%'} borderRight={'1px solid #EAECF0'} pt={0} >
                                            <List component="nav" aria-label="secondary mailbox folder">
                                                <ListItem className={selectedFilter == 1 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(1)} secondaryAction={filterState.start_date_from !== "" && filterState.start_date_to ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterState.start_date_from !== "" && filterState.start_date_to ? "1" : ""}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 1 ? <Text smallBlue1 ml={3}>Project Start Date</Text> : <Text mediumBlack ml={3}>Project Start Date</Text>} />
                                                </ListItem>
                                                <Divider />
                                                <ListItem className={selectedFilter == 2 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(2)} secondaryAction={(filterState.end_date_from !== "" && filterState.end_date_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{(filterState.end_date_from !== "" && filterState.end_date_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 2 ? <Text ml={3} smallBlue1>Project End Date</Text> : <Text ml={3} mediumBlack>Project End Date</Text>} />
                                                </ListItem>
                                                <Divider className={classes.divider} />
                                                <ListItem className={selectedFilter == 3 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(3)} secondaryAction={filterState.clients.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{filterState.clients.length}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 3 ? <Text ml={3} smallBlue1>Clients</Text> : <Text ml={3} mediumBlack>Clients</Text>} />
                                                </ListItem>
                                                <Divider />
                                                <ListItem className={selectedFilter == 4 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(4)} secondaryAction={filterState.recruiter.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#2DC890" }}><Text mediumWhite400>{filterState.recruiter.length}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 4 ? <Text ml={3} smallBlue1>Recruiter</Text> : <Text ml={3} mediumBlack>Recruiter</Text>} />
                                                </ListItem>
                                                <Divider />
                                                <ListItem className={selectedFilter == 5 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(5)} secondaryAction={filterState.timesheet_cycle.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#7643A3" }}><Text mediumWhite400>{filterState.timesheet_cycle.length}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 5 ? <Text ml={3} smallBlue1>Timesheet Cycle</Text> : <Text ml={3} mediumBlack>Timesheet Cycle</Text>} />
                                                </ListItem>
                                                <Divider />
                                            </List>

                                        </Box>
                                        <Box display={'flex'} sx={{ overflow: "auto" }} flexDirection={'column'} height={'70vh'} width={'55%'} pl={'25px !important'} pt={2}>

                                            {
                                                selectedFilter == 1 ?
                                                    <Grid container alignItems={"center"}>
                                                        <Grid item lg={5.5} md={5} sm={5}>
                                                            <Date
                                                                labelText={`From`}
                                                                name='start_date_from'
                                                                value={filterState.start_date_from}
                                                                onChange={(value) => dateChange(value, 'start_date_from')}
                                                                height='56px'
                                                            />
                                                        </Grid>
                                                        <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                            &#8594;
                                                        </Grid>
                                                        <Grid item lg={5.5} md={5} sm={5}>
                                                            <Date
                                                                labelText={`To`}
                                                                name='start_date_to'
                                                                value={filterState.start_date_to}
                                                                onChange={(value) => dateChange(value, 'start_date_to')}
                                                                height='56px'
                                                                minDate={moment(filterState.start_date_from).format(dateFormat())}
                                                                disabled={filterState.start_date_from == ""}
                                                                error={datesError.start_date_to}
                                                            />
                                                            <Box sx={{ height: "0px" }}>{datesError.start_date_to ? <Text red>{datesError.start_date_to ? datesError.start_date_to : ''}</Text> : ''}</Box>
                                                        </Grid>
                                                    </Grid> :
                                                    selectedFilter == 2 ?
                                                        <Grid container alignItems={"center"}>
                                                            <Grid item lg={5.5} md={5} sm={5}>
                                                                <Date
                                                                    labelText={`From`}
                                                                    name='end_date_from'
                                                                    value={filterState.end_date_from}
                                                                    onChange={(value) => dateChange(value, 'end_date_from')}
                                                                    height='56px'
                                                                />
                                                            </Grid>
                                                            <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                                &#8594;
                                                            </Grid>
                                                            <Grid item lg={5.5} md={5} sm={5}>
                                                                <Date
                                                                    labelText={`To`}
                                                                    name='end_date_to'
                                                                    value={filterState.end_date_to}
                                                                    onChange={(value) => dateChange(value, 'end_date_to')}
                                                                    height='56px'
                                                                    minDate={moment(filterState.end_date_from).format(dateFormat())}
                                                                    disabled={filterState.end_date_from == ""}
                                                                    error={datesError.end_date_to}
                                                                />
                                                                <Box sx={{ height: "0px" }}>{datesError.end_date_to ? <Text red>{datesError.end_date_to ? datesError.end_date_to : ''}</Text> : ''}</Box>
                                                            </Grid>
                                                        </Grid> :
                                                        selectedFilter == 3 ?
                                                            <>
                                                                {
                                                                    clientDropdown.length > 0 && clientDropdown.map((item, index) => (
                                                                        <Grid container alignItems={"center"} pb={2}>
                                                                            <FormControlLabel
                                                                                key={index}
                                                                                control={
                                                                                    <Checkbox
                                                                                        size="small"
                                                                                        name={"clients"}
                                                                                        value={item.id}
                                                                                        onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                        // onClick = {handleCheckClick()}
                                                                                        icon={<CheckBorderIcon />}
                                                                                        checkedIcon={<CheckedIcon />}
                                                                                        checked={filterState.clients.includes(item.id)} />}
                                                                                label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                                    <BlackToolTip title={item.value} placement="right" arrow>
                                                                                        {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                                    </BlackToolTip>
                                                                                    :
                                                                                    item.value}</Text>}
                                                                            />
                                                                        </Grid>
                                                                    ))
                                                                }
                                                            </>
                                                            : selectedFilter == 4 ?
                                                                <>
                                                                    {
                                                                        recruiters.length > 0 && recruiters.map((item, index) => (
                                                                            <Grid container alignItems={"center"} pb={2}>
                                                                                <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                                    <FormControlLabel
                                                                                        key={index}
                                                                                        control={
                                                                                            <Checkbox
                                                                                                size="small"
                                                                                                name={"recruiter"}
                                                                                                value={item.id}
                                                                                                onChange={handleChangeRecruiters}
                                                                                                icon={<CheckBorderIcon />}
                                                                                                checkedIcon={<CheckedIcon />}
                                                                                                checked={filterState.recruiter.includes(item.id)} />}
                                                                                        label={<Text mediumBlack nowrap>{
                                                                                            item.display_name === "" ? "--" : item.display_name && item.display_name.length > 16 ?
                                                                                                <BlackToolTip title={item.display_name} placement="right" arrow>
                                                                                                    {item.display_name.slice(0, 16) + (item.display_name.length > 16 ? "..." : "")}
                                                                                                </BlackToolTip>
                                                                                                :
                                                                                                item.display_name
                                                                                        }</Text>}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid container alignItems={"center"} item lg={2} md={2} sm={2}>
                                                                                    <Text smallBlue1>{item.count}</Text>
                                                                                </Grid>
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                </>
                                                                : selectedFilter == 5 ?
                                                                    <>
                                                                        {
                                                                            cycleDropdown.length > 0 && cycleDropdown.map((item, index) => (
                                                                                <Grid container alignItems={"center"} pb={2}>
                                                                                    {
                                                                                        item.id != 5 &&
                                                                                        <>
                                                                                            <FormControlLabel
                                                                                                key={index}
                                                                                                control={
                                                                                                    <Checkbox
                                                                                                        size="small"
                                                                                                        name={"timesheet_cycle"}
                                                                                                        value={item.id}
                                                                                                        onChange={(e) => handleChangeCheckBox(e)}
                                                                                                        icon={<CheckBorderIcon />}
                                                                                                        checkedIcon={<CheckedIcon />}
                                                                                                        checked={filterState.timesheet_cycle.includes(item.id)} />}
                                                                                                label={<Text mediumBlack >{item.value}</Text>}
                                                                                            />
                                                                                        </>
                                                                                    }

                                                                                </Grid>
                                                                            ))
                                                                        }
                                                                    </> :
                                                                    <Box sx={{ height: "60vh", width: "100%", display: "flex", alignItems: "center" }}>
                                                                        <Grid container>
                                                                            <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                                <img src={nofilterplacement} alt="nofilterplacement" style={{ paddingTop: "15px" }} />
                                                                            </Grid>
                                                                            <Grid item lg={12} md={12} sm={12} textAlign={"center"} >
                                                                                <Text veryLargeLabel>Gear Up!</Text>
                                                                            </Grid>
                                                                            <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                                <Text offBoardBodyFont>Select filters to scale our data peaks</Text>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                // nofilterplacement
                                            }
                                        </Box>
                                    </Box>

                                    <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={1} height={'73px'} pr={'26px'}>
                                        <Button cancelSmall onClick={() => cancelFilter()}>Cancel</Button>
                                        <Button saveSmall onClick={() => handleApplyFilters()} >Apply Filters</Button>
                                    </Box>
                                </Box >
                                : null
                        }

                    </SwipeableDrawer>
                </Box >}</>
    );
}