import React from 'react';
import { Box, Grid, Divider } from '@mui/material';
import Text from '../../../../components/customText/Text';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { NoDataFound } from '../../../../utils/utils';

function RecruitersTable(props) {
    const { setRecruitersTableShow, recruiters, handleRecFilter } = props;
    return (
        <Grid container pt={4}>
            <Grid item lg={2} md={1} sm={1}></Grid>
            <Grid item lg={8} md={10} sm={10}>
                <Box mb={2} p={4} sx={{ boxShadow: "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", height: "81vh", overflow: "auto !important" }}>
                    <Grid container>
                        <ArrowBackIcon onClick={() => setRecruitersTableShow(false)} sx={{ cursor: "pointer" }} />
                        <Text blackHeader18 pl={1}>Recruiters</Text>
                    </Grid>
                    <Grid container padding={"10px"} alignItems={"center"} mt={2}>
                        <Grid item lg={5} md={4} sm={4}><Text overViewLable sx={{ font: "14px Quicksand !important", fontWeight: "600 !important" }}>Recruiter Name</Text></Grid>
                        <Grid item lg={4} md={4} sm={4}><Text overViewLable sx={{ font: "14px Quicksand !important", fontWeight: "600 !important" }}>Role</Text></Grid>
                        <Grid item lg={3} md={4} sm={4}><Text overViewLable sx={{ font: "14px Quicksand !important", fontWeight: "600 !important" }}>Number of Placements</Text></Grid>
                    </Grid>
                    {
                        recruiters.length > 0 ?
                            <Box sx={{ height: "60vh", overflow: "auto !important" }}>
                                {
                                    recruiters.map((item, index) => (
                                        <>
                                            <Grid key={index} container padding={"24px 10px"} alignItems={"center"}>
                                                <Grid item lg={5} md={4} sm={4}><Text mediumBlack>{item.display_name ? item.display_name : "--"}</Text></Grid>
                                                <Grid item lg={4} md={4} sm={4}><Text mediumBlack>{item.role_name ? item.role_name : "--"}</Text></Grid>
                                                <Grid item lg={3} md={4} sm={4}><Text smallBlue1 sx={{ cursor: "pointer" }} onClick={() => handleRecFilter(item.id)}>{item.count}</Text></Grid>
                                            </Grid>
                                            <Divider />
                                        </>
                                    ))
                                }
                            </Box> :
                            <Box >
                                {NoDataFound("To fetch the data","No Data Found")}
                            </Box>
                    }
                </Box>
            </Grid>
            <Grid item lg={2} md={1} sm={1}></Grid>
        </Grid>
    )
}

export default RecruitersTable