import React, { useEffect, useState } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import Button from '../../../../../components/customButton/Button';
import Text from '../../../../../components/customText/Text';
import MainStyles from '../../MainStyles'
import Input from '../../../../../components/input/Input';// eslint-disable-next-line
// import EmployeeTypesApi from '../../../../../apis/configurations/employee/EmployeeTypesApi';
import { validate_usContact, validates_emailId, isValid, validate_emptyField, validate_zipcode, validate_city, validate_url, address_validation, validate_empty_address, validate_alphaNumericWithSpecialChars } from '../../../../../components/Validation';
import LocalStorage from '../../../../../utils/LocalStorage';
import FileInput from '../../../../../components/muiFileInput/FileInput';
import CommonApi from '../../../../../apis/CommonApi';
import { NoPermission, addErrorMsg, addLoader, addSuccessMsg, checkZipcode, removeLoader } from '../../../../../utils/utils';
import OrganizationDetailsApi from '../../../../../apis/configurations/organization/OrganizationDetailsApi';
import blueLoader from '../../../../../assets/gif/japfuLoader.gif';
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import Select from '../../../../../components/select/Select';
import EditIcon from '../../../../../assets/svg/newEdit.svg'
import { useSearchParams } from 'react-router-dom';
import { domain } from '../../../../../config/Domain';
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import Sure from '../../../../../assets/svg/placementPop.svg'
import Zipcode from '../../../../admin/Zipcode';
import { useZipcodeAPICall } from '../../../../admin/ZipcodeCommon';
import { Buffer } from 'buffer';

function OrganizationDetails(props) {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = MainStyles()// eslint-disable-next-line
    const [isEditable, setIsEditable] = useState(LocalStorage.getUserData().org_config_set == false ? false : true);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [btnloading, setBtnLoading] = useState(false);
    const [state, setState] = useState({
        request_id: LocalStorage.uid(),
        organization_name: "",
        // organization_logo_name: '',
        logo_id: "",
        logo_url: "",
        first_name: "",
        middle_name: "",
        time_zone_format: "",
        email_id: "",
        contact_number: "",
        ext: "",
        mobile_number: "",
        documentName: "",
        date_format: "",
        currency_symbol: "$ USD",
        logo_name: '',
        website_url: '',
        organization_fax_number: "",
        payable_to: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        zip_code: '',
        additional_information: "",
    });
    const [deletePopup, setdeletePopup] = useState(false);

    useEffect(() => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_view" && item.is_allowed == true))) ) {
            getList();
        }// eslint-disable-next-line
    }, []);

    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams(); 
    useEffect(() => {
        const token = searchParams.get("token")
        if (token) {
            let data = {
                request_id: LocalStorage.uid(),
                access_token: token,
                subdomain_name: domain
            }
            CommonApi.getLoginDetails(data).then(
                (response) => {
                    if (response.data.statusCode == 1003) {
                        LocalStorage.setUserData(response.data.data);
                        window.location.reload()
                    }
                }
            )
        }
        // eslint-disable-next-line
    }, [])

    const options = [
        { id: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
        { id: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
        { id: 'YYYY/MM/DD', value: 'YYYY/MM/DD' },
    ];
    const timezones = [
        { id: 'America/Los_Angeles', value: '(UTC-08:00) PST (Pacific Standard Time) - Los Angeles, America' },
        { id: 'America/Chicago', value: '(UTC-06:00) CST (Central Standard Time) - Chicago, America' },
        { id: 'America/New_York', value: '(UTC-05:00) EST (Eastern Standard Time) - New York, America' },
        { id: 'America/Denver', value: '(UTC-07:00) MST (Mountain Standard Time) - Denver, America' },
        { id: 'Asia/Kolkata', value: '(UTC+05:30) IST (India Standard Time) - Kolkata, Asia' }
    ];

    const getList = (update = false) => {
        setLoading(true)
        // setBtnLoading(true)
        OrganizationDetailsApi.getOrganizationDetails(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            setTimeout(() => {
                if (res.data.statusCode == 1003) {
                    setError({})
                    let updatedUserData = LocalStorage.getUserData();
                    props.setLogoUrl(res.data.data[0].logo_url);
                    updatedUserData.logo_url = res.data.data[0].logo_url;
                    updatedUserData.organization_id = res.data.data[0].id;
                    LocalStorage.setUserData(updatedUserData);
                    if (res.data.data.length > 0) {
                        if (update == "update") {
                            let updatedUserData = LocalStorage.getUserData();
                            updatedUserData.logo_url = res.data.data[0].logo_url;
                            updatedUserData.organization_id = res.data.data[0].id;
                            LocalStorage.setUserData(updatedUserData);
                        }
                        let data = res.data.data[0]
                        data.country_id = res.data.data[0].country_id;
                        data.state_id = res.data.data[0].state_id;
                        data.logo_id = "";
                        data.doc_url = res.data.data[0].logo_url
                        data.currency_symbol = '$ USD'
                        data.organization_name = Buffer.from(res.data.data[0].organization_name, 'base64').toString('utf-8')
                        setTimeout(() => {
                            setState({
                                ...data
                            });
                            setLoading(false);
                        }, 600)

                    } else {
                        setLoading(false);
                        setState({ ...state });
                    }
                } else {
                    setLoading(false);
                    addErrorMsg(res.data.message);
                }
            }, 300)

        });
    };

    const handleCancel = () => {
        setIsEditable(true)
        getList();
        setError({});
    };

    const handleSubmit = async() => {

        let errors = await validateAll();
        if (isValid(errors)) {
            state["request_id"] = LocalStorage.uid();
            if (!state.logo_id) {
                state["logo_id"] = "";
            }
            setState({ ...state });
            // state.logo_document_name = ''
            // console.log(state, "state")
            let data = state
            data.organization_name = Buffer.from(state.organization_name).toString('base64')
            updateAPI(data);
        } else {
            let s1 = { error };
            s1 = errors;
            setError(s1);
            addErrorMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.')

        }
    };
    const validateAll = async () => {
        let { organization_name, email_id, address_line_1, zip_code, city,
            time_zone_format,
            date_format,
            currency_symbol,
            logo_name,
            website_url,
            mobile_number,
            state_id,
            country_id,
            address_line_2,
        } =
            state;
        let errors = {};
        errors.organization_name = validate_alphaNumericWithSpecialChars(organization_name);
        errors.time_zone_format = validate_emptyField(time_zone_format);
        errors.address_line_1 = address_validation(address_line_1);
        errors.zip_code = await checkZipcode(zip_code)
        errors.city = validate_city(city);
        errors.email_id = validates_emailId(email_id);
        errors.logo_name = validate_emptyField(logo_name);
        errors.address_line_2 = validate_empty_address(address_line_2);
        errors.date_format = validate_emptyField(date_format);
        errors.mobile_number = validate_usContact(mobile_number, 'Mobile Number');
        errors.website_url = validate_url(website_url);
        errors.currency_symbol = validate_emptyField(currency_symbol);
        errors.state_id = validate_emptyField(state_id);
        errors.country_id = validate_emptyField(country_id);
        setError(errors)
        return errors;
    };

    const updateAPI = (data) => {
        setLoading(true)
        setBtnLoading(true)
        let id = data.id
        data.currency_symbol = '$'
        OrganizationDetailsApi.updateOrganizationDetails(data, id, LocalStorage.getAccessToken()).then((res) => {
            setTimeout(() => {
                if (res.data.statusCode == 1003) {
                    setLoading(false)
                    setBtnLoading(false)
                    LocalStorage.setCurrencySymbol(state.currency_symbol);
                    LocalStorage.setDateFormat(state.date_format);
                    let data = LocalStorage.getUserData()
                    data.logo_url = state.doc_url;
                    data.org_config_set = true
                    data.plan_not_set = false
                    LocalStorage.setUserData(data)
                    // addSuccessMsg("Data Added Successfully");
                    addSuccessMsg("Organization Details updated Successfully");// 
                    setIsEditable(true)
                    getList();
                } else {
                    setLoading(false)
                    setBtnLoading(false)
                    addErrorMsg(res.data.message);
                }
            }, 300);
        });
    };

    const uploadDocs = (value) => {
        const logoData = new FormData();
        logoData.append("files", value.target.files[0]);
        logoData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        addLoader(true)
        CommonApi.documentUpload("organization-logo", logoData, LocalStorage.getAccessToken()).then((response) => {
            setTimeout(() => {
                if (response.data.statusCode == 1003) {
                    state[value.target.name] = response.data.data.id;
                    state["logo_name"] = value.target.files[0].name.length > 30 ? value.target.files[0].name.slice(0, 30) + "..." : value.target.files[0].name;
                    state["logo_url"] = URL.createObjectURL(value.target.files[0])
                    state["logo_id"] = response.data.data.id;
                    state["doc_url"] = response.data.data.document_url;
                    removeLoader()
                    setState({ ...state });
                    handleValidateChangeHandler({ name: "logo_name", value: response.data.data.id });
                } else {
                    removeLoader();
                    addErrorMsg(response.data.message);
                }
            }, 300)

        });
    };

    const fetchZipcodeData = useZipcodeAPICall({ setState, setError });
    const changeHandler = (e) => {
        if (e.target.name == 'zip_code') {
            fetchZipcodeData(e.target.value)
        }
        if (e.target.name === 'country_id') {
            if (e.target.value == '' || e.target.value == null) {
                let err = error;
                err.state_id = ''
                setError({ ...err })
            }
            else {
                state.state_id = ''
                error.state_id = ''
            }
        }
        if (
            e.target.name == "contact_number" ||
            e.target.name == "mobile_number" ||
            e.target.name == "ext"
        ) {
            convertFormat(e);
        } else {
            setState(
                {
                    ...state,
                    [e.target.name]: e.target.value,
                },
                handleValidateChangeHandler(e.target)
            );
        }
    };

    const convertFormat = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const input = value.replace(/\D/g, "").substring(0, 10);

        // Divide numbers in 3 parts :"(123) 456-7890"
        const first =
            name == "contact_number" || name == "mobile_number"
                ? input.substring(0, 3)
                : input.substring(0, 3);
        const middle =
            name == "contact_number" || name == "mobile_number"
                ? input.substring(3, 6)
                : input.substring(3, 5);
        const last =
            name == "contact_number" || name == "mobile_number"
                ? input.substring(6, 10)
                : input.substring(5, 9);

        if (
            input.length >
            (name == "contact_number" || name == "mobile_number" ? 6 : 5)
        ) {
            setState(
                {
                    ...state,
                    [e.target.name]: `${first}-${middle}-${last}`,
                },
                handleValidateChangeHandler(e.target)
            );
        } else if (input.length > 3) {
            setState(
                {
                    ...state,
                    [e.target.name]: `${first}-${middle}`,
                },
                handleValidateChangeHandler(e.target)
            );
        } else if (input.length >= 0) {
            setState(
                {
                    ...state,
                    [e.target.name]: input,
                },
                handleValidateChangeHandler(e.target)
            );
        }
    };

    const handleEditClick = (args) => {
        setIsEditable(false)

    };

    const handleValidateChangeHandler = (input) => {
        // let input = e.target;
        let err = error;
        switch (input.name || input.tagName) {
            case "organization_name":
                err.organization_name = validate_alphaNumericWithSpecialChars(input.value);
                break;
            case "time_zone_format":
                err.time_zone_format = validate_emptyField(input.value);
                break;
            case "mobile_number":
                err.mobile_number = validate_usContact(input.value, 'Mobile Number');
                break;
            case "email_id":
                err.email_id = validates_emailId(input.value);
                break;
            case "date_format":
                err.date_format = validate_emptyField(input.value);
                break;
            case "currency_symbol":
                err.currency_symbol = validate_emptyField(input.value);
                break;
            case "address_line_1":
                err.address_line_1 = address_validation(input.value);
                break;
            case "address_line_2":
                err.address_line_2 = validate_empty_address(input.value);
                break;
            case "logo_name":
                err.logo_name = validate_emptyField(input.value)
                break
            case "zip_code":
                err.zip_code = validate_zipcode(input.value, state.country_id)
                break

            case "website_url":
                err.website_url = validate_url(input.value)
                break
            case "city":
                err.city = validate_city(input.value)
                break
            case "payable_to":
                err.payable_to = validate_emptyField(input.value)
                break
            case "state_id":
                err.state_id = validate_emptyField(input.value)
                break
            case "country_id":
                err.country_id = validate_emptyField(input.value)
                break
            case "organization_fax_number":
                err.organization_fax_number = validate_emptyField(input.value)
                break

            case "additional_information":
                err.additional_information = validate_emptyField(input.value)
                break
            default:
                break;
        }
        setError({ ...err });
    };

    return (
        <div>
            {
            
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_view" && item.is_allowed == true))  ) ?
                    <Box sx={{
                        height: '70vh',
                        overflow: 'auto',
                        padding: '16px',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}>
                        {loading ?
                            <Box sx={{
                                height: '70vh',
                                overflow: 'auto',
                                padding: '16px',
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                            }}>
                                <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
                                    <img src={blueLoader} height={100} width={100} alt='loading' />
                                </Stack>
                            </Box>
                            :

                            <Box className={classes.activeItemBox} >
                                <Box className={classes.activeBoxHeading}>
                                    <Box display='flex' justifyContent='space-between'>
                                        <Text mediumBoldBlack >{props.current}</Text>
                                        {isEditable &&
                                            (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_edit" && item.is_allowed == true))) ?
                                            <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={handleEditClick} /> :
                                            ''
                                        }
                                    </Box>
                                    <Box sx={{ minHeight: "30vh", overflow: 'auto' }}>
                                        <Grid container spacing={2} columnSpacing={4} pt={4}>
                                            <Grid item lg={6} md={6} sm={12} xs={12} >
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: "true",
                                                    }}
                                                    inputProps={{
                                                        name: "organization_name",
                                                        value: state.organization_name,
                                                        inputProps: { maxLength: 60 }
                                                    }}
                                                    disabled={isEditable}
                                                    labelText={`Organization Name`}
                                                    required
                                                    clientInput
                                                    handleChange={(e) => changeHandler(e)}
                                                    error={error.organization_name}
                                                />
                                                {error.organization_name ? <Text red>{error.organization_name}</Text> : ""}
                                            </Grid>

                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <Select
                                                    required
                                                    name='date_format'
                                                    value={state.date_format}
                                                    onChange={changeHandler}
                                                    options={options}
                                                    disabled={isEditable}
                                                    label={'Date Format'}
                                                    helperText={error.date_format && <Text red>{error.date_format ? error.date_format : ''}</Text>}
                                                />
                                                {/* <Box pt={1}> */}
                                                {/* <CustomSelect
                                                    name="date_format"
                                                    value={state.date_format}
                                                    onChange={changeHandler}
                                                    commonSelect
                                                    label={'Date Format'}
                                                    options={options}
                                                    disabled={isEditable}
                                                /> */}
                                                {/* </Box> */}
                                                {/* {error.date_format ? <Text red>{error.date_format}</Text> : ""} */}

                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Box pt={1}>
                                                    <Select
                                                        name='time_zone_format'
                                                        value={state.time_zone_format}
                                                        onChange={changeHandler}
                                                        options={timezones}
                                                        disabled={isEditable}
                                                        label={'Time Zone'}
                                                        required
                                                        helperText={error.time_zone_format && <Text red>{error.time_zone_format ? error.time_zone_format : ''}</Text>}
                                                    />
                                                    {/* <CustomSelect
                                                        name="time_zone_format"
                                                        value={state.time_zone_format}
                                                        onChange={changeHandler}
                                                        commonSelect
                                                        label={'Time Zone'}
                                                        options={timezones}
                                                        disabled={isEditable}
                                                    /> */}
                                                </Box>
                                                {/* {error.currency_symbol ? <Text red>{error.currency_symbol}</Text> : ""} */}
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: "true",
                                                    }}
                                                    inputProps={{
                                                        name: "currency_symbol",
                                                        value: state.currency_symbol,
                                                        inputProps: { maxLength: 33 }
                                                    }}
                                                    disabled={true}
                                                    labelText={`Currency`}
                                                    clientInput
                                                    handleChange={(e) => changeHandler(e)}
                                                    error={error.currency_symbol}
                                                />
                                                {/* <Select
                                                    name='currency_symbol'
                                                    value={state.currency_symbol}
                                                    onChange={changeHandler}
                                                    options={currency}
                                                    disabled={true}
                                                    label={'Currency'}
                                                    helperText={error.currency_symbol && <Text red>{error.currency_symbol ? error.currency_symbol : ''}</Text>}
                                                /> */}
                                                {/* <Box pt={1}>
                                                    <CustomSelect
                                                        name="currency_symbol"
                                                        value={state.currency_symbol}
                                                        onChange={changeHandler}
                                                        commonSelect
                                                        label={'Currency'}
                                                        options={currency}
                                                        disabled={isEditable}
                                                    />
                                                </Box>
                                                {error.currency_symbol ? <Text red>{error.currency_symbol}</Text> : ""} */}
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Box sx={{ height: '56px !important' }}>
                                                    <FileInput
                                                        required
                                                        name={"logo_name"}
                                                        FileName={state.logo_name}
                                                        handleChange={uploadDocs}
                                                        label='Organization Logo'
                                                        uploadKeyName={'Upload'}
                                                        isDisabled={isEditable}
                                                        actionState={state.logo_name != '' ? 1 : ''}
                                                        documentUrl={state.doc_url}
                                                        handleDelete={() => {
                                                            setdeletePopup(true)
                                                        }}
                                                        error={error.logo_name}
                                                    />
                                                </Box>
                                                {error.logo_name ? <Text red>{error.logo_name}</Text> : ""}

                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12} >
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: "true",
                                                    }}
                                                    inputProps={{
                                                        name: "email_id",
                                                        value: state.email_id,
                                                    }}
                                                    disabled={true}
                                                    required
                                                    labelText={'Organization Email ID'}
                                                    clientInput
                                                    handleChange={(e) => changeHandler(e)}
                                                    error={error.email_id}
                                                />
                                                {error.email_id ? <Text red>{error.email_id}</Text> : ""}
                                            </Grid>

                                            <Grid item lg={6} md={6} sm={12} xs={12} >
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: "true",
                                                    }}
                                                    inputProps={{
                                                        name: "website_url",
                                                        value: state.website_url,
                                                    }}
                                                    disabled={isEditable}
                                                    labelText={'Website URL'}
                                                    clientInput
                                                    required
                                                    handleChange={(e) => changeHandler(e)}
                                                    error={error.website_url}
                                                />
                                                {error.website_url ? <Text red>{error.website_url}</Text> : ""}

                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12} >
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: "true",
                                                    }}
                                                    inputProps={{
                                                        name: "mobile_number",
                                                        value: state.mobile_number,
                                                        inputProps: { maxLength: 12 },
                                                    }}
                                                    disabled={isEditable}
                                                    labelText={'Mobile Number'}
                                                    clientInput
                                                    handleChange={(e) => changeHandler(e)}
                                                    error={error.mobile_number}
                                                    required
                                                />
                                                {error.mobile_number ? <Text red>{error.mobile_number}</Text> : ""}

                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pt={4} pb={4}>
                                                <Text largeBlue>Address</Text>
                                            </Box>
                                        </Grid>
                                        <Grid container spacing={2} columnSpacing={4}>
                                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: "true",
                                                    }}
                                                    inputProps={{
                                                        name: "address_line_1",
                                                        value: state.address_line_1,
                                                        inputProps: { maxLength: 225 }
                                                    }}
                                                    disabled={isEditable}
                                                    labelText={'Address Line 1'}
                                                    clientInput
                                                    handleChange={(e) => changeHandler(e)}
                                                    error={error.address_line_1}
                                                    required
                                                />
                                                {error.address_line_1 ? <Text red>{error.address_line_1}</Text> : ""}

                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: "true",
                                                    }}
                                                    inputProps={{
                                                        name: "address_line_2",
                                                        value: state.address_line_2,
                                                        inputProps: { maxLength: 225 }
                                                    }}
                                                    disabled={isEditable}
                                                    labelText={'Address Line 2 (Optional)'}
                                                    clientInput
                                                    handleChange={(e) => changeHandler(e)}
                                                    error={error.address_line_2}
                                                />
                                                {error.address_line_2 ? <Text red>{error.address_line_2}</Text> : ""}

                                            </Grid>
                                            <Zipcode state={state} error={error} handleChange={changeHandler} columnLength={6} isEditable={isEditable} />
                                        </Grid>
                                        <Grid container spacing={2} columnSpacing={4}>
                                            <Grid item lg={12} md={12} sm={12} xs={12} display={'flex'} justifyContent={'space-between'} gap={'20px'} mt={'40px'}>
                                                {!isEditable && (
                                                    <>
                                                        <Button cancelBtnBorder sx={{ height: "48px !important", width: "120px !important" }} onClick={handleCancel}>
                                                            Cancel
                                                        </Button>

                                                        <LoadingButton sx={{ height: "48px !important", width: "120px !important" }} loading={btnloading} saveBtn onClick={handleSubmit}>
                                                            {btnloading ? 'Saving' : 'Save'}
                                                        </LoadingButton>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Box>

                                </Box>
                            </Box >
                        }
                    </Box >
                    :
                    <>
                        {NoPermission()}
                    </>
            }
            <ReusablePopup openPopup={deletePopup} setOpenPopup={setdeletePopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you really wish to delete.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setdeletePopup(false) }}>
                                No
                            </Button>
                            <Button finishFilledQuicksand onClick={() => {
                                setState({
                                    ...state,
                                    logo_name: '',
                                    doc_url: ''
                                });
                                setdeletePopup(false)
                            }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </div>
    )
}

export default OrganizationDetails;
