import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
// eslint-disable-next-line
import EmployeesApi from '../../../../../apis/admin/employees/EmployeesApi'; // eslint-disable-next-line
import { BlackToolTip, addErrorMsg, addLoader, addSuccessMsg, dateFormat, openDocumentInNewtab, removeLoader } from '../../../../../utils/utils'; // eslint-disable-next-line
import Button from '../../../../../components/customButton/Button';
import LocalStorage from '../../../../../utils/LocalStorage';
import Text from '../../../../../components/customText/Text';
import EditIcon from '../../../../../assets/svg/newEdit.svg'
import FileSvg from '../../../../../assets/svg/File.svg'
import noDataFound from '../../../../../assets/svg/NoDataFoundIcon.svg'
import FileInput from '../../../../../components/muiFileInput/FileInput';
import Input from '../../../../../components/input/Input';
import Select from '../../../../../components/select/Select'
import Date from '../../../../../components/datePicker/Date';
import moment from 'moment';
import CommonApi from '../../../../../apis/CommonApi';
import { isValid, validate_emptyField, validates_new_i94 } from '../../../../../components/Validation';
import LoadingScreen from './LoadingScreen';
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import Sure from '../../../../../assets/svg/placementPop.svg'
import Userplus from '../../../../../assets/svg/plus.svg';
import LoadingButton from '../../../../../components/customButton/LoadingButton';

export default function I94View(props) {
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [list, setList] = useState({
        documents: []
    });  // eslint-disable-next-line
    const [current, setCurrent] = useState("education");
    const [defaultOpen, setdefaultOpen] = useState(false)
    const [employee, setEmployee] = useState(props.employee)
    const [formOpen, setformOpen] = useState(false)
    const [clearPopup3, setclearPopup3] = useState(false);
    const [error, setError] = useState({
        country_id: "",
        expiry_type: "",
        valid_from: "",
        valid_till: "",
        document_number: "",
        documents: [
            {
                id: "",
                new_document_id: "",
                name: ""
            }
        ]
    });
    const [deletePopup, setdeletePopup] = useState(false);
    const [clearPopup, setclearPopup] = useState(false);// eslint-disable-next-line
    const [buttonLoader, setButtonLoader] = useState(false);
    const [state, setState] = useState(
        {
            country_id: "",
            valid_from: "",
            valid_till: "",
            document_number: "",
            expiry_type: "",
            documents: [
                {
                    id: "",
                    new_document_id: "",
                    name: ""
                }
            ],
            documents_deleted_ids: [],
            clear_all: false
        }
    );

    const expiryTypeList = [
        {
            id: 2,
            value: "Date"
        },
        {
            id: 1,
            value: "D/S"
        }
    ]
    useEffect(() => {
        setEmployee(props.employee)
        setCurrent(props.documentPosition) // eslint-disable-next-line
    }, [props])

    useEffect(() => {
        if (props.documentPosition === 'work' && props.formEdit == false) {
            getI94();
        }
        // eslint-disable-next-line
    }, [props.documentPosition])

    const getI94 = () => {
        setLoading(true)
        setError({
            ...error,
            document_number: ''
        })
        setdefaultOpen(false)
        EmployeesApi.getI94(props.employee.id).then((response) => {
            setTimeout(() => {
                setLoading(false)
                if (response.data.statusCode == 1003) {
                    if (response.data.data.length > 0) {
                        setList(response.data.data);
                        if (response.data.data[0].documents.length === 0) {
                            let data = response.data.data[0]
                            data.documents = [
                                {
                                    id: "",
                                    new_document_id: "",
                                    name: ""
                                }
                            ]
                            setState({ ...data })
                        }
                        else {
                            setState(response.data.data[0])
                        }

                    } else {
                        setState({
                            ...state,
                            id: '',
                            expiry_type: '',
                            valid_from: "",
                            documents_deleted_ids: []
                        })
                        setList({
                            country_id: "",
                            valid_from: "",
                            valid_till: "",
                            expiry_type: '',
                            document_number: "",
                            documents: [
                                {
                                    id: "",
                                    new_document_id: "",
                                    name: ""
                                }
                            ],
                            documents_deleted_ids: []
                        });
                    }
                } else {
                    addErrorMsg(response.data.message);
                    setLoading(false)
                }
            }, 400)
        });
    }

    const openInNewTab = (args, documentUrl) => {
        if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else if (args.aws_s3_status == undefined || args.aws_s3_status == undefined) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            openDocumentInNewtab(args.document_key, args.document_path)
        }
    }

    const uploadDocs = (value) => {
        addLoader()
        const formData = new FormData();
        formData.append("files", value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        let size = ((value.target.files[0].size / (1024 * 1024)).toFixed(2))
        if (Number(size) <= 25) {
            CommonApi
                .documentUpload("i94-document", formData, LocalStorage.getAccessToken())
                .then((response) => {
                    if (response.data.statusCode == 1003) {
                        let docInfo = response.data.data
                        const data = {
                            id: response.data.data.id,
                            url: response.data.data.document_url
                        }
                        state.documents[0].new_document_id = response.data.data.id
                        state.documents[0].document_url = response.data.data.document_url
                        state.documents[0].name = value.target.files[0].name
                        state.clear_all = false
                        setState({ ...state })
                        handleDocumentValidations({ name: value.target.name, value: docInfo.id });
                        CommonApi.ocrDocumentUpload('i94-data', data).then((res) => {
                            if (res.data.statusCode === 1003) {
                                removeLoader()
                                const admitUntilDate = res?.data?.data?.admit_until_date.toLowerCase();
                                state.document_number = res.data.data.i94_record_number ? res.data.data.i94_record_number : res.data.data.i_94_record_number ? res.data.data.i94_record_number : state.document_number
                                state.valid_from = moment(res?.data?.data?.recent_date_of_entry, dateFormat()).format(dateFormat())
                                state.valid_till = res.data.data.admit_until_date ? admitUntilDate != 'd/s' ? moment(res.data.data.admit_until_date, dateFormat()).format(dateFormat()) : state.valid_till : state.valid_till
                                state.expiry_type = res.data.data.admit_until_date ? admitUntilDate == 'd/s' ? 1 : 2 : state.expiry_type
                                state.documents[0].new_document_id = response.data.data.id
                                state.documents[0].document_url = response.data.data.document_url
                                state.documents[0].name = value.target.files[0].name
                                state.clear_all = false
                                setState({ ...state })
                                handleDocumentValidations({ name: value.target.name, value: docInfo.id });
                            } else {

                                removeLoader()
                                state.documents[0].new_document_id = response.data.data.id
                                state.documents[0].document_url = response.data.data.document_url
                                state.documents[0].name = value.target.files[0].name
                                state.clear_all = false
                                setState({ ...state })
                                handleDocumentValidations({ name: value.target.name, value: docInfo.id });
                            }
                        })
                    } else {

                        addErrorMsg(response.data.message);
                        removeLoader()
                    }
                });
        }
        else {
            removeLoader()
            addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
        }
    };

    const handleChange = (e) => {
        state.clear_all = false
        state.valid_till = ''
        if (e.target.name == 'document_number') {
            setState({
                ...state, [e.target.name]: e.target.value
            })
            checkI94(e.target.value, 'document_number')
        }
        else {
            setState({
                ...state, [e.target.name]: e.target.value
            })
        }
        setdefaultOpen(false)
        if (e.target.name == 'expiry_type') {
            if (e.target.value == 2) {
                setdefaultOpen(true)
            }
        }
        handleValidations(e.target);
    };

    const checkI94 = (val, args) => {
        const delayDebounceFn = setTimeout(() => {
            var passportObj = {
                request_id: LocalStorage.uid(),
                document_number: val,
                id: employee?.id,
            }
            EmployeesApi.checkDuplicateNumber('i94', args == 'document_number' ? passportObj : '').then((res) => {
                if (res.data.statusCode === 1003) {
                    if (res.data.valid) {

                    } else {
                        if (val == 'document_number') {
                            setError({
                                ...error,
                                document_number: "Passport Number is already exists"
                            })

                        }
                    }
                }
                else {
                    setError({
                        ...error,
                        document_number: res.data.message
                    })
                }
            });
        }, 300)
        return () => clearTimeout(delayDebounceFn)
    }

    const handleChangeDate = (e, name) => {
        state.clear_all = false
        let data = { name: name, value: e == null ? '' : moment(e.$d).format(dateFormat()) }
        if (data.name == 'valid_from') {
            state.valid_till = ''
        }
        if (data.name == 'valid_till') {
            setdefaultOpen(false)
        }
        setState({ ...state, [data.name]: data.value })
        handleValidations(data);
    };

    const handleValidations = (input) => {
        // let input = e.target;
        let err = error;
        switch (input.name || input.tagName) {
            case "document_number":
                err.document_number = validates_new_i94(input.value);
                break;
            case "valid_till":
                err.valid_till = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError(err);
    };

    const handleDocumentValidations = (input) => {
        let err = error;
        switch (input.name) {
            case "documents":
                err.documents = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError(err);
    }

    const deleteDoc = () => {
        state.documents[0].new_document_id = ''
        state.documents[0].document_url = ''
        state.documents[0].name = ''
        state.clear_all = false
        if (state.documents[0].id != '') {
            state.documents_deleted_ids = [state.documents[0].id]
            state.documents[0].id = ''
        }
        setState({ ...state })
        setdeletePopup(false)
    }

    const clearAll = () => {
        if (state.documents[0].id != '') {
            state.documents_deleted_ids = [state.documents[0].id]
            state.documents[0].id = ''
        }
        setState({
            ...state,
            country_id: "",
            valid_from: "",
            valid_till: "",
            document_number: "",
            documents: [
                {
                    id: "",
                    new_document_id: "",
                    name: ""
                }
            ],
            clear_all: true,
            expiry_type: '',
        })
        setError({
            ...error,
            document_number: '',
            valid_till: ''
        })
        setclearPopup(false)
    }

    const submitForm = () => {
        if (error.document_number !== '') {
            return true
        }
        let data = {
            document_id: state.documents[0].new_document_id,
            document_number: state.document_number,
            issue: state.country_id,
            date_issue: state.valid_from,
            date_end: state.valid_till,
            expiry_type: state.expiry_type
        }
        if (state.id !== '') {
            state['request_id'] = LocalStorage.uid();
            state['employee_id'] = props.employee.id;
            if (isValid(data)) {
                state['clear_all'] = true;
            }
            if (state.expiry_type == 2) {
                if (state.valid_till == '') {
                    error.valid_till = 'This field is required';
                    addErrorMsg("Admit until date is required");
                    setError({ ...error });
                    return true;
                }
            }
            if (state.clear_all == false && state.document_number === '') {
                error.document_number = 'This field is required'
                setError({ ...error })
            }
            else {
                setButtonLoader(true);
                EmployeesApi.updateI94(state, state.id, LocalStorage.getAccessToken()).then((res) => {
                    setButtonLoader(false);
                    if (res.data.statusCode === 1003) {
                        addSuccessMsg(`Changes Saved`);
                        setformOpen(false);
                        getI94()
                        props.setformEdit(false)
                    } else {
                        addErrorMsg(res.data.message);
                    }
                })
            }
        }
        else {
            if (isValid(data)) {
                setformOpen(false);
                return true
            }
            else {
                if (state.expiry_type == 2) {
                    if (state.valid_till === '') {
                        if (state.document_number === '') {
                            error.document_number = 'This field is required'
                            setError({ ...error })
                        }
                        error.valid_till = 'This field is required';
                        addErrorMsg("Admit until date is required");
                        setError({ ...error });
                        return true;
                    }
                }
                if (state.document_number === '') {
                    error.document_number = 'This field is required'
                    setError({ ...error })
                }
                else {
                    state['request_id'] = LocalStorage.uid()
                    state['employee_id'] = props.employee.id
                    EmployeesApi.storeI94(state, LocalStorage.getAccessToken()).then((res) => {
                        if (res.data.statusCode === 1003) {
                            addSuccessMsg(`I94 Added`);
                            setformOpen(false);
                            getI94();
                            props.setformEdit(false)
                        } else {
                            addErrorMsg(res.data.message);
                        }
                    })
                }
            }
        }
    }
    return (
        <Grid container>
            {
                loading ?
                    <Grid container>
                        <LoadingScreen />
                    </Grid>
                    :
                    <Grid container>
                        <Grid item lg={11} md={11} sm={11} xs={11}>
                            <Box p={1} pl={2} pt={2}>
                                <Text largeBlue>
                                    I-94
                                </Text>
                            </Box>
                        </Grid>
                        {
                            formOpen ? '' :
                                employee && employee.is_off_boarding_initiated === false && list.length > 0 ?
                                    <Grid item lg={1} md={1} sm={1} xs={1}>
                                        <Box p={1} pr={0}>
                                            {(LocalStorage.getUserData().super_admin || (props.rolePermission !== "" && props.rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) &&
                                                <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => {
                                                    if (employee.pending_requests.i94) {
                                                        setclearPopup3(true)
                                                    }
                                                    else { setformOpen(true); props.setformEdit(true) }
                                                }} />
                                            }
                                        </Box>
                                    </Grid>
                                    : ''
                        }
                        {
                            formOpen ? '' :
                                list.length > 0 ?
                                    <Grid item container lg={12}>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    I-94 Number
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {list[0].document_number ? list[0].document_number : '-'}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Recent Date of Entry
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {list[0].valid_from ? list[0].valid_from : '-'}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={6}></Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Admit Until Date
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {list[0].expiry_type_name ? list[0].expiry_type_name : '-'}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Admit Until Date
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {list[0].valid_till ? list[0].valid_till : '-'}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    I-94 Document
                                                </Text>
                                                {list[0].documents.length > 0 && list[0].documents[0].name !== '' ?
                                                    <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(list[0].documents[0], list[0].documents[0].document_url) }}>
                                                        <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />
                                                        <Text mediumBlue>
                                                            <BlackToolTip arrow placement='top' title={list[0].documents[0].name}>
                                                                {list[0].documents[0].name.slice(0, 16)} {list[0].documents[0].name.length > 16 ? '...' : ''}
                                                            </BlackToolTip>
                                                        </Text>
                                                    </Stack>
                                                    : '-'
                                                }

                                            </Box>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                            <img src={noDataFound} alt='nodata' />
                                        </Grid>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                            <Text largeBlack>No Documents Uploaded</Text>
                                        </Grid>
                                        {employee.is_off_boarding_initiated === false && (LocalStorage.getUserData().super_admin || (props.rolePermission !== "" && props.rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) &&
                                            <>
                                                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                    <Text mediumLabel sx={{ fontSize: '14px !important' }}>Upload I-94 document for this employee by </Text>
                                                </Grid>
                                                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={1}>
                                                    <Text mediumLabel sx={{ fontSize: '14px !important' }}>clicking on the below button.</Text>
                                                </Grid>
                                                {
                                                    employee.is_off_boarding_initiated === false &&
                                                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={3}>
                                                        <Button finishFilledQuicksandLargeWidth onClick={() => {
                                                            setdefaultOpen(false)
                                                            if (employee.pending_requests.i94) {
                                                                setclearPopup3(true)
                                                            }
                                                            else { setformOpen(true); props.setformEdit(true) }
                                                        }}> <img src={Userplus} alt='plus' /> Add I94 Details</Button>
                                                    </Grid>
                                                }
                                            </>
                                        }
                                    </Grid>
                        }
                        {formOpen ?
                            <Grid container p={2} pl={0}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box p={1} pt={2} pl={2}>
                                        <FileInput
                                            name={"I-94"}
                                            FileName={state.documents[0].name}
                                            handleChange={uploadDocs}
                                            label={"I-94 document"}
                                            documentUrl={state.documents[0].document_url}
                                            handleDelete={() => { setdeletePopup(true) }}
                                            actionState={state.documents[0].name ? 1 : ''}
                                            state={state.documents[0]}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={2}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'document_number',
                                                value: state.document_number,
                                                type: 'text',
                                                inputProps: { minLength: 5, maxLength: 11 }
                                            }}
                                            handleChange={handleChange}
                                            clientInput
                                            labelText={'I-94 Number'}
                                            error={error.document_number}
                                        />
                                        <Text red>{error.document_number}</Text>
                                    </Box>
                                </Grid>
                                {/* <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={2}>
                                        <Select
                                            options={countriesList}
                                            name='country_id'
                                            value={state.country_id}
                                            onChange={handleChange}
                                            label={'Issued Country'}
                                        />
                                    </Box>
                                </Grid> */}
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={2}>
                                        <Date
                                            labelText={"Recent Date of Entry"}
                                            name={"valid_from"}
                                            maxDate={moment().format(dateFormat())}
                                            value={state.valid_from}
                                            onChange={(e) => handleChangeDate(e, "valid_from")}
                                            error={error.valid_from}
                                        />
                                        <Text red>{error.valid_from}</Text>
                                    </Box>
                                </Grid>

                                <Grid item lg={4}></Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={2}>
                                        <Select
                                            options={expiryTypeList}
                                            name='expiry_type'
                                            value={state.expiry_type}
                                            onChange={handleChange}
                                            label={'Admit Until Date'}
                                            disabled={state.valid_from == ''}
                                            error={error.expiry_type}
                                        />
                                        <Text red>{error.expiry_type}</Text>
                                    </Box>
                                </Grid>
                                {state.expiry_type == 2 && (
                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                        <Box p={1} pt={2} pl={2}>
                                            <Date
                                                labelText={"Admit Until Date"}
                                                name={"valid_till"}
                                                value={state.valid_till}
                                                defaultOpen={defaultOpen}
                                                // disabled={state.valid_from === ''}
                                                minDate={state.valid_from}
                                                onChange={(e) => handleChangeDate(e, "valid_till")}
                                                error={error.valid_till}
                                            />
                                            <Text red>{error.valid_till}</Text>
                                        </Box>
                                    </Grid>
                                )}
                                <Grid item container lg={12} md={12} sm={12} xs={12} p={2} pl={2}>
                                    <Grid item container lg={6} md={6} sm={6} xs={12} pt={3}>
                                        <Button cancelOutlineQuicksand onClick={() => { setformOpen(false); getI94(); props.setformEdit(false) }}>Cancel</Button>
                                    </Grid>
                                    <Grid item container lg={6} md={6} sm={6} xs={12} pt={3} justifyContent={'flex-end'}>
                                        <Stack direction={'row'} >
                                            <Button clearAllRed onClick={() => { setclearPopup(true) }}>Clear all</Button>
                                            <LoadingButton loading={buttonLoader} smallBlue onClick={() => { submitForm() }}>Save</LoadingButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : ''
                        }
                        <Grid container>
                            <Box height={'5vh'} />
                        </Grid>
                    </Grid>
            }
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to clear all the information</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { clearAll() }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={deletePopup} setOpenPopup={setdeletePopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you really wish to delete.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setdeletePopup(false) }}>
                                No
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { deleteDoc() }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup3} setOpenPopup={setclearPopup3} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Editing Denied!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>There is an open request to change the details.</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Please take actions before editing the fields.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button finishFilledQuicksand onClick={() => { setclearPopup3(false) }} >
                                Okay
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Grid>

    );
}