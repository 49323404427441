import { Avatar, AvatarGroup, Box, Button as MuiButton, Divider, FormControl, Grid, IconButton, InputAdornment, List, ListItem, ListItemIcon, TextField, Typography, styled, Skeleton, Stack, ButtonGroup, Menu, MenuItem } from '@mui/material'
import { FiberManualRecord } from "@mui/icons-material"
import React, { useState } from 'react';
import { useEffect } from 'react';
import ArrowLeft from '../../../assets/svg/fi_arrow-left.svg'
import Text from '../../../components/customText/Text'
import MyProfileStyles from '../myProfile/MyProfileStyles'
import Input from '../../../components/input/Input'
import visible from "../../../assets/svg/eye.svg"
import invisible from "../../../assets/svg/NotVisible.svg"
import upload from "../../../assets/svg/fi_upload.svg"
import { empty_name, empty_usContact, isValid, onCharactersOnlyChange, validate_alphaNumericWithSpecialChars, validate_charWithSpace, validate_emptyField, validate_usContact, validates_emailId, validates_password } from '../../../components/Validation'
import { addErrorMsg, addLoader, addLoaderPlanExpired, addSuccessMsg, capitalize, capitalizeAndAddSpace, dateFormat, NoPermission, removeExpiredLoader, removeLoader } from '../../../utils/utils'
import UserProfileApi from '../../../apis/admin/userprofile/UserProfileApi'
import LocalStorage from '../../../utils/LocalStorage'
import Button from '../../../components/customButton/Button'
import { useLocation, useNavigate } from 'react-router-dom';
import CommonApi from '../../../apis/CommonApi';
import EditIcon from '../../../assets/svg/newEdit.svg'
import genderOptions from '../../../utils/jsons/Gender.json'
import Select from '../../../components/select/Select';
import RightIcon from "../../../assets/svg/right-icon.svg"
import CheckIcon from '@mui/icons-material/Check';
import FileInput from '../../../components/muiFileInput/FileInput';
import OrganizationDetailsApi from '../../../apis/configurations/organization/OrganizationDetailsApi';
import ReusablePopup from '../../../components/reuablePopup/ReusablePopup';
import Sure from '../../../assets/svg/ExpenseDeleteIcon.svg'
import { domain } from '../../../config/Domain';
import CustomSelect from '../../../components/customSelect/CustomSelect';
import moment from 'moment/moment';
import purchaseEmpty from "../../../assets/svg/purchaseEmpty.svg"
import AddIcon from '@mui/icons-material/Add';
import InstalledAppsApi from '../../../apis/InstalledAppsApi';
import ownerShip from "../../../assets/svg/ownershipTransfer.svg"
import profile from "../../../assets/svg/profile.svg"
import SureORG from '../../../assets/svg/placementPop.svg'; // eslint-disable-next-line
import sideArrow from '../../../assets/employee/white-side-arrow.svg';
import DashboardAPI from '../../../apis/admin/DashboardAPI';
import LoadingButton from '../../../components/customButton/LoadingButton';
import { BlackToolTip } from '../../../utils/utils';
import InfoIcon from '../../../assets/svg/info_orange.svg';
import InfoIconRed from '../../../assets/svg/info_red.svg';
import ToggleSwitch from '../../../components/toggle/CustomToggle';
import LoaderButton from '../../../components/customButton/LoadingButton';
import CardDetails from '../../../assets/svg/cardDetails.svg';
import visa from '../../../assets/svg/cards/visa.png';
import master from '../../../assets/svg/cards/MasterCard.png';
import maestro from '../../../assets/svg/cards/maestro-card.png';
import paypall from '../../../assets/svg/cards/paypal.png';
import amex from '../../../assets/svg/cards/american-express.png';
import menuIcon from '../../../assets/svg/cards/billing-menu.svg';
import { Buffer } from 'buffer';

const MyDetails = ({ state, setState, classes, setAvatarData, getUserProfileDetails, setStatus }) => {
    const [action, setAction] = useState("read");// eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [transferPopup, setTransferpopup] = useState(false)
    const [error, setError] = useState({});

    const handleChange = (e) => {
        if (e.target.name == 'email_id' && LocalStorage.getUserData().super_admin) {
            setTransferpopup(true);
            return true
        }
        if (e.target.name === "contact_number" || e.target.name === "alternate_contact_number") {
            let number = contactNumberConvert(e);
            setState({ ...state, [e.target.name]: number })
        }
        else if (e.target.name == 'first_name' || e.target.name == 'last_name' || e.target.name == 'middle_name') {
            setState({
                ...state,
                [e.target.name]: capitalize(e.target.value)
            }, handleValidations(e))
        }
        else {
            setState({ ...state, [e.target.name]: e.target.value })
        } handleValidations(e);
    }
    const contactNumberConvert = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const input = value.replace(/\D/g, "").substring(0, 10);

        // Divide numbers in 3 parts :"(123) 456-7890"
        const first =
            name == "contact_number" || name == "mobile_number" || name == "alternate_contact_number"
                ? input.substring(0, 3)
                : input.substring(0, 3);
        const middle =
            name == "contact_number" || name == "mobile_number" || name == "alternate_contact_number"
                ? input.substring(3, 6)
                : input.substring(3, 5);
        const last =
            name == "contact_number" || name == "mobile_number" || name == "alternate_contact_number"
                ? input.substring(6, 10)
                : input.substring(5, 9);

        if (
            input.length >
            (name == "contact_number" || name == "mobile_number" || name == "alternate_contact_number" ? 6 : 5)
        ) {
            return `${first}-${middle}-${last}`;
        } else if (input.length > 3) {
            return `${first}-${middle}`;
        } else if (input.length >= 0) {
            return input;
        }
    };

    const handleValidations = (e) => {
        let input = e.target;
        let err = error;
        switch (input.name || input.tagname) {
            case "first_name":
                err.first_name = validate_charWithSpace(input.value, 'first ');
                break;
            case "last_name":
                err.last_name = validate_charWithSpace(input.value, 'last ');
                break;
            case "middle_name":
                err.middle_name = empty_name(input.value, 'midlde ');
                break;
            case "email_id":
                err.email_id = validates_emailId(input.value);
                break;
            case "contact_number":
                err.contact_number = validate_usContact(input.value, 'Mobile Number');
                break;
            case "gender":
                err.gender = validate_emptyField(input.value);
                break;
            case "alternate_contact_number":
                err.alternate_contact_number = empty_usContact(input.value, "contact Number");
                break;
            default:
                break;
        }
        setError(err);
    }

    const validateAll = () => {
        let { first_name, last_name, email_id, contact_number, gender, middle_name } = state;
        let errors = {};
        errors.first_name = validate_charWithSpace(first_name, 'first ');
        errors.last_name = validate_charWithSpace(last_name, 'last ');
        errors.middle_name = empty_name(middle_name, 'midlde ');
        errors.email_id = validates_emailId(email_id);
        errors.contact_number = validate_usContact(contact_number, 'Mobile Number');
        errors.gender = validate_emptyField(gender);
        return errors;
    }
    const [profileDetailLoader, setProfileDetailLoader] = useState(false);
    const updateUserProfileDetails = () => {

        let data = { ...state, request_id: LocalStorage.uid(), };
        setProfileDetailLoader(true)
        UserProfileApi.updateUserProfileDetails(data).then((response) => {
            if (response.data.statusCode == 1003) {
                setProfileDetailLoader(false)
                setAction("read");
                addSuccessMsg(response.data.message);
                getUserProfileDetails();
            } else {
                setProfileDetailLoader(false)
                addErrorMsg(response.data.message);
            }
        });
    }


    const handleSubmit = () => {
        let errors = validateAll();
        if (isValid(errors)) {
            updateUserProfileDetails();
        } else {
            // 
            setError(errors);
        }
    }

    return (
        <Box px={4} pt={3} pb={4} >
            <Grid container spacing={1} alignItems='center'>
                <Grid item xs={9} pt={2}>
                    <Text profileTitle>My Details</Text>
                </Grid>
                <Grid item container xs={3} justifyContent={'flex-end'}>
                    <Box>
                        {action != "update" ?
                            <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => { setAction("update"); setError({}) }} />
                            : ''
                        }
                    </Box>
                </Grid>

            </Grid>

            <Box pt={3}>
                {loading ? (
                    <Box>
                        <Grid container spacing={0}>
                            {
                                [1, 2, 3, 4, 5, 6].map(() => (
                                    <Grid lg={6} md={6} sm={12} xs={12}>
                                        <Box p={1}>
                                            <Skeleton variant="rounded" width={'100%'} height={'54px'} borderRadius={"10px"} />
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                ) :
                    action != "update" ?
                        <Grid container spacing={1} >
                            <Grid item lg={4} md={4} sm={4} xs={6}>
                                <Box p={1} pt={2}>
                                    <Text mediumLabel>
                                        First Name
                                    </Text>
                                    <Text pt={1} mediumBlack14>
                                        {state && state.first_name ? (
                                            state.first_name.length > 20 ? (
                                                <BlackToolTip title={state.first_name} placement="top" arrow>
                                                    {state.first_name.slice(0, 20) + "..."}
                                                </BlackToolTip>
                                            ) : (
                                                state.first_name
                                            )
                                        ) : (
                                            "-"
                                        )}
                                    </Text>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={6}>
                                <Box p={1} pt={2}>
                                    <Text mediumLabel>
                                        Middle Name (Optional)
                                    </Text>
                                    <Text pt={1} mediumBlack14>
                                        {state && state.middle_name ? (
                                            state.middle_name.length > 20 ? (
                                                <BlackToolTip title={state.middle_name} placement="top" arrow>
                                                    {state.middle_name.slice(0, 20) + "..."}
                                                </BlackToolTip>
                                            ) : (
                                                state.middle_name
                                            )
                                        ) : (
                                            "-"
                                        )}
                                    </Text>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={6}>
                                <Box p={1} pt={2}>
                                    <Text mediumLabel>
                                        Last Name
                                    </Text>
                                    <Text pt={1} mediumBlack14>
                                        {state && state.last_name ? (
                                            state.last_name.length > 20 ? (
                                                <BlackToolTip title={state.last_name} placement="top" arrow>
                                                    {state.last_name.slice(0, 20) + "..."}
                                                </BlackToolTip>
                                            ) : (
                                                state.last_name
                                            )
                                        ) : (
                                            "-"
                                        )}
                                    </Text>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={6}>
                                <Box p={1} pt={2}>
                                    <Text mediumLabel>
                                        Gender
                                    </Text>
                                    <Text pt={1} mediumBlack14>
                                        {state.gender}
                                    </Text>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={6}>
                                <Box p={1} pt={2}>
                                    <Text mediumLabel>
                                        Mobile Number
                                    </Text>
                                    <Text pt={1} mediumBlack14>
                                        {state.contact_number}
                                    </Text>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={6}>
                                <Box p={1} pt={2}>
                                    <Text mediumLabel>
                                        Email ID
                                    </Text>

                                    <Text pt={1} mediumBlack14>
                                        {
                                            state.email_id ? state.email_id.length > 16 ?
                                                <BlackToolTip title={state.email_id} placement="top" arrow>
                                                    {(state.email_id.slice(0, 16)) + (state.email_id.length > 16 ? "..." : "")}
                                                </BlackToolTip>
                                                : state.email_id : '--'
                                        }
                                        {/* {state.email_id} */}
                                    </Text>
                                </Box>
                            </Grid>
                        </Grid>
                        :
                        (
                            <Grid container spacing={3} >
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'first_name',
                                                value: state.first_name,
                                                type: 'text',
                                                disabled: action === "update" ? false : true,
                                                inputProps: { maxLength: 33 }
                                            }}
                                            handleChange={handleChange}
                                            clientInput
                                            // labelText={<Text largeLabel>First Name</Text>}
                                            labelText="First Name"
                                            error={error.first_name}
                                        />
                                        <Text errorText>{error.first_name ? error.first_name : ""}</Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'middle_name',
                                                value: state.middle_name,
                                                type: 'text',
                                                disabled: action === "update" ? false : true,
                                                inputProps: { maxLength: 33 }
                                            }}
                                            error={error.middle_name}
                                            handleChange={handleChange}
                                            // labelText={<Text largeLabel>Middle Name <span className={classes.optional}>(Optional)</span></Text>}
                                            labelText="Middle Name (Optional)"
                                            clientInput
                                        />
                                        <Text errorText>{error.middle_name ? error.middle_name : ""}</Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'last_name',
                                                value: state.last_name,
                                                type: 'text',
                                                disabled: action === "update" ? false : true,
                                                inputProps: { maxLength: 33 }
                                            }}
                                            handleChange={handleChange}
                                            // labelText={<Text largeLabel>Last Name</Text>}
                                            labelText="Last Name"
                                            clientInput
                                            error={error.last_name}
                                        />
                                        <Text errorText>{error.last_name ? error.last_name : ""}</Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box>
                                        <Select
                                            name={'gender'}
                                            options={genderOptions}
                                            label={'Gender'}
                                            value={state.gender}
                                            onChange={handleChange}
                                            helperText={
                                                error.gender ?
                                                    <Text red>{error.gender ? error.gender : ''}</Text> : ''
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'contact_number',
                                                value: state.contact_number,
                                                type: 'text',
                                                inputProps: { maxLength: 12 },
                                                disabled: action === "update" ? false : true

                                            }}
                                            handleChange={handleChange}
                                            labelText={'Mobile Number'}
                                            clientInput
                                            error={error.contact_number}
                                        />
                                        <Text errorText>{error.contact_number ? error.contact_number : ""}</Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'email_id',
                                                value: state.email_id,
                                                type: 'text',
                                                disabled: (action === "update") || (LocalStorage.getUserData().super_admin == false) ? false : true
                                            }}
                                            disabled={LocalStorage.getUserData().super_admin == false}
                                            handleChange={handleChange}
                                            labelText={'Email ID'}
                                            clientInput
                                            error={error.email_id}
                                        />
                                        <Text errorText>{error.email_id ? error.email_id : ""}</Text>
                                    </Box>
                                </Grid>
                                <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent={'flex-end'}>
                                    <Stack direction={'row'} gap={4}>
                                        <Button blackCancelBtn onClick={() => { setAction("read"); getUserProfileDetails() }}>Cancel</Button>
                                        <LoadingButton loading={profileDetailLoader} saveBtn onClick={() => handleSubmit()}>Save</LoadingButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        )
                }
            </Box>
            <ReusablePopup openPopup={transferPopup} setOpenPopup={setTransferpopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>If you change you email id permission will be transfer</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>to the new email id.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setTransferpopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand sx={{ width: '140px !important' }} onClick={() => { setTransferpopup(false); setStatus('transfer') }} >
                                Yes, Transfer
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Box >
    )
}

const OrganizationDetails = ({ getOrganizationDetails, state, setState, classes, setAvatarData, setLogoUrl }) => {
    const [action, setAction] = useState("read");// eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [deletePopup, setdeletePopup] = useState(false);
    const [error, setError] = useState({});

    const handleChange = (e) => {
        if (e.target.name === "contact_number" || e.target.name === "alternate_contact_number") {
            let number = contactNumberConvert(e);
            setState({ ...state, [e.target.name]: number })
        } else {
            setState({ ...state, [e.target.name]: e.target.value })
        } handleValidations(e);
    }
    const contactNumberConvert = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const input = value.replace(/\D/g, "").substring(0, 10);

        // Divide numbers in 3 parts :"(123) 456-7890"
        const first =
            name == "contact_number" || name == "mobile_number" || name == "alternate_contact_number"
                ? input.substring(0, 3)
                : input.substring(0, 3);
        const middle =
            name == "contact_number" || name == "mobile_number" || name == "alternate_contact_number"
                ? input.substring(3, 6)
                : input.substring(3, 5);
        const last =
            name == "contact_number" || name == "mobile_number" || name == "alternate_contact_number"
                ? input.substring(6, 10)
                : input.substring(5, 9);

        if (
            input.length >
            (name == "contact_number" || name == "mobile_number" || name == "alternate_contact_number" ? 6 : 5)
        ) {
            return `${first}-${middle}-${last}`;
        } else if (input.length > 3) {
            return `${first}-${middle}`;
        } else if (input.length >= 0) {
            return input;
        }
    };

    const handleValidations = (e) => {
        let input = e.target;
        let err = error;
        switch (input.name || input.tagname) {
            case "organization_name":
                err.organization_name = validate_alphaNumericWithSpecialChars(input.value);
                break;
            case "currency_symbol":
                err.currency_symbol = validate_emptyField(input.value);
                break;
            case "time_zone_format":
                err.time_zone_format = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError(err);
    }

    const validateAll = () => {
        let { organization_name, currency_symbol, logo_name, time_zone_format } = state;
        let errors = {};
        errors.organization_name = validate_alphaNumericWithSpecialChars(organization_name);
        errors.currency_symbol = validate_emptyField(currency_symbol);
        errors.logo_name = validate_emptyField(logo_name)
        errors.time_zone_format = validate_emptyField(time_zone_format)
        return errors;
    }
    const updateUserProfileDetails = () => {

        let data = { ...state, request_id: LocalStorage.uid(), };
        data.organization_name = Buffer.from(state.organization_name).toString('base64')
        OrganizationDetailsApi.updateOrganizationDetails(data, data.id, LocalStorage.getAccessToken()).then((response) => {
            if (response.data.statusCode == 1003) {
                setAction("read");
                setState({
                    ...state,
                    logo_deleted: false,
                    logo_id: ''
                })
                let data = LocalStorage.getUserData()
                data.logo_url = state.logo_url;
                LocalStorage.setUserData(data)
                setLogoUrl(state.logo_url)
                addSuccessMsg(response.data.message);
            } else {
                addErrorMsg(response.data.message);
            }
        });
    }


    const handleSubmit = () => {
        let errors = validateAll();
        if (isValid(errors)) {
            updateUserProfileDetails();
        } else {
            // 
            setError(errors);
        }
    }

    const currency = [
        { id: '$', value: '$ USD' },
    ];

    const uploadDocs = (e) => {
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg'].includes(r))) {
            if (Number(((e.target.files[0].size / (1024 * 1024)).toFixed(2))) < 25) {
                const logoData = new FormData();
                logoData.append("files", e.target.files[0]);
                logoData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                CommonApi.documentUpload("organization-logo", logoData, LocalStorage.getAccessToken()).then((response) => {
                    setTimeout(() => {
                        if (response.data.statusCode == 1003) {
                            state[e.target.name] = response.data.data.id;
                            state["logo_name"] = e.target.files[0].name.length > 30 ? e.target.files[0].name.slice(0, 30) + "..." : e.target.files[0].name;
                            state["logo_url"] = response.data.data.document_url
                            state["logo_id"] = response.data.data.id;
                            state["doc_url"] = response.data.data.document_url
                            setState({ ...state });
                            setError({
                                ...error,
                                logo_name: ''
                            })
                        } else {
                            addErrorMsg(response.data.message);
                        }
                    }, 300)
                });
            } else {
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            addErrorMsg("Please upload files in PNG or JPG format only.");
        }
    };

    const openInNewTab = (documentUrl) => {
        const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const timezones = [
        { id: 'America/Los_Angeles', value: '(UTC-08:00) PST (Pacific Standard Time) - Los Angeles, America' },
        { id: 'America/Chicago', value: '(UTC-06:00) CST (Central Standard Time) - Chicago, America' },
        { id: 'America/New_York', value: '(UTC-05:00) EST (Eastern Standard Time) - New York, America' },
        { id: 'America/Denver', value: '(UTC-07:00) MST (Mountain Standard Time) - Denver, America' },
        { id: 'Asia/Kolkata', value: '(UTC+05:30) IST (India Standard Time) - Kolkata, Asia' }
    ];

    return (
        <Box px={4} pt={3} pb={4} >
            <Grid container spacing={1} >
                <Grid item xs={9}>
                    <Text profileTitle>Organization Details</Text>
                </Grid>
                {
                    LocalStorage.getUserData().super_admin
                        ?
                        <Grid item container xs={3} justifyContent={'flex-end'}>
                            <Box>
                                {action != "update" ?
                                    <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => { setAction("update"); setError({}) }} />
                                    : ''
                                }
                            </Box>
                        </Grid>
                        : ''
                }
            </Grid>

            <Box pt={3}>
                {loading ? (
                    <Box>
                        <Grid container spacing={0}>
                            {
                                [1, 2, 3, 4, 5, 6].map(() => (
                                    <Grid lg={6} md={6} sm={12} xs={12}>
                                        <Box p={1}>
                                            <Skeleton variant="rounded" width={'100%'} height={'54px'} borderRadius={"10px"} />
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                )
                    :
                    state.organization_name == '' && state.currency_symbol == '' && state.time_zone_format == '' ?
                        <Grid container spacing={1} >
                            {NoPermission()}
                        </Grid>
                        :
                        action != "update" ?
                            <Grid container spacing={1} >
                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <Box p={1} pt={2}>
                                        <Text mediumLabel>
                                            Organization Name
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {state.organization_name}
                                        </Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <Box p={1} pt={2}>
                                        <Text mediumLabel>
                                            Currency
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {state.currency_symbol} {state.currency_type}
                                        </Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <Box p={1} pt={2}>
                                        <Text mediumLabel>
                                            Time Zone
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {state.time_zone_format}
                                        </Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={4} sm={4} xs={6}>
                                    <Box p={1} pt={2}>
                                        <Text mediumLabel>
                                            Organization Logo
                                        </Text>
                                        <Text pt={1} sx={{ cursor: 'pointer' }} mediumBlue onClick={() => { if (state?.logo_url) { openInNewTab(state.logo_url) } }} >
                                            {state.logo_name}
                                        </Text>
                                    </Box>
                                </Grid>
                            </Grid>
                            :
                            (
                                <Grid container spacing={3} >
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'organization_name',
                                                    value: state.organization_name,
                                                    type: 'text',
                                                    disabled: action === "update" ? false : true,
                                                    inputProps: { maxLength: 60 }
                                                }}
                                                handleChange={handleChange}
                                                clientInput
                                                labelText={'Organization Name'}
                                                error={error.organization_name}
                                            />
                                            <Text errorText>{error.organization_name ? error.organization_name : ""}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box>
                                            <Select
                                                name="currency_symbol"
                                                value={state.currency_symbol}
                                                onChange={handleChange}
                                                label={'Currency'}
                                                options={currency}
                                                error={error.currency_symbol}
                                            />
                                            <Text errorText>{error.currency_symbol ? error.currency_symbol : ""}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box>
                                            <Select
                                                name='time_zone_format'
                                                value={state.time_zone_format}
                                                onChange={handleChange}
                                                options={timezones}
                                                label={'Time Zone'}
                                                helperText={error.time_zone_format && <Text red>{error.time_zone_format ? error.time_zone_format : ''}</Text>}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box sx={{ height: '56px !important' }}>
                                            <FileInput
                                                name={"logo_name"}
                                                FileName={state.logo_name}
                                                handleChange={uploadDocs}
                                                label='Organizational Logo'
                                                uploadKeyName={'Upload'}
                                                actionState={state.logo_name != '' ? 1 : ''}
                                                documentUrl={state.logo_url}
                                                handleDelete={() => {
                                                    setdeletePopup(true)
                                                }}
                                                error={error.logo_name}
                                            />
                                        </Box>
                                        {error.logo_name ? <Text red>{error.logo_name}</Text> : ""}

                                    </Grid>
                                    <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent={'flex-end'}>
                                        <Stack direction={'row'} gap={4}>
                                            <Button blackCancelBtn onClick={() => { setAction("read"); getOrganizationDetails() }}>Cancel</Button>
                                            <Button saveBtn onClick={() => handleSubmit()}>Save</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            )
                }
            </Box>
            <ReusablePopup openPopup={deletePopup} setOpenPopup={setdeletePopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={SureORG} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you really wish to delete.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setdeletePopup(false) }}>
                                No
                            </Button>
                            <Button finishFilledQuicksand onClick={() => {
                                setState({
                                    ...state,
                                    logo_name: '',
                                    doc_url: '',
                                    logo_url: '',
                                    logo_deleted: true
                                })
                                setdeletePopup(false)
                            }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Box >
    )
}

const EmployeeList = ({ users, setAvatarData, loading1 }) => {
    // eslint-disable-next-line
    const [loading, setLoading] = useState(loading1);
    const navigate = useNavigate()
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    return (
        <Box px={4} pt={3} pb={4} >

            <Box pt={3}>
                {loading ? (
                    <Box>
                        <Grid container spacing={0}>
                            {
                                [1, 2, 3, 4, 5, 6].map(() => (
                                    <Grid lg={6} md={6} sm={12} xs={12}>
                                        <Box p={1}>
                                            <Skeleton variant="rounded" width={'100%'} height={'54px'} borderRadius={"10px"} />
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                ) :
                    (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))
                        ?
                        <Grid container spacing={1} >
                            <Grid item xs={9}>
                                <Text profileTitle>My Users</Text>
                            </Grid>
                            <Grid item xs={3} container justifyContent={'end'} pt={2}>
                                <Text blue14 sx={{ fontWeight: '400 !important', cursor: 'pointer' }} onClick={() => {
                                    LocalStorage.removeRedirectedModule();
                                    LocalStorage.removeFromPage(); navigate('/employees', { state: { profile: 1 } })
                                }}>View List</Text>
                            </Grid>
                            <Grid item xs={12} container justifyContent={'flex-start'} pt={2} pb={2}>
                                <Text profileTitle sx={{ fontSize: '22px !important' }}>{users.userData.current_count}/{users.userData.max_count}</Text>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} xs={12} container justifyContent={'flex-start'} pb={2}>
                                <Stack direction={'row'}>
                                    <AvatarGroup total={users.userData.profile_avatars.length} spacing={18}
                                        renderSurplus={(surplus) => <span>{surplus.toString()}</span>}
                                        sx={{
                                            "& .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar": {
                                                color: '#0C75EB',
                                                backgroundColor: '#D1E1FF',
                                                font: '12px Quicksand !important',
                                                fontWeight: `${600} !important`,
                                            },
                                            "& .MuiAvatar-root": {
                                                position: "static !important",
                                                border: "none !important",
                                                color: '#0C75EB !important',
                                                backgroundColor: '#D1E1FF !important',
                                                font: '12px Quicksand !important',
                                                fontWeight: `${600} !important`,
                                                width: '50px',
                                                height: '50px'
                                                // marginX: '-10px'
                                            },
                                            "& .MuiAvatar-root-MuiAvatarGroup-avatar": {
                                                color: '#0C75EB !important',
                                                backgroundColor: '#D1E1FF !important',
                                                font: '12px Quicksand !important',
                                                fontWeight: `${600} !important`,
                                                // marginX: '-10px'
                                            }
                                        }}>
                                        {users.userData.profile_avatars && users.userData.profile_avatars.map((profile) => (
                                            <Avatar alt="Remy Sharp" src={profile} sx={{ width: "50px", height: "50px" }} />
                                        ))}
                                    </AvatarGroup>
                                    <Box p={1} pl={3} pt={2}>
                                        <Text boldGrey14>{users.userData.max_count - users.userData.current_count} Users Remaining</Text>
                                    </Box>
                                </Stack>

                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={9} mt={3}>
                                <Text profileTitle>All Employees</Text>
                            </Grid>
                            {
                                users.employeeData.profile_avatars && users.employeeData.profile_avatars.length == 0 ? ''
                                    :
                                    <Grid item xs={3} container justifyContent={'end'} mt={3}>
                                        <Text blue14 sx={{ fontWeight: '400 !important', cursor: 'pointer' }} onClick={() => {
                                            LocalStorage.removeRedirectedModule();
                                            LocalStorage.removeFromPage(); navigate('/employees')
                                        }}>View List</Text>
                                    </Grid>
                            }

                            {
                                users.employeeData.profile_avatars && users.employeeData.profile_avatars.length == 0
                                    ?
                                    <Grid container xs={12} pt={3}>
                                        {
                                            users?.employeeData?.current_count != 0 ?
                                                <Grid item xs={12} container justifyContent={'flex-start'} pt={2} pb={2} pl={2}>
                                                    <Text profileTitle sx={{ fontSize: '22px !important' }}>{users.employeeData.current_count}/{users.employeeData.max_count}</Text>
                                                </Grid>
                                                : ''
                                        }
                                        <Grid item xs={12} container justifyContent={'center'}>
                                            <AvatarGroup total={3} spacing={18}
                                                renderSurplus={(surplus) => <span>{surplus.toString()}</span>}
                                                sx={{
                                                    "& .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar": {
                                                        color: '#0C75EB',
                                                        backgroundColor: '#D1E1FF',
                                                        font: '12px Quicksand !important',
                                                        fontWeight: `${600} !important`,
                                                    },
                                                    "& .MuiAvatar-root": {
                                                        position: "static !important",
                                                        border: "none !important",
                                                        color: '#0C75EB !important',
                                                        backgroundColor: '#D1E1FF !important',
                                                        font: '12px Quicksand !important',
                                                        fontWeight: `${600} !important`,
                                                        width: '50px',
                                                        height: '50px'
                                                        // marginX: '-10px'
                                                    },
                                                    "& .MuiAvatar-root-MuiAvatarGroup-avatar": {
                                                        color: '#0C75EB !important',
                                                        backgroundColor: '#D1E1FF !important',
                                                        font: '12px Quicksand !important',
                                                        fontWeight: `${600} !important`,
                                                        // marginX: '-10px'
                                                    }
                                                }}>
                                                {[0, 1, 2].map((abc) => (
                                                    <Avatar alt="Remy Sharp" src={profile} sx={{ width: "50px", height: "50px" }} />
                                                ))}
                                            </AvatarGroup>
                                        </Grid>
                                        <Grid item xs={12} container justifyContent={'center'} pt={2}>
                                            You can add employees here
                                        </Grid>
                                        <Grid item xs={12} container justifyContent={'center'} pt={1}>
                                            <Button addButton onClick={() => { navigate('/employees') }}>
                                                Add Employee
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container xs={12} pl={1.5}>
                                        <Grid item xs={12} container justifyContent={'flex-start'} pt={2} pb={2}>
                                            <Text profileTitle sx={{ fontSize: '22px !important' }}>{users.employeeData.current_count}/{users.employeeData.max_count}</Text>
                                        </Grid>
                                        <Grid item xl={12} lg={8} md={12} xs={12} container justifyContent={'flex-start'} pb={2}>
                                            <Stack direction={'row'}>
                                                <AvatarGroup total={users.employeeData.profile_avatars.length} spacing={18}
                                                    renderSurplus={(surplus) => <span>{surplus.toString()}</span>}
                                                    sx={{
                                                        "& .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar": {
                                                            color: '#0C75EB',
                                                            backgroundColor: '#D1E1FF',
                                                            font: '12px Quicksand !important',
                                                            fontWeight: `${600} !important`,
                                                        },
                                                        "& .MuiAvatar-root": {
                                                            position: "static !important",
                                                            border: "none !important",
                                                            color: '#0C75EB !important',
                                                            backgroundColor: '#D1E1FF !important',
                                                            font: '12px Quicksand !important',
                                                            fontWeight: `${600} !important`,
                                                            width: '50px',
                                                            height: '50px'
                                                            // marginX: '-10px'
                                                        },
                                                        "& .MuiAvatar-root-MuiAvatarGroup-avatar": {
                                                            color: '#0C75EB !important',
                                                            backgroundColor: '#D1E1FF !important',
                                                            font: '12px Quicksand !important',
                                                            fontWeight: `${600} !important`,
                                                            // marginX: '-10px'
                                                        }
                                                    }}>
                                                    {users.employeeData.profile_avatars && users.employeeData.profile_avatars.map((profile) => (
                                                        <Avatar alt="Remy Sharp" src={profile} sx={{ width: "50px", height: "50px" }} />
                                                    ))}
                                                </AvatarGroup>
                                                <Box p={1} pl={3} pt={2}>
                                                    <Text boldGrey14>{users.employeeData.max_count - users.employeeData.current_count} Employees Remaining</Text>
                                                </Box>
                                            </Stack>
                                        </Grid>

                                    </Grid>
                            }


                        </Grid>
                        : ''
                }
            </Box>
        </Box >
    )
}

const ManageSubscription = ({ state, setState, classes, setAvatarData, setStatus, status, current, setCurrent }) => {
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const navigate = useNavigate();

    return (
        <Box px={4} pt={3} pb={4} >
            <Grid container spacing={1} >
                <Grid item xs={9}>
                    <Text profileTitle>Manage Subscriptions & Payments</Text>
                </Grid>
            </Grid>

            <Box pt={3}>
                {loading ? (
                    <Box>
                        <Grid container spacing={0}>
                            {
                                [1, 2, 3, 4, 5, 6].map(() => (
                                    <Grid lg={6} md={6} sm={12} xs={12}>
                                        <Box p={1}>
                                            <Skeleton variant="rounded" width={'100%'} height={'54px'} borderRadius={"10px"} />
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                ) :
                    <Grid container spacing={1} >
                        <Grid item xs={9}>
                            <Box p={2}>
                                <Text profileTitle sx={{ fontSize: '14px !important' }}>Manage Subscription</Text>
                            </Box>
                        </Grid>
                        <Grid item xs={2.8} container justifyContent={'end'}>
                            <Box p={2}>
                                <img src={RightIcon} alt="icon" style={{ cursor: 'pointer' }}
                                    onClick={() => { setStatus('manage'); window.scrollTo(0, 0) }}
                                // onClick={() => navigate('/billing', { state: { renew: true } })}
                                />
                            </Box>
                        </Grid>
                        {
                            LocalStorage.getUserData().super_admin ?
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                : ''}
                        {
                            LocalStorage.getUserData().super_admin ?
                                <Grid item xs={9}>
                                    <Box p={2}>
                                        <Text profileTitle sx={{ fontSize: '14px !important' }}>Billing Details </Text>
                                    </Box>
                                </Grid>
                                : ''
                        }
                        {
                            LocalStorage.getUserData().super_admin ?
                                <Grid item xs={2.8} container justifyContent={'end'}>
                                    <Box p={2}>
                                        <img src={RightIcon} alt="icon" style={{ cursor: 'pointer' }} onClick={() => { setStatus('billingHistory') }} />
                                    </Box>
                                </Grid>
                                : ''}
                        {
                            LocalStorage.getUserData().super_admin ?
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                : ''}
                        {
                            LocalStorage.getUserData().super_admin ?
                                <Grid item xs={9}>
                                    <Box p={2}>
                                        <Text profileTitle sx={{ fontSize: '14px !important' }}>View Purchasing History</Text>
                                    </Box>
                                </Grid>
                                : ''
                        }
                        {
                            LocalStorage.getUserData().super_admin ?
                                <Grid item xs={2.8} container justifyContent={'end'}>
                                    <Box p={2}>
                                        <img src={RightIcon} alt="icon" style={{ cursor: 'pointer' }} onClick={() => { setStatus('purchase') }} />
                                    </Box>
                                </Grid>
                                : ''}
                    </Grid>
                }
            </Box>
        </Box >
    )
}

const ManageAppIntegrations = ({ classes, navigate, apps, loading }) => {

    return (
        <Box px={4} pt={3} pb={4} >
            <Grid container spacing={1} >
                <Grid item lg={7} md={7} sm={8} xs={12}>
                    <Text profileTitle>{apps.length > 0 ? "App Integrations" : "Manage App Integrations"}</Text>
                </Grid>
                {apps.length > 0 && <Grid item lg={5} md={5} sm={4} xs={12} textAlign={"end"}>
                    <Button onClick={() => { navigate("/app-integrations", { state: { status: true } }) }} className={classes.manageButton} addButton >Manage Integration</Button>
                </Grid>}
                {loading ? (
                    <Grid lg={10} md={10} sm={10} xs={11}>
                        <Skeleton variant="rounded" width={'100%'} height={'54px'} borderRadius={"10px"} />
                    </Grid>
                ) :
                    <>
                        {apps.length > 0 &&
                            <Grid item lg={10} md={10} sm={10} xs={11} mt={3} container justifyContent={'flex-start'}>
                                <AvatarGroup total={apps.length} spacing={18}
                                    renderSurplus={(surplus) => <span>{surplus.toString()}</span>}
                                    sx={{
                                        "& .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar": {
                                            color: '#0C75EB',
                                            backgroundColor: '#D1E1FF',
                                            font: '12px Quicksand !important',
                                            fontWeight: `${600} !important`,
                                        },
                                        "& .MuiAvatar-root": {
                                            position: "static !important",
                                            border: "none !important",
                                            color: '#0C75EB !important',
                                            backgroundColor: '#D1E1FF !important',
                                            font: '12px Quicksand !important',
                                            fontWeight: `${600} !important`,
                                            width: '50px',
                                            height: '50px'
                                            // marginX: '-10px'
                                        },
                                        "& .MuiAvatar-root-MuiAvatarGroup-avatar": {
                                            color: '#0C75EB !important',
                                            backgroundColor: '#D1E1FF !important',
                                            font: '12px Quicksand !important',
                                            fontWeight: `${600} !important`,
                                            // marginX: '-10px'
                                        }
                                    }}>
                                    {apps && apps.map((profile) => (
                                        <Avatar alt="Remy Sharp" src={profile.icon_url} sx={{ width: "50px", height: "50px" }} />
                                    ))}

                                </AvatarGroup>
                            </Grid>}
                    </>
                }
                {apps.length > 0 && <Grid itm lg={2} md={2} sm={2} xs={1} textAlign={"end"} mt={3}>
                    <img src={RightIcon} alt='RightIcon' style={{ cursor: 'pointer' }} onClick={() => { navigate("/app-integrations") }} />
                </Grid>}
            </Grid>

            <Box pt={3}>
                {loading ? (
                    <Grid lg={10} md={10} sm={10} xs={11}>
                        <Skeleton variant="rounded" width={'100%'} height={'14px'} borderRadius={"10px"} />
                    </Grid>
                ) :
                    <>
                        {apps.length == 0 &&
                            <Grid container alignItems={"center"} justifyContent={"center"}>
                                <Button onClick={() => { navigate("/app-integrations") }} className={classes.addButton} addButton startIcon={<AddIcon />}>Add New Apps</Button>
                            </Grid>
                        }
                    </>}
            </Box>
        </Box >
    )
}

const ViewSubscription = ({ state, setState, classes, setAvatarData, setStatus, status, current, setCurrent }) => {
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [plan, setPlan] = useState('free');
    const freePlan = [
        {
            label: 'Admin Access',
            value: plan == 'starter' ? '8 Users' : plan == 'advanced' ? '12 Users' : '5 Users'
        },
        {
            label: 'Active Employees',
            value: plan == 'starter' ? '75' : plan == 'advanced' ? '155' : '35'
        },
        {
            label: 'AI Timesheets',
            value: plan == 'starter' ? '175' : plan == 'advanced' ? '500' : '50'
        },
        {
            label: 'MIVI AI Prompts',
            value: plan == 'starter' ? '250' : plan == 'advanced' ? '500' : '75'
        },
        {
            label: 'Onboarding & Offboarding',
            value: 'Yes'
        },
        {
            label: 'Timesheet Management',
            value: 'Yes'
        },
        {
            label: 'Placement Management',
            value: 'Yes'
        },
        {
            label: 'Client & Vendor Management',
            value: 'Yes'
        }
    ]

    const [data, setData] = useState({})
    const [clearPopup, setclearPopup] = useState(false)
    const [mainAutoRenew, setmainAutoRenew] = useState(false)
    const [aiAutoRenew, setaiAutoRenew] = useState(false)

    useEffect(() => {
        if (status == 'manage') {
            setLoading(true)
            UserProfileApi.subscriptionDetails().then(
                (response) => {
                    if (response.data.statusCode == 1003) {
                        setData(response.data.data)
                        setLoading(false)
                        setmainAutoRenew(response.data.data.is_plan_auto_renewed)
                        setaiAutoRenew(response.data.data.ai.is_plan_auto_renewed)
                        setPlan(response.data.data.slug == 'trail' ? 'free' : response.data.data.slug == 'starter-monthly' || response.data.data.slug == "starter-yearly" ? 'starter' : 'advanced')
                    }
                    else {
                        setLoading(false)
                    }
                }
            )
        }
    }, [status])

    const getDetails = () => {
        setLoading(true)
        UserProfileApi.subscriptionDetails().then(
            (response) => {
                if (response.data.statusCode == 1003) {
                    setLoading(false)
                    setData(response.data.data);
                    setmainAutoRenew(response.data.data.is_plan_auto_renewed)
                    setaiAutoRenew(response.data.data.ai.is_plan_auto_renewed)
                    setPlan(response.data.data.slug == 'trail' ? 'free' : response.data.data.slug == 'starter-monthly' || response.data.data.slug == "starter-yearly" ? 'starter' : 'advanced')
                }
                else {
                    setLoading(false)
                }
            }
        )
    }

    // eslint-disable-next-line
    const RenewPlan = () => {
        let dataSubmit = {
            request_id: LocalStorage.uid(),
            plan_id: data.id,
            subdomain_name: domain
        }
        CommonApi.renewInitiation(dataSubmit).then(
            (res) => {
                if (res.data.statusCode == 1003) {
                    // addSuccessMsg(res.data.message);
                    window.open(res.data.data.url, '_blank');
                }
                else {
                    addErrorMsg(res.data.message)
                }
            }
        )
    }
    // eslint-disable-next-line
    const cancelSubscription = () => {
        addLoader(true)
        if (current == 'ai') {
            let dataSubmit = {
                request_id: LocalStorage.uid(),
                subdomain_name: domain
            }
            // let key = 'pk_test_51P1o8905CUJoRYAjl2LMbglsEN0oSR2Ew01Y36eR4LYlarrOeqgmC7dXLsDIotcAfvnYcTV3Kg4NVy8Ph7V2kR7y00rwRmQOTi'
            CommonApi.cancelSubscriptionAI(dataSubmit).then(
                (res) => {
                    if (res.data.statusCode == 1003) {
                        addSuccessMsg('Cancellation successful');
                        getDetails();
                        setclearPopup(false);
                        removeLoader()
                    }
                    else {
                        addErrorMsg(res.data.message);
                        setclearPopup(false)
                        removeLoader()
                    }
                }
            )

        }
        else {
            let dataSubmit = {
                request_id: LocalStorage.uid(),
                subdomain_name: domain
            }
            // let key = 'pk_test_51P1o8905CUJoRYAjl2LMbglsEN0oSR2Ew01Y36eR4LYlarrOeqgmC7dXLsDIotcAfvnYcTV3Kg4NVy8Ph7V2kR7y00rwRmQOTi'
            CommonApi.cancelSubscription(dataSubmit).then(
                (res) => {
                    if (res.data.statusCode == 1003) {
                        addSuccessMsg('Cancellation successful');
                        setclearPopup(false);
                        getDetails()
                        removeLoader()
                    }
                    else {
                        addErrorMsg(res.data.message);
                        setclearPopup(false)
                        removeLoader()
                    }
                }
            )
        }

    }
    // eslint-disable-next-line
    const upgradePlan = () => {
        let dataSubmit = {
            request_id: LocalStorage.uid(),
            plan_id: data.id,
            subdomain_name: domain
        }
        CommonApi.upgradeInitiation(dataSubmit).then(
            (res) => {
                if (res.data.statusCode == 1003) {
                    addSuccessMsg(res.data.message)
                }
                else {
                    addErrorMsg(res.data.message)
                }
            }
        )
    }

    const navigate = useNavigate()


    const autoPlanRenewalMain = (e) => {
        if (current == 'ai') {
            setaiAutoRenew(e.target.checked)
        }
        else {
            setmainAutoRenew(e.target.checked)
        }
        let data = {
            request_id: LocalStorage.uid(),
            auto_renewal: e.target.checked
        }
        addLoader(true)
        if (current == 'ai') {
            CommonApi.autoPlanRenewalMainAI(data).then((res) => {
                if (res.data.statusCode == 1003) {
                    removeLoader();
                    addSuccessMsg(`${data.auto_renewal == true ? 'Your auto-renewal is turned on successfully' : 'Your auto-renewal is turned off successfully'}`)
                    getDetails();
                }
                else {
                    removeLoader();
                    setmainAutoRenew(!e.target.checked);
                    getDetails();
                    addErrorMsg(res.data.message)
                }
            })
        }
        else {
            CommonApi.autoPlanRenewalMain(data).then((res) => {
                if (res.data.statusCode == 1003) {
                    removeLoader();
                    addSuccessMsg(`${data.auto_renewal == true ? 'Your auto-renewal is turned on successfully' : 'Your auto-renewal is turned off successfully'}`)
                    getDetails();
                }
                else {
                    removeLoader();
                    getDetails();
                    addErrorMsg(res.data.message)
                }
            })
        }

    }

    return (
        <Box px={4} pt={3} pb={4} >
            <Grid container spacing={1} >
                <Grid lg={7} md={7} sm={7} item xs={7}>
                    <Stack direction={'row'}>
                        <img src={ArrowLeft} alt='ArrowLeft' style={{ cursor: 'pointer' }} onClick={() => { setStatus(''); setCurrent('main') }} />
                        <Text profileTitle sx={{ paddingLeft: '15px' }}>Manage Subscription</Text>
                    </Stack>
                </Grid>
                {
                    loading ?
                        <Grid item container lg={5} md={5} sm={5} xs={5} justifyContent={'flex-end'} pb={2}>
                            <Skeleton variant="rounded" width={'100%'} height={'54px'} borderRadius={"10px"} />
                        </Grid>
                        :
                        LocalStorage.getUserData().super_admin && current != 'ai' && data?.slug != 'trail' && !data?.is_subscription_cancelled ?
                            <Grid item container lg={5} md={5} sm={5} xs={5} justifyContent={'flex-end'} pb={2}>
                                <Text headerBlack sx={{ paddingRight: '20px' }}>Auto Renewal</Text>
                                <ToggleSwitch name='is_active' isActive={mainAutoRenew} switchChange={(e) => { autoPlanRenewalMain(e) }} sx={{ height: '24px !important' }} />
                            </Grid>
                            : ''
                }

            </Grid>

            <Box pt={3}>
                {loading ? (
                    <Box>
                        <Grid container spacing={0}>
                            {
                                [1, 2, 3, 4, 5, 6].map(() => (
                                    <Grid lg={6} md={6} sm={12} xs={12}>
                                        <Box p={1}>
                                            <Skeleton variant="rounded" width={'100%'} height={'54px'} borderRadius={"10px"} />
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                ) :
                    current == "ai" ?
                        <Grid container lg={12} md={12} sm={12} xs={12} pl={4} >
                            <Grid container lg={12} md={12} sm={12} xs={12} pt={2}>
                                <Grid container lg={12} md={12} sm={12} xs={12} alignItems={'center'} pb={2}>
                                    {
                                        data?.ai?.cancel_subscription && !data?.ai?.is_subscription_expired ?
                                            <Box sx={{ background: '#FDECCE', width: '100%', height: '50px', borderRadius: '8px', padding: '15px 0px 15px 0px', textAlign: 'center', display: 'flex', flexDirection: 'row' }}>
                                                <img src={InfoIcon} alt='info' style={{ paddingLeft: '20px' }} />
                                                <Text mediumOrange sx={{ fontSize: '15px !important', paddingLeft: '10px' }}>Your subscription is cancelled. You will be able to use your credits till {data?.ai?.plan_expires_on}.</Text>
                                            </Box>
                                            : ''
                                    }
                                    {
                                        data?.ai?.going_to_expire && !aiAutoRenew ?
                                            <Box mt={1} sx={{ background: '#FFE1E2', width: '100%', height: '50px', borderRadius: '8px', padding: '15px 0px 15px 0px', textAlign: 'center', display: 'flex', flexDirection: 'row' }}>
                                                <img src={InfoIconRed} alt='info' style={{ paddingLeft: '20px' }} />
                                                <Text mediumOrange sx={{ fontSize: '15px !important', paddingLeft: '10px', color: "#ED2D46 !important" }}>Your Plan is going to expire on {moment(data.ai?.plan_expires_on, dateFormat()).format(dateFormat())}.</Text>
                                            </Box>
                                            : ''
                                    }
                                </Grid>
                                <Grid container lg={12} md={12} sm={12} xs={12} alignItems={'center'}>
                                    <Grid item lg={6} md={6} sm={6} xs={6} pb={2}>
                                        <Text headerBlack>AI Timesheet</Text>
                                    </Grid>
                                    {
                                        data?.ai?.plan_name == null || data?.ai?.cancel_subscription ? '' :
                                            LocalStorage.getUserData().super_admin == false ? ''
                                                :
                                                <Grid item container lg={6} md={6} sm={6} xs={6} justifyContent={'flex-end'} pb={2}>
                                                    <Text headerBlack sx={{ paddingRight: '20px' }}>Auto Renewal</Text>
                                                    <ToggleSwitch name='is_active' isActive={aiAutoRenew} switchChange={(e) => { autoPlanRenewalMain(e) }} sx={{ height: '24px !important' }} />
                                                </Grid>
                                    }

                                </Grid>
                                {
                                    LocalStorage.getUserData().super_admin && data?.ai?.plan_name == null ?
                                        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} pl={1.5}>
                                            <Button fullWidth onClick={() => { navigate('/ai_timesheet', { state: { renew: true, } }) }} sx={{ background: "#0C75EB !important", color: "white !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', borderRadius: '8px' }}>Buy Credits</Button>
                                        </Grid> :
                                        data?.ai?.plan_name != null &&
                                        <Grid container lg={12} md={12} sm={12} xs={12} alignItems={'center'}
                                            style={{
                                                // backgroundImage: "linear-gradient(135deg, #A15C47, #FFDDC4 )",
                                                // backgroundImage: "linear-gradient(135deg, #FFC803, #FFDCA7 )",
                                                backgroundImage: data?.ai?.plan_slug == 'ai-timesheet-elite' ? "linear-gradient(135deg, #8186FF, #C6EAFF )" : data?.ai?.plan_slug == 'ai-timesheet-pro' ? "linear-gradient(135deg, #FFC0EA, #ED5EDE )" : "linear-gradient(135deg, #FFC803, #FFDCA7 )",
                                                color: "#E8C5B3",
                                                height: '200px',
                                                width: '100%',
                                                borderRadius: '8px',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Grid container lg={12} md={12} sm={12} xs={12} alignItems={'center'} justifyContent={'space-between'}>
                                                <Box pl={5}>
                                                    <Grid item container xs={12}>
                                                        <Box sx={{ borderRadius: '24px', padding: '4px 10px 4px 10px', background: 'white' }}>
                                                            <Text largeWhite sx={{ fontSize: '14px !important', color: data?.ai?.plan_slug == 'ai-timesheet-pro' ? '#ED62DF !important' : data?.ai?.plan_slug == 'ai-timesheet-elite' ? '#8288FF !important' : '#F59E0B !important' }}>Credits - {data?.ai?.plan_access_credits}</Text>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item container xs={12}>
                                                        <Text sx={{
                                                            fontFamily: 'Quicksand !important',
                                                            fontSize: '64px !important', color: '#FFFFFF !important',
                                                            fontWeight: `${600} !important`,
                                                            "@media (min-width: 1200px) and (max-width: 1420px)": {
                                                                fontSize: '44px !important'
                                                            }
                                                        }}>${data?.ai?.plan_amount}
                                                        </Text>
                                                    </Grid>

                                                    <Grid item container xs={12}>
                                                        {
                                                            data?.ai?.is_subscription_expired ?
                                                                <Text largeWhite sx={{ fontSize: '14px !important' }}>Bundle Expired</Text>
                                                                :
                                                                <Text largeWhite sx={{ fontSize: '14px !important' }}>{aiAutoRenew ? 'Renews' : 'Ends'} on {data?.ai?.plan_expires_on}</Text>
                                                        }
                                                    </Grid>

                                                </Box>
                                                <Box display={'flex'} alignItems={'center'} pr={3}>
                                                    <Text sx={{
                                                        fontFamily: 'Quicksand !important',
                                                        fontSize: '84px !important', color: '#fff2f2ad !important',
                                                        fontWeight: `${600} !important`,
                                                        "@media (min-width: 1200px) and (max-width: 1420px)": {
                                                            fontSize: '64px !important'
                                                        }
                                                    }}>{data?.ai?.plan_slug == 'ai-timesheet-elite' ? 'Elite' : data?.ai?.plan_slug == 'ai-timesheet-pro' ? 'Pro' : 'Basic'}</Text>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                }
                                {
                                    data?.ai?.plan_name == null ?
                                        '' :
                                        LocalStorage.getUserData().super_admin == false ? '' :
                                            <Grid container lg={12} md={12} sm={12} xs={12} pt={4} >
                                                {
                                                    data?.ai?.cancel_subscription || data?.ai?.is_subscription_expired ?
                                                        <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                            <Button fullWidth onClick={() => { navigate('/ai_timesheet', { state: { renew: true } }) }} sx={{ background: "#FFFFFF !important", color: "#0C75EB !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #0C75EB', borderRadius: '8px' }}>Renew</Button>
                                                        </Grid>
                                                        :
                                                        data?.ai?.plan_slug == 'ai-timesheet-elite' || aiAutoRenew ?
                                                            <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                                <Button fullWidth onClick={() => { navigate('/ai_timesheet') }} sx={{ background: "#FFFFFF !important", color: "#0C75EB !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #0C75EB', borderRadius: '8px' }}>Renew</Button>
                                                            </Grid>
                                                            :
                                                            data?.ai?.plan_slug != 'ai-timesheet-elite' && !aiAutoRenew ?
                                                                <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                                    <Button fullWidth onClick={() => { navigate('/ai_timesheet') }} sx={{ background: "#FFFFFF !important", color: "#0C75EB !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #0C75EB', borderRadius: '8px' }}>Renew</Button>
                                                                </Grid>
                                                                :
                                                                <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                                    <Button fullWidth onClick={() => { setclearPopup(true) }} sx={{ background: "#FFFFFF !important", color: "#F70D0D !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #F70D0D', borderRadius: '8px' }}>Cancel</Button>
                                                                </Grid>
                                                }
                                                {
                                                    data?.ai?.plan_slug == 'ai-timesheet-elite' && aiAutoRenew ?
                                                        <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                            <Button fullWidth onClick={() => { setclearPopup(true) }} sx={{ background: "#FFFFFF !important", color: "#F70D0D !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #F70D0D', borderRadius: '8px' }}>Cancel</Button>
                                                        </Grid>
                                                        :
                                                        data?.ai?.plan_slug == 'ai-timesheet-elite' ?
                                                            <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pl={1.5}>
                                                                <Button fullWidth onClick={() => { navigate('/ai_timesheet', { state: { renew: true, } }) }} sx={{ background: "#0C75EB !important", color: "white !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', borderRadius: '8px' }}>Explore All Bundles</Button>
                                                            </Grid>
                                                            :
                                                            <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pl={1.5}>
                                                                <Button fullWidth onClick={() => { navigate('/ai_timesheet', { state: { renew: true, } }) }} sx={{ background: "#0C75EB !important", color: "white !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', borderRadius: '8px' }}>Upgrade Plan</Button>
                                                            </Grid>
                                                }
                                                {
                                                    (data?.ai?.plan_slug != 'ai-timesheet-elite') && data?.ai?.cancel_subscription == false && data?.ai?.is_subscription_expired == false && aiAutoRenew ?
                                                        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} pl={1.5} justifyContent={'center'} pt={1}>
                                                            <Button fullWidth onClick={() => { setclearPopup(true) }} sx={{ background: "FFFFFF !important", color: "#F70D0D !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', borderRadius: '8px' }}>Cancel</Button>
                                                        </Grid>
                                                        : ''}
                                            </Grid>
                                }
                            </Grid>
                            <Grid container lg={12} md={12} sm={12} xs={12} pt={4} pb={2}>
                                <Divider sx={{ width: '100%', margin: '0px !important', borderColor: '#C7CCD3 !important', borderWidth: '1px !important' }} />
                            </Grid>
                            <Grid container lg={12} md={12} sm={12} xs={12} pt={2}>
                                <Grid container lg={12} md={12} sm={12} xs={12} alignItems={'center'}>
                                    <Grid item lg={6} md={6} sm={6} xs={6} pb={2}>
                                        <Text headerBlack>MIVI AI Prompt</Text>
                                    </Grid>
                                </Grid>
                                {
                                    LocalStorage.getUserData().super_admin && data?.mivi?.plan_name == null ?
                                        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} pl={1.5}>
                                            <Button fullWidth onClick={() => { navigate('/mivi-upgrade', { state: { renew: true, } }) }} sx={{ background: "#0C75EB !important", color: "white !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', borderRadius: '8px' }}>Buy Credits</Button>
                                        </Grid>
                                        :
                                        data?.mivi?.plan_name != null &&
                                        <Grid container lg={12} md={12} sm={12} xs={12} alignItems={'center'}
                                            style={{
                                                // backgroundImage: "linear-gradient(135deg, #A15C47, #FFDDC4 )",
                                                // backgroundImage: "linear-gradient(135deg, #FFC803, #FFDCA7 )",
                                                backgroundImage: data?.mivi?.plan_slug == 'mivi-prompt-elite' ? "linear-gradient(135deg, #8186FF, #C6EAFF )" : data?.mivi?.plan_slug == 'mivi-prompt-pro' ? "linear-gradient(135deg, #FFC0EA, #ED5EDE )" : "linear-gradient(135deg, #FFC803, #FFDCA7 )",
                                                color: "#E8C5B3",
                                                height: '200px',
                                                width: '100%',
                                                borderRadius: '8px',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Grid container lg={12} md={12} sm={12} xs={12} alignItems={'center'} justifyContent={'space-between'}>
                                                <Box pl={5}>
                                                    <Grid item container xs={12}>
                                                        <Box sx={{ borderRadius: '24px', padding: '4px 10px 4px 10px', background: 'white' }}>
                                                            <Text largeWhite sx={{ fontSize: '14px !important', color: data?.mivi?.plan_slug == 'mivi-prompt-pro' ? '#ED62DF !important' : data?.mivi?.plan_slug == 'mivi-prompt-elite' ? '#8288FF !important' : '#F59E0B !important' }}>Credits - {data?.mivi?.plan_access_credits}</Text>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item container xs={12}>
                                                        <Text sx={{
                                                            fontFamily: 'Quicksand !important',
                                                            fontSize: '64px !important', color: '#FFFFFF !important',
                                                            fontWeight: `${600} !important`,
                                                            "@media (min-width: 1200px) and (max-width: 1420px)": {
                                                                fontSize: '44px !important'
                                                            }
                                                        }}>${data?.mivi?.plan_amount}
                                                        </Text>
                                                    </Grid>
                                                </Box>
                                                <Box display={'flex'} alignItems={'center'} pr={3}>
                                                    <Text sx={{
                                                        fontFamily: 'Quicksand !important',
                                                        fontSize: '84px !important', color: '#fff2f2ad !important',
                                                        fontWeight: `${600} !important`,
                                                        "@media (min-width: 1200px) and (max-width: 1420px)": {
                                                            fontSize: '64px !important'
                                                        }
                                                    }}>{data?.mivi?.plan_slug == 'mivi-prompt-elite' ? 'Elite' : data?.mivi?.plan_slug == 'mivi-prompt-pro' ? 'Pro' : 'Basic'}</Text>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                }
                                {
                                    LocalStorage.getUserData().super_admin == false ? '' :
                                        data?.mivi?.plan_name == null ? '' :
                                            <Grid container lg={12} md={12} sm={12} xs={12} pt={4}>
                                                <Grid item container lg={12} md={12} sm={12} xs={12} >
                                                    <Button fullWidth onClick={() => { navigate('/mivi-upgrade', { state: { renew: true, } }) }} sx={{ background: "#0C75EB !important", color: "white !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', borderRadius: '8px' }}>Buy More</Button>
                                                </Grid>
                                            </Grid>
                                }
                            </Grid>

                        </Grid>
                        :
                        <Grid container lg={12} md={12} sm={12} xs={12} pl={4} >
                            <Grid container lg={12} md={12} sm={12} xs={12} pt={2}>
                                <Grid container lg={12} md={12} sm={12} xs={12} alignItems={'center'} pb={2}>
                                    {
                                        data.is_subscription_cancelled && !data.is_subscription_expired ?
                                            <Box sx={{ background: '#FDECCE', width: '100%', height: '50px', borderRadius: '8px', padding: '15px 0px 15px 0px', textAlign: 'center', display: 'flex', flexDirection: 'row' }}>
                                                <img src={InfoIcon} alt='info' style={{ paddingLeft: '20px' }} />
                                                <Text mediumOrange sx={{ fontSize: '15px !important', paddingLeft: '10px' }}>Your subscription is cancelled. You will be able to use your account till {data.renews_on}.</Text>
                                            </Box>
                                            : ''
                                    }
                                    {
                                        data.going_to_expire && !mainAutoRenew ?
                                            <Box mt={1} sx={{ background: '#FFE1E2', width: '100%', height: '50px', borderRadius: '8px', padding: '15px 0px 15px 0px', textAlign: 'center', display: 'flex', flexDirection: 'row' }}>
                                                <img src={InfoIconRed} alt='info' style={{ paddingLeft: '20px' }} />
                                                <Text mediumOrange sx={{ fontSize: '15px !important', paddingLeft: '10px', color: "#ED2D46 !important" }}>Your Plan is going to expire on {moment(data.renews_on, dateFormat()).format(dateFormat())}.</Text>
                                            </Box>
                                            : ''
                                    }
                                </Grid>
                                <Grid container lg={12} md={12} sm={12} xs={12} alignItems={'center'}
                                    style={{
                                        // backgroundImage: "linear-gradient(135deg, #A15C47, #FFDDC4 )",
                                        // backgroundImage: "linear-gradient(135deg, #FFC803, #FFDCA7 )",
                                        backgroundImage: plan == 'starter' ? "linear-gradient(135deg, #0396FF, #ABDCFF )" : plan == 'advanced' ? "linear-gradient(135deg, #FFC803, #FFDCA7 )" : "linear-gradient(135deg, #A15C47, #FFDDC4 )",
                                        color: "#E8C5B3",
                                        height: '200px',
                                        width: '100%',
                                        borderRadius: '8px',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Grid container lg={12} md={12} sm={12} xs={12} alignItems={'center'} justifyContent={'space-between'}>
                                        <Box pl={5}>
                                            <Grid item container xs={12}>
                                                <Text largeWhite sx={{ fontSize: '18px !important' }}>{(data.slug == 'starter-monthly' || data.slug == 'advanced-monthly') ? 'Monthly' : (data.slug == 'starter-yearly' || data.slug == 'advanced-yearly') ? 'Yearly' : 'Free'}</Text>
                                            </Grid>
                                            <Grid item container xs={12}>
                                                <Text sx={{
                                                    fontFamily: 'Quicksand !important',
                                                    fontSize: '64px !important', color: '#FFFFFF !important',
                                                    fontWeight: `${600} !important`,
                                                    "@media (min-width: 1200px) and (max-width: 1420px)": {
                                                        fontSize: '44px !important'
                                                    }
                                                }}>${data.amount}
                                                </Text>
                                            </Grid>
                                            {
                                                plan == 'advanced' || plan == 'starter' ?
                                                    <Grid item container xs={12}>
                                                        <Text largeWhite sx={{ fontSize: '14px !important' }}>{mainAutoRenew ? 'Renews' : 'Ends'} on {data.renews_on}</Text>
                                                    </Grid> : ''
                                            }

                                        </Box>
                                        <Box display={'flex'} alignItems={'center'} pr={3}>
                                            <Text sx={{
                                                fontFamily: 'Quicksand !important',
                                                fontSize: '84px !important', color: '#fff2f2ad !important',
                                                fontWeight: `${600} !important`,
                                                "@media (min-width: 1200px) and (max-width: 1420px)": {
                                                    fontSize: '64px !important'
                                                }
                                            }}>{plan == 'starter' ? 'Starter' : plan == 'advanced' ? 'Advanced' : 'No Cost'}</Text>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {
                                freePlan.map((i) => (
                                    <Grid container lg={12} md={12} sm={12} xs={12} pt={2} pl={2} pr={2}>
                                        <Grid item lg={7} sm={6} pt={2}>
                                            <Stack direction={'row'} gap={3}>
                                                <CheckIcon sx={{ color: 'green !important', marginTop: '1px' }} />
                                                <Text grey18px>  {i.label} </Text>
                                            </Stack>

                                        </Grid>
                                        <Grid item lg={1} sm={1} pt={2} container justifyContent={'end'}>
                                            <Text greyLabel> - </Text>
                                        </Grid>
                                        <Grid item lg={4} sm={4} pt={2} container justifyContent={'end'}>
                                            <Text black18px> {i.value} </Text>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            {
                                LocalStorage.getUserData().super_admin && (plan == 'free') ?
                                    <Grid container lg={12} md={12} sm={12} xs={12} pt={4} >
                                        <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                            <Button fullWidth onClick={() => { navigate('/mivi-upgrade', { state: { renew: true, } }) }} sx={{ background: "#FFFFFF !important", color: "#404040  !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #737373', borderRadius: '8px' }}>Buy Credits</Button>
                                        </Grid>
                                        <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pl={1.5}>
                                            <Button fullWidth onClick={() => { navigate('/billing', { state: { renew: true, status: 'activeUpgrade', renewal: true, locationNavigate: 'myprofile', autoRenewStarter: true, autoRenewStarterMonthly: false } }) }} sx={{ background: "#0C75EB !important", color: "white !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', borderRadius: '8px' }}>Upgrade Plan</Button>
                                        </Grid>
                                    </Grid>

                                    :
                                    LocalStorage.getUserData().super_admin && (plan == 'starter') ?
                                        <Grid container lg={12} md={12} sm={12} xs={12} pt={4} >
                                            {data.is_subscription_cancelled ?
                                                <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                    <Button fullWidth onClick={() => { navigate('/billing', { state: { renew: true, status: 'renew', locationNavigate: 'myprofile', autoRenewStarter: true, autoRenewStarterMonthly: true } }) }} sx={{ background: "#FFFFFF !important", color: "#0C75EB !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #0C75EB', borderRadius: '8px' }}>Renew</Button>
                                                </Grid>
                                                :
                                                data.going_to_expire && !mainAutoRenew ?
                                                    <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                        <Button fullWidth onClick={() => {
                                                            navigate('/billing', {
                                                                state: {
                                                                    renew: true, status: 'activeUpgrade', renewal: true, locationNavigate: 'myprofile',
                                                                    autoRenewStarter: true, autoRenewStarterMonthly: true
                                                                }
                                                            })
                                                        }} sx={{ background: "#FFFFFF !important", color: "#0C75EB !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #0C75EB', borderRadius: '8px' }}>Renew</Button>
                                                    </Grid>
                                                    :
                                                    !mainAutoRenew ?
                                                        <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                            <Button fullWidth onClick={() => { navigate('/billing', { state: { renew: true, status: 'activeUpgrade', renewal: true, locationNavigate: 'myprofile', autoRenewStarter: true, autoRenewStarterMonthly: true } }) }} sx={{ background: "#FFFFFF !important", color: "#0C75EB !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #0C75EB', borderRadius: '8px' }}>Renew</Button>
                                                        </Grid>
                                                        :
                                                        mainAutoRenew ?
                                                            <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                                <Button fullWidth onClick={() => { navigate('/billing', { state: { renew: true, status: 'activeUpgrade', renewal: true, locationNavigate: 'myprofile', autoRenewStarter: !mainAutoRenew ? true : false, autoRenewStarterMonthly: false } }) }} sx={{ background: "#FFFFFF !important", color: "#0C75EB !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #0C75EB', borderRadius: '8px' }}>Renew</Button>
                                                            </Grid> :
                                                            <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                                <Button fullWidth onClick={() => { setclearPopup(true) }} sx={{ background: "#FFFFFF !important", color: "#F70D0D !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #F70D0D', borderRadius: '8px' }}>Cancel</Button>
                                                            </Grid>
                                            }
                                            <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pl={1.5}>
                                                <Button fullWidth onClick={() => { navigate('/billing', { state: { renew: true, status: data.is_subscription_cancelled ? 'upgrade' : 'activeUpgrade', plan_name: data.slug, locationNavigate: 'myprofile', autoRenewStarter: !mainAutoRenew ? true : false, autoRenewStarterMonthly: !mainAutoRenew ? true : false, renewal: !mainAutoRenew ? true : false } }) }} sx={{ background: "#0C75EB !important", color: "white !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', borderRadius: '8px' }}>Upgrade Plan</Button>
                                            </Grid>
                                            {
                                                mainAutoRenew &&
                                                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} pl={1.5} justifyContent={'center'} pt={2}>
                                                    <Button fullWidth onClick={() => { setclearPopup(true) }} sx={{ background: "FFFFFF !important", color: "#F70D0D !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', borderRadius: '8px' }}>Cancel</Button>
                                                </Grid>

                                            }
                                        </Grid>

                                        :
                                        LocalStorage.getUserData().super_admin ?
                                            <Grid container lg={12} md={12} sm={12} xs={12} pt={4} >
                                                {data.is_subscription_cancelled ?
                                                    data.slug == 'advanced-monthly' ?
                                                        <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pl={1.5}>
                                                            <Button fullWidth onClick={() => { navigate('/billing', { state: { renew: true, status: data.is_subscription_cancelled ? 'renew' : data.slug == 'advanced-monthly' ? 'advanceUpgrade' : 'advanceUpgrade', locationNavigate: 'myprofile' } }) }} sx={{ background: "#FFFFFF !important", color: "#0C75EB !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', borderRadius: '8px', border: '1px solid #0C75EB' }}>{data.is_subscription_cancelled ? 'Renew' : data.slug == 'advanced-monthly' ? "Upgrade Plan" : 'Renew'}</Button>
                                                        </Grid>
                                                        :
                                                        <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                            <Button fullWidth onClick={() => { navigate('/mivi-upgrade', { state: { renew: true, } }) }} sx={{ background: "#FFFFFF !important", color: "#404040  !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #737373', borderRadius: '8px' }}>Buy Credits</Button>
                                                        </Grid>
                                                    :
                                                    data.going_to_expire && !mainAutoRenew && data.slug == 'advanced-monthly' ?
                                                        <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                            <Button fullWidth onClick={() => { navigate('/billing', { state: { renew: true, status: 'renew', renewal: true, locationNavigate: 'myprofile', autoRenewStarterMonthly: true } }) }} sx={{ background: "#FFFFFF !important", color: "#0C75EB !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #0C75EB', borderRadius: '8px' }}>Renew</Button>
                                                        </Grid>
                                                        :
                                                        !mainAutoRenew && data.slug == 'advanced-monthly' ?
                                                            <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                                <Button fullWidth onClick={() => { navigate('/billing', { state: { renew: true, status: 'renew', renewal: true, locationNavigate: 'myprofile', autoRenewAdvance: false, autoRenewStarterMonthly: true } }) }} sx={{ background: "#FFFFFF !important", color: "#0C75EB !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #0C75EB', borderRadius: '8px' }}>Renew</Button>
                                                            </Grid>
                                                            :
                                                            !mainAutoRenew ?
                                                                <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                                    <Button fullWidth onClick={() => { navigate('/mivi-upgrade', { state: { renew: true, } }) }} sx={{ background: "#FFFFFF !important", color: "#404040  !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #737373', borderRadius: '8px' }}>Buy Credits</Button>
                                                                </Grid>
                                                                :
                                                                mainAutoRenew && data.slug == 'advanced-monthly' ?
                                                                    <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                                        <Button fullWidth onClick={() => { navigate('/billing', { state: { renew: true, renewal: data.going_to_expire && !mainAutoRenew ? true : false, status: (!mainAutoRenew || data.is_subscription_cancelled || (data.going_to_expire && !mainAutoRenew)) ? 'renew' : data.slug == 'advanced-monthly' ? 'advanceUpgrade' : 'advanceUpgrade', locationNavigate: 'myprofile', autoRenew: (!mainAutoRenew && data.slug == 'advanced-monthly') ? true : false, autoRenewAdvance: true, autoRenewStarterMonthly: true } }) }} sx={{ background: "#FFFFFF !important", color: "#0C75EB !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #0C75EB', borderRadius: '8px' }}>Renew</Button>
                                                                    </Grid>
                                                                    :
                                                                    <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pr={1.5}>
                                                                        <Button fullWidth onClick={() => { navigate('/mivi-upgrade', { state: { renew: true, } }) }} sx={{ background: "#FFFFFF !important", color: "#404040  !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', border: '1px solid #737373', borderRadius: '8px' }}>Buy Credits</Button>
                                                                    </Grid>
                                                }
                                                <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} pl={1.5}>
                                                    <Button fullWidth onClick={() => { navigate('/billing', { state: { renew: true, renewal: data.going_to_expire && !mainAutoRenew ? true : false, status: (!mainAutoRenew || data.is_subscription_cancelled || (data.going_to_expire && !mainAutoRenew)) ? 'renew' : data.slug == 'advanced-monthly' ? 'advanceUpgrade' : 'advanceUpgrade', locationNavigate: 'myprofile', autoRenew: (!mainAutoRenew && data.slug == 'advanced-monthly') ? true : false, autoRenewStarterMonthly: true } }) }} sx={{ background: "#0C75EB !important", color: "white !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', borderRadius: '8px' }}>{(data.is_subscription_cancelled && data.slug != 'advanced-monthly') ? 'Renew' : data.slug == 'advanced-monthly' ? "Upgrade Plan" : 'Renew'}</Button>
                                                </Grid>
                                                {
                                                    mainAutoRenew &&
                                                    <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} pl={1.5} justifyContent={'center'} pt={2}>
                                                        <Button fullWidth onClick={() => { setclearPopup(true) }} sx={{ background: "FFFFFF !important", color: "#F70D0D !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '18px !important', fontWeight: '600 !important', borderRadius: '8px' }}>Cancel</Button>
                                                    </Grid>

                                                }
                                            </Grid>

                                            : ''

                            }

                            {/* <Grid container lg={12} md={12} sm={12} xs={12} pt={1}>
                            <Button onClick={cancelSubscription} fullWidth sx={{ color: "#737373 !important", textTransform: 'capitalize !important', fontSize: '14px !important', fontWeight: '400 !important', }}>Cancel Subscription</Button>
                        </Grid> */}
                        </Grid>
                }
            </Box>
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>
                            {
                                !mainAutoRenew && current == "ai" && moment(data?.ai?.plan_expires_on, dateFormat()).isAfter(data.renews_on) ?
                                    <>
                                        Canceling your AI Timesheet Plan, which expires on <span style={{ color: 'red' }}>{data?.ai?.plan_expires_on}</span>, will result in a loss of {moment(data?.ai?.plan_expires_on).diff(moment(data?.renews_on), 'days')} days of service.
                                    </>
                                    :
                                    `Your active subscription will be cancelled on`
                            }
                        </Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>
                            {
                                !mainAutoRenew && current == "ai" && moment(data?.ai?.plan_expires_on, dateFormat()).isAfter(data.renews_on) ?
                                    <>
                                        The system will stop services based on the Main Plan expiry date of <span style={{ color: 'red' }}>{data?.renews_on}</span>
                                    </>

                                    :
                                    <>
                                        <span style={{ color: 'red' }}>{current == "ai" ? data?.ai?.plan_expires_on : data.renews_on}</span>. You will be able to use the account till
                                    </>

                            }</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont> {
                                !mainAutoRenew && current == "ai" && moment(data?.ai?.plan_expires_on, dateFormat()).isAfter(data.renews_on) ? '' : 'expiry' }</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand sx={{ width: '140px !important' }} onClick={() => { cancelSubscription() }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Box >
    )
}

const BillingHistory = ({ state, setState, classes, setAvatarData, setStatus, status, current, setCurrent }) => {
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [cards, setCards] = useState([]);
    const [card, setCard] = useState('');
    const [clearPopup, setClearPopup] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    }

    // eslint-disable-next-line
    const navigate = useNavigate();

    useEffect(() => {
        getCardDetails()
    }, [])

    const getCardDetails = () => {
        setLoading(true);
        OrganizationDetailsApi.getCardDetails().then((response) => {
            if (response.data.statusCode == 1003) {
                setLoading(false);
                setCards(response.data.data)
            } else {
                setLoading(false);
                addErrorMsg(response.data.message);
            }
        });
    }

    const makePrimary = (card) => {
        setLoading(true);
        let data = {
            request_id: LocalStorage.uid(),
            id: card.id,
            customer_id: card.customer
        }
        OrganizationDetailsApi.makePrimaryCard(data).then((response) => {
            if (response.data.statusCode == 1003) {
                setLoading(false);
                getCardDetails();
                handleClose();
            } else {
                setLoading(false);
                addErrorMsg(response.data.message);
            }
        });
    }

    const deleteCard = (card) => {
        setClearPopup(false)
        setLoading(true);
        let data = {
            request_id: LocalStorage.uid(),
            id: card.id,
            customer_id: card.customer
        }
        OrganizationDetailsApi.deleteCard(data).then((response) => {
            if (response.data.statusCode == 1003) {
                setLoading(false);
                addSuccessMsg(response.data.message)
                getCardDetails();
            } else {
                setLoading(false);
                addErrorMsg(response.data.message);
            }
        });
    }

    const addNewCard = () => {
        setButtonLoader(true);
        let data = {
            request_id: LocalStorage.uid()
        }
        OrganizationDetailsApi.addNewCard(data).then((response) => {
            if (response.data.statusCode == 1003) {
                setButtonLoader(false);
                window.open(response.data.data.url);
            } else {
                setLoading(false);
                addErrorMsg(response.data.message);
            }
        });
    }

    return (
        <Box px={4} pt={3} pb={4} >
            <Grid container spacing={1} >
                <Grid item xs={10}>
                    <Stack direction={'row'}>
                        <img src={ArrowLeft} alt='ArrowLeft' style={{ cursor: 'pointer' }} onClick={() => { setStatus(''); setCurrent('main') }} />
                        <Text profileTitle sx={{ paddingLeft: '15px' }}>Billing information</Text>
                    </Stack>
                </Grid>
                <Grid item xs={9}>
                </Grid>
            </Grid>

            <Box pt={3}>
                {loading ? (
                    <Box>
                        <Grid container spacing={0}>
                            {
                                [1, 2, 3, 4, 5, 6].map(() => (
                                    <Grid lg={6} md={6} sm={12} xs={12}>
                                        <Box p={1}>
                                            <Skeleton variant="rounded" width={'100%'} height={'54px'} borderRadius={"10px"} />
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                ) : cards.length > 0 ?
                    <>
                        {
                            cards.map((item, key) => (
                                <Box p={1} pb={'4px'}>
                                    <Grid container lg={12} md={12} sm={12} xs={12} p={'2px'} justifyContent={'center'} alignContent={'center'} backgroundColor={'#FBFBFB'} borderRadius={"10px"}>
                                        <Grid item container lg={1} md={1} sm={12} xs={12} px={1} justifyContent={'left'} alignContent={'left'}>
                                            <Box p={1}>
                                                <img src={item.brand == 'visa' ? visa : item.brand == 'mastercard' ? master : item.brand == 'maestro' ? maestro : item.brand == 'paypal' ? paypall : item.brand == 'dinersclub' ? master : item.brand == 'discover' ? master : item.brand == 'jcb' ? master : item.brand == 'amex' ? amex : amex} alt='cardBrand' style={{ cursor: 'pointer' }} width={'41px'} height={'41px'} />
                                            </Box>
                                        </Grid>
                                        <Grid item container lg={10} md={10} sm={12} xs={12} justifyContent={'left'} pl={2} alignContent={'center'} pt={1}>
                                            <Text offBoardHeadFont sx={{ fontSize: "14px !important" }}>{capitalize(item.brand)} ending with {item.last4}</Text>
                                        </Grid>
                                        <Grid item container lg={1} md={1} sm={12} xs={12} justifyContent={'center'} alignContent={'center'}>
                                            {item.is_primary ?
                                                <Text primaryFlagFont mr={2} sx={{ fontSize: "13px !important", color: '#22C55E', backgroundColor: '#ECFFF3' }}>Primary</Text>
                                                :
                                                <img src={menuIcon} alt='menu' style={{ cursor: 'pointer' }} onClick={(e) => { setAnchorEl(e.currentTarget); setCard(item) }} />}
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                sx={{
                                                    '& .MuiPaper-root': {
                                                        border: '1px solid #EAECF0 !important',
                                                        width: '150px !important',
                                                        borderRadius: '8px !important',
                                                        //padding: '0px 2px 0px 0px !important',
                                                        boxShadow: '0px 2px 24px 0px #919EAB1F'
                                                    },
                                                }}
                                            >
                                                <MenuItem onClick={() => { makePrimary(card) }} sx={{
                                                    "&:hover": {
                                                        background: 'none !important'
                                                    }
                                                }}>
                                                    <Text mediumBlue sx={{ fontWeight: `${600} !important` }}>Make Primary</Text></MenuItem>
                                                <Divider />
                                                <MenuItem onClick={() => { setCard(card); setClearPopup(true); handleClose() }} sx={{
                                                    "&:hover": {
                                                        background: 'none !important'
                                                    }
                                                }}><Text smallBlackBold sx={{ fontWeight: `${600} !important` }}>Remove</Text></MenuItem>
                                            </Menu>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))
                        }
                        <Grid p={2} pl={1} pr={1}>
                            <LoaderButton lightBlue loading={buttonLoader} onClick={() => { addNewCard() }}>Add New Card</LoaderButton>
                        </Grid>
                    </>
                    : <Grid container lg={12} md={12} sm={12} xs={12} pt={2} justifyContent={'center'} alignContent={'center'}>
                        <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} justifyContent={'center'} alignContent={'center'}>
                            <img src={CardDetails} alt='empty' />
                        </Grid>
                        <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} justifyContent={'center'} alignContent={'center'}>
                            <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Uh..Ohh!!!</Text>
                        </Grid>
                        <Grid item container justifyContent={'center'} alignContent={'center'} color={'white'} fontFamily={'Quicksand !important'} fontWeight={'700 !important'}>
                            <Text offBoardBodyFont>No card details available. Save your card by clicking</Text>
                        </Grid>
                        <Grid item container justifyContent={'center'} alignContent={'center'} color={'white'} fontFamily={'Quicksand !important'} fontWeight={'700 !important'}>
                            <Text offBoardBodyFont>on the button below.</Text>
                        </Grid>
                        <Grid item container justifyContent={'center'} alignContent={'center'} pt={2} >
                            <Button sx={{ background: '#0C75EB', borderRadius: '8px', color: "White", font: '14px Quicksand !important', width: '160px', height: '40px', textTransform: 'capitalize', '&:hover': { color: '#FFFFFF', background: '#0C75EB', transform: 'scale3d(1.05,1.05,1)' } }} onClick={() => { addNewCard() }}>Add Card Details</Button>
                        </Grid>
                    </Grid>
                }
            </Box>
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setClearPopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>This card will be removed from your account</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>permanently.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setClearPopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { deleteCard(card) }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Box >
    )
}

const PurchaseHistory = ({ state, setState, classes, setAvatarData, setStatus, status }) => {
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [plan, setPlan] = useState('');
    const [year, setyear] = useState('2024');
    const [data, setData] = useState([])

    useEffect(() => {
        defaultYears();
        if (status == 'purchase') {
            getPurchaseHistory()
        }
        // eslint-disable-next-line
    }, [status, year])

    const getPurchaseHistory = () => {
        setLoading(true)
        let data = {
            request_id: LocalStorage.uid(),
            from_date: moment(year).startOf('year').format(dateFormat()),
            to_date: moment(year).endOf('year').format(dateFormat())
        }
        OrganizationDetailsApi.purchaseHistory(data, LocalStorage.getAccessToken()).then((response) => {
            if (response.data.statusCode == 1003) {
                setData(response.data.data);
                setLoading(false)
            } else {
                addErrorMsg(response.data.message);
                setLoading(false)
            }
        });
    }

    const [years, setYears] = useState([]);

    const defaultYears = () => {
        CommonApi.defaultYearsList().then((res) => {
            if (res.data.statusCode == 1003) {
                setYears(res.data.data);
            }
        })
    }

    const downloadPdf = (args) => {
        const newWindow = window.open(args, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
        // let data = {
        //     id: id,
        //     request_id: LocalStorage.uid()
        // }
        // OrganizationDetailsApi.downloadExport(data).then((res) => {
        //     if (res.data.statusCode == 1003) {
        //         FileSaver.saveAs(res.data.data.pdf_link);
        //     } else {
        //         addErrorMsg(res.data.message);
        //     }
        // })
    }

    return (
        <Box px={4} pt={3} pb={4} pl={0.4} >
            <Grid container spacing={1} alignItems='center'>
                <Grid item xs={7}>
                    <Stack direction={'row'}>
                        <img src={ArrowLeft} alt='ArrowLeft' style={{ cursor: 'pointer' }} onClick={() => { setStatus('') }} />
                        <Text profileTitle sx={{ paddingLeft: '15px' }}>View Purchasing History</Text>
                    </Stack>
                </Grid>
                <Grid item xs={5} container justifyContent={'end'}>
                    <CustomSelect
                        name='year'
                        viewDrop
                        value={year}
                        scrollTrue={true}
                        options={years}
                        onChange={(e) => { setyear(e.target.value) }}
                    />
                </Grid>
            </Grid>

            <Box pt={3}>
                {loading ? (
                    <Box>
                        <Grid container spacing={0}>
                            {
                                [1, 2, 3, 4, 5, 6].map(() => (
                                    <Grid lg={6} md={6} sm={12} xs={12}>
                                        <Box p={1}>
                                            <Skeleton variant="rounded" width={'100%'} height={'54px'} borderRadius={"10px"} />
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                )
                    :
                    data.length == 0 ?
                        <Grid container lg={12} md={12} sm={12} xs={12} pt={2} justifyContent={'center'} alignContent={'center'}>
                            <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} justifyContent={'center'} alignContent={'center'}>
                                <img src={purchaseEmpty} alt='img' />
                            </Grid>
                            <Grid item container lg={12} md={12} sm={12} xs={12} pt={4} justifyContent={'center'} alignContent={'center'}>
                                <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Uh...Oh!</Text>
                            </Grid>
                            <Grid item container lg={12} md={12} sm={12} xs={12} pt={1} justifyContent={'center'} alignContent={'center'}>
                                <Text offBoardBodyFont >It looks like there’s no purchase history for the past 12 months.</Text>
                            </Grid>
                        </Grid>
                        :
                        <Grid container lg={12} md={12} sm={12} xs={12}>
                            <Grid container item xs={12} spacing={2} pt={4}>
                                <Grid item xs={12} sm={4} md={1.5} textAlign="center">
                                    <Text greyLabel sx={{ color: '#000000', fontSize: '12px' }}>Order ID</Text>
                                </Grid>
                                <Grid item xs={12} sm={4} md={2} textAlign="center">
                                    <Text greyLabel sx={{ color: '#000000', fontSize: '12px' }}>Purchase Date</Text>
                                </Grid>
                                <Grid item xs={6} sm={3} md={1.2} textAlign="center">
                                    <Text greyLabel sx={{ color: '#000000', fontSize: '12px' }}>Valid From</Text>
                                </Grid>
                                <Grid item xs={6} sm={3} md={1.2} textAlign="center">
                                    <Text greyLabel sx={{ color: '#000000', fontSize: '12px' }}>Valid Till</Text>
                                </Grid>
                                <Grid item xs={12} sm={4} md={2} textAlign="center">
                                    <Text greyLabel sx={{ color: '#000000', fontSize: '12px' }}>Item Name</Text>
                                </Grid>
                                <Grid item xs={6} sm={3} md={1} textAlign="center">
                                    <Text greyLabel sx={{ color: '#000000', fontSize: '12px' }}>Cost</Text>
                                </Grid>
                                <Grid item xs={6} sm={4} md={1.8} textAlign="center">
                                    <Text greyLabel sx={{ color: '#000000', fontSize: '12px' }}>Transaction Status</Text>
                                </Grid>
                                <Grid item xs={12} sm={4} md={1} textAlign="center">
                                    <Text greyLabel sx={{ color: '#000000', fontSize: '12px' }}>Invoice/Receipt</Text>
                                </Grid>
                            </Grid>
                            <Grid container lg={12} md={12} sm={12} xs={12} pt={3}>
                                {
                                    data.map((i, index) => (
                                        <Box display='flex' flexDirection='row' gap={2} justifyContent='space-evenly' width='100%' sx={{
                                            background: index % 2 === 0 ? '#F6FBFF' : '',
                                            padding: '16px 0px',
                                            margin: '4px 0px',
                                            borderRadius: '8px',
                                            textAlign: 'start'
                                        }}>
                                            <Grid item lg={1.5} md={1.5} textAlign='center'>
                                                <Text greyLabel> {i.order_number} </Text>
                                            </Grid>
                                            <Grid item lg={2} md={2} textAlign='center'>
                                                <Text greyLabel> {i.plan_purchase_date} </Text>
                                            </Grid>
                                            <Grid item lg={1} md={1.7} textAlign='center'>
                                                <Text greyLabel> {i.plan_started_at == '' ? 'N/A' : i.plan_started_at} </Text>
                                            </Grid>
                                            <Grid item lg={1} md={1.7} textAlign='center'>
                                                <Text greyLabel> {i.plan_expired_on == '' ? 'N/A' : i.plan_expired_on} </Text>
                                            </Grid>
                                            <Grid item lg={2} md={2} textAlign='center'>
                                                <Text greyLabel> {capitalizeAndAddSpace(i.plan)} </Text>
                                            </Grid>
                                            <Grid item lg={1} md={1} textAlign='center'>
                                                <Text greyLabel> {LocalStorage.getCurrencySymbol()}{i.plan_amount} </Text>
                                            </Grid>
                                            <Grid item lg={1.5} md={2} textAlign='center'>
                                                <Box sx={{
                                                    background: `${i.payment_intent_status == 'Success' ? '#ECFFF3' : i.payment_intent_status == 'Fail' ? '#FFECEC' : '#FFFAE7'}`, borderRadius: '31px', textAlign: 'center', alignItems: 'center',
                                                    color: `${i.payment_intent_status == 'Success' ? '#22C55E' : i.payment_intent_status == 'Fail' ? '#F70D0D' : '#F59E0B'}`, padding: '4px 15px', minWidth: '60px', height: '22px', font: '12px Quicksand', fontWeight: `${600} !important`
                                                }}>
                                                    {i.payment_intent_status == 'Success' ? 'Success' : i.payment_intent_status == 'Fail' ? 'Failed' : 'Processing'}
                                                </Box>
                                            </Grid>
                                            <Grid item lg={1.3} md={1.3} textAlign='center'>
                                                <Text mediumBlue onClick={() => downloadPdf(i.receipt_url)} sx={{ cursor: 'pointer' }}> View </Text>
                                            </Grid>
                                        </Box>
                                    ))
                                }
                            </Grid>
                        </Grid>
                }
            </Box>
        </Box >
    )
}

const Transfer = ({ state, setState, classes, setAvatarData, setStatus, status }) => {
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [plan, setPlan] = useState('internal');
    const navigate = useNavigate()
    const [error, setError] = useState({});
    const handleChange = (e) => {
        if (e.target.name == 'contact_number') {
            convertFormat(e);
        }
        else if (e.target.name == 'first_name' || e.target.name == 'last_name' || e.target.name == 'middle_name') {
            setState({
                ...state,
                [e.target.name]: capitalize(e.target.value)
            }, handleValidations(e))
        }
        else {
            setState({ ...state, [e.target.name]: e.target.value })
            handleValidations(e);
        }
    };
    const convertFormat = (e) => {
        const value = e.target.value;
        const name = e.target.name
        const input = value.replace(/\D/g, '').substring(0, 10);

        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'contact_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'contact_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'contact_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > (name == 'contact_number' ? 6 : 5)) {
            setState(
                {
                    ...state,
                    [e.target.name]: `${first}-${middle}-${last}`
                }, handleValidations(e));
        }
        else if (input.length > 3) {
            setState(
                {
                    ...state,
                    [e.target.name]: `${first}-${middle}`
                }, handleValidations(e));
        }
        else if (input.length >= 0) {
            setState(
                {
                    ...state,
                    [e.target.name]: input
                }, handleValidations(e));
        }
    }
    const handleValidations = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "first_name":
                error.first_name = validate_charWithSpace(input.value, 'first ');
                break;
            case "transfer_employee_id":
                error.transfer_employee_id = validate_emptyField(input.value)
                break
            case "middle_name":
                error.middle_name = empty_name(input.value, 'middle ');
                break;
            case "last_name":
                error.last_name = validate_charWithSpace(input.value, 'last ');
                break;
            case 'gender':
                error.gender = validate_emptyField(input.value)
                break
            case 'email_id':
                if ((input.value != "" || input.value != null)) {
                    error.email_id = validates_emailId(input.value)
                } else {
                    error.email_id = "Please enter Email ID"
                }
                break
            case 'contact_number':
                if ((input.value != "" || input.value != null) && input.value.length == 12) {
                    error.contact_number = validate_usContact(input.value, 'Mobile Number')
                } else {
                    error.contact_number = "Please enter Mobile Number"
                }
                break
            default:
                break;
        }
        setError(error);
    }
    const [clearPopup, setclearPopup] = useState(false);
    const [clearPopupOwner, setclearPopupOwner] = useState(false);
    const [employeeType, setemployeeType] = useState([])
    useEffect(() => {
        CommonApi.EmployeeDetailsdropdownTransfer(LocalStorage.uid(), 1, LocalStorage.getAccessToken()).then(
            (response) => {
                if (response.data.statusCode == 1003) {
                    setemployeeType(response.data.data)
                }
            }
        )
    }, [])
    // eslint-disable-next-line
    const sendRequest = () => {
        let data = state
        if (data.transfer_employee_id == '') {
            data.transfer_type = '2'
        }
        else {
            data.transfer_type = '1'
        }
        data.request_id = LocalStorage.uid()
        UserProfileApi.transferOwnership(data).then(
            (response) => {
                if (response.data.statusCode == 1003) {
                    setclearPopupOwner(true);
                    setclearPopup(false);
                }
                else {
                    addErrorMsg(response.data.message)
                    setclearPopup(false)
                }
            }
        )
    }

    const basicDetailsError = () => {
        const { first_name, last_name, gender, email_id, contact_number } = state
        error.first_name = validate_charWithSpace(first_name, 'first ')
        error.last_name = validate_charWithSpace(last_name, 'last ')
        error.gender = validate_emptyField(gender);
        error.email_id = error.email_id == 'Email ID already exists' ? 'Email ID already exists' : validates_emailId(email_id);
        error.contact_number = error.contact_number == 'Mobile Number already exists' ? 'Mobile Number already exists' : validate_usContact(contact_number, 'Contact Number');
        return setError({ ...error })
    }

    const checkValidation = () => {
        basicDetailsError()
        if (isValid(error)) {
            setclearPopup(true)
        }
    }

    const emptyStates = () => {
        setState({
            first_name: "",
            middle_name: "",
            transfer_type: '',
            transfer_employee_id: '',
            last_name: "",
            email_id: "",
            contact_number: "",
            alternate_contact_number: "",
            reference_id: "",
            profile_picture_url: "",
            documents: [
                {
                    new_document_id: "",
                    document_url: "",
                }
            ],
        })
        setError({})
    }

    const getName = () => {
        let a = (state.transfer_employee_id != '' && employeeType.length > 0) && employeeType.find((i) => i.id == state.transfer_employee_id).value
        return a
    }

    return (
        <Box px={4} pt={3} pb={4} >
            <Grid container spacing={1} >
                <Grid item xs={1}>
                    <img src={ArrowLeft} alt='ArrowLeft' style={{ cursor: 'pointer' }} onClick={() => { setStatus('') }} />
                </Grid>
                <Grid item xs={9}>
                    <Text profileTitle>Transfer Ownership To</Text>
                </Grid>

            </Grid>

            <Box pt={3}>
                {loading ? (
                    <Box>
                        <Grid container spacing={0}>
                            {
                                [1, 2, 3, 4, 5, 6].map(() => (
                                    <Grid lg={6} md={6} sm={12} xs={12}>
                                        <Box p={1}>
                                            <Skeleton variant="rounded" width={'100%'} height={'54px'} borderRadius={"10px"} />
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                ) :
                    <Grid container lg={12} md={12} sm={12} xs={12} pl={4} >
                        <Grid item lg={12}>
                            <Box className={classes.tabBg}>
                                <Box className={plan == 'internal' ? classes.ActiveBg : classes.inactiveBg} onClick={() => { setPlan('internal'); emptyStates() }} >
                                    {
                                        plan == 'internal' ?
                                            <Text largeWhite400>Internal Employee</Text> :
                                            <Text blackHeader>Internal Employee</Text>
                                    }
                                </Box>
                                <Box className={plan == 'new' ? classes.ActiveBg : classes.inactiveBg} onClick={() => { setPlan('new'); emptyStates() }}>
                                    {
                                        plan == 'new' ?
                                            <Text largeWhite400>New User</Text> :
                                            <Text blackHeader>New User</Text>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                        {
                            plan == 'internal' ?
                                <Grid item container lg={12}>
                                    <Grid item xs={12}>
                                        <Box p={1} pt={3}>
                                            <Select
                                                name='transfer_employee_id'
                                                options={employeeType}
                                                value={state.transfer_employee_id}
                                                label={'Internal Employee'}
                                                onChange={handleChange}
                                                error={error.transfer_employee_id}
                                            />
                                            <Text errorText> {error.transfer_employee_id ? error.transfer_employee_id : ""}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} container justifyContent={'flex-end'}>
                                        <Stack direction={'row'} spacing={2} p={1}>
                                            <Button blackCancel100 onClick={() => { setStatus('') }}>Cancel</Button>
                                            <Button smallBlue sx={{ width: '100% !important' }} onClick={() => { if (state.transfer_employee_id == '') { setError({ ...error, transfer_employee_id: 'This field is required' }) } else { setclearPopup(true) } }}>Transfer</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                :
                                <Grid item container lg={12} spacing={2} pt={4}>
                                    <Grid item lg={6} md={6} sm={6} xs={12} >
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'first_name',
                                                value: state.first_name,
                                                type: 'text',
                                                inputProps: { maxLength: 33 }
                                            }}
                                            handleChange={handleChange}
                                            onKeyPress={onCharactersOnlyChange}
                                            clientInput
                                            labelText={'First Name'}
                                            error={error.first_name}
                                        />
                                        <Text errorText> {error.first_name ? error.first_name : ""}</Text>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12} >
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'middle_name',
                                                value: state.middle_name,
                                                type: 'text',
                                                inputProps: { maxLength: 33 }
                                            }}
                                            handleChange={handleChange}
                                            onKeyPress={onCharactersOnlyChange}
                                            clientInput
                                            labelText={'Middle Name (Optional)'}
                                            error={error.middle_name}
                                        />
                                        <Text errorText> {error.middle_name ? error.middle_name : ""}</Text>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12} >
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'last_name',
                                                value: state.last_name,
                                                type: 'text',
                                                inputProps: { maxLength: 33 }
                                            }}
                                            handleChange={handleChange}
                                            onKeyPress={onCharactersOnlyChange}
                                            clientInput
                                            labelText={'Last Name'}
                                            error={error.last_name}
                                        />
                                        <Text errorText> {error.last_name ? error.last_name : ""}</Text>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Select
                                            name='gender'
                                            value={state.gender}
                                            commonSelect
                                            label={'Gender'}
                                            options={genderOptions}
                                            onChange={handleChange}
                                            error={error.gender}
                                        />
                                        <Text errorText> {error.gender ? error.gender : ""}</Text>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12} >
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'email_id',
                                                value: state.email_id,
                                                type: 'text',
                                                inputProps: { maxLength: 33 }
                                            }}
                                            handleChange={handleChange}
                                            clientInput
                                            labelText={'Email ID'}
                                            error={error.email_id}
                                        />
                                        <Text errorText> {error.email_id ? error.email_id : ""}</Text>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12} >
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'contact_number',
                                                value: state.contact_number,
                                                type: 'text',
                                                inputProps: { maxLength: 12 }
                                            }}
                                            handleChange={handleChange}
                                            clientInput
                                            labelText={'Mobile Number'}
                                            error={error.contact_number}
                                        />
                                        <Text errorText> {error.contact_number ? error.contact_number : ""}</Text>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent={'flex-end'} pt={2}>
                                        <Stack direction={'row'} gap={2} pt={3}>
                                            <Button smallBlackOutline onClick={() => { setStatus('') }} >Cancel</Button>
                                            <Button blueBtnSave onClick={() => { checkValidation() }} >Send Invite</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                        }
                    </Grid>
                }
            </Box>
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>By transferring ownership, you cannot access this</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>account when the new owner signs in.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand sx={{ width: '140px !important' }} onClick={() => { sendRequest() }} >
                                Yes, Transfer
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopupOwner} setOpenPopup={setclearPopupOwner} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={ownerShip} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Hurray!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Invite has been sent to {plan == 'internal' ? getName() : state.email_id}</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont >to claim account ownership</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button finishFilledQuicksandLargeWidth sx={{ width: '140px !important' }} onClick={() => { navigate('/dashboard') }} >
                                Go to Home
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Box >
    )
}


const MyProfile = (props) => {
    const classes = MyProfileStyles();
    const location = useLocation()
    const [status, setStatus] = useState('');
    // const plan = 1
    const [users, setUsers] = useState({
        "userData": {
            "current_count": "",
            "max_count": "",
            "profile_avatars": [

            ]
        },
        "employeeData": {
            "current_count": "",
            "max_count": "",
            "profile_avatars": [

            ]
        }
    });
    const [organisationstate, setorganisationstate] = useState({
        id: "",
        organization_name: "",
        address_line_1: "",
        address_line_2: "",
        state_id: "",
        country_id: "",
        state: "",
        country: "",
        city: "",
        zip_code: "",
        mobile_number: "",
        date_format: "",
        currency_type: "",
        currency_symbol: "",
        website_url: "",
        email_id: "",
        logo_name: "",
        logo_url: "",
        associated_app_names: "",
        notify_university_usics: "",
        enable_delete_email: "",
        enable_settlement_amount: "",
        created_by: "",
        updated_by: ""
    });
    const [state, setState] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        email_id: "",
        contact_number: "",
        alternate_contact_number: "",
        reference_id: "",
        profile_picture_url: "",
        documents: [
            {
                new_document_id: "",
                document_url: "",
            }
        ],
        gender: ''
    });// eslint-disable-next-line
    const [transferState, settransferState] = useState({
        first_name: "",
        middle_name: "",
        transfer_type: '',
        transfer_employee_id: '',
        last_name: "",
        email_id: "",
        contact_number: "",
        alternate_contact_number: "",
        reference_id: "",
        profile_picture_url: "",
        documents: [
            {
                new_document_id: "",
                document_url: "",
            }
        ],
        gender: ''
    });// eslint-disable-next-line
    const [avatarData, setAvatarData] = useState("");
    const navigate = useNavigate();


    const PasswordField = (name, value, onChange, placeholder) => {
        const [showPassword, setShowPassword] = useState({
            old_password: false,
            password: false,
            confirm_password: false
        })

        const handlePasswordVisibility = (field, event) => {
            event.preventDefault();
            setShowPassword({ ...showPassword, [field]: !showPassword[field] });
        };

        return (
            <FormControl fullWidth
                className={classes.endAdornmentControl}
            >
                <TextField
                    className={classes.endAdornmentInput}
                    onFocus='false'
                    placeholder={placeholder}
                    margin="dense"
                    type={showPassword["old_password"] ? "text" : "password"}
                    value={value}
                    onChange={onChange}
                    name={name}
                    autoComplete='false'
                    InputProps={{
                        disableUnderline: true,
                        endAdornment:
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={(event) => handlePasswordVisibility("old_password", event)}
                                    disableRipple
                                >
                                    {
                                        showPassword["old_password"] ? <img src={visible} alt='Visible' className={classes.visiblityBtn} /> : <img src={invisible} alt='invisible' className={classes.visiblityBtn} />
                                    }
                                </IconButton>
                            </InputAdornment>

                    }}
                />

            </FormControl>
        )
    }
    // eslint-disable-next-line
    const ChangePassword = () => {
        const [formData, setFormData] = useState({
            old_password: '',
            password: '',
            confirm_password: ''
        })

        const [error, setError] = useState({});

        const handleInputChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
            handlePasswordValidations(e);
        }

        const handlePasswordValidations = (e) => {
            let input = e.target;
            let err = error;
            switch (input.name) {
                case "old_password":
                    err.old_password = validates_password(input.value);
                    break;
                case "password":
                    err.password = validates_password(input.value);
                    break;
                case "confirm_password":
                    err.confirm_password = validates_password(input.value);
                    break;
                default:
                    break;
            }
            setError(err);
        }

        const validateAll = () => {
            let { old_password, password, confirm_password } = formData;
            let errors = {};
            errors.old_password = validates_password(old_password);
            errors.password = validates_password(password);
            errors.confirm_password = validates_password(confirm_password);
            return errors;
        }


        const changePassword = () => {
            let data = { ...formData, request_id: LocalStorage.uid(), };
            UserProfileApi.changePassword(data).then((response) => {
                if (response.data.statusCode == 1003) {
                    addSuccessMsg(response.data.message);
                } else {
                    addErrorMsg(response.data.message);
                }
            })
        }

        const handleSubmitPassord = () => {
            let errors = validateAll();
            if (isValid(errors)) {
                changePassword();
            } else {
                // 
                setError(errors);
            }
        }

        return (
            <Box px={4} py={3.5} style={{ width: "100%", minHeight: "auto", boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                <Box>
                    <Typography className={classes.myDetails}>Change Password</Typography>
                </Box>
                <Box my={2}>
                    <Grid container>
                        <Grid item lg={12} sm={12} xs={12}>
                            <Box display={'flex'} flexDirection={'column'} gap={1}>
                                {PasswordField("old_password", formData.old_password, handleInputChange, "Current Password")}
                                <Text errorText>{error.old_password ? error.old_password : ""}</Text>

                                {PasswordField("password", formData.password, handleInputChange, "New Password")}
                                <Text errorText>{error.password ? error.password : ""}</Text>

                                {PasswordField("confirm_password", formData.confirm_password, handleInputChange, "Confirm Password")}
                                <Text errorText>{error.confirm_password ? error.confirm_password : ""}</Text>
                                <Box>
                                    <Button
                                        disableRipple
                                        blueBtnSave
                                        fullWidth
                                        className={classes.saveBtn}
                                        onClick={handleSubmitPassord}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box py={3}>
                        <Divider />
                    </Box>
                    <Box>
                        <Typography className={classes.myDetails} sx={{ color: "#111827 !important", fontWeight: "500 !important" }}>Password Requirements :</Typography>
                        <List
                            sx={{ listStyleType: 'disc' }}
                        >
                            <ListItem sx={{ px: "3px", py: "5px" }}>
                                <ListItemIcon sx={{ minWidth: "20px" }}>
                                    <FiberManualRecord sx={{ color: '#4A4A4A', width: "10px", right: "0px" }} />
                                </ListItemIcon>
                                <Typography className={classes.myDetails} sx={{ color: "#4A4A4A !important" }}>8 or more characters</Typography>
                            </ListItem>
                            <ListItem sx={{ px: "3px", py: "5px" }}>
                                <ListItemIcon sx={{ minWidth: "20px" }}>
                                    <FiberManualRecord sx={{ color: '#4A4A4A', width: "10px", right: "0px" }} />
                                </ListItemIcon>
                                <Typography className={classes.myDetails} sx={{ color: "#4A4A4A !important" }}>At least one upper case</Typography>
                            </ListItem>
                            <ListItem sx={{ px: "3px", py: "5px" }}>
                                <ListItemIcon sx={{ minWidth: "20px" }}>
                                    <FiberManualRecord sx={{ color: '#4A4A4A', width: "10px", right: "0px" }} />
                                </ListItemIcon>
                                <Typography className={classes.myDetails} sx={{ color: "#4A4A4A !important" }}>At least one lower case</Typography>
                            </ListItem>
                            <ListItem sx={{ px: "3px", py: "5px" }}>
                                <ListItemIcon sx={{ minWidth: "20px" }}>
                                    <FiberManualRecord sx={{ color: '#4A4A4A', width: "10px", right: "0px" }} />
                                </ListItemIcon>
                                <Typography className={classes.myDetails} sx={{ color: "#4A4A4A !important" }}>At least one number</Typography>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Box >
        )
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleBackNavigation = () => {
        navigate("/dashboard")
    }

    const handleFileChange = (e) => {
        addLoader(true);
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg'].includes(r))) {
            if (Number(((e.target.files[0].size / (1024 * 1024)).toFixed(2))) < 25) {
                const formData = new FormData();
                formData.append("files", e.target.files[0]);
                formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                CommonApi.documentUpload("employee-profile", formData, LocalStorage.getAccessToken())
                    .then((response) => {
                        if (response.data.statusCode == 1003) {
                            removeLoader()
                            let docInfo = response.data.data;
                            let docArr = [{
                                new_document_id: docInfo.id,
                            }]
                            setState({ ...state, profile_picture_url: docInfo.document_url });
                            profileUpload({ request_id: LocalStorage.uid(), documents: docArr });
                            props.setprofile_url(docInfo.document_url);
                            let updatedUserData = LocalStorage.getUserData();
                            updatedUserData.profile_picture_url = docInfo.document_url
                            LocalStorage.setUserData(updatedUserData);
                        } else {
                            removeLoader()
                            addErrorMsg(response.data.message);
                        }
                    })
            } else {
                removeLoader()
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            removeLoader()
            addErrorMsg("Please upload files in PNG or JPG format only.");
        }
    }

    const profileUpload = (data) => {
        let id = LocalStorage.getUserData().login_id
        UserProfileApi.updateUserProfile(data, id).then((response) => {
            if (response.data.statusCode == 1003) {
                addSuccessMsg(response.data.message);
            } else {
                addErrorMsg(response.data.message);
            }
        })
    }
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [apps, setApps] = useState([]);
    const [appLoading, setAppLoading] = useState(false);
    const URL = new URLSearchParams(window.location.search); // eslint-disable-next-line
    const [planLoading, setPlanLoading] = useState(false);
    const param = URL.get('managesubscribtion');// eslint-disable-next-line
    const [creditDetails, setCreditDetails] = useState({
        ai_timesheet_access_available_credits: '',
        plan_expires_on: '',
        ai_ts_bundle_sub_end_on: '',
        ai_ts_bundle_sub_status: '',
        ai_ts_payment_slug: '',
        ai_ts_bundle_sub_expired_on: '',
        ai_ts_bundle_sub_expiring_on: ''
    });

    const getPlanCheck = () => {
        addLoader(true);
        setPlanLoading(true);
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                removeLoader();
                setPlanLoading(false);
                setCreditDetails(res.data);
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
            } else {
                setPlanLoading(false);
                removeLoader();
            }
        })
    }

    useEffect(() => {
        if (location.state?.redirection) {
            setStatus('manage')
        }
        getUserProfileDetails();
        getMyUsersList()
        getOrganizationDetails();
        if (LocalStorage.getUserData().super_admin) {
            getAllApps();
        }
        if (LocalStorage.getRedirectedModule() == 'myprofile > manage subscription' || (param !== null && param)) {
            setStatus('manage');
        }
        getPlanCheck();
        // eslint-disable-next-line
    }, []);

    const getAllApps = () => {
        setAppLoading(true);
        InstalledAppsApi.getApps('connected').then((res) => {
            setAppLoading(false);
            if (res.data.statusCode === 1003) {
                setApps(res.data.data);
            }
        })
    }

    const getUserProfileDetails = () => {
        setLoading(true);
        UserProfileApi.getUserProfileDetails().then((response) => {
            setTimeout(() => {
                setLoading(false);
                if (response.data.statusCode == 1003) {
                    setState(response.data.data);
                    // setState({ ...state, ...response.data.data});
                    const name = response.data.data.first_name + " " + response.data.data.last_name
                    setAvatarData(name);
                    const localUserData = LocalStorage.getUserData();
                    const updatedUserData = { ...localUserData, full_name: response.data.data.display_name, profile_picture_url: response.data.data.profile_picture_url }
                    LocalStorage.setUserData(updatedUserData);
                    props.setprofile_url(response.data.data.profile_picture_url);
                    navigate('/myprofile');
                } else {
                    addErrorMsg(response.data.message);
                }
            }, 400)
        });
    }
    // eslint-disable-next-line
    const getMyUsersList = () => {
        setLoading(true);
        UserProfileApi.getMyusers().then((response) => {
            setTimeout(() => {
                setLoading(false);
                if (response.data.statusCode == 1003) {
                    setUsers(response.data.data)
                } else {
                    addErrorMsg(response.data.message);
                }
            }, 400)
        });
    }

    const getOrganizationDetails = () => {
        setLoading(true);
        OrganizationDetailsApi.getOrganizationDetails(LocalStorage.uid(), LocalStorage.getAccessToken()).then((response) => {
            setLoading(false);
            if (response.data.statusCode == 1003) {
                let dataOrganization = response.data.data[0]
                dataOrganization.organization_name = Buffer.from(response.data.data[0].organization_name, 'base64').toString('utf-8')
                setorganisationstate(dataOrganization)
            } else {
                if (response.data.message == `You don't have access to perform this action. Please contact admin`) {

                } else {
                    addErrorMsg(response.data.message);
                }
            }
        });
    }

    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    const [current, setCurrent] = useState('main')

    return (
        <Box className={classes.mainContainer} px={4} py={1}>
            <Box mx={12} display={'flex'} alignItems={'center'} gap={1}>
                <IconButton disableRipple onClick={handleBackNavigation}>
                    <img src={ArrowLeft} alt='ArrowLeft'></img>
                </IconButton>
                <Text blackHeader600 >My Profile</Text>
            </Box>

            <Box mx={{ lg: 12, md: 12, sm: 12, xs: 4 }} ml={{ xs: 15 }} mt={0}>
                <Grid container spacing={4}>
                    <Grid item lg={2}></Grid>
                    <Grid item lg={8} md={12} sm={12} xs={12}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box px={4} py={2} display={'flex'} alignItems={"center"} flexDirection={'column'} style={{ width: '100%', minHeight: "auto" }}>
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <Box mt={2} width={"100px"} height={"100px"} position={'relative'}>
                                        <Avatar
                                            src={state.profile_picture_url}
                                            alt={state.display_name ? capitalizeAndAddSpace(state.display_name[0]) : ''}
                                            className={classes.avatar}
                                        />
                                        <Box
                                            position={'absolute'}
                                            top={"0px"}
                                            textAlign={'center'}
                                            className={classes.uploadImage}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                        >
                                            <MuiButton component="label" sx={{ textTransform: "none" }} disableRipple onChange={handleFileChange}>
                                                <Typography className={classes.uploadImageText}>
                                                    <img src={upload} alt='upload' />
                                                    <br />
                                                    Upload
                                                    <br />
                                                    Image
                                                </Typography>
                                                <VisuallyHiddenInput type="file" />
                                            </MuiButton>
                                        </Box>
                                    </Box>
                                    <Box mt={1}>
                                        <Text largeLabel700 sx={{ color: "#262626 !important" }}>
                                            {state && state.display_name ? (
                                                state.display_name.length > 20 ? (
                                                    <BlackToolTip title={state.display_name} placement="top" arrow>
                                                        {state.display_name.slice(0, 20) + "..."}
                                                    </BlackToolTip>
                                                ) : (
                                                    state.display_name
                                                )
                                            ) : (
                                                "-"
                                            )}
                                        </Text>

                                    </Box>
                                    <Box mt={0.2}>
                                        <Text smallGrey sx={{ fontWeight: "600 !important" }}>{state.role_name}</Text>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        {
                            status == 'manage'
                                ?
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box px={1} py={2} display={'flex'} alignItems={"center"} sx={{ width: '100% !important' }}>
                                        <ButtonGroup
                                            variant="outlined"
                                            fullWidth
                                            aria-label="outlined button group"
                                        >
                                            <Button
                                                sx={{
                                                    font: "15px Quicksand",
                                                    borderRadius: '8px 0px 0px 8px',
                                                    textTransform: 'capitalize ',
                                                    fontWeight: "700",
                                                    padding: "9px 15px 9px 15px",
                                                    borderRight: `${current === "main" ? 'none' : ''}`,
                                                    borderColor: `${current === "main" ? "#0C75EB" : "#E2E5E6"} `,
                                                    background: `${current === "main" ? "#0C75EB" : "#F9FAFA"} `,
                                                    color: `${current === "main" ? "#ffffff" : "#849199"} `,
                                                    "&:hover": { borderColor: `${current === "main" ? "#0C75EB" : "#E2E5E6"} `, },
                                                    cursor: 'pointer'
                                                }}
                                                variant={`${current === "main" ? "contained" : "outlined"}`}
                                                onClick={() => { setCurrent('main') }}
                                            >
                                                Main Plan
                                            </Button>
                                            <Button
                                                sx={{
                                                    font: "15px Quicksand ",
                                                    textTransform: 'capitalize ',
                                                    borderRadius: '8px 8px 8px 0px',
                                                    fontWeight: "700",
                                                    padding: "9px 15px 9px 15px",
                                                    borderLeft: `${current === "ai" ? 'none' : ''}`,
                                                    borderColor: `${current === "ai" ? "#0C75EB" : "#E2E5E6"} `,
                                                    background: `${current === "ai" ? "#0C75EB" : "#F9FAFA"} `,
                                                    color: `${current === "ai" ? "#ffffff" : "#849199"}`,
                                                    cursor: 'pointer',
                                                    "&:hover": {
                                                        borderColor: `${current === "ai" ? "#0C75EB" : "#E2E5E6"}`,
                                                        borderLeft: "none",
                                                    }
                                                }}
                                                variant={`${current === "ai" ? "contained" : "outlined"}`}
                                                onClick={() => { setCurrent('ai') }}
                                            >
                                                AI Bundles
                                            </Button>
                                        </ButtonGroup>
                                    </Box>
                                </Grid>
                                : ''
                        }

                        {/* {
                            planLoading ?
                                <Grid item lg={12} md={12} sm={12} xs={12} p={'5px 8px'}>
                                    <Box sx={{ height: '86px', width: '100%', background: '#FAFAFA', borderRadius: '8px' }}></Box>
                                </Grid> :
                                LocalStorage.getUserData().super_admin && !planLoading ?
                                    <Grid item lg={12} md={12} sm={12} xs={12} p={'5px 8px'} sx={{ cursor: 'pointer' }} onClick={() => navigate('/ai_timesheet')}>
                                        {
                                            creditDetails.ai_ts_payment_slug == '' ?
                                                <Box display='flex' flexDirection='row' gap={2} justifyContent='space-between' sx={{
                                                    height: '86px',
                                                    backgroundImage: 'linear-gradient(271.39deg, #E88CFF -8.63%, #1A30FF 155.72%)',
                                                    padding: '20px',
                                                    borderRadius: '12px',
                                                    alignItems: 'center'
                                                }}>
                                                    <Box>
                                                        <Text largeWhite18 sx={{ fontWeight: `${600} !important` }}>Looking for additional credits to buy Mivi AI prompts or AI Timesheets? </Text>
                                                        <Text mediumWhite sx={{ fontWeight: `${400} !important`, paddingTop: '7px' }}>See our all-new AI Bundle packs</Text>
                                                    </Box>
                                                    <img src={sideArrow} alt='arrow' style={{ height: '12px', width: '6px' }} />
                                                </Box>
                                                : moment(creditDetails.ai_ts_bundle_sub_expiring_on).subtract(7, "days") ?
                                                    <Box display='flex' flexDirection='row' gap={2} justifyContent='space-between' sx={{
                                                        height: '86px',
                                                        backgroundImage: 'linear-gradient(271.39deg, #45B649 -8.63%, #DCE35B 155.72%)',
                                                        padding: '20px',
                                                        borderRadius: '12px',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Box>
                                                            <Text largeWhite18 sx={{ fontWeight: `${600} !important` }}>Upgrade your  AI Timesheets and prompts plans </Text>
                                                            <Text mediumWhite sx={{ fontWeight: `${400} !important`, paddingTop: '7px' }}>View our plans here!</Text>
                                                        </Box>
                                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                            <Text mediumBoldWhite400 sx={{ fontWeight: `${700} !important` }}>Valid till {creditDetails.ai_ts_bundle_sub_end_on}</Text>
                                                            <img src={sideArrow} alt='arrow' style={{ height: '12px', width: '6px' }} />
                                                        </Box>
                                                    </Box> :
                                                    moment().isBefore(creditDetails.ai_ts_bundle_sub_expired_on) ?
                                                        <Box display='flex' flexDirection='row' gap={2} justifyContent='space-between' sx={{
                                                            height: '86px',
                                                            backgroundImage: 'linear-gradient(271.39deg, #FF8181 -8.63%, #E40027 155.72%)',
                                                            padding: '20px',
                                                            borderRadius: '12px',
                                                            alignItems: 'center'
                                                        }}>
                                                            <Box>
                                                                <Text largeWhite18 sx={{ fontWeight: `${600} !important` }}>Upgrade your  AI Timesheets and prompts plans </Text>
                                                                <Text mediumWhite sx={{ fontWeight: `${400} !important`, paddingTop: '7px' }}>View our plans here!</Text>
                                                            </Box>
                                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                                <Text mediumBoldWhite400 sx={{ fontWeight: `${700} !important` }}>Plan Expired on 02/06/2024</Text>
                                                                <img src={sideArrow} alt='arrow' style={{ height: '12px', width: '6px' }} />
                                                            </Box>
                                                        </Box> : creditDetails.ai_ts_bundle_sub_end_on != '' ?
                                                            <Box display='flex' flexDirection='row' gap={2} justifyContent='space-between' sx={{
                                                                height: '86px',
                                                                backgroundImage: 'linear-gradient(271.39deg, #830760 -8.63%, #FB284C 155.72%)',
                                                                padding: '20px',
                                                                borderRadius: '12px',
                                                                alignItems: 'center'
                                                            }}>
                                                                <Box>
                                                                    <Text largeWhite18 sx={{ fontWeight: `${600} !important` }}>Upgrade your  AI Timesheets and prompts plans </Text>
                                                                    <Text mediumWhite sx={{ fontWeight: `${400} !important`, paddingTop: '7px' }}>View our plans here!</Text>
                                                                </Box>
                                                                <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                                    <Text mediumBoldWhite400 sx={{ fontWeight: `${700} !important` }}>Expiring on {creditDetails.ai_ts_bundle_sub_end_on}</Text>
                                                                    <img src={sideArrow} alt='arrow' style={{ height: '12px', width: '6px' }} />
                                                                </Box>
                                                            </Box> : ''
                                        }
                                    </Grid> : ''
                        } */}
                        {
                            status == 'manage'
                                ?
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box m={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                        <ViewSubscription status={status} setStatus={setStatus} state={state} setState={setState} classes={classes} setAvatarData={setAvatarData} current={current} setCurrent={setCurrent} />
                                    </Box>
                                </Grid>
                                :
                                status == 'billingHistory'
                                    ?
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box m={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                            <BillingHistory status={status} setStatus={setStatus} state={state} setState={setState} classes={classes} setAvatarData={setAvatarData} current={current} setCurrent={setCurrent} />
                                        </Box>
                                    </Grid>
                                    :
                                    status == 'purchase' ?
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box m={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                                <PurchaseHistory status={status} setStatus={setStatus} state={state} setState={setState} classes={classes} setAvatarData={setAvatarData} />
                                            </Box>
                                        </Grid>
                                        :
                                        status == 'transfer' ?
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Box m={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                                    <Transfer status={status} setStatus={setStatus} state={transferState} setState={settransferState} classes={classes} setAvatarData={setAvatarData} />
                                                </Box>
                                            </Grid>
                                            :
                                            <Grid item container lg={12} md={12} sm={12} xs={12}>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Box m={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                                        <MyDetails getUserProfileDetails={getUserProfileDetails} state={state} setState={setState} classes={classes} setAvatarData={setAvatarData} setStatus={setStatus} />
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                                    <Box m={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                                        <OrganizationDetails setLogoUrl={props.setLogoUrl} getOrganizationDetails={getOrganizationDetails} state={organisationstate} setState={setorganisationstate} classes={classes} setAvatarData={setAvatarData} />
                                                    </Box>
                                                </Grid>
                                                {
                                                    (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))
                                                        ?
                                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                                            <Box m={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                                                <EmployeeList users={users} classes={classes} loading1={loading} setAvatarData={setAvatarData} />
                                                            </Box>
                                                        </Grid>
                                                        : ''
                                                }

                                                {
                                                    LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "app_integrations_create" && item.is_allowed == true)) ?
                                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                                            <Box m={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                                                <ManageAppIntegrations loading={appLoading} classes={classes} navigate={navigate} apps={apps} />
                                                            </Box>
                                                        </Grid> : ""}
                                                <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                                    <Box m={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                                        <ManageSubscription status={status} setStatus={setStatus} state={state} setState={setState} classes={classes} setAvatarData={setAvatarData} current={current} setCurrent={setCurrent} />
                                                    </Box>
                                                </Grid>
                                                {
                                                    LocalStorage.getUserData().super_admin && (state.active_ownership_transfer == false)
                                                        ?
                                                        <Grid item lg={12} md={12} sm={12} xs={12} p={1}>
                                                            <Button fullWidth variant='outlined' sx={{ textTransform: 'capitalize !important', borderColor: '#737373 !important', color: "#737373 !important", font: '14px Quicksand !important', fontWeight: '600 !important' }} onClick={() => { setStatus('transfer') }}>Transfer Ownership</Button>
                                                        </Grid>
                                                        : ''
                                                }

                                            </Grid>
                        }


                    </Grid>
                    <Grid item lg={2}></Grid>

                </Grid>
            </Box>

        </Box>
    )
}

export default MyProfile