import BaseApi from '../../BaseApi'
import APIURL from "../../../config/development";
import LocalStorage from '../../../utils/LocalStorage';

class DeductionApi {
    //listing
    getListing(params) {
        let {search,limit,page}=params
        return BaseApi.getWithParams(APIURL.org_URL + `payroll-config/expense-management/deduction/listing?request_id=${LocalStorage.uid()}&search=${search}&page=${page}&limit=${limit}`, LocalStorage.getAccessToken());
    }
    getListingReimbursement(params) {
        let {search,limit,page}=params
        return BaseApi.getWithParams(APIURL.org_URL + `payroll-config/expense-management/reimbursement/listing?request_id=${LocalStorage.uid()}&search=${search}&page=${page}&limit=${limit}`, LocalStorage.getAccessToken());
    }
    // getDepartmentTypeDropDown(){
    //     return BaseApi.getWithParams(APIURL.org_URL + `departments/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    // }
    getIndex(id) {                           
        return BaseApi.getWithParams(APIURL.org_URL + `payroll-config/expense-management/index?request_id=${LocalStorage.uid()}&id=${id}`, LocalStorage.getAccessToken());
    }
    getIndexReimbursement() {                           
        return BaseApi.getWithParams(APIURL.org_URL + `payroll-config/reimbursement/index?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }
    storeApi(data){
        return BaseApi.postWithToken(APIURL.org_URL + 'payroll-config/expense-management/store', data, LocalStorage.getAccessToken());
    }
    deleteApi(data, id) {
        return BaseApi.deleteWithToken(APIURL.org_URL + `payroll-config/expense-management/destroy/${id}`, data, LocalStorage.getAccessToken())
    }
    updateApi(data, id) {
        return BaseApi.putWithToken(APIURL.org_URL + `payroll-config/expense-management/update/${id}`, data, LocalStorage.getAccessToken())
    }
    updateStatus(data, id) {
        return BaseApi.putWithToken(APIURL.org_URL + `payroll-config/expense-management/update-status/${id}`, data, LocalStorage.getAccessToken())
    }
    deductBalance(data) {
        return BaseApi.putWithToken(APIURL.org_URL + `payroll-config/reimbursement/update-status/deduct-balance`, data, LocalStorage.getAccessToken())
    }
    offPayrollStatus(data) {
        return BaseApi.putWithToken(APIURL.org_URL + `payroll-config/reimbursement/update-status/off-cycle`, data, LocalStorage.getAccessToken())
    }
    approvalStatus(data) {
        return BaseApi.putWithToken(APIURL.org_URL + `payroll-config/reimbursement/update-status/approval-required`, data, LocalStorage.getAccessToken())
    }
    getIndexOffPayroll() {                           
        return BaseApi.getWithParams(APIURL.org_URL + `payroll-config/reimbursement-config?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }
    offPayrollApprovalUpdate(data) {
        return BaseApi.putWithToken(APIURL.org_URL + `payroll-config/reimbursement-config/update`, data, LocalStorage.getAccessToken())
    }
}
// eslint-disable-next-line
export default new DeductionApi();
