import TimesheetSummary from "../views/employee/timesheets/summary/TimesheetSummary";
import Timesheetview from "../views/employee/timesheets/summary/Timesheetview";
import Dashboard from "../views/employee/Dashboard";
import PlacementDashboard from "../views/employee/placements/PlacementDashboard";
import AddTimesheet from "../views/employee/timesheets/AddTimesheet";
import TimesheetDashboard from "../views/employee/timesheets/TimesheetDashboard"
import ChangePasswordForm from "../layouts/changePassword/ChangePasswordForm";
import Notifications from "../views/settings/notifications/Notifications";
import EditTimesheetEmployee from "../views/employee/timesheets/EditTimesheetEmployee";
import EditTimesheet from "../views/employee/timesheets/EditTimesheet";
import LocalStorage from "../utils/LocalStorage";
import ExpenseDashboard from "../views/admin/payroll/expenseManagement/ExpenseDashboard";
import BalanceSheetIndex from "../views/admin/payroll/balanceSheet/BalanceSheetIndex";

var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

const EmployeeRoute = [
  {
    id: "admin",
    path: "/dashboard",
    element: <Dashboard />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/placement/dashboard",
    element: <PlacementDashboard />,
    name: "",
    slug: "placement",
    access: true
  },
  {
    id: "admin",
    path: "/timesheet",
    element: <TimesheetDashboard />,
    name: "Timesheets",
    slug: "timesheet_view",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true)) ? true : false
  },
  {
    id: "admin",
    path: "/timesheet/summary",
    element: <TimesheetSummary />,
    name: "Add Timesheet",
    slug: "dashboard",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_create" && item.is_allowed == true)) ? true : false
  },
  {
    id: "admin",
    path: "/timesheet/add-timesheet",
    element: <AddTimesheet />,
    name: "Add Timesheet",
    slug: "timesheet_create",
    access: (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_create" && item.is_allowed == true)) ? true : false
  },
  {
    id: "admin",
    path: "/timesheet/edit-timesheet",
    element: <EditTimesheet />,
    name: "",
    slug: "timesheet_edit",
    access: true
  },
  {
    id: "admin",
    path: "/timesheet/edit-timesheet",
    element: <EditTimesheetEmployee />,
    name: "",
    slug: "timesheet_create",
    access: true
  },
  {
    id: "admin",
    path: "/timesheet/view",
    element: <Timesheetview />,
    name: "",
    slug: "timesheet_view",
    access: true
  },
  {
    id: "admin",
    path: "/change-password",
    element: <ChangePasswordForm />,
    name: "",
    slug: "",
    access: true
  },
    {
    id: "admin",
    path: "/notifications",
    element: <Notifications />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "Admin",
    path: "/expenseDashboard",
    element: <ExpenseDashboard />,
    slug: "dashboard",
    name: "",
    access: true
  },
  {
    id: "Admin",
    path: "/balanceSheet",
    element: <BalanceSheetIndex />,
    slug: "dashboard",
    name: "",
    access: true
  }
]

export default EmployeeRoute;