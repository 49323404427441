import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid } from '@mui/material'
import React, { useState } from 'react'
import Text from '../../../../../components/customText/Text'
import { formatNumberWithCommas, getCurrencySymbol, NoDataFound, openDocumentInNewtab } from '../../../../../utils/utils'
import Button from '../../../../../components/customButton/Button'
import GeneratePayrollStyles from '../GeneratePayrollStyles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import checkcircle from '../../../../../assets/svg/checkcircle.svg';
import trendup from '../../../../../assets/svg/trendup.svg';
import clock from '../../../../../assets/svg/clock.svg'

function Reimbursements({ summary,reimbursementDeductionProp,closePopUp,setUpdateStandardPayPopUp }) {

  const classes = GeneratePayrollStyles();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const openInNewTab = (args,documentUrl) => {
    if (args?.aws_s3_status == 0 || args?.aws_s3_status == false) {
      const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
  } else if (args?.aws_s3_status == undefined || args?.aws_s3_status == undefined) {
      const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
  }
  else {
      openDocumentInNewtab(args?.document_key, args?.document_path)
  }
  };

  console.log('reimbursement props',reimbursementDeductionProp)
  return (
    <div>
      <Box px={3}>
        <Grid container columnSpacing={4}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box sx={{ borderRadius: "12px", background: "#E7F2FD", padding: "15px" }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text blackHeader600 >Total Reimbursements <br />Raised</Text>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent={"space-between"}>
                  <Box display={"flex"} justifyContent={"start"}>
                    <Text veryLargeBlack sx={{display:'flex',justifyContent:'center',alignItems:'center'}}><img src={trendup}  alt='trendup'/>{reimbursementDeductionProp?.reimbursement_cards?.total_reimbursement_raised?.count}</Text>
                  </Box>
                  <Text veryLargeBlack>{getCurrencySymbol()} {reimbursementDeductionProp?.reimbursement_cards?.total_reimbursement_raised?.amount}</Text>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box sx={{ borderRadius: "12px", background: "#EAF9EB", padding: "15px" }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text blackHeader600 >Total Reimbursements <br />Approved</Text>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent={"space-between"}>
                  <Box display={"flex"} justifyContent={"start"}>
                    <Text veryLargeBlack sx={{display:'flex',justifyContent:'center',alignItems:'center'}}><img src={checkcircle}  alt='checkcircle'/>{reimbursementDeductionProp?.reimbursement_cards?.total_reimbursement_approved?.count}</Text>
                  </Box>
                  <Text veryLargeBlack>{getCurrencySymbol()} {reimbursementDeductionProp?.reimbursement_cards?.total_reimbursement_approved?.amount}</Text>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box sx={{ borderRadius: "12px", background: "#F3F0FF", padding: "15px" }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text blackHeader600 >Total Reimbursements Pending Approval</Text>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent={"space-between"}>
                  <Box display={"flex"} justifyContent={"start"}>
                    <Text veryLargeBlack sx={{display:'flex',justifyContent:'center',alignItems:'center'}}><img src={clock}  alt='clock'/>{reimbursementDeductionProp?.reimbursement_cards?.total_reimbursement_pending_approval?.count}</Text>
                  </Box>
                  <Text veryLargeBlack>{getCurrencySymbol()} {reimbursementDeductionProp?.reimbursement_cards?.total_reimbursement_pending_approval?.amount}</Text>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box my={2} >
          <Box sx={{ display: "flex", background: "#FBFBFB", padding: "12px 10px", borderRadius: "8px" }}>
            <Box width={"20%"}><Text black400 noWrap>Name</Text></Box>
            <Box width={"20%"}><Text black400 noWrap>Type</Text></Box>
            <Box width={"20%"}><Text black400 noWrap>Added To</Text></Box>
            <Box width={"20%"}><Text black400 noWrap>Status</Text></Box>
            <Box width={"20%"} textAlign={"center"}><Text black400 noWrap>Amount</Text></Box>
          </Box>
          <Box sx={{ height: "40vh", overflowY: "auto" }}>
            {reimbursementDeductionProp?.reimbursements?.length > 0 ? 
              reimbursementDeductionProp?.reimbursements?.map((data, key) => (
                <Accordion key={key} className={classes.customAccordion}
                  expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}
                >
                  <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header" className={classes.customSummary}>
                    <Grid container alignItems='center' justifyContent={"space-between"}>
                      <Box width={"20%"}><Text black18px noWrap>{data?.name}</Text></Box>
                      <Box width={"20%"}><Text black18px noWrap>{data?.type}</Text></Box>
                      <Box width={"20%"}><Text black18px noWrap>{data?.added_to}</Text></Box>
                      <Box width={"20%"} textAlign={"center"}><Text black18px noWrap >{data?.status}</Text></Box>
                      <Box width={"10%"} textAlign={"end"}><Text black18px noWrap>{getCurrencySymbol()} {formatNumberWithCommas(data?.approved_amount)}</Text></Box>
                      <Box width={"10%"} textAlign={"end"}><ExpandMoreIcon /></Box>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className={classes.AccordionDetails} height={"150px"} py={1}>
                      <Box className={classes.AccordionDetailsContent}>
                        <Grid container>
                          <Grid item md={4} sm={4} xs={6} className={classes.contentLeftAlign}>
                            <Text grey16>Name</Text>
                            <Text blackHeader600 pt="4px">{data?.name}</Text>
                          </Grid>
                          <Grid item md={4} sm={4} xs={6} className={classes.contentLeftAlign}>
                            <Text grey16>Reimbursement Type</Text>
                            <Text blackHeader600 pt="4px" noWrap>{data?.type}</Text>
                          </Grid>
                          <Grid item md={4} sm={4} xs={6} className={classes.contentLeftAlign}>
                            <Text grey16>Date of Expense</Text>
                            <Text blackHeader600 pt="4px" noWrap>{data?.date_of_expense}</Text>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider />
                      <Box className={classes.AccordionDetailsContent}>
                        <Grid container>
                          <Grid item md={4} sm={4} xs={6} className={classes.contentLeftAlign}>
                            <Text grey16>Amount</Text>
                            <Text blackHeader600 pt="4px">{getCurrencySymbol()} {data?.approved_amount}</Text>
                          </Grid>
                          <Grid item md={4} sm={4} xs={6} className={classes.contentLeftAlign}>
                            <Text grey16>Added To</Text>
                            <Text blackHeader600 pt="4px" noWrap>{data?.added_to}</Text>
                          </Grid>
                          <Grid item md={4} sm={4} xs={6} className={classes.contentLeftAlign}>
                            <Text grey16>Supporting Document</Text>
                            <Text sx={{ cursor: "pointer !important" }} largeBlue pt="4px" noWrap onClick={() => { openInNewTab(data,data?.expense_documents[0]?.document_url) }}>{data?.expense_documents?.length > 0 ? 'View Attachment' : '--'}</Text>
                          </Grid>

                        </Grid>
                      </Box>
                    </Box>
                  </AccordionDetails>

                </Accordion>
              )) : <Box>{NoDataFound()}</Box>}
          </Box>
        </Box>
      </Box>
      <Box sx={{ background: "#EAECF0" }}>
        <Grid container item lg={12} md={12} sm={12} xs={12} alignItems={"center"} p={1} px={3} gap={2} justifyContent={"end"}>
          <Button cancelBtn sx={{ width: "50px !important", height: '40px !important' }} onClick={closePopUp}>Cancel</Button>
          {!summary ? <Button save14 sx={{ width: "50px !important" }} onClick={setUpdateStandardPayPopUp}>Finalize</Button>: <Button save14 sx={{ width: "50px !important" }} onClick={closePopUp}>Done</Button>}

        </Grid>
      </Box>
    </div>
  )
}

export default Reimbursements
