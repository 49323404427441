import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const ForgotPasswordStyles = makeStyles(() => ({ 
    leftBox: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        paddingTop: "245px",
        marginLeft: "-50px !important",
        [useTheme().breakpoints.down('md')]: {
            marginLeft: "0px !important",
        },
    },
    header: {
        color: '#2D2D2D !important',
        textAlign: 'center',
    },  
    rightBox: {
        background: '#243042 ! important',
        height: '100vh'
    },
    image: {
        marginTop: "160px",
        marginLeft: "-200px",
        width: "85%"      
    },
    input: {
        textAlign: "left",
        paddingLeft:'14px !important',
        paddingRight:'14px !important',
        width: "300px !important",
    },
    loginButton: {
        width: "100% !important",
        height: "43px !important",
        background: `#0C75EB !important`,
        borderRadius: "8px !important",
        textTransform: "none !important",
        font: "15px Quicksand !important",
        fontWeight: '600',
        [useTheme().breakpoints.down("lg")]: {
            font: "12px Quicksand !important",
            width: "100% !important",
        },
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
        }
    },
    loginButton1: {
        width: '28.8vw !important',
        height: "43px !important",
        background: `#0C75EB !important`,
        borderRadius: "8px !important",
        textTransform: "none !important",
        font: "15px Quicksand !important",
        fontWeight: '600',
        [useTheme().breakpoints.down("lg")]: {
            font: "15px Quicksand !important",
            width: "100% !important",
        },
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
        }
    },
}))
