import { Box, Grid, MenuItem, Divider, Menu } from '@mui/material'
import React from 'react'
import Text from '../../../../components/customText/Text'
import { addErrorMsg, dateFormat } from '../../../../utils/utils'
import moment from 'moment'
import Date from '../../../../components/datePicker/Date'
import Button from '../../../../components/customButton/Button';
import calendar from '../../../../assets/svg/payroll/calendar.svg';
import closeIcon from '../../../../assets/svg/payroll/close-Icon.svg';
import paidAmount from '../../../../assets/svg/payroll/paidAmount.svg'
import pendingDue from '../../../../assets/svg/payroll/pendingIcon.svg'
import reimbursed from '../../../../assets/svg/payroll/reimbursement-dashboard.svg'
import recovered from '../../../../assets/svg/payroll/recoveredIcon.svg'
import dollar from '../../../../assets/svg/payroll/currency-dollar.svg'
import finance from '../../../../assets/svg/payroll/financedollar.svg'
import employees from '../../../../assets/svg/payroll/employees.svg';
import ReactApexChart from 'react-apexcharts'
import PayrollDashboardStyles from './PayrollDashboardStyles'
import { useNavigate } from 'react-router-dom'

function PayrollHistorySelection(props) {
    const { state, setState, payrollHistory, payrollHistoryApi, choosePeriod, setChoosePeriod, open, anchorEl, setAnchorEl, finalized, payrollCycles } = props;
    const classes = PayrollDashboardStyles();
    const navigate = useNavigate();
    const semicircle = {
        series: [finalized],
        options: {
            chart: {
                type: 'radialBar',
                offsetY: 0,
                sparkline: {
                    enabled: false
                }
            },
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none'
                    }
                }
            },
            fill: {
                type: 'solid',
                colors: ['#FA9174', '#7F56DA']
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: "#e7e7e7",
                        strokeWidth: '97%',
                        margin: 5, // margin is in pixels    
                        borderRadius: 30,
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: 0,
                            fontSize: '22px',
                            color: '#fff',
                            fontWeight: `${700} !important`,
                            fontFamily: 'Quicksand !important'
                        }
                    }
                }
            },
            grid: {
                padding: {
                    top: -10
                }
            },
        },
    }

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d;
        if (!date) {
            addErrorMsg("Please select a valid date");
            return;
        }
    
        const formattedInputDate = moment(date, dateFormat()); // Ensure consistent formatting
        console.log("Selected date: ", formattedInputDate.format());
    
        // Find matching payroll cycle
        const match = payrollCycles.data.find(({ from_date, to_date }) => {
            const fromDate = moment(from_date, dateFormat());
            const toDate = moment(to_date, dateFormat());
            return formattedInputDate.isBetween(fromDate, toDate, 'day', '[]');
        });
    
        if (match) {
            const { from_date: startDate, to_date: endDate } = match;
            console.log("Match found: ", { startDate, endDate });
    
            // Only update if the new range is different
            setState((prevState) => {
                if (
                    prevState.pHTemporaryStartDate === startDate &&
                    prevState.pHTemporaryEndDate === endDate
                ) {
                    return prevState; // Avoid unnecessary re-renders
                }
                return {
                    ...prevState,
                    pHTemporaryStartDate: startDate,
                    pHTemporaryEndDate: endDate,
                };
            });
    
            return { startDate, endDate };
        } else {
            console.log("No match found for the selected date.");
            setState((prevState) => ({
                ...prevState,
                pHTemporaryStartDate: '',
                pHTemporaryEndDate: '',
            }));
            addErrorMsg(
                "Based on the provided 'Start Date' or 'End Date', no payroll cycle exists"
            );
            return null; // Handle cases where the date is not found
        }
    };
    
    
    

    const applyFilter = () => {
        if (state.pHTemporaryStartDate !== '' && state.pHTemporaryEndDate !== '') {
            payrollHistoryApi(state.pHTemporaryStartDate, state.pHTemporaryEndDate);
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Grid item container xs={12} alignItems='center'>
            <Grid item xs={6}>
                <Text largeLabel>Payroll History</Text>
            </Grid>
            <Grid item container xs={5.3} justifyContent='end'>
                {
                    payrollCycles?.data?.length == 0 ? '' : (choosePeriod && state.pHStartDate != "") ?
                        <Box display='flex' flexDirection='row' gap={1} className={classes.choosePeriod}>
                            <Box><Text offBoardBodyFont>{state.pHStartDate} - {state.pHEndDate}</Text></Box>
                            <Box><img src={closeIcon} alt='close' style={{ cursor: 'pointer' }} onClick={() => setChoosePeriod(false)} /></Box>
                        </Box> :
                        <>
                            <Button
                                choosePeriod
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                    <Text offBoardBodyFont>Choose period</Text>
                                    <img src={calendar} alt='calendar' />
                                </Box>
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={() => handleClose()}
                                MenuListProps={{
                                    'aria-labelledby': 'account-menu',
                                }}
                                style={{ left: "-52px", top: '20px', width: '100%' }}
                            >
                                <MenuItem
                                    disableRipple={true}
                                    sx={{ backgroundColor: 'transparent !important' }}>
                                    <Grid item xs={12}>
                                        <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                            <Box>
                                                <Date
                                                    labelText='Start Date'
                                                    name='pHTemporaryStartDate'
                                                    value={state.pHTemporaryStartDate}
                                                    minDate={payrollCycles.min_date}
                                                    maxDate={payrollCycles.max_date}
                                                    onChange={(value => dateChange(value, 'pHTemporaryStartDate'))}
                                                    height='45px'
                                                />
                                            </Box>
                                            <Box>
                                                -
                                            </Box>
                                            <Box>
                                                <Date
                                                    labelText='End Date'
                                                    name='pHTemporaryEndDate'
                                                    value={state.pHTemporaryEndDate}
                                                    disabled={true}
                                                    minDate={state.pHTemporaryStartDate}
                                                    maxDate={payrollCycles.max_date}
                                                    onChange={(value => dateChange(value, 'pHTemporaryEndDate'))}
                                                    height='45px'
                                                />
                                            </Box>
                                        </Box>
                                        <Grid item xs={12}>
                                            <Box my={2}>
                                                <Divider />
                                            </Box>
                                            <Box display='flex' flexDirection='row' gap={1} justifyContent='space-between'>
                                                <Button payrollHistoryCancel onClick={handleClose}>Cancel</Button>
                                                <Button blueButton onClick={applyFilter}>Apply Filter</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            </Menu>
                        </>
                }
            </Grid>
            <Grid item container xs={12} lg={12} md={12} mt={3}>
                <Box sx={{ width: '94.4%', boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '15px 15px 25px 15px' }}>
                    <Grid item container xs={12} alignItems='center'>
                        <Grid item xs={6} lg={6} md={6}>
                            <Text largeLabel>{payrollHistory?.payroll_history?.from_date} - {payrollHistory?.payroll_history?.to_date}</Text>
                        </Grid>
                        <Grid item xs={6} lg={6} md={6} textAlign='end'>
                            {
                                payrollCycles?.data?.length == 0 ? '' :
                                    <Text largeBlue style={{ cursor: 'pointer' }} onClick={() => navigate('/payrollSummary', { state: { id: payrollHistory?.payroll_history?.configuration_id } })}>View Summary</Text>
                            }
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} lg={12} md={12} my={3}>
                        <Divider sx={{ border: '0.5px solid #EAECF0 !important', width: '100%' }} />
                    </Grid>
                    <Grid item container xs={12} lg={12} md={12} alignItems='center'>
                        <Grid item container xs={3} lg={3} md={3} alignItems='center' justifyContent='center' textAlign='center'>
                            <Box display='flex' flexDirection='column' justifyContent='center' gap={2}>
                                <Box style={{ position: "relative" }}>
                                    <ReactApexChart options={semicircle.options} series={semicircle.series} type="radialBar" />
                                    <Box
                                        style={{
                                            position: "absolute",
                                            top: "70%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            textAlign: "center",
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Box display='flex' flexDirection='column' gap={0.5} justifyContent='center'>
                                            <img src={employees} alt='employees' style={{ height: '18px', width: '20px', marginLeft: '4px' }} />
                                            <Text veryLargeLabel>{payrollHistory.payroll_history.total_employees}</Text>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display='flex' flexDirection='row' justifyContent='center' gap={2}>
                                    <Box display='flex' flexDirection='column' gap={1}>
                                        <Text smallGrey400>Finalized</Text>
                                        <Text blue14>{payrollHistory.payroll_history.finalized}</Text>
                                        <Divider sx={{ border: '1px solid #FA9174' }} />
                                    </Box>
                                    <Box display='flex' flexDirection='column' gap={1}>
                                        <Text smallGrey400>Non Finalized</Text>
                                        <Text blue14>{payrollHistory.payroll_history.not_finalized}</Text>
                                        <Divider sx={{ border: '1px solid #7F56DA' }} />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Divider orientation='vertical' variant='middle' flexItem />
                        <Grid item xs={2.9} lg={2.9} md={2.9} alignItems='center'>
                            <Box display='flex' flexDirection='column' gap={2} justifyContent='center' pl={3}>
                                <Box display='flex' flexDirection='row' gap={1} >
                                    <img src={paidAmount} alt='amount' />
                                    <Text mediumBlack600>Amount Paid</Text>
                                </Box>
                                <Text largeBlack22>{payrollHistory.payroll_history.total_paid ? `$ ${payrollHistory.payroll_history.total_paid}` : '-'}</Text>
                                <Divider sx={{ margin: '10px 5px 10px -10px' }} />
                                <Box display='flex' flexDirection='row' gap={1} >
                                    <img src={pendingDue} alt='amount' />
                                    <Text mediumBlack600>Pending Dues</Text>
                                </Box>
                                <Text largeBlack22>{payrollHistory.payroll_history.pending_dues ? `$ ${payrollHistory.payroll_history.pending_dues}` : '-'}</Text>
                            </Box>
                        </Grid>
                        <Divider orientation='vertical' variant='middle' flexItem />
                        <Grid item xs={2.9} lg={2.9} md={2.9} alignItems='center'>
                            <Box display='flex' flexDirection='column' gap={2} justifyContent='center' pl={3}>
                                <Box display='flex' flexDirection='row' gap={1} >
                                    <img src={reimbursed} alt='amount' />
                                    <Text mediumBlack600>Reimbursed Amount</Text>
                                </Box>
                                <Text largeBlack22>{payrollHistory.payroll_history.total_reimbursement ? `$ ${payrollHistory.payroll_history.total_reimbursement}` : '-'}</Text>
                                <Divider sx={{ margin: '10px 5px 10px -10px' }} />
                                <Box display='flex' flexDirection='row' gap={1} >
                                    <img src={recovered} alt='amount' />
                                    <Text mediumBlack600>Recovered Amount</Text>
                                </Box>
                                <Text largeBlack22>{payrollHistory.payroll_history.total_deduction ? `$ ${payrollHistory.payroll_history.total_deduction}` : '-'}</Text>
                            </Box>
                        </Grid>
                        <Divider orientation='vertical' variant='middle' flexItem />
                        <Grid item xs={2.9} lg={2.9} md={2.9} alignItems='center'>
                            <Box display='flex' flexDirection='column' gap={2} justifyContent='center' pl={3}>
                                <Box display='flex' flexDirection='row' gap={1} >
                                    <img src={dollar} alt='amount' />
                                    <Text mediumBlack600>Margin Earned</Text>
                                </Box>
                                <Text largeBlack22>{payrollHistory.payroll_history.margin_earned ? `$ ${payrollHistory.payroll_history.margin_earned}` : '-'}</Text>
                                <Divider sx={{ margin: '10px 5px 10px -10px' }} />
                                <Box display='flex' flexDirection='row' gap={1} >
                                    <img src={finance} alt='amount' />
                                    <Text mediumBlack600>Final Balance</Text>
                                </Box>
                                <Text largeBlack22>{payrollHistory.payroll_history.final_balance ? `$ ${payrollHistory.payroll_history.final_balance}` : '-'}</Text>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid >
    )
}

export default PayrollHistorySelection