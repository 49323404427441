

import React from 'react';
import ClientStyles from '../../views/admin/clients/ClientStyles';
import CompanyStyles from '../../views/admin/clients/userProfile/UserProfileStyles';
import { Box, Grid, Menu, MenuItem, ListItemButton, IconButton, Breadcrumbs, Hidden, Divider, Accordion, AccordionSummary, AccordionDetails, Backdrop } from '@mui/material';
import Text from '../../components/customText/Text';
import Button from '../../components/customButton/Button';
import Search from '../../assets/svg/search1.svg';
import cloud from '../../assets/svg/download-cloud-blue.svg';
import menu from '../../assets/svg/clients/client-menu-withoutbg.svg';
import Userplus from '../../assets/svg/clients/user-plus-bold.svg';// eslint-disable-next-line
import { useEffect } from 'react';
import { useState } from 'react';
import LocalStorage from '../../utils/LocalStorage';
import { BlackToolTip, NoPermission, capitalizeAndAddSpace } from '../../utils/utils';

import FilterListIcon from '@mui/icons-material/FilterList';
import arrowUp from '../../assets/svg/clients/blackarrowUp.svg';
import info from '../../assets/svg/clients/grey-info-bold.svg';
import Loader from '../../assets/gif/japfuLoader.gif';
import CustomCircularProgress from '../../components/progressbar/CircularProgress';
import noDataFound from '../../assets/svg/NoDataFoundIcon.svg'
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import LeftNavigateArrow from '../../assets/svg/LeftNavigateArrow.svg'
import TourGuideConfigApi from '../../apis/configurations/tourguideConfig/TourGuideConfigApi';
import { useNavigate } from 'react-router-dom';
import { employee_URL } from '../../config/development';

const dataArr = ["Client Details", "Placements", "Contact Details", "Analytics"];

export default function ClientsDashboard({ setStartTour }) {

    const navigate = useNavigate();
    const classes = ClientStyles();
    const classesCompany = CompanyStyles();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const [anchorEl, setAnchorEl] = useState(null);
    const [showClients, setShowClients] = useState('allclients');

    const open = Boolean(anchorEl);

    const vendorRows = [
        {
            company_id: "4787ac90-2d54-48a5-9c76-1bb1e762f1c7",
            company_name: "Vendor 1",
            reference: "CLT-83",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "",
            status: "Active",
            profile_percentage: 50,
            created_at: "2024-10-28T09:08:53.191Z",
            primary_contact: 'ABC',
            id: "4787ac90-2d54-48a5-9c76-1bb1e762f1c7",
            name: "ABC",
            reference_id: "CLT-83"
        },
        {
            company_id: "abe82998-7261-4553-ac0f-f92539bd089c",
            company_name: "Vendor 2",
            reference: "CLT-82",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "fsgf@wfesfrh.fghfg",
            status: "Active",
            profile_percentage: 100,
            created_at: "2024-10-21T10:19:57.004Z",
            primary_contact: "DEF",
            id: "abe82998-7261-4553-ac0f-f92539bd089c",
            name: "DEF",
            reference_id: "CLT-82"
        },
        {
            company_id: "a5e100a3-e4b3-45f1-a143-f0a56c88464e",
            company_name: "Vendor 3",
            reference: "CLT-81",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "fgh@fg.fg",
            status: "Active",
            profile_percentage: 100,
            created_at: "2024-10-21T10:19:39.647Z",
            primary_contact: "GHI",
            id: "a5e100a3-e4b3-45f1-a143-f0a56c88464e",
            name: "GHI",
            reference_id: "CLT-81"
        },
        {
            company_id: "991ea0c4-183c-43f4-b74e-1e626f4a6129",
            company_name: "Vendor 4",
            reference: "CLT-80",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "",
            status: "Active",
            profile_percentage: 50,
            created_at: "2024-10-21T10:19:15.321Z",
            primary_contact: 'JKL',
            id: "991ea0c4-183c-43f4-b74e-1e626f4a6129",
            name: "JKL",
            reference_id: "CLT-80"
        },
        {
            company_id: "fbfeda02-26c1-425a-89f4-4f52373e1822",
            company_name: "Vendor 5",
            reference: "CLT-79",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "",
            status: "Active",
            profile_percentage: 50,
            created_at: "2024-10-21T10:19:07.914Z",
            primary_contact: 'MNO',
            id: "fbfeda02-26c1-425a-89f4-4f52373e1822",
            name: "MNO",
            reference_id: "CLT-79"
        },

    ]

    const clientRows = [
        {
            company_id: "4787ac90-2d54-48a5-9c76-1bb1e762f1c7",
            company_name: "Client 1",
            reference: "CLT-83",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "",
            status: "Active",
            profile_percentage: 50,
            created_at: "2024-10-28T09:08:53.191Z",
            primary_contact: 'ABC',
            id: "4787ac90-2d54-48a5-9c76-1bb1e762f1c7",
            name: "ABC",
            reference_id: "CLT-83",
        },
        {
            company_id: "abe82998-7261-4553-ac0f-f92539bd089c",
            company_name: "Client 2",
            reference: "CLT-82",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "fsgf@wfesfrh.fghfg",
            status: "Active",
            profile_percentage: 100,
            created_at: "2024-10-21T10:19:57.004Z",
            primary_contact: "DEF",
            id: "abe82998-7261-4553-ac0f-f92539bd089c",
            name: "DEF",
            reference_id: "CLT-82",
        },
        {
            company_id: "a5e100a3-e4b3-45f1-a143-f0a56c88464e",
            company_name: "Client 3",
            reference: "CLT-81",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "fgh@fg.fg",
            status: "Active",
            profile_percentage: 100,
            created_at: "2024-10-21T10:19:39.647Z",
            primary_contact: "GHI",
            id: "a5e100a3-e4b3-45f1-a143-f0a56c88464e",
            name: "GHI",
            reference_id: "CLT-81"
        },
        {
            company_id: "991ea0c4-183c-43f4-b74e-1e626f4a6129",
            company_name: "Client 4",
            reference: "CLT-80",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "",
            status: "Active",
            profile_percentage: 50,
            created_at: "2024-10-21T10:19:15.321Z",
            primary_contact: 'JKL',
            id: "991ea0c4-183c-43f4-b74e-1e626f4a6129",
            name: "JKL",
            reference_id: "CLT-80"
        },
        {
            company_id: "fbfeda02-26c1-425a-89f4-4f52373e1822",
            company_name: "Client 5",
            reference: "CLT-79",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "",
            status: "Active",
            profile_percentage: 50,
            created_at: "2024-10-21T10:19:07.914Z",
            primary_contact: 'MNO',
            id: "fbfeda02-26c1-425a-89f4-4f52373e1822",
            name: "MNO",
            reference_id: "CLT-79"
        },

    ]
    const endCltRows = [
        {
            company_id: "4787ac90-2d54-48a5-9c76-1bb1e762f1c7",
            company_name: "Client 1",
            reference: "CLT-83",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "",
            status: "Active",
            profile_percentage: 50,
            created_at: "2024-10-28T09:08:53.191Z",
            primary_contact: 'ABC',
            id: "4787ac90-2d54-48a5-9c76-1bb1e762f1c7",
            name: "ABC",
            reference_id: "CLT-83"
        },
        {
            company_id: "abe82998-7261-4553-ac0f-f92539bd089c",
            company_name: "Client 2",
            reference: "CLT-82",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "fsgf@wfesfrh.fghfg",
            status: "Active",
            profile_percentage: 100,
            created_at: "2024-10-21T10:19:57.004Z",
            primary_contact: "DEF",
            id: "abe82998-7261-4553-ac0f-f92539bd089c",
            name: "DEF",
            reference_id: "CLT-82"
        },
        {
            company_id: "a5e100a3-e4b3-45f1-a143-f0a56c88464e",
            company_name: "Client 3",
            reference: "CLT-81",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "fgh@fg.fg",
            status: "Active",
            profile_percentage: 100,
            created_at: "2024-10-21T10:19:39.647Z",
            primary_contact: "GHI",
            id: "a5e100a3-e4b3-45f1-a143-f0a56c88464e",
            name: "GHI",
            reference_id: "CLT-81"
        },
        {
            company_id: "991ea0c4-183c-43f4-b74e-1e626f4a6129",
            company_name: "Client 4",
            reference: "CLT-80",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "",
            status: "Active",
            profile_percentage: 50,
            created_at: "2024-10-21T10:19:15.321Z",
            primary_contact: 'JKL',
            id: "991ea0c4-183c-43f4-b74e-1e626f4a6129",
            name: "JKL",
            reference_id: "CLT-80"
        },
        {
            company_id: "fbfeda02-26c1-425a-89f4-4f52373e1822",
            company_name: "Client 5",
            reference: "CLT-79",
            document_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            telephone_number: "123-456-7890",
            telephone_number_ext: null,
            mobile_number: "",
            contact_email: "",
            status: "Active",
            profile_percentage: 50,
            created_at: "2024-10-21T10:19:07.914Z",
            primary_contact: 'MNO',
            id: "fbfeda02-26c1-425a-89f4-4f52373e1822",
            name: "MNO",
            reference_id: "CLT-79"
        },

    ]

    const viewData = {
        id: "fbfeda02-26c1-425a-89f4-4f52373e1822",
        name: "ABCD",
        reference_id: "CLT-79",
        status: "Active",
        logo_url: null,
        logo_path_id: "",
        logo_name: "",
        profile_percentage: 50,
        address: [
            {
                id: 132,
                address_line_one: "fs",
                address_line_two: "",
                city: "uhjgf",
                state_id: 59,
                state_name: null,
                country_id: 2,
                country_name: null,
                zip_code: "35432"
            }
        ],
        isMappedToEmployees: false
    }

    const [current, setCurrent] = useState("Client Details");// eslint-disable-next-line 
    const [expanded, setExpanded] = React.useState(false);
    const [activeState, setActiveState] = useState('clients');
    const steps = [];
    if(LocalStorage.getUserData().super_admin ||(rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))){
        
        steps.push(...[{
            element: '#addclient', popover: {
                title: 'Add Client', description:
                    'Easily add new clients. Provide essential details and a contact person details for further use.', side: "right",
                align: 'start'
            }
        },])
        
    }
    if(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed == true))){
        steps.push(...[
            {
                element: '#addvendor', popover: {
                    title: 'Add Vendor', description:
                        'Easily add new vendors. Provide essential details and a contact person details for further use.', side: "right",
                    align: 'start'
                }
            },])
            
    }
    if(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed == true))){
        steps.push(...[
            {
                element: '#addendclient', popover: {
                    title: 'Add End Client', description:
                        'Easily add new end-clients. Provide essential details and a contact person details for further use.', side: "right",
                    align: 'start'
                }
            },])
            
    }

     steps.push(...[
        
        
        {
            element: '#clienttabs', popover: {
                title: 'Client Category Tabs', description:
                    'Manage and organize clients vendors and end-clients effectively.', side: "right",
                align: 'start'
            }
        },  ])
        if(LocalStorage.getUserData().super_admin ||(rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))){
        steps.push(...[
        {
            element: '#viewanalytics', popover: {
                title: 'Analytics', description:
                    'Access comprehensive analytics and insights related to your clients. Track performance, engagement, and other key metrics to make informed decisions.', side: "right",
                align: 'start'
            }
        },
    ])}

        steps.push(...[


        {
            element: '#viewprofile', popover: {
                title: 'View Profile', description:
                    'View detailed profiles of your clients, including essential information and interactions, for better management and relationship building.', side: "right",
                align: 'start'
            }
        },])

        if(LocalStorage.getUserData().super_admin ||(rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))){
            steps.push(...[
        {
            element: '#ClientDetails', popover: {
                title: 'Client Details', description:
                    'View and manage key details about each client, including company information, industry, and relevant notes.', side: "right",
                align: 'start'
            }
        },
        {
            element: '#Placements', popover: {
                title: 'Placements', description:
                    'Track placements related to each client. Manage and review placement history to ensure accurate and effective service delivery.', side: "right",
                align: 'start'
            }
        },
        {
            element: '#ContactDetails', popover: {
                title: 'Contact Details', description:
                    'Access and update contact information of a POC for each client. Ensure you have the latest details for effective communication.', side: "right",
                align: 'end'
            }
        },
        {
            element: '#Analytics', popover: {
                title: 'Analytics', description:
                    'Utilize analytics to gain insights into client interactions, performance metrics, and other relevant data to enhance your client management strategies.', side: "right",
                align: 'end'
            }
        },
        
    ])}

    if(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_edit" && item.is_allowed == true) && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))){
        steps.push(...[ {
            element: '#deactivationoption', popover: {
                title: 'Deactivation Option', description:
                    'Safely deactivate clients who are no longer active or required. This feature allows you to manage client status and maintain a clean and relevant client list.', side: "right",
                align: 'start'
            }
        },])
    }

    useEffect(() => {
        const driverObj = driver({
            popoverClass: 'driverjs-theme',
            allowClose: false,
            stagePadding: 0,
            stageRadius: 0,
            animate: false,
            allowKeyboardControl: false,
            disableActiveInteraction: true,
            steps: steps,
            popoverOffset: 15,

            onPopoverRender: (popover, opts) => {//creates the popover with required design 
                const currentStep = opts.state.activeIndex;
                const totalSteps = opts.config.steps.length;
                const updateTourGuideStatus = async (tourModule) => {
                    await TourGuideConfigApi.updateApi({
                        "request_id": LocalStorage.uid(),
                        "module": tourModule,
                        "status": true
                    })
                }
                const title = document.createElement('div');
                title.style.display = 'flex';
                title.style.alignItems = 'center';
                title.style.marginBottom = '8px';

                const icon = document.createElement('img');
                icon.src = LeftNavigateArrow;
                icon.alt = 'Back Arrow';
                icon.style.width = '16px';
                icon.style.height = '16px';
                icon.style.marginRight = '2px';
                icon.style.cursor = 'pointer';
                if (currentStep !== 0) {
                    icon.onclick = (event) => {
                        event.stopPropagation();
                        if (opts.config.steps[currentStep-1].popover.title == 'Add Client') {
                            setActiveState('clients')
                            driverObj.movePrevious();
                        }
                        else if (opts.config.steps[currentStep-1].popover.title == 'Add Vendor') {
                            setActiveState('vendors')
                            driverObj.movePrevious();
                        }
                        else if (opts.config.steps[currentStep-1].popover.title == 'Add End Client') {
                            setActiveState('end-clients')
                            driverObj.movePrevious();
                        }
                        else if (opts.config.steps[currentStep-1].popover.title == 'View Profile') {
                            setShowClients('allclients')
                            if (LocalStorage.getUserData().super_admin ||(rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) {
                                setActiveState('clients');
                            } else if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed == true))) {
                                setActiveState('vendors');
                            } else if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed == true))) {
                                setActiveState('end-clients');
                            }

                            driverObj.movePrevious();
                        }
                        else if (opts.config.steps[currentStep-1].popover.title == 'Client Details') {
                            setCurrent('Client Details')
                            driverObj.movePrevious();
                        }
                        else if (opts.config.steps[currentStep-1].popover.title == 'Placements') {
                            setCurrent('Placements')
                            driverObj.movePrevious();
                        }
                        else if (opts.config.steps[currentStep-1].popover.title == 'Contact Details') {
                            setCurrent('Contact Details')
                            driverObj.movePrevious();
                        }
                        else if (opts.config.steps[currentStep-1].popover.title == 'Analytics') {
                            handleClose();
                            driverObj.movePrevious();
                            setCurrent('Analytics')
                        }
                        else {
                            driverObj.movePrevious();
                        }

                    };
                    title.appendChild(icon);
                }
                const textContainer = document.createElement('div');
                textContainer.style.display = 'flex';
                textContainer.style.justifyContent = 'space-between';
                textContainer.style.width = '100%';
                const titleText = document.createElement('p');
                titleText.textContent = opts.config.steps[currentStep].popover.title;
                titleText.style.fontFamily = 'Quicksand';
                titleText.style.fontSize = '15px';
                titleText.style.fontWeight = '700';
                const stepSpan = document.createElement('span');
                stepSpan.textContent = `(${currentStep + 1}/${totalSteps})`;
                stepSpan.style.display = 'flex';
                stepSpan.style.alignItems = 'center';
                stepSpan.style.fontSize = '12px';
                stepSpan.style.fontWeight = '600';
                stepSpan.style.color = '#525252';
                stepSpan.style.fontFamily = 'Quicksand';
                textContainer.appendChild(titleText);
                textContainer.appendChild(stepSpan);
                title.appendChild(textContainer);

                const description = document.createElement('div');
                description.innerText = opts.config.steps[currentStep].popover.description;
                description.style.marginBottom = '24px';
                description.style.fontSize = '14px';
                description.style.fontWeight = '400';
                description.style.color = '#404040';
                description.style.fontFamily = 'Quicksand';

                const footer = document.createElement('div');
                footer.style.display = 'flex';
                footer.style.justifyContent = 'space-between';
                footer.style.width = '100%';
                footer.style.boxSizing = 'border-box';
                const applyInviteLinkBtnStyles = (element, text) => {
                    element.style.cursor = 'pointer';
                    element.style.textAlign = 'center';
                    element.style.fontSize = '12px';
                    element.style.fontFamily = 'Quicksand';
                    element.style.fontWeight = '600';
                    element.style.width = (text == 'finish') ? '100%' : '130px';
                    element.style.height = '32px';
                    element.style.border = '1.5px solid rgba(12, 117, 235, 1)';
                    element.style.borderRadius = '4px';
                    element.style.color = (text == 'skip') ? '#0C75EB' : '#ffffff';
                    element.style.transition = 'all .3s ease';
                    element.style.outline = 'none';
                    element.style.backgroundColor = (text == 'skip') ? '#ffffff' : '#0C75EB';

                };
                const skipButton = document.createElement('button');
                skipButton.innerText = 'Skip';
                applyInviteLinkBtnStyles(skipButton, 'skip');

                skipButton.onclick = async () => {
                    LocalStorage.setStartTour({ 'companies': false })
                    updateTourGuideStatus('COMPANIES');
                    setStartTour();
                    driverObj.destroy();
                    if(LocalStorage.getUserData().org_config_set == false){
                        navigate('/configuration');
                    }
                };
                const finishButton = document.createElement('button');
                finishButton.innerText = 'Finish';
                applyInviteLinkBtnStyles(finishButton, 'finish');
                finishButton.onclick = () => {
                    LocalStorage.setStartTour({ 'companies': false })
                    updateTourGuideStatus('COMPANIES');
                    setStartTour();
                    driverObj.destroy();
                    if(LocalStorage.getUserData().org_config_set == false){
                        navigate('/configuration');
                    }
                };
                const nextButton = document.createElement('button');
                nextButton.innerText = 'Next';
                applyInviteLinkBtnStyles(nextButton, 'next');
                nextButton.onclick = () => {
                    if (opts.config.steps[currentStep+1].popover.title == 'Add Vendor') {
                        setActiveState('vendors')
                        driverObj.moveNext();
                    }
                    else if (opts.config.steps[currentStep+1].popover.title == 'Add End Client') {
                        setActiveState('end-clients')
                        driverObj.moveNext();
                    }
                    else if (opts.config.steps[currentStep].popover.title == 'Client Category Tabs') {
                        if (LocalStorage.getUserData().super_admin ||(rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) {
                            setActiveState('clients');
                        } else if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed == true))) {
                            setActiveState('vendors');
                        } else if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed == true))) {
                            setActiveState('end-clients');
                        }
                        driverObj.moveNext();
                    }
                    else if (opts.config.steps[currentStep].popover.title == 'View Profile') {
                        setShowClients('clientProfile')
                        setCurrent('Client Details')

                        setTimeout(() => { driverObj.moveNext(); }, 300);
                    }
                    else if (opts.config.steps[currentStep].popover.title == 'Client Details') {
                        setCurrent('Placements')
                        driverObj.moveNext();
                    }
                    else if (opts.config.steps[currentStep].popover.title == 'Placements') {
                        setCurrent('Contact Details')
                        driverObj.moveNext();
                    }
                    else if (opts.config.steps[currentStep].popover.title == 'Contact Details') {
                        setCurrent('Analytics')
                        driverObj.moveNext();
                    }
                    else if (opts.config.steps[currentStep+1].popover.title == 'Deactivation Option') {
                        const threedots = document.getElementById('threedots');
                        setCurrent('Client Details')
                        threedots.click();
                        setTimeout(() => { driverObj.moveNext(); }, 300);
                    }
                    else {
                        driverObj.moveNext();
                    }
                };
                if (currentStep === totalSteps - 1) {
                    footer.appendChild(finishButton);
                } else {
                    footer.appendChild(skipButton);
                    footer.appendChild(nextButton);
                }
                popover.wrapper.innerHTML = '';
                const arrow = popover.arrow;
                if (arrow) {
                    popover.wrapper.appendChild(arrow);
                }
                popover.wrapper.appendChild(title);
                popover.wrapper.appendChild(description);
                popover.wrapper.appendChild(footer);

            }
        });

        if (LocalStorage.getUserData().super_admin ||(rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) {
            setActiveState('clients');
        } else if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed == true))) {
            setActiveState('vendors');
        } else if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed == true))) {
            setActiveState('end-clients');
        }else{
            setActiveState(undefined)
        }
        setTimeout(() => {

            
            driverObj.drive();
        }, 500);

        return () => {
            driverObj.destroy();
        };
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleTab = (args) => {
        setActiveState(args);
        setExpanded(false);

    }

    return (
        <>
            {showClients == 'allclients' && <>
                {
                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => (item.slug == "end_client_view" || item.slug == "client_view" || item.slug == "vendor_view") && item.is_allowed == true))) ?
                        <Grid item container lg={11} md={12} sm={12} xs={12} pt={2} justifyContent='center' spacing={2}>
                            <Grid item container xl={5} lg={4} md={4} sm={12} xs={12} >
                                <Grid item lg={8} md={8} sm={8} xs={12} id="clienttabs" >

                                    <Box sx={{ display: "flex", background: "#F4F8FF", height: '51px', borderRadius: "12px !important", width: '100% !important', alignItems: 'center', textAlign: 'center', padding: "12px 10px !important", gap: 3 }} >
                                        <Box className={activeState == 'clients' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('clients')}>
                                            {
                                                activeState == 'clients' ?
                                                    <Text largeWhite400>Clients</Text> :
                                                    <Text blackHeader>Clients</Text>
                                            }
                                        </Box>
                                        <Box className={activeState == 'vendors' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('vendors')}>
                                            {
                                                activeState == 'vendors' ?
                                                    <Text largeWhite400>Vendors</Text> :
                                                    <Text blackHeader>Vendors</Text>
                                            }
                                        </Box>
                                        <Box className={activeState == 'end-clients' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('end-clients')}>
                                            {
                                                activeState == 'end-clients' ?
                                                    <Text largeWhite400>End Clients</Text> :
                                                    <Text blackHeader>End Clients</Text>
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item container xl={7} lg={8} md={8} sm={12} xs={12} spacing={2} justifyContent='end'>
                                {
                                    activeState == "clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed !== true))) ? "" :
                                        activeState == "vendors" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed !== true))) ? "" :
                                            activeState == "end-clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed !== true))) ? "" :
                                                <>
                                                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                                                        <Box style={{ height: "44px", marginTop: '4px', border: "1.5px solid #E2E5E6", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                            <input
                                                                className={classes.EmployeesSearchInput}
                                                                name="text"
                                                                placeholder='Search by Name / ID'
                                                            />
                                                            <Box sx={{ paddingRight: "15px !important" }}>

                                                                <img src={Search} alt="Search" />
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xl={1} lg={1.2} md={1.3} sm={1.3} xs={2}>
                                                        <Box sx={{ paddingTop: '4px' }}>
                                                            <button title="Export" type="button" className={classes.borderButton}

                                                            >
                                                                <img src={cloud} alt="Userplus" />
                                                            </button>
                                                        </Box>

                                                    </Grid>
                                                    {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) ?
                                                        <Grid item xl={3} lg={3.5} md={2.7} sm={2.5} xs={12} >
                                                            <Box id="viewanalytics" sx={{ padding: '5px' }}>
                                                                <Button style={{ width: '100%', fontSize: `12px !important` }} analyticsView >View Analytics</Button>
                                                            </Box>

                                                        </Grid>
                                                        : ""}
                                                    <Grid item xl={3} lg={3} md={4} sm={4} xs={4}>


                                                        {activeState == "clients" && <Box id="addclient" sx={{ padding: '5px', marginLeft: '-10px' }}>
                                                            <Button id='addclient' sx={{ width: '100%' }} addButton ><img src={Userplus} alt='plus' className={classes.addIcon} />Add client </Button>
                                                        </Box>

                                                        }
                                                        {activeState == "vendors" && <Box id="addvendor" sx={{ padding: '5px', marginLeft: '-10px' }}>
                                                            <Button id='addvendor' sx={{ width: '100%' }} addButton ><img src={Userplus} alt='plus' className={classes.addIcon} />Add Vendor</Button> </Box>
                                                        }

                                                        {activeState == "end-clients" && <Box id="addendclient" sx={{ padding: '5px', marginLeft: '-10px' }}>
                                                            <Button id='addendclient' sx={{ width: '100%' }} addButton ><img src={Userplus} alt='plus' className={classes.addIcon} />Add End Client</Button></Box>}
                                                    </Grid>
                                                </>}
                            </Grid>
                            <Grid item container lg={11.6} md={11} sm={11} xs={11} pt={4}>
                                {
                                    activeState == "clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed !== true))) ? "" :
                                        activeState == "vendors" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed !== true))) ? "" :
                                            activeState == "end-clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed !== true))) ? "" :
                                                <Grid item container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center' sx={{ borderBottom: "1px solid #F3EFEF", padding: "16px 12px 5px 12px" }}>
                                                    <Grid item lg={4} md={4} sm={4} xs={4} textAlign={'left'} display='flex' flexDirection='row' alignItems='center'>
                                                        <Text mediumBoldBlack2>{activeState == 'clients' ? 'Clients' : activeState == 'vendors' ? 'Vendors' : activeState == 'end-clients' ? 'End Clients' : ''}</Text>
                                                        <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} >
                                                            <FilterListIcon sx={{
                                                                height: "19px", color: ((activeState == 'clients' && clientRows.length == 0) || (activeState == 'vendors' && vendorRows.length == 0) || (activeState == 'end-clients' && endCltRows.length == 0)) ? '#777A8E' : '#2BEA2B'
                                                            }} />
                                                        </IconButton>

                                                    </Grid>
                                                    <Grid item lg={2} md={2} sm={2} xs={2} textAlign='left'>
                                                        <Text mediumBoldBlack2>Primary Contact</Text>
                                                    </Grid>
                                                    <Grid item lg={2} md={2} sm={2} xs={2} textAlign='left'>
                                                        <Text mediumBoldBlack2>Phone Number</Text>
                                                    </Grid>
                                                    <Grid item container lg={1.5} md={2} sm={2} xs={2} justifyContent='center'>
                                                        <Grid aria-controls="status-menu" item lg={6} className={classes.statusGrid}>
                                                            <Text mediumBoldBlack2 sx={{ paddingRight: '5px' }}>Status</Text>
                                                            <img src={arrowUp} alt='up' className={classes.cursor} />
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item lg={2.5} md={2} sm={2} xs={2}>
                                                        <Text offBoardBodyFont>Count
                                                            <BlackToolTip arrow placement='bottom' title={
                                                                <Box p={'4px'}>
                                                                    <Text mediumWhite400>Total {activeState == 'clients' ? 'Clients' : activeState == 'vendors' ? 'Vendors' : activeState == 'end-clients' ? 'End-Clients' : ''} count is based<br /> on the filters applied</Text>
                                                                </Box>
                                                            }>
                                                                <img src={info} alt='info' className={classes.infoIcon} />
                                                            </BlackToolTip>
                                                        </Text>
                                                    </Grid>
                                                </Grid>
                                }


                                <>
                                    {
                                        activeState == "clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed !== true))) ? <>{NoPermission()}</> :
                                            activeState == "vendors" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed !== true))) ? <>{NoPermission()}</> :
                                                activeState == "end-clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed !== true))) ? <>{NoPermission()}</> :
                                                    <>
                                                        {
                                                            (activeState == 'clients' ? clientRows.length > 0 : activeState == 'vendors' ? vendorRows.length > 0 : activeState == 'end-clients' ? endCltRows.length > 0 : '') ?
                                                                <Grid container className={classes.scrollContent}  >
                                                                    {
                                                                        (activeState == 'clients' ? clientRows.length > 0 && clientRows : activeState == 'vendors' ? vendorRows.length > 0 && vendorRows : activeState == 'end-clients' ? endCltRows.length > 0 && endCltRows : '').map((item, key) => (
                                                                            <Accordion
                                                                                key={key}
                                                                                className={classes.newCustomAccordion}
                                                                                expanded={expanded === `panel${key}`} >
                                                                                <AccordionSummary
                                                                                    // expandIcon={selectedOption === "All employees" && <ExpandMoreIcon style={{ '& svg path': { stroke: 'black', strokeWidth: 2 }, color: '#000000' }} />}
                                                                                    aria-controls="panel1bh-content"
                                                                                    id="panel1bh-header"
                                                                                    className={classes.AccordionSummary}                                                    >
                                                                                    <Grid item container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                                                        <Grid item lg={4.1} md={4} sm={4} xs={4} textAlign='start'>
                                                                                            <Box display='flex' alignItems='center' gap={2}>
                                                                                                <CustomCircularProgress

                                                                                                    color={'#ffffff'} src={item.document_url}
                                                                                                    name={item.document_url == undefined || item.document_url == null || item.document_url == '' ? item.name && item.name[0] : ''}
                                                                                                    imgWidth='50px !important'
                                                                                                    imgHeight='50px !important'
                                                                                                    avatarWidth='50px !important'
                                                                                                    avatarHeight='50px !important'
                                                                                                    size={58}

                                                                                                    dashboardContent={item.status == 'Active' && item.profile_percentage == 50 ? <Button addButtonTooltip sx={{ margin: '10px 0px 5px 0px' }} >Complete Profile</Button> : ''}
                                                                                                />
                                                                                                <Box>
                                                                                                    {
                                                                                                        item.status == 'In Active' ?
                                                                                                            <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                                                {
                                                                                                                    capitalizeAndAddSpace(item.name)
                                                                                                                }
                                                                                                            </Text>
                                                                                                            :
                                                                                                            <Text mediumBoldBlack style={{ margin: '4px 0px' }}>
                                                                                                                {
                                                                                                                    capitalizeAndAddSpace(item.name)
                                                                                                                }

                                                                                                            </Text>
                                                                                                    }
                                                                                                    <Text mediumLabel noWrap style={{ margin: '4px 0' }}>  {item.reference_id === "" ? "--" : item.reference_id}</Text>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                        <Grid item lg={2} md={2} sm={2} xs={2} textAlign='left'>
                                                                                            <Text mediumBlack14>{

                                                                                                capitalizeAndAddSpace(item.primary_contact)
                                                                                            }</Text>
                                                                                        </Grid>
                                                                                        <Grid item lg={2} md={2} sm={2} xs={2} textAlign='left' pl={'5px'}>
                                                                                            <Text mediumBlack14>{item.telephone_number ? item.telephone_number : '--'}</Text>
                                                                                        </Grid>
                                                                                        <Grid item container lg={2} md={2} sm={2} xs={2} justifyContent='space-around' display='flex' flexDirection='row' alignItems='center'>
                                                                                            <Divider orientation="vertical" flexItem style={{ height: '50px' }} />
                                                                                            <Box className={item.status == 'Active' ? classes.activeStatus : classes.inactiveStatus}>
                                                                                                {item.status == 'Active' ? 'Active' : 'Inactive'}
                                                                                            </Box>
                                                                                        </Grid>
                                                                                        <Grid item lg={1.9} md={2} sm={2} xs={2} id='viewprofile' p={1}>
                                                                                            <Text mediumBlue >View Profile</Text>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails>
                                                                                    <Box className={classes.AccordionDetails} pt={1}>
                                                                                        <Grid container lg={12} md={12} sm={12} xs={12} justifyContent='center' alignItems='center'>
                                                                                            <Grid item lg={4} md={4} sm={4} xs={4} textAlign='center'>
                                                                                                <Text mediumGrey2>Email ID</Text>
                                                                                                <Text smallBoldBlack sx={{ paddingTop: '5px' }}>
                                                                                                    {
                                                                                                        item.contact_email ? item.contact_email.length > 16 ?
                                                                                                            <BlackToolTip title={item.contact_email} placement="top" arrow>
                                                                                                                {(item.contact_email.slice(0, 16)) + (item.contact_email.length > 16 ? "..." : "")}
                                                                                                            </BlackToolTip>
                                                                                                            : (item.contact_email) : '--'
                                                                                                    }
                                                                                                </Text>
                                                                                            </Grid>
                                                                                            <Grid item lg={4} md={4} sm={4} xs={4} textAlign='center'>
                                                                                                <Text mediumGrey2>Mobile Number</Text>
                                                                                                <Text smallBoldBlack sx={{ paddingTop: '5px' }}>{item.mobile_number ? item.mobile_number : '--'}</Text>
                                                                                            </Grid>
                                                                                            <Grid item lg={4} md={4} sm={4} xs={4} textAlign='center'>
                                                                                                <Text mediumGrey2>Extension</Text>
                                                                                                <Text smallBoldBlack sx={{ paddingTop: '5px' }}>{item.telephone_number_ext ? item.telephone_number_ext : '--'}</Text>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Box>
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        ))
                                                                    }
                                                                    <div />
                                                                </Grid>
                                                                :
                                                                ''
                                                        }
                                                    </>
                                    }
                                </>

                            </Grid>
                        </Grid >
                        :
                        <Grid container>
                            <Box>
                                <Text mediumViewAmt>Clients</Text>
                            </Box>
                            {NoPermission()}
                        </Grid>
                }
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}

                >
                    <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                </Backdrop>

            </ >}

            {
                showClients == 'clientProfile' &&
                <Box sx={{ width: "100%" }} px={5}>

                    <Box mx={2} >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Text boldGrey14 className={classesCompany.cursor}>All Clients</Text>
                            <Text largeBldBlack>Client Profile</Text>
                        </Breadcrumbs>
                    </Box>
                    <Hidden lgDown>
                        {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) ?
                            <Box mx={2}>
                                <Grid container spacing={0} columnSpacing={3}>
                                    <Grid item container lg={3.5} md={3} sm={4} xs={12} pr={2}>
                                        <Box className={classesCompany.cardBg}>
                                            {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_edit" && item.is_allowed == true))) &&
                                                <Box width={"100%"} display={'flex'} justifyContent={'end'} px={2} pt={2} >


                                                    <img src={menu} alt='menu' id='threedots' className={classesCompany.cursor} onClick={handleClickMenu} />

                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                        sx={{
                                                            '& .MuiPaper-root, .MuiMenu-list': {
                                                                padding: '0px ',
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem className={classesCompany.inactive} id='deactivationoption'>
                                                            <Text smallBlack>Deactivate</Text>

                                                        </MenuItem>
                                                    </Menu>
                                                </Box>}

                                            <Box textAlign={'center'}>


                                                <CustomCircularProgress

                                                    color={'#ffffff'}
                                                    src={viewData.logo_url}
                                                    name={viewData.name[0]}
                                                    imgWidth='80px !important'
                                                    imgHeight='80px !important'
                                                    avatarWidth='80px !important'
                                                    avatarHeight='80px !important'
                                                    value={100}
                                                    size={88}


                                                />

                                            </Box>
                                            <Grid item lg={12} textAlign='center' >

                                                <Text largeHeaderText sx={{ textTransform: 'capitalize' }}>
                                                    {
                                                        viewData.name && viewData.name.length > 16 ?
                                                            <BlackToolTip title={capitalizeAndAddSpace(viewData.name)} placement="top" arrow>
                                                                {capitalizeAndAddSpace(viewData.name).slice(0, 16) + (capitalizeAndAddSpace(viewData.name.length > 16 ? "..." : ""))}
                                                            </BlackToolTip>
                                                            : capitalizeAndAddSpace(viewData.name)
                                                    }
                                                </Text>

                                            </Grid>
                                            <Grid item lg={12} textAlign='center' py={2}>

                                                <Text largeGrey14 noWrap>{viewData.reference_id}</Text>

                                            </Grid>
                                            <Grid item lg={12} textAlign='center' mb={2}>

                                                <Text mediumBoldBlue noWrap sx={{ cursor: 'pointer' }}>Activity Log</Text>

                                            </Grid>
                                            <Box px={3}>
                                                <Divider />
                                            </Box>
                                            <Box p={3} className={classesCompany.clientList}>{
                                                dataArr.map((item, key) => (
                                                    <Box
                                                        id={item.replace(' ', '')}

                                                        sx={{ padding: '3px', display: 'flex ', flexDirection: "column", justifyContent: 'center', alignItems: 'center', }} >
                                                        <ListItemButton
                                                            key={key}
                                                            sx={{ width: '90% ' }}
                                                            className={`${classesCompany.listItems} ${current === item ? classesCompany.listItemsActive : null}`}
                                                            onClick={() => {
                                                                setCurrent(item);
                                                            }}

                                                        >
                                                            <Grid container>
                                                                <Grid item xs={10}>{item}</Grid>

                                                            </Grid>
                                                        </ListItemButton>
                                                    </Box>
                                                ))}</Box>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={8.5} md={9} sm={8} xs={12}>
                                        <Box sx={{ width: '100%', height: true ? "78.5vh" : "70vh", borderRadius: "8px", overflow: 'auto', overflowX: 'hidden' }}>
                                            <Box>
                                                <Box m={0.5} mr={0.1} mb={0} sx={{ width: '100% !important', overflowX: 'hidden' }}>

                                                    <Box sx={{ height: '70vh', width: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                        <Box m={.5} mr={0.1} sx={{ width: '100% !important' }}>  <Grid container p={1} pr={0} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", }}>
                                                            <Grid container>
                                                                <Grid item lg={11} md={11} sm={11} xs={11}>
                                                                    <Box p={1} pl={2} pt={2}>
                                                                        <Text largeBlue>
                                                                            {current}
                                                                        </Text>
                                                                    </Box>
                                                                </Grid>


                                                                <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                                                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                                                        <img src={noDataFound} alt='nodata' />
                                                                    </Grid>
                                                                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                                        <Text largeBlack>No Data Found</Text>
                                                                    </Grid>


                                                                </Grid>
                                                                <Grid container>
                                                                    <Box height={'5vh'} />
                                                                </Grid>
                                                                <Backdrop
                                                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}

                                                                >
                                                                    <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                                                                </Backdrop>
                                                            </Grid>


                                                        </Grid>  </Box>
                                                    </Box>
                                                </Box>


                                            </Box>

                                        </Box>
                                    </Grid>

                                </Grid>
                            </Box >
                            :
                            <>
                                {NoPermission()}
                            </>
                        }
                    </Hidden>
                    <Hidden lgUp>
                        <Grid container md={12} xs={12} justifyContent='center' mt={2}>
                            <Grid item container md={11} xs={12} sx={{
                                border: '1px solid #EAECF0', borderRadius: '12px', padding: '15px'
                            }}>
                                <Grid item container md={12} xs={12} columnSpacing={2}>
                                    {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_edit" && item.is_allowed == true))) &&
                                        <Box width={"100%"} display={'flex'} justifyContent={'end'} pt={2}>

                                            <img src={menu} id="threedots" alt='menu' className={classesCompany.cursor} onClick={handleClickMenu} />

                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                sx={{
                                                    '& .MuiPaper-root, .MuiMenu-list': {
                                                        padding: '0px ',
                                                    },
                                                }}
                                            >
                                                <MenuItem className={classesCompany.inactive} id='deactivationoption'>
                                                    <Text smallBlack>Deactivate</Text>

                                                </MenuItem>
                                            </Menu>
                                        </Box>}
                                    <Grid item md={2} xs={3}>
                                        <CustomCircularProgress
                                            color={'#ffffff'}
                                            src={viewData.logo_url}
                                            name={viewData.name[0]}
                                            imgWidth='80px !important'
                                            imgHeight='80px !important'
                                            avatarWidth='80px !important'
                                            avatarHeight='80px !important'
                                            value={viewData.profile_percentage}
                                            size={88}

                                        />
                                    </Grid>
                                    <Grid item container md={6} xs={6} alignItems='center' ml={1}>
                                        <Box alignItems='center'>
                                            <Box display='flex' flexDirection='row' gap={1} justifyContent='center' alignItems='center'>
                                                <Text className={classesCompany.profileName}>
                                                    <BlackToolTip arrow placement='top' title={viewData.name}>
                                                        {viewData.name.slice(0, 14)}{viewData.name.length > 14 ? "..." : ""}
                                                    </BlackToolTip>
                                                </Text>
                                            </Box>
                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                <Text className={classesCompany.profileId}>{viewData.reference_id}</Text>
                                                <Text mediumBoldBlue noWrap sx={{ cursor: 'pointer' }}>Activity Log</Text>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item container md={12} xs={12} columnSpacing={2} >
                                    {
                                        dataArr.map((item, key) => (
                                            <Box
                                                id={item.replace(' ', '')}

                                                sx={{ padding: '5px', display: 'flex ', flexDirection: "column", justifyContent: 'center', alignItems: 'center', }} >

                                                <Grid item md={3} xs={3}>
                                                    <Box display='flex' flexDirection='row' justifyContent={'center'} gap={1}>
                                                        {
                                                            current == item ?
                                                                <Text analyticsViewAll sx={{ cursor: 'pointer !important' }} onClick={() => {



                                                                    setCurrent(item);


                                                                }}>{item}</Text> :
                                                                <Text mediumBoldBlack400 sx={{ cursor: 'pointer !important' }} onClick={() => {



                                                                    setCurrent(item);

                                                                }}>{item}</Text>
                                                        }

                                                    </Box>
                                                </Grid>
                                            </Box>
                                        ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container md={12} xs={12} pt={2} justifyContent='center'>
                            <Grid item container md={11} xs={12}>
                                <Box sx={{ width: '100%', height: true ? "78.5vh" : "70vh", borderRadius: "8px", overflow: 'auto', overflowX: 'hidden' }}>
                                    <Box>


                                        <Box m={0.5} mr={0.1} mb={0} sx={{ width: '100% !important', overflowX: 'hidden' }}>

                                            <Box sx={{ height: '70vh', width: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                <Box m={.5} mr={0.1} sx={{ width: '100% !important' }}>  <Grid container p={1} pr={0} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", }}>
                                                    <Grid container>
                                                        <Grid item lg={11} md={11} sm={11} xs={11}>
                                                            <Box p={1} pl={2} pt={2}>
                                                                <Text largeBlue>
                                                                    {current}
                                                                </Text>
                                                            </Box>
                                                        </Grid>


                                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                                            <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                                                <img src={noDataFound} alt='nodata' />
                                                            </Grid>
                                                            <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                                <Text largeBlack>No Data Found</Text>
                                                            </Grid>


                                                        </Grid>



                                                        <Grid container>
                                                            <Box height={'5vh'} />
                                                        </Grid>
                                                        <Backdrop
                                                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}


                                                        >
                                                            <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                                                        </Backdrop>
                                                    </Grid>


                                                </Grid>  </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                </Box>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Box>

            }
        </>

    )
};
