import React, { useEffect, useState } from 'react';
import UserProfileStyles from '../UserProfileStyles';
import { Box, Breadcrumbs, Grid, Divider, ListItemButton, Menu, MenuItem, CircularProgress, Skeleton, SwipeableDrawer, Stack, Hidden } from '@mui/material';
import Overview from './Overview';
import CompanyDetails from './CompanyDetails';
import ContactDetails from './ContactDetails';
import { useLocation, useNavigate, } from 'react-router-dom';
import { addSuccessMsg, addErrorMsg, BlackToolTip, capitalizeAndAddSpace, NoPermission } from '../../../../../utils/utils';
import LocalStorage from '../../../../../utils/LocalStorage';
import ClientsAPI from '../../../../../apis/admin/clients/ClientsApi';
import Text from '../../../../../components/customText/Text';
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import deactivateImg from '../../../../../assets/svg/clients/clientDeactivate.svg';
import CustomCircularProgress from '../../../../../components/progressbar/CircularProgress'
import Button from '../../../../../components/customButton/Button';
import menu from '../../../../../assets/svg/clients/client-menu-withoutbg.svg';
import PlacementDetails from './PlacementDetails';
import CommonApi from '../../../../../apis/CommonApi';
import ActivityLog from '../../../employees/userprofile/ActivityLog';
import FileSaver from 'file-saver';
import eVerifyPending from '../../../../../assets/employee/pendingEverify.svg';
import offBoardSave from "../../../../../assets/svg/offBoardSave.svg"
import deactivatedPlacement from '../../../../../assets/svg/clients/deactivated-placement.svg';
import Sure from '../../../../../assets/svg/placementPop.svg'
import LoadingButton from '../../../../../components/customButton/LoadingButton';

const dataArr = ["Client Details", "Placements", "Contact Details", "Analytics"];

export default function UserProfile(props) {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = UserProfileStyles();
    const location = useLocation();
    const navigate = useNavigate()
    const id = LocalStorage.getRedirectedModule() !== null && LocalStorage.getRedirectedModule() == 'client' ? LocalStorage.getNotificationId() : location.state.data.id;
    const complete = location && location.state && location.state.complete
    const flow = LocalStorage.getRedirectedModule() !== null && LocalStorage.getRedirectedModule() == 'client' ? '' : location.state.flow
    const [action, setAction] = useState('view');
    const [actionContact, setActionContact] = useState('view');
    const [current, setCurrent] = useState("Client Details");// eslint-disable-next-line 
    const [stepItem, setStepItem] = useState('Client Details');
    const [loading, setLoading] = useState(true); // eslint-disable-next-line 
    const [statusLoading, setStatusLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [deletePopup, setDeletePopup] = useState(false);
    const [activityTotal, setActivityTotal] = useState();
    const [activityData, setActivityData] = useState([]);
    const [openPopup, setOpenpopup] = useState(false);
    const [eventData, setEventData] = useState('');
    const [enbaleEdit, setEnableEdit] = useState('');
    const [placementEnable, setPlacementEnable] = useState(false);
    const [activityFilter, setActivityFilter] = useState({
        limit: 100,
        page: 1,
        search: "",
        request_id: LocalStorage.uid(),
        employee_id: ''
    })
    const [formData, setFormData] = useState(
        {
            name: "",
            reference_id: "",
            logo_path_id: '',
            logo_name: '',
            logo_url: '',
            address: [
                {
                    address_line_one: "",
                    address_line_two: "",
                    city: "",
                    state_id: "",
                    country_id: "",
                    country: null,
                    zip_code: ""
                }
            ],net_pay_terms_id:'',net_pay_term_days:''
        }
    );
    const [viewData, setViewData] = useState(
        {
            name: "",
            reference_id: "",
            logo_path_id: '',
            logo_name: '',
            logo_url: '',
            address: [
                {
                    address_line_one: "",
                    address_line_two: "",
                    city: "",
                    state_id: "",
                    country_id: "",
                    country: null,
                    zip_code: ""
                }
            ],net_pay_terms_id:'',net_pay_term_days:''
        }
    );
    const [activityAnchorEl, setActivityAnchorEl] = useState(null);
    const activityOpen = Boolean(activityAnchorEl)
    const [loader, setLoader] = useState(false)
    const handleActivityClick = (event) => {
        setActivityAnchorEl(event.currentTarget);
    };

    const handleActivityClose = () => {
        setActivityAnchorEl(null);
    };

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) {
            getCompanyDetails();
            getActivity(activityFilter);
            // if ((!LocalStorage.getUserData().super_admin &&) && (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed !== true))) {
            //     for (let i = 0; i < dataArr.length; i++) {
            //         if (dataArr[i] == 'Overview') {
            //             dataArr.splice('Overview', 1)
            //         }
            //     }
            // }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (complete == 'contact' || flow == 'placementContact') {
            setCurrent('Contact Details');
        }
        if (current == 'Client Details') {
            setAction('view')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) {
            getActivity()
        }
        // eslint-disable-next-line
    }, [activityFilter])

    const getCompanyDetails = () => {
        setLoading(true);
        ClientsAPI.companyIndexApi(id, "client").then((response) => {
            setTimeout(() => {
                setLoading(false);
                if (response.data.statusCode == 1003) {
                    setFormData({ ...response.data.data });
                    setViewData({ ...response.data.data })
                } else {
                    addErrorMsg(response.data.message);
                }
            }, 400)
        });
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const updateClientStatus = () => {
        let data = {
            request_id: LocalStorage.uid(),
            status: viewData.status == 'Active' ? 'In Active' : 'Active',
            id: id
        };
        setLoader(true)
        setStatusLoading(true)
        ClientsAPI.updateStatus("client", data).then((response) => {
            setTimeout(() => {
                setStatusLoading(false)
                if (response.data.statusCode == 1003) {
                    setDeletePopup(false);
                    setLoader(false)
                    setAnchorEl(null);
                    getActivity();
                    getCompanyDetails();
                    setCurrent('Client Details');
                    if (response.data.is_company_mapped) {
                        if (viewData.status == 'In Active') {
                            setPlacementEnable(false);
                        } else {
                            setPlacementEnable(true);
                        }
                    } else {
                        addSuccessMsg(response.data.message);
                    }

                } else {
                    setLoader(false)
                    addErrorMsg(response.data.message);
                }
            }, 400)
        });
    }

    const uploadProfile = (e) => {
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg'].includes(r))) {
            // if (((e.target.files[0].size / (1024 * 1024)).toFixed(2)) < '25') {
            setStatusLoading(true)
            const formData1 = new FormData();
            formData1.append("files", e.target.files[0]);
            formData1.append("tenant_id", LocalStorage.getUserData().tenant_id);
            CommonApi.documentUpload("company-logo", formData1)
                .then((response) => {
                    if (response.data.statusCode == 1003) {
                        let data = {
                            request_id: LocalStorage.uid(),
                            logo_path_id: response.data.data.id,
                            id: id
                        };
                        ClientsAPI.uploadClientLogo("client", data).then((response) => {
                            if (response.data.statusCode == 1003) {
                                formData['logo_url'] = (response.data.data.logo_url);
                                setFormData({ ...formData });
                                viewData['logo_url'] = (response.data.data.logo_url);
                                setViewData({ ...viewData });
                                addSuccessMsg('Logo Uploaded Successfully') ;
                                getActivity();
                                getCompanyDetails();
                                setStatusLoading(false);
                            } else {
                                setStatusLoading(false);
                                addErrorMsg(response.data.message);
                            }
                        });
                    } else {
                        setStatusLoading(false);
                        addErrorMsg(response.data.message);
                    }
                });
            // }
            // else {
            //     addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            // }
        } else {
            setLoading(false);
            addErrorMsg("Please upload files in PNG or JPG format only.");
        }
    }
    const completeProfile = () => {
        if (viewData.profile_percentage == 50) {
            setCurrent("Contact Details");
            setActionContact('view')
        }
    }

    const openDeletPop = () => {
        if (props.formEdit && (action == 'update' || actionContact == 'update')) {
            setOpenpopup(true);
            setEnableEdit('Status');
        } else {
            setDeletePopup(true);
        }
    }

    const downloadActivity = () => {
        let data = {
            request_id: LocalStorage.uid(),
            id: id
        }
        ClientsAPI.exportactivity('client', data).then((response) => {
            if (response.data.statusCode == 1003) {
                let url = response.data.data[0].file_path
                let fileName = url.split('/exports/')
                FileSaver.saveAs(response.data.data[0].file_path, fileName[1]);
            }
            else {
                addErrorMsg(response.data.message)
            }
        });
    }

    const getActivity = (args) => {
        let data = {
            from: '',
            to: '',
            request_id: LocalStorage.uid(),
            id: id,
            search: activityFilter.search,
        }
        ClientsAPI.downloadActivity(data, 'client').then((response) => {
            if (response.data.statusCode == 1003) {
                setActivityData(response.data.data);
                setActivityTotal(response.data.pagination.total)
            }
        });
    }

    const discontinueHandler = () => {
        props.setformEdit(false);
        setCurrent(stepItem);
        setOpenpopup(false);
        getCompanyDetails();
        setActionContact('view'); setAction('view');
        setAnchorEl(null);
        if (enbaleEdit == 'upload') {
            uploadProfile(eventData);
        }
        if (enbaleEdit == 'clientNavigate') {
            navigate('/clients')
        }
        if (enbaleEdit == 'Status') {
            updateClientStatus();
        }
    }

    const breadCrumbsHandler = () => {
        if (props.formEdit && (action == 'update' || actionContact == 'update')) {
            setOpenpopup(true);
            setEnableEdit('clientNavigate');
        } else {
            navigate('/clients', { state: { page: '' } })
        }
    }

    const cilentDeleteApi = () => {
        let data = {
            request_id: LocalStorage.uid(),
            id: id
        };
        ClientsAPI.cilentProfileDeleteApi(data, 'client').then((res) => {
            if (res.data.statusCode == 1003) {
                addSuccessMsg('Logo Deleted Successfully');
                getActivity();
                getCompanyDetails();
                setStatusLoading(false);
            } else {
                setStatusLoading(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    return (
        <Box className={classes.mainContainer} px={5} py={0}>
            <Box mx={2}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Text boldGrey14 onClick={breadCrumbsHandler} className={classes.cursor}>All Clients</Text>
                    <Text largeBldBlack>Client Profile</Text>
                </Breadcrumbs>
            </Box>
            <Hidden lgDown>
                {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) ?
                    <Box my={2} mx={2}>
                        <Grid container spacing={0} columnSpacing={3}>
                            <Grid item container lg={3.5} md={3} sm={4} xs={12} pr={2}>
                                <Box className={classes.cardBg}>
                                    {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_edit" && item.is_allowed == true))) &&
                                        <Box width={"100%"} display={'flex'} justifyContent={'end'} px={2} pt={2}>
                                            {
                                                loading ?
                                                    <Grid item container lg={12} justifyContent='end'>
                                                        <Skeleton animation='linear' className={classes.skeleton} />
                                                    </Grid>
                                                    :
                                                    <img src={menu} alt='menu' className={classes.cursor} onClick={handleClickMenu} />
                                            }
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                sx={{
                                                    '& .MuiPaper-root, .MuiMenu-list': {
                                                        padding: '0px ',
                                                    },
                                                }}
                                            >
                                                {
                                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_edit" && item.is_allowed == true))) ?
                                                        <MenuItem onClick={openDeletPop} className={classes.inactive}>
                                                            <Text smallBlack>{viewData.status == 'In Active' ? 'Activate' : 'Deactivate'}</Text>
                                                            {statusLoading ? <CircularProgress color="secondary" size={"12px"} sx={{ margin: "0px 6px" }} /> : ""}
                                                        </MenuItem> :
                                                        <MenuItem className={classes.inactive}><Text greyLabel sx={{ cursor: 'default' }}>{viewData.status == 'In Active' ? 'Activate' : 'Deactivate'}</Text></MenuItem>
                                                }
                                            </Menu>
                                        </Box>}

                                    <Box textAlign={'center'}>
                                        {
                                            loading ?
                                                <Grid item container lg={12} justifyContent='center'>
                                                    <Skeleton animation='linear' className={classes.profileskeleton} />
                                                </Grid> :
                                                <CustomCircularProgress
                                                    percentage={viewData.profile_percentage}
                                                    color={viewData.profile_percentage >= 76 ? 'green' : viewData.profile_percentage <= 75 && viewData.profile_percentage >= 51 ? 'yellow' : viewData.profile_percentage <= 50 ? "#FFBF00" : ''}
                                                    src={viewData.logo_url}
                                                    name={viewData.name[0]}
                                                    imgWidth='80px !important'
                                                    imgHeight='80px !important'
                                                    avatarWidth='80px !important'
                                                    avatarHeight='80px !important'
                                                    value={viewData.profile_percentage}
                                                    size={88}
                                                    edit={(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_edit" && item.is_allowed == true))) && viewData.status == 'Active'}
                                                    handleEdit={(e) => props.formEdit && (action == 'update' || actionContact == 'update') ? (setOpenpopup(true), setEventData(e), setEnableEdit('upload')) : uploadProfile(e)}
                                                    deleteButton={cilentDeleteApi}
                                                    tooltip
                                                    tooltipContent={
                                                        <Box p={'3px 0px'} width='100%'>
                                                            <Text mediumBoldWhite400>{`Profile Completion - ${viewData.profile_percentage}%`}</Text>
                                                            {
                                                                viewData.profile_percentage == 100 ? '' : <Button addButtonTooltip sx={{ marginTop: '10px' }} onClick={completeProfile}>Complete Profile</Button>
                                                            }
                                                        </Box>
                                                    }

                                                />
                                        }
                                    </Box>
                                    <Grid item lg={12} textAlign='center' mt={1}>
                                        {
                                            loading ?
                                                <Grid item container lg={12} justifyContent='center'>
                                                    <Skeleton animation='linear' className={classes.textSkeleton} />
                                                </Grid> :
                                                <Text largeHeaderText sx={{ textTransform: 'capitalize' }}>
                                                    {
                                                        viewData.name && viewData.name.length > 16 ?
                                                            <BlackToolTip title={capitalizeAndAddSpace(viewData.name)} placement="top" arrow>
                                                                {capitalizeAndAddSpace(viewData.name).slice(0, 16) + (capitalizeAndAddSpace(viewData.name.length > 16 ? "..." : ""))}
                                                            </BlackToolTip>
                                                            : capitalizeAndAddSpace(viewData.name)
                                                    }
                                                </Text>
                                        }
                                    </Grid>
                                    <Grid item lg={12} textAlign='center' py={2}>
                                        {
                                            loading ?
                                                <Grid item container lg={12} justifyContent='center'>
                                                    <Skeleton animation='linear' className={classes.textSkeleton} />
                                                </Grid> :
                                                <Text largeGrey14 noWrap>{viewData.reference_id}</Text>
                                        }
                                    </Grid>
                                    <Grid item lg={12} textAlign='center' mb={4}>
                                        {
                                            loading ?
                                                <Grid item container lg={12} justifyContent='center'>
                                                    <Skeleton animation='linear' className={classes.textSkeleton} />
                                                </Grid> :
                                                <Text mediumBoldBlue noWrap onClick={handleActivityClick} sx={{ cursor: 'pointer' }}>Activity Log</Text>
                                        }
                                    </Grid>
                                    <Box px={3}>
                                        <Divider />
                                    </Box>
                                    <Box p={3} className={classes.clientList}>
                                        {
                                            loading ?
                                                <>
                                                    {
                                                        [1, 2, 3, 4].map(() => (
                                                            <Grid item container lg={12} justifyContent='center'>
                                                                <Skeleton animation='linear' className={classes.textSkeleton} />
                                                            </Grid>
                                                        ))
                                                    }
                                                </> :
                                                <>
                                                    {
                                                        dataArr.map((item, key) => (
                                                            <ListItemButton
                                                                key={key}
                                                                className={`${classes.listItems} ${current === item ? classes.listItemsActive : null}`}
                                                                onClick={() => {
                                                                    //  setCurrent(item); 
                                                                    // if(current == item){}
                                                                    // else{
                                                                    //     setActionContact('view'); setAction('view')
                                                                    // }                                   
                                                                    if (current == item) { }
                                                                    else if (props.formEdit) {
                                                                        setOpenpopup(true); setStepItem(item)
                                                                    }
                                                                    else {
                                                                        setCurrent(item);
                                                                        setActionContact('view'); setAction('view'); setStepItem(item)
                                                                    }
                                                                }}
                                                            >
                                                                <Grid container>
                                                                    <Grid item xs={10}>{item}</Grid>
                                                                    {item === 'Contact Details' && viewData.profile_percentage == 50 &&
                                                                        <Grid item container justifyContent={'flex-end'} xs={2}>
                                                                            <BlackToolTip arrow placement='right' title={'Pending'}>
                                                                                <img src={eVerifyPending} alt='pending' />
                                                                            </BlackToolTip>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </ListItemButton>
                                                        ))}
                                                </>
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={8.5} md={9} sm={8} xs={12}>
                                <Box className={classes.cardContent}>
                                    {
                                        current === "Client Details" ? <CompanyDetails formEdit={props.formEdit} setformEdit={props.setformEdit} id={id} action={action} setAction={setAction} formData={formData} setFormData={setFormData} getCompanyDetails={getCompanyDetails} loading={loading} setLoading={setLoading} getActivity={getActivity} /> : null
                                    }
                                    {
                                        current === "Placements" ? <PlacementDetails id={id} current={current} /> : null
                                    }
                                    {
                                        current === "Contact Details" ? <ContactDetails current={current} formEdit={props.formEdit} setformEdit={props.setformEdit} id={id} actionContact={actionContact} setActionContact={setActionContact} status={formData.status} getActivity={getActivity} loading={loading} setLoading={setLoading} getCompanyDetails={getCompanyDetails} flow={flow} /> : null
                                    }
                                    {
                                        current === "Analytics" ? <Overview id={id} /> : null
                                    }
                                </Box>
                            </Grid>
                            <ReusablePopup iconHide white openPopup={deletePopup} setOpenPopup={setDeletePopup} statusWidth>
                                <Box textAlign='center'>
                                    <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                        <img src={viewData.status == 'In Active' ? Sure : deactivateImg} alt="warning" />
                                    </Box>
                                    <Box my={3}>
                                        <Text popupHead1>
                                            Are You Sure?
                                        </Text>
                                        <Text largeLabel sx={{ fontWeight: '400 !important' }} my={3}>
                                            Do you want to {viewData.status == 'In Active' ? 'Activate' : 'Deactivate'} the client <span className={classes.blueText}>{capitalizeAndAddSpace(viewData.name)}</span>
                                        </Text>
                                    </Box>
                                    <Grid container lg={12} xs={12} justifyContent={'center'} alignContent={'center'} mt={1} spacing={2}>
                                        <Grid item lg={5} xs={6} textAlign='right'>
                                            <Button onClick={() => { setDeletePopup(false); setAnchorEl(null); }} redBorder>Cancel</Button>
                                        </Grid>
                                        <Grid item lg={5} xs={6} textAlign='left'>
                                            <LoadingButton onClick={updateClientStatus} loading={loader} blueButton>Yes</LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </ReusablePopup>
                        </Grid>
                    </Box >
                    :
                    <>
                        {NoPermission()}
                    </>
                }
            </Hidden>
            <Hidden lgUp>
                <Grid container md={12} xs={12} justifyContent='center' mt={2}>
                    <Grid item container md={11} xs={12} sx={{
                        border: '1px solid #EAECF0', borderRadius: '12px', padding: '15px'
                    }}>
                        <Grid item container md={9} xs={8} columnSpacing={2}>
                            <Grid item md={2} xs={3}>
                                <CustomCircularProgress
                                    percentage={viewData.profile_percentage}
                                    color={viewData.profile_percentage >= 76 ? 'green' : viewData.profile_percentage <= 75 && viewData.profile_percentage >= 51 ? 'yellow' : viewData.profile_percentage <= 50 ? "#FFBF00" : ''}
                                    src={viewData.logo_url}
                                    name={viewData.name[0]}
                                    imgWidth='80px !important'
                                    imgHeight='80px !important'
                                    avatarWidth='80px !important'
                                    avatarHeight='80px !important'
                                    value={viewData.profile_percentage}
                                    size={88}
                                    edit={(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_edit" && item.is_allowed == true))) && viewData.status == 'Active'}
                                    handleEdit={(e) => props.formEdit && (action == 'update' || actionContact == 'update') ? (setOpenpopup(true), setEventData(e), setEnableEdit('upload')) : uploadProfile(e)}
                                    deleteButton={cilentDeleteApi}
                                    tooltip
                                    tooltipContent={
                                        <Box p={'3px 0px'} width='100%'>
                                            <Text mediumBoldWhite400>{`Profile Completion - ${viewData.profile_percentage}%`}</Text>
                                            {
                                                viewData.profile_percentage == 100 ? '' : <Button addButtonTooltip sx={{ marginTop: '10px' }} onClick={completeProfile}>Complete Profile</Button>
                                            }
                                        </Box>
                                    }

                                />
                            </Grid>
                            <Grid item container md={6} xs={6} alignItems='center' ml={1}>
                                <Box alignItems='center'>
                                    <Box display='flex' flexDirection='row' gap={1} justifyContent='center' alignItems='center'>
                                        <Text className={classes.profileName}>
                                            <BlackToolTip arrow placement='top' title={viewData.name}>
                                                {viewData.name.slice(0, 14)}{viewData.name.length > 14 ? "..." : ""}
                                            </BlackToolTip>
                                        </Text>
                                    </Box>
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                        <Text className={classes.profileId}>{viewData.reference_id}</Text>
                                        <Text mediumBoldBlue noWrap onClick={handleActivityClick} sx={{ cursor: 'pointer' }}>Activity Log</Text>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item container md={12} xs={12} columnSpacing={2} pt={2}>
                            {
                                dataArr.map((item, key) => (
                                    <>
                                        <Grid item md={3} xs={3}>
                                            <Box display='flex' flexDirection='row' gap={1}>
                                                {
                                                    current == item ?
                                                        <Text analyticsViewAll sx={{ cursor: 'pointer !important' }} onClick={() => {
                                                            if (current == item) { }
                                                            else if (props.formEdit) {
                                                                setOpenpopup(true); setStepItem(item)
                                                            }
                                                            else {
                                                                setCurrent(item);
                                                                setActionContact('view'); setAction('view'); setStepItem(item)
                                                            }
                                                        }}>{item}</Text> :
                                                        <Text mediumBoldBlack400 sx={{ cursor: 'pointer !important' }} onClick={() => {
                                                            if (current == item) { }
                                                            else if (props.formEdit) {
                                                                setOpenpopup(true); setStepItem(item)
                                                            }
                                                            else {
                                                                setCurrent(item);
                                                                setActionContact('view'); setAction('view'); setStepItem(item)
                                                            }
                                                        }}>{item}</Text>
                                                }
                                                {
                                                    item === 'Contact Details' && viewData.profile_percentage == 50 ?
                                                        // <Grid item container justifyContent={'flex-end'} xs={2}>
                                                        <BlackToolTip arrow placement='right' title={'Pending'}>
                                                            <img src={eVerifyPending} alt='pending' />
                                                        </BlackToolTip>
                                                        : ''
                                                }
                                            </Box>
                                        </Grid>
                                    </>
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container md={12} xs={12} pt={2} justifyContent='center'>
                    <Grid item container md={11} xs={12}>
                        <Box className={current == 'Analytics' || current == 'Client Details' ? classes.responseiveBoxShadow : classes.responsiveContainer}>
                            {
                                current === "Client Details" ? <CompanyDetails formEdit={props.formEdit} setformEdit={props.setformEdit} id={id} action={action} setAction={setAction} formData={formData} setFormData={setFormData} getCompanyDetails={getCompanyDetails} loading={loading} setLoading={setLoading} getActivity={getActivity} /> : null
                            }
                            {
                                current === "Placements" ? <PlacementDetails id={id} current={current} /> : null
                            }
                            {
                                current === "Contact Details" ? <ContactDetails current={current} formEdit={props.formEdit} setformEdit={props.setformEdit} id={id} actionContact={actionContact} setActionContact={setActionContact} status={formData.status} getActivity={getActivity} loading={loading} setLoading={setLoading} getCompanyDetails={getCompanyDetails} flow={flow} /> : null
                            }
                            {
                                current === "Analytics" ? <Overview id={id} /> : null
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Hidden>
            <SwipeableDrawer
                anchor={'right'}
                open={activityOpen}
                onClose={handleActivityClose}
                // onOpen={toggleDrawer(true, state.view)}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <ActivityLog
                    activityTotal={activityTotal}
                    activityData={activityData}
                    activityFilter={activityFilter}
                    setActivityFilter={setActivityFilter}
                    downloadActivity={downloadActivity}
                    employee={id} setLoading={setLoading} loading={loading}
                    placeholder='Search by Client details'
                />
            </SwipeableDrawer>
            <ReusablePopup openPopup={openPopup} setOpenPopup={setOpenpopup} white iconHide fixedWidth>
                <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                    <img src={offBoardSave} alt='noactive' />
                </Grid>
                <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                    <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
                </Grid>
                <Grid container justifyContent={'center'} alignContent={'center'}>
                    <Text offBoardBodyFont>Your progress will not be saved</Text>
                </Grid>
                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                    <Stack direction={"row"} spacing={3}>
                        <Button smallBlackOutline onClick={() => { setOpenpopup(false) }}>
                            Cancel
                        </Button>
                        <Button smallBlue redBackground onClick={discontinueHandler}>
                            Yes
                        </Button>
                    </Stack>
                </Grid>
            </ReusablePopup>
            <ReusablePopup openPopup={placementEnable} setOpenPopup={setPlacementEnable} white iconHide crossIcon fixedWidth>
                <Grid container justifyContent={'center'} textAlign='center' alignContent={'center'} pb={2}>
                    <img src={deactivatedPlacement} alt='noactive' />
                </Grid>
                <Grid container justifyContent={'center'} textAlign={'center'}>
                    <Text mediumOverView>This client has existing active placements. <br /> Kindly end the active placements associated with the client to deactivate.   </Text>
                </Grid>
                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                    <Button save14 onClick={() => navigate('/placements')}>
                        Go To Placements
                    </Button>
                </Grid>
            </ReusablePopup>
        </Box >
    );
}