import { makeStyles } from "@mui/styles";

const AddSelectFormStyles = makeStyles({ 
    closeIcon: {
        color: "#2D2D2D !important",
        width: "17px !important",
        paddingTop: "1px !important",
    },
    popupContainer: {
        height: 'auto !important',
        width: "260px !important"
    },
    closeIconStyles: {
        cursor: 'pointer',
        height: '17px !important',
        width: '17px !important'
    },
    popupContactContainer: {
        height: 'auto !important',
        width: "280px !important",
        padding: '0px !important'
    },  
    optional: {
        font: '14px Quicksand !important',
        color: 'rgb(194 188 188) !important',
        marginLeft: '4px !important'
    } 
});

export default AddSelectFormStyles