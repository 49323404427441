import React, { useEffect, useState } from 'react'
import { Box, Typography, Tab, Tabs, Dialog, Paper, Grid } from '@mui/material'
import MainStyles from './MainStyles'
import { styled } from "@mui/styles";
import LeftNavigateArrow from '../../../assets/svg/LeftNavigateArrow.svg';
import Text from '../../../components/customText/Text';
import Employee from './employeeConfig/EmployeeConfig';
import Placement from './placementConfig/PlacementConfig';
import TimeSheet from './timesheetConfig/TimeSheetConfig';
import Invoice from './invoiceConfig/InvoiceConfig';
import EmployeeSelfService from './employeeSelfServiceConfig/EmployeeSelfServiceConfig';
import Clients from './clientConfig/ClientConfig';
import ExpenseManagement from './expenseConfig/ExpenseConfig';
import Templates from './templatesConfig/TemplatesConfig';
import Remainders from './remindersConfig/RemindersConfig';
import Group from './groupsConfig/GroupsConfig';
import Notifications from './notificationConfig/NotificationConfig';
import Organization from './organization/OrganizationConfig'
import GroupsConfig from './roleConfig/RoleConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import LocalStorage from '../../../utils/LocalStorage';
import Button from '../../../components/customButton/Button';
import { domain } from '../../../config/Domain';
import DashboardAPI from '../../../apis/admin/DashboardAPI';
import { addErrorMsg,addLoaderPlanExpired, removeExpiredLoader } from '../../../utils/utils';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import TourGuideConfigApi from '../../../apis/configurations/tourguideConfig/TourGuideConfigApi';
import PayrollConfig from './payroll/PayrollConfig';

const ConfigurationTab = ['Organization', "Payroll","Role", 'Employee', 'Placement',
  //  'Timesheet',
  // 'Invoices',
  // 'Employee Self Service', 
  // 'Clients',
  'Templates',
  //  'Groups',
  //   'Reminders',
  // 'Notifications',
];


// custom Tabs container
// eslint-disable-next-line
const TabsContainer = styled(Box)({
  borderBottom: '1px solid #C7CCD3',
  display: 'inline-block', // Display as inline-block
  width: 'auto', // Set width to fit content
  // maxWidth:'100/%',
  overflowX: 'auto', // Enable horizontal scrolling if needed
  whiteSpace: 'nowrap', // Prevent line breaks
});

// custom Tabs
const CustomTabs = styled(Tabs)({
  // overflowX: 'auto',
  '& .MuiTabs-indicator': {
    borderBottom: '1px solid #0C75EB',
  },
  // '& .MuiTabs-scrollable': {
  //   overflowX: 'hidden',
  // },
});

// custom Tab
const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: "Quicksand !important",
  color: "#737373 !important",
  fontSize: "15px !important",
  fontWeight: `${500} !important`,
  padding: "8px 10px !important",
  textTransform: 'none !important',
  // minWidth: 0,
  '&.Mui-selected': {
    color: '#0C75EB !important',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff !important',
  },
  "@media (min-width: 900px) and (max-width: 1400px)": {
    fontSize: "12px !important",
    fontWeight: "500 !important"
  }
}));


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ width: "100%" }}
        // sx={{ 
        //   '@media (max-width: 1000px)': {
        //     width: 'fit-content'
        //   },
        //  }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function ConfigurationPanel(props) {

  const classes = MainStyles();
  const [tab, setTab] = useState(0);
  const [internal, setInternal] = useState(false);
  const [current, setCurrent] = useState("Organization Details");
  const [orgConfigEdit,setOrgConfigEdit] = useState(false);
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const handleChangeTab = (event, newValue) => {
    if (LocalStorage.getUserData().org_config_set == false) {
      setOpen(true);
      setTab(0);
      setCurrent("Organization Details");
    }
    else {
      setInternal(false);
      if(newValue==3){
        setCurrent('Employment Type')
      }
      setTab(newValue);
      setOpen(false)
    }
  };// eslint-disable-next-line
  const [anchorEl, setAnchorEl] = React.useState(null);// eslint-disable-next-line
  const location = useLocation();
  const from = location && location.state && location.state.from

  useEffect(() => {
    // console.log(from, "from");
    if (from == 'notification') {
      setTab(2);
      setCurrent('Internal Employees');
    }
    if (from == 'placement') {
      setTab(0)
      setCurrent('Working Hours')
    }// eslint-disable-next-line
  }, [])

  useEffect(() => {
    getPlanCheck();
        // eslint-disable-next-line
}, []);

const tourGuideStart = () => {
  var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
  
  
  const lists = [
    { list: ['Organization Details','Working Hours' ,'Prefixes', 'Web Announcements'], index: 0 },
    { list: ['Role'], index: 2 },
    { list: ['Employment Type', 'Internal Employees', 'Employment Category', 'Skills', 'Onboarding Documents'], index: 3 },
    { list: ['Job Title'], index: 4 },
    { list: ['Email Templates'], index: 5 },
  ];
  let finalStepList = [];
  if (rolePermission && rolePermission.length > 0) {
    if (rolePermission.find(item => item.slug == 'organization_details_view' && item.is_allowed == true)) {
      finalStepList.push(...[{
        element: '#OrganizationDetails', popover: {
          title: 'Organization Details', description: `Welcome, ${LocalStorage.getUserData()?.full_name}! Let's set up your organization. Add address, organization name, logo, website, and contact email and save to continue.`, side: "right",
          align: 'start'
        }
      }, {
        element: '#WorkingHours', popover: {
          title: 'Working Hours', description: 'Adjust working hours for your organization. By default the working hours will be 2080 hours/year.', side: "right",
          align: 'start'
        }
      },
      {
        element: '#Prefixes',
        popover: {
          title: 'Prefixes', description: 'Set or modify codes for employees, placements, payments, etc.', side: "right",
          align: 'start',
        }
      },
      {
        element: '#WebAnnouncements', popover: {
          title: 'Web Announcements', description: 'Publish announcements with web and mobile banners for any company-wide communication', side: "right",
          align: 'start'
        }
      },])
    }
    if (rolePermission.find(item => item.slug == 'roles_view' && item.is_allowed == true)) {
      finalStepList.push(...[{
        element: '#Role', popover: {
          title: 'Role', description: 'Add roles that will be assigned to your internal employees. 5 roles will be available by default, you can add 5 more.', side: "right",
          align: 'start'
        }
      },])
    }
    finalStepList.push(...[{
      element: '#EmploymentType', popover: {
        title: 'Employment Type', description: 'Here you can manage the types of employment. By default they are set to Consultant & Contractor.', side: "right",
        align: 'start'
      }
    },])
    if (rolePermission.find(item => item.slug == 'internal_employee_view' && item.is_allowed == true)) {
      finalStepList.push(...[{
        element: '#InternalEmployees', popover: {
          title: 'Internal Employees', description: `Manage, view, and add internal users to run the show. You can add ${LocalStorage.getPlanCheckLocal()?.current_plan_slug === "trail" ? 5 : (["starter-monthly", "starter-yearly"].includes(LocalStorage.getPlanCheckLocal()?.current_plan_slug) ? 8 : (["advanced-monthly", "advanced-yearly"].includes(LocalStorage.getPlanCheckLocal()?.current_plan_slug) ? 12 : 0))} as per your ${LocalStorage.getUserData()?.plan_name} plan.`, side: "right",
          align: 'start'
        }
      },])
    }
    if (rolePermission.find(item => item.slug == 'employee_category_view' && item.is_allowed == true)) {
      finalStepList.push(...[{
        element: '#EmploymentCategory', popover: {
          title: 'Employment Category', description: 'Setup more employee categories for your employement types to add employees. You can add 5 more categories over the default ones.', side: "right",
          align: 'start'
        }
      },])
    }
    if (rolePermission.find(item => item.slug == 'skill_types_view' && item.is_allowed == true)) {
      finalStepList.push(...[{
        element: '#Skills', popover: {
          title: 'Skills', description: 'Add allowed skills for employees to include in their profiles and showcase their talent.', side: "right",
          align: 'start'
        }
      },])
    }
     if (rolePermission.find(item => item.slug == 'onboarding_document_types_view' && item.is_allowed == true)) {
      finalStepList.push(...[{
        element: '#OnboardingDocuments', popover: {
          title: 'Onboarding Documents', description: 'Manage document types required for self-onboarding. Make document types mandatory or non-mandatory for consultants while they do the self-onboarding process.', side: "right",
          align: 'start'
        }
      },])
    } 
    if (rolePermission.find(item => item.slug == 'organization_placement_view' && item.is_allowed == true)) {
      finalStepList.push(...[{
        element: '#JobTitle', popover: {
          title: 'Job Title', description: 'Create all job titles to select from dropdown while setting-up placements.', side: "right",
          align: 'start'
        }
      },])
    } 
    if (rolePermission.find(item => item.slug == 'email_templates_view' && item.is_allowed == true)) {
      finalStepList.push(...[{
        element: '#Templates', popover: {
          title: 'Email Templates', description: 'This is your default email template which will be used to send emails to your internal and external employees.', side: "left",
          align: 'start'
        }
      },])
    }
  }
  

  
  const driverObj = driver({
    popoverClass: 'driverjs-theme',
    allowClose: false,
    stagePadding: 4,
    stageRadius: 8,
    animate: false,
    allowKeyboardControl: false,
    disableActiveInteraction: true,
    popoverOffset:8,
    steps:finalStepList,
    onPopoverRender: (popover, opts) => {//creates the popover with required design 
        const currentStep = opts.state.activeIndex;
        const totalSteps = opts.config.steps.length; 
        const updateTourGuideStatus = async(tourModule)=>{
          await TourGuideConfigApi.updateApi({
            "request_id": LocalStorage.uid(),
              "module": tourModule,
              "status": true})
        }
        const title = document.createElement('div');
        title.style.display = 'flex'; 
        title.style.alignItems = 'center'; 
        title.style.marginBottom='8px';

        const icon = document.createElement('img');
        icon.src = LeftNavigateArrow; 
        icon.alt = 'Back Arrow';
        icon.style.width = '16px';
        icon.style.height = '16px';
        icon.style.marginRight = '2px'; 
        icon.style.cursor='pointer';
        if (currentStep !== 0) {
            icon.onclick = (event) => {
                event.stopPropagation();
                const category = lists.find(({ list }) => list.includes(opts.config.steps[currentStep-1].popover.title));
                setTab(category ? category.index : null);
                setCurrent(opts.config.steps[currentStep-1].popover.title);
                driverObj.movePrevious();
            };
            title.appendChild(icon);
        }
        const textContainer = document.createElement('div');
        textContainer.style.display = 'flex';
        textContainer.style.justifyContent = 'space-between';
        textContainer.style.width = '100%'; 
        const titleText = document.createElement('p');
        titleText.textContent = opts.config.steps[currentStep].popover.title;
        titleText.style.fontFamily='Quicksand';
        titleText.style.fontSize='15px';
        titleText.style.fontWeight='700';
        const stepSpan = document.createElement('span');
        stepSpan.textContent = `(${currentStep + 1}/${totalSteps})`;
        stepSpan.style.display = 'flex';
        stepSpan.style.alignItems = 'center';
        stepSpan.style.fontSize='12px';
        stepSpan.style.fontWeight='600';
        stepSpan.style.color = '#525252'; 
        stepSpan.style.fontFamily = 'Quicksand'; 
        textContainer.appendChild(titleText);
        textContainer.appendChild(stepSpan);
        title.appendChild(textContainer);

        const description = document.createElement('div');
        description.innerText = opts.config.steps[currentStep].popover.description; 
        description.style.marginBottom='24px';
        description.style.fontSize='14px';
        description.style.fontWeight='400';
        description.style.color = '#404040'; 
        description.style.fontFamily = 'Quicksand'; 

        const footer = document.createElement('div');
        footer.style.display = 'flex';
        footer.style.justifyContent = 'space-between'; 
        footer.style.width = '100%'; 
        footer.style.boxSizing = 'border-box'; 
        const applyInviteLinkBtnStyles = (element,text) => {
          element.style.cursor = 'pointer';
          element.style.textAlign = 'center';
          element.style.fontSize = '12px';
          element.style.fontFamily = 'Quicksand';
          element.style.fontWeight = '600';
          element.style.width = '130px';
          element.style.height = '32px';
          element.style.border = '1.5px solid rgba(12, 117, 235, 1)';
          element.style.borderRadius = '4px';
          element.style.color =(text=='skip' || text=="gotodashboard")?'#0C75EB':'#ffffff';
          element.style.transition = 'all .3s ease';
          element.style.outline='none';
          element.style.backgroundColor = (text=='skip' || text=="gotodashboard")?'#ffffff':'#0C75EB';
          
      };
        const skipButton = document.createElement('button');
        skipButton.innerText = 'Skip';
        applyInviteLinkBtnStyles(skipButton,'skip');
        
        skipButton.onclick = async() => {
          if(tourGuideResponse?.DONE_TOUR == false){
              const menuClick = document.getElementById("avatar-click");
              menuClick.click();
          }
          updateTourGuideStatus("ORG_CONFIG");
          LocalStorage.setStartTour({'orgconfig':false});
          setTab(0);
          setCurrent('Organization Details'); 
          driverObj.destroy();
        };
        const GoToDashboardButton = document.createElement('button');
        GoToDashboardButton.innerText = 'Go to Dashboard';
        applyInviteLinkBtnStyles(GoToDashboardButton,'gotodashboard');
        GoToDashboardButton.onclick = () => {
          updateTourGuideStatus("ORG_CONFIG");
          setTimeout(() => {
            updateTourGuideStatus("DONE_TOUR");
          }, 2000);
          LocalStorage.setStartTour({'orgconfig':false});

          if(LocalStorage.getUserData().org_config_set == true){
            driverObj.destroy();
            navigate('/dashboard');
          }else{
            setTab(0);
            setCurrent('Organization Details');
            addErrorMsg('Please fill out the organization details before going to the dashboard!');
            driverObj.destroy();
          }
        };
        const finishButton = document.createElement('button');
        finishButton.innerText = 'Finish';
        applyInviteLinkBtnStyles(finishButton,'finish');
        finishButton.onclick = () => {
          updateTourGuideStatus("ORG_CONFIG");
          setTimeout(() => {
            updateTourGuideStatus("DONE_TOUR");
          }, 2000);

          setTab(0);
          setCurrent('Organization Details');
          LocalStorage.setStartTour({'orgconfig':false});
          driverObj.destroy()
        };
        const nextButton = document.createElement('button');
        nextButton.innerText = 'Next';
        applyInviteLinkBtnStyles(nextButton,'next');
        nextButton.onclick = () => {
          const category = lists.find(({ list }) => list.includes(opts.config.steps[currentStep+1].popover.title));
          setTab(category ? category.index : null);
          setCurrent(opts.config.steps[currentStep + 1].popover.title);
          driverObj.moveNext();  
        };
        if (currentStep === totalSteps - 1) {
            footer.appendChild(GoToDashboardButton);
            footer.appendChild(finishButton);
        } else {
            footer.appendChild(skipButton);
            footer.appendChild(nextButton);
        }
        popover.wrapper.innerHTML = '';
        const arrow = popover.arrow; 
        if (arrow) {
            popover.wrapper.appendChild(arrow); 
        }
        popover.wrapper.appendChild(title);
        popover.wrapper.appendChild(description);
        popover.wrapper.appendChild(footer);

    }
  });
    
  let tourGuideResponse={} ;
  
  const getTourGuideDetails = async() => {
    let response = await TourGuideConfigApi.getApi();
      tourGuideResponse={...response.data.data};
      if(((LocalStorage.getStartTour() && LocalStorage.getStartTour().orgconfig == true)||(response.data.statusCode == 1003 && response.data.data?.ORG_CONFIG == false )) && LocalStorage.getUserData().admin_login == true){
        setTimeout(()=>{
          if(finalStepList.length>0){
            LocalStorage.setStartTour({'orgconfig':true})
            const category = lists.find(({ list }) => list.includes(finalStepList[0].popover.title));
            setTab(category ? category.index : 0);
            setCurrent(finalStepList.length > 0 ? finalStepList[0].popover.title :'Organization Details');
            driverObj.drive();//starts the driver
          }
          
        },500);
      
      }
    
   
  }

 
  
  getTourGuideDetails();
}


const getPlanCheck = () => {
    let data = {
        request_id: LocalStorage.uid(),
        subdomain_name: domain
    }
    DashboardAPI.planCheck(data).then((res) => {
        if (res.data.statusCode === 1003) {
            let data = LocalStorage.getUserData()
            data.plan_name = res.data.plan_name
            data.plan_not_set = res.data.plan_not_set
            LocalStorage.setDateFormat(res.data.date_format)
            LocalStorage.setUserData(data);
            LocalStorage.setPlanCheckLocal(res.data)
            if (res.data.plan_not_set) {
                navigate('/billing', { state: { renew: true } })
            }
            if (res.data.plan_expired) {
                if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                    addLoaderPlanExpired()
                }
                else {
                    removeExpiredLoader()
                }
            }
            if(!res.data.plan_not_set && !res.data.plan_expired){
              tourGuideStart();
            }
        }
    })
}

  return (
    <Box className={classes.mainBox}  >
      <Box display={"flex"} gap={1} >
        {
          LocalStorage.getUserData().org_config_set ?
            <img onClick={() => { if (LocalStorage.getUserData().org_config_set == false) { setOpen(true) } else { navigate('/dashboard') } }} style={{ cursor: "pointer" }} src={LeftNavigateArrow} alt="LeftNavigateArrow"></img>
            : ''
        }
        <Text sx={{
          fontSize: "22px",
          fontFamily: "Quicksand",
          fontWeight: "600", color: "#092333",
          "@media (min-width: 900px) and (max-width: 1400px)": {
            fontSize: "18px",
            fontWeight: "600"
          }
        }}>Organization Configuration</Text>
      </Box>
      <Box className={classes.mainPanel}>
        <Box sx={{ borderBottom: '1px', borderColor: 'divider', width: "100%" }}>
          {/* <TabsContainer> */}
          <CustomTabs
            value={tab}
            onChange={handleChangeTab}
            // variant="scrollable"\
            variant='fullWidth'
            // scrollButtons="auto"
            sx={{ borderBottom: '1px solid #C7CCD3' }}
          >
            {
              ConfigurationTab.map((item, key) => (
                <CustomTab key={key} label={item} {...a11yProps(0)} id={item=="Templates"?item:key}/>
              ))
            }
          </CustomTabs>
          {/* </TabsContainer> */}
        </Box>
        <Dialog
          // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
          sx={{
            zIndex: 1200, top: '-155px !important', left: '-980px !important'
          }}
          open={open}
          anchorEl={anchorEl}
          transition
          Backdrop
          PaperProps={{
            style: {
              backdropFilter: "blur(10px)",
            },
          }}
        >

          <Paper>
            <Grid container p={1}>
              <Text popupHead1>Org Config</Text>
            </Grid>
            <Grid container p={1}>
              <Typography sx={{ p: 2 }}>"Set the stage! Configure your <br /> organization's details to ensure <br /> everything runs smoothly."</Typography>
            </Grid>
            <Grid container justifyContent={'flex-end'} p={1}>
              <Button blueBtnSave onClick={() => { setOpen(false) }}>Next</Button>
            </Grid>
          </Paper>
        </Dialog>
        <CustomTabPanel value={tab} index={0} >
          <Organization setLogoUrl={props.setLogoUrl} setCurrent={setCurrent} current={current} setOrgConfigEdit={setOrgConfigEdit} orgConfigEdit={orgConfigEdit}/>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1} >
          <PayrollConfig/>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={2}>
          <GroupsConfig setTab={setTab} setInternal={setInternal} />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={3} >
          <Employee internal={internal} setInternal={setInternal} setCurrent={setCurrent} current={current}/>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={4}>
          <Placement />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={5}>
          <Templates />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={6}>
          <Notifications />
        </CustomTabPanel>

        <CustomTabPanel value={tab} index={7}>
          <TimeSheet />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={8}>
          <Invoice />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={9}>
          <EmployeeSelfService />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={10}>
          <Clients />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={11}>
          <ExpenseManagement />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={12}>
          <Group />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={13}>
          <Remainders />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={14}>
          <Notifications />
        </CustomTabPanel>
      </Box>
    </Box>
  )
}

export default ConfigurationPanel