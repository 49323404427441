import { makeStyles } from "@mui/styles";

const CustomRoleTableStyles = makeStyles(() => ({
  tableContainer: {
    height: "371px !important",
    borderRadius: "12px !important", 
    '& thead': {
      '& .MuiTableCell-root': {
        background: '#F6F6F6'
      }
    }
  }
}))

export default CustomRoleTableStyles;