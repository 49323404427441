import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const LoginStyles = makeStyles(() => ({
    box: {
        [useTheme().breakpoints.down('md')]: {
            background: "",
        }
    },
    boxOne: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: "-50px ! important",
        [useTheme().breakpoints.down('md')]: {
            marginTop: "50px ! important",
            justifyContent: 'center',
        }
    },
    boxTwo: {
        marginLeft: "-90px !important",
        width: '60vh',
        [useTheme().breakpoints.down('md')]: {
            width: '350px',
            marginLeft: "0px !important",
        },
        [useTheme().breakpoints.down('sm')]: {
            width: '300px',
            marginLeft: "0px !important",
        }
    },
    rightBox: {
        background: '#243042 ! important',
        height: '100vh'
    },
    image: {
        marginTop: "200px",
        marginLeft: "-200px",
        width: "100%",
    },
    header: {
        color: '#2D2D2D !important',
        textAlign: 'center',
        margin:'10px 0px !important',
    },
    buttonStyles: {
        textAlign: 'center',
        padding: '10px',
        width: '100% !important'
    },
    forgotPassword: {
        color: "#4C9FEB !important",
        fontSize: "11px !important",
        cursor: "pointer"
    },
    loginButton: {
        width: "100% !important",
        height: "43px !important",
        background: `#0C75EB !important`,
        borderRadius: "8px !important",
        textTransform: "none !important",
        font: "15px Quicksand !important",
        fontWeight: '600',
        
        '&:disabled': {
            opacity: '0.8'
        },
        [useTheme().breakpoints.down("lg")]: {
            font: "12px Quicksand !important",
            width: "100% !important",
        },
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
        }
    },
    Paper: {
        margin: '6px 0px',
        display: "flex",
        alignItems: "center",    
        border: '1px solid #1A181E !important',
        boxShadow: "none !important",
        borderRadius: '5px !important',
    },
    InputBase: {
        marginLeft: 7,
        flex: 1,
        font: '14px Quicksand !important',
        color: '#1A181E !important',
        background: '#FFFFFF 0% 0% no-repeat padding-box !important',
        opacity: 1,
        borderRadius: '5px',
        width: '100%',
        height: '60px ! important',
        '& input': {
            textAlign: 'left !important',
            paddingLeft: '20px !important'
        },
    }, 
    span: {
        marginRight: "10px",
        "@media (max-width:320px) ": {
            marginRight: "5px",
        },
    },
}))

