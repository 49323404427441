import { Dialog, DialogContent, DialogTitle, Grid, Slide } from '@mui/material';
import React from 'react';
import deleteAlert from "../../assets/svg/placementPop.svg";
import closeImg from '../../assets/svg/crossIcon.svg';
import Button from '../../components/customButton/Button';
import { makeStyles } from '@mui/styles';
import Text from '../customText/Text';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction={"up"} ref={ref} {...props} />;
});

const deleteStyles = makeStyles(() => ({
    paper: {
        position: 'absolute',
        borderRadius: '8px 8px 8px 8px !important',
        background: '#FFFFFF !important',
        width: "400px !important"
    },
}));

function ReusableAlert(props) {
    const { open, handleClose, message,title } = props;

    const classes = deleteStyles();
    return (
        <Dialog classes={{ paper: classes.paper }} onClose={handleClose} open={open} TransitionComponent={Transition}>
            <DialogTitle >
                <div style={{ display: 'flex', justifyContent: "end" }}>
                    {props.icon && <img src={closeImg} alt='close' onClick={handleClose} style={{ cursor: 'pointer' }} />}
                </div>
            </DialogTitle>
            <DialogContent sx={{ textAlign: "center", padding: "20px" }}>
                <Grid container>
                    <Grid item md={12} sm={12}>
                        <img src={deleteAlert} alt="deleteAlert" />
                    </Grid>
                    <Grid item md={12} sm={12} pt={1}>
                        <Text popupHead1>Are You Sure?</Text>
                    </Grid>
                    <Grid item md={12} sm={12} pt={1}>
                        <Text offBoardBodyFont>{message ? `After deactivation, this ${title ? title : 'job title'} won't reflect in your organization. You can reactivate again to use it. ` : "Do you really wish to In Active."}</Text>
                    </Grid>
                    <Grid container columnGap={3} display={"flex"} justifyContent={"center"} pt={3}>
                        <Button onClick={handleClose} outlineBlueMedium1 sx={{ height: "inherit !important", width: "80px !important", borderRadius: "8px !important", font: "14px Quicksand !important", fontWeight: `${700} !important` }}>No</Button>
                        <Button onClick={props.onClick} deleteButton sx={{ background: "#0C75EB !important", font: "14px Quicksand !important", fontWeight: `${700} !important`, minWidth: "88px !important", }}>Yes</Button>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>
    )
}

export default ReusableAlert