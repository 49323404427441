import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import PropTypes from "prop-types";
import { Box } from '@mui/material';
import moment from 'moment';
import { dateFormat } from '../../utils/utils';
import { ReactComponent as CalendarIcon } from '../../assets/svg/CalenderIcon.svg';
import { inputLabelClasses } from "@mui/material/InputLabel";

export default function Date(props) {
    const {
        onChange, height, svgHeight, disabled, labelText, error, defaultOpen
    } = props;
    let dateValue = moment(props.value, dateFormat()).format('YYYY-MM-DD');
    let mindate = moment(props.minDate, dateFormat()).format('YYYY-MM-DD');
    let maxdate = moment(props.maxDate, dateFormat()).format('YYYY-MM-DD');
    const [open, setOpen] = useState(defaultOpen)
    useEffect(() => {
        setOpen(defaultOpen);
        // eslint-disable-next-line
    }, [props])
    const onCloseDate = () => {
        setOpen(false);
    }

    // Function to disable Saturdays and Sundays
    const disableWeekends = (date) => {
        const day = date.day(); // dayjs method to get the day of the week
        return day === 0 || day === 6; // Disable Sunday (0) and Saturday (6)
    };


    return (
        <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    open={open}
                    onClose={onCloseDate}
                    label={labelText}
                    disabled={disabled}
                    error={error ? 'red !important' : '#737373'}
                    slotProps={
                        {
                            // field: { clearable: dateValue ? true : false },
                            textField: {
                                InputLabelProps: {
                                    sx: {
                                        color: error ? 'red' : "#737373",
                                        font: '15px Quicksand !important',
                                        [`&.${inputLabelClasses.shrink}`]: {
                                            color: error ? 'red !important' : (props.value == '' || disabled) ? "#737373" : "#0C75EB",
                                            font: '15px Quicksand !important',
                                        },
                                    },
                                },
                                InputProps: {
                                    disableUnderline: true
                                },
                                variant: 'filled',
                                fullWidth: true,
                                error: error, // Bolean
                                sx: {
                                    "&:focus": {
                                        border: '1px solid red !important',
                                    },
                                    "& .MuiOutlinedInput-input": {
                                        font: '14px Quicksand !important',
                                        fontWeight: `${400} !important`,
                                    },
                                    ".MuiFormLabel-root.MuiInputLabel-root": {
                                        font: '14px Quicksand !important',
                                        fontWeight: `${400} !important`,
                                        // color: '#737373 !important'
                                    },
                                    '& .MuiInputBase-root': {
                                        height: height ? height : '54px !important',
                                        border: `${disabled ? '1px solid #FFFF !important' : error ? '1px solid red !important' : '1px solid #C7CCD3'}`,
                                        borderRadius: '8px !important',
                                        background: `${disabled ? '#FAFAFA !important' : '#FFFF !important'}`,
                                        font: '14px Quicksand !important',
                                        fontWeight: `${400} !important`,
                                    },
                                    "& .MuiInputBase-input.MuiFilledInput-input": {
                                        font: '14px Quicksand !important',
                                        fontWeight: `${600} !important`,
                                        // color: '#262626 !important',
                                    },
                                    '& .PrivatePickersYear-yearButton': {
                                        font: '14px Quicksand !important',
                                        fontWeight: `${400} !important`,
                                        color: '#262626 !important'
                                    },
                                    "& .MuiYearPicker-root": {
                                        font: '14px Quicksand !important',
                                        fontWeight: `${400} !important`,
                                        color: '#262626 !important'
                                    },
                                    "& .MuiInputBase-input.MuiFilledInput-input.Mui-disabled": {
                                        '-webkit-text-fill-color': '#525252 ',
                                        // font: '14px Quicksand !important',
                                        fontWeight: `${600} `,
                                    },
                                    "& .MuiPickersYear-yearButton": {
                                        font: '14px Quicksand !important',
                                        fontWeight: `${400} !important`,
                                        // color: '#262626 !important'
                                    },
                                    background: "#FFFF !important",
                                    svg: { color: '#725AC1', height: svgHeight ? svgHeight : "24px" },

                                }
                            },
                            desktopPaper: {
                                sx: {
                                    '.MuiPickersYear-yearButton.Mui-selected': {
                                        font: '14px Quicksand !important',
                                        fontWeight: `${400} !important`,
                                        // color: '#262626 !important'
                                    },
                                    '.MuiPickersYear-yearButton': {
                                        font: '14px Quicksand !important',
                                        fontWeight: `${400} !important`,
                                        // color: '#262626 !important'
                                    }
                                }
                            },
                        }}
                    value={dayjs(dateValue)} onChange={onChange} format={dateFormat().toUpperCase()}
                    minDate={props.minDate !== undefined ? dayjs(mindate) : ''}
                    maxDate={props.maxDate !== undefined ? dayjs(maxdate) : ''}
                    shouldDisableDate={props.weekDisabled ? disableWeekends : ""} // Disable weekends

                    components={{
                        OpenPickerIcon: () => (
                            <CalendarIcon onClick={() => { setOpen(!open) }} stroke={disabled ? "#737373" : props.value === "" ? "#737373" : "#5DA5F5"} />
                        ),
                    }}
                />
            </LocalizationProvider>
        </Box>

    );
}
Date.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.any,
    error: PropTypes.any
};