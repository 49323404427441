import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Avatar, Divider, Stack, DialogContent } from "@mui/material";
import Text from '../../../../components/customText/Text';
import Date from '../../../../components/datePicker/Date';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from "../../../../assets/svg/accordionOff.svg"
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import CustomButton from '../../../../components/customButton/Button';
import LoaderButton from '../../../../components/customButton/LoadingButton';
import CustomSelect from '../../../../components/customSelect/CustomSelect';
import FileInput from '../../../../components/muiFileInput/FileInput';
import { BlackToolTip, addLoader, addSuccessMsg, capitalizeAndAddSpace, getCurrencySymbol, removeLoader } from "../../../../utils/utils";
import LocalStorage from '../../../../utils/LocalStorage';
import moment from 'moment';
import { dateFormat } from '../../../../utils/utils';
import offBoardImag1 from "../../../../assets/svg/disabled 1.svg"
// eslint-disable-next-line
import offBoardImag2 from "../../../../assets/svg/offBoard2.svg"
// eslint-disable-next-line
import offBoardImag3 from "../../../../assets/svg/offBoard3.svg"
import offBoardImag4 from "../../../../assets/svg/give-money 2.svg"
import checked from "../../../../assets/svg/Checked.svg"
import { Link, useNavigate } from "react-router-dom";
import { addErrorMsg } from '../../../../utils/utils';
import completed from "../../../../assets/svg/CompletedImg.svg"
import placement from "../../../../assets/svg/placementOff.svg"
import recovationOff from "../../../../assets/svg/recovationOff.svg"
import deleteOff from "../../../../assets/svg/deleteOff.svg"
import expenseOff from "../../../../assets/svg/expenseOff.svg"
import noActiveOff from "../../../../assets/svg/noActiveOff.svg"
import expenseSettleOff from "../../../../assets/svg/expenseSettleOff.svg"
import offBoardSure from "../../../../assets/svg/offBoardSure.svg"
import offBoardSave from "../../../../assets/svg/offBoardSave.svg"
// import billsOff from "../../../../assets/svg/billsOff.svg"
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { useLocation } from 'react-router-dom';
import CommonApi from '../../../../apis/CommonApi';
import { makeStyles } from "@mui/styles";
import OffboardingChecklistStyles from './OffboardingChecklistStyles';
import OffboardApi from '../../../../apis/admin/offboard/OffboardApi';
import Slide from "@mui/material/Slide";
import SettingsApi from '../../../../apis/settingsApi/SettingsApi';
import { validate_emptyField } from '../../../../components/Validation';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import expenseOffPopup from "../../../../assets/svg/OffBoardPop.svg"
import Initiated from "../../../../assets/svg/InitiatedImg.svg"
import { Buffer } from 'buffer';


const useStyles = makeStyles({
  accordionSummary: {
    backgroundColor: "#ffffff",
    cursor: 'pointer !important'
  },
});

function OffboardingChecklist() {
  const classes = OffboardingChecklistStyles();
  const navigate = useNavigate()
  const classes1 = useStyles();
  // eslint-disable-next-line
  const payViaList = require('../../../../utils/jsons/PayVia.json');
  const EmailSentTo = require('../../../../utils/jsons/EmailSentTo.json');
  const location = useLocation();
  const [expanded, setExpanded] = React.useState(location.state.expand);
  const messagesEndRef = useRef(location.state.expand ? location.state.expand : '');
  const [cancelPopup, setCancelPopup] = React.useState(false);
  const [backPopup, setBackPopup] = React.useState(false);
  const [finishPopup, setFinishPopup] = React.useState(false);
  const [expensePopup, setExpensePopup] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const [state, setState] = useState({
    application_name: '',
    last_working_day: {
      date: location.state.last_date,
      reason_for_offboarding: Buffer.from(location.state.offBoarding).toString('base64'),
      status: true
    },
    placement: {
      status: false,
      data: []
    },
    disable_user_access: {
      status: false
    },
    send_revocation: {
      email_sent_to: "",
      disabled: false,
      date: "",
      revocation_sent_document: {
        id: "",
        document_name: "",
        document_url: "",
        document_path: "",
        deleted_url: ""
      },
      revocation_raised_document: {
        id: "",
        document_name: "",
        document_url: "",
        document_path: "",
        deleted_url: ""
      },
      status: false
    },
    delete_mail: {
      email_deleted_on: '',
      proof_of_document: {
        id: '',
        document_name: '',
        document_url: '',
        deleted_url: ''
      },
      status: false
    },
    expense_settlement: {
      reimbursement_amount: '',
      reimbursement_payment: '',
      deduction_amount: 0,
      deduction_payment: 0,
      status: false
    },
    settle_balance: {
      total_balance: 0,
      default_total_balance: 0,
      pay_via: '',
      status: false
    },
    finish_once: false,
    type: 1
  })

  // type 1 internal
  // type 2 consultant
  // type 3 contractor

  const typeSno = {
    1: {
      disable: 1,
      delete: 2,
      expense: 3,
      settle: 4,
      placement: '',
      send: '',
      // bills: ''
    },
    2: {
      placement: 1,
      disable: 2,
      send: 3,
      delete: 4,
      expense: 5,
      settle: 6,
      // bills: ''
    },
    3: {
      placement: 1,
      disable: 2,
      delete: 3,
      expense: 4,
      // bills: 4,
      settle: 5,
      send: ''
    }
  }

  const [error, setError] = useState({
    last_working_day: {
      date: ''
    },
    placement: {
      status: false,
      data: []
    },
    disable_user_access: {
      status: false
    },
    send_revocation: {
      email_sent_to: "",
      disabled: false,
      date: "",
      revocation_sent_document: {
        id: "",
        document_name: "",
        document_url: "",
        document_path: "",
        deleted_url: ""
      },
      revocation_raised_document: {
        id: "",
        document_name: "",
        document_url: "",
        document_path: "",
        deleted_url: ""
      },
      status: false
    },
    delete_mail: {
      email_deleted_on: '',
      proof_of_document: {
        document_name: '',
        document_url: ''
      },
      status: false
    },
    expense_settlement: {
      reimbursement_amount: '',
      reimbursement_payment: '',
      deduction_amount: '',
      deduction_payment: '',
      status: false
    },
    settle_balance: {
      total_balance: '',
      pay_via: '',
      status: false
    },
    finish_once: false
  })
  // eslint-disable-next-line
  const reimbursementOptions = [
    {
      id: 1,
      name: 'Add to Balance Sheet'
    },
    {
      id: 2,
      name: 'Write Off'
    }
  ]
  // eslint-disable-next-line
  const deductionOptions = [
    {
      id: 1,
      name: 'Deduct from Balance Sheet'
    },
    {
      id: 2,
      name: 'Write Off'
    }
  ]
  // eslint-disable-next-line
  const [get1, setGet1] = useState({});// eslint-disable-next-line
  const [popUpOpen, setPopUpOpen] = useState(false);// eslint-disable-next-line
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
      borderRadius: "16px",
      width: "500px"
    }
  }));// eslint-disable-next-line
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });// eslint-disable-next-line
  const { acc } = location.state// eslint-disable-next-line
  const { full_name, reference_id, avatar_url, id, args, visa } = location.state
  useEffect(() => {
    getOffBoardCheckList(id, location.state.last_date)
    OrganizationIndex();
    // eslint-disable-next-line
  }, [])

  const getOffBoardCheckList = (args, date) => {
    // return true // eslint-disable-next-line
    OffboardApi.getOffBoardCheckList(args, date).then((response) => {
      if (response.data.statusCode == 1003) {
        setTimeout(() => {
          setGet1({ ...response.data.data[0] })
          let data = response.data.data[0]
          if (data.last_working_day.date === "") {
            data.last_working_day.date = location.state.last_date
          }
          setState({ ...response.data.data[0] })
        }, 400)
        if (response.data.data[0].finish_once && expanded !== 'placements' && expanded !== 'disable') {
          messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
        }
      } else {
        addErrorMsg(response.data.message);
      }
    });
  }

  const OrganizationIndex = () => {
    SettingsApi.configIndexApi().then((res) => {
      if (res.data.statusCode == 1003) {
        // setLevel(res.data.data[0])
      }
    })
  }



  const uploadDocsRevocationRaisedDocs = (value) => {
    addLoader('ss')
    let name = value.target.files[0].name
    var lastFour = name.substr(name.length - 4);
    if (value.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx', 'document'].includes(r)) || lastFour === 'docx' || lastFour === '.doc') {
      if (Number(((value.target.files[0].size / (1024 * 1024)).toFixed(2))) < 25) {
        const formData = new FormData();
        formData.append("files", value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        CommonApi
          .documentUpload("offboarding-document", formData, LocalStorage.getAccessToken())
          .then((response) => {
            if (response.data.statusCode == 1003) {
              removeLoader()
              state.send_revocation.revocation_raised_document.aws_s3_status = 0
              state.send_revocation.revocation_raised_document.document_url = response.data.data.document_url
              state.send_revocation.revocation_raised_document.id = response.data.data.id
              state.send_revocation.revocation_raised_document.document_name = response.data.data.document_name
              state.send_revocation.status = false
              setState({ ...state })
            } else {
              removeLoader()
              addErrorMsg(response.data.message);
            }
          });
      } else {
        removeLoader()
        addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
      }
    }
    else {
      removeLoader()
      addErrorMsg("Please upload files in pdf or png or jpg or jpeg or doc format only.");
    }

  };
  const uploadDocsDeleteMail = (value) => {
    addLoader('ss')
    let name = value.target.files[0].name
    var lastFour = name.substr(name.length - 4);
    if (value.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx', 'document'].includes(r)) || lastFour === 'docx' || lastFour === '.doc') {
      if (Number(((value.target.files[0].size / (1024 * 1024)).toFixed(2))) < 25) {
        const formData = new FormData();
        formData.append("files", value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);

        CommonApi.documentUpload("offboarding-document", formData, LocalStorage.getAccessToken())
          .then((response) => {
            if (response.data.statusCode === 1003) {
              removeLoader()
              state.delete_mail.proof_of_document.aws_s3_status = 0
              state.delete_mail.proof_of_document.document_url = response.data.data.document_url
              state.delete_mail.proof_of_document.id = response.data.data.id
              state.delete_mail.proof_of_document.document_name = response.data.data.document_name
              state.delete_mail.status = false
              setState({ ...state })
            } else {
              removeLoader()
              addErrorMsg(response.data.message);
            }
          });
      } else {
        removeLoader()
        addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
      }
    } else {
      removeLoader()
      addErrorMsg("Please upload files in pdf or png or jpg or jpeg or doc format only.");
    }

  };


  const uploadDocsRevocationSentDocs = (value) => {
    addLoader('ss')
    let name = value.target.files[0].name
    var lastFour = name.substr(name.length - 4);
    if (value.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx', 'document'].includes(r)) || lastFour === 'docx' || lastFour === '.doc') {
      if (Number(((value.target.files[0].size / (1024 * 1024)).toFixed(2))) < 25) {
        const formData = new FormData();
        formData.append("files", value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        CommonApi
          .documentUpload("offboarding-document", formData, LocalStorage.getAccessToken())
          .then((response) => {
            if (response.data.statusCode == 1003) {
              removeLoader()
              state.send_revocation.revocation_sent_document.aws_s3_status = 0
              state.send_revocation.revocation_sent_document.document_url = response.data.data.document_url
              state.send_revocation.revocation_sent_document.id = response.data.data.id
              state.send_revocation.revocation_sent_document.document_name = response.data.data.document_name
              state.send_revocation.status = false
              setState({ ...state })
            } else {
              removeLoader()
              addErrorMsg(response.data.message);
            }
          });
      } else {
        removeLoader()
        addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
      }
    }
    else {
      removeLoader()
      addErrorMsg("Please upload files in pdf or png or jpg or jpeg or doc format only.");
    }

  };


  const dateChange = (e, name, args) => {
    let date = e == null ? '' : e.$d
    state[args][name] = date == '' ? '' : moment(date).format(dateFormat())
    state[args].status = false
    error[args][name] = ''
    setError({ ...error })
    setState({ ...state })
  }

  const dateChangePlacement = (e, name, args, index) => {
    let date = e == null ? '' : e.$d
    state[args]['data'][index][name] = date == '' ? '' : moment(date).format(dateFormat())
    state[args].status = false
    setState({ ...state })
  }

  const dateChangeLastworking = (e, name, args) => {
    let date = e == null ? '' : e.$d
    state[args][name] = date == '' ? '' : moment(date).format(dateFormat())
    setState({ ...state })
    getOffBoardCheckList(id, moment(date).format(dateFormat()))
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const confirmStatus = (args) => {
    state[args].status = true
    setState({ ...state })
    setExpanded('')
  }

  const placement_submit = () => {
    if (state.placement.data.length > 0) { // eslint-disable-next-line
      state.placement.data.find((i, index) => {
        if (moment(state.last_working_day.date).isBefore(i.placement_end_date) || moment(i.placement_end_date).isBefore(i.placement_start_date)) {
          if (moment(state.last_working_day.date).isSame(i.placement_end_date)) { }
          else {
            addErrorMsg('Please ensure that the placement end date cannot be before the placement start date and after last working date')
            return true
          }
        }
        if (index == state.placement.data.length - 1) {
          if (moment(state.last_working_day.date).isBefore(i.placement_end_date) || moment(i.placement_end_date).isBefore(i.placement_start_date)) {
            if (moment(state.last_working_day.date).isSame(i.placement_end_date)) { }
            else {
              addErrorMsg('Please ensure that the placement end date cannot be before the placement start date and after last working date')
              return true
            }
          }
          else {
            state.placement.status = true
            setExpanded('')
          }
        }
      })
      // state.placement.status = true
      // setExpanded('')
    }
  }

  const submit_send_revocation = () => {
    if (state.send_revocation.date !== '' && state.send_revocation.email_sent_to) {
      state['send_revocation'].status = true;
      setState({ ...state })
      error.send_revocation.date = validate_emptyField(state.send_revocation.date)
      error.send_revocation.email_sent_to = validate_emptyField(state.send_revocation.email_sent_to)
      setError({ ...error })
      setExpanded('')
    }
    else {
      error.send_revocation.date = validate_emptyField(state.send_revocation.date)
      error.send_revocation.email_sent_to = validate_emptyField(state.send_revocation.email_sent_to)
      setError({ ...error })
    }
  }

  const submit_delete = () => {

    if (moment(state.last_working_day.date).isBefore(state.delete_mail.email_deleted_on)) {
      error.delete_mail.email_deleted_on = 'Please ensure that the delete date is before last working day'
      setError({ ...error })
    }
    else if (state.delete_mail.email_deleted_on !== '') {
      state['delete_mail'].status = true;
      setState({ ...state })
      error.delete_mail.email_deleted_on = validate_emptyField(state.delete_mail.email_deleted_on)
      setError({ ...error })
      setExpanded('')
    }
    else {
      error.delete_mail.email_deleted_on = validate_emptyField(state.delete_mail.email_deleted_on)
      setError({ ...error })
    }
  }



  const changeHandler = (e, args) => {
    state[args][e.target.name] = e.target.value
    error[args][e.target.name] = ''
    state[args].status = false
    setError({ ...error })
    setState({ ...state })
  }




  const deleteDoc = (args, documentName) => {
    state[args][documentName].deleted_url = state[args][documentName].document_url
    state[args][documentName].document_name = ''
    state[args][documentName].document_url = ''
    state[args][documentName].id = ''
    state[args][documentName].aws_s3_status = 0
    state[args].status = false
    setState({ ...state })
  }
// eslint-disable-next-line
  const submit_expense = () => {
    if (state.expense_settlement.reimbursement_payment !== '' && state.expense_settlement.deduction_payment) {
      setExpensePopup(true)
      error.expense_settlement.reimbursement_payment = validate_emptyField(state.expense_settlement.reimbursement_payment)
      error.expense_settlement.deduction_payment = validate_emptyField(state.expense_settlement.deduction_payment)
      setError({ ...error })
    }
    else {
      error.expense_settlement.reimbursement_payment = validate_emptyField(state.expense_settlement.reimbursement_payment)
      error.expense_settlement.deduction_payment = validate_emptyField(state.expense_settlement.deduction_payment)
      setError({ ...error })
    }
  }

  const continue_expense = () => {
    if (state.expense_settlement.reimbursement_payment == 1) {
      state.settle_balance.total_balance = state.settle_balance.default_total_balance + state.expense_settlement.reimbursement_amount
    }
    else {
      state.settle_balance.total_balance = state.settle_balance.default_total_balance
    }
    if (state.expense_settlement.deduction_payment == 1) {
      state.settle_balance.total_balance = state.settle_balance.default_total_balance - state.expense_settlement.deduction_amount
    }
    else {
      state.settle_balance.total_balance = state.settle_balance.default_total_balance
    }
    if (state.expense_settlement.reimbursement_payment == 1 && state.expense_settlement.deduction_payment == 1) {
      state.settle_balance.total_balance = state.settle_balance.default_total_balance + state.expense_settlement.reimbursement_amount - state.expense_settlement.deduction_amount
    }
    state['expense_settlement'].status = true;
    setState({ ...state })
    setExpensePopup(false)
    setExpanded('')
  }

// eslint-disable-next-line
  const submit_settle = () => {
    if (state.settle_balance.pay_via !== '') {
      state['settle_balance'].status = true;
      setState({ ...state })
      error.settle_balance.pay_via = validate_emptyField(state.settle_balance.pay_via)
      setError({ ...error })
      setExpanded('')
    }
    else {
      error.settle_balance.pay_via = validate_emptyField(state.settle_balance.pay_via)
      setError({ ...error })
    }
  }

  const openCancelPopup = () => {
    setCancelPopup(true)
  }
  
  const finishOffboard = () => {
    setLoader(true)
    let data = state
    data.reference_id = LocalStorage.uid()
    data.last_working_day.status = true
    data.last_working_day.date= location.state.last_date
    data.last_working_day.reason_for_offboarding = Buffer.from(location.state.offBoarding).toString('base64');
    OffboardApi.newstoreOffBoard(data).then((response) => {
      if (response.data.statusCode === 1003) {
        setLoader(false)
        setFinishPopup(true)
      }
      else {
        setLoader(false)
        addErrorMsg(response.data.message)
       
      }
    })
  }

  const saveOffboard = () => {
    setLoader(true)
    let data = state
    data.reference_id = LocalStorage.uid()
    data.last_working_day.status = true
    data.last_working_day.date= location.state.last_date
    data.last_working_day.reason_for_offboarding = Buffer.from(location.state.offBoarding).toString('base64');
    OffboardApi.newstoreOffBoard(data).then((response) => {
      if (response.data.statusCode === 1003) {
        setLoader(false)
        addSuccessMsg('Changes saved');
        LocalStorage.removeRedirectedModule();
        LocalStorage.removeSubModule();
        LocalStorage.removeNotificationEmpId();
        navigate(`/employees/user-profile/${full_name ? full_name.trim().split(/ +/).join('-') : ""}`, { state: { id: id, full_name: full_name, reference_id: reference_id, offBoardButton: false, avatar_url: avatar_url, active: 3, grButn: 3, from: 'employmentDashboard' } })
      }
      else {
        setLoader(false)
        addErrorMsg(response.data.message)
      }
    })
  }

  const checkPriortoWeek =()=>{
    const last_working_day =moment(moment(state.last_working_day.date,dateFormat()).format());
    return last_working_day.diff(moment().startOf('day'),'days')<=0;     
  }

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center", height: "20vh" }}  >
        <Grid container sx={{ width: "56vw" }} pt={2}>
          <Grid item>
            <Text boldBlackfont16>
              Offboarding Checklist
            </Text>
          </Grid>
          <Grid item container lg={12} md={12} mt={3} sx={{ backgroundColor: "#fff !important", boxShadow: "0px 2px 24px 0px #919EAB1F" }} p={2}>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <Box display="flex" alignItems="center">
                <Avatar alt="rychbcb" src={avatar_url} sx={{ width: '70px', height: "70px" }} />
                <Box ml={2}>
                  <Text boldBlackfont16 sx={{ color: '#262626 !important', fontFamily: "Quicksand !important", fontWeight: `${700} !important`, fontSize: '15px !important' }}>{full_name}</Text>
                  <Text smallBlack sx={{ color: '#737373 !important', paddingTop: '5px !important', fontFamily: "Quicksand !important" }} nowrap>{reference_id} {visa ? `- ${visa}` : ''}</Text>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <Box pt={'8px'}>
                <Date
                  labelText={'Last Working Day'}
                  name='date'
                  value={location.state.last_date}
                  disabled
                  minDate={location.state.min_date}
                  onChange={(value => dateChangeLastworking(value, 'date', 'last_working_day'))}
                  height='56px' />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box pt={2} sx={{ width: "100%", display: "flex", justifyContent: "center", height: "60.8vh" }}>
        <Grid container sx={{ width: "56vw", marginLeft: '0px', overflowY: "scroll" }} >
          <Grid item lg={12} mt={1}>
            {
              (state.type === 2 || state.type == 3) &&
              <Accordion
                expanded={expanded === `placements`}
                onChange={handleChange(`placements`)}
                className={classes.customAccordion}
                ref={messagesEndRef}
              >
                <AccordionSummary
                  expandIcon={<img src={ExpandMoreIcon} alt='expand' />}
                  aria-controls="panel1d-content" id="panel1d-header"
                  className={classes1.accordionSummary}
                >
                  <Grid item container sx={{ cursor: 'pointer !important' }}>
                    <Grid item lg={1} md={2} sm={2} xs={12}>
                      {
                        state.placement.status ?
                          <img alt='checked' src={checked} /> :
                          <img alt='off1' src={placement} />
                      }
                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={9} p={1} ml={3} display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                      <Text offBoardHeadFont >&nbsp;{typeSno[state.type].placement}. Please Select End Dates For Active Placements</Text>
                      <Text offBoardBodyFont >Note : Last working date will be selected as placement end date for active <br /> placements. You can change the placement end date below.</Text>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item lg={12}><Divider sx={{ border: "1px solid #EAECF0" }} /></Grid>
                  {
                    state.placement.data.length > 0 ?
                      <>
                        {state.placement.data.map((item, index) => (
                          <Grid item container>
                            <Grid item container lg={12} display={"flex"} mt={2} sx={{ backgroundColor: '#FCFCFC', padding: '10px' }}>
                              <Grid item xs={6}>
                                <Stack direction={'row'}>
                                  <Box>
                                    {
                                      item.logo_document_url === '' ?
                                        <Avatar
                                          sx={{ width: 50, height: 50, }}
                                        >
                                          {item.company_name[0]}
                                        </Avatar>
                                        :
                                        <Avatar
                                          alt={item.company_name[0]}
                                          src={item.logo_document_url}
                                          sx={{ width: 50, height: 50, }}
                                        />
                                    }

                                  </Box>
                                  <Box pl={2}>
                                    <Text offBoardHeadFont>{item.company_name}</Text>
                                    <Text offBoardBodyGreen>{item.placement_reference_id}</Text>
                                  </Box>
                                </Stack>

                              </Grid>
                              <Grid item xs={6} container justifyContent={'flex-end'}>
                                <Date
                                  labelText={'Placement End Date'}
                                  name='placement_end_date'
                                  value={item.placement_end_date}
                                  minDate={item.placement_start_date}
                                  maxDate={location.state.last_date}
                                  onChange={(value => dateChangePlacement(value, 'placement_end_date', 'placement', index))}
                                  height='56px' />
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                        {
                          state.placement.status ? '' :
                            <Grid item xs={12} container justifyContent={'flex-end'}>
                              <Stack direction={'row'} pt={3}>
                                <Box p={1}>
                                  <CustomButton finishLater onClick={() => { setExpanded('') }}>
                                    Finish Later
                                  </CustomButton>
                                </Box>
                                <Box p={1} pr={0}>
                                  <CustomButton finishFilledQuicksand onClick={() => { placement_submit() }}>
                                    Save
                                  </CustomButton>
                                </Box>
                              </Stack>
                            </Grid>
                        }

                      </>
                      :
                      <Grid container>
                        <Grid container justifyContent={'center'} alignContent={'center'}>
                          <img src={noActiveOff} alt='noactive' />
                        </Grid>
                        {
                          state.placement.status ? '' :
                            <Grid item xs={12} container justifyContent={'flex-end'}>
                              <Stack direction={'row'} pt={3}>
                                <Box p={1} pr={0}>
                                  <CustomButton finishFilledQuicksand onClick={() => { confirmStatus('placement') }}>
                                    Confirm
                                  </CustomButton>
                                </Box>
                              </Stack>
                            </Grid>
                        }
                      </Grid>
                  }
                </AccordionDetails>
              </Accordion>
            }
            <Accordion
              expanded={expanded === `disable`}
              onChange={handleChange(`disable`)}
              className={classes.customAccordion}
              ref={messagesEndRef}
            >
              <AccordionSummary
                expandIcon={<img src={ExpandMoreIcon} alt='expand' />}
                aria-controls="panel1d-content" id="panel1d-header"
                className={classes1.accordionSummary}
              >
                <Grid item container sx={{ cursor: 'pointer !important' }}>
                  <Grid item container sx={{ cursor: 'pointer !important' }}>
                    <Grid item lg={1} md={2} sm={2} xs={2}>
                      {
                        state.disable_user_access.status ?
                          <img alt='checked' src={checked} /> :
                          <img alt='off1' src={offBoardImag1} />
                      }
                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={9} p={1} ml={3} display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                      <Text offBoardHeadFont >&nbsp;{typeSno[state.type].disable}. Disable User Access Across Apps</Text>
                      <Text offBoardBodyFont >Revoke the user's access to all applications and systems within the organization's <br /> network, ensuring that they no longer have permission to log in or access any <br /> company resources.</Text>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item lg={12}><Divider sx={{ border: "1px solid #EAECF0" }} /></Grid>
                <Grid item container>
                  <Grid item md={12} sm={12} xs={12} p={2}>
                    {
                      state.disable_user_access.status ?
                        <Text offBoardHeadFont sx={{ cursor: 'default !important' }}>Congratulations! The user access for {full_name} has been successfully restricted!</Text>
                        :
                        <Text offBoardHeadFont sx={{ cursor: 'default !important' }}>Have you restricted user access to {state.application_name} for {full_name}?</Text>
                    }
                  </Grid>
                  {
                    state.disable_user_access.status ? '' :
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} display={"flex"} justifyContent={"end"} mt={2}>
                          <Stack direction={"row"} spacing={3}>
                            <CustomButton finishLater onClick={() => { setExpanded('') }}>
                              Finish Later
                            </CustomButton>
                            <CustomButton finishFilledQuicksand onClick={() => { confirmStatus('disable_user_access') }}>
                              Yes
                            </CustomButton>
                          </Stack>
                        </Grid>
                      </>
                  }
                </Grid>
              </AccordionDetails>
            </Accordion>
            {
              state.type === 2 &&
              <Accordion
                expanded={expanded === `recovation`}
                onChange={handleChange(`recovation`)}
                className={classes.customAccordion}
                ref={messagesEndRef}
              >
                <AccordionSummary
                  expandIcon={<img src={ExpandMoreIcon} alt='expand' />}
                  aria-controls="panel1d-content" id="panel1d-header"
                  className={classes1.accordionSummary}
                >
                  <Grid item container sx={{ cursor: 'pointer !important' }}>
                    <Grid item container sx={{ cursor: 'pointer !important' }}>
                      <Grid item lg={1} md={2} sm={2} xs={2}>
                        {
                          state.send_revocation.status ?
                            <img alt='checked' src={checked} /> :
                            <img alt='off1' src={recovationOff} />
                        }

                      </Grid>
                      <Grid item lg={10} md={9} sm={9} xs={9} p={1} ml={3} display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                        <Text offBoardHeadFont >&nbsp;{typeSno[state.type].send}. Send revocation letter to USCIS for H1B or mail to university in case of OPT, CPT.</Text>
                        <Text offBoardBodyFont >Formally notify the appropriate authority, either the United States Citizenship and <br /> Immigration Services (USCIS) for H1B visa revocation or the university for OPT/CPT <br /> termination</Text>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item lg={12}><Divider sx={{ border: "1px solid #EAECF0" }} /></Grid>
                  <Grid item container>
                    <Grid item container spacing={3} pt={3}>
                      <Grid item lg={3} md={4} sm={4} xs={12}>
                        <Box pt={'8px'}>
                          <CustomSelect
                            name='email_sent_to'
                            value={state.send_revocation.email_sent_to}
                            commonSelect
                            onChange={(e) => changeHandler(e, 'send_revocation')}
                            label={'Email Sent To'}
                            options={EmailSentTo}
                            disabled={state.send_revocation.disabled}
                            error={error.send_revocation.email_sent_to}
                          />
                          <Text red>{error.send_revocation.email_sent_to}</Text>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={4} sm={4} xs={12}>
                        <Box pt={'8px'}>
                          <Date
                            labelText={'Date'}
                            name='date'
                            value={state.send_revocation.date}
                            shouldDisableDate
                            onChange={(value => dateChange(value, 'date', 'send_revocation'))}
                            error={error.send_revocation.date}
                            height='53px' />
                          <Text red>{error.send_revocation.date}</Text>
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box pt={'7px'}>
                          <FileInput
                            name={"invite_via_link"}
                            FileName={state?.send_revocation?.revocation_sent_document?.document_name}
                            actionState={state?.send_revocation?.revocation_sent_document?.document_name ? 1 : ''}
                            handleChange={uploadDocsRevocationSentDocs}
                            handleDelete={() => { deleteDoc('send_revocation', 'revocation_sent_document') }}
                            label={'Revocation Letter Sent (Optional)'}
                            documentUrl={state?.send_revocation?.revocation_sent_document?.document_url}
                            state={state?.send_revocation?.revocation_sent_document}
                          />
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box pt={'7px'}>
                          <FileInput
                            name={"invite_via_link"}
                            FileName={state?.send_revocation?.revocation_raised_document?.document_name}
                            actionState={state?.send_revocation?.revocation_raised_document?.document_name ? 1 : ''}
                            handleChange={uploadDocsRevocationRaisedDocs}
                            handleDelete={() => { deleteDoc('send_revocation', 'revocation_raised_document') }}
                            label={'Revocation Letter Raised (Optional)'}
                            documentUrl={state?.send_revocation?.revocation_raised_document?.document_url}
                            state={state?.send_revocation?.revocation_raised_document}
                          />
                        </Box>
                      </Grid>

                    </Grid>
                    {
                      state.send_revocation.status ? '' :
                        <Grid item lg={12} md={12} sm={12} xs={12} display={"flex"} justifyContent={"end"} mt={2}>
                          <Stack direction={"row"} spacing={3}>
                            <CustomButton finishLater onClick={() => { setExpanded('') }}>
                              Finish Later
                            </CustomButton>
                            <CustomButton finishFilledQuicksand onClick={() => { submit_send_revocation() }}>
                              Save
                            </CustomButton>
                          </Stack>
                        </Grid>
                    }
                  </Grid>
                </AccordionDetails>
              </Accordion>
            }
            <Accordion
              expanded={expanded === `delete`}
              onChange={handleChange(`delete`)}
              className={classes.customAccordion}
              ref={messagesEndRef}
            >
              <AccordionSummary
                expandIcon={<img src={ExpandMoreIcon} alt='expand' />}
                aria-controls="panel1d-content" id="panel1d-header"
                className={classes1.accordionSummary}
              >
                <Grid item container sx={{ cursor: 'pointer !important' }}>
                  <Grid item container sx={{ cursor: 'pointer !important' }}>
                    <Grid item lg={1} md={2} sm={2} xs={2}>
                      {
                        state.delete_mail.status ?
                          <img alt='checked' src={checked} /> :
                          <img alt='off1' src={deleteOff} />
                      }

                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={9} p={1} ml={3} display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                      <Text offBoardHeadFont >&nbsp;{typeSno[state.type].delete}. Delete Mail ID</Text>
                      <Text offBoardBodyFont >Remove the former employee's email address from all company systems and databases,<br /> ensuring that they no longer receive any correspondence or have access to company <br /> communication channels</Text>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item lg={12}><Divider sx={{ border: "1px solid #EAECF0" }} /></Grid>
                <Grid item container>
                  <Grid item container spacing={3} pt={3}>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Box pt={'8px'}>
                        <Date
                          labelText={'E-Mail ID Deleted on'}
                          name='email_deleted_on'
                          value={state.delete_mail.email_deleted_on}
                          maxDate={state.last_working_day.date}
                          error={error.delete_mail.email_deleted_on}
                          onChange={(value => dateChange(value, 'email_deleted_on', 'delete_mail'))}
                          height='53px' />
                        <Text red>{error.delete_mail.email_deleted_on}</Text>
                      </Box>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                      <Box pt={'7px'}>
                        <FileInput
                          name={"invite_via_link"}
                          FileName={state.delete_mail.proof_of_document.document_name}
                          actionState={state.delete_mail.proof_of_document.document_name ? 1 : ''}
                          handleChange={uploadDocsDeleteMail}
                          handleDelete={() => { deleteDoc('delete_mail', 'proof_of_document') }}
                          label={'Proof Of Document (Optional)'}
                          documentUrl={state.delete_mail.proof_of_document.document_url}
                          state={state?.delete_mail?.proof_of_document}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  {
                    state.delete_mail.status ? '' :
                      <Grid item lg={12} md={12} sm={12} xs={12} display={"flex"} justifyContent={"end"} mt={2}>
                        <Stack direction={"row"} spacing={3}>
                          <CustomButton finishLater onClick={() => { setExpanded('') }}>
                            Finish Later
                          </CustomButton>
                          <CustomButton finishFilledQuicksand onClick={() => { submit_delete() }}>
                            Yes
                          </CustomButton>
                        </Stack>
                      </Grid>
                  }

                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === `expenseOff`}
              onChange={handleChange(`expenseOff`)}
              className={classes.customAccordion}
              ref={messagesEndRef}
            >
              <AccordionSummary
                expandIcon={<img src={ExpandMoreIcon} alt='expand' />}
                aria-controls="panel1d-content" id="panel1d-header"
                className={classes1.accordionSummary}
              >
                <Grid item container sx={{ cursor: 'pointer !important' }}>
                  <Grid item container sx={{ cursor: 'pointer !important' }}>
                    <Grid item lg={1} md={2} sm={2} xs={2}>
                      {
                        state.expense_settlement.status ?
                          <img alt='checked' src={checked} /> :
                          <img alt='off1' src={expenseOff} />
                      }
                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={9} p={1} ml={3} display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                      <Text offBoardHeadFont >&nbsp;{typeSno[state.type].expense}. Expense Settlement </Text>
                      <Text offBoardBodyFont >Settle all the expense for the employee.</Text>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item lg={12}><Divider sx={{ border: "1px solid #EAECF0" }} /></Grid>
                  <Grid container pt={2}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                      <img src={expenseSettleOff} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                      <Text offBoardHeadFont>Hurray!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                      <Text offBoardBodyFont>There are no expenses currently.</Text>
                    </Grid>
                    {
                      state.expense_settlement.status ? '' :
                        <Grid item xs={12} container justifyContent={'flex-end'}>
                          <Stack direction={'row'} pt={3}>
                            <Box p={1} pr={0}>
                              <CustomButton finishFilledQuicksand onClick={() => { confirmStatus('expense_settlement') }}>
                                Confirm
                              </CustomButton>
                            </Box>
                          </Stack>
                        </Grid>
                    }
                  </Grid>
              </AccordionDetails>
            </Accordion>
            {/* {
              state.type === 3 &&
              <Accordion
                expanded={expanded === `bills`}
                onChange={handleChange(`bills`)}
                className={classes.customAccordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1d-content" id="panel1d-header"
                  className={classes1.accordionSummary}
                >
                  <Grid item container sx={{ cursor: 'pointer !important' }}>
                    <Grid item lg={1}>
                      {
                        state.placement.status ?
                          <img alt='checked' src={checked} /> :
                          <img alt='off1' src={billsOff} />
                      }
                    </Grid>
                    <Grid item lg={10} p={1} ml={3} display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                      <Text offBoardHeadFont >&nbsp;{typeSno[state.type].placement}. Bills Due</Text>
                      <Text offBoardBodyFont >Verify the bill amount due for the contractor before their departure</Text>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {
                    state.placement.data.length > 0 ?
                      <>
                        {state.placement.data.map((item, index) => (
                          <Grid item container>
                            <Grid item container lg={12} display={"flex"} mt={2} sx={{ backgroundColor: '#FCFCFC', padding: '10px' }}>
                              <Grid item xs={4}>
                                <Box>
                                  <Text offBoardHeadFontMedium>Vendor Name</Text>
                                  <Text mediumBoldBlack>Google</Text>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box>
                                  <Text offBoardHeadFontMedium>BILL ID</Text>
                                  <Text mediumBoldBlack>Bl-1236457</Text>
                                </Box>
                              </Grid>
                              <Grid item xs={4} container >
                                <Box>
                                  <Text offBoardHeadFontMedium>Due Amount</Text>
                                  <Text mediumBoldBlack>{getCurrencySymbol()}{" "} 2000</Text>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                        {
                          state.bills.status ? '' :
                            <Grid item xs={12} container justifyContent={'flex-end'}>
                              <Stack direction={'row'} pt={3}>
                                <Box p={1}>
                                  <CustomButton finishLater onClick={() => { setExpanded('') }}>
                                    Finish Later
                                  </CustomButton>
                                </Box>
                                <Box p={1} pr={0}>
                                  <CustomButton finishFilledQuicksand onClick={() => { confirmStatus('placement') }}>
                                    Save
                                  </CustomButton>
                                </Box>
                              </Stack>
                            </Grid>
                        }
                      </>
                      :
                      <Grid container>
                        <Grid container justifyContent={'center'} alignContent={'center'}>
                          <img src={noActiveOff} alt='noactive' />
                        </Grid>
                        {
                          state.bills.status ? '' :
                            <Grid item xs={12} container justifyContent={'flex-end'}>
                              <Stack direction={'row'} pt={3}>
                                <Box p={1} pr={0}>
                                  <CustomButton finishFilledQuicksand onClick={() => { confirmStatus('placement') }}>
                                    Confirm
                                  </CustomButton>
                                </Box>
                              </Stack>
                            </Grid>
                        }
                      </Grid>
                  }
                </AccordionDetails>
              </Accordion>
            } */}
            <Accordion
              expanded={expanded === `settle`}
              onChange={handleChange(`settle`)}
              className={classes.customAccordion}
              ref={messagesEndRef}
            >
              <AccordionSummary
                expandIcon={<img src={ExpandMoreIcon} alt='expand' />}
                aria-controls="panel1d-content" id="panel1d-header"
                className={classes1.accordionSummary}
              >
                <Grid item container sx={{ cursor: 'pointer !important' }}>
                  <Grid item container sx={{ cursor: 'pointer !important' }}>
                    <Grid item lg={1} md={2} sm={2} xs={2}>
                      {
                        state.settle_balance.status ?
                          <img alt='checked' src={checked} /> :
                          <img alt='off1' src={offBoardImag4} />
                      }
                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={9} p={1} ml={3} display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                      <Text offBoardHeadFont >&nbsp;{typeSno[state.type].settle}. Settle Balance</Text>
                      <Text offBoardBodyFont >Finalize and pay off any outstanding payments or financial settlements owed to the <br /> departing employee.</Text>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item lg={12}><Divider sx={{ border: "1px solid #EAECF0" }} /></Grid>
                  <Grid container pt={2}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                      <img src={expenseSettleOff} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                      <Text offBoardHeadFont>Hurray!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                      <Text offBoardBodyFont>There are no Payments or Settlements currently</Text>
                    </Grid>
                    {
                      state.settle_balance.status ? '' :
                        <Grid item xs={12} container justifyContent={'flex-end'}>
                          <Stack direction={'row'} pt={3}>
                            <Box p={1} pr={0}>
                              <CustomButton finishFilledQuicksand onClick={() => { confirmStatus('settle_balance') }}>
                                Confirm
                              </CustomButton>
                            </Box>
                          </Stack>
                        </Grid>
                    }
                  </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <ReusablePopup openPopup={finishPopup} setOpenPopup={setFinishPopup} white iconHide >
          <Box my={3} width={'450px'}>
            <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
              <img src={checkPriortoWeek()? completed : Initiated} alt="completed" />
            </Box>
            <Grid container justifyContent={'center'} my={1}>
              <Text my={1} offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>
                Off boarding {checkPriortoWeek() ?  "Completed":'Initiated'} 
              </Text>

            </Grid>
            <Grid container justifyContent={'center'} my={1} sx={{textAlign:'center'}}>
             {checkPriortoWeek() ? 
              <Text offBoardBodyFont>
                  <span style={{ color: "#0C75EB" }}>{capitalizeAndAddSpace(full_name.slice(0, 33)) + capitalizeAndAddSpace(full_name.length > 33 ? "..." : " ")}</span> has been off boarded Successfully
                </Text>: 
                <Text offBoardBodyFont>
                  Offboarding for <span style={{ color: "#0C75EB" }}>{capitalizeAndAddSpace(full_name.slice(0, 33)) + capitalizeAndAddSpace(full_name.length > 33 ? "..." : " ")}</span> has been initiated
                </Text>
             }
            </Grid>

            <Box my={2} className={classes.popupHead1} mt={4}>
              <CustomButton finishFilledQuicksand sx={{ width: "335px !important" }} component={Link}
                onClick={() => {
                  LocalStorage.removeRedirectedModule();
                  LocalStorage.removeSubModule();
                  LocalStorage.removeNotificationEmpId();
                }}
                to={`/employees/user-profile/${full_name ? full_name.trim().split(/ +/).join('-') : ""}`}
                state={{
                  id: id, full_name: full_name, reference_id: reference_id, offBoardButton: false,
                  avatar_url: avatar_url, active: 3, grButn: 3, from: 'employmentDashboard'
                }} >
                Done
              </CustomButton>
            </Box>
          </Box>
        </ReusablePopup>
        <BootstrapDialog
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="customized-dialog-title"
          open={expensePopup}
          fullWidth={true}
          maxWidth={"lg"}
        >
          <DialogContent sx={{ margin: "20px", }}>
            <Grid container lg={12} textAlign={'center'} justifyContent={'center'}>
              <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                <img src={expenseOffPopup} alt="OffBoard" />
              </Box>
              <Box my={2}>
                <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}> Are You Sure?</Text>
                <Text offBoardBodyFont>These changes will impact the balancesheet of employee &nbsp;&nbsp;&nbsp;&nbsp; so please re-verify</Text>
              </Box>
              <Grid container sx={{ background: '#F8F8F8', borderRadius: '8px' }} p={'20px'} alignItems='center'>
                <Stack direction={'row'} alignItems='center' pb={1}>
                  <Box sx={{ height: '5px', width: '5px', background: 'black', borderRadius: '50%', marginRight: '5px' }}></Box>
                  <Text smallBlack>Reimbursement Amount {getCurrencySymbol()}{" "}{state.expense_settlement.reimbursement_amount} will be added to balancesheet</Text>
                </Stack>
                <Stack direction={'row'} alignItems='center' pb={1}>
                  <Box sx={{ height: '5px', width: '5px', background: 'black', borderRadius: '50%', marginRight: '5px' }}></Box>
                  <Text smallBlack>Deduction Amount {getCurrencySymbol()}{" "}{state.expense_settlement.deduction_amount} will be deducted from balancesheet</Text>
                </Stack>
              </Grid>
              <Box my={2} mt={3} sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '20px' }} >
                <CustomButton popupCancel1 onClick={() => setExpensePopup(false)}>
                  Cancel
                </CustomButton>
                <CustomButton popupSaveBlue onClick={continue_expense}>Continue</CustomButton>
              </Box>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="customized-dialog-title"
          open={cancelPopup}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogContent sx={{ margin: "40px", }}>
            <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
              <img src={offBoardSure} alt='noactive' />
            </Grid>
            <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
              <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
            </Grid>
            <Grid container justifyContent={'center'} alignContent={'center'}>
              <Text offBoardBodyFont sx={{textAlign:'center'}} >Do you wish to cancel offboarding <span style={{ color: '#0C75EB' }}>
                <BlackToolTip title={capitalizeAndAddSpace(full_name)} placement="top" arrow>
                  {capitalizeAndAddSpace(full_name.slice(0, 33)) + capitalizeAndAddSpace(full_name.length > 33 ? "..." : " ")}
                </BlackToolTip>
              </span>. All the progress will be lost. </Text>
            </Grid>
            <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
              <Stack direction={"row"} spacing={3}>
                <CustomButton smallBlueOutline onClick={() => { setCancelPopup(false) }}>
                  No
                </CustomButton>
                <CustomButton redBackground onClick={() => {
                  setCancelPopup(false);
                  LocalStorage.removeRedirectedModule();
                  LocalStorage.removeSubModule();
                  LocalStorage.removeNotificationEmpId();
                  navigate(`/employees/user-profile/${full_name ? full_name.trim().split(/ +/).join('-') : ""}`, { state: { id: id, full_name: full_name, reference_id: reference_id, offBoardButton: false, avatar_url: avatar_url, active: 3, grButn: 3, from: 'employmentDashboard' } })
                }}>
                  Yes, Cancel
                </CustomButton>
              </Stack>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="customized-dialog-title"
          open={backPopup}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogContent sx={{ margin: "50px", }}>
            <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
              <img src={offBoardSave} alt='noactive' />
            </Grid>
            <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
              <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
            </Grid>
            <Grid container justifyContent={'center'} alignContent={'center'}>
              <Text offBoardBodyFont>Your progress will not be saved</Text>
            </Grid>
            <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
              <Stack direction={"row"} spacing={3}>
                <CustomButton smallBlackOutline onClick={() => { setBackPopup(false) }}>
                  Cancel
                </CustomButton>
                <CustomButton smallBlue redBackground onClick={() => {
                  LocalStorage.removeRedirectedModule();
                  LocalStorage.removeSubModule();
                  LocalStorage.removeNotificationEmpId();
                  navigate(`/employees/user-profile/${full_name ? full_name.trim().split(/ +/).join('-') : ""}`, { state: { id: id, full_name: full_name, reference_id: reference_id, offBoardButton: false, avatar_url: avatar_url, active: 3, grButn: 3, from: 'employmentDashboard' } })
                }}>
                  Yes
                </CustomButton>
              </Stack>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Box >
      <Box pt={2} sx={{ width: "100%", display: "flex", justifyContent: "center", height: "9vh" }}  >
        <Grid container lg={7} md={7} sm={7} xs={12}>
          <Grid item container lg={6} md={6} sm={6} xs={12} pl={2}>
            {
              state.finish_once ?
                <CustomButton cancelOutlineQuicksand onClick={() => setBackPopup(true)}>Back</CustomButton> :
                <CustomButton cancelOutlineQuicksand onClick={() => openCancelPopup()}>Cancel</CustomButton>
            }
          </Grid>
          <Grid item container lg={6} md={6} sm={6} xs={12} pr={2} justifyContent={'flex-end'}>
            {
              state.finish_once ?
                <LoaderButton loading={loader} finishFilledQuicksand onClick={() => { saveOffboard() }}>{loader?'Saving':'Save'}</LoaderButton> :
                <LoaderButton loading={loader} finishFilledQuicksand onClick={() => { finishOffboard() }}>{loader ? 'Finishing':'Finish'}</LoaderButton>
            }

          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default OffboardingChecklist
