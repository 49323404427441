import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Skeleton } from "@mui/material";
import Text from "../../../../../components/customText/Text";
import MainStyles from "../../MainStyles";
import Input from "../../../../../components/input/Input";
import Button from "../../../../../components/customButton/Button";
import {
  BlackToolTip,
  NoDataFound,
  addErrorMsg,
  addSuccessMsg, NoPermission,
  ListLoadingIcon
} from "../../../../../utils/utils";
import LocalStorage from "../../../../../utils/LocalStorage";
import ConfigApi from "../../../../../apis/configurations/ConfigApi";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import EditIcon from "../../../../../assets/svg/newEdit.svg";
import infoCircle from "../../../../../assets/images/info-circle.png";
import { styled } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";
import PlacementApi from "../../../../../apis/admin/placements/PlacementApi";
import LoadingButton from "../../../../../components/customButton/LoadingButton";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${theme?.classes?.tooltip}`]: {
    backgroundColor: "#696969",
    padding: "6px 14px",
    minWidth: 100,
    border: "1px solid #696969",
    borderRadius: "4px",
  },
  [`& .${theme?.classes?.arrow}`]: {
    color: "#696969",
    "&::before": {
      backgroundColor: "#696969",
      border: "1px solid #696969",
    },
  },
}));

function Placements({ current }) {
  var rolePermission = LocalStorage.getRolesData()
    ? LocalStorage.getRolesData().role_permissions.permissions
    : "";
  const classes = MainStyles();
  const recentSection = useRef(null);

  const [activityFilter, setActivityFilter] = useState({
    limit: 10,
    page: 1,
  });

  const [activityTotal, setActivityTotal] = useState();
  const activityRef = useRef(null);
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [inputValue, setInputValue] = useState({
    id: '',
    default_annual_hours: ''
  });
  const [recentActivityShow, setrecentActivityShow] = useState(false)
  const [listLoading, setListLoading] = useState(false);
  useEffect(() => {
    if( !LocalStorage.getStartTour()?.orgconfig){
    if (
      LocalStorage.getUserData().super_admin ||
      (rolePermission !== "" &&
        rolePermission.some(
          (item) =>
            item.slug == "organization_details_view" && item.is_allowed == true
        ))
    ) {
      getActivity(activityFilter);
      defaultHoursApi();
    }} // eslint-disable-next-line
  }, []);

  const getActivity = (args, popup) => {
    if (activityFilter.limit <= 10) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    ConfigApi.getActivity(30, "", args).then((response) => {
      if (response.data.statusCode == 1003) {
        setLoading(false);
        setActivityData(response.data.data);
        setActivityTotal(response.data.pagination.total);
        if (popup) { }
        else {
          setrecentActivityShow(true)
        }
      }
      // removeLoader();
      setListLoading(false);
    });
  };

  const defaultHoursApi = () => {
    setLoader(true);
    PlacementApi.getDefaultHours().then((res) => {
      setLoader(false);
      if (res.data.statusCode == 1003) {
        setInputValue({ ...res.data.data });
      } else {
        addErrorMsg(res.data.message);
      }
    })
  }

  const UpdateApi = () => {
    if (inputValue.default_annual_hours == '') {
      addErrorMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
    } else if (inputValue.default_annual_hours == 0 || inputValue.default_annual_hours == '0000:00' || inputValue.default_annual_hours == '0000:0') {
      addErrorMsg('Work hours should be greater than zero.');
    } else if (inputValue.default_annual_hours > 5000) {
      addErrorMsg('Default hours should not be greater than 5000');
    } else {
      if (inputValue.default_annual_hours.includes(':')) { } else {
        inputValue.default_annual_hours = `${inputValue.default_annual_hours}:00`
        setInputValue({ ...inputValue })
      }
      setButtonLoader(true);
      const data = {
        request_id: LocalStorage.uid(),
        default_annual_hours: inputValue.default_annual_hours
      }
      PlacementApi.defaultHoursUpdate(inputValue.id, data).then((res) => {
        setButtonLoader(false);
        if (res.data.statusCode == 1003) {
          addSuccessMsg(res.data.message);
          setDisable(true);
          getActivity(activityFilter);
        } else {
          addErrorMsg(res.data.message);
        }
      })
    }
  }

  const activityHandleScroll = () => {
    const { current } = activityRef;
    if (current) {
      const { scrollTop, scrollHeight, clientHeight } = current;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        if (Number(activityTotal) >= activityFilter.limit) {
          // addLoader(true);
          setListLoading(true);
          activityFilter.limit = activityFilter.limit + 10;
          setActivityFilter({ ...activityFilter });
          getActivity(activityFilter, true);
        }
      }
    }
  };

  const scrollDown = () => {
    window.scroll({
      top: recentSection.current.scrollIntoView(),
      behavior: "smooth",
    });
    setrecentActivityShow(false)
  };
  const [disable, setDisable] = useState(true);


  const changeHandler = (e) => {
    let input = e.target.value.replace(/\D/g, "").substring(0, 6);
    const first = input.substring(0, 4);
    const second = input.substring(4, 6);
    if (input.length > 4) {
      setInputValue({ ...inputValue, [e.target.name]: `${first}:${second}` });
    } else {
      setInputValue({ ...inputValue, [e.target.name]: input });
    }
    // setInputValue({
    //   ...inputValue,
    //   [e.target.name]: e.target.value
    // });
  };

  return (
    <div>{LocalStorage.getStartTour()?.orgconfig ? NoDataFound():
      (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_view" && item.is_allowed == true))) ?
        <Box sx={{ height: '70vh', padding: '16px', overflow: "auto"}}>
          <Box
            className={classes.activeItemBox}
            sx={{ padding: "25px", marginTop: "16px" }}
          >
            <Grid container spacing={1} className={classes.flexContainer}>
              <Grid item sx={{ display: "flex" }}>
                <Text blackHeader1 className={classes.placementHeadingWeight}>
                  Default Work Hours{"  "}
                  <HtmlTooltip
                    placement="right"
                    arrow
                    title={
                      <React.Fragment>
                        <Box>
                          <Text mediumWhite400>
                            This is to calculate the pay rate and the margin for the
                            placement
                          </Text>
                        </Box>
                      </React.Fragment>
                    }
                  >
                    <img
                      src={infoCircle}
                      alt="infoCircle"
                      height={"17px"}
                      className={classes.infoIconProperties}
                    />
                  </HtmlTooltip>
                </Text>
              </Grid>
              <Grid item justifyContent={"flex-end"}>
                {disable && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "working_hours_edit" && item.is_allowed == true))) ?
                  <Grid>
                    <img
                      src={EditIcon}
                      alt="Edit"
                      onClick={() => {
                        setDisable(false);
                      }}
                      style={{
                        cursor: 'pointer'
                      }}
                    />
                  </Grid>
                  : ''}
              </Grid>
            </Grid>
            <Grid className={classes.flexContainerWithSpacing}>
              <Grid className={classes.centeredFlexContainerWithGap}>
                {
                  loader ?
                    <Skeleton animation="wave" width="200px" height='60px' /> :
                    <Box display='flex' flexDirection='column' gap={0.5} width='100%'>
                      <Input
                        formControlProps={{
                          fullWidth: "true",
                        }}
                        inputProps={{
                          name: "default_annual_hours",
                          value: inputValue.default_annual_hours,
                        }}
                        disabled={disable}
                        labelText={"Work hours/ year"}
                        handleChange={(e) => changeHandler(e)}
                        clientInput
                        error={(inputValue.default_annual_hours == '' || inputValue.default_annual_hours == 0 || inputValue.default_annual_hours == '0000:00' || inputValue.default_annual_hours == '0000:0' || inputValue.default_annual_hours > 5000)}
                      />
                      <Box sx={{ height: '0px' }}>
                        <Text red>
                          {
                            inputValue.default_annual_hours == '' ? 'This field is required' : (inputValue.default_annual_hours == 0 || inputValue.default_annual_hours == '0000:00' || inputValue.default_annual_hours == '0000:0') ? 'Work hours should be greater than 0' : inputValue.default_annual_hours > 5000 ? 'Default hours should not be greater than 5000' : ''
                          }
                        </Text>
                      </Box>
                    </Box>
                }
                <HtmlTooltip
                  placement="right"
                  arrow
                  title={
                    <React.Fragment>
                      <Box>
                        <Text mediumWhite400>
                          If the work hours/ year is changed, this will affect all
                          the previous, current and upcoming placements and their
                          pay rates and margins (only for salaried employees)
                        </Text>
                      </Box>
                    </React.Fragment>
                  }
                >
                  {
                    loader ?
                      <Skeleton animation="wave" width="40px" height='60px' borderRadius='50%' /> :
                      <img
                        src={infoCircle}
                        alt="infoCircle"
                        height={"17px"}
                        className={classes.infoIconProperties}
                      />
                  }
                </HtmlTooltip>
              </Grid>

              {!disable && (
                <Grid sx={{ display: "flex", gap: "15px" }}>
                  <Button
                    cancelOutlineBlue
                    className={classes.cancelButtonInPlacements}
                    onClick={() => { setDisable(true); defaultHoursApi() }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={buttonLoader}
                    blueButton
                    className={classes.saveButtonInPlacements}
                    onClick={UpdateApi}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Box>

          <Box className={classes.activeItemBox} mt={4} mb={6} ref={recentSection}>
            <Box mb={3}>
              <Text>Recent Activities</Text>
            </Box>
            <Box
              sx={{
                height: "40vh",
                overflowY: "auto",
              }}
              ref={activityRef}
              onScroll={activityHandleScroll}
            >
              {loading ? (
                <>
                  {[1, 2, 3].map((item, index) => (
                    <Grid container key={index} mt={3}>
                      <Grid item lg={8}>
                        <Skeleton animation="wave" width="200px" />
                        <Skeleton animation="wave" width="200px" />
                      </Grid>
                      <Grid item lg={3}>
                        <Skeleton animation="wave" width="200px" />
                        <Skeleton animation="wave" width="200px" />
                      </Grid>
                    </Grid>
                  ))}
                </>
              ) : (
                <>
                  {activityData.length > 0 ? (
                    <>
                      {activityData.map((value, index) => (
                        <Box
                          className={classes.descriptionBoxStyle}
                          key={index}
                          mb={2}
                        >
                          <Grid container spacing={6}>
                            <Grid
                              container
                              item
                              lg={4}
                              md={4}
                              sm={6}
                              xs={12}
                              alignItems={"center"}
                            >
                              <Text mediumBlackColor>
                                {
                                  value.user_name && value.user_name.length > 16 ?
                                    <BlackToolTip title={value.user_name} placement="top" arrow>
                                      {value.user_name.slice(0, 16) + (value.user_name.length > 16 ? "..." : "")}
                                    </BlackToolTip>
                                    : value.user_name
                                } {value.action}
                                <br />
                                <Text greyLabel mt={"5px"}>
                                  {value.created_at}
                                </Text>
                              </Text>
                            </Grid>
                            <Grid
                              item
                              lg={8}
                              md={8}
                              sm={6}
                              xs={12}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              {value.change_log.length > 0 &&
                                value.change_log.map((item) => (
                                  <Text mediumGrey2>{item}.</Text>
                                ))}
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </>
                  ) : (
                    <>{NoDataFound("", "No Data Found", "50%", "35")}</>
                  )}
                </>
              )}
              {listLoading && (
                <Box >
                  <ListLoadingIcon />
                </Box>
              )}
            </Box>
          </Box> 
          </Box> :
        <>
          {NoPermission()}
        </>
    }
      {recentActivityShow ?
        <Grid container xs={6} justifyContent={'center'}>
          <Button
            addButton
            sx={{
              position: "fixed",
              bottom: 20,
              borderRadius: "30px !important",
              paddingLeft: "20px",
              paddingRight: "20px",
              font: "14px Quicksand !important",
              fontWeight: `${700} !important`,
            }}
            onClick={scrollDown}
            endIcon={<ArrowDownwardRoundedIcon sx={{ width: "24px" }} />}
          >
            New Activity
          </Button>
        </Grid>
        : ''}
    </div>
  );
}

export default Placements;
