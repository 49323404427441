import { Backdrop, Box, Grid, Step, Stepper, Autocomplete, Chip, TextField } from '@mui/material'
import React, { useState } from 'react'
import { AddEmployeeStepper, BrownMnColorlibStepLabel, BrownMnCustomisedConnector } from '../../theme';
import Text from '../../components/customText/Text';
import logoName from '../../assets/svg/logoName.svg';
import OnboardStyles from './OnboardStyles';
import Dropzone from 'react-dropzone';
import download from '../../assets/employee/filedownload.svg';
import Button from '../../components/customButton/Button';
import { addErrorMsg, addLoader, removeLoader, scrollToTop } from '../../utils/utils';
import Loader from '../../assets/gif/japfuLoader.gif';
import EmergencyContactInformation from './EmergencyContactInformation';
import I9andW4Documents from './I9andW4Documents';
import onBoardLinkApiInstance from '../../apis/onBoardLink/onBoardLinkApi';
// import clear from '../../assets/employee/cross-grey-circle.svg';
import LocalStorage from '../../utils/LocalStorage';
import { domain } from '../../config/Domain';

function OnboardEmployeeUpload({ state, setState, id, active, setActive, preView, setPreview, getInviteViaLink, data }) {
    // const queryParams = new URLSearchParams(window.location.search);
    // let d = window.location.pathname
    // let b = d.split('/')
    // let c = b[b.length - 1]
    // let f = c.split('=')
    // let k = f[1]
    // console.log(k, "queryParams");
    // const id = k;
    let dataKeys = Object.keys(data.upload_documents)
    let x = dataKeys.filter(
        (i) => data.upload_documents[i].checked == true
    )


    let options = {
        counter_signed_offer_letter: {
            name: 'Countersigned Offer Letter',
            content: `A countersigned offer is a document that has been signed by both the employer and the prospective employee, indicating mutual agreement and acceptance of the terms outlined in the job offer. This counter offer can be downloaded from the mail.`
        },
        passport: {
            name: 'Passport',
            content: `Please include all pages with stamps or visas, along with the biodata page, ensuring correct sizing and order, excluding blank pages.`
        },
        i94: {
            name: 'I94',
            content: `Please upload your record showing entry date, immigration status, and authorized duration of stay.`
        },
        signed_ssn: {
            name: 'Signed SSN',
            content: `Your "signed SSN" on official documents like the Social Security card or tax forms is validated for various purposes like identity verification, employment eligibility, tax reporting, and accessing government benefits or services.`
        },
        work_authorization: {
            name: 'Work Authorization',
            content: `Please upload your current visa document and previous visa documents.`
        },
        all_previous_i20s: {
            name: `All Previous I-20’s`,
            content: `The "previous signed I-20" is a prior Form I-20, a crucial document issued by U.S. educational institutions to international students, confirming acceptance and visa status terms.`
        },
        education_documents: {
            name: 'Education Documents',
            content: `Upload your undergraduate and highest degree documents.`
        },
        drivers_license: {
            name: 'Driving License/State ID',
            content: `Please upload a clear copy of your Driver's License. This document serves as an official identification and verifies your driving eligibility.`
        },
        copy_of_void_cheque: {
            name: 'Copy Of Void Cheque',
            content: `A void cheque bears the word 'VOID' prominently. It's a blank cheque used to share account details securely. Please enter max 5 void cheques.`
        }
    }

    const classes = OnboardStyles();
    const mainStepsList = ['Upload Documents', 'I-9 & W4', 'Emergency Contact Information'];
    const [mainStep, setMainStep] = useState(0);
    const [expand, setExpand] = useState(true);
    const [disableExpand, setDisableExpand] = useState(false);
    const [status, setStatus] = useState('');
    const [openBacKLoader, setOpenBackDrop] = useState(false);
    const [name, setName] = useState(x[0]);

    const handleExpand = (args, name) => {
        if (args == active) {
            setExpand(!expand)
        } else {
            if (name === 'active') {
                setDisableExpand(!disableExpand);
                setStatus(args);
            } else {
                setDisableExpand(disableExpand);
                setStatus('');
            }
        }
    }
    const handleDrop = (e) => {
        if (e.length <= 10) {
            setOpenBackDrop(true);
            for (let i = 0; i < e.length; i++) {
                if (e[i].path.split('.').some(r => ['pdf', 'jpg', 'png', 'jpeg'].includes(r))) {
                    if (Number(((e[i].size / (1024 * 1024)).toFixed(2))) < 25) {
                        const formData = new FormData();
                        // e.map((i) => formData.append("files", i))
                        formData.append("files", e[i]);
                        // formData.append("tenant_id", '4a0bcfaf-5fb3-4d88-b133-b1b5eb6c137f');
                        onBoardLinkApiInstance.documentUploadInvite(
                            name == 'copy_of_void_cheque' ? 'bank-document' : name == 'counter_signed_offer_letter' ? 'personal-document' : name == 'work_authorization' ? 'visa-document' :
                                name == 'all_previous_i20s' ? 'visa-document' : name == 'signed_ssn' ? 'education-document' : name == 'education_documents' ? 'education-document' :
                                    name == 'passport' ? 'passport-document' : name == 'i94' ? 'i94-document' : name == 'drivers_license' ? 'personal-document' : '', formData).then((res) => {
                                        if (res.data.statusCode == 1003) {
                                            if (name == 'counter_signed_offer_letter' && state.upload_documents.counter_signed_offer_letter.data.length < 5) {
                                                state.upload_documents.counter_signed_offer_letter.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.counter_signed_offer_letter.data);
                                            } else if (name == 'work_authorization' && state.upload_documents.work_authorization.data.length < 5) {
                                                state.upload_documents.work_authorization.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.work_authorization.data);
                                            } else if (name == 'all_previous_i20s' && state.upload_documents.all_previous_i20s.data.length < 5) {
                                                state.upload_documents.all_previous_i20s.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.all_previous_i20s.data);
                                            } else if (name == 'signed_ssn' && state.upload_documents.signed_ssn.data.length < 1) {
                                                state.upload_documents.signed_ssn.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.signed_ssn.data);
                                            } else if (name == 'education_documents' && state.upload_documents.education_documents.data.length < 10) {
                                                state.upload_documents.education_documents.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.education_documents.data)
                                            } else if (name == 'passport' && state.upload_documents.passport.data.length < 1) {
                                                state.upload_documents.passport.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.passport.data)
                                            } else if (name == 'i94' && state.upload_documents.i94.data.length < 1) {
                                                state.upload_documents.i94.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.i94.data)
                                            } else if (name == 'drivers_license' && state.upload_documents.drivers_license.data.length < 1) {
                                                state.upload_documents.drivers_license.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.drivers_license.data);
                                                // const data1 = {
                                                //     id: res.data.data.id,
                                                //     url: res.data.data.document_url
                                                // }
                                                // EmployeesApi.ocrDocumentUpload('dl-data', data1).then((res) => {
                                                //     if (res.data.statusCode == 1003) {
                                                //         if (i === e.length - 1) {
                                                //             setOpenBackDrop(false);
                                                //         }
                                                //         setPreview(state.upload_documents.drivers_license.data);
                                                //     } else {
                                                //         addErrorMsg(res.data.message);
                                                //     }
                                                // })
                                            }
                                            else if (name == 'copy_of_void_cheque' && state.upload_documents.copy_of_void_cheque.data.length < 5) {
                                                state.upload_documents.copy_of_void_cheque.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                setPreview(state.upload_documents.copy_of_void_cheque.data);
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                            }
                                            else {
                                                setOpenBackDrop(false);
                                                addErrorMsg(`You can upload a maximum of ${active == 3 || active == 2 || active == 4 || active == 8 ? '1' : active == 7 ? '10' : '5'} files at a time. Please remove excess files and try again`)
                                            }
                                            setState({ ...state })
                                        } else {
                                            setOpenBackDrop(false);
                                            addErrorMsg(res.data.message);
                                        }
                                    })
                    }
                    else {
                        setOpenBackDrop(false);
                        addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
                    }
                }
                else {
                    setOpenBackDrop(false);
                    addErrorMsg('Please upload files in PDF or Image format only.')
                }
            }
        } else {
            setOpenBackDrop(false);
            addErrorMsg('You can upload a maximum of 10 files at a time. Please remove excess files and try again')
        }
    }

    const deleteChip = (args) => {
        if (name == 'copy_of_void_cheque') {
            state.upload_documents.copy_of_void_cheque.data.splice(args, 1)
        } else if (name == 'counter_signed_offer_letter') {
            state.upload_documents.counter_signed_offer_letter.data.splice(args, 1)
        } else if (name == 'work_authorization') {
            state.upload_documents.work_authorization.data.splice(args, 1)
        } else if (name == 'all_previous_i20s') {
            state.upload_documents.all_previous_i20s.data.splice(args, 1)
        } else if (name == 'signed_ssn') {
            state.upload_documents.signed_ssn.data.splice(args, 1)
        } else if (name == 'education_documents') {
            state.upload_documents.education_documents.data.splice(args, 1);
        } else if (name == 'passport') {
            state.upload_documents.passport.data.splice(args, 1);
        } else if (name == 'i94') {
            state.upload_documents.i94.data.splice(args, 1);
        } else if (name == 'drivers_license') {
            state.upload_documents.drivers_license.data.splice(args, 1);
        }
        setState({ ...state });
    }


    const updateApi = () => {
        state['subdomain_name'] = domain
        state['request_id'] = LocalStorage.uid();
        addLoader(true);
        onBoardLinkApiInstance.uploadDocsUpdate(id, state).then((res) => {
            if (res.data.statusCode == 1003) {
                removeLoader();
                getInviteViaLink(id);
                if (active <= (x.length - 1)) {
                    setActive(active + 1);
                    setName(x[active]);
                } else if (active == x.length) {
                    setMainStep(mainStep + 1);
                    scrollToTop();
                }
            } else {
                removeLoader();
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleSubmit = () => {
        addLoader(true);
        updateApi();
    }
    const handleSkip = () => {
        if (active <= (x.length - 1)) {
            setActive(active + 1);
            setName(x[active]);
        } else if (active == x.length) {
            setMainStep(mainStep + 1);
            scrollToTop();
        }
    }
    const handleBack = () => {
        if (active <= x.length) {
            setActive(active - 1);
            setName(x[active - 2]);
        }
    }
    return (
        <Grid container>
            <Grid item lg={2} md={2} sm={2} xs={2}>
                <img src={data ? data.logo_url : logoName} alt="logoName" className={classes.logoStyles}></img>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent='center'>
                <Grid item xl={7} lg={8} md={10} sm={10} xs={12} justifyContent='center' textAlign='center' pt={3}>
                    <Stepper activeStep={mainStep} connector={<BrownMnCustomisedConnector />}>
                        {
                            mainStepsList.map((item) => (
                                <Step>
                                    <BrownMnColorlibStepLabel StepIconComponent={AddEmployeeStepper}>
                                        <Text BrowmnMnStepperText>{item}</Text>
                                    </BrownMnColorlibStepLabel>
                                </Step>
                            ))
                        }
                    </Stepper>
                </Grid>
                <Grid item container xl={8} lg={9} md={11} sm={11} xs={12} justifyContent='center' textAlign='center' pt={7} spacing={2}>
                    {
                        mainStep == 0 ?
                            <>
                                <Grid item container lg={4} md={4} sm={4} xs={12} rowSpacing={2} sx={{ height: '550px', overflowY: 'scroll' }}>
                                    {
                                        x.map((item, index) => (
                                            <Grid item lg={12} md={12} sm={12} xs={12} className={active == index + 1 ? classes.blueBg : classes.greyBg} onClick={() => handleExpand(index + 1, 'active', item)}>
                                                {
                                                    active == index + 1 ?
                                                        <>
                                                            <Text largeWhite>{index + 1}. {options[item].name}</Text>
                                                            {
                                                                (active == index + 1) && expand ?
                                                                    <Text mediumBoldWhite400 sx={{ paddingTop: '10px' }}>{options[item].content}</Text> : ''
                                                            }
                                                        </> :
                                                        <>
                                                            <Text overViewLable>{index + 1}. {options[item].name}</Text>
                                                            {
                                                                disableExpand && (status == (index + 1)) ?
                                                                    <Text smallGreyFont sx={{ paddingTop: '10px' }}>{options[item].content}</Text> : ''
                                                            }
                                                        </>
                                                }
                                            </Grid>
                                        ))
                                    }

                                    {/* <Grid item lg={12} md={12} sm={12} xs={12} className={active == 2 ? classes.blueBg : classes.greyBg} onClick={() => handleExpand(2, 'active')}>
                                        {
                                            active == 2 ?
                                                <>
                                                    <Text largeWhite>2. Countersigned Offer Letter</Text>
                                                    {
                                                        active == 2 && expand ?
                                                            <Text mediumBoldWhite400 sx={{ paddingTop: '10px' }}>A countersigned offer is a<br /> document that has been signed by<br /> both the employer and the<br /> prospective employee, indicating<br /> mutual agreement and acceptance<br /> of the terms outlined in the job<br /> offer. This counter offer can be<br /> downloaded from the mail.</Text> : ''
                                                    }
                                                </> :
                                                <>
                                                    <Text overViewLable>2. Countersigned Offer Letter</Text>
                                                    {
                                                        disableExpand && status == 2 ?
                                                            <Text smallGreyFont sx={{ paddingTop: '10px' }}>A countersigned offer is a<br /> document that has been signed by<br /> both the employer and the<br /> prospective employee, indicating<br /> mutual agreement and acceptance<br /> of the terms outlined in the job<br /> offer. This counter offer can be<br /> downloaded from the mail.</Text> : ''
                                                    }
                                                </>
                                        }
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className={active == 3 ? classes.blueBg : classes.greyBg} onClick={() => handleExpand(3, 'active')}>
                                        {
                                            active == 3 ?
                                                <>
                                                    <Text largeWhite>3. Work Authorization</Text>
                                                    {
                                                        active == 3 && expand ?
                                                            <Text mediumBoldWhite400 sx={{ paddingTop: '10px' }}>Please upload your current visa<br /> document and previous visa<br /> documents.</Text> : ''
                                                    }
                                                </> :
                                                <>
                                                    <Text overViewLable>3. Work Authorization</Text>
                                                    {
                                                        disableExpand && status == 3 ?
                                                            <Text smallGreyFont sx={{ paddingTop: '10px' }}>Please upload your current visa<br /> document and previous visa<br /> documents.</Text> : ''
                                                    }
                                                </>
                                        }
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className={active == 4 ? classes.blueBg : classes.greyBg} onClick={() => handleExpand(4, 'active')}>
                                        {
                                            active == 4 ?
                                                <>
                                                    <Text largeWhite>4. All Previous I-20’s</Text>
                                                    {
                                                        active == 4 && expand ?
                                                            <Text mediumBoldWhite400 sx={{ paddingTop: '10px' }}>The "previous signed I-20" is a prior<br /> Form I-20, a crucial document<br /> issued by U.S. educational <br />institutions to international <br />students, confirming acceptance <br />and visa status terms.</Text> : ''
                                                    }
                                                </> :
                                                <>
                                                    <Text overViewLable>4. All Previous I-20’s</Text>
                                                    {
                                                        disableExpand && status == 4 ?
                                                            <Text smallGreyFont sx={{ paddingTop: '10px' }}>The "previous signed I-20" is a prior<br /> Form I-20, a crucial document<br /> issued by U.S. educational <br />institutions to international <br />students, confirming acceptance <br />and visa status terms.</Text> : ''
                                                    }
                                                </>
                                        }
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className={active == 5 ? classes.blueBg : classes.greyBg} onClick={() => handleExpand(5, 'active')}>
                                        {
                                            active == 5 ?
                                                <>
                                                    <Text largeWhite>5. Signed SSN</Text>
                                                    {
                                                        active == 5 && expand ?
                                                            <Text mediumBoldWhite400 sx={{ paddingTop: '10px' }}>Your "signed SSN" on official<br /> documents like the Social Security <br />card or tax forms is validated for <br />various purposes like identity <br />verification, employment eligibility,<br /> tax reporting, and accessing <br />government benefits or services.</Text> : ''
                                                    }
                                                </> :
                                                <>
                                                    <Text overViewLable>5. Signed SSN</Text>
                                                    {
                                                        disableExpand && status == 5 ?
                                                            <Text smallGreyFont sx={{ paddingTop: '10px' }}>The "previous signed I-20" is a prior<br />Your "signed SSN" on official<br /> documents like the Social Security <br />card or tax forms is validated for <br />various purposes like identity <br />verification, employment eligibility,<br /> tax reporting, and accessing <br />government benefits or services.</Text> : ''
                                                    }
                                                </>
                                        }
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className={active == 6 ? classes.blueBg : classes.greyBg} onClick={() => handleExpand(6, 'active')}>
                                        {
                                            active == 6 ?
                                                <>
                                                    <Text largeWhite>6. Education Documents</Text>
                                                    {
                                                        active == 6 && expand ?
                                                            <Text mediumBoldWhite400 sx={{ paddingTop: '10px' }}>upload your undergraduate and<br /> highest degree documents.</Text> : ''
                                                    }
                                                </> :
                                                <>
                                                    <Text overViewLable>6. Education Documents</Text>
                                                    {
                                                        disableExpand && status == 6 ?
                                                            <Text smallGreyFont sx={{ paddingTop: '10px' }}>upload your undergraduate and<br /> highest degree documents.</Text> : ''
                                                    }
                                                </>
                                        }
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className={active == 7 ? classes.blueBg : classes.greyBg} onClick={() => handleExpand(7, 'active')}>
                                        {
                                            active == 7 ?
                                                <>
                                                    <Text largeWhite>7. Passport</Text>
                                                    {
                                                        active == 7 && expand ?
                                                            <Text mediumBoldWhite400 sx={{ paddingTop: '10px' }}>Please include all pages with <br />stamps or visas, along with the<br /> biodata page, ensuring correct<br /> sizing and order, excluding blank<br /> pages.</Text> : ''
                                                    }
                                                </> :
                                                <>
                                                    <Text overViewLable>7. Passport</Text>
                                                    {
                                                        disableExpand && status == 7 ?
                                                            <Text smallGreyFont sx={{ paddingTop: '10px' }}>Please include all pages with <br />stamps or visas, along with the<br /> biodata page, ensuring correct<br /> sizing and order, excluding blank<br /> pages.</Text> : ''
                                                    }
                                                </>
                                        }
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className={active == 8 ? classes.blueBg : classes.greyBg} onClick={() => handleExpand(8, 'active')}>
                                        {
                                            active == 8 ?
                                                <>
                                                    <Text largeWhite>8. I94</Text>
                                                    {
                                                        active == 8 && expand ?
                                                            <Text mediumBoldWhite400 sx={{ paddingTop: '10px' }}>Please upload your record showing<br /> entry date, immigration status, and<br /> authorized duration of stay.</Text> : ''
                                                    }
                                                </> :
                                                <>
                                                    <Text overViewLable>8. I94</Text>
                                                    {
                                                        disableExpand && status == 8 ?
                                                            <Text smallGreyFont sx={{ paddingTop: '10px' }}>Please upload your record showing<br /> entry date, immigration status, and<br /> authorized duration of stay.</Text> : ''
                                                    }
                                                </>
                                        }
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className={active == 9 ? classes.blueBg : classes.greyBg} onClick={() => handleExpand(9, 'active')}>
                                        {
                                            active == 9 ?
                                                <>
                                                    <Text largeWhite>9. Driver’s License</Text>
                                                    {
                                                        active == 9 && expand ?
                                                            <Text mediumBoldWhite400 sx={{ paddingTop: '10px' }}>A void cheque bears the word<br /> 'VOID' prominently. It's a blank<br /> check used to share account <br />details securely.</Text> : ''
                                                    }
                                                </> :
                                                <>
                                                    <Text overViewLable>9. Driver’s License</Text>
                                                    {
                                                        disableExpand && status == 9 ?
                                                            <Text smallGreyFont sx={{ paddingTop: '10px' }}>A void cheque bears the word<br /> 'VOID' prominently. It's a blank<br /> check used to share account <br />details securely.</Text> : ''
                                                    }
                                                </>
                                        }
                                    </Grid> */}
                                </Grid>
                                <Grid item container spacing={2} lg={8} md={8} sm={8} xs={12}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Dropzone onDrop={acceptedFiles => { handleDrop(acceptedFiles) }}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section className={classes.dropZone}>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <Box className={classes.dropzoneContainer}>
                                                            <Grid container spacing={1} textAlign='center' justifyContent='center'>
                                                                <Grid item lg={12} marginTop={'-8px'}>
                                                                    <img src={download} alt='download' />
                                                                </Grid>
                                                                <Grid item lg={12}>
                                                                    <Text largeBlack>Drag & Drop Documents</Text>
                                                                </Grid>
                                                                <Grid item lg={12}>
                                                                    <Text smallGrey>or</Text>
                                                                </Grid>
                                                                <Box display='flex' flexDirection='row' gap={2} textAlign='center' p={'10px 0px 20px 0px'}>
                                                                    <Text smallBlue1 className={classes.clickhere}>Click Here</Text>
                                                                    <Text largeBlack>To Upload</Text>
                                                                </Box>
                                                                <Grid item lg={12} mt={1}>
                                                                    <Text smallGreyFont>Document Size 25 MB, Format - PDF,  IMG (Jpeg, PNG)</Text>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </Grid>
                                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {
                                            (active == 1 && state.upload_documents.copy_of_void_cheque.data.length == 0) ||
                                                (active == 2 && state.upload_documents.counter_signed_offer_letter.data.length == 0) ||
                                                (active == 3 && state.upload_documents.work_authorization.data.length == 0) ||
                                                (active == 4 && state.upload_documents.all_previous_i20s.data.length == 0) || (active == 5 && state.upload_documents.signed_ssn.data.length == 0) ||
                                                (active == 6 && state.upload_documents.education_documents.data.length == 0) || (active == 7 && state.upload_documents.passport.data.length == 0) ||
                                                (active == 8 && state.upload_documents.i94.data.length == 0) || (active == 9 && state.upload_documents.drivers_license.data.length == 0) ?
                                                <Box className={classes.greyText}>
                                                    <Text offBoardBodyFont>Uploaded Documents</Text>
                                                </Box>
                                                :
                                                <Box className={classes.uploadDocsInput}>
                                                    <Box sx={{ height: '15px' }}>
                                                        <Text smallBlue>Uploaded Documents</Text>
                                                    </Box>
                                                    <Box className={classes.chipsScroll}>
                                                        <Grid container>
                                                            {
                                                                ((active == 1 && state.upload_documents.copy_of_void_cheque.data) || (active == 2 && state.upload_documents.counter_signed_offer_letter.data) ||
                                                                    (active == 3 && state.upload_documents.work_authorization.data) || (active == 4 && state.upload_documents.all_previous_i20s.data) ||
                                                                    (active == 5 && state.upload_documents.signed_ssn.data) || (active == 6 && state.upload_documents.education_documents.data) ||
                                                                    (active == 7 && state.upload_documents.passport.data) || (active == 8 && state.upload_documents.i94.data) || (active == 9 && state.upload_documents.drivers_license.data)).map((item, index) => (
                                                                        <Grid item lg={3}>
                                                                            <Box gap={2} display='flex' flexDirection='row' justifyContent='space-between' className={classes.chip}>
                                                                                <Text mediumBlue onClick={() => window.open(item.document_url)} className={classes.cursor} noWrap>{item.document_name}</Text>
                                                                                <img src={clear} alt='clear' className={classes.cursor} onClick={() => deleteChip(index)} />
                                                                            </Box>
                                                                        </Grid>
                                                                    ))
                                                            }
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                        }
                                    </Grid> */}
                                    <Grid item lg={12} pt={3}>
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center", border: "1px solid #C7CCD3", borderRadius: "8px", }}>
                                            <div style={{
                                                width: '100%', paddingTop:
                                                    (active == 1 && state.upload_documents.copy_of_void_cheque.data.length > 0) ||
                                                        (active == 2 && state.upload_documents.counter_signed_offer_letter.data.length > 0) ||
                                                        (active == 3 && state.upload_documents.work_authorization.data.length > 0) ||
                                                        (active == 4 && state.upload_documents.all_previous_i20s.data.length > 0) || (active == 5 && state.upload_documents.signed_ssn.data.length > 0) ||
                                                        (active == 6 && state.upload_documents.education_documents.data.length > 0) || (active == 7 && state.upload_documents.passport.data.length > 0) ||
                                                        (active == 8 && state.upload_documents.i94.data.length > 0) || (active == 9 && state.upload_documents.drivers_license.data.length > 0)
                                                        ? '12px' : '0px', minHeight: '59px', display: 'flex', alignItems: "center",
                                            }}>
                                                <Autocomplete
                                                    clearIcon={false}
                                                    options={[]}
                                                    freeSolo
                                                    multiple
                                                    onKeyPress={(e) => { e.preventDefault() }}
                                                    value={(name == "copy_of_void_cheque" && state.upload_documents.copy_of_void_cheque.data) || (name == "counter_signed_offer_letter" && state.upload_documents.counter_signed_offer_letter.data) ||
                                                        (name == "work_authorization" && state.upload_documents.work_authorization.data) || (name == "all_previous_i20s" && state.upload_documents.all_previous_i20s.data) ||
                                                        (name == "signed_ssn" && state.upload_documents.signed_ssn.data) || (name == "education_documents" && state.upload_documents.education_documents.data) ||
                                                        (name == "passport" && state.upload_documents.passport.data) || (name == "i94" && state.upload_documents.i94.data) || (name == "drivers_license" && state.upload_documents.drivers_license.data)}
                                                    renderTags={(value, props) =>
                                                        value.map((option, index) => (
                                                            <Chip label={option.document_name} {...props({ index })}
                                                                onClick={() => window.open(option.document_url)}
                                                                onDelete={() => { deleteChip(index) }}
                                                                className={classes.chipLabel}
                                                            />
                                                        ))
                                                    }
                                                    renderInput={(params) => <TextField label="Uploaded Documents" {...params} />}
                                                    sx={{
                                                        width: '100%',
                                                        "& .MuiInputBase-root": {
                                                            cursor: "pointer",
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            cursor: "pointer",
                                                        },
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            border: "none"
                                                        },
                                                        "& .MuiAutocomplete-endAdornment": {
                                                            display: "none"
                                                        },
                                                        "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                                                            transform: "translate(10px, 16px) scale(1);"
                                                        },
                                                        "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                                                            color: "#737373",
                                                            fontSize: "14px",
                                                            fontFamily: "Quicksand !important",
                                                            fontWeight: 400,
                                                        },
                                                        "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                                                            color: "#737373",
                                                            fontSize: "15px",
                                                            fontFamily: "Quicksand !important",
                                                            fontWeight: 500,
                                                        },
                                                        "&.Mui-focused .MuiInputLabel-outlined": {
                                                            color: "#737373",
                                                            fontSize: "10px",
                                                            fontFamily: "Quicksand !important",
                                                            fontWeight: 400,
                                                            transform: (active == 1 && state.upload_documents.copy_of_void_cheque.data.length == 0) ||
                                                                (active == 2 && state.upload_documents.counter_signed_offer_letter.data.length == 0) ||
                                                                (active == 3 && state.upload_documents.work_authorization.data.length == 0) ||
                                                                (active == 4 && state.upload_documents.all_previous_i20s.data.length == 0) || (active == 5 && state.upload_documents.signed_ssn.data.length == 0) ||
                                                                (active == 6 && state.upload_documents.education_documents.data.length == 0) || (active == 7 && state.upload_documents.passport.data.length == 0) ||
                                                                (active == 8 && state.upload_documents.i94.data.length == 0) || (active == 9 && state.upload_documents.drivers_license.data.length == 0) ? "translate(12px, 0px) scale(1);" : "translate(12px, -8px) scale(1);"
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </Box>
                                    </Grid>
                                    <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2}>
                                        <Grid item lg={7} md={5} sm={5} xs={12} textAlign='start'>
                                            {
                                                active == 1 ? '' :
                                                    <Button blackCancel onClick={handleBack}>Back</Button>
                                            }
                                        </Grid>
                                        <Grid item lg={5} md={7} sm={7} xs={12} textAlign='end'>
                                            <Box display='flex' flexDirection='row' gap={1} justifyContent='end'>
                                                <Button borderlessSkip onClick={handleSkip}>Skip</Button>
                                                <Button saveNcontinueSmall onClick={handleSubmit}>Save & Continue</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </> :
                            mainStep == 1 ?
                                <I9andW4Documents mainStep={mainStep} setMainStep={setMainStep} state={state} setState={setState} getInviteViaLink={getInviteViaLink} id={id} /> :
                                mainStep == 2 ?
                                    <EmergencyContactInformation mainStep={mainStep} setMainStep={setMainStep} state={state} setState={setState} getInviteViaLink={getInviteViaLink} id={id} /> : ''
                    }
                </Grid>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBacKLoader}
            >
                <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
            </Backdrop>
        </Grid >
    )
}

export default OnboardEmployeeUpload