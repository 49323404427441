import React, { useEffect } from 'react'
import loader from "../../../assets/gif/japfuLoader.gif"
import { Grid } from '@mui/material'



function SelectedAppConfigureNavigate() {
    useEffect(() => {
        let initialURL = window.location.href
        let divideURL2 = initialURL.split('/app-integrations/selected-app/configure?app=japfu&')[1]
        if (divideURL2 != undefined) {
            const url = new URL(window.location.href);
            const searchParams3 = new URLSearchParams(url.search);
            const newDomain = searchParams3.get('state');
            const generateHost = [`${newDomain}/app-integrations/selected-app/configure?`]
            const combineURLS = generateHost.concat(divideURL2)
            let finalURL = `${combineURLS[0]}${combineURLS[1]}`
            if (initialURL != finalURL) {
                window.location.replace(finalURL)
            }
        }
        // decodeURIComponent('')
    }, [])
    return (
        <Grid container justifyContent={'center'} alignContent={'center'} height={'100vh'}>
            <img src={loader} alt='loader' width={'100px'} height={'100px'} />
        </Grid>
    )

}

export default SelectedAppConfigureNavigate