import { Accordion, AccordionSummary, AccordionDetails, Box, Divider, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import Text from '../../../../../components/customText/Text'
import { formatNumberWithCommas, getCurrencySymbol, NoDataFound, openDocumentInNewtab } from '../../../../../utils/utils'
import Button from '../../../../../components/customButton/Button'
import GeneratePayrollStyles from '../GeneratePayrollStyles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import checkcircle from '../../../../../assets/svg/checkcircle.svg';
// import trendup from '../../../../../assets/svg/trendup.svg';
// import clock from '../../../../../assets/svg/clock.svg';


import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#404040",
    padding: "6px 14px",
    minWidth: 100,
    border: "1px solid #404040"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#404040",
    "&::before": {
      backgroundColor: "#404040",
      border: "1px solid #404040"
    }
  },
}));

function Deductions({summary,currentSalaryRow,reimbursementDeductionProp,closePopUp,setUpdateStandardPayPopUp}) {

  

  const classes = GeneratePayrollStyles();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const openInNewTab = (args,documentUrl) => {
    if (args?.aws_s3_status == 0 || args?.aws_s3_status == false) {
      const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
  } else if (args?.aws_s3_status == undefined || args?.aws_s3_status == undefined) {
      const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
  }
  else {
      openDocumentInNewtab(args?.document_key, args?.document_path)
  }
  };

  return (
    <div>
      <Box px={3}>
        <Grid container columnSpacing={4}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box sx={{ borderRadius: "12px", background: "#E7F2FD", padding: "15px" }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text blackHeader600 >Total Deductions</Text>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent={"space-between"}>
                  <Box display={"flex"} justifyContent={"start"}>
                    {/* <Text veryLargeBlack sx={{display:'flex',justifyContent:'center',alignItems:'center'}}><img src={trendup}  alt='trendup'/>{currentSalaryRow?.deduction_counts?.find((item) => item.status === "Total")?.record_count}</Text> */}
                  </Box>
                  <Text veryLargeBlack>{getCurrencySymbol()} {reimbursementDeductionProp?.deduction_cards?.total_deduction}</Text>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box sx={{ borderRadius: "12px", background: "#EAF9EB", padding: "15px" }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text blackHeader600 >Total Deductions Recovered</Text>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent={"space-between"}>
                  <Box display={"flex"} justifyContent={"start"}>
                    {/* <Text veryLargeBlack sx={{display:'flex',justifyContent:'center',alignItems:'center'}}><img src={checkcircle}  alt='checkcircle'/>{currentSalaryRow?.deduction_counts?.find((item) => item.status === "Recovered")?.record_count}</Text> */}
                  </Box>
                  <Text veryLargeBlack>{getCurrencySymbol()} {reimbursementDeductionProp?.deduction_cards?.total_deduction_recovered}</Text>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box sx={{ borderRadius: "12px", background: "#F3F0FF", padding: "15px" }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text blackHeader600 >Total Deductions Due</Text>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent={"space-between"}>
                  <Box display={"flex"} justifyContent={"start"}>
                    {/* <Text veryLargeBlack sx={{display:'flex',justifyContent:'center',alignItems:'center'}}><img src={clock}  alt='clock'/>{currentSalaryRow?.deduction_counts?.find((item) => item.status === "Due")?.record_count}</Text> */}
                  </Box>
                  <Text veryLargeBlack>{getCurrencySymbol()} {reimbursementDeductionProp?.deduction_cards?.total_deduction_due}</Text>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box my={2} >
          <Box sx={{ display: "flex", background: "#FBFBFB", padding: "12px 10px", borderRadius: "8px" }}>
            <Grid container>
              <Grid item lg={3} md={3} sm={3} xs={12}><Text black400 noWrap>Name</Text></Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}><Text black400 noWrap>Deduction Type</Text></Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}><Text black400 noWrap>Deduction From </Text></Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}><Text black400 noWrap>Pending Amount</Text></Grid>
            </Grid>
          </Box>
          <Box sx={{ height: "43vh", overflowY: "auto" }}>
            { reimbursementDeductionProp?.deductions?.length > 0 ?
              reimbursementDeductionProp?.deductions?.map((data, key) => (
                <Accordion key={key} className={classes.customAccordion}
                  expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}
                >
                  <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header" className={classes.customSummary}>
                    <Grid container alignItems='center' justifyContent={"space-between"}>
                      <Grid container>
                        <Grid item lg={3} md={3} sm={3} xs={12}><Text black400 noWrap>{data?.name}</Text></Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12}><Text black400 noWrap>{data?.type}</Text></Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12}><Text black400 noWrap>{data?.added_to}</Text></Grid>
                        <Grid item lg={2} md={2} sm={2} xs={8}><Text black18px noWrap>{getCurrencySymbol()} {formatNumberWithCommas(data?.pending_amount)}</Text></Grid>
                        <Grid item lg={1} md={1} sm={1} xs={4}><ExpandMoreIcon /></Grid>
                      </Grid>

                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className={classes.AccordionDetails} height={"100%"} py={1}>
                      <Box className={classes.AccordionDetailsContent}>
                        <Grid container>
                          <Grid item md={4} sm={4} xs={6} className={classes.contentLeftAlign}>
                            <Text grey16>Name</Text>
                            <Text blackHeader600 pt="4px">{data?.name}</Text>
                          </Grid>
                          <Grid item md={4} sm={4} xs={6} className={classes.contentLeftAlign}>
                            <Text grey16>Deduction Type</Text>
                            <Text blackHeader600 pt="4px" noWrap>{data?.type}</Text>
                          </Grid>
                          <Grid item md={4} sm={4} xs={6} className={classes.contentLeftAlign}>
                            <Text grey16>Date of Expense</Text>
                            <Text blackHeader600 pt="4px" noWrap>{data?.date_of_expense}</Text>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider />
                      <Box className={classes.AccordionDetailsContent}>
                        <Grid container>
                          <Grid item md={4} sm={4} xs={6} className={classes.contentLeftAlign}>
                            <Text grey16>Due</Text>
                            <Text blackHeader600 pt="4px">{getCurrencySymbol()} {data?.raised_amount}</Text>
                          </Grid>
                          <Grid item md={4} sm={4} xs={6} className={classes.contentLeftAlign}>
                            <Text grey16>Recovered Amount</Text>
                            <Text blackHeader600 pt="4px">{getCurrencySymbol()} {data?.recovered_amount}</Text>
                          </Grid>
                          <Grid item md={4} sm={4} xs={6} className={classes.contentLeftAlign}>
                            <Text grey16>Pending Amount</Text>
                            <Text blackHeader600 pt="4px">{getCurrencySymbol()} {data?.pending_amount}</Text>
                          </Grid>
                          <Grid item md={4} sm={4} xs={6} pt={2} className={classes.contentLeftAlign}>
                            <Text grey16>Deduction From</Text>
                            <Text blackHeader600 pt="4px" noWrap>{data?.added_to}</Text>
                          </Grid>
                          <Grid item md={4} sm={4} xs={6} pt={2} className={classes.contentLeftAlign}>
                            <Text grey16>Completed Cycles
                              <HtmlTooltip
                                placement="right"
                                arrow
                                title={
                                  <React.Fragment>
                                    <Box>
                                      <Typography className={classes.profileTooltipText}>
                                        This Employee has {Number(data?.recurring_count) - Number(data?.completed_recurring_count)} Cycles Pending
                                      </Typography>
                                    </Box>
                                  </React.Fragment>
                                }
                              >
                                <InfoOutlinedIcon style={{ marginLeft: "8px", marginBottom: "-6px", cursor: "pointer" }} />
                              </HtmlTooltip>

                            </Text>
                            <Text blackHeader600 pt="4px" noWrap>{data?.completed_recurring_count?data?.completed_recurring_count:'-'}/{data?.recurring_count?data?.recurring_count:'-'}</Text>
                          </Grid>
                          <Grid item md={4} sm={4} xs={6} pt={2} className={classes.contentLeftAlign}>
                            <Text grey16>Supporting Document</Text>
                            <Text sx={{ cursor: "pointer !important" }} largeBlue pt="4px" noWrap onClick={() => { openInNewTab(data,data?.expense_documents[0]?.document_url) }}>{data?.expense_documents?.length > 0 ? 'View Attachment' : '--'}</Text>
                          </Grid>

                        </Grid>
                      </Box>
                    </Box>
                  </AccordionDetails>

                </Accordion>
              )) : <Box>{NoDataFound()}</Box>}
          </Box>

        </Box>
      </Box>

      <Box sx={{ background: "#EAECF0" }}>
        <Grid container item lg={12} md={12} sm={12} xs={12} alignItems={"center"} p={1} px={3} gap={2} justifyContent={"end"}>
          <Button cancelBtn sx={{ width: "50px !important", height: '40px !important' }} onClick={closePopUp}>Cancel</Button>
          {!summary ? <Button save14 sx={{ width: "50px !important" }} onClick={setUpdateStandardPayPopUp}>Finalize</Button>: <Button save14 sx={{ width: "50px !important" }} onClick={closePopUp}>Done</Button>}

        </Grid>
      </Box>
    </div>
  )
}

export default Deductions
