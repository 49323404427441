import LocalStorage from "../utils/LocalStorage"; // eslint-disable-next-line
import { dev_url, qa_url, uat_url } from "./development";

export const domain = (LocalStorage.getURLNAME() == "localhost" || LocalStorage.getURLNAME().includes('192')) ? "codetru" : LocalStorage.getURLNAME();
export const urlEnroll = process.env.REACT_APP_DOMAIN_URL
// export const urlEnroll = qa_url
// export const urlEnroll = uat_url

// indicates app version and date (DDMMYYYY)
export const app_version = '0.1'


// workforce.codetru.org