import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Box, Avatar, Stack, Grid, Breadcrumbs, Hidden, CircularProgress, ListItemButton, Divider, Menu, Backdrop, Badge, } from '@mui/material';
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Component87 from '../../assets/svg/Component87.svg';
import Pending from '../../assets/employee/Orange-PendingIcon.svg';
import Verify from '../../assets/svg/verify_icon.svg';
import CustomCircularProgress from '../../components/progressbar/CircularProgress';
import Search from '../../assets/svg/search1.svg';
import NewDownloadcloud from '../../assets/svg/download-cloud-new.svg';
import recruiter from '../../assets/svg/recruiter.svg';
import { capitalizeAndAddSpace, NoDataFound, NoPermission } from '../../utils/utils';
import Text from '../../components/customText/Text';
import Button from '../../components/customButton/Button';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LocalStorage from '../../utils/LocalStorage';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import infoCircle from '../../assets/images/info-circle.png';
import ClearIcon from '@mui/icons-material/Clear';
import Loader from '../../assets/gif/japfuLoader.gif';
import FilterListIcon from '@mui/icons-material/FilterList';
import PlacementDashboardStyles from '../../views/admin/placements/newDashboard/PlacementDashboardStyles';
import PlacementViewStyles from '../../views/admin/placements/viewAndEditPlacement/PlacementViewStyles';
import recruitersActive from '../../assets/svg/recruitersActive.svg';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { blue } from '../../theme';
import noDataFound from '../../assets/svg/NoDataFoundIcon.svg'
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import LeftNavigateArrow from '../../assets/svg/LeftNavigateArrow.svg'
import TourGuideConfigApi from '../../apis/configurations/tourguideConfig/TourGuideConfigApi';
import { useNavigate } from 'react-router-dom';
import { employee_URL } from '../../config/development';

const dataArr = ["Client Details", "Placement Details", "Pay Rate Configuration", "Timesheet Configuration"];

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));



export default function Dashboard({ setStartTour }) {


    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = PlacementDashboardStyles();
    const classesPlacementProfile = PlacementViewStyles();
    const [showPlacement, setShowPlacement] = useState('allplacements');
    const [anchorEl, setAnchorEl] = useState(null);// eslint-disable-next-line
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if (anchorEl) {
            handleClose();
        }
    };
    const placements = [
        {
            id: "4730caf3-409b-4209-951c-9091772f133f",
            employee_id: "e78b84a9-9243-45fc-a5aa-8a4bc1f13eae",
            client_id: "5f6a3409-b195-4a7e-8922-4782ec04aee8",
            employee_name: "ABC DEF",
            employee_reference_id: "CON-142",
            employee_e_verified: 2,
            placement_reference_id: "PLS-160",
            client_name: "Client ABC",
            start_date: "10/29/2024",
            end_date: "",
            status: "Active",
            timesheet_cycle: "Weekly",
            pay_type: 2,
            pay_rate: 0,
            bill_rate: 502,
            recruiter_name: "",
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            profile_progress: 88.89,
            client_logo_url: "",
            client_profile_progress: 100
        },
        {
            id: "b46a0810-0e10-47bd-b5cc-761b86a35979",
            employee_id: "120850bc-6a4b-4891-bae7-29b739558f17",
            client_id: "41ca9839-65aa-4d63-b7e7-ee9b1c56b7a4",
            employee_name: "EFG HIJ",
            employee_reference_id: "CON-140",
            employee_e_verified: 0,
            placement_reference_id: "PLS-159",
            client_name: "Client EFG",
            start_date: "10/28/2024",
            end_date: "",
            status: "Active",
            timesheet_cycle: "Monthly",
            pay_type: 2,
            pay_rate: 0,
            bill_rate: 100,
            recruiter_name: "",
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            profile_progress: 55.56,
            client_logo_url: "",
            client_profile_progress: 100
        },
        {
            id: "f8df2fe6-2102-411b-8b80-b8dc351bdec3",
            employee_id: "12e91aa4-69e4-48c2-b0b7-bb8f8f234bec",
            client_id: "da1da88f-3ef9-4a19-ac21-20ee669487d7",
            employee_name: "JKL MNO",
            employee_reference_id: "CON-2",
            employee_e_verified: 4,
            placement_reference_id: "PLS-158",
            client_name: "Client HIJ",
            start_date: "10/01/2024",
            end_date: "10/18/2024",
            status: "In Active",
            timesheet_cycle: "Weekly",
            pay_type: 2,
            pay_rate: 0,
            bill_rate: 100,
            recruiter_name: "",
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            profile_progress: 100,
            client_logo_url: "",
            client_profile_progress: 100
        },
        {
            id: "96ac3718-f6a1-48f1-b13f-34c32b9903e5",
            employee_id: "491a20cc-39d4-4f8a-aa7b-33505d7dafd6",
            client_id: "e8f25e22-ab4d-40d0-8eee-177a1b2d8a6b",
            employee_name: "PQR STU",
            employee_reference_id: "CON-127",
            employee_e_verified: 2,
            placement_reference_id: "PLS-157",
            client_name: "Client LMN",
            start_date: "10/15/2024",
            end_date: "",
            status: "Active",
            timesheet_cycle: "Bi Weekly",
            pay_type: 2,
            pay_rate: 0,
            bill_rate: 500,
            recruiter_name: "",
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            profile_progress: 77.78,
            client_logo_url: "",
            client_profile_progress: 100
        },
        {
            id: "7ba83891-7df2-4e6a-979b-db4c75762ee5",
            employee_id: "491a20cc-39d4-4f8a-aa7b-33505d7dafd6",
            client_id: "93add272-5c72-427d-be36-a147a5e1130e",
            employee_name: "VWX YZ",
            employee_reference_id: "CON-127",
            employee_e_verified: 2,
            placement_reference_id: "PLS-156",
            client_name: "Client OPQ",
            start_date: "10/14/2024",
            end_date: "",
            status: "Active",
            timesheet_cycle: "Bi Weekly",
            pay_type: 2,
            pay_rate: 0,
            bill_rate: 4325,
            recruiter_name: "test hy",
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            profile_progress: 77.78,
            client_logo_url: "",
            client_profile_progress: 100
        },
    ]

    const EmployeeProfile = `${employee_URL + 'documents/avatar/male/25.png'}`;
    const employee = {
        id: "e78b84a9-9243-45fc-a5aa-8a4bc1f13eae",
        status: "Active",
        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
        gender: "Male",
        last_working_day: null,
        company_id: null,
        vendor_name: null,
        company_name: null,
        profile_progress: "88.89",
        e_verified: 1,
        project_status: "Placed",
        label_name: "Active - Placed",
        basic_details: {
            first_name: "Abc",
            middle_name: "",
            last_name: "Def",
            full_name: "Abc Def",
            dob: "10/29/2006",
            gender: "Male",
            blood_group: null,
            marital_status_id: null,
            vendor_id: "vendor_price"
        },
        contact_details: {
            contact_number: "967-969-6754",
            alternate_contact_number: "",
            email_id: "sai@assurmail.net",
            alternate_email_id: ""
        },
        emergency_contacts: [
            {
                id: 414,
                city: "Secunderabad",
                name: "qwert",
                email_id: null,
                state_id: 72,
                zip_code: "500007",
                address_1: "werthj",
                address_2: null,
                country_id: 1,
                state_name: "Telangana",
                country_name: "India",
                contact_number: "768-746-3245",
                relationship_id: 1,
                relationship_name: "Father"
            },
            {
                id: 415,
                city: "Serilingampally",
                name: "qwerty",
                email_id: null,
                state_id: 72,
                zip_code: "500046",
                address_1: "qwerty",
                address_2: null,
                country_id: 1,
                state_name: "Telangana",
                country_name: "India",
                contact_number: "648-987-9878",
                relationship_id: 1,
                relationship_name: "Father"
            }
        ],
        current_address: {
            address_line_one: "werty",
            address_line_two: null,
            city: "Berwick",
            state_id: 62,
            state_name: "Iowa",
            country_id: 2,
            country_name: "United States",
            zip_code: "50032"
        },
        employment_details: {
            reference_id: "CON-142",
            employment_type: "Consultant",
            employment_type_id: 2,
            date_of_joining: "10/29/2024",
            rejoin_date: null,
            employee_category: "1099",
            employment_category_id: 2,
            is_usc: 1,
            ssn: "798-98-7987",
            visa_type: "CPT",
            visa_type_id: 2,
            role_id: null,
            role_name: null,
            enable_login: 0
        },
        confirm_rehire: null,
        enable_payroll: false,
        complete_profile: "education_documents",
        documents_pending: true,
        bank_pending: false,
        skills_pending: false,
        is_off_boarding_initiated: false,
        date_of_joining: "10/29/2024",
        off_boarding_percentage: 0,
        is_configurable_timesheet_available: false,
        min_last_working_day: "10/30/2024",
        first_name: "Abc",
        middle_name: "",
        last_name: "Def",
        full_name: "ABC DEF",
        dob: "10/29/2006",
        blood_group: null,
        marital_status_id: null,
        vendor_id: "vendor_price",
        contact_number: "967-969-6754",
        alternate_contact_number: "",
        email_id: "sai@assurmail.net",
        alternate_email_id: "",
        address_line_one: "werty",
        address_line_two: null,
        city: "Berwick",
        state_id: 62,
        state_name: "Iowa",
        country_id: 2,
        country_name: "United States",
        zip_code: "50032",
        reference_id: "CON-142",
        employment_type: "Consultant",
        employment_type_id: 2,
        rejoin_date: null,
        employee_category: "1099",
        employment_category_id: 2,
        is_usc: 1,
        ssn: "798-98-7987",
        visa_type: "CPT",
        visa_type_id: 2,
        role_id: null,
        role_name: null,
        enable_login: 0,
        enable_rehire: true,
        visa_information_exists: true,
        pending_requests: {
            general_details: false,
            education_documents: false,
            passport: false,
            i94: false,
            visa_documents: false,
            personal_documents: false,
            ssn_documents: false,
            other_documents: false,
            bank_details: false
        }
    }
    const [current, setCurrent] = useState("Client Details");
    const handleTabChange = (item) => {
        setCurrent(item);
    }
    const statusPlacement = "Active"; // eslint-disable-next-line
    const filterData = {
        limit: 10,
        page: 1,
        search: "",
        status: [],
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        clients: [],
        recruiter: [],
        timesheet_cycle: [],
        sort_column: "created_at",
        sort_order: "desc"
    };

    const steps = [{
        element: '#addplacement', popover: {
            title: 'Add Placement', description:
                'Easily create and manage new placements. Provide necessary details and configurations to ensure accurate tracking and management of placements.', side: "left",
            align: 'start'
        }
    }, {
        element: '#analytics', popover: {
            title: 'Analytics', description:
                'Access detailed analytics related to placements. Review performance metrics, placement trends, and other key insights to optimize your placement processes.', side: "left",
            align: 'start'
        }
    }, {
        element: '#viewplacement', popover: {
            title: 'View Placement', description:
                'Quickly view and manage all placements. Access detailed information and make updates as needed to maintain accurate records.', side: "left",
            align: 'start'
        }
    }, {
        element: '#ClientDetails', popover: {
            title: 'Client Details', description:
                'Access client-specific information related to the placement. Review client contacts, requirements, and any other relevant details to maintain effective client relationships.', side: "right",
            align: 'start'
        }
    }, {
        element: '#PlacementDetails', popover: {
            title: 'Placement Details', description:
                'Review comprehensive details about each placement, including job title, candidate information, and assignment specifics. Ensure all relevant data is accurate and up-to-date.', side: "right",
            align: 'start'
        }
    }, {
        element: '#PayRateConfiguration', popover: {
            title: 'Pay Config', description:
                'Manage and configure payment details associated with the placement. Set payment terms, rates, and other financial aspects to ensure smooth processing.', side: "right",
            align: 'end'
        }
    }, {
        element: '#TimesheetConfiguration', popover: {
            title: 'Timesheet Config', description:
                'Configure and manage timesheet settings for the placement. Ensure that time tracking aligns with the placement requirements and client agreements.', side: "right",
            align: 'end'
        }
    },];


    if(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_edit" && item.is_allowed == true))){
        steps.push(...[ {
            element: '#Endplacement', popover: {
                title: 'End Placement', description:
                    'Click End Placement to finalize and close this assignment. ', side: "right",
                align: 'start'
            }
        },])
    }

    useEffect(() => {
        const driverObj = driver({
            popoverClass: 'driverjs-theme',
            allowClose: false,
            stagePadding: 0,
            stageRadius: 0,
            animate: false,
            allowKeyboardControl: false,
            disableActiveInteraction: true,
            steps: steps,
            popoverOffset: 15,
            onPopoverRender: (popover, opts) => {//creates the popover with required design 
                const currentStep = opts.state.activeIndex;
                const totalSteps = opts.config.steps.length;
                const updateTourGuideStatus = async (tourModule) => {
                    await TourGuideConfigApi.updateApi({
                        "request_id": LocalStorage.uid(),
                        "module": tourModule,
                        "status": true
                    });
                }
                const title = document.createElement('div');
                title.style.display = 'flex';
                title.style.alignItems = 'center';
                title.style.marginBottom = '8px';

                const icon = document.createElement('img');
                icon.src = LeftNavigateArrow;
                icon.alt = 'Back Arrow';
                icon.style.width = '16px';
                icon.style.height = '16px';
                icon.style.marginRight = '2px';
                icon.style.cursor = 'pointer';
                if (currentStep !== 0) {
                    icon.onclick = (event) => {
                        event.stopPropagation();
                        if (currentStep == 3) {
                            setShowPlacement('allplacements');
                            driverObj.movePrevious();
                        } else if (currentStep == 4) {
                            setCurrent('Client Details')
                            driverObj.movePrevious();
                        } else if (currentStep == 5) {
                            setCurrent('Placement Details');
                            driverObj.movePrevious();
                        } else if (currentStep == 6) {
                            setCurrent('Pay Rate Configuration');
                            driverObj.movePrevious();
                        } else if (currentStep == 7) {
                            setCurrent('Timesheet Configuration');
                            handleClose();
                            driverObj.movePrevious();
                        }
                        else {
                            driverObj.movePrevious();
                        }
                    };
                    title.appendChild(icon);
                }
                const textContainer = document.createElement('div');
                textContainer.style.display = 'flex';
                textContainer.style.justifyContent = 'space-between';
                textContainer.style.width = '100%';
                const titleText = document.createElement('p');
                titleText.textContent = opts.config.steps[currentStep].popover.title;
                titleText.style.fontFamily = 'Quicksand';
                titleText.style.fontSize = '15px';
                titleText.style.fontWeight = '700';
                const stepSpan = document.createElement('span');
                stepSpan.textContent = `(${currentStep + 1}/${totalSteps})`;
                stepSpan.style.display = 'flex';
                stepSpan.style.alignItems = 'center';
                stepSpan.style.fontSize = '12px';
                stepSpan.style.fontWeight = '600';
                stepSpan.style.color = '#525252';
                stepSpan.style.fontFamily = 'Quicksand';
                textContainer.appendChild(titleText);
                textContainer.appendChild(stepSpan);
                title.appendChild(textContainer);

                const description = document.createElement('div');
                description.innerText = opts.config.steps[currentStep].popover.description;
                description.style.marginBottom = '24px';
                description.style.fontSize = '14px';
                description.style.fontWeight = '400';
                description.style.color = '#404040';
                description.style.fontFamily = 'Quicksand';

                const footer = document.createElement('div');
                footer.style.display = 'flex';
                footer.style.justifyContent = 'space-between';
                footer.style.width = '100%';
                footer.style.boxSizing = 'border-box';
                const applyInviteLinkBtnStyles = (element, text) => {
                    element.style.cursor = 'pointer';
                    element.style.textAlign = 'center';
                    element.style.fontSize = '12px';
                    element.style.fontFamily = 'Quicksand';
                    element.style.fontWeight = '600';
                    element.style.width = (text == 'finish') ? '100%' : '130px';
                    element.style.height = '32px';
                    element.style.border = '1.5px solid rgba(12, 117, 235, 1)';
                    element.style.borderRadius = '4px';
                    element.style.color = (text == 'skip') ? '#0C75EB' : '#ffffff';
                    element.style.transition = 'all .3s ease';
                    element.style.outline = 'none';
                    element.style.backgroundColor = (text == 'skip') ? '#ffffff' : '#0C75EB';

                };
                const skipButton = document.createElement('button');
                skipButton.innerText = 'Skip';
                applyInviteLinkBtnStyles(skipButton, 'skip');

                skipButton.onclick = async () => {
                    LocalStorage.setStartTour({ 'placements': false })
                    updateTourGuideStatus('PLACEMENTS');
                    setStartTour();
                    driverObj.destroy();
                    if (LocalStorage.getUserData().org_config_set == false) {
                        navigate('/configuration');
                    }
                };
                const finishButton = document.createElement('button');
                finishButton.innerText = 'Finish';
                applyInviteLinkBtnStyles(finishButton, 'finish');
                finishButton.onclick = () => {
                    LocalStorage.setStartTour({ 'placements': false })
                    updateTourGuideStatus('PLACEMENTS');
                    setStartTour();
                    driverObj.destroy();
                    if (LocalStorage.getUserData().org_config_set == false) {
                        navigate('/configuration');
                    }
                };
                const nextButton = document.createElement('button');
                nextButton.innerText = 'Next';
                applyInviteLinkBtnStyles(nextButton, 'next');
                nextButton.onclick = () => {
                    if (currentStep == 2) {
                        setShowPlacement('placementprofile');
                        setCurrent('Client Details');
                        setTimeout(() => { driverObj.moveNext(); }, 300);
                    } else if (currentStep == 3) {
                        setCurrent('Placement Details');
                        driverObj.moveNext();
                    } else if (currentStep == 4) {
                        setCurrent('Pay Rate Configuration');
                        driverObj.moveNext();
                    } else if (currentStep == 5) {
                        setCurrent('Timesheet Configuration');
                        driverObj.moveNext();
                    } else if (currentStep == 6) {
                        setCurrent('Client Details');
                        const threeDotsMenu = document.getElementById('threedots');
                        threeDotsMenu.click();
                        setTimeout(() => { driverObj.moveNext(); }, 300);
                    }
                    else {
                        driverObj.moveNext();
                    }
                };
                if (currentStep === totalSteps - 1) {
                    footer.appendChild(finishButton);
                } else {
                    footer.appendChild(skipButton);
                    footer.appendChild(nextButton);
                }
                popover.wrapper.innerHTML = '';
                const arrow = popover.arrow;
                if (arrow) {
                    popover.wrapper.appendChild(arrow);
                }
                popover.wrapper.appendChild(title);
                popover.wrapper.appendChild(description);
                popover.wrapper.appendChild(footer);

            }
        });
        setTimeout(() => {
            driverObj.drive();
        }, 500);

        return () => {
            driverObj.destroy();
        };
        // eslint-disable-next-line
    }, []);

    /**
     * Handles the filtering when a recruiter placement count is clicked. 
     * Updates the filter based on the recruiter selected.
     * @param {string} param - The ID of the recruiter to filter by.
     */

    return (
        <>
            {showPlacement == "allplacements" && <Box className={classes.flexBox} style={{ paddingLeft: '65px' }}>
                <Box sx={{ width: "90%" }} >
                    <Box style={{ padding: "20px 10px 10px 10px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <Text mediumViewAmt>All Placements</Text>
                            </div>
                            {
                                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true))) ?
                                    <div>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingRight: "5px" }}>
                                            <Box sx={{
                                                height: "44px", border: "1.5px solid #E2E5E6", width: "368px", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center",
                                                "@media (min-width: 900px) and (max-width: 1400px)": {
                                                    width: "258px"
                                                },
                                            }}>
                                                <input
                                                    type="text"
                                                    value={filterData.search}
                                                    className={classes.EmployeesSearchInput}
                                                    placeholder="Search by Name / ID"
                                                />
                                                <Box sx={{ paddingRight: "15px !important" }}>
                                                    {
                                                        filterData.search !== "" ?
                                                            <ClearIcon sx={{ cursor: "pointer" }} />
                                                            :
                                                            <img src={Search} alt="Search" />
                                                    }
                                                </Box>
                                            </Box>
                                            <button title="Export" type="button" className={classes.borderButton}

                                            >
                                                <img src={NewDownloadcloud} alt="Userplus" />
                                            </button>
                                            <button title="Filter"
                                                type="button"
                                                className={classes.borderButton}
                                            >
                                                <Badge badgeContent={filterData.recruiter.length + filterData.timesheet_cycle.length + filterData.clients.length + ((filterData.start_date_from !== "" && filterData.start_date_to !== "") && 1) + ((filterData.end_date_from !== "" && filterData.end_date_to !== "") && 1)} color="error">
                                                    <FilterListIcon sx={{ color: (filterData.timesheet_cycle.length > 0 || filterData.recruiter.length > 0 || filterData.clients.length > 0 || (filterData.start_date_from !== "" && filterData.start_date_to !== "") || (filterData.end_date_from !== "" && filterData.end_date_to !== "")) ? "#2BEA2B" : "" }} />
                                                </Badge>
                                            </button>
                                            <HtmlTooltip
                                                placement="bottom"
                                                arrow
                                                title={
                                                    <React.Fragment>
                                                        <Box textAlign={"center"}>
                                                            <Typography className={classes.profileTooltipText}>
                                                                Recruiters
                                                            </Typography>
                                                        </Box>
                                                    </React.Fragment>
                                                }
                                            >
                                                <button
                                                    type="button"
                                                    className={classes.borderButton}

                                                >
                                                    {
                                                        filterData.recruiter.length > 0 ?
                                                            <Badge className={classes.badge} badgeContent={filterData.recruiter.length} color="error">
                                                                <img src={recruitersActive} alt="recruitersActive" />
                                                            </Badge>
                                                            :
                                                            <img src={recruiter} alt="recruiter" />
                                                    }
                                                </button>
                                            </HtmlTooltip>
                                            <Box id="analytics" sx={{ padding: '5px' }}>
                                                <Button analyticsView >View Analytics</Button>

                                            </Box>
                                            {/* {
                                                    (LocalStorage.getUserData().super_admin || (rolePermission != "" && rolePermission.some(item => item.slug == "placement_create" && item.is_allowed == true))) ? */}
                                            <Box id="addplacement" sx={{ padding: '5px', marginLeft: '-10px' }}>
                                                <Button sx={{ padding: "0px 15px !important" }} addButton id="" startIcon={<AddIcon />}>
                                                    Add Placement
                                                </Button>
                                            </Box>
                                            {/* :
                                                        ""
                                                } */}
                                        </div>
                                    </div>
                                    : ""
                            }
                        </div>

                    </Box>
                    {
                        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true))) ?
                            <Box style={{ padding: "10px" }}>

                                <Box sx={{
                                    display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px",
                                    "@media (min-width: 200px) and (max-width: 1400px)": {
                                        font: "12px Quicksand",
                                        fontWeight: "600",
                                        paddingTop: '1px'
                                    },
                                }} >
                                    <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                        <Grid item lg={4.5} md={4} sm={4} xs={4.5} textAlign={'left'} letterSpacing={'0.5px'}>Employee Name
                                            <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} >
                                                <FilterListIcon sx={{ height: "19px", color: (filterData.sort_column == "name" || filterData.sort_column == "created_at") ? "#2BEA2B" : "" }} />
                                            </IconButton>
                                        </Grid>
                                        <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerNames}>Client Name</Grid>
                                        <Grid item lg={1} md={1.5} sm={1} xs={1} className={classes.headerNames} >Start Date</Grid>
                                        <Grid container item lg={2} md={2.1} sm={2} xs={2}>
                                            <Grid item lg={6} md={6} sm={6} xs={6} className={classes.headerEndDate} >End Date</Grid>
                                            <Grid aria-controls="status-menu" item lg={6} md={6} sm={6} xs={6} className={classes.headerStatus}  >Status
                                                <KeyboardArrowDownIcon sx={{ color: filterData.status.length > 0 ? "#2BEA2B !important" : "" }} />
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5} display={"flex"} justifyContent={"center"}>
                                            <Text offBoardBodyFont>
                                                Count
                                                <HtmlTooltip
                                                    placement="bottom"
                                                    arrow
                                                    title={
                                                        <React.Fragment>
                                                            <Box>
                                                                <Typography className={classes.profileTooltipText}>
                                                                    Total Placements count is <br />based on the filters applied
                                                                </Typography>
                                                            </Box>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                                </HtmlTooltip>
                                                {` -  Placements`}</Text>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <>
                                    {
                                        placements.length > 0 ?
                                            <Box sx={{
                                                height: "65.3vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
                                                paddingLeft: '5px', paddingRight: '5px',
                                                "@media (min-height: 850px)": {
                                                    height: '73.5vh'
                                                }
                                            }} >
                                                {
                                                    placements.map((place, key) => (
                                                        <Accordion
                                                            key={key}
                                                            className={classes.newCustomAccordion}
                                                        >
                                                            <AccordionSummary
                                                                aria-controls="panel1bh-content"
                                                                id="panel1bh-header"
                                                                className={classes.AccordionSummary}
                                                            >
                                                                <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                                    <Grid item lg={4.5} md={4} sm={4} xs={4.5} textAlign='start'>
                                                                        <Box display='flex' alignItems='center' gap={2}>
                                                                            <Box sx={{ position: 'relative', display: 'flex' }}>
                                                                                <CircularProgress variant="determinate" sx={{ backgroundColor: "#ffffff", color: '#ffffff', borderRadius: "100%", }} />
                                                                                <Box
                                                                                    sx={{
                                                                                        top: 0,
                                                                                        left: 0,
                                                                                        bottom: 0,
                                                                                        right: 0,
                                                                                        position: 'absolute',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                    }}
                                                                                >
                                                                                    <Box sx={{ display: "flex", padding: "3px", borderRadius: "50%", backgroundColor: "#ffffff" }}>
                                                                                        <HtmlTooltip
                                                                                            placement="bottom"
                                                                                            arrow
                                                                                            title={
                                                                                                <React.Fragment>
                                                                                                    <Box>
                                                                                                        <Typography className={classes.profileTooltipText}>
                                                                                                            {`Profile Completion - ${place.profile_progress}%`}
                                                                                                        </Typography>
                                                                                                    </Box>
                                                                                                </React.Fragment>
                                                                                            }
                                                                                        >
                                                                                            <Avatar
                                                                                                alt={place.employee_name}
                                                                                                src={place.profile_picture_url}
                                                                                                sx={{ width: 50, height: 50, }}
                                                                                            />
                                                                                        </HtmlTooltip>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                            <Box>
                                                                                {
                                                                                    place.status == 'In Active' ?
                                                                                        <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                            {place.employee_name === "" ? "--" : capitalizeAndAddSpace(place.employee_name).length > 16 ?
                                                                                                <HtmlTooltip title={capitalizeAndAddSpace(place.employee_name)} placement="right" arrow>
                                                                                                    {capitalizeAndAddSpace(place.employee_name).slice(0, 16) + (capitalizeAndAddSpace(place.employee_name).length > 16 ? "..." : "")}
                                                                                                </HtmlTooltip>
                                                                                                :
                                                                                                capitalizeAndAddSpace(place.employee_name)
                                                                                            }
                                                                                            {place.employee_e_verified == 1 || place.employee_e_verified == 4 ?
                                                                                                <HtmlTooltip title={<Text smallWhite>E-verified</Text>} placement="right" arrow>
                                                                                                    <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                </HtmlTooltip>
                                                                                                : place.employee_e_verified == 2 ?
                                                                                                    <HtmlTooltip title={<Text smallWhite>E-verification is pending</Text>} placement="right" arrow>
                                                                                                        <img src={Pending} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                    </HtmlTooltip> : ""
                                                                                            }
                                                                                        </Text>
                                                                                        :
                                                                                        <Text mediumBoldBlack noWrap>
                                                                                            {place.employee_name === "" ? "--" :
                                                                                                capitalizeAndAddSpace(place.employee_name).length > 16 ?
                                                                                                    <HtmlTooltip title={capitalizeAndAddSpace(place.employee_name)} placement="right" arrow>
                                                                                                        {capitalizeAndAddSpace(place.employee_name).slice(0, 16) + (capitalizeAndAddSpace(place.employee_name).length > 16 ? "..." : "")}
                                                                                                    </HtmlTooltip>
                                                                                                    :
                                                                                                    capitalizeAndAddSpace(place.employee_name)
                                                                                            }
                                                                                            {place.employee_e_verified == 1 || place.employee_e_verified == 4 ?
                                                                                                <HtmlTooltip title={<Text smallWhite>E-verified</Text>} placement="right" arrow>
                                                                                                    <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                </HtmlTooltip>
                                                                                                : place.employee_e_verified == 2 ?
                                                                                                    <HtmlTooltip title={<Text smallWhite>E-verification is pending</Text>} placement="right" arrow>
                                                                                                        <img src={Pending} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                    </HtmlTooltip> : ''
                                                                                            }
                                                                                        </Text>
                                                                                }
                                                                                <Text mediumLabel noWrap style={{ margin: '4px 0' }}>  {place.employee_reference_id ? place.employee_reference_id : "--"} &#128900; {place.placement_reference_id ? place.placement_reference_id : "--"}</Text>

                                                                            </Box>
                                                                        </Box>

                                                                    </Grid>
                                                                    <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"start"}>
                                                                        <Text smallBoldBlack noWrap >
                                                                            {place.client_name === "" ? "--" : capitalizeAndAddSpace(place.client_name).length > 16 ?
                                                                                <HtmlTooltip title={capitalizeAndAddSpace(place.client_name)} placement="right" arrow>
                                                                                    {capitalizeAndAddSpace(place.client_name).slice(0, 16) + (capitalizeAndAddSpace(place.client_name).length > 16 ? "..." : "")}
                                                                                </HtmlTooltip>
                                                                                :
                                                                                capitalizeAndAddSpace(place.client_name)
                                                                            }
                                                                        </Text>
                                                                    </Grid>
                                                                    <Grid item lg={1} md={1.5} sm={1} xs={1} textAlign={"start"}>
                                                                        <Text smallBoldBlack noWrap >{place.start_date ? place.start_date : "--"}</Text>
                                                                    </Grid>
                                                                    <Grid container lg={3} md={3} sm={3} xs={3} textAlign={"start"}>
                                                                        <Stack
                                                                            direction="row"
                                                                            useFlexGap
                                                                            width='80%'
                                                                            divider={<Divider orientation="vertical" flexItem style={{ height: '50px' }} />}
                                                                            spacing={3}
                                                                            textAlign='center' alignItems='center'
                                                                        >
                                                                            <Grid item lg={6} md={6} sm={6} xs={6} textAlign={"start"}>
                                                                                <Text smallBoldBlack noWrap pr={place.end_date ? 0 : 2.4} >{place.end_date ? place.end_date : `Current`}</Text>
                                                                            </Grid>

                                                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                                                {
                                                                                    place.status == 'In Active' ?
                                                                                        <Box className={classes.inActiveBox}>
                                                                                            <Text mediumBoldWhite>Inactive</Text>
                                                                                        </Box> :
                                                                                        <Box className={classes.activeBox}>
                                                                                            <Text mediumBoldWhite>Active</Text>
                                                                                        </Box>
                                                                                }
                                                                            </Grid>
                                                                        </Stack>

                                                                    </Grid>

                                                                    <Grid item lg={1.5} md={1.5} id='viewplacement'>
                                                                        <Text mediumBlue sx={{ padding: '5px' }}>View Placement</Text>
                                                                    </Grid>
                                                                </Grid>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Box className={classes.AccordionDetails} pt={1}>
                                                                    <Box width={'100%'}>
                                                                        <Typography className={classes.text1}>
                                                                            Timesheet Cycle
                                                                        </Typography>
                                                                        <Typography my={1} className={classes.text2}>
                                                                            {place.timesheet_cycle ? place.timesheet_cycle : "--"}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box width={'100%'}>
                                                                        <Typography className={classes.text1}>
                                                                            Pay Rate ({place.pay_type == 2 ? "Salary" : "Hourly"})
                                                                        </Typography>
                                                                        <Typography my={1} className={classes.text2}>
                                                                            {place.pay_type == 2 ? "--" : place.pay_rate ? `${LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} ${place.pay_rate}` : "--"}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box width={'100%'}>
                                                                        <Typography className={classes.text1}>
                                                                            Bill Rate
                                                                        </Typography>
                                                                        <Typography my={1} className={classes.text2}>
                                                                            {place.bill_rate ? `${LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} ${place.bill_rate}` : "--"}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box width={'100%'} >
                                                                        <Typography className={classes.text1}>
                                                                            Recruiter Name
                                                                        </Typography>
                                                                        <Typography my={1} className={classes.text2}>
                                                                            {place.recruiter_name === "" ? "Not Assigned" : capitalizeAndAddSpace(place.recruiter_name).length > 16 ?
                                                                                <HtmlTooltip title={capitalizeAndAddSpace(place.recruiter_name)} placement="right" arrow>
                                                                                    {capitalizeAndAddSpace(place.recruiter_name).slice(0, 16) + (capitalizeAndAddSpace(place.recruiter_name).length > 16 ? "..." : "")}
                                                                                </HtmlTooltip>
                                                                                :
                                                                                capitalizeAndAddSpace(place.recruiter_name)
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))
                                                }
                                            </Box>
                                            :
                                            placements.length == 0 ?
                                                <Box>
                                                    {NoDataFound("To fetch the data. Please add placements", 'No Placement Yet')}
                                                </Box>
                                                : ''
                                    }
                                </>
                            </Box> :
                            <>
                                {NoPermission()}
                            </>
                    }
                </Box>
            </Box>}
            {
                showPlacement == 'placementprofile' &&
                <Box className={classesPlacementProfile.mainContainer} px={5} py={1}>
                    <Box mx={2} pl={3}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography className={classesPlacementProfile.breadcrumbsLink}>All Placements</Typography>
                            <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>Placement Details</Text>
                        </Breadcrumbs>
                    </Box>
                    <Hidden lgDown>
                        {
                            (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true))) ?
                                <Box mx={2}>
                                    <Grid container columnSpacing={4}>
                                        <Grid item container lg={3} md={3} sm={4} xs={12}>
                                            <Box style={{ width: "100%", height: "78.5vh", borderRadius: "8px" }}>
                                                <Box px={3} py={1} pt={4}>
                                                    <Box my={1} mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                                        <CustomCircularProgress
                                                            percentage={employee && employee.profile_progress}
                                                            color={'#ffffff'}
                                                            src={EmployeeProfile}
                                                            name={EmployeeProfile == undefined || EmployeeProfile == null || EmployeeProfile == '' ? employee && employee.basic_details && employee.full_name[0] : ''}
                                                            imgWidth='80px !important'
                                                            imgHeight='80px !important'
                                                            avatarWidth='80px !important'
                                                            avatarHeight='80px !important'
                                                            value={employee && employee.profile_progress}
                                                            size={88}

                                                        />
                                                    </Box>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        {
                                                            employee &&
                                                            <Box display='flex' flexDirection='row' gap={1} justifyContent='center'>
                                                                <Text className={classesPlacementProfile.profileName} noWrap>{employee &&
                                                                    capitalizeAndAddSpace(employee.full_name).length > 16 ?
                                                                    <HtmlTooltip title={capitalizeAndAddSpace(employee.full_name)} placement="right" arrow>
                                                                        {capitalizeAndAddSpace(employee.full_name).slice(0, 16) + (capitalizeAndAddSpace(employee.full_name).length > 16 ? "..." : "")}
                                                                    </HtmlTooltip>
                                                                    :
                                                                    capitalizeAndAddSpace(employee.full_name)}</Text>
                                                            </Box>
                                                        }
                                                    </Grid>

                                                    <Grid item lg={12} pt={0}>
                                                        <Text className={classesPlacementProfile.profileId}>{employee && employee?.employment_details?.reference_id}</Text>

                                                    </Grid>
                                                    <Grid item lg={12} xs={12} pt={0} p={1} textAlign='center' justifyContent='center'>
                                                        <Box sx={{ cursor: 'pointer' }} className={classesPlacementProfile.uploadBtn} >
                                                            Activity log
                                                        </Box>

                                                    </Grid>
                                                    <Stack my={1} direction="row" justifyContent={"center"} spacing={2}>
                                                        {

                                                            employee && employee.e_verified == 1 ?
                                                                <Button className={classesPlacementProfile.eVerifyBtn} sx={{ border: '1px solid #22C55E !important', backgroundColor: "#22C55E !important" }}>
                                                                    <Stack direction='row' gap={1} sx={{ color: "#FFFFFF  !important", alignItems: "center" }}>
                                                                        E-Verified
                                                                        <img style={{ marginTop: '0px' }} width={'15px'} height={'15px'} src={Verify} alt='e_verify' />
                                                                    </Stack>
                                                                </Button> :
                                                                employee && employee.e_verified == 0 ?
                                                                    <Button className={classesPlacementProfile.notVerified} sx={{ border: '1px solid #0C75EB !important', backgroundColor: "#0C75EB !important" }}>
                                                                        Not E-Verified
                                                                    </Button> : ''
                                                        }
                                                        {

                                                            (statusPlacement !== "In Active" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_edit" && item.is_allowed == true))) &&
                                                                <Button variant="outlined" className={open ? classesPlacementProfile.blueMenu : classesPlacementProfile.moreBtn} onClick={handleClick} id='threedots'>
                                                                    <MoreHorizIcon sx={{ fontSize: "18px", color: open ? `${blue}` : "#9D9E9F" }} />
                                                                    <Menu
                                                                        id="basic-menu"
                                                                        anchorEl={anchorEl}
                                                                        open={open}
                                                                        onClose={handleClose}
                                                                        sx={{
                                                                            '& .MuiPaper-root': {
                                                                                border: '1px solid #EAECF0 !important',
                                                                                width: '150px !important',
                                                                                boxShadow: "#0000001F !important",
                                                                                borderRadius: '8px !important',
                                                                                padding: '0px 0px 0px 0px !important'
                                                                            },
                                                                        }}
                                                                    >
                                                                        <MenuItem className={classesPlacementProfile.inactive} id="Endplacement"><Text smallBlackBold>End Placement</Text></MenuItem>
                                                                    </Menu>
                                                                </Button>
                                                            )
                                                        }
                                                    </Stack>
                                                </Box>
                                                <Box px={3}>
                                                    <Divider />
                                                </Box>
                                                <Box py={3}>
                                                    <Box px={3} sx={{
                                                        height: "30vh",
                                                        overflow: "auto",
                                                        '&::-webkit-scrollbar': {
                                                            width: '4px',
                                                        },
                                                        '&::-webkit-scrollbar-track': {
                                                            '-webkit-box-shadow': 'inset 0 0 6px #ffffff',
                                                        },
                                                        '&::-webkit-scrollbar-thumb': {
                                                            backgroundColor: '#C7CCD3',
                                                            outline: '1px solid #C7CCD3',
                                                            borderRadius: "4px",
                                                        },
                                                        "@media (min-height: 750px)": {
                                                            height: '36vh'
                                                        }

                                                    }}>
                                                        {
                                                            dataArr.map((item, key) => (
                                                                <Box
                                                                    id={item.replaceAll(' ', '')}

                                                                    sx={{ display: 'flex ', flexDirection: "column", justifyContent: 'center', alignItems: 'center', }} >
                                                                    <ListItemButton
                                                                        sx={{ width: '90% ', minHeight: '20px !important' }}
                                                                        key={key}
                                                                        className={`${classesPlacementProfile.listItems} ${current === item ? classesPlacementProfile.listItemsActive : null}`}
                                                                        onClick={() => handleTabChange(item)}
                                                                    >
                                                                        <Grid container>
                                                                            <Grid item xs={10}>{item}</Grid>
                                                                        </Grid>
                                                                    </ListItemButton>
                                                                </Box>

                                                            ))}

                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={9} md={9} sm={8} xs={12}>
                                            <Box sx={{ width: '100%', height: "78vh", borderRadius: "8px", overflow: 'hidden' }}>
                                                <Box m={.5} mr={0.1} sx={{ width: '100% !important' }}>  <Grid container p={1} pr={0} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                                    <Grid container>
                                                        <Grid item lg={11} md={11} sm={11} xs={11}>
                                                            <Box p={1} pl={2} pt={2}>
                                                                <Text largeBlue>
                                                                    {current}
                                                                </Text>
                                                            </Box>
                                                        </Grid>


                                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                                            <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                                                <img src={noDataFound} alt='nodata' />
                                                            </Grid>
                                                            <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                                <Text largeBlack>No Data Found</Text>
                                                            </Grid>


                                                        </Grid>
                                                        <Grid container>
                                                            <Box height={'5vh'} />
                                                        </Grid>

                                                    </Grid>
                                                </Grid>  </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                :
                                <>
                                    {NoPermission()}
                                </>
                        }
                    </Hidden>

                    <Hidden lgUp>
                        <Grid container md={12} xs={12} justifyContent='center' mt={2}>
                            <Grid item container md={11} xs={12} sx={{
                                border: '1px solid #EAECF0', borderRadius: '12px', padding: '15px'
                            }}>
                                <Grid item container md={9} xs={8} columnSpacing={2}>
                                    <Grid item md={2} xs={3}>
                                        <CustomCircularProgress
                                            percentage={employee && employee.profile_progress}
                                            color={'#ffffff'}
                                            src={EmployeeProfile}
                                            name={EmployeeProfile == undefined || EmployeeProfile == null || EmployeeProfile == '' ? employee && employee.basic_details && employee.full_name[0] : ''}
                                            imgWidth='80px !important'
                                            imgHeight='80px !important'
                                            avatarWidth='80px !important'
                                            avatarHeight='80px !important'
                                            value={employee && employee.profile_progress}
                                            size={88}
                                        />
                                    </Grid>
                                    <Grid item container md={6} xs={6} alignItems='center' ml={1}>
                                        <Box alignItems='center'>
                                            <Box display='flex' flexDirection='row' gap={1} justifyContent='center' alignItems='center'>
                                                {
                                                    employee &&
                                                    <Text className={classesPlacementProfile.profileName} noWrap>{employee &&
                                                        capitalizeAndAddSpace(employee.full_name).length > 16 ?
                                                        <HtmlTooltip title={capitalizeAndAddSpace(employee.full_name)} placement="right" arrow>
                                                            {capitalizeAndAddSpace(employee.full_name).slice(0, 16) + (capitalizeAndAddSpace(employee.full_name).length > 16 ? "..." : "")}
                                                        </HtmlTooltip>
                                                        :
                                                        capitalizeAndAddSpace(employee.full_name)}</Text>
                                                }
                                            </Box>
                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                <Text className={classesPlacementProfile.profileId}>{employee && employee?.employment_details?.reference_id}</Text>
                                                <Box mt={.5} sx={{ cursor: 'pointer' }} className={classesPlacementProfile.uploadBtn}>
                                                    Activity log
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item container md={3} xs={4} alignItems='center'>
                                    <Stack my={1} direction="row" justifyContent={"center"} gap={1}>

                                        <Button className={classesPlacementProfile.eVerifyBtn} sx={{ border: '1px solid #22C55E !important', backgroundColor: "#22C55E !important" }}>
                                            <Stack direction='row' gap={1} sx={{ color: "#FFFFFF  !important", alignItems: "center" }}>
                                                E-Verified
                                                <img style={{ marginTop: '0px' }} width={'15px'} height={'15px'} src={Verify} alt='e_verify' />
                                            </Stack>
                                        </Button>

                                        {
                                            (statusPlacement !== "In Active" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_edit" && item.is_allowed == true))) &&
                                                <Button variant="outlined" className={open ? classesPlacementProfile.blueMenu : classesPlacementProfile.moreBtn} onClick={handleClick} id='threedots'>
                                                    <MoreHorizIcon sx={{ fontSize: "18px", color: open ? `${blue}` : "#9D9E9F" }} />
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleClose}
                                                        sx={{
                                                            '& .MuiPaper-root': {
                                                                border: '1px solid #EAECF0 !important',
                                                                width: '150px !important',
                                                                boxShadow: "#0000001F !important",
                                                                borderRadius: '8px !important',
                                                                padding: '0px 2px 0px 0px !important'
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem className={classesPlacementProfile.inactive} id='Endplacement' ><Text smallBlackBold>End Placement</Text></MenuItem>
                                                    </Menu>
                                                </Button>
                                            )
                                        }
                                    </Stack>
                                </Grid>
                                <Grid item container md={12} xs={12} columnSpacing={2} pt={2}>
                                    {
                                        dataArr.map((item, key) => (
                                            <Box
                                                id={item.replaceAll(' ', '')}

                                                sx={{ padding: '5px', display: 'flex ', flexDirection: "row", justifyContent: 'space-evenly !important' }} >

                                                <Grid item md={3} xs={3}>
                                                    <Box display='flex' flexDirection='row' gap={1}>
                                                        {
                                                            current == item ?
                                                                <Text analyticsViewAll sx={{ cursor: 'pointer !important' }} onClick={() => handleTabChange(item)}>{item}</Text> :
                                                                <Text mediumBoldBlack400 sx={{ cursor: 'pointer !important' }} onClick={() => handleTabChange(item)}>{item}</Text>
                                                        }
                                                    </Box>
                                                </Grid>
                                            </Box>
                                        ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container md={12} xs={12} pt={2} justifyContent='center'>
                            <Grid item container md={11} xs={12}>
                                <Box sx={{ height: '70vh', width: '100%', overflowY: 'hidden', overflowX: 'hidden' }}>
                                    <Box m={.5} mr={0.1} sx={{ width: '100% !important' }}>  <Grid container p={1} pr={0} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", }}>
                                        <Grid container>
                                            <Grid item lg={11} md={11} sm={11} xs={11}>
                                                <Box p={1} pl={2} pt={2}>
                                                    <Text largeBlue>
                                                        {current}
                                                    </Text>
                                                </Box>
                                            </Grid>


                                            <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                                <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                                    <img src={noDataFound} alt='nodata' />
                                                </Grid>
                                                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                    <Text largeBlack>No Data Found</Text>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Box height={'5vh'} />
                                            </Grid>
                                            <Backdrop
                                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            >
                                                <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                                            </Backdrop>
                                        </Grid>
                                    </Grid>  </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Box >
            }
        </>
    );
}