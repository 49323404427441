import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import { activeTextColor } from "../../theme";

const SidebarStyles = makeStyles(() => ({

    // Navbar

    nav: {
        backgroundColor: "#FFFFFF",
        height: "84px",
        padding: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        gridGap: "28px",
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 100,
        "@media (min-width: 750px)": {
            padding: "0 20px",
        }
    },
    globalSearchInput: {
        border: "none",
        padding: "0px 0px 0px 10px",
        width: "440px",
        height: "100%",
        background: "transparent",
        color: "rgba(38, 38, 38, 1)",
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontWeight: "600",
        transition: "all .3s ease",
        '&::-webkit-input-placeholder': {
            color: "rgba(199, 204, 211, 1)",
        },
        '&:focus': {
            outline: "none"
        }
    },

    searchIcon: {
        all: "unset",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        textAlign: "center",
        fontSize: "15px",
        fontFamily: "Quicksand",
        width: "45px",
        height: "38px",
        border: "none",
        backgroundColor: "#FFFFFF",
        borderRadius: "6px"
    },

    searchField: {
        height: "40px",
        border: "1.5px solid rgba(199, 204, 211, 1)",
        width: "200px",
        borderRadius: "6px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "@media (min-width: 750px)": {
            width: '460px'
        }
    },

    rightItems: {
        width: "100%",
        display: "flex",
        justifyContent: "end",
        alignItems: "center !important",
        gap: "14px",
        paddingRight: "22px",
    },

    image: {
        height: '20px',
        width: '20px'
    },  
    paper: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 520,
        backgroundColor: "#f4f4f4 !important",
        height: "33px",
        boxShadow: "none !important",
        "@media (min-width:2560px)": {
            width: 850,
        },
        [useTheme().breakpoints.down('lg')]: {
            width: 350
        },
        [useTheme().breakpoints.down('sm')]: {
            width: "100%"
        },
    },
    rightBox: {
        paddingLeft: "130px !important",
        display: "flex",
        "@media (min-width:2560px)": {
            paddingLeft: "200px !important",
        },        
        [useTheme().breakpoints.down("lg")]: {
            paddingLeft: "80px !important",
        },
        [useTheme().breakpoints.down("md")]: {
            display: "none"
        }
    },  
    avatar: {
        width: "31px !important",
        height: "31px !important",
        [useTheme().breakpoints.down('md')]: {
            width: "32px !important",
            height: "32px !important",
        }
    },
    icons: {
        height: '20px !important',
    },  
    menu: {
        marginTop: '48px !important',
        [useTheme().breakpoints.down("sm")]: {
            marginTop: '42px !important',
        }
    },
    flexBox: {
        flexGrow: 1
    }, 
    //Notification popper styles

    popper: {
        marginTop: "10px",
        marginLeft: "-10px"
    },

    // Sidebar styles  
  
    title: {
        paddingTop: "15px !important",
        fontSize: '25px ! important',
        color: `${activeTextColor} `,
        fontWeight: 'bold !important',
        cursor: 'pointer'
    },
    list: {
        width: '100%',
        background: 'background.paper',
        paddingBottom: "0px !important",
        paddingTop: "25px !important"
    },
    text: {
        color: "#FFFFFF"
    }, 
    SearchList: {
        position: 'absolute !important',
        width: '458px !important',
        marginLeft: '-14px',
        top: '64px !important',
        background: '#ffff !important',
        maxHeight: '35vh !important',
        overflowY: 'scroll !important',
        overflowX: 'hidden !important',
    },
    innerBoxmain: {
        background: '#f7f7f7 !important',
        borderRadius: '13px !important',
        padding: '4px !important',
        color: '#191919 !important',
        margin: '4px !important',
        textAlign: 'center',
        width: '20%',
        font: "normal normal normal 13px/25px Quicksand !important"
    },
    innerBoxname: {
        display: 'flex !important',
        alignItems: 'center !important',
        justifyItems: 'center !important',
        color: '#191919 !important',
        marginLeft: '15px !important',
        font: "normal normal normal 13px/25px Quicksand !important"
    },
    mainSearchBar: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': {
            background: '#e1e6ee'
        }
    },
    selectedItem: {
        background: '#e1e6ee',
    }, 

    // toast messages Styles     
    upgrade: {
        width: '78px', background: '#11755B', font: '12px Quicksand !important', padding: '4px 12px 6px 12px !important', borderRadius: '4px', fontWeight: `${600} !important`, color: '#fff', alignItems: 'center', textAlign: 'center', cursor: 'pointer'
    }

}))


export default SidebarStyles;