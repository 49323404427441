import * as React from 'react';
import isoWeek from 'dayjs/plugin/isoWeek';
import { Box, Grid, Stack } from "@mui/material";
import dayjs from 'dayjs';
import Text from '../../components/customText/Text';
import './CustomCalendars.css';
import { Fragment } from 'react';
import { dateFormat } from '../../utils/utils';
import moment from 'moment';
import missingInfo from "../../assets/svg/missingInfo.svg"

// Extend dayjs with the isoWeek plugin to handle weeks correctly
dayjs.extend(isoWeek);

export default function CustomCalendars(props) {

  var value = props.value
  var calendarDetails1 = [];
  if (props.calendarDetails) {
    var timesheet_hours = props.calendarDetails.length ? props.calendarDetails : [];
    timesheet_hours.map(item => {
      let details = {
        date: item.date,
        // id?
        invoice_ready_timesheet: "",
        total_hours: item.actual_hours,
        ot_hours: item.ot_hours,
        pending_for_approval: "",
        invoiced: "",
        holiday: "",
        status: item.status,
        invoice_raised: '',
        weekoff: ""
      };

      if (item.status === 3 && item.invoice_raise === false) {
        details.invoice_ready_timesheet = true;
      }
      else if (item.status === 1 || item.status === 2) {
        details.pending_for_approval = true;
      }
      else if (item.status === 3 && item.invoice_raise === true) {
        details.invoiced = true;
      } else {
        details.weekoff = true;
      }

      calendarDetails1.push(details);

      return calendarDetails1;
    });
  }
  var calendarDetails = calendarDetails1;

  const day = ['WEEK', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

  // Calculate the start and end of the month view
  const startOfMonth = value.startOf('month').startOf('isoWeek');
  const endOfMonth = value.endOf('month').endOf('isoWeek');

  // Generate the days for the calendar view
  let days = [
    // {}
  ];
  let day_of = startOfMonth;
  while (day_of.isBefore(endOfMonth)) {
    days.push(day_of);
    // days.push({});
    day_of = day_of.add(1, 'day');
  }
  days.splice(0, 0, {
    day: 'Week - 1',
    weekBetween: `${moment(days[0].$d).format(dateFormat())} - ${moment(days[6].$d).format(dateFormat())}`
  });
  days.splice(8, 0, {
    day: 'Week - 2',
    weekBetween: `${moment(days[8].$d).format(dateFormat())} - ${moment(days[14].$d).format(dateFormat())}`
  });
  days.splice(16, 0, {
    day: 'Week - 3',
    weekBetween: `${moment(days[16].$d).format(dateFormat())} - ${moment(days[22].$d).format(dateFormat())}`
  });
  days.splice(24, 0, {
    day: 'Week - 4',
    weekBetween: `${moment(days[24].$d).format(dateFormat())} - ${moment(days[30].$d).format(dateFormat())}`
  });
  if(moment(value.$d).day() == 0){
    days.splice(32, 0, {
      day: 'Week - 5',
      weekBetween: `${moment(days[32].$d).format(dateFormat())} - ${moment(days[38].$d).format(dateFormat())}`
    });
    if(days.length > 40){
      days.splice(40, 0, {
        day: 'Week - 6',
        weekBetween: `${moment(days[40].$d).format(dateFormat())} - ${moment(endOfMonth.$d).format(dateFormat())}`
      });
    }
  }
  else{
    days.splice(32, 0, {
      day: 'Week - 5',
      weekBetween: `${moment(days[32].$d).format(dateFormat())} - ${moment(endOfMonth.$d).format(dateFormat())}`
    });
  }
 
  const dateDetailsMap = {};
  calendarDetails.forEach(item => {
    let time = moment(item.date, dateFormat()).format('YYYY-MM-DD')
    dateDetailsMap[new Date(time).getDate()] = item;
  });

  // eslint-disable-next-line
  const HolidayDayBox = (prop) => (
    <Box className="calendarBox" key={prop.index} sx={{
      height: '100px',
      textAlign: 'center', border: '1px solid #F6F6F6'
    }}>
      <Box sx={{
        background: "linear-gradient(0deg, #FCF1F3, #FCF1F3)",
        height: '65%', width: '100%'
      }}>
        <Text sx={{ textAlign: "end", padding: '5px' }} verySmallBoldBlack>{prop.day}</Text>
        <Text sx={{ color: "#EE859D", margin: '10px' }}>{prop.total_hours}</Text>
      </Box>
      <Box sx={{ textAlign: 'center', background: "linear-gradient(0deg, #FCF1F3, #FCF1F3)", height: '35%', width: '100%' }}>
        <div style={{ margin: "auto", textAlign: "center", color: "#EE859D", fontSize: '9px', border: '1px solid #EE859D', background: 'white', padding: '2px', width: 'fit-content', borderRadius: "9px" }}>
          Labor Day (U.S.)
        </div>
      </Box>
    </Box>
  );

  const InvoiceReadyBox = (prop) => (
    <Box className="calendarBox" key={prop.index} sx={{
      height: '100px',
      textAlign: 'center', border: '1px solid #DEDEDE', background: prop.week ? '#F6F6F6' : "#FFFFFF"
    }}>
      <Grid container xs={12}>
        <Grid item xs={6} p={0.6}>
          <Box sx={{ minWidth: '79px', height: '18px', borderRadius: '33px', background: '#16A34A' }}>
            <Text sx={{ color: "#FFFFFF !important", fontSize: '12px !important', fontWeight: '600 !important' }}>Approved</Text>
          </Box>
        </Grid>
        <Grid item container xs={6} justifyContent={'flex-end'}>
          <Text sx={{ textAlign: "end", padding: '5px', fontSize: '14px !important', fontWeight: '600 !important', color: '#404040' }} >{prop.day}</Text>
        </Grid>
      </Grid>
      <Grid container xs={12} justifyContent={'center'}>
        <Stack direction={'row'} spacing={1} pt={2}>
          <Box sx={{
            width: "63px",
            height: "27px",
            margin: "5px 0px 15px 0px",
            borderRadius: "8px",
            opacity: " 0px",
            border: "1px solid #C7CCD3",
          }}>
            <Stack direction={'row'}>
              <span style={{ width: '6px', height: '19px', borderRadius: '4px', background: '#0C75EB', margin: '3px 3px 0px 5px' }} > </span>
              <Text sx={{ color: "#404040", fontSize: '12px', fontWeight: '600', margin: '4px' }}>{prop.total_hours}</Text>
            </Stack>
          </Box>
          <Box sx={{
            width: "63px",
            height: "27px",
            margin: "5px 0px 15px 0px",
            borderRadius: "8px",
            opacity: " 0px",
            border: "1px solid #C7CCD3",
          }}>
            <Stack direction={'row'}>
              <span style={{ width: '6px', height: '19px', borderRadius: '4px', background: '#C186EF', margin: '3px 3px 0px 5px' }} > </span>
              <Text sx={{ color: "#404040", fontSize: '12px', fontWeight: '600', margin: '4px' }}>{prop.ot_hours}</Text>
            </Stack>
          </Box>
        </Stack>


      </Grid>
    </Box>
  );

  const RejectedBox = (prop) => (
    <Box className="calendarBox" key={prop.index} sx={{
      height: '100px',
      textAlign: 'center', border: '1px solid #DEDEDE', background: prop.week ? '#F6F6F6' : "#FFFFFF"
    }}>
      <Grid container xs={12}>
        <Grid item xs={6} p={0.6}>
          <Box sx={{ minWidth: '79px', height: '18px', borderRadius: '33px', background: '#E52525' }}>
            <Text sx={{ color: "#FFFFFF !important", fontSize: '12px !important', fontWeight: '600 !important' }}>Rejected</Text>
          </Box>
        </Grid>
        <Grid item container xs={6} justifyContent={'flex-end'}>
          <Text sx={{ textAlign: "end", padding: '5px', fontSize: '14px !important', fontWeight: '600 !important', color: '#404040' }} >{prop.day}</Text>
        </Grid>
      </Grid>
      <Grid container xs={12} justifyContent={'center'}>
        <Stack direction={'row'} spacing={1} pt={2}>
          <Box sx={{
            width: "63px",
            height: "27px",
            margin: "5px 0px 15px 0px",
            borderRadius: "8px",
            opacity: " 0px",
            border: "1px solid #C7CCD3",
          }}>
            <Stack direction={'row'}>
              <span style={{ width: '6px', height: '19px', borderRadius: '4px', background: '#D1E1FF', margin: '3px 3px 0px 5px' }} > </span>
              <Text sx={{ color: "#C7CCD3", fontSize: '12px', fontWeight: '600', margin: '4px' }}>{prop.total_hours}</Text>
            </Stack>
          </Box>
          <Box sx={{
            width: "63px",
            height: "27px",
            margin: "5px 0px 15px 0px",
            borderRadius: "8px",
            opacity: " 0px",
            border: "1px solid #C7CCD3",
          }}>
            <Stack direction={'row'}>
              <span style={{ width: '6px', height: '19px', borderRadius: '4px', background: '#E2C4F9', margin: '3px 3px 0px 5px' }} > </span>
              <Text sx={{ color: "#C7CCD3", fontSize: '12px', fontWeight: '600', margin: '4px' }}>{prop.ot_hours}</Text>
            </Stack>
          </Box>
        </Stack>


      </Grid>
    </Box>
  );

  const WeekDayBox = (prop) => (
    <Box className="calendarBox" key={prop.index} sx={{
      height: '100px',
      textAlign: 'center', border: '1px solid #DEDEDE', background: "#F6F6F6"
    }}>
      <Text sx={{ textAlign: "end", padding: '5px', fontSize: '14px !important', fontWeight: '600 !important', color: '#404040' }} >{prop.day}</Text>
      <Text sx={{ color: '#C4C6D0', margin: '12px' }}>{prop.total_hours != '00:00' ? prop.total_hours : ''}</Text>
    </Box>
  );

  const PendingBox = (prop) => (
    <Box className="calendarBox" key={prop.index} sx={{
      height: '100px',
      textAlign: 'center', border: '1px solid #DEDEDE', background: prop.week ? '#F6F6F6' : "#FFFFFF"
    }}>
      <Grid container xs={12}>
        <Grid item xs={6} p={0.6}>
          <Box sx={{ minWidth: '79px', height: '18px', borderRadius: '33px', background: '#F59E0B' }}>
            <Text sx={{ color: "#FFFFFF !important", fontSize: '12px !important', fontWeight: '600 !important' }}>Pending</Text>
          </Box>
        </Grid>
        <Grid item container xs={6} justifyContent={'flex-end'}>
          <Text sx={{ textAlign: "end", padding: '5px', fontSize: '14px !important', fontWeight: '600 !important', color: '#404040' }} >{prop.day}</Text>
        </Grid>
      </Grid>
      <Grid container xs={12} justifyContent={'center'}>
        <Stack direction={'row'} spacing={1} pt={2}>
          <Box sx={{
            width: "63px",
            height: "27px",
            margin: "5px 0px 15px 0px",
            borderRadius: "8px",
            opacity: " 0px",
            border: "1px solid #C7CCD3",
          }}>
            <Stack direction={'row'}>
              <span style={{ width: '6px', height: '19px', borderRadius: '4px', background: '#D1E1FF', margin: '3px 3px 0px 5px' }} > </span>
              <Text sx={{ color: "#C7CCD3", fontSize: '12px', fontWeight: '600', margin: '4px' }}>{prop.total_hours}</Text>
            </Stack>
          </Box>
          <Box sx={{
            width: "63px",
            height: "27px",
            margin: "5px 0px 15px 0px",
            borderRadius: "8px",
            opacity: " 0px",
            border: "1px solid #C7CCD3",
          }}>
            <Stack direction={'row'}>
              <span style={{ width: '6px', height: '19px', borderRadius: '4px', background: '#E2C4F9', margin: '3px 3px 0px 5px' }} > </span>
              <Text sx={{ color: "#C7CCD3", fontSize: '12px', fontWeight: '600', margin: '4px' }}>{prop.ot_hours}</Text>
            </Stack>
          </Box>
        </Stack>


      </Grid>
    </Box>
  );

  const MissingBox = (prop) => (
    <Box className="calendarBox" key={prop.index} sx={{
      height: '100px',
      textAlign: 'center', border: '1px solid #DEDEDE', background: "#FFFFFF"
    }}>
      <Grid container xs={12}>
        <Grid item xs={6} p={0.6}>
          <Stack direction={'row'} spacing={1}>
            <Box sx={{ minWidth: '79px', height: '18px', borderRadius: '33px', background: '#171717' }}>
              <Text sx={{ color: "#FFFFFF !important", fontSize: '12px !important', fontWeight: '600 !important' }}>Missing</Text>
            </Box>
            <img src={missingInfo} alt="missing" />
          </Stack>
        </Grid>
        <Grid item container xs={6} justifyContent={'flex-end'}>
          <Text sx={{ textAlign: "end", padding: '5px', fontSize: '14px !important', fontWeight: '600 !important', color: '#404040' }} >{prop.day}</Text>
        </Grid>
      </Grid>
      <Grid container xs={12} justifyContent={'center'}>
        <Stack direction={'row'} spacing={1} pt={2}>
          <Box sx={{
            width: "63px",
            height: "27px",
            margin: "5px 0px 15px 0px",
            borderRadius: "8px",
            opacity: " 0px",
            border: "1px solid #C7CCD3",
          }}>
            <Stack direction={'row'}>
              <span style={{ width: '6px', height: '19px', borderRadius: '4px', background: '#D1E1FF', margin: '3px 3px 0px 5px' }} > </span>
              <Text sx={{ color: "#C7CCD3", fontSize: '12px', fontWeight: '600', margin: '4px' }}>{prop.total_hours}</Text>
            </Stack>
          </Box>
          <Box sx={{
            width: "63px",
            height: "27px",
            margin: "5px 0px 15px 0px",
            borderRadius: "8px",
            opacity: " 0px",
            border: "1px solid #C7CCD3",
          }}>
            <Stack direction={'row'}>
              <span style={{ width: '6px', height: '19px', borderRadius: '4px', background: '#E2C4F9', margin: '3px 3px 0px 5px' }} > </span>
              <Text sx={{ color: "#C7CCD3", fontSize: '12px', fontWeight: '600', margin: '4px' }}>{prop.ot_hours}</Text>
            </Stack>
          </Box>
        </Stack>


      </Grid>
    </Box>
  );

  const DraftedBox = (prop) => (
    <Box className="calendarBox" key={prop.index} sx={{
      height: '100px',
      textAlign: 'center', border: '1px solid #DEDEDE', background: "#FFFFFF"
    }}>
      <Grid container xs={12}>
        <Grid item xs={6} p={0.6}>
          <Box sx={{ minWidth: '79px', height: '18px', borderRadius: '33px', background: '#0C75EB' }}>
            <Text sx={{ color: "#FFFFFF !important", fontSize: '12px !important', fontWeight: '600 !important' }}>Drafted</Text>
          </Box>
        </Grid>
        <Grid item container xs={6} justifyContent={'flex-end'}>
          <Text sx={{ textAlign: "end", padding: '5px', fontSize: '14px !important', fontWeight: '600 !important', color: '#404040' }} >{prop.day}</Text>
        </Grid>
      </Grid>
      <Grid container xs={12} justifyContent={'center'}>
        <Stack direction={'row'} spacing={1} pt={2}>
          <Box sx={{
            width: "63px",
            height: "27px",
            margin: "5px 0px 15px 0px",
            borderRadius: "8px",
            opacity: " 0px",
            border: "1px solid #C7CCD3",
          }}>
            <Stack direction={'row'}>
              <span style={{ width: '6px', height: '19px', borderRadius: '4px', background: '#D1E1FF', margin: '3px 3px 0px 5px' }} > </span>
              <Text sx={{ color: "#C7CCD3", fontSize: '12px', fontWeight: '600', margin: '4px' }}>{prop.total_hours}</Text>
            </Stack>
          </Box>
          <Box sx={{
            width: "63px",
            height: "27px",
            margin: "5px 0px 15px 0px",
            borderRadius: "8px",
            opacity: " 0px",
            border: "1px solid #C7CCD3",
          }}>
            <Stack direction={'row'}>
              <span style={{ width: '6px', height: '19px', borderRadius: '4px', background: '#E2C4F9', margin: '3px 3px 0px 5px' }} > </span>
              <Text sx={{ color: "#C7CCD3", fontSize: '12px', fontWeight: '600', margin: '4px' }}>{prop.ot_hours}</Text>
            </Stack>
          </Box>
        </Stack>


      </Grid>
    </Box>
  );

  const NormalDayBox = (prop) => (
    <Box className="calendarBox" key={prop.index} sx={{
      height: '100px',
      textAlign: 'center', border: '1px solid #DEDEDE', background: "#F6F6F6"
    }}>
      <Text sx={{ textAlign: "end", padding: '5px', fontSize: '14px !important', fontWeight: '600 !important', color: '#404040' }} >{prop.day}</Text>
      <Text sx={{ color: "#0C75EB", margin: '12px' }}></Text>
    </Box>
  );

  const WeekNameBox = (prop) => (
    <Box className="calendarBox" key={prop.index} sx={{
      height: '100px',
      alignItems: 'center',
      textAlign: 'center', border: '1px solid #DEDEDE', background: "#FFFFFF"
    }}>
      <Text sx={{ textAlign: "center", margin: '12px' }} verySmallBoldBlack>{prop.day.day}</Text>
      <Text sx={{ color: "#0C75EB", margin: '12px' }} verySmallBoldBlack>{prop.day.weekBetween}</Text>
    </Box>
  );

  return (
    <Fragment>
      <Box  sx={{
        display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)', // Creates 7 columns with equal width
      }}>
        {day.map((weekday, index) => (
          <Box key={index} sx={{
            textAlign: 'center',
            color: "#979CA1",
            background: '#FFFFFF',
            border: '1px solid #DEDEDE',
            height: '38px',
            padding: '8px'
          }}>
            <Text sx={{ fontSize: '13px !important', fontWeight: '700 !important', color: '#404040 !important' }}>{weekday}</Text>
          </Box>
        ))}
        {
          days.map((day, index) => {
            if (props.location.weekly && props.location.weekStart > index) {
              return ''
            }
            else if (props.location.weekly && props.location.weekEnd < index) {
              return ''
            }
            else if (day.weekBetween !== undefined) {
              return <WeekNameBox key={index} index={index} day={day}></WeekNameBox>;
            }
            else {
              const details = dateDetailsMap[day.date()];
              var dateMatchwithMonth = false;
              if (dateDetailsMap[day.date()] !== undefined) {
                dateMatchwithMonth = (dateDetailsMap[day.date()].date === day.format(dateFormat()));
              }
              if (details && dateMatchwithMonth) {
                if (day.day() == 6 || day.day() == 0) {
                  if (details.status == "Approved") {
                    return <InvoiceReadyBox ot_hours={details.ot_hours} total_hours={details.total_hours} key={index} index={index} day={day.date()} week={true}></InvoiceReadyBox>;
                  }
                  else if (details.status == "Rejected") {
                    return <RejectedBox ot_hours={details.ot_hours} total_hours={details.total_hours} key={index} index={index} day={day.date()} week={true}></RejectedBox>;
                  }
                  else if (details.status == "Pending Approval") {
                    return <PendingBox ot_hours={details.ot_hours} total_hours={details.total_hours} key={index} index={index} day={day.date()} week={true}></PendingBox>;
                  }
                  else{
                    return <WeekDayBox total_hours={details.total_hours} ot_hours={details.ot_hours} key={index} index={index} day={day.date()}></WeekDayBox>;
                  }
                }
                else if (details.status == "Pending Approval") {
                  return <PendingBox ot_hours={details.ot_hours} total_hours={details.total_hours} key={index} index={index} day={day.date()} week={false}></PendingBox>;
                } else if (details.status == "Approved") {
                  return <InvoiceReadyBox ot_hours={details.ot_hours} total_hours={details.total_hours} key={index} index={index} day={day.date()} week={false}></InvoiceReadyBox>;
                }
                else if (details.status == "Draft") {
                  return <DraftedBox ot_hours={details.ot_hours} total_hours={details.total_hours} key={index} index={index} day={day.date()}></DraftedBox>;
                }
                else if (details.status == "Missing") {
                  return <MissingBox ot_hours={details.ot_hours} total_hours={details.total_hours} key={index} index={index} day={day.date()}></MissingBox>;
                }
                else if (details.status == "Rejected") {
                  return <RejectedBox ot_hours={details.ot_hours} total_hours={details.total_hours} key={index} index={index} day={day.date()} week={false}></RejectedBox>;
                }
              }
              return <NormalDayBox key={index} index={index} day={day.date()}></NormalDayBox>;
            }

          })
        }
      </Box>
    </Fragment>
  );
}
