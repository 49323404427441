import { makeStyles } from "@mui/styles";

const EmployeeVerifyStyles = makeStyles(() => ({
    sideBarBox: {
        height: "75vh",
        width: "82%",
        padding: "0px 25px !important",
        marginLeft: '6px !important'     
    },
    innerSideBox: {
        width: "100%",
        border: "1px solid #E2E5E6",
        borderRadius: "4px",
        padding: "15px",
        height: "75vh",
        overflow: 'auto',
        "&::-webkit-scrollbar": {
            display: "none !important"
        }
    },
    imageBox: {
        position: "relative",
        display: "inline-flex",
        overflow: 'auto',
        cursor: "pointer",
    },
    activeImageBox: {
        border: "2px solid #0C75EB !important",
        borderRadius: "4px",
    },
    image: {
        height: "140px",
        width: "120px",
        borderRadius: "4px !important"
    },
    activeImage: {
        position: 'absolute',
        right: 0,
        height: "20px !important",
        paddingRight: "11px !important",
        marginTop: "8px !important",
        borderRadius: "8px !important"
    },
    cropOverlay: {
        position: 'absolute',
        height: "140px",
        width: "100%",
        top: 0,
        left: 0,
        opacity: "20%",
        backgroundColor: '#313131', // Set your desired background color and transparency
    },
    rejectImage: {
        height: "132px",
        width: "150px",
        position: 'absolute',
        zIndex: 10       
    },
    middleGrid: {
        background: "#F6F6F6",
        borderRadius: "8px",
    },
    middleImageBox: {
        height: "75vh",
        width: "100%",
        padding: "40px 70px ",
        textAlign: "left",
        overflow: "auto !important",
        "&::-webkit-scrollbar": {
            display: "none !important"
        }
    },
    middleImage: {       
        width: "-webkit-fill-available",
        paddingTop: "80px !important",
        margin: 'auto'
    }, 
    noVoidChequeSidebar: {
        height: "140px",
        width: "120px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: "4px !important"
    }
}))

export default EmployeeVerifyStyles;