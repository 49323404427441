import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Backdrop, TextField, Chip, Autocomplete } from '@mui/material';
import EmployeesApi from '../../../../../apis/admin/employees/EmployeesApi';
import Button from '../../../../../components/customButton/Button';
import LocalStorage from '../../../../../utils/LocalStorage';
import Text from '../../../../../components/customText/Text';
import EditIcon from '../../../../../assets/svg/newEdit.svg'
import FileSvg from '../../../../../assets/svg/File.svg'
import noDataFound from '../../../../../assets/svg/NoDataFoundIcon.svg'
import { blue } from '../../../../../theme';
import download from '../../../../../assets/employee/filedownload.svg';
import CommonApi from '../../../../../apis/CommonApi';
import Dropzone from 'react-dropzone';
import Loader from '../../../../../assets/gif/japfuLoader.gif';
import { BlackToolTip, addErrorMsg, addSuccessMsg, capitalizeAndAddSpace, dateFormat, openDocumentInNewtab } from '../../../../../utils/utils'
import LoadingScreen from './LoadingScreen';
import OnBoardApi from '../../../../../apis/admin/employees/OnBoardApi';
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import Sure from '../../../../../assets/svg/placementPop.svg'
import Select from '../../../../../components/select/Select';
import Date from '../../../../../components/datePicker/Date';
import moment from 'moment';
import { isValid } from '../../../../../components/Validation';
import Userplus from '../../../../../assets/svg/plus.svg';
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import visaUpdated from '../../../../../assets/employee/Visa_updated.svg';

export default function WorkView(props) {
    const { getIndex } = props;
    // eslint-disable-next-line
    const [loading, setloading] = useState(false);
    const [list, setList] = useState({
        support_documents: [],
        valid_from: '',
        valid_till: '',
        visa_type_name: '',
        id: ''
    });// eslint-disable-next-line
    const [current, setCurrent] = useState("education");
    const [employee, setEmployee] = useState(props.employee)
    const [formOpen, setformOpen] = useState(false)
    const [openBacKLoader, setOpenBackDrop] = useState(false);
    const [visaTypeDropdown, setVisaTypeDropdown] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [clearPopup3, setclearPopup3] = useState(false);
    const [openVisaChange, setOpenVisachange] = useState(false);
    const [error, setError] = useState({
        id: '',
        valid_from: '',
        valid_till: '',
        visa_type_id: '',
        support_documents: [],
        support_documents_deleted_ids: [],
    })
    const [state, setState] = useState({
        id: '',
        employee_id: props.employee.id,
        valid_from: '',
        valid_till: '',
        visa_type_id: '',
        support_documents: [],
        support_documents_deleted_ids: [],
        clear_all: false
    });
    const [clearPopup, setclearPopup] = useState(false);// eslint-disable-next-line

    useEffect(() => {
        setEmployee(props.employee)
        setCurrent(props.documentPosition)
        // eslint-disable-next-line
    }, [props])

    useEffect(() => {
        if (props.documentPosition === 'work' && props.formEdit == false) {
            getVisa();
            getVisaTypeDropdown();
        }
        // eslint-disable-next-line
    }, [props.documentPosition])

    const getVisaTypeDropdown = () => {
        let search = "";
        CommonApi.VisaTypeDropdown(search).then((response) => {
            if (response.data.statusCode == 1003) {
                setVisaTypeDropdown(response.data.data);
            } else {
                addErrorMsg(response.data.message);
            }
        });
    }

    const getVisa = () => {
        setloading(true)
        setError({
            ...error,
            visa_type_id: ''
        })
        EmployeesApi.getVisa(props.employee.id).then((response) => {
            setTimeout(() => {
                if (response.data.statusCode == 1003) {
                    setloading(false)
                    if (response.data.data.length > 0) {
                        setList(response.data.data[0]);
                        setState({
                            ...state,
                            id: response.data.data[0].id,
                            visa_type_id: response.data.data[0].visa_type_id,
                            employee_id: response.data.data[0].employee_id,
                            valid_from: response.data.data[0].valid_from,
                            valid_till: response.data.data[0].valid_till,
                            support_documents: response.data.data[0].support_documents,
                            support_documents_deleted_ids: []
                        })
                    }
                    else {
                        setState({
                            ...state,
                            id: '',
                            employee_id: props.employee.id,
                            valid_from: '',
                            valid_till: '',
                            visa_type_id: '',
                            support_documents: [],
                            support_documents_deleted_ids: [],
                            clear_all: false
                        })
                        setList({
                            id: '',
                            employee_id: props.employee.id,
                            valid_from: '',
                            valid_till: '',
                            visa_type_id: '',
                            support_documents: [],
                            support_documents_deleted_ids: [],
                            clear_all: false
                        });
                    }
                }
                else {
                    setloading(false)
                }
            }, 400)
        });
    }


    const openInNewTab = (args, documentUrl) => {
        if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else if (args.aws_s3_status == undefined || args.aws_s3_status == undefined) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            openDocumentInNewtab(args.document_key, args.document_path)
        }
    }

    const handleDrop = (e) => {
        state.clear_all = false
        if (e.length < 11) {
            setOpenBackDrop(true);
            for (let i = 0; i < e.length; i++) {
                if (e[i].path.split('.').some(r => ['pdf', 'doc', 'docx'].includes(r))) {
                    if (Number(((e[i].size / (1024 * 1024)).toFixed(2))) < 25 ) {
                        const formData = new FormData();
                        // e.map((i) => formData.append("files", i))
                        formData.append("files", e[i]);
                        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                        CommonApi.documentUpload("visa-document", formData, LocalStorage.getAccessToken()).then((res) => {
                            if (res.data.statusCode == 1003) {
                                if (state.support_documents.length < 10) {
                                    state.support_documents.push({
                                        id: '',
                                        visa_document_upload_id: res.data.data.id,
                                        document_url: res.data.data.document_url,
                                        document_name: res.data.data.document_name,
                                        slug: ''
                                    });
                                    state.clear_all = false
                                    if (i === e.length - 1) {
                                        setOpenBackDrop(false);
                                    }
                                }
                                else {
                                    setOpenBackDrop(false);
                                    addErrorMsg('You can upload a maximum of 10 files at a time. Please remove excess files and try again')
                                }
                                setState({ ...state })
                            } else {
                                setOpenBackDrop(false);
                                addErrorMsg(res.data.message);
                            }
                        })
                    }
                    else {
                        setOpenBackDrop(false);
                        addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
                    }
                }
                else {
                    setOpenBackDrop(false);
                    addErrorMsg('Please upload files in PDF or DOC format only.')
                }
            }
        } else {
            setOpenBackDrop(false);
            addErrorMsg('You can upload a maximum of 10 files at a time. Please remove excess files and try again')
        }
        setState({ ...state })
    }

    const submitForm = () => {
        if (error.visa_type_id !== '') {
            return true
        }
        let data = {
            valid_from: state.valid_from,
            valid_till: state.valid_till,
            visa_type_id: state.visa_type_id,
            support_documents: state.support_documents.length > 0 ? 'error' : '',
        }
        if (state.id !== '') {
            state['request_id'] = LocalStorage.uid()
            state['employee_id'] = props.employee.id
            if (isValid(data)) {
                state['clear_all'] = true;
            }
            if (state.clear_all !== true && state.visa_type_id === '') {
                error.visa_type_id = 'This field is required'
                setError({ ...error })
            }
            else {
                setButtonLoader(true);
                OnBoardApi.visaUpdate(state, state.id, LocalStorage.getAccessToken()).then((res) => {
                    setButtonLoader(false);
                    if (res.data.statusCode === 1003) {
                        addSuccessMsg(`Changes Saved`);
                        setformOpen(false);
                        props.setformEdit(false);
                        if (employee.e_verified != 0 && employee.employment_type_id == 2) {
                            setOpenVisachange(true);
                        }
                        getVisa()
                    } else {
                        addErrorMsg(res.data.message);
                    }
                })
            }
        }
        else {
            if (isValid(data)) {
                setformOpen(false);
                return true
            }
            else {
                if (state.visa_type_id === '') {
                    error.visa_type_id = 'This field is required'
                    setError({ ...error })
                }
                else {
                    state['request_id'] = LocalStorage.uid()
                    state['employee_id'] = props.employee.id
                    OnBoardApi.visaStore(state, LocalStorage.getAccessToken()).then((res) => {
                        if (res.data.statusCode === 1003) {
                            addSuccessMsg(`Visa Added`);
                            setformOpen(false);
                            getVisa()
                            props.setformEdit(false)
                        } else {
                            addErrorMsg(res.data.message);
                        }
                    })
                }
            }
        }
    }

    const removeDeletedItem = (item) => {
        let data = state
        if (item.id) {
            data.support_documents_deleted_ids.push(item.id)
        }
        data.support_documents.splice(item.index, 1)
        if (data.support_documents.length === 0) {
            if (state.visa_type_id == '' || state.visa_type_id == null || state.visa_type_id == undefined) {
                data.clear_all = true
            }
        }
        // state.support_documents_deleted_ids.push(item.id)
        // state.support_documents.splice(item.index, 1)
        setState({ ...data })
        // setDeletePopup(false)
    }

    const clearAll = () => {
        setclearPopup(false)
        if (state.support_documents.length > 0 && state.support_documents[0].id != '') {
            let err = []
            state.support_documents.forEach((i) => { err.push(i.id) })
            state.support_documents_deleted_ids = err
        }
        setState({
            ...state,
            support_documents: [],
            valid_from: '',
            valid_till: '',
            visa_type_id: '',
            clear_all: true
        })
        setclearPopup(false)
    }

    const handleChange = (e) => {
        state.clear_all = false
        setState({
            ...state, [e.target.name]: e.target.value
        })
        if (e.target.name === 'visa_type_id') {
            setError({
                ...error,
                visa_type_id: ''
            })
        }
    };

    const handleChangeDate = (e, name) => {
        state.clear_all = false
        let data = { name: name, value: e == null ? '' : moment(e.$d).format(dateFormat()) }
        if (data.name == 'valid_from') {
            state.valid_till = ''
        }
        setState({ ...state, [data.name]: data.value })

    };

    return (
        <Grid container p={1} >
            {
                loading ?
                    <Grid container>
                        <LoadingScreen />
                    </Grid>
                    :
                    <Grid container>
                        <Grid item lg={11} md={11} sm={11} xs={11}>
                            <Box p={1} pl={2} pt={2}>
                                <Text largeBlue>
                                    Work Authorization
                                </Text>
                            </Box>
                        </Grid>
                        {
                            formOpen ? '' :
                                (employee && employee.is_off_boarding_initiated === false && (list.support_documents && list.support_documents.length > 0)) || list.id !== '' ?
                                    <Grid item lg={1} md={1} sm={1} xs={1}>
                                        <Box p={1} pr={0}>
                                            {(LocalStorage.getUserData().super_admin || (props.rolePermission !== "" && props.rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) &&
                                                <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => {
                                                    if (employee.pending_requests.visa_documents) {
                                                        setclearPopup3(true)
                                                    }
                                                    else { setformOpen(true); LocalStorage.setEditForm('edit'); props.setformEdit(true) }
                                                }} />
                                            }
                                        </Box>
                                    </Grid>
                                    : ''
                        }
                        {
                            formOpen ? '' :
                                (list.support_documents && list.support_documents.length > 0) || list.id !== '' ?
                                    <Grid container>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Visa Type
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {list.visa_type_name}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Date of Issue
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {list.valid_from}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Date of Expiry
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {list.valid_till}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        {list.support_documents.map((item, index) => (
                                            <Grid item lg={3} md={6} sm={6} xs={12}>
                                                <Box p={1} pt={2} pl={2}>
                                                    <Text mediumLabel>
                                                        Supporting Document - {index + 1}
                                                    </Text>
                                                    <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(item, item.document_url) }}>
                                                        <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />
                                                        <Text mediumBlue>
                                                            <BlackToolTip arrow placement='top' title={item.document_name}>
                                                                {item.document_name.slice(0, 16)} {item.document_name.length > 16 ? '...' : ''}
                                                            </BlackToolTip>
                                                        </Text>
                                                    </Stack>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>

                                    :
                                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                            <img src={noDataFound} alt='nodata' />
                                        </Grid>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                            <Text largeBlack>No Documents Uploaded</Text>
                                        </Grid>
                                        {employee.is_off_boarding_initiated === false && (LocalStorage.getUserData().super_admin || (props.rolePermission !== "" && props.rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) &&
                                            <>
                                                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                    <Text mediumLabel sx={{ fontSize: '14px !important' }}>Upload Work Authorization document for this  </Text>
                                                </Grid>
                                                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={1}>
                                                    <Text mediumLabel sx={{ fontSize: '14px !important' }}>employee by clicking on the below button.</Text>
                                                </Grid>
                                                {
                                                    employee.is_off_boarding_initiated === false &&
                                                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={3}>
                                                        <Button finishFilledQuicksandLargeWidth350 onClick={() => {
                                                            if (employee.pending_requests.visa_documents) {
                                                                setclearPopup3(true)
                                                            }
                                                            else { setformOpen(true); props.setformEdit(true) }
                                                        }}> <img src={Userplus} alt='plus' /> Add Work Authorization Documents</Button>
                                                    </Grid>
                                                }
                                            </>
                                        }
                                    </Grid>
                        }
                        {formOpen ?
                            <Grid container p={2}>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={0}>
                                        <Select
                                            options={visaTypeDropdown}
                                            name='visa_type_id'
                                            value={state.visa_type_id}
                                            onChange={handleChange}
                                            label={'Visa Type'}
                                            error={error.visa_type_id}
                                        />
                                        <Text red>{error.visa_type_id}</Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Dropzone onDrop={acceptedFiles => { handleDrop(acceptedFiles) }}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section style={{
                                                background: '#F0F7FF',
                                                border: `1px dashed #0C75EB`,
                                                padding: '30px',
                                                borderRadius: '12px'
                                            }}>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <Grid container spacing={1} textAlign='center'>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} marginTop={'-8px'}>
                                                            <img src={download} alt='download' />
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign='center'>
                                                            <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' textAlign='center' gap={1}>
                                                                <Text largeBlack>Drag & Drop Documents</Text>
                                                                <Text smallGrey>or</Text>
                                                                <Text smallBlue1 sx={{ textDecoration: `1px underline ${blue} !important`, cursor: 'pointer' }}>Click Here</Text>
                                                                <Text largeBlack>To Upload</Text>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                            <Text mediumOrange>Add visa documents like EAD, I20's, I983, I-129, I-797,</Text>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Text mediumOrange> GC, GC EAD, etc</Text>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                            <Text smallGreyFont>Document Size 25MB, Format - PDF, Docs</Text>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center", border: "1px solid #C7CCD3", borderRadius: "8px", }}>
                                        <div style={{ width: '100%', paddingTop: state.support_documents.length > 0 ? '12px' : '0px', minHeight: '59px', display: 'flex', alignItems: "center", }}>
                                            <Autocomplete
                                                clearIcon={false}
                                                options={[]}
                                                freeSolo
                                                multiple
                                                onKeyPress={(e) => { e.preventDefault() }}
                                                value={state.support_documents}
                                                renderTags={(value, props) =>
                                                    value.map((option, index) => (
                                                        <Chip label={option.document_name} {...props({ index })}
                                                            onClick={() => openInNewTab(option, option.document_url)}
                                                            onDelete={() => { removeDeletedItem({ id: option.id, index: index }) }}
                                                        // className={classes.chipLabel}
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) => <TextField label="Uploaded Documents" {...params} />}
                                                sx={{
                                                    width: '100%',
                                                    "& .MuiInputBase-root": {
                                                        cursor: "pointer",
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        cursor: "pointer",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        border: "none"
                                                    },
                                                    "& .MuiAutocomplete-endAdornment": {
                                                        display: "none"
                                                    },
                                                    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                                                        transform: "translate(10px, 16px) scale(1);"
                                                    },
                                                    "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                                                        color: "#737373",
                                                        fontSize: "14px",
                                                        fontFamily: "Quicksand !important",
                                                        fontWeight: 400,
                                                    },
                                                    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                                                        color: "#737373",
                                                        fontSize: "15px",
                                                        fontFamily: "Quicksand !important",
                                                        fontWeight: 500,
                                                    },
                                                    "&.Mui-focused .MuiInputLabel-outlined": {
                                                        color: "#737373",
                                                        fontSize: "10px",
                                                        fontFamily: "Quicksand !important",
                                                        fontWeight: 400,
                                                        transform: state.support_documents.length === 0 ? "translate(12px, 0px) scale(1);" : "translate(12px, -8px) scale(1);"
                                                    },
                                                }}
                                            />
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={0}>
                                        <Date
                                            labelText={"Date Of Issue"}
                                            name={"valid_from"}
                                            maxDate={moment().format(dateFormat())}
                                            value={state.valid_from}
                                            onChange={(e) => handleChangeDate(e, "valid_from")}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2}>
                                        <Date
                                            labelText={"Date Of Expiry"}
                                            name={"valid_till"}
                                            value={state.valid_till}
                                            minDate={state.valid_from}
                                            disabled={state.valid_from == ''}
                                            onChange={(e) => handleChangeDate(e, "valid_till")}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item container lg={12} md={12} sm={12} xs={12} pt={3}>
                                    <Grid item container lg={6} md={6} sm={6} xs={12} pt={3}>
                                        <Button cancelOutlineQuicksand onClick={() => { setformOpen(false); getVisa(); props.setformEdit(false) }}>Cancel</Button>
                                    </Grid>
                                    <Grid item container lg={6} md={6} sm={6} xs={12} pt={3} justifyContent={'flex-end'}>
                                        <Stack direction={'row'} >
                                            <Button clearAllRed onClick={() => { setclearPopup(true) }}>Clear all</Button>
                                            <LoadingButton loading={buttonLoader} smallBlue onClick={() => { submitForm() }}>Save</LoadingButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : ''
                        }

                        <Grid container>
                            <Box height={'5vh'} />
                        </Grid>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={openBacKLoader}
                        // onClick={handleClose}
                        >
                            <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                        </Backdrop>
                    </Grid>
            }
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to clear all the information</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { clearAll() }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup3} setOpenPopup={setclearPopup3} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Editing Denied!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>There is an open request to change the details.</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Please take actions before editing the fields.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button finishFilledQuicksand onClick={() => { setclearPopup3(false) }} >
                                Okay
                            </Button>
                        </Stack>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup openPopup={openVisaChange} setOpenPopup={setOpenVisachange} statusWidth white iconHide>
                <Grid container lg={12} xs={12} textAlign='center' spacing={2} p={2}>
                    <Grid item lg={12} xs={12}>
                        <img src={visaUpdated} alt="approve" />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Text largeBlack22>E-Verify Employee</Text>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Text offBoardBodyFont>As
                            &nbsp;{employee ? capitalizeAndAddSpace(employee.basic_details.full_name) : '-'}&nbsp;
                            visa type has been changed, please ensure that the employee undergoes the E-verification process.</Text>
                    </Grid>
                    <Grid item lg={12} xs={12} mt={2}>
                        <Button blueButton onClick={() => { setOpenVisachange(false); getIndex(); }}>Done</Button>
                    </Grid>
                </Grid>
            </ReusablePopup>
        </Grid>
    );
}