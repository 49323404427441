import React, { useEffect, useRef, useState, useCallback } from 'react'// eslint-disable-next-line
import EmployeeVerifyApi from "../../../../apis/admin/employees/EmployeeVerifyApi";
import { Box, Card, CardContent, CardHeader, Divider, Grid, Skeleton, Step, Stepper } from '@mui/material'
import checkIcon from '../../../../assets/svg/checkedCircle.svg';// eslint-disable-next-line
import { addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, checkZipcode, dateFormat, formatString, getCountryDetails, removeLoader } from '../../../../utils/utils';
// eslint-disable-next-line
import alertCircle from '../../../../assets/timeSheets/alert-circle.svg';
import LocalStorage from '../../../../utils/LocalStorage';
import EmployeeVerifyStyles from './EmployeeVerifyStyles';
import { BrownMnColorlibStepLabel, BrownMnCustomStepIcon, BrownMnCustomisedConnector, btnTxtBlack } from '../../../../theme';
import Text from '../../../../components/customText/Text';
import Input from '../../../../components/input/Input';
import { address_validation, isValid, isValidMulti, onCharactersAndNumbersChange, onNumberOnlyChange, validate_accountNumber, validate_charWithSpace, validate_city, validate_emptyField, validate_empty_address, validate_passport, validate_routingNumber, validate_ssn, validate_usContact, validate_zipcode, validates_new_i94 } from '../../../../components/Validation';
import Button from '../../../../components/customButton/Button';
import ReusableDelete from '../../../../components/reuablePopup/ReusableDelete';
import documentReject from '../../../../assets/images/documentReject.png';
import CommonApi from '../../../../apis/CommonApi';
import Date from '../../../../components/datePicker/Date';
import moment from 'moment';
import Select from '../../../../components/select/Select';
import whiteDownLoad from "../../../../assets/svg/whiteDownload.svg";
import whiteZoomin from "../../../../assets/svg/whiteZoomin.svg";
import blueLoader from '../../../../assets/gif/japfuLoader.gif';
import { useLocation, useNavigate } from 'react-router-dom'; // eslint-disable-next-line
import { pdfjs } from 'react-pdf';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { ErrorMessages } from '../../../../utils/ErrorMessages';
import { debounce } from 'lodash';
import EmployeesApi from '../../../../apis/admin/employees/EmployeesApi';

const desiredOrder = [
    'counter_signed_offer_letter',
    'passport',
    'i94',
    'signed_ssn',
    'work_authorization',
    'all_previous_i20s',
    'education_documents',
    'drivers_license',
    'copy_of_void_cheque'
];


function EmployeeVerifyFLow() {

    const accountType = require('../../../../utils/jsons/AccountType.json');// eslint-disable-next-line
    const navigate = useNavigate();
    const location = useLocation()  // eslint-disable-next-line
    const param = new URLSearchParams(window.location.search);
    const id = param.get('id');
    const employeeVerifyId = (id !== '' && id !== null && id !== undefined) ? id : LocalStorage.getRedirectedModule() != null ? LocalStorage.getRedirectedModule() == 'employee > verify' ? LocalStorage.getNotificationId() : LocalStorage.getEmployeeVerifyId() : LocalStorage.getEmployeeVerifyId();
    // eslint-disable-next-line
    const classes = EmployeeVerifyStyles();
    const [step, setStep] = useState(0);  // eslint-disable-next-line
    const [data, setData] = useState(null);
    const [documentData, setDocumentData] = useState([]);
    const [visaTypes, setVisaTypes] = useState([]);// eslint-disable-next-line    
    const [countries, setCountries] = useState(getCountryDetails() ? getCountryDetails() : []);
    const [relation, setRelation] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeObject, setActiveObject] = useState(null);
    const [rejectOpen, setRejectOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [emergencyContact, setEmergencyContact] = useState([]);   // eslint-disable-next-line
    const [scrollPosition, setScrollPosition] = useState(0);
    const [error, setError] = useState({});
    const [contactError, setContactError] = useState([
        {
            relationship_id: '',
            name: '',
            mobile_number: '',
            address_1: '',
            address_2: '',
            city: '',
            zip_code: '',
            state_id: '',
            country_id: '',
            email_id: ''
        },
        {
            relationship_id: '',
            name: '',
            mobile_number: '',
            address_1: '',
            address_2: '',
            city: '',
            zip_code: '',
            state_id: '',
            country_id: '',
            email_id: ''
        }
    ]);

    const scrollToTop = () => {
        setScrollPosition(0); // Set scroll position to 0
    };

    const expiryTypeList = [
        {
            id: 2,
            value: "Date"
        },
        {
            id: 1,
            value: "D/S"
        }
    ]

    const useWindowCloseAlert = (targetPath, onLeaveCallback) => {
        useEffect(() => {
            const isReloadingFlag = 'isReloading';

            let userConfirmedLeave = false;

            const alertUser = (event) => {
                const isReloading = sessionStorage.getItem(isReloadingFlag);

                if (window.location.pathname === targetPath && !isReloading) {
                    event.preventDefault();
                    event.returnValue = 'Are you sure you want to leave this page? The verification process is not yet complete.';
                    return 'Are you sure you want to leave this page? The verification process is not yet complete.';
                }
            };

            const handleTabClosing = () => {
                if (userConfirmedLeave) {
                    onLeaveCallback();
                }
            };

            const setReloadFlag = () => {
                sessionStorage.setItem(isReloadingFlag, 'true');
            };

            const clearReloadFlag = () => {
                sessionStorage.removeItem(isReloadingFlag);
            };

            const handleKeydown = (event) => {
                if ((event.ctrlKey || event.metaKey) && (event.key === 'r' || event.key === 'F5')) {
                    setReloadFlag();
                }
            };

            window.addEventListener('load', () => {
                setTimeout(() => clearReloadFlag(), 100);
            });

            window.addEventListener('beforeunload', alertUser);
            window.addEventListener('unload', handleTabClosing);
            window.addEventListener('keydown', handleKeydown);

            // Track if the user confirmed the unload
            const handleUnloadConfirmation = (event) => {
                if (event.returnValue) {
                    userConfirmedLeave = true;
                } else {
                    userConfirmedLeave = false;
                }
            };

            window.addEventListener('beforeunload', handleUnloadConfirmation);

            return () => {
                window.removeEventListener('beforeunload', alertUser);
                window.removeEventListener('unload', handleTabClosing);
                window.removeEventListener('keydown', handleKeydown);
                window.removeEventListener('beforeunload', handleUnloadConfirmation);
            };
        }, [targetPath, onLeaveCallback]);
    };

    const unLinkAssignee = async () => {
        try {
            const data = {
                request_id: LocalStorage.uid(),
                id: employeeVerifyId,
            };
            const res = await EmployeesApi.employeeUnlinkAssign(data);
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
            } else {
                addErrorMsg(res.data.message);
            }
        } catch (error) {
            addErrorMsg('Failed to unlink assignee.');
        }
    };

    useWindowCloseAlert('/employee/verify', unLinkAssignee);

    useEffect(() => {
        // setData(EmployeeJson);
        // setDocumentData(handleFormArray(data));
        // if (location.state != null && location.state != undefined && location.state != "") {
        //     setStep(1);
        // }
        // setEmergencyContact(EmployeeJson.emergency_contacts);
        // if (EmployeeJson.emergency_contacts !== null && EmployeeJson.emergency_contacts.length > 0) {
        //     statesList(EmployeeJson.emergency_contacts[0].country_id, 0) // for emergency contacts - 1
        //     statesList(EmployeeJson.emergency_contacts[1].country_id, 1) // for emergency contacts - 2
        // }
        visaDropdownList();
        relationList();
        countriesList();
        // setTimeout(() => {
        //     handleAssignState(0);
        // }, 500)
        getInviteViaLink(employeeVerifyId);
        // eslint-disable-next-line
    }, []);

    const getOCRdata = (obj) => {
        addLoader()
        if (obj && (obj.name == 'passport' && obj.approval_status != "Approved")) {
            const data = {
                id: obj.id,
                url: obj.document_url
            }
            CommonApi.ocrDocumentUpload('passport-data', data).then((res) => {
                removeLoader()
                if (res.data.statusCode === 1003) {
                    obj.document_number = res.data.data.passport_number ? res.data.data.passport_number : ''
                    obj.date_of_issue = res.data.data.date_of_issue ? moment(res.data.data.date_of_issue, dateFormat()).format(dateFormat()) : ''
                    obj.date_of_expiry = res.data.data.date_of_expiry ? moment(res.data.data.date_of_expiry, dateFormat()).format(dateFormat()) : ''
                    setActiveObject({ ...obj })
                }
            }
            )
        }
        else if (obj && (obj.name == 'signed_ssn' && obj.approval_status != "Approved")) {
            addLoader()
            const data = {
                id: obj.id,
                url: obj.document_url
            }
            CommonApi.ocrDocumentUpload('ssn-data', data).then((res) => {
                removeLoader()
                if (res.data.statusCode === 1003) {
                    obj.document_number = res.data.data.social_security_number ? res.data.data.social_security_number : ''
                    obj.date_of_issue = res.data.data.issue_date ? moment(res.data.data.issue_date, dateFormat()).format(dateFormat()) : ''
                    obj.date_of_expiry = res.data.data.date_of_expiry ? moment(res.data.data.date_of_expiry, dateFormat()).format(dateFormat()) : ''
                    setActiveObject({ ...obj })
                }
            }
            )
        }
        else if (obj && (obj.name == 'i94' && obj.approval_status != "Approved")) {
            addLoader()
            const data = {
                id: obj.id,
                url: obj.document_url
            }
            CommonApi.ocrDocumentUpload('i94-data', data).then((res) => {
                removeLoader()
                if (res.data.statusCode === 1003) {
                    obj.document_number = res.data.data.i94_record_number ? res.data.data.i94_record_number : res.data.data.i_94_record_number ? res.data.data.i94_record_number : ''
                    const admitUntilDate = res?.data?.data?.admit_until_date.toLowerCase();
                    obj.valid_from = moment(res?.data?.data?.recent_date_of_entry, dateFormat()).format(dateFormat())
                    obj.valid_till = res.data.data.admit_until_date ? admitUntilDate != 'd/s' ? moment(res.data.data.admit_until_date, dateFormat()).format(dateFormat()) : '' : ''
                    obj.expiry_type = res.data.data.admit_until_date ? admitUntilDate == 'd/s' ? 1 : 2 : ''
                    obj.date_of_issue = moment(res?.data?.data?.recent_date_of_entry, dateFormat()).format(dateFormat())
                    obj.date_of_expiry = res.data.data.admit_until_date ? admitUntilDate != 'd/s' ? moment(res.data.data.admit_until_date, dateFormat()).format(dateFormat()) : '' : ''
                    setActiveObject({ ...obj })
                }
            }
            )
        }
        else if (obj && (obj.name == 'drivers_license' && obj.approval_status != "Approved")) {
            addLoader()
            const data = {
                id: obj.id,
                url: obj.document_url
            }
            CommonApi.ocrDocumentUpload('dl-data', data).then((res) => {
                removeLoader()
                if (res.data.statusCode === 1003) {
                    obj.document_number = res.data.data.driving_license_number ? res.data.data.driving_license_number : ''
                    obj.valid_from = res.data.data.issued_date ? moment(res.data.data.issued_date, dateFormat()).format(dateFormat()) : ''
                    obj.valid_till = res.data.data.expiry_date ? moment(res.data.data.expiry_date, dateFormat()).format(dateFormat()) : ''
                    setActiveObject({ ...obj })
                } else {
                    addErrorMsg(res.data.message);
                }
            }
            )
        }
        else {
            removeLoader()
        }
    }

    /**********  API Integrations starts here    **********/
    const getInviteViaLink = (id) => {
        EmployeeVerifyApi.getVerifyEmployeeIndex(id).then((res) => {
            if (res.data.statusCode === 1003) {
                setData(res.data.data);
                setDocumentData(handleFormArray(res.data.data));

                setEmergencyContact(res.data.data.emergency_contacts);
                if (documentData.length == 0) {
                    // setTimeout(() => {
                    setActiveObject(handleFormArray(res.data.data)[0]);
                    getOCRdata(handleFormArray(res.data.data)[0]);
                    setActiveIndex(0);
                    // }, 500)
                } else {
                    setTimeout(() => {
                        handleAssignState(0);
                    }, 500)
                }
                if (location.state != null && location.state != undefined && location.state != "") {
                    setStep(1);
                }

            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const updateInviteViaLink = (param, param1) => {
        param['request_id'] = LocalStorage.uid();
        setLoader(true);
        EmployeeVerifyApi.updateVerifyEmployeeIndex(param).then((res) => {
            if (res.data.statusCode === 1003) {
                if (param1 != undefined) {
                    LocalStorage.removeEmployeeId();
                    navigate('/employees/onboard', { state: { id: param.id, from: 'verifyFlow' } });
                }
                setLoader(false);
            } else {
                setLoader(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const countriesList = () => {
        CommonApi.getCountryList('', LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setCountries(res.data.data);
            }
        })
    }

    const visaDropdownList = () => {
        CommonApi.visaTypes(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setVisaTypes(res.data.data);
            }
        })
    }

    const relationList = () => {
        CommonApi.relation(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setRelation(res.data.data);
            }
        })
    }

    /**********  API Integrations Ends here    **********/

    /**
  * Convert nested object data into an array of objects with serial numbers.
  * @param {object} param - The input object containing nested data.
  * @returns {array} - An array of objects with serial numbers.
  */
    const handleFormArray = (param) => {
        // Initialize an empty array to store merged data
        let mergedArray = [];
        // Initialize serial number counter
        let serialNumber = 1;

        const sortedKeys = Object.keys(param.upload_documents).sort((a, b) => {
            return desiredOrder.indexOf(a) - desiredOrder.indexOf(b);
        });
        const sortedDocuments = {};
        for (const key of sortedKeys) {
            sortedDocuments[key] = param.upload_documents[key];
        }

        // Iterate over keys in the upload_documents object
        for (const key in sortedDocuments) {
            // Extract data array for the current key
            const dataArray = sortedDocuments[key].data;

            // Check if the data array contains only one object
            if (dataArray.length === 1) {
                // If only one object, push it to mergedArray with serial number
                const item = dataArray[0];
                if (item.name === "signed_ssn") {
                    mergedArray.push({
                        serial_number: `${serialNumber}`,
                        ocr: false,
                        document_type_id: 2,
                        document_number: item.document_number ? item.document_number : "",
                        valid_from: item.valid_from ? item.valid_from : "",
                        valid_till: item.valid_till ? item.valid_till : "",
                        approval_status: item.approval_status ? item.approval_status : "Submitted",
                        ...item
                    });
                } else if (item.name === "drivers_license") {
                    mergedArray.push({
                        serial_number: `${serialNumber}`,
                        ocr: false,
                        document_type_id: 1,
                        document_number: item.document_number ? item.document_number : "",
                        valid_from: item.valid_from ? item.valid_from : "",
                        valid_till: item.valid_till ? item.valid_till : "",
                        approval_status: item.approval_status ? item.approval_status : "Submitted",
                        ...item
                    });
                } else if (item.name === "i94") {
                    mergedArray.push({
                        serial_number: `${serialNumber}`,
                        ocr: false,
                        expiry_type: '',
                        country_id: item.country_id ? item.country_id : "",
                        document_number: item.document_number ? item.document_number : "",
                        date_of_issue: item.date_of_issue ? item.date_of_issue : "",
                        date_of_expiry: item.date_of_expiry ? item.date_of_expiry : "",
                        approval_status: item.approval_status ? item.approval_status : "Submitted",
                        ...item
                    });
                } else if (item.name === "passport") {
                    mergedArray.push({
                        serial_number: `${serialNumber}`,
                        ocr: false,
                        issued_country_id: item.issued_country_id ? item.issued_country_id : "",
                        document_number: item.document_number ? item.document_number : "",
                        date_of_issue: item.date_of_issue ? item.date_of_issue : "",
                        date_of_expiry: item.date_of_expiry ? item.date_of_expiry : "",
                        approval_status: item.approval_status ? item.approval_status : "Submitted",
                        ...item
                    });
                } else if (item.name === "copy_of_void_cheque") {
                    mergedArray.push({
                        serial_number: `${serialNumber}`,
                        ocr: false,
                        account_type: item.account_type ? item.account_type : "",
                        routing_number: item.routing_number ? item.routing_number : "",
                        account_number: item.account_number ? item.account_number : "",
                        bank_name: item.bank_name ? item.bank_name : "",
                        approval_status: item.approval_status ? item.approval_status : "Submitted",
                        ...item
                    });
                } else if (item.name === "work_authorization") {
                    mergedArray.push({
                        serial_number: `${serialNumber}`,
                        ocr: false,
                        valid_from: item.valid_from ? item.valid_from : "",
                        valid_till: item.valid_till ? item.valid_till : "",
                        visa_type_id: item.visa_type_id ? item.visa_type_id : "",
                        approval_status: item.approval_status ? item.approval_status : "Submitted",
                        ...item
                    });
                } else {
                    mergedArray.push({
                        serial_number: `${serialNumber}`,
                        ocr: false,
                        approval_status: item.approval_status ? item.approval_status : "Submitted",
                        ...item
                    });
                }
                serialNumber++;
            } else {
                if (dataArray.length > 0) {
                    // If multiple objects, push each with serial_number.sub_serial_number format
                    for (let i = 0; i < dataArray.length; i++) {
                        const item = dataArray[i];
                        if (item.name === "copy_of_void_cheque") {
                            mergedArray.push({
                                serial_number: `${serialNumber}.${i + 1}`,
                                serial: i + 1,
                                ocr: false,
                                account_type: item.account_type ? item.account_type : "",
                                routing_number: item.routing_number ? item.routing_number : "",
                                account_number: item.account_number ? item.account_number : "",
                                bank_name: item.bank_name ? item.bank_name : "",
                                approval_status: item.approval_status ? item.approval_status : "Submitted",
                                ...item
                            });
                        } else if (item.name === "work_authorization") {
                            mergedArray.push({
                                serial_number: `${serialNumber}.${i + 1}`,
                                serial: i + 1,
                                ocr: false,
                                valid_from: item.valid_from ? item.valid_from : "",
                                valid_till: item.valid_till ? item.valid_till : "",
                                visa_type_id: item.visa_type_id ? item.visa_type_id : "",
                                approval_status: item.approval_status ? item.approval_status : "Submitted",
                                ...item
                            });
                        } else {
                            mergedArray.push({
                                serial_number: `${serialNumber}.${i + 1}`,
                                serial: i + 1,
                                ocr: false,
                                approval_status: item.approval_status ? item.approval_status : "Submitted",
                                ...item
                            });
                        }
                    }
                    serialNumber++;
                }
            }
        }
        mergedArray.push({
            serial_number: mergedArray.length == 0 ? "1" : mergedArray[mergedArray.length - 1].serial_number ? parseInt(mergedArray[mergedArray.length - 1].serial_number) + 1 : '1',
            ocr: false,
            id: param.i9andw4.i9_document.id,
            name: "i9_document",
            document_name: param.i9andw4.i9_document.document_name,
            document_url: param.i9andw4.i9_document.document_url,
            approval_status: param.i9andw4.i9_document.approval_status ? param.i9andw4.i9_document.approval_status : "Submitted"
        });
        mergedArray.push({
            serial_number: mergedArray.length == 0 ? "2" : mergedArray[mergedArray.length - 1].serial_number ? parseInt(mergedArray[mergedArray.length - 1].serial_number) + 1 : '2',
            ocr: false,
            name: "w4_document",
            id: param.i9andw4.w4_document.id,
            document_name: param.i9andw4.w4_document.document_name,
            document_url: param.i9andw4.w4_document.document_url,
            approval_status: param.i9andw4.w4_document.approval_status ? param.i9andw4.w4_document.approval_status : "Submitted"
        });
        return mergedArray;
    }

    // Function to handle state assignment based on index
    const handleAssignState = (index) => {
        setLoader(true);
        if (documentData.length > 0) {

            if (activeIndex == (documentData.length - 1)) {
                setEmergencyContact(data.emergency_contacts);
                setLoader(false);
                setStep(1);
            } else {
                // if (documentData[index].name == "passport" || documentData[index].name == "i94" || documentData[index].name == "work_authorization" || documentData[index].name == "signed_ssn") {
                //     addLoader();
                //     const data = {
                //         request_id: LocalStorage.uid(),
                //         document_id: documentData[index].id
                //     }
                //     EmployeeCreateAPI.ocrDocumentUpload(slugReturn(documentData[index].name), data).then((res) => {
                //         if (res.data.statusCode == 1003) {
                //             removeLoader();
                //             // if (documentData[index].name == "copy_of_void_cheque") {
                //             //     documentData[index]['account_number'] = "";
                //             //     documentData[index]['routing_number'] = "";
                //             //     documentData[index]['bank_name'] = "";
                //             //     documentData[index]['account_type'] = "";
                //             // } else
                //             if (documentData[index].name == "passport") {
                //                 documentData[index]['document_number'] = res.data.data.data.document_number ? res.data.data.data.document_number : '';
                //                 documentData[index]['issued_country'] = res.data.data.data.country_code == "IND" ? 101 : 233;
                //                 documentData[index]['date_of_issue'] = res.data.data.data.date_of_issue ? moment(res.data.data.data.date_of_issue, dateFormat()).format(dateFormat()) : '';
                //                 documentData[index]['date_of_expiry'] = res.data.data.data.date_of_expiry ? moment(res.data.data.data.date_of_expiry, dateFormat()).format(dateFormat()) : '';
                //                 documentData[index]['ocr'] = true
                //             } else if (documentData[index].name == "i94") {
                //                 documentData[index]['document_number'] = res.data.data.document_number ? res.data.data.document_number : '';
                //                 documentData[index]['issued_country'] = "";
                //                 documentData[index]['date_of_arrival'] = "";
                //                 documentData[index]['date_of_expiry'] = "";
                //             } else if (documentData[index].name == "work_authorization") {
                //                 documentData[index]['visa_type_id'] = "";
                //                 documentData[index]['date_of_issue'] = "";
                //                 documentData[index]['date_of_expiry'] = "";
                //             } else if (documentData[index].name == "signed_ssn") {
                //                 documentData[index]['document_number'] = "";
                //                 documentData[index]['date_of_issue'] = "";
                //                 documentData[index]['date_of_expiry'] = "";
                //             }
                //         } else {
                //             // if (documentData[index].name == "copy_of_void_cheque") {
                //             //     documentData[index]['account_number'] = "";
                //             //     documentData[index]['routing_number'] = "";
                //             //     documentData[index]['bank_name'] = "";
                //             //     documentData[index]['account_type'] = "";
                //             // } else
                //             if (documentData[index].name == "passport") {
                //                 documentData[index]['document_number'] = "";
                //                 documentData[index]['issued_country'] = "";
                //                 documentData[index]['date_of_issue'] = "";
                //                 documentData[index]['date_of_expiry'] = "";
                //                 documentData[index]['ocr'] = false
                //             } else if (documentData[index].name == "i94") {
                //                 documentData[index]['document_number'] = "";
                //                 documentData[index]['issued_country'] = "";
                //                 documentData[index]['date_of_arrival'] = "";
                //                 documentData[index]['date_of_expiry'] = "";
                //             } else if (documentData[index].name == "work_authorization") {
                //                 documentData[index]['visa_type_id'] = "";
                //                 documentData[index]['date_of_issue'] = "";
                //                 documentData[index]['date_of_expiry'] = "";
                //             } else if (documentData[index].name == "signed_ssn") {
                //                 documentData[index]['document_number'] = "";
                //                 documentData[index]['date_of_issue'] = "";
                //                 documentData[index]['date_of_expiry'] = "";
                //             }
                //             setDocumentData([...documentData]);
                //             removeLoader()
                //         }
                //     })
                // }
                setTimeout(() => {
                    setActiveIndex(index);
                    setActiveObject(documentData[index]);
                    getOCRdata(documentData[index]);
                    setLoader(false);
                }, 600);
            }
            setError({});
        }
    }

    // eslint-disable-next-line
    const slugReturn = (name) => {
        switch (name) {
            case "passport":
                return "passport";
            case "i94":
                return "i94";
            case "copy_of_void_cheque":
                return "cheque";
            case "work_authorization":
                return "ead";
            case "signed_ssn":
                return "ssn";
            default:
                break
        }
    }

    const handleApprove = (index) => {
        if (activeObject.name === "copy_of_void_cheque") {
            if (activeObject.account_number == "" || activeObject.routing_number == "" || activeObject.bank_name == "" || activeObject.account_type == "") {
                error['account_number'] = validate_accountNumber(activeObject.account_number)
                error['routing_number'] = validate_routingNumber(activeObject.routing_number)
                error['bank_name'] = validate_charWithSpace(activeObject.bank_name, 'bank ');
                error['account_type'] = validate_emptyField(activeObject.account_type)
                setError({ ...error });
                addErrorMsg("please enter the fields to continue");
                return false;
            }
            if (activeObject.account_number != "" && activeObject.account_number.length < 8) {
                error['account_number'] = validate_accountNumber(activeObject.account_number);
                setError({ ...error });
                addErrorMsg("please enter the proper account number to continue");
                return false;
            }
            if (activeObject.routing_number != "" && activeObject.routing_number.length < 9) {
                error['routing_number'] = validate_routingNumber(activeObject.routing_number);
                setError({ ...error });
                addErrorMsg("please enter the proper routing number to continue");
                return false;
            }
        }
        if (activeObject.name == "signed_ssn" || activeObject.name == "drivers_license" || activeObject.name == "i94" || activeObject.name == "passport") {
            if (activeObject.name == "drivers_license") {
                if (activeObject.document_number == "") {
                    error['document_number'] = ErrorMessages.fieldRequired;
                    setError({ ...error })
                    addErrorMsg(ErrorMessages.documentContinue);
                    return false;
                } else if (activeObject.document_number !== "" && activeObject.document_number.length < 4) {
                    error['document_number'] = ErrorMessages.documentNumberDrivingAndStateID;
                    setError({ ...error })
                    addErrorMsg(ErrorMessages.documentNumberDrivingAndStateID);
                    return false;
                } else {
                    documentData[activeIndex].document_number = activeObject.document_number;
                    setDocumentData([...documentData]);
                }
            } else if (activeObject.name == "i94") {
                if (activeObject.expiry_type == 2) {
                    if (activeObject.document_number == "") {
                        error['document_number'] = ErrorMessages.fieldRequired;
                        setError({ ...error })
                    }
                    if (activeObject.date_of_expiry == '') {
                        addErrorMsg("Admin until date is required");
                        error['date_of_expiry'] = ErrorMessages.fieldRequired;
                        setError({ ...error })
                        return true;
                    }
                }
                if (activeObject.document_number == "") {
                    error['document_number'] = ErrorMessages.fieldRequired;
                    setError({ ...error })
                    addErrorMsg(ErrorMessages.documentContinue);
                    return false;
                } else if (activeObject.document_number !== "" && validates_new_i94(activeObject.document_number)) {
                    error['document_number'] = validates_new_i94(activeObject.document_number);
                    addErrorMsg(validates_new_i94(activeObject.document_number));
                    setError({ ...error })
                    return false;
                } else {
                    documentData[activeIndex].document_number = activeObject.document_number;
                    setDocumentData([...documentData]);
                }
                if (moment(activeObject.date_of_issue, dateFormat()).isAfter(moment(), dateFormat())) {
                    addErrorMsg(ErrorMessages.invalidRecentDate);
                    setError({ ...error })
                    return false;
                }
                if (moment(activeObject.date_of_expiry, dateFormat()).isBefore(moment(activeObject.date_of_issue, dateFormat()))) {
                    addErrorMsg(ErrorMessages.invalidAdmitUntilDate);
                    setError({ ...error })
                    return false;
                }
            } else if (activeObject.name == "passport") {
                if (activeObject.document_number == "") {
                    error['document_number'] = ErrorMessages.fieldRequired;
                    setError({ ...error })
                    addErrorMsg(ErrorMessages.documentContinue);
                    return false;
                } else if (activeObject.document_number !== "" && activeObject.document_number.length < 8) {
                    error['document_number'] = ErrorMessages.validPassportLength;
                    setError({ ...error })
                    addErrorMsg(ErrorMessages.validPassportLength);
                    return false;
                } else {
                    documentData[activeIndex].document_number = activeObject.document_number;
                    setDocumentData([...documentData]);
                }
            } else {
                if (activeObject.document_number == "") {
                    error['document_number'] = ErrorMessages.fieldRequired;
                    setError({ ...error })
                    addErrorMsg(ErrorMessages.documentContinue);
                    return false;
                } else {
                    if (activeObject.name == "signed_ssn" && validate_ssn(activeObject.document_number)) {
                        error['document_number'] = validate_ssn(activeObject.document_number);
                        addErrorMsg(validate_ssn(activeObject.document_number));
                        return false;
                    } else {
                        documentData[activeIndex].document_number = activeObject.document_number;
                        setDocumentData([...documentData]);
                    }
                }
            }

        }
        if (activeObject.name == "work_authorization") {
            if (activeObject.visa_type_id == "") {
                error['visa_type_id'] = ErrorMessages.fieldRequired;
                setError({ ...error });
                addErrorMsg("please select the visa type to continue");
                return false;
            }
        }
        activeObject.approval_status = "Approved";
        setActiveObject({ ...activeObject });
        getOCRdata(activeObject);
        // documentData[activeIndex].approval_status = "Approved";
        // setDocumentData([...documentData]);

        documentData[activeIndex] = activeObject;
        setDocumentData([...documentData]);

        if (activeObject.name == "i9_document") {
            data.i9andw4.i9_document = activeObject
            setData({ ...data });
        } else if (activeObject.name == "w4_document") {
            data.i9andw4.w4_document = activeObject
            setData({ ...data });
        } else {
            for (const key in data.upload_documents) {
                // Extract data array for the current key
                const dataArray = data.upload_documents[key].data;
                if (dataArray.length > 0) {
                    for (let i = 0; i < dataArray.length; i++) {
                        if (dataArray[i].id == documentData[activeIndex].id) {
                            dataArray[i] = activeObject;
                            setData({ ...data });
                        }
                    }
                }
            }
        }
        setData({ ...data });
        updateInviteViaLink(data);

        if (activeIndex === (documentData.length - 1)) {
            setEmergencyContact(data.emergency_contacts);
            setStep(1);
        } else {
            handleAssignState(index);
        }
        scrollToTop();
    }

    const handleFinalApprove = () => {
        emergency_detailsError();
        // eslint-disable-next-line
        contactError.find((i) => {
            if (isValid(i)) { } else {
                addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
                return true
            }
        })
        if (isValidMulti(contactError)) {
            data.emergency_contacts = emergencyContact;
            setData({ ...data });
            updateInviteViaLink(data, "final");
        }
    }

    const handleClick = async (item, index) => {
        setStep(0);
        setError({});
        setLoader(true);
        let dataObject = documentData[index];

        let data = {
            request_id: LocalStorage.uid(),
            document_key: dataObject.document_path
        };

        try {
            const res = await CommonApi.documentViewS3('invite_via_link', data);
            removeLoader();

            if (res.data.statusCode === 1003) {
                dataObject.document_url = res.data.data.document_url;
            }

            // Once API call completes, update the state
            setActiveIndex(index);
            setActiveObject(dataObject);
            getOCRdata(dataObject);
        } catch (error) {
            console.error("Error fetching document URL:", error);
            removeLoader(); // ensure loader is removed on error as well
        } finally {
            setLoader(false); // stop loader after everything is done
        }
    };

    /**  onChange function are starts here   **/
    const handleChange = (e) => {
        if (activeObject.name == "signed_ssn") {
            const value = e.target.value;
            const name = e.target.name;
            const input = value.replace(/\D/g, '').substring(0, 10);

            // Divide numbers in 3 parts :"(123) 456-7890" 
            const first = input.substring(0, 3);
            const middle = input.substring(3, 5);
            const last = input.substring(5, 9);

            if (input.length > 5) {
                setActiveObject({ ...activeObject, [e.target.name]: `${first}-${middle}-${last}` });
                if (name == 'document_number') {
                    if (last == '0000') {
                        error['document_number'] = 'Invalid SSN, please check the format '
                        setError(error)
                        return true
                    }
                }
            }
            else if (input.length > 3) {
                setActiveObject({ ...activeObject, [e.target.name]: `${first}-${middle}` });
                if (name == 'document_number') {
                    if (middle == '00') {
                        error['document_number'] = 'Invalid SSN, please check the format '
                        setError(error)
                        // addErrorMsg('Invalid SSN, please check the format ');
                        return true
                    }
                }
            }
            else if (input.length >= 0) {
                setActiveObject({ ...activeObject, [e.target.name]: input });
                if (name == 'document_number') {
                    if (first == '000' || first == '666' || first == '900') {
                        error['document_number'] = 'Invalid SSN, please check the format '
                        setError(error)
                        // addErrorMsg('Invalid SSN, please check the format ');
                        return true
                    }
                }
            }
            handleValidate(e, "ssn");
        } else {
            activeObject[e.target.name] = e.target.value;
            if (activeObject.name == 'i94') {
                setActiveObject({ ...activeObject });
                checkPassport('i94', e.target.value, 'i94')
            } else {
                setActiveObject({ ...activeObject });
                checkPassport('passport', e.target.value, 'document_number')
            }
            if (activeObject.name == "i94") {
                handleValidate(e, "i94");
            } else if (activeObject.name == "drivers_license") {
                handleValidate(e, "drivers_license");
            } else {
                handleValidate(e);
            }

        }
    }

    const handleValidate = (e, param) => {
        var input = e.target;
        switch (input.name || input.tagName) {
            case "account_number":
                error['account_number'] = validate_accountNumber(input.value);
                break;
            case "routing_number":
                error['routing_number'] = validate_routingNumber(input.value);
                break;
            case "bank_name":
                error['bank_name'] = validate_charWithSpace(input.value, 'bank ');
                break;
            case "account_type":
                error['account_type'] = validate_emptyField(input.value);
                break;
            case "visa_type_id":
                error['visa_type_id'] = validate_emptyField(input.value);
                break;
            case "document_number":
                if (param == "ssn") {
                    error['document_number'] = input.value == "" ? validate_emptyField(input.value) : validate_ssn(input.value);
                } else if (param == 'i94') {
                    error['document_number'] = input.value == "" ? validate_emptyField(input.value) : validates_new_i94(input.value)
                } else if (param == 'drivers_license') {
                    error['document_number'] = (input.value !== '' && input.value.length < 4) ? ErrorMessages.documentNumberDrivingAndStateID : validate_emptyField(input.value);
                } else {
                    error['document_number'] = input.value == "" ? validate_emptyField(input.value) : validate_passport(input.value);
                }
                break;
            default:
                break;
        }
        setError({ ...error });
    }

    const changeHandler = (e, index) => {
        if (e.target.name === "country_id") {
            emergencyContact.state_id = "";
            emergencyContact[index][e.target.name] = e.target.value;
            setEmergencyContact([...emergencyContact]);
            contactHandleValidates(e.target, index);
        }
        if (e.target.name === "mobile_number") {
            convertFormat(e, index);
        } else {
            emergencyContact[index][e.target.name] = e.target.value;
            setEmergencyContact([...emergencyContact]);
            contactHandleValidates(e.target, index);
        }
        if (e.target.name == 'zip_code') {
            zipcodeAPICallEmergency(e.target.value, index)
        }
    }

    const resetStateWithErrorEmergency = (data, index) => {
        // Correctly update the specific index of dummyState without affecting other indices
        setEmergencyContact((prevDummyState) =>
            prevDummyState.map((item, i) =>
                i === index
                    ? {
                        ...item,
                        state_id: '',
                        state_name: '',
                        country_name: '',
                        city: '',
                        country_id: '',
                        zip_code: data
                    }
                    : item // Preserve other indices
            )
        );

        // Correctly update the specific index of erro without affecting other indices
        setContactError((prevErro) =>
            prevErro.map((item, i) =>
                i === index
                    ? {
                        ...item,
                        city: '',
                        state_id: '',
                        country_id: '',
                        zip_code:
                            data.length == 0 ? ErrorMessages.fieldRequired :
                                data.length === 6
                                    ? ErrorMessages.invalidPincode
                                    : data.length === 5
                                        ? ErrorMessages.invalidZipcode
                                        : ErrorMessages.invalidPinZipcode
                    }
                    : item // Preserve other indices
            )
        );
    };
    // eslint-disable-next-line
    const zipcodeAPICallEmergency = useCallback(
        debounce((data, index) => {
            CommonApi.getZipcodeData(data).then((res) => {
                const { statusCode, data: responseData } = res.data;

                if (statusCode === 1003 && responseData.length > 0) {
                    const { state_name, country_name, city, state_id, country_id } = responseData[0];

                    // Update the specific index in dummyState without affecting others
                    setEmergencyContact((prevDummyState) =>
                        prevDummyState.map((item, i) =>
                            i === index
                                ? {
                                    ...item,
                                    state_id,
                                    state_name,
                                    country_name,
                                    city,
                                    country_id,
                                    zip_code: data
                                }
                                : item // Preserve other indices
                        )
                    );

                    // Update the specific index in erro without affecting others
                    setContactError((prevErro) =>
                        prevErro.map((item, i) =>
                            i === index
                                ? {
                                    ...item,
                                    city: '',
                                    zip_code: '',
                                    state_id: '',
                                    country_id: ''
                                }
                                : item // Preserve other indices
                        )
                    );
                } else {
                    // Reset only the specific index if no data is found
                    resetStateWithErrorEmergency(data, index);
                }
            });
        }, 500), // 500ms delay
        [] // No dependencies needed since we're using setState callbacks
    );



    const convertFormat = (e, index) => {
        const value = e.target.value;
        const name = e.target.name
        const input = value.replace(/\D/g, '').substring(0, 10);
        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'mobile_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'mobile_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'mobile_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > (name == 'mobile_number' ? 6 : 5)) {
            emergencyContact[index][e.target.name] = `${first}-${middle}-${last}`
            setEmergencyContact([...emergencyContact], contactHandleValidates(e.target, index))
        }
        else if (input.length > 3) {
            emergencyContact[index][e.target.name] = `${first}-${middle}`
            setEmergencyContact([...emergencyContact], contactHandleValidates(e.target, index))
        }
        else if (input.length >= 0) {
            emergencyContact[index][e.target.name] = input
            setEmergencyContact([...emergencyContact], contactHandleValidates(e.target, index))
        }
    }

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d
        let key = name == 'date_of_issue' ? 'date_of_expiry' : name == 'valid_from' ? 'valid_till' : null

        if (key) {
            setActiveObject({
                ...activeObject,
                [name]: date == '' ? '' : moment(date).format(dateFormat()),
                [key]: ''
            })
        } else {
            setActiveObject({
                ...activeObject,
                [name]: date == '' ? '' : moment(date).format(dateFormat())
            })
        }
        if (name == 'date_of_expiry') {
            setError({
                ...error,
                date_of_expiry: ''
            })
        }
    }

    const handleRejectOpen = (item) => {
        setRejectOpen(true);
    }


    const handleRejectDocument = () => {
        activeObject.approval_status = "Rejected";
        setActiveObject({ ...activeObject });

        documentData[activeIndex] = activeObject;
        setDocumentData([...documentData]);
        // documentData[activeIndex].approval_status = "Rejected";
        // setDocumentData([...documentData]);
        if (activeObject.name == "i9_document") {
            data.i9andw4.i9_document = activeObject
            setData({ ...data });
        } else if (activeObject.name == "w4_document") {
            data.i9andw4.w4_document = activeObject
            setData({ ...data });
        } else {
            for (const key in data.upload_documents) {
                // Extract data array for the current key
                const dataArray = data.upload_documents[key].data;
                if (dataArray.length > 0) {
                    for (let i = 0; i < dataArray.length; i++) {
                        if (dataArray[i].id == documentData[activeIndex].id) {
                            dataArray[i] = activeObject;
                            // dataArray[i].approval_status = "Rejected";
                            setData({ ...data });
                        }
                    }
                }
            }
        }
        updateInviteViaLink(data);
        setRejectOpen(false);
        addSuccessMsg("Document has been rejected");
    }

    const handleBack = () => {
        setActiveIndex(documentData.length - 1);
        setStep(0);
        setActiveObject(documentData[documentData.length - 1]);
    }

    const contactHandleValidates = (e, index) => {
        let input = e;
        let error1 = contactError[index] || {};

        for (var k = 0; k <= index; k++) {
            contactError.push({});
        }
        let s1 =
            contactError.length > 0 ? [...contactError] : [{ ...contactError }];
        switch (input.name || input.tagName) {
            case "relationship_id":
                error1.relationship_id = validate_emptyField(input.value);
                break;
            case "name":
                error1.name = validate_charWithSpace(input.value);
                break;
            case "mobile_number":
                error1.mobile_number = validate_usContact(input.value, 'Mobile Number');
                break;
            case "address_1":
                error1.address_1 = address_validation(input.value);
                break;
            case "address_2":
                error1.address_2 = validate_empty_address(input.value);
                break;
            case "city":
                error1.city = validate_city(input.value);
                break;
            case "state_id":
                error1.state_id = validate_emptyField(input.value);
                break;
            case "country_id":
                error1.country_id = validate_emptyField(input.value);
                break;
            case "zip_code":
                error1.zip_code = emergencyContact[index].country_id == 1 ? validate_zipcode(input.value, 1) : validate_zipcode(input.value);
                break;
            default:
                break;
        }
        setContactError(s1);
    }

    const emergency_detailsError = async () => {
        // eslint-disable-next-line
        const updatedContactErrors = await Promise.all(
            emergencyContact.map(async (value, index) => {
                contactError[index].relationship_id = validate_emptyField(value.relationship_id);
                contactError[index].name = validate_charWithSpace(value.name);
                contactError[index].mobile_number = validate_usContact(value.mobile_number, 'Mobile Number');
                contactError[index].address_1 = address_validation(value.address_1);
                contactError[index].address_2 = validate_empty_address(value.address_2);
                contactError[index].state_id = validate_emptyField(value.state_id);
                contactError[index].country_id = validate_emptyField(value.country_id);
                contactError[index].city = validate_city(value.city);
                contactError[index].zip_code = await checkZipcode(value.zip_code);

                return contactError[index]; // Return the updated object
            })
        );

        // Update state after all async operations are completed
        setContactError([...updatedContactErrors]);
    };

    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current && activeIndex !== undefined) {
            // Calculate the offset of the active index image
            const offset = activeIndex * containerRef.current.children[0].offsetHeight;
            // Scroll the container to show the active index image
            containerRef.current.scrollTop = offset;
        }
    }, [activeIndex]);

    const fullScreenRef = useRef(null);

    const handleFullScreen = () => {
        const elem = fullScreenRef.current;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    }

    const onError = (error) => {
        console.error(error);
    };

    const formatReturn = (url) => {
        return url?.substring(url.lastIndexOf('.') + 1);
    }



    const checkPassport = (entityName, val, args) => {
        const delayDebounceFn = setTimeout(() => {
            if (args == 'document_number') {
                var passportObj = {
                    request_id: LocalStorage.uid(),
                    document_number: val,
                    id: '',
                }
            } else {
                var i94Obj = {
                    request_id: LocalStorage.uid(),
                    document_number: val,
                    id: '',
                }
            }


            EmployeesApi.checkDuplicateNumber(entityName, args == 'document_number' ? passportObj : i94Obj).then((res) => {
                if (res.data.statusCode === 1003) {
                    if (res.data.valid) {

                    } else {
                        if (args == 'document_number') {
                            setError({
                                ...error,
                                document_number: "Passport Number is already exists"
                            })

                        }else{
                            setError({
                                ...error,
                                document_number: "I94 Number is already exists"
                            }) 
                        }
                    }
                }
                else {
                    setError({
                        ...error,
                        document_number: res.data.message
                    })
                }
            });
        }, 300)
        return () => clearTimeout(delayDebounceFn)
    }

    return (
        <Grid container pb={2}>
            <Grid item lg={3} md={3}></Grid>
            <Grid item lg={5} md={5} textAlign='center' p={'20px 0px 10px 0px !important'}>
                <Stepper activeStep={step} connector={<BrownMnCustomisedConnector />}>
                    {
                        ['Uploaded Documents', 'Emergency Contact Information'].map((item) => (
                            <Step>
                                <BrownMnColorlibStepLabel StepIconComponent={BrownMnCustomStepIcon}>
                                    <Text BrowmnMnStepperText>{item}</Text>
                                </BrownMnColorlibStepLabel>
                            </Step>
                        ))
                    }
                </Stepper>
            </Grid>
            <Grid item lg={3} md={3}></Grid>
            <>
                {
                    step == 0 && documentData.length > 0 ?
                        <>
                            {activeObject != undefined && activeObject != null ?
                                <>
                                    <Grid item lg={2} md={2} pt={3} ml={2}>
                                        <Box className={classes.sideBarBox}>
                                            <Box className={classes.innerSideBox} ref={containerRef}>
                                                {documentData.length > 0 && documentData.map((item, ind) => (
                                                    <>
                                                        <Box key={ind} mt={1} className={`${classes.imageBox} ${activeIndex === ind ? classes.activeImageBox : ''}`} onClick={() => handleClick(item, ind)}>
                                                            {
                                                                formatReturn(item.document_name) == "pdf" ?
                                                                    <iframe title={item.document_url ? item.document_url : ""} src={item.document_url ? item.document_url : ""} style={{ width: "120px" }} />
                                                                    :
                                                                    <img src={item.document_url} alt={"image_url"} className={classes.image} />
                                                            }
                                                            {activeIndex === ind ? <img src={checkIcon} alt="checkIcon" className={classes.activeImage} /> : <div className={classes.cropOverlay}></div>}
                                                        </Box>
                                                        <Text smallLabel sx={{ textAlign: "center" }}>{item.serial_number}</Text>
                                                        <Divider className={classes.divider} />
                                                    </>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid onScroll={(e) => { setScrollPosition(e.target.scrollTop) }} item lg={activeObject != null && (activeObject.name == "education_documents" || activeObject.name == "counter_signed_offer_letter" || activeObject.name == "w4_document" || activeObject.name == "i9_document" || activeObject.name == "all_previous_i20s") ? 7 : 6} md={6} mt={3} ml={-3} className={classes.middleGrid}>
                                        {
                                            loader ?
                                                <Grid item md={12} sm={12} xs={12} textAlign={"center"}>
                                                    <img src={blueLoader} height={100} width={100} alt='loading' style={{ marginTop: "170px" }} />
                                                </Grid>
                                                :
                                                <>
                                                    <Box className={classes.middleImageBox}  >
                                                        <Grid container item md={12} xs={12} alignItems={"center"}>
                                                            <Grid item md={8} lg={6} xl={8} sm={7}>

                                                                <Text mediumBlack14>{activeObject ? activeObject.name ? `${formatString(activeObject.name === "drivers_license"
                                                                    ? "Driving License/State ID"
                                                                    : activeObject.name
                                                                )}${activeObject.serial ? `- ${activeObject.serial}` : ""}` : "" : ""}</Text>
                                                            </Grid>
                                                            <Grid item md={4} lg={6} xl={4} sm={5} textAlign='center' mt={-4}>
                                                                {activeObject != null && activeObject.approval_status === "Rejected" &&
                                                                    <img src={documentReject} alt="documentReject" className={classes.rejectImage} />
                                                                }
                                                                <Grid container item md={10} justifyContent='end' pt={2} textAlign='end'>
                                                                    <Box display='flex' flexDirection='row' gap={2}>
                                                                        <img onClick={() => { handleFullScreen() }} src={whiteZoomin} alt="whiteZoomin" style={{ cursor: "pointer" }} />
                                                                        <img onClick={() => window.open(activeObject.document_url, '_blank', 'noopener,noreferrer')} src={whiteDownLoad} alt="whiteDownLoad" style={{ cursor: "pointer" }} />
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {
                                                            formatReturn(activeObject.document_name) == "pdf" ?
                                                                <div style={{ paddingTop: "80px", width: "-webkit-fill-available", display: "inline-table" }}>
                                                                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`} >
                                                                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`} >
                                                                            <div ref={fullScreenRef}>
                                                                                <Viewer
                                                                                    width="100%"
                                                                                    fileUrl={activeObject !== null ? activeObject.document_url : ''}
                                                                                    canvasCss={{
                                                                                        fontFamily: 'Gungsuh-Bold, sans-serif', // Use a fallback font
                                                                                    }}
                                                                                    onError={onError} />
                                                                            </div>
                                                                        </Worker>
                                                                    </Worker>
                                                                </div>
                                                                :
                                                                <img ref={fullScreenRef} src={activeObject != null ? activeObject.document_url : ''} alt={"image_url"} className={classes.middleImage} />
                                                        }
                                                    </Box>
                                                    {
                                                        activeObject != null && (activeObject.name == "education_documents" || activeObject.name == "counter_signed_offer_letter" || activeObject.name == "w4_document" || activeObject.name == "i9_document" || activeObject.name == "all_previous_i20s") ?
                                                            <Grid pt={3} item lg={12} md={12} sm={12} sx={{ background: "#ffffff !important" }}>
                                                                {
                                                                    activeObject.approval_status != "Submitted" ?
                                                                        <Grid item md={12} textAlign={"end"}>
                                                                            <Button saveNcontinue onClick={() => { handleAssignState(activeIndex + 1) }} sx={{ width: "175px !important", }}>Continue</Button>
                                                                        </Grid>
                                                                        :
                                                                        <Grid container item md={12} justifyContent={"space-between"}>
                                                                            <Button onClick={() => { handleRejectOpen() }} blackCancel sx={{ border: `1px solid ${btnTxtBlack.shade4} !important` }}>Reject</Button>
                                                                            <Button onClick={() => { handleApprove(activeIndex + 1) }} saveNcontinue sx={{ width: "175px !important", }}>Approve & Continue</Button>
                                                                        </Grid>
                                                                }
                                                            </Grid> : ""
                                                    }
                                                </>
                                        }
                                    </Grid>
                                    {
                                        activeObject != null && (activeObject.name == "education_documents" || activeObject.name == "counter_signed_offer_letter" || activeObject.name == "w4_document" || activeObject.name == "i9_document" || activeObject.name == "all_previous_i20s") ?
                                            ""
                                            :
                                            <Grid item lg={4} md={4} mt={1} p={2} ml={1}>
                                                <Text largeBlack >Identified Fields</Text>
                                                <Grid container spacing={3} pt={2} pr={5}>
                                                    <Box width={'100%'} sx={{ height: "65vh" }}>
                                                        <Grid container spacing={3} mt={2} pl={3}>
                                                            {activeObject.name == "copy_of_void_cheque" ?
                                                                <>
                                                                    <Grid item md={12} xs={12} >
                                                                        {
                                                                            loader ?
                                                                                <Skeleton variant='rounded' height={50} /> :
                                                                                <Input
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    inputProps={{
                                                                                        name: 'account_number',
                                                                                        value: activeObject.account_number,
                                                                                        type: 'text',
                                                                                        inputProps: { maxLength: 12 },
                                                                                    }}
                                                                                    disabled={activeObject.approval_status != "Submitted"}
                                                                                    handleChange={(e) => { handleChange(e) }}
                                                                                    onKeyPress={onNumberOnlyChange}
                                                                                    clientInput
                                                                                    labelText={'Account Number'}
                                                                                    error={error.account_number}
                                                                                />
                                                                        }
                                                                        {error.account_number ? <Text red>{error.account_number}</Text> : null}
                                                                    </Grid>

                                                                    <Grid item md={12} xs={12}>
                                                                        {
                                                                            loader ?
                                                                                <Skeleton variant='rounded' height={50} /> :
                                                                                <Input
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    inputProps={{
                                                                                        name: 'routing_number',
                                                                                        value: activeObject.routing_number,
                                                                                        type: 'text',
                                                                                        inputProps: { maxLength: 9 }
                                                                                    }}
                                                                                    disabled={activeObject.approval_status != "Submitted"}
                                                                                    onKeyPress={onNumberOnlyChange}
                                                                                    handleChange={(e) => { handleChange(e) }}
                                                                                    clientInput
                                                                                    labelText={'Routing Number'}
                                                                                    error={error.routing_number}
                                                                                />
                                                                        }
                                                                        {error.routing_number ? <Text red>{error.routing_number}</Text> : null}
                                                                    </Grid>

                                                                    <Grid item md={12} xs={12}>
                                                                        {
                                                                            loader ?
                                                                                <Skeleton variant='rounded' height={50} /> :
                                                                                <Input
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    inputProps={{
                                                                                        name: 'bank_name',
                                                                                        value: activeObject.bank_name,
                                                                                        type: 'text',
                                                                                        inputProps: { maxLength: 50 }
                                                                                    }}
                                                                                    error={error.bank_name}
                                                                                    disabled={activeObject.approval_status != "Submitted"}
                                                                                    handleChange={(e) => { handleChange(e) }}
                                                                                    clientInput
                                                                                    labelText={'Bank Name'}
                                                                                />
                                                                        }
                                                                        {error.bank_name ? <Text red>{error.bank_name}</Text> : null}
                                                                    </Grid>

                                                                    <Grid item md={12} xs={12}>
                                                                        {
                                                                            loader ?
                                                                                <Skeleton variant='rounded' height={50} /> :
                                                                                <Select
                                                                                    name='account_type'
                                                                                    value={activeObject.account_type}
                                                                                    onChange={(e) => { handleChange(e) }}
                                                                                    options={accountType}
                                                                                    label={'Account Type'}
                                                                                    helperText={error.account_type ? <Text red>{error.account_type}</Text> : null}
                                                                                    disabled={activeObject.approval_status != "Submitted"}
                                                                                />
                                                                        }
                                                                    </Grid>
                                                                </>
                                                                :
                                                                activeObject.name == "work_authorization" ?
                                                                    <>
                                                                        <Grid item md={12} xs={12}>
                                                                            {
                                                                                loader ?
                                                                                    <Skeleton variant='rounded' height={50} /> :
                                                                                    <Select
                                                                                        name='visa_type_id'
                                                                                        value={activeObject.visa_type_id}
                                                                                        onChange={(e) => { handleChange(e) }}
                                                                                        options={visaTypes}
                                                                                        label={'Visa Type'}
                                                                                        disabled={activeObject.approval_status != "Submitted"}
                                                                                        helperText={error.visa_type_id ? <Text red>{error.visa_type_id}</Text> : ""}
                                                                                    />
                                                                            }
                                                                        </Grid>
                                                                        <Grid item lg={6}>
                                                                            {
                                                                                loader ?
                                                                                    <Skeleton variant='rounded' height={50} /> :
                                                                                    <Date
                                                                                        labelText={'Date of Issue (Optional)'}
                                                                                        name='date_of_issue'
                                                                                        value={activeObject.date_of_issue}
                                                                                        maxDate={activeObject.date_of_expiry !== '' ? moment(activeObject.date_of_expiry, dateFormat()).format(dateFormat()) : moment().format(dateFormat())}
                                                                                        onChange={(value => dateChange(value, 'date_of_issue', 'visa'))}
                                                                                        height='56px'
                                                                                        disabled={activeObject.approval_status != "Submitted"}
                                                                                    />
                                                                            }
                                                                        </Grid>
                                                                        <Grid item lg={6}>
                                                                            {
                                                                                loader ?
                                                                                    <Skeleton variant='rounded' height={50} /> :
                                                                                    <>
                                                                                        <Date
                                                                                            labelText={'Date of Expiry (Optional)'}
                                                                                            name='date_of_expiry'
                                                                                            minDate={moment(activeObject.date_of_issue, dateFormat()).format(dateFormat())}
                                                                                            value={activeObject.date_of_expiry}
                                                                                            onChange={(value => dateChange(value, 'date_of_expiry', 'visa'))}
                                                                                            height='56px'
                                                                                            disabled={activeObject.approval_status != "Submitted"}
                                                                                            error={error.date_of_expiry}
                                                                                        />
                                                                                        {error.date_of_expiry ? <Text red> {error.date_of_expiry}</Text> : ""}
                                                                                    </>
                                                                            }
                                                                        </Grid>
                                                                    </>
                                                                    :
                                                                    (activeObject.name == "passport" || activeObject.name == "i94") ?
                                                                        <>
                                                                            <Grid item md={12} xs={12}>
                                                                                {
                                                                                    loader ?
                                                                                        <Skeleton variant='rounded' height={50} /> :
                                                                                        <Input
                                                                                            formControlProps={{
                                                                                                fullWidth: true
                                                                                            }}
                                                                                            inputProps={{
                                                                                                name: 'document_number',
                                                                                                value: activeObject.document_number,
                                                                                                inputProps: { maxLength: activeObject.name == "i94" ? 11 : 14 }
                                                                                            }}
                                                                                            disabled={activeObject.approval_status != "Submitted"}
                                                                                            handleChange={(e) => { handleChange(e) }}
                                                                                            onKeyPress={onCharactersAndNumbersChange}
                                                                                            clientInput
                                                                                            labelText={activeObject.name == "passport" ? `Passport Number` : "I-94 Number"}
                                                                                            error={error.document_number}
                                                                                        />
                                                                                }
                                                                                {error.document_number ? <Text red> {error.document_number}</Text> : ""}
                                                                            </Grid>
                                                                            {
                                                                                activeObject.name != "i94" &&
                                                                                <Grid item md={12} xs={12} sm={12} lg={12}>
                                                                                    {
                                                                                        loader ?
                                                                                            <Skeleton variant='rounded' height={50} /> :
                                                                                            <Select
                                                                                                name='issued_country'
                                                                                                value={activeObject.issued_country}
                                                                                                onChange={(e) => { handleChange(e) }}
                                                                                                options={countries}
                                                                                                disabled={activeObject.approval_status != "Submitted"}
                                                                                                label={`Issued Country (Optional)`}
                                                                                                helperText={''}
                                                                                            />
                                                                                    }
                                                                                </Grid>
                                                                            }

                                                                            <Grid item md={6} xs={12} sm={6} lg={6}>
                                                                                {
                                                                                    loader ?
                                                                                        <Skeleton variant='rounded' height={50} /> :
                                                                                        <Date
                                                                                            labelText={activeObject.name == "i94" ? 'Recent Date of Entry (Optional)' : 'Date of Issue (Optional)'}
                                                                                            name='date_of_issue'
                                                                                            value={activeObject.date_of_issue}
                                                                                            maxDate={activeObject.date_of_expiry !== '' ? moment(activeObject.date_of_expiry, dateFormat()).format(dateFormat()) : moment().format(dateFormat())}
                                                                                            onChange={(value => dateChange(value, 'date_of_issue', 'passport'))}
                                                                                            height='56px'
                                                                                            disabled={activeObject.approval_status != "Submitted"}
                                                                                        />
                                                                                }
                                                                            </Grid>
                                                                            {
                                                                                activeObject.name == "i94" ?
                                                                                    <Grid item md={6} xs={12} sm={6} lg={6}>
                                                                                        <Select
                                                                                            options={expiryTypeList}
                                                                                            name='expiry_type'
                                                                                            value={activeObject.expiry_type || ''}
                                                                                            onChange={(e) => { handleChange(e); setError({ ...error, date_of_expiry: '' }) }}
                                                                                            label={'Admit Until Date'}
                                                                                            disabled={activeObject.date_of_issue == '' || activeObject.approval_status != "Submitted"}
                                                                                        />
                                                                                    </Grid>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                            <Grid item md={6} xs={12} sm={6} lg={6}>
                                                                                {
                                                                                    loader ?
                                                                                        <Skeleton variant='rounded' height={50} /> :
                                                                                        activeObject.name == "i94" && (activeObject.expiry_type == '' || activeObject.expiry_type == 1) ? '' :
                                                                                            <>

                                                                                                <Date
                                                                                                    labelText={activeObject.name == "i94" ? 'Admit Until Date (Optional)' : 'Date of Expiry (Optional)'}
                                                                                                    name='date_of_expiry'
                                                                                                    minDate={moment(activeObject.date_of_issue, dateFormat()).format(dateFormat())}
                                                                                                    value={activeObject.date_of_expiry}
                                                                                                    onChange={(value => dateChange(value, 'date_of_expiry', 'passport'))}
                                                                                                    height='56px'
                                                                                                    disabled={activeObject.approval_status != "Submitted"}
                                                                                                    error={error.date_of_expiry}
                                                                                                />
                                                                                                {error.date_of_expiry ? <Text red> {error.date_of_expiry}</Text> : ""}
                                                                                            </>
                                                                                }
                                                                            </Grid>
                                                                        </>
                                                                        :
                                                                        activeObject.name == "drivers_license" || activeObject.name == "signed_ssn" ?
                                                                            <>
                                                                                <Grid item md={12} xs={12}>
                                                                                    {
                                                                                        loader ?
                                                                                            <Skeleton variant='rounded' height={50} /> :
                                                                                            <Input
                                                                                                formControlProps={{
                                                                                                    fullWidth: true
                                                                                                }}
                                                                                                inputProps={{
                                                                                                    name: 'document_number',
                                                                                                    value: activeObject.document_number,
                                                                                                    inputProps: { maxLength: activeObject.name == "signed_ssn" ? 11 : 16 }
                                                                                                }}
                                                                                                disabled={activeObject.approval_status != "Submitted"}
                                                                                                handleChange={(e) => { handleChange(e) }}
                                                                                                clientInput
                                                                                                onKeyPress={onCharactersAndNumbersChange}
                                                                                                labelText={activeObject.name == "signed_ssn" ? "SSN Number" : `Document Number`}
                                                                                                error={error.document_number}
                                                                                            />
                                                                                    }
                                                                                    {error.document_number ? <Text red> {error.document_number}</Text> : ""}
                                                                                </Grid>
                                                                                <Grid item lg={6}>
                                                                                    {
                                                                                        loader ?
                                                                                            <Skeleton variant='rounded' height={50} /> :
                                                                                            activeObject.name != "signed_ssn" ?
                                                                                                <Date
                                                                                                    labelText={'Date of Issue (Optional)'}
                                                                                                    name='valid_from'
                                                                                                    value={activeObject.valid_from}
                                                                                                    maxDate={activeObject.valid_till !== '' ? moment(activeObject.valid_till, dateFormat()).format(dateFormat()) : moment().format(dateFormat())}
                                                                                                    onChange={(value => dateChange(value, 'valid_from', activeObject.name))}
                                                                                                    height='56px'
                                                                                                    disabled={activeObject.approval_status != "Submitted"}
                                                                                                />
                                                                                                : ''
                                                                                    }
                                                                                </Grid>
                                                                                <Grid item lg={6}>
                                                                                    {
                                                                                        loader ?
                                                                                            <Skeleton variant='rounded' height={50} /> :
                                                                                            activeObject.name != "signed_ssn" ?
                                                                                                <>
                                                                                                    <Date
                                                                                                        labelText={'Date of Expiry (Optional)'}
                                                                                                        name='valid_till'
                                                                                                        minDate={moment(activeObject.valid_from, dateFormat()).format(dateFormat())}
                                                                                                        value={activeObject.valid_till}
                                                                                                        onChange={(value => dateChange(value, 'valid_till', activeObject.name))}
                                                                                                        height='56px'
                                                                                                        disabled={activeObject.approval_status != "Submitted"}
                                                                                                        error={error.valid_till}

                                                                                                    />
                                                                                                    {error.valid_till ? <Text red> {error.valid_till}</Text> : ""}
                                                                                                </>
                                                                                                : ''
                                                                                    }
                                                                                </Grid>
                                                                            </>
                                                                            :
                                                                            ""
                                                            }
                                                        </Grid>
                                                    </Box>
                                                    {
                                                        !loader &&
                                                        <>
                                                            {
                                                                activeObject.approval_status != "Submitted" ?
                                                                    <Grid item md={12} textAlign={"end"}>
                                                                        <Button saveNcontinue onClick={() => { handleAssignState(activeIndex + 1) }} sx={{ width: "175px !important", }}>Continue</Button>
                                                                    </Grid>
                                                                    :
                                                                    <Grid container item md={12} justifyContent={"space-between"}>
                                                                        <Button onClick={() => { handleRejectOpen() }} blackCancel sx={{ border: `1px solid ${btnTxtBlack.shade4} !important` }}>Reject</Button>
                                                                        <Button onClick={() => { handleApprove(activeIndex + 1) }} saveNcontinue sx={{ width: "175px !important", }}>Approve & Continue</Button>
                                                                    </Grid>
                                                            }
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                    }
                                </>
                                : ''
                            }
                        </>
                        :
                        step == 1 ?
                            <>
                                <Grid item lg={2} md={2} pt={3}></Grid>
                                <Grid item lg={7} md={6} mt={3}>
                                    <Card sx={{ padding: '20px !important', boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.05) !important", borderRadius: '15px' }}>
                                        <CardHeader sx={{ padding: '15px 0px 0px 25px !important' }} title={
                                            ''
                                        } />
                                        <CardContent sx={{ padding: '20px 20px 30px 30px !important', height: '81vh', overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}
                                        >
                                            {
                                                emergencyContact.length > 0 && emergencyContact.map((item, index) => (
                                                    <Grid container lg={12} spacing={3} p={index > 0 ? '30px 0px' : ''}>
                                                        {
                                                            emergencyContact.length > 0 &&
                                                            <>
                                                                <Grid item container lg={12} md={12} sm={12} xs={12}>
                                                                    <Grid item lg={8} md={8} sm={8} xs={10}>
                                                                        <Text largeBlack>Emergency Contact Details -{index + 1}</Text>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        }
                                                        <Grid item lg={6}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'name',
                                                                    value: item.name,
                                                                    inputProps: { maxLength: 33 }
                                                                }}
                                                                handleChange={(e) => changeHandler(e, index)}
                                                                clientInput
                                                                error={contactError.length > 0 ? contactError[index] ? contactError[index].name : "" : ''}
                                                                labelText={'Name'} />
                                                            {contactError.length > 0 ? <Text red>{contactError[index] ? contactError[index].name : ""}</Text> : ''}
                                                        </Grid>
                                                        <Grid item lg={6}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'mobile_number',
                                                                    value: item.mobile_number,
                                                                    inputProps: { maxLength: 12 }
                                                                }}
                                                                handleChange={(e) => changeHandler(e, index)}
                                                                clientInput
                                                                error={contactError.length > 0 ? contactError[index] ? contactError[index].mobile_number : "" : ''}
                                                                labelText='Number'
                                                            />
                                                            {contactError.length > 0 ? <Text red>{contactError[index] ? contactError[index].mobile_number : ""}</Text> : ''}
                                                        </Grid>
                                                        <Grid item lg={6}>
                                                            <Box pt={'10px'}>
                                                                <Select
                                                                    name='relationship_id'
                                                                    value={item.relationship_id}
                                                                    onChange={(e) => { changeHandler(e, index) }}
                                                                    options={relation}
                                                                    label={'Relation'}
                                                                    helperText={contactError.length > 0 && contactError[index] && contactError[index].relationship_id && <Text red> {contactError[index].relationship_id}</Text>}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={6} >
                                                        </Grid>
                                                        <Grid item container lg={12} spacing={2}>
                                                            <Grid item lg={12}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'address_1',
                                                                        value: item.address_1,
                                                                        inputProps: { maxLength: 225 }
                                                                    }}
                                                                    handleChange={(e) => changeHandler(e, index)}
                                                                    clientInput
                                                                    labelText={'Address Line-1'}
                                                                    error={contactError.length > 0 ? contactError[index] ? contactError[index].address_1 : "" : ''}
                                                                />
                                                                {contactError.length > 0 ? <Text red>{contactError[index] ? contactError[index].address_1 : ""}</Text> : ''}
                                                            </Grid>
                                                            <Grid item lg={12}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'address_2',
                                                                        value: item.address_2,
                                                                        inputProps: { maxLength: 225 }
                                                                    }}
                                                                    handleChange={(e) => changeHandler(e, index)}
                                                                    clientInput
                                                                    error={contactError.length > 0 ? contactError[index] ? contactError[index].address_2 : "" : ''}
                                                                    labelText={'Address Line-2 (Optional)'} />
                                                                {contactError.length > 0 ? <Text red>{contactError[index] ? contactError[index].address_2 : ""}</Text> : ''}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'zip_code',
                                                                    value: item.zip_code,
                                                                    inputProps: { maxLength: 6 }
                                                                }}
                                                                handleChange={(e) => changeHandler(e, index, 'contact')}
                                                                clientInput
                                                                onKeyPress={onNumberOnlyChange}
                                                                error={contactError.length > 0 ? contactError[index] ? contactError[index].zip_code : "" : ''}
                                                                labelText={item.zip_code.length == 5 ? 'Zipcode' : item.zip_code.length == 6 ? 'Pincode' : 'Zipcode / Pincode'}
                                                            />
                                                            {contactError.length > 0 ? <Text red>{contactError[index] ? contactError[index].zip_code : ""}</Text> : ''}
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'city',
                                                                    value: item.city,
                                                                    inputProps: { maxLength: 50 }
                                                                }}
                                                                handleChange={(e) => changeHandler(e, index, 'contact')}
                                                                clientInput
                                                                error={contactError.length > 0 ? contactError[index] ? contactError[index].city : "" : ''}
                                                                labelText={'City'}
                                                            />
                                                            {contactError.length > 0 ? <Text red>{contactError[index] ? contactError[index].city : ""}</Text> : ''}
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Box pt={'10px'}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'state_name',
                                                                        value: item.state_name || '',
                                                                    }}
                                                                    disabled={true}
                                                                    clientInput
                                                                    labelText='State'
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Box pt={'10px'}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'country_name',
                                                                        value: item.country_name || '',
                                                                    }}
                                                                    disabled={true}
                                                                    clientInput
                                                                    labelText='Country'
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </CardContent>
                                    </Card>
                                    <Grid container pt={5} item md={12} justifyContent={"space-between"}>
                                        <Button onClick={() => { handleBack() }} blackCancel sx={{ border: `1px solid ${btnTxtBlack.shade4} !important` }}>Back</Button>
                                        <Button onClick={() => { handleFinalApprove() }} saveNcontinue sx={{ width: "175px !important", }}>Approve</Button>
                                    </Grid>
                                </Grid>
                            </> :
                            ''
                }
            </>
            {rejectOpen && <ReusableDelete open={rejectOpen} setOpen={setRejectOpen} onClick={() => { handleRejectDocument() }} reject={true}
                rejectcontent={
                    activeObject.name == "work_authorization" ? "visa documents"
                        : activeObject.name == "copy_of_void_cheque" ? "bank"
                            : activeObject.name == "counter_signed_offer_letter" ? "others documents"
                                : activeObject.name == "passport" ? "passport documents"
                                    : activeObject.name == "i94" ? "i94 documents" :
                                        activeObject.name == "signed_ssn" ? "SSN documents" :
                                            activeObject.name == "drivers_license" ? "driving license documents" :
                                                activeObject.name == "education_documents" ? "education documents" :
                                                    activeObject.name == "w4_document" ? "bank" : "documents"}
            />}
        </Grid >
    )
}

export default EmployeeVerifyFLow