import React, { useState } from 'react'// eslint-disable-next-line
import { Box, ListItemButton, Grid } from '@mui/material';
import MainStyles from '../MainStyles';// eslint-disable-next-line
import Text from '../../../../components/customText/Text';
import Role from './Preferences/Role';


// eslint-disable-next-line
const prefernces = ['Role'];

function GroupsConfig(props) {
    // eslint-disable-next-line
    const classes = MainStyles()
    // eslint-disable-next-line
    const [current, setCurrent] = useState("Role");

    return (
        <Box display={'flex'} justifyContent={'center'} width={'100%'}>
            <Box padding={'20px 0px 0px 0px'} width={{ sm: '95%', md: '95%', lg: '85%' }}>
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Box className={classes.mainListBox} >
                            <Box className={classes.prefTitle}>
                                <Text blackHeader600>Preference</Text>
                            </Box>
                            <Box className={classes.listContainer} sx={{ maxHeight: '58vh' }}>
                                {
                                    prefernces.map((item, key) => (
                                        <ListItemButton
                                            key={key}
                                            className={`${classes.listItems} ${current === item ? classes.listItemsActive : null}`}
                                            onClick={() => setCurrent(item)}
                                            id={item}
                                        >
                                            {item}
                                        </ListItemButton>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Grid>
                    {/* <Grid item lg={2} md={2} sm={2} xs={12}></Grid> */}
                    <Grid item lg={8} md={8} sm={8} xs={12}>
                        {
                            current === "Role" ? <Role current={current} setTab={props.setTab} setInternal={props.setInternal} /> : null
                        }
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default GroupsConfig;
