import React from 'react'
import { onNumberOnlyChange } from '../../../components/Validation'
import Input from '../../../components/input/Input'
import { Box, Grid } from '@mui/material'
import Text from '../../../components/customText/Text'

export default function Worklocation(props) {
    const { state, error, changeHandlerAddress, work_location_type, columnSize } = props
    return (
        <>
            <Grid container columnSpacing={4}>
                {(work_location_type == 'In-Office' || work_location_type == 'Hybrid') &&
                    <Grid item container columnSpacing={4}>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                            <Box display='flex' flexDirection='row' gap={2}>
                                <Text blackHeader1>Please enter the office address</Text>
                            </Box>
                        </Grid>
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'address_line_one',
                                    value: state.address.office.address_line_one,
                                    inputProps: { maxLength: 225 }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'office')}
                                clientInput
                                labelText={`Address Line-1 `}
                                error={error.office_address_line_one}
                            />
                            <Text red>{error.office_address_line_one ? error.office_address_line_one : ""}</Text>
                        </Grid>
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'address_line_two',
                                    value: state.address.office.address_line_two,
                                    inputProps: { maxLength: 225 }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'office')}
                                clientInput
                                labelText={`Address Line-2 (Optional)`}
                                error={error.office_address_line_two}
                            />
                            <Text red>{error.office_address_line_two ? error.office_address_line_two : ""}</Text>
                        </Grid>
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'zip_code',
                                    value: state.address.office.zip_code,
                                    inputProps: { maxLength: 5 }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'office')}
                                onKeyPress={onNumberOnlyChange}
                                clientInput
                                labelText={`Zip Code`}
                                error={error.office_zip_code}
                            />
                            <Text red>{error.office_zip_code ? error.office_zip_code : ""}</Text>
                        </Grid>
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'city',
                                    value: state.address.office.city,
                                    inputProps: { maxLength: 50 }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'office')}
                                clientInput
                                labelText={`City`}
                                error={error.office_city}
                            />
                            <Text red>{error.office_city ? error.office_city : ""}</Text>
                        </Grid>
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'state_name',
                                    value: state.address.office.state_name || '',
                                }}
                                disabled={true}
                                clientInput
                                labelText='State'
                            />
                        </Grid>
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'country_name',
                                    value: state.address.office.country_name || '',
                                }}
                                disabled={true}
                                clientInput
                                labelText='Country'
                            />
                        </Grid>

                    </Grid>
                }
                {(work_location_type == 'Remote' || work_location_type == 'Hybrid') &&
                    <Grid item container columnSpacing={4}>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                            <Box display='flex' flexDirection='row' gap={2}>
                                <Text blackHeader1>Please enter the remote address</Text>
                            </Box>
                        </Grid>
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'address_line_one',
                                    value: state.address.remote.address_line_one,
                                    inputProps: { maxLength: 225 }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'remote')}
                                clientInput
                                labelText={`Address Line-1 `}
                                error={error.remote_address_line_one}
                            />
                            <Text red>{error.remote_address_line_one ? error.remote_address_line_one : ""}</Text>
                        </Grid>
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'address_line_two',
                                    value: state.address.remote.address_line_two,
                                    inputProps: { maxLength: 225 }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'remote')}
                                clientInput
                                labelText={`Address Line-2 (Optional)`}
                                error={error.remote_address_line_two}
                            />
                            <Text red>{error.remote_address_line_two ? error.remote_address_line_two : ""}</Text>
                        </Grid>
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'zip_code',
                                    value: state.address.remote.zip_code,
                                    inputProps: { maxLength: 5 }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'remote')}
                                onKeyPress={onNumberOnlyChange}
                                clientInput
                                labelText={`Zip Code`}
                                error={error.remote_zip_code}
                            />
                            <Text red>{error.remote_zip_code ? error.remote_zip_code : ""}</Text>
                        </Grid>
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'city',
                                    value: state.address.remote.city,
                                    inputProps: { maxLength: 50 }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'remote')}
                                clientInput
                                labelText={`City`}
                                error={error.remote_city}
                            />
                            <Text red>{error.remote_city ? error.remote_city : ""}</Text>
                        </Grid>
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'state_name',
                                    value: state.address.remote.state_name || '',
                                }}
                                disabled={true}
                                clientInput
                                labelText='State'
                            />
                        </Grid>
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'country_name',
                                    value: state.address.remote.country_name || '',
                                }}
                                disabled={true}
                                clientInput
                                labelText='Country'
                            />
                        </Grid>


                    </Grid>
                }
            </Grid>
        </>
    )
}