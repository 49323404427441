import Chart from "react-apexcharts";

const RadialChart = (props) => {
  const defaultOptions = {
    labels: props.labels,
    legend:{
        show:true,
      },
    options: {
      chart: {
        type: "radialBar",
      },
      legend: {
        show: false
      },
    },
  };

  const options = props.options ? props.options : defaultOptions;
  const series = props.series;
  const height = props.height ? props.height : "160px";
  const width = props.width ? props.width : "100%";

  return (
    <Chart
      {...props}
      options={options}
      series={series}
      type="radialBar"
      height={height}
      width={width}
    />
  );
};

export default RadialChart;
