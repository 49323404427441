import BaseApi from './BaseApi';
import APIURL from '../config/development';
import LocalStorage from '../utils/LocalStorage';

class ChatbotApi {
    FetchReply(question) {
        return BaseApi.getWithParams(APIURL.org_URL + `chatbot?question=${question}`, LocalStorage.getAccessToken())
    }
}
// eslint-disable-next-line
export default new ChatbotApi()